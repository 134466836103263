import { SparklesIcon } from '@heroicons/react/solid';
import React from 'react';
import { foodOptions } from '../../common';

const TemplateInformation = ({item}) => {
    return (
        <div>
            <div className="mt-7 grid grid-cols-2 lg:grid-cols-4 gap-3">
                <div>
                    <p className="bid-label text-gray-500">CGE Option</p>
                    <div className="border-2 border-gray-700 rounded-full h-7 inline-flex items-center px-2.5">
                        <p className="andie-description-text text-gray-700 capitalize font-roboto-medium">{item.cge_type}</p>
                    </div>
                </div>
                <div>
                    <p className="bid-label text-gray-500">Cuisines</p>
                    <div className="flex flex-wrap">
                        {[...new Set(item.packages.map((pkg => pkg.cuisine_type)).flat(1))].map(ct => (

                        <div className="h-7 px-2.5 inline-flex items-center rounded-full bg-blue-100 mr-2 mb-2">
                            <p className="andie-description-text font-roboto-medium text-blue-600">{foodOptions.find(x => x.value === ct).label}</p>
                        </div>
                        ))}
                    </div>
                </div>
                <div>
                    <p className="bid-label text-gray-500">Atmosphere</p>
                    <div className="flex items-center space-x-2">
                        <SparklesIcon className="h-5 text-blue-600" />
                        <p className="andie-description-text text-gray-600 capitalize">{item.atmosphere}</p>
                    </div>
                </div>
                <div>
                    <p className="bid-label text-gray-500">Suitable for</p>
                    <p className="andie-description-text text-gray-500 capitalize leading-tight">{item.suitable_for}</p>
                </div>
            </div>
            
        </div>
    )
}

export default TemplateInformation