import { CheckCircleIcon, PhotographIcon, UserGroupIcon, XIcon } from "@heroicons/react/solid";
import moment from "moment";
import { useLocation } from "react-router-dom";

import { calculateBudget, getTransactionAmounts, moneySignText, pricetoString } from "../common";
import { cge_frontend } from "../../apis/config";

export default function BidResponseHeader({ bid, bidResponse, interested, checkIfPreferenceIsInReponse, dietary_preferencesList }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const location = useLocation();
    const report_body = `The following bid: ${location.pathname.replace('/bid/', '')} requires attention.. 
    ${cge_frontend + location.pathname.slice(1)}`;

    function renderPrice(response) {
        let total = getTransactionAmounts(response.items, bid.budget.tipAmount , response.delivery_fee, response.business_delivers, bid.cge_service_fee, response.business_service_fee, null, bidResponse.pricing, bidResponse.total_price).total;
        let tbudget = calculateBudget(bid.budget.total, 1)

        let ratio = total / tbudget
        if (ratio > 1) {
            return (
                <>
                    <h6 className="text-sm font-productsans-medium text-gray-700">${pricetoString(total)} </h6>
                    <p className="font-roboto-bold text-red-500 text-xs">${pricetoString(tbudget - total)} ({ratio.toFixed(2)}%)</p>
                </>
            )
        }
        else {
            return (
                <>
                    <h6 className="text-sm font-productsans-medium text-gray-700">{pricetoString(total)} </h6>
                    <p className="font-roboto-bold text-green-500 text-xs">$+{pricetoString(tbudget - total)} ({ratio.toFixed(2)}%)</p>
                </>
            )
        }
    }

    function renderLock(response) {
        let total = getTransactionAmounts(response.items, bid.budget.tipAmount, response.delivery_fee, response.business_delivers, bid.cge_service_fee, response.business_service_fee, null, response.pricing, response.total_price).total

        return (
            <>
                <h6 className="text-sm font-productsans-medium text-green-600"> {moneySignText(total)} </h6>
            </>
        )
    }

    return (
        <div className="border-2 rounded-md border-gray-100">
            <div className="h-12 flex items-center justify-between px-3">
                {(bidResponse.accepted && !interested) ?
                    <div className="bg-green-700 rounded-sm h-6 w-20 text-white flex items-center justify-center font-roboto-medium text-xs">Accepted</div>
                    :
                    <div className="bg-gray-200 rounded-sm h-6 w-20 text-gray-800 flex items-center justify-center font-roboto-medium text-xs">Submitted</div>
                }
                <div className="text-right">
                    {user !== null ?
                        <>{renderPrice(bidResponse)}</>
                        :
                        <>{renderLock(bidResponse)}</>
                    }
                </div>
            </div>
            <div className="p-3 border-b-2 border-t-2 border-gray-100">

                <div className="flex flex-col py-3.5 justify-center items-center space-y-3.5">
                    <h5 className="font-productsans-medium text-lg">Response #00{bidResponse.id}</h5>


                    <div className="flex flex-wrap justify-center gap-2">
                        <div className="border-2 text-xs font-roboto-medium flex items-center justify-center rounded p-1">
                            {bidResponse.items.length} items
                        </div>
                        <div className="border-2 space-x-1 flex items-center justify-center rounded py-1 px-1.5">
                            <UserGroupIcon className="h-3.5 -mt-0.5 text-gray-800" />
                            <p className="text-xs font-roboto-medium">{bid.guest_count}</p>
                        </div>
                        <div className="border-2 space-x-1 flex items-center justify-center rounded py-1 px-1.5">
                            {(!checkIfPreferenceIsInReponse() && dietary_preferencesList.length > 0) ?
                                <>
                                    <XIcon className="h-4 text-red-700" />
                                    <p className="text-xs font-roboto-medium">Meets dietary requirements</p>
                                </>
                                : <>
                                    <CheckCircleIcon className="h-4 text-green-700" />
                                    <p className="text-xs font-roboto-medium">Meets dietary requirements</p>
                                </>
                            }
                        </div>
                        <div className="border-2 space-x-1 flex items-center justify-center rounded p-1">
                            <PhotographIcon className={`h-4 text-gray-300 ${bidResponse.images.length > 0 && "text-gray-600"}`} />
                        </div>
                    </div>

                    <p className="andie-description-text text-xs text-gray-400">Posted {moment(bidResponse.date_created).format('ll')}.</p>
                </div>


            </div>
            {(user?.account_type == 1 && interested) ?
                <div className="h-11 flex justify-between items-center px-3">
                    <h6 className="bid-form-input-label mb-0">Interested in this response?</h6>
                    <div className="w-32 flex justify-center">
                    </div>
                </div>
                :
                <div className="h-11 flex justify-between items-center px-3">
                    <h6 className="bid-form-input-label mb-0">Having an issue with this response?</h6>
                    <div onClick={() => window.open(`mailto:engineering@andie.work?subject=Report on bid: ${location.pathname.replace('/bid/', '')}&body=${report_body}`)} className="andie-text-button">Report</div>
                </div>
            }
        </div>
    )
}