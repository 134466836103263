import { useEffect, useState } from "react";
import { Header } from "../../components/navigation";
import { LoadingIcon, getTransactionAmounts } from "../../components/common";
import { BillingForm } from "../../components/billing";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BiddingAPIs } from "../../apis";
import { useNavigate } from "react-router-dom";
import base64url from "base64url";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST)

export default function PaymentLanding() {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState({});
    const [tax, setTax] = useState(0);
    const [serviceFee, setServiceFee] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [tipInput, setTipInput] = useState(0);
    let nav = useNavigate();

    useEffect(() => {
        const getResponse = async () => {
            const queryParameters = new URLSearchParams(window.location.search)
            const params = queryParameters.get("ref")
            try {
                const decoded_params = JSON.parse(base64url.decode(params))
                if (decoded_params.response_id != null) {
                    const res = await BiddingAPIs.getSingleResponse(decoded_params.response_id, decoded_params.email, decoded_params.transaction_code);
                    console.log(res)
                    setResponse(res)
                    let data = getTransactionAmounts(res.items, res.bid.budget.tipAmount, res.delivery_fee, res.business_delivers, res.bid.cge_service_fee, res.business_service_fee, null, res.pricing, res.total_price)
                    setTax(data.tax)
                    setServiceFee(data.serviceFee)
                    setSubtotal(data.subtotal)
                    setTotal(data.total)
                    setLoading(false)
                }
            } catch(e) {
                console.log(e)
            }
        }
        getResponse();
    }, [])

    async function handleSubmit(paymentInfo) {
        console.log(paymentInfo)
    }

    return (
        <div className="h-screen">
            {/* <Header /> */}
            {loading ?
                <>
                    <div div className="h-full flex flex-col items-center justify-center w-full">
                        <div className="-mt-32">
                            <LoadingIcon />
                        </div>
                        <p> Loading Transaction </p>
                    </div>
                </>
            :
            <>
                <div className="h-10 w-full bg-white px-3 md:px-6 flex items-center border-b border-gray-200 justify-between">
                    <div className="flex space-x-5 justify-between md:justify-start">
                        <div className="flex items-center cursor-pointer" onClick={() => nav('/home')}>
                            <img className="h-7 w-7 rounded-sm" src={require("../../assets/images/icon-black.png")} />
                            <div className="ml-3 md:hidden bg-gray-300 rounded-full h-1.5 w-1.5" />
                        </div>
                        
                    </div>
                </div>
                {/* <p> ye </p> */}
                <div className="md:px-12 py-8 flex items-center border-b border-gray-200 justify-between">
                    <Elements stripe={stripePromise}>
                        <BillingForm
                            serviceFeeRate={response.bid.cge_service_fee}
                            subtotal={subtotal}
                            total={total}
                            tax={tax}
                            tipInput={tipInput} setTipInput={(e) => setTipInput(e)}
                            serviceFee={serviceFee}
                            handleSubmit={(paymentInfo) => handleSubmit(paymentInfo)}
                            items={response.items}
                            response={response}
                            guestCount={response.bid.guest_count}
                        />
                    </Elements>
                </div>
            </>
            }
            </div>
    )
}
