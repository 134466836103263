import { useEffect, useState } from "react"
import { PopoutModal } from "../../components/common";
import { PlusIcon } from "@heroicons/react/outline";


export default function AdminCGEResponses({ parentLoading, getCGEURLLeads, URLLeads }) {
    const [loading, setLoading] = useState(true);
    const [urlSlug, setUrlSlug] = useState("andie");

    useEffect(() => {
        getCGEURLLeads();
    }, [])

    useEffect(() => {
        if (
            parentLoading[9]
        ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [parentLoading])

    return (
        loading ?                 <div className="flex flex-col space-y-5 w-full h-full items-center justify-center">
        <span className="loading loading-spinner loading-lg"></span>
        <p className="font-productsans-medium">Loading Leads</p>
    </div>

        :
        <>
            <div className=" w-full">
                {URLLeads.map((lead) => (
                    <div className="m-5 p-2.5 bg-neutral-100 rounded-md">
                        <div className="flex flex-row justify-between">
                            <div>
                                <a className="link">
                                    <h3 className="text-md font-productsans-bold">
                                        https://www.cge.alwaysandie.com/ref/
                                        <span className="ml-1 badge badge-primary">{lead.url_slug}</span>
                                    </h3>
                                </a>
                                <p className="font-productsans-regular">
                                    No Name
                                </p>
                            </div>
                            <p className="font-productsans-medium text-xl">
                                {lead.visits.length} Visits
                            </p>
                        </div>

                        <div className="mt-2 flex flex-row w-full overflow-auto whitespace-nowrap">
                            {lead.visits.map(visit => (
                                <div className="flex flex-col mx-2 bg-white rounded-md p-4 w-1/4">
                                    <p className="font-productsans-medium text-sm">
                                        {visit.date}
                                    </p>
                                    <p className="font-productsans-regular text-sm">
                                        {visit.ip_addr}
                                    </p>
                                    <p className="font-productsans-regular text-sm">
                                        {visit.location}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <PopoutModal
                button={
                    <button
                        class="absolute bottom-12 right-10 z-99 flex flex-row items-center space-x-2 
                        text-white px-4 w-auto h-10 bg-blue-600 rounded-full
                        hover:bg-blue-700 active:shadow-lg mouse shadow-xl
                        transition ease-in duration-200 focus:outline-none">
                        <PlusIcon className="h-4" />
                        <span>New Referral Link</span>
                    </button>}
                modalID={"refLink"}
                title={"Generate Referral Link"}
            >
                <p className="font-productsans-regular mb-1">
                    Enter the referral code you would like to use.
                </p>
                <input type="text" onChange={(e) => setUrlSlug(e.target.value)}
                    defaultValue={urlSlug} className="mb-1 input w-full rounded-sm border-blue-600" />

                <p className="mb-6 font-productsans-regular text-sm">
                    https://www.cge.alwaysandie.com/ref/{urlSlug}
                </p>

                <div onClick={() => navigator.clipboard.writeText("https://www.cge.alwaysandie.com/ref/" + urlSlug)}
                    className="cursor-pointer h-9 px-3 bg-blue-600 rounded flex items-center justify-center mb-4 w-full">
                    <h6 className="text-xs text-white font-productsans-medium">Copy URL</h6>
                </div>


            </PopoutModal>
        </>
    )
}