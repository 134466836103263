import React, { useEffect, useState } from "react";

import { BiddingAPIs } from "../../../apis";
import { AdminOrderNowBreakdown, AdminOrderNowInfo, AdminOrderNowResponseBar } from "../";
import { toast } from "react-hot-toast";
import { getTransactionAmounts } from "../../common";

export default function AdminOrderNowBidBar({ bid, cgeAccountList, staffAccountList, businessAccountList }) {
    const [items, setItems] = useState(bid.order_now_details[0].items);
    const [edited, setEdited] = useState(0)
    const [loading, setLoading] = useState(false)

    const [sortedItems, setSortedItems] = useState(sortItemsByRestaurant(items))

    useEffect(() => {
        setSortedItems(sortItemsByRestaurant(items))
    }, [items, edited]);


    function sortItemsByRestaurant(itemlist) {
        let res = itemlist.reduce(function (r, a) {
            let rest = a.assigned_restaurant ? a.assigned_restaurant : "Unassigned"
            r[rest] = r[rest] || []
            r[rest].push(a)

            return r
        }, Object.create(null))


        return res
    }

    async function handleSubmitResponse() {
        let newSortedItems = {}
        for (const rest of Object.keys(sortedItems)){
            const newRestItems = []
            for (const item of sortedItems[rest]){
                if (item.quantity > 0) {
                    newRestItems.push(item)
                }
            }
            newSortedItems[rest] = newRestItems
        }

        if (newSortedItems["Unassigned"]) {
            toast.error("Please assign all items before submitting!")
        } else {
            // setLoading(true)

            let restaurantPaymentPayload = {}
            let restaurantPayload = {}
            let clientPaymentPayload = {}

            const restaurantsList = Object.keys(newSortedItems)
            Object.keys(newSortedItems).map((restaurant, index) => {
                const transAmounts = getTransactionAmounts(
                    newSortedItems[restaurant],
                    bid.budget['tipAmount'], 0, false,
                    bid.cge_service_fee, newSortedItems[restaurant][0].service_fee,
                    2
                )

                const tempacc = businessAccountList.find((acc) => acc.email === restaurant)

                restaurantPayload[restaurant] = tempacc
                restaurantPayload[restaurant]['location'] = tempacc.place_id
                restaurantPayload[restaurant]["title"] = tempacc.job_title
                restaurantPayload[restaurant]["restaurant_type"] = tempacc.restaurant_types[0]

                restaurantPaymentPayload[restaurant] = {
                    "subtotal": transAmounts.subtotal,
                    "serviceFee": transAmounts.serviceFee,
                    "deliveryFee": transAmounts.deliveryFee,
                    "tax": transAmounts.tax,
                    "tip": transAmounts.tip,
                    "total": transAmounts.total
                }
            })

            const clientTransAmounts = getTransactionAmounts(
                items,
                bid.budget['tipAmount'], 0, false,
                bid.cge_service_fee, 0,
                1
            )

            clientPaymentPayload = {
                "subtotal": clientTransAmounts.subtotal,
                "serviceFee": clientTransAmounts.serviceFee,
                "deliveryFee": clientTransAmounts.deliveryFee,
                "tax": clientTransAmounts.tax,
                "tip": clientTransAmounts.tip,
                "total": clientTransAmounts.total
            }

            const payload = {
                bid: bid,
                on_items: items,
                items: newSortedItems,
                restaurants: restaurantPayload,
                restaurant_payments: restaurantPaymentPayload,
                client_payment: clientPaymentPayload
            }

            await BiddingAPIs.orderNowResponse(payload)
        }
    }

    function getLocationDetails(loc) {
        var country = loc.filter(item => item.types.includes('country')).map(item => item.short_name)
        var province = loc.filter(item => item.types.includes('administrative_area_level_1')).map(item => item.short_name)
        var city = loc.filter(item => item.types.includes('administrative_area_level_2')).map(item => item.short_name)

        return (city + ', ' + province + " " + country)
    }

    return (
        <>
            {bid.order_now_details &&
                <div className="andie-description-text mb-5 w-[calc(100vw_/_2.5)]">
                    <AdminOrderNowInfo bid={bid} staffAccountList={staffAccountList} />
                    <AdminOrderNowResponseBar status={bid.status} items={items} businessAccountList={businessAccountList} setItems={setItems} edited={edited} setEdited={setEdited} />
                    <AdminOrderNowBreakdown bid={bid} allItems={items} sortedItems={sortedItems} edited={edited} status={bid.status} cgeAccountList={cgeAccountList} businessAccountList={businessAccountList} staffAccountList={staffAccountList} />
                    {bid.status === "Posted" && <div onClick={() => handleSubmitResponse()} className="andie-dark-button">Respond to OrderNow</div>}
                </div>
            }
        </>
    )
}