import React from "react";
import collectionspreview from "./collections-screengrab.png";

export default function TutorialCollections() {
    const ordercollection = [
        "Login into your Pluck account and navigate to the Explore page.",
        "Browse the latest collections available, and select the desired package of your choice.",
        "Enter your preferred quantity and items choices, and complete the transaction.",
        "It's that simple!"
    ]
    return (
        <div className="mb-8">
            <div className="bg-orange-100 p-3 h-72 rounded-lg">
                <img src={collectionspreview} className="h-full rounded-md" />
            </div>
            <div className="mt-7 mb-10 space-y-5 xl:w-3/4">
                <h6 className="font-productsans-bold text-lg">Celebrate in style with Pluck Collections.</h6>
                <p className="andie-description-text leading-tight">Collections are fixed and <b>Ready To Order</b> packages for special events such as Happy Hours, holidays such as Thanksgiving, Lunar New Year, Black History Month, or events with lower budgets or headcounts such as Client Meetings and Meeting refreshments.</p>
                <p className="andie-description-text leading-tight">Each collection is carefully crafted by our team and in partnership with our top restaurants and caterers, and contains unique individual packages that are designed to provide different values at their respective prices.</p>
                <div className="pt-5">
                    <h6 className="font-roboto-medium text-sm mb-2 text-blue-600">How to order a collection:</h6>
                    <div className="space-y-3">
                        {ordercollection.map((item, index) => (
                            <div className="flex items-center flex-shrink-0 space-x-3">
                                <div className="h-7 w-7 flex flex-shrink-0 items-center justify-center text-white font-productsans-regular bg-blue-600 rounded-full">
                                    {index+1}
                                </div>
                                <p className="andie-description-text leading-tight">{item}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}