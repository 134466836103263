import React, { useState } from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import Modal from "react-responsive-modal";
import logo from "../../assets/images/andielogo_white.png"
import productSansRegular from "../../assets/fonts/ProductSans-Regular.ttf"
import productSansMedium from "../../assets/fonts/ProductSans-Medium.ttf"
import { PopoutModal, dietaryLabels } from '../common';

Font.register ({family: "Product Sans Regular", src: productSansRegular})
Font.register ({family: "Product Sans Medium", src: productSansMedium})
const styles = StyleSheet.create({
    modal: {
		backgroundColor: 'white',
		boxShadow: 'none',
		overflow: 'none',
		padding: '0',
		margin: '0',
		width: "40%",
		height: '85%',
		justifyContent: 'center',
        borderRadius: '2px'
	},
    page: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: 20,
    },
    box: {
        width: '61%',
        height: 225,
        marginBottom: 45,
        border: '1pt solid white',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#3B82F6',
        position: 'relative',
    },
    box2: {
        width: '95%',
        height: 160,
        marginBottom: 30,
        marginTop: 5,
        border: '1pt solid white',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        },

    itemName: {
        fontSize: 20,
        fontWeight: 'bold',
        marginTop: 20,
        textAlign: 'center',
        color: '#FFFFFF',
        fontFamily: "Product Sans Medium",
    },
    description: {
        fontSize: 12,
        marginTop: 5,
        marginHorizontal: 25,
        textAlign: 'center',
        color: '#FFFFFF',
        fontFamily: "Product Sans Regular",
    },
    logo: {
        position: 'absolute',
        bottom: 4,
        right: 10,
        width: 65,
    },
    dietary:{
        fontSize: 14,
        position: 'absolute',
        color: '#FFFFFF',
        right: 5,
        bottom: 2,
        fontFamily: "Product Sans Medium",
    }
});

export default function LabelBidResponse({ response, admin, subsection }) {
    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    const splitItemsIntoPages = (items) => {
        const pageCount = Math.ceil(items.length / 6);
        const pages = [];

        for (let i = 0; i < pageCount; i++) {
        const start = i * 6;
        const end = start + 6;
        const itemsOnPage = items.slice(start, end);
        pages.push(itemsOnPage);
        }

        return pages;
    };

    function renderDietaryRestriction(item){
        let st = ""
        item?.map((restriction) => {
            if (dietaryLabels[restriction]) {
                st += dietaryLabels[restriction] + " "
            }
        })
        return st
    }

    const renderItems = (items) => {
        return items.map((item, index) => (
        <View style={styles.box} key={index}>
            <View style={styles.box2} key={index}>
                <Text style={styles.itemName}>{item.name}</Text>
                <Text style={styles.description}>{item.description}</Text>
                <Text style={styles.dietary}>{renderDietaryRestriction(item.dietary_option)}</Text>
            </View>
            <Image src={logo} style={styles.logo} />
        </View>
        ));
    };

    const renderPages = () => {
        const items = response.items;
        const pages = splitItemsIntoPages(items);

        return pages.map((pageItems, pageIndex) => (
        <Page size="A4" style={styles.page} key={pageIndex}>
            {renderItems(pageItems)}
        </Page>
        ));
    };

    return (
        <div>
            <PopoutModal
                title={"Item Labels"}
                button={
                    admin == true ?
                    <div
                        className="cursor-pointer text-paragraph font-productsans-medium andie-dark-button"
                    >
                        Print item labels
                    </div>
                    :
                    <div
                        className={`andie-text-button ${subsection && "border-b-0"}`}
                    >
                        Print item labels
                    </div>
                }
            >
                
                <div className="min-h-[70vh] min-w-[50vw] pb-4">
                    <PDFViewer className="w-full max-h-[80vh]" height={window.innerHeight}>
                        <Document>{renderPages()}</Document>
                    </PDFViewer>
                </div>
            </PopoutModal>
        </div>
    );
}
