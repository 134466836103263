import React from 'react'
import { Template } from './../../template';
import { LoadingCollectionsExplore } from '../../common';

const AllTemplates = ({ filteredSelection, loading }) => {

    return (
        <div>
            <div className="flex items-end justify-between">
                <div>
                    <h5 className="section-header">All Collections</h5>
                    <p className="andie-section-descriptor mb-0">Explore our entire collection of CGE Collections.</p>
                </div>
            </div>
            <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-3.5">
                {loading ?
                        <LoadingCollectionsExplore />
                    :
                    filteredSelection.length <= 0 ?
                        <div className="flex items-center col-span-2 col-start-2 h-full justify-center font-roboto-medium">No collections under this category.</div>
                        :
                        filteredSelection.map((item, index) => {
                            return <Template item={item} key={index} />;
                        })
                }
                {Array.from({ length: 4 } , () => <div className="bg-slate-100 mt-1.5 w-full h-32 lg:h-40 flex items-center text-blue-400 justify-center andie-description-text rounded-md font-roboto-medium">Launching April. 17th</div>)} 
                
            </div>
        </div>
    )
}

export default AllTemplates;