const debug = false;

let main_apiEndpoint;
let cge_apiEndpoint;
let business_apiEndpoint;
let cge_frontend;

if (debug === true) {
    main_apiEndpoint = `http://localhost:8000/`;
    cge_apiEndpoint = `http://localhost:8001/`;
    business_apiEndpoint = 'http://localhost:8002/';

    cge_frontend = 'http://localhost:3000/';
} else {
    main_apiEndpoint = `https://wallepy-main.azurewebsites.net/`;
    cge_apiEndpoint = `https://wallepy-cge.azurewebsites.net/`;
    business_apiEndpoint = 'https://wallepy-business.azurewebsites.net/';

    cge_frontend = 'https://app.pluckbids.com/'
}

export { main_apiEndpoint, cge_apiEndpoint, business_apiEndpoint, cge_frontend }
