import React, { useEffect, useState } from "react";

import {
    TemplateInformation, PackageViewer, TemplateImage, TemplateTitleAndPrice, TemplateCard, TemplateOrderForm
} from "."
import { PopoutModal, calulatePackageMaxAndMin, menuoptions, pricetoString } from "../common";
import { AdminTemplateForm } from "../admin";
import { BiddingAPIs } from "../../apis";
import { toast } from "react-hot-toast";
import { isAuthenticated } from "../../apis/Credentials";
import { CubeIcon, InformationCircleIcon, ShieldCheckIcon } from "@heroicons/react/solid";

export default function Template({ item, highlight, noteworthy, page, popular }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState({})
    const [packageItems, setPackageItems] = useState([]);
    const [orderInProgress, setOrderInProgress] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [isEditOpen, setIsEditOpen] = useState(false);

    useEffect(() => {
        if (selectedPackage && selectedPackage.title) {
            setButtonDisabled(false)
        }
    }, [selectedPackage])


    function renderPrice(packages, frommodal) {
        const packagePrices = packages.map(pkg => calulatePackageMaxAndMin(pkg.items, pkg.pricing, pkg.package_price))

        let pricesList = []
        for (const prices of packagePrices) {
            if (prices.max >= 0) pricesList.push(prices.max)
            if (prices.min >= 0) pricesList.push(prices.min)
            if (prices.total >= 0) pricesList.push(prices.total)
        }

        if (Math.max(...pricesList) === Math.min(...pricesList)) {
            return (<p className="text-green-600 font-roboto-bold text-paragraph">{"$" + pricetoString(Math.max(...pricesList))} {frommodal && <small className="text-gray-500 uppercase font-roboto-medium"> / package</small>}</p>)
        } else {
            return (<p className="text-green-600 font-roboto-bold text-paragraph">{"$" + pricetoString(Math.min(...pricesList)) + " - $" + pricetoString(Math.max(...pricesList))} {frommodal && <small className="text-gray-500 uppercase font-roboto-medium"> / package</small>}</p>)
        }
    }

    function renderRating(rating) {
        let display = [<div className="h-2 w-2 bg-blue-600 rounded-full" />,
        <div className="h-2 w-2 bg-blue-600 rounded-full" />,
        <div className="h-2 w-2 bg-blue-600 rounded-full" />,
        <div className="h-2 w-2 bg-blue-100 rounded-full" />,
        <div className="h-2 w-2 bg-blue-100 rounded-full" />];
        display = display.map((ratingBubble, index) => {
            if (parseInt(rating) < index + 1) {
                return <div className="h-2 w-2 bg-blue-100 rounded-full" />
            } else {
                return <div className="h-2 w-2 bg-blue-600 rounded-full" />
            }
        })
        return display
    }

    async function deleteTemplate() {
        const res = await BiddingAPIs.delete_collection_and_package(item.id)
    }

    return (
        <>
            <PopoutModal
                modalID={'explore_template_' + String(item.id)}
                button={
                    <TemplateCard
                        renderPrice={renderPrice}
                        renderRating={renderRating}
                        item={item}
                        highlight={highlight}
                        noteworthy={noteworthy}
                        popular={popular}
                    />
                }
                title={
                    <TemplateTitleAndPrice
                        renderPrice={renderPrice}
                        item={item}
                        orderInProgress={orderInProgress}
                    />
                }

                footerElements={
                    <div className="flex sm:py-3 flex-col bg-white sm:flex-row w-full sm:justify-end space-y-1 sm:space-y-0 sm:space-x-5 sm:items:center">
                        {page == "admin" ?
                            <div className="flex w-full justify-between space-y-1">
                                <button className={"andie-dark-button w-48 bg-red-600"} onClick={() => deleteTemplate()}>Delete</button>
                                <PopoutModal
                                    title={"Edit Collection"}
                                    button={<div className="andie-dark-button w-48">Edit Collection</div>}
                                    modalID={'create_template_form_' + String(item.id)}

                                >
                                    <AdminTemplateForm
                                        loadingSubmit={false}
                                        setModalOpen={(e) => setIsEditOpen(e)}
                                        data={item}
                                        editing={true}
                                    />

                                </PopoutModal>
                            </div>
                            :
                            <>
                                <TemplateOrderForm item={item} orderInProgress={orderInProgress} setOrderInProgress={setOrderInProgress} setPackageItems={(e) => setPackageItems(e)} packageItems={packageItems} selectedPackage={selectedPackage} setIsOpen={(e) => setIsOpen(e)} isOrderNowButtonDisabled={buttonDisabled} />
                            </>
                        }
                    </div>
                }
            >
                <div className="flex h-full justify-between flex-col">

                    <div className="h-full scrollbar-hide">
                        <TemplateImage item={item} />
                        <div className="mt-4 py-4 border-t">
                            <div className="flex space-x-2.5 mb-4 items-center">
                                <InformationCircleIcon className="h-5" />
                                <h6 className="bid-details-header">About this package</h6>
                            </div>
                            
                            <p className="andie-description-text leading-tight">{item.description}</p>
                            <div className="md:w-4/5 lg:w-3/4">
                                <TemplateInformation
                                    item={item}
                                />
                            </div>
                        </div>
                        <div className="mt-4 py-4 border-t">
                            <div className="flex space-x-2.5 mb-4 items-center">
                                <CubeIcon className="h-5" />
                                <div className="flex items-center space-x-1">
                                    <h6 className="bid-details-header">Packages</h6>
                                    <div className="bg-red-600 h-4 w-4 text-white font-roboto-medium text-xs flex items-center justify-center rounded-full">{item.packages.length}</div>
                                </div>
                            </div>
                            <PackageViewer
                                item={item}
                                selectedPackage={selectedPackage}
                                setSelectedPackage={setSelectedPackage}
                            />
                        </div>
                    </div>
                </div>
            </PopoutModal>
        </>
    )
}