import { useState } from "react";

export default function AdminBusinessForm({ createUser, editUser, type, setFirstname, firstname, setLastname, lastname, setEmail, email, setPhone, phone, setDepartmentName, department, setRole, role, adminLevel, setAdminLevel }) {
    const [adminUser, setAdminUser] = useState(JSON.parse(window.localStorage.getItem('admin_user_details')));

    return (
        <form class="space-y-4" onSubmit={type == "edit" ? (e) => editUser(e) : (e) => createUser(e)}>
            <div className="flex flex-row grid grid-cols-2 gap-12">
                <div>
                    <label class="label">
                        <span class="text-base label-text">First Name</span>
                    </label>
                    <input onChange={(e) => setFirstname(e.target.value)} value={firstname} type="text" placeholder="First Name" class="w-full bid-form-input" required />
                </div>
                <div>
                    <label class="label">
                        <span class="text-base label-text">Last Name</span>
                    </label>
                    <input onChange={(e) => setLastname(e.target.value)} value={lastname} type="text" placeholder="Last Name" class="w-full bid-form-input" required />
                </div>
            </div>
            <div className="flex flex-row grid grid-cols-2 gap-12">
                <div>
                    <label class="label">
                        <span class="text-base label-text">Email</span>
                    </label>
                    <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder="Email" class="w-full bid-form-input" required />
                </div>
                <div>
                    <label class="label">
                        <span class="text-base label-text">Phone</span>
                    </label>
                    <input onChange={(e) => setPhone(e.target.value)} value={phone} type="text" placeholder="Phone" class="w-full bid-form-input" required />
                </div>
            </div>
            <div className="flex flex-row grid grid-cols-2 gap-12">
                <div>
                    <label class="label">
                        <span class="text-base label-text">Department</span>
                    </label>
                    <input onChange={(e) => setDepartmentName(e.target.value)} value={department} type="text" placeholder="Partner Business Name" class="w-full bid-form-input" required />
                </div>
                <div>
                    <label class="label">
                        <span class="text-base label-text">Role</span>
                    </label>
                    <input onChange={(e) => setRole(e.target.value)} value={role} type="text" placeholder="Job Title e.g. Owner, Manager, etc." class="w-full bid-form-input" required />
                </div>
            </div>

            <div className="flex flex-row grid grid-cols-2 gap-12">
                <label class="label">
                    <span class="text-base label-text">Admin Level</span>
                </label>
                <select onChange={(e) => setAdminLevel(e.target.value)} className="select select-bordered w-full max-w-xs">
                    <option disabled>Admin Level (Lower level has more privileges)</option>
                    <option disabled={adminUser?.admin_level > 0} selected={adminLevel == 0} value={0}>0 (Highest Access Level: Developer Leads ONLY) </option>
                    <option disabled={adminUser?.admin_level > 1} selected={adminLevel == 1} value={1}>1 (Higher) </option>
                    <option disabled={adminUser?.admin_level > 2} selected={adminLevel == 2} value={2}>2</option>
                    <option disabled={adminUser?.admin_level > 3} selected={adminLevel == 3} value={3}>3</option>
                    <option disabled={adminUser?.admin_level > 4} selected={adminLevel == 4} value={4}>4</option>
                    <option disabled={adminUser?.admin_level > 5} selected={adminLevel == 5} value={5}>5</option>
                    <option disabled={adminUser?.admin_level > 6} selected={adminLevel == 6} value={6}>6</option>
                    <option disabled={adminUser?.admin_level > 7} selected={adminLevel == 7} value={7}>7</option>
                    <option disabled={adminUser?.admin_level > 8} selected={adminLevel == 8} value={8}>8</option>
                    <option disabled={adminUser?.admin_level > 9} selected={adminLevel == 9} value={9}>9 (Lower) </option>
                    <option disabled={adminUser?.admin_level > 10} selected={adminLevel == 10} value={10}>10 (Lower) </option>
                </select>
            </div>

            <div className="flex justify-end">
                <button type='submit' className="btn-xs rounded-sm text-white bg-blue-600">{type == "edit" ? "Edit Staff User" : "Create Staff User"}</button>
            </div>
        </form>
    )
}