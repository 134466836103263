import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";

import { BiddingAPIs } from "../../../apis";

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        width: "30%",
        height: '58%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

export default function BiddingIssue() {
    const user = JSON.parse(window.localStorage.getItem('user_details'));

    const [isOpen, setIsOpen] = useState(false);
    const [issueTitle, setIssueTitle] = useState("");
    const [issueDescription, setIssueDescription] = useState("");
    const business_type = user.account_type
    function toggleModal() {
        setIsOpen(!isOpen);
    }

    const submitTicket = async () => {
        const payload = {
            title: issueTitle,
            description: issueDescription,
            businessname: user.businessname,
            business_type: business_type,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone
        }


        await BiddingAPIs.supportTicket(payload)

        setIssueTitle("");
        setIssueDescription("");
        toggleModal();
    }

    return (
        <>
            <div onClick={toggleModal} className="cursor-pointer py-1 px-2 hover:bg-gray-200 hover:rounded">
                <h6 className="text-largescreen text-blue-500 font-productsans-medium">Report an issue</h6>
            </div>
            <Modal
                blockScroll={false}
                open={isOpen}
                onClose={toggleModal}
                animationDuration={400}
                showCloseIcon={false}
                styles={styles}
                center
            >
                <div className='h-full flex flex-col relative pt-4 overflow-y-hidden'>
                    <div className="flex px-4 border-b border-gray-100 pb-3 justify-between">
                        <div>
                            <h5 className="text-base font-productsans-medium text-gray-500">Support Ticket</h5>
                            <p className="text-tiny sf-regular text-gray-400">Report an issue</p>
                        </div>

                        <div onClick={toggleModal} className="cursor-pointer h-5 w-5 rounded-full flex items-center justify-center bg-gray-100">
                            <XIcon className="h-3 text-gray-500" />
                        </div>
                    </div>
                    <div className="pt-2 pb-8 px-4 flex flex-col">
                        <p className="mt-3 text-xs font-semibold text-gray-500">Issue</p>
                        <input
                            className="mt-2.5 w-full placeholder-gray-400 andie-description-text leading-tight text-gray-500 border-2 rounded border-gray-100"
                            id="cus_bid_title"
                            onChange={(e) => setIssueTitle(e.target.value)}
                            type="text"
                            required
                            placeholder="E.g. Can't save bid"
                            maxLength={50}
                            aria-label="Bid Title"
                            value={issueTitle}
                        />
                        <p className="mt-6 text-xs font-semibold text-gray-500">Description</p>
                        <textarea
                            className="mt-2.5 w-full h-36 placeholder-gray-400 andie-description-text leading-tight text-gray-500 border-2 rounded border-gray-100"
                            id="cus_description"
                            onChange={(e) => setIssueDescription(e.target.value)}
                            type="text"
                            required
                            placeholder="E.g. My bid is not being displayed when I save it, each time it keeps telling there is an error and I need to contact support."
                            maxLength={250}
                            aria-label="Description"
                            value={issueDescription}
                        />
                        <div className="flex justify-end">
                            <div onClick={submitTicket} className="cursor-pointer mt-6 flex items-center justify-center bg-blue-600 text-xs font-productsans-medium text-white h-8 w-28 rounded px-2.5">
                                Submit Ticket
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}