import { ArrowLeftIcon, EyeIcon } from "@heroicons/react/solid"
import BidCancellationButton from "./BidCancellationButton"
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function BidTopBar({ bid }) {
    const navigate = useNavigate();

    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user_details')));

    function goBack() {
        navigate(-1)
    }

    return (
        <div className={`hidden md:inline-flex w-full mt-1.5 items-center justify-between md:h-10 px-3 md:px-6`}>
            <div className="cursor-pointer" onClick={() => goBack(false)}>
                <ArrowLeftIcon className="h-4 text-gray-500" />
            </div>
            {
                (user !== null) && (user.email == bid.cge_email) ?
                    <BidCancellationButton bid={bid} />
                    :
                    <div className="flex space-x-5 items-center">
                        <div className="flex space-x-1 items-center">
                            <EyeIcon className="h-4 text-gray-500" />
                            <p className="text-[11px] andie-description-text text-gray-500">{bid.views} views</p>
                        </div>
                    </div>
            }
        </div>
    )
}