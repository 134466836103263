import { CheckIcon, MinusCircleIcon, PencilAltIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid';
import React from 'react';
import { pricetoString } from '../../common';

export default function ResponseFormItem({ item, index, editingID, setEditingID, deleteItem }) {
    const editing = false
    const editIndex = -1
    return (
        <div className={`mt-3 flex p-3 bg-gray-100 rounded-md ${(editingID == item.id) && "border-2 border-gray-400"}`}>
            {/* <div className="w-6 h-6 text-white text-xs font-roboto-medium flex items-center justify-center flex-shrink-0 mr-2 mt-1 rounded-full bg-gray-700">
                {item.quantity}
            </div> */}
            <div className="flex flex-col w-full">
                <div className="flex w-full justify-between">
                    <div className="w-4/5">
                        <h6 className="text-gray-700 font-roboto-medium text-paragraph break-words">{item.name}</h6>
                        {/* <div className='w-full justify-center'><div className=' border-b border-3 my-2 mx-10'></div></div> */}
                        <p className="mt-1 andie-description-text leading-tight break-words ">{item.description}</p>
                    </div>
                    <div className="w-1/5">
                        <div className="flex justify-end text-base font-roboto-medium text-[#009473]">${pricetoString(item.price)}</div>
                        <div className="flex justify-end text-xs font-roboto-medium ">{"Qty: " + item.quantity}</div>
                        <div className="flex justify-end text-xs font-roboto-medium ">{item.serves == null ? null : "Serves: " + item.serves}</div>
                    </div>

                </div>
                <div className="mt-3 flex">
                    <div className="w-4/5">
                        <p className="font-roboto-medium text-xs text-gray-500">{item.menuoption}</p>
                        <div className="mt-1 space-x-2 max-w-4 flex">
                            {item?.dietary_option.filter((i) => (i != "None")).map(e =>
                                <div className="flex space-x-1.5 text-xs font-roboto-regular text-gray-500"><CheckIcon className="text-green-500 h-4" />{e}</div>
                            )}

                        </div>
                    </div>
                    <div className="w-1/5 flex justify-end items-end space-x-2">
                        <div onClick={() => {setEditingID(item.id)}} className="cursor-pointer border p-0.5 rounded-full border-blue-600 text-blue-600">
                            <PencilIcon className="w-3.5 h-3.5" />
                        </div>
                        <div onClick={() => deleteItem(item.id)} className="cursor-pointer border p-0.5 rounded-full border-red-500 text-red-500">
                            <TrashIcon className="w-3.5 h-3.5" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
