import React, { useState } from "react";
import moment from 'moment';
import { BadgeCheckIcon, NewspaperIcon, StarIcon, UserGroupIcon } from "@heroicons/react/solid";
import { BookmarkIcon as BookmarkIconFull } from '@heroicons/react/solid';
import { BookmarkIcon } from '@heroicons/react/outline';
import { CGETypeImage, calculateBudget, moneySignText, pricetoString } from "../../../components/common";
import { AccountsAPI, BiddingAPIs } from "../../../apis";
import { useNavigate } from "react-router-dom";

export default function BidDisplay({ bid, homepage, index, screenSize, selectedIndex, setSelectedIndex }) {
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user_details')));
    const userType = JSON.parse(window.localStorage.getItem('user_type'));
    const navigate = useNavigate();

    const [loadingSave, setLoadingSave] = useState(false);

    function renderLock(bid) {
        let total = 0
        total = pricetoString(calculateBudget(bid.budget.total, 1))
        return moneySignText(total)
    }

    function showFullBid(transaction_code) {
        navigate('/bid/' + transaction_code);
    }

    const handleBidSelect = (bid, index) => {
        if (screenSize.width < 768) {
            showFullBid(bid.transaction_code)
        } else {
            // setSelectedBid(bid)
            setSelectedIndex(index)
        }
    }

    const handleSaveBid = async (bid_id, save) => {
        if (loadingSave == false) {
            setLoadingSave(true)
            const payload = {
                'bid_id': bid_id
            }
            let tempUser = user
            if (save) {
                await BiddingAPIs.saveBid(payload, user.account_type);
                tempUser.saved_bids?.push(bid_id.toString())
                localStorage.setItem('user_details', JSON.stringify(tempUser))
                setUser(tempUser)
            } else {
                await BiddingAPIs.unsaveBid(payload, user.account_type);
                let savedBidsArr = []
                tempUser.saved_bids?.map((bid) => {
                    if (bid_id != bid) {
                        savedBidsArr.push(bid)
                    }
                })
                tempUser.saved_bids = savedBidsArr
                localStorage.setItem('user_details', JSON.stringify(tempUser))
                setUser(tempUser)
            }
            AccountsAPI.VerifyUserOnLogin();
        }
        setLoadingSave(false)
    }

    return (
        <div onClick={() => { handleBidSelect(bid, index) }} className={`h-56 md:h-40 border-2 border-gray-100 rounded-md p-2.5 cursor-pointer ${homepage ? 'w-[330px]' : 'w-full'} md:w-full flex flex-col shrink-0 justify-between ${selectedIndex === index && "bg-blue-50 border-0"}`} >
            <div>
                <div className="flex space-x-2">
                    <div className={`bg-blue-50 h-11 w-11 md:h-10 md:w-10 flex items-center justify-center flex-shrink-0 rounded-md ${selectedIndex === index && "bg-indigo-100"}`} >
                        <CGETypeImage type={bid.cge_type} page={"SimilarBids"} />
                    </div>
                    <div className="w-full">
                        <div className="flex justify-between">
                            <div className="w-[85%]">
                                <h5 className={`font-productsans-bold text-base line-clamp-1 md:text-sm ${selectedIndex === index ? "text-blue-600" : "text-gray-700"}`}>{bid.bid_title}</h5>
                            </div>
                            
                            {user !== null ?
                                user.saved_bids?.includes(bid.id.toString()) ?
                                    <BookmarkIconFull onClick={() => handleSaveBid(bid.id, false)} className="h-[18px] md:h-4 text-gray-600" />
                                    : <BookmarkIcon onClick={() => handleSaveBid(bid.id, true)} className="h-[18px] md:h-4 text-gray-600" />
                                : <BadgeCheckIcon className="h-[18px] md:h-4 flex-shrink-0 text-green-700" />
                            }
                        </div>
                        <div className="-mt-0.5 md:mt-0.5 flex items-center space-x-2">
                            <p className="font-roboto-bold text-xs text-gray-500">{bid.event_type}</p>
                            <div className="h-1 w-1 rounded-full bg-gray-400" />
                            <div className="flex items-center">
                                <p className="text-xs font-roboto-medium text-gray-800">{bid.restaurant_rating}</p>
                                <StarIcon className="h-4 text-yellow-400" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3 flex items-center space-x-2">
                    <p className="text-xs font-roboto-medium text-gray-400">{bid.cge_type}</p>
                    <div className="flex items-center space-x-1">
                        <NewspaperIcon className="h-4 text-gray-700" />
                        <p className="text-xs text-gray-600 font-roboto-medium">{bid.responses.length} responses</p>
                        {/* <p className="text-xs text-gray-600 font-roboto-medium">{Math.floor(Math.random() * (10 - 4 + 1)) + 4} responses</p> */}
                    </div>
                    <div className="md:hidden flex space-x-1 items-center">
                        <UserGroupIcon className="h-4 -mt-1" />
                        <p className="text-xs leading-none text-gray-600 font-roboto-medium">{bid.guest_count} guests</p>
                    </div>
                </div>
                <div className="hidden md:inline-flex mt-2 space-x-1 items-center">
                    <UserGroupIcon className="h-4 -mt-1" />
                    <p className="text-xs leading-none text-gray-600 font-roboto-medium">{bid.guest_count} guests</p>
                </div>
            </div>

            <p className="md:hidden andie-description-text line-clamp-3 text-gray-500 leading-tight">{bid.bid_description}</p>
            <div className="flex justify-between items-end">
                {/* Use the correct budget variable from the backend. This is just for example only */}
                <div>
                    {user !== null ?
                        <h6 className="text-base md:text-sm font-productsans-bold text-green-600">${pricetoString(calculateBudget(bid.budget.total, 1))}</h6>
                        :
                        <h6 className="text-base md:text-sm font-productsans-bold text-green-600">{renderLock(bid)}</h6>
                    }
                    <p className="font-productsans-medium leading-none text-gray-600 text-xs lg:text-tiny uppercase">{bid.currency}</p>
                </div>
                <div>
                    <p className="text-xs font-roboto-medium text-gray-400">{moment(new Date(bid.event_date_time)).fromNow()}</p>
                </div>
            </div>
        </div>
    )
}