import React from "react";
import { SidebarRight } from "../../components/navigation";

export default function Disclaimer() {
    return (
        <div className="h-screen">
            <div className="flex h-[calc(100%_-_40px)]">
                <div className="andie-side-panel" /> 
                <div className="andie-main-panel overflow-y-scroll">
                    <p className="andie-description-text text-xs">3mins Read</p>
                    <h1 className="text-xl font-productsans-bold">Dislaimer</h1>
                    <div className="my-1 h-1 w-16 bg-gray-700" />

                    <h4 className="mt-10 text-center mx-auto max-w-xl font-productsans-regular text-lg">
                    We provide the Pluck Platform and all Content “as is” without warranty of any kind and we disclaim all warranties, whether express or implied. For example: (i) we do not endorse or warrant the existence, conduct, performance, safety, quality, legality, or suitability of any Restaurant, Caterer, Organization, Bid, Response, Package, or third party; (ii) we do not warrant the performance or non-interruption of the Pluck Platform; and (iii) we do not warrant that verification, identity or background checks conducted on Bids, Responses, or Users (if any) will identify past misconduct or prevent future misconduct. Any references to a User or Bid being "verified" (or similar language) indicate only that the User or Bid or Response or Pluck has completed a relevant verification or identification process and nothing else. These disclaimers apply to the maximum extent permitted by law. If you have statutory rights or warranties we cannot disclaim, the duration of any such statutorily required rights or warranties will be limited to the maximum extent permitted by law.
                    </h4>
                </div>
                <SidebarRight />
            </div>
        </div>
    )
}