import React, { useState, useEffect } from "react";
import { BiddingAPIs } from "../../apis";
import moment from "moment";
import { TransactionReceipt } from "../../components/transactions";
import { LabelBidResponse, ResponseDocumentsDownload } from "../../components/responses";
import { getTransactionAmounts, pricetoString } from "../../components/common";
import ReactDatePicker from "react-datepicker";
import { toUpper } from "lodash";

const tabs = [
    "All",
    "Accepted",
    "Confirmed",
    "Completed and Paid"
]

export default function TransactionManagement({ parentLoading, getCGETransactions, originalTransactions, cgeAccountList, staffAccountList, businessAccountList }) {
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [selectedTab, setSelectedTab] = useState("All");
    const [selectedIndex, setSelectedIndex] = useState(0);
    var user = JSON.parse(window.localStorage.getItem('admin_user_details'));

    useEffect(() => {
        transactions.map((transaction) => {
            transaction['paymentDate'] = moment.now()
        })
    }, [selectedTab, originalTransactions])

    useEffect(() => {
        if (
            parentLoading[6] ||
            parentLoading[0] ||
            parentLoading[1] ||
            parentLoading[2]
        ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [parentLoading])


    useEffect(() => {
        if (selectedTab == "Completed and Paid") {
            let temp = []
            originalTransactions.forEach((transaction) => { if (transaction.status == "Completed and Paid") { temp.push(transaction) } })
            setTransactions(temp)
        } else if (selectedTab == "Accepted") {
            let temp = []
            originalTransactions.forEach((transaction) => { if (transaction.status == "Accepted") { temp.push(transaction) } })
            setTransactions(temp)
        } else if (selectedTab == "Confirmed") {
            let temp = []
            originalTransactions.forEach((transaction) => { if (transaction.status == "Confirmed") { temp.push(transaction) } })
            setTransactions(temp)
        } else {
            setTransactions(originalTransactions)
        }
    }, [selectedTab, originalTransactions])


    const CorporateCode = 1
    const PartnerCode = 2

    let steps = ["Pending", "Accepted", "Confirmed", "Completed and Paid"];

    useEffect(() => {
        if (user !== null) {
            getData();
        }
    }, [refresh]);

    async function getData() {
        getCGETransactions();
    }

    function getDateFormatted(trans) {
        const val = trans?.bid.event_date_time
        const date = moment(new Date(val)).format("MM/DD/YYYY")
        return date
    }

    function handlePaymentDate(date, index) {
        setSelectedIndex(index)
        let temp = transactions
        temp[index]['paymentDate'] = moment(date)
        setTransactions([...temp])
    }


    return (
        <div className="h-full">
            <div className="h-8 p-1 mb-4 bg-gray-100 rounded-md grid grid-cols-4 space-x-2 ">
                {tabs.map((tab, index) => (
                    <div onClick={() => setSelectedTab(tab)} className={`${selectedTab === tab && "bg-white text-blue-600"} text-gray-600 cursor-pointer rounded-md px-3 flex font-productsans-medium text-sidebar items-center justify-center`}>
                        {tab}
                    </div>
                ))}
            </div>

            {loading ?
                                <div className="flex flex-col space-y-5 w-full h-full items-center justify-center">
                                <span className="loading loading-spinner loading-lg"></span>
                                <p className="font-productsans-medium">Loading Transactions</p>
                            </div>
            
                :
                <div className="h-full space-y-1 pb-28 overflow-scroll scrollbar-hide">
                    {transactions.length > 0 &&
                        transactions.map((transaction, index) => (
                            <div className="flex w-full p-5 space-x-5 align-middle items-center border rounded-md justify-between">
                                {/* <div className="w-[15%]">Transaction #{transaction.id}</div> */}
                                <div className="w-[70%] text-left ">
                                    <p><span className="font-productsans-medium">Response #{transaction?.response.id}</span> ({transaction?.response.restaurant_name})</p>
                                    <p><span className="font-productsans-medium">Bid #{toUpper(transaction?.bid.transaction_code)}</span> ({transaction?.bid.organization})</p>
                                </div>

                                <div className="w-[20%] text-right font-productsans-bold text-green-700">
                                    <p>PO: ${pricetoString(getTransactionAmounts(transaction.response.items, transaction.tipAmount, transaction.delivery_fee, transaction.business_delivers, transaction.bid.cge_service_fee, transaction.response.business_service_fee, 2).total)}</p>
                                    <p>Invoice: ${pricetoString(getTransactionAmounts(transaction.response.items, transaction.tipAmount, transaction.delivery_fee, transaction.business_delivers, transaction.bid.cge_service_fee, transaction.response.business_service_fee, 1).total)}</p>
                                </div>
                                <div className="w-[10%]"><ResponseDocumentsDownload
                                    staffAccountList={staffAccountList}
                                    businessAccountList={businessAccountList}
                                    cgeAccountList={cgeAccountList}
                                    response={transaction?.response}
                                /></div>
                            </div>

                        ))
                    }
                </div>
            }


        </div>
    )
}