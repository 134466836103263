import './App.css';
import 'react-responsive-modal/styles.css';

import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { Account, AccountForgotPassword, AccountPasswordReset, Login, Signup } from './pages/account';
import { CreateBid, Home } from './pages/explore';
import { Activity } from './pages/activity';
import { Messages } from './pages/messaging';
import { AndieGuarantee, Bid, BudgetCalculator, Disclaimer, PrivacyPolicy, Support, Sustainability, TermsOfUse, TransactionView, Tutorials } from './pages/independent'
import { Admin } from './pages/admin';
import { RequestAccessCodeSuccess } from './pages/other';
import PrebuiltResponse from './components/responses/PrebuiltResponses';
import PaymentLanding from './pages/independent/PaymentLanding';
import { Header } from './components/navigation';

function App() {
  useEffect(() => {
    const script = document.createElement("script")

    script.src =
      "https://maps.googleapis.com/maps/api/js?key=" + process.env.REACT_APP_GOOGLE_API_KEY + "&libraries=places&callback=Function.prototype"

    document.body.appendChild(script)
    document.body.classList.add('is-scrollLocked')
    document.body.classList.add('is-momentumScrollable')



    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script)
    }
  }, [])

  // useEffect(() => {
  //   const disableRightClick = (e) => {
  //     e.preventDefault();
  //   };

  //   document.addEventListener('contextmenu', disableRightClick);

  //   return () => {
  //     document.removeEventListener('contextmenu', disableRightClick);
  //   };
  // }, []);


  return (
    <Router basename="/">
      <Toaster position='top-right' />
      <Header bypassToken={true} />
      <Routes>
        <Route path={"/success-request"} exact element={<RequestAccessCodeSuccess />} />
        {/* <Route path={"/websocket"} exact element={<WebSocketInner />} /> */}

        <Route path={"/9CzFO4REGlf93l2bDHEnpqjDg/dashboard/*"} exact element={<Admin />} />
        {/* <Route path={"/231754-trn-cge/dashboard/admin-accounts"} exact element={<AdminAdminAccounts />} />
        <Route path={"/231754-trn-cge/dashboard/cge-requests"} exact element={<AdminCGERequests />} />
        <Route path={"/231754-trn-cge/dashboard/cge-users"} exact element={<AdminCGEUsers />} />
        <Route path={"/231754-trn-cge/dashboard/cge-transactions"} exact element={<AdminCGETransactions />} />
        <Route path={"/231754-trn-cge/dashboard/cge-bids"} exact element={<AdminCGEBids />} />
        <Route path={"/231754-trn-cge/dashboard/cge-templates"} exact element={<AdminCGETemplates />} />
        <Route path={"/231754-trn-cge/dashboard/cge-ordernow"} exact element={<AdminCGEOrderNow />} />
        <Route path={"/231754-trn-cge/dashboard/business-users"} exact element={<AdminBusinessUsers />} /> */}

        <Route path={"/cge/create-a-bid/*"} exact element={<CreateBid />} />
        <Route path={"/bid/:transaction_code/"} exact element={<Bid />} />
        <Route path={'/transaction/:transaction_code'} exact element={<TransactionView />} />
        <Route path={'/prebuilt-response/:business_user/:bid_id'} exact element={<PrebuiltResponse/>} />

        <Route path={'/policies/andie-guarantee'} exact element={<AndieGuarantee />} />
        <Route path={'/policies/privacy-policy'} exact element={<PrivacyPolicy />} />
        <Route path={'/policies/terms-of-use'} exact element={<TermsOfUse />} />
        <Route path={'/policies/disclaimer'} exact element={<Disclaimer />} />
        <Route path={'/support'} exact element={<Support />} />
        <Route path={'/how-pluck-is-helping-build-a-better-future'} exact element={<Sustainability />} />
        <Route path={'/pluck-ideal-budget-calculator'} exact element={<BudgetCalculator />} />
        <Route path={'/tutorials-and-guides'} exact element={<Tutorials />} />
        
        <Route path={'/account'} exact element={<Account />} />
        <Route path={'/activity/*'} exact element={<Activity />} />
        <Route path={'/payment/*'} exact element={<PaymentLanding />} />
        <Route path={"/messages"} exact element={<Messages />} />
        <Route path={"/login"} element={<Login />} />
        <Route path={'/forgot-password'} exact element={<AccountForgotPassword />} />
        <Route path={'/password/reset/confirm/:uid/:token'} exact element={<AccountPasswordReset />} />
        <Route path={'/signup'} exact element={<Signup />} />
        <Route path={'/ref/:url-slug'} element={<Home />} />
        <Route path="*" element={<Home />} />

      </Routes>
    </Router>
  );
}

export default App;
