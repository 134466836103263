import React, { useEffect, useState } from 'react';
import { CheckIcon,  MenuIcon,  MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/solid';
import { random } from 'lodash';


export default function TemplateOrderItemQuantity({ product, initialQuantity, handleSetItems }) {
    const [qty, setQty] = useState(initialQuantity);

    useEffect(() => {
        if (isNaN(qty)) {
            setQty(0)
        } else if (qty < 0) {
            setQty(0)
        }

        handleSetItems(product.name, product.id, qty)
        product.quantity = qty
    }, [qty]);

    function handleItemQuantity(quantity) {
        setQty(quantity)
    }
    
    function addItemQuantity() {
        setQty(qty + 1)
    }

    function subItemQuantity() {
        setQty(qty - 1)
    }

    return (
        <div className="flex w-full justify-between">
            <div className="flex pr-4 xl:pr-5 space-x-2 xl:space-x-3">
                <div className="h-9 w-9 flex-shrink-0 flex items-center justify-center bg-blue-200 rounded">
                    <MenuIcon className="h-5" />
                </div>
                <div>
                    <h6 className="text-gray-700 font-roboto-medium leading-tight text-paragraph">{product.name} (Serves. {product.serves})</h6>
                    <p className="andie-description-text text-gray-500 leading-tight line-clamp-2 w-4/5 text-xs">{product.description}</p>
                    {/* <p className="mt-3 text-xs font-roboto-medium text-gray-500">{product.menuoption}</p> */}
                    <div className="w-3/4 mt-2.5">
                        <div className="mt-1 space-x-2 max-w-4 flex">
                            {product.restriction?.filter((i) => (i != "None")).length > 0 &&
                                product.restriction?.filter((i) => (i != "None")).map(e =>
                                    <div className="flex space-x-1.5 text-xs font-roboto-medium text-gray-500">
                                        <CheckIcon className="text-green-500 h-4" />
                                        <p>{e}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div key={random(1000000)} className='flex'>
                <MinusCircleIcon onClick={() => subItemQuantity()} className='cursor-pointer select-none h-6 text-blue-600' />
                <div className='text-center'>
                    <input
                        className='w-16 font-productsans-bold text-base text-center bid-form-input 
                            [&::-webkit-outer-spin-button]:appearance-none 
                            [&::-webkit-inner-spin-button]:appearance-none 
                            border-0 focus:border-1 h-5 bg-blue-50 mx-auto'
                        type='number'
                        placeholder='Qty'
                        defaultValue={qty}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => handleItemQuantity(parseInt(e.target.value))}
                    />
                    <div className='items-center font-roboto-medium text-center text-xs'>({qty*product.serves} people)</div>
                </div>
                <PlusCircleIcon onClick={() => addItemQuantity()} className='cursor-pointer select-none h-6 text-blue-600' />
            </div>
        </div>
    );
};