import React, { useEffect } from 'react'
import { useState } from 'react'
import { PackageForm } from '..';
import { PopoutModal, calculateDeliveryFee } from '../../common';
import { random } from 'lodash';

const PackageCreator = ({ packages, setPackages }) => {
    const [packageTitle, setPackageTitle] = useState("");
    const [restaurantTypes, setRestaurantTypes] = useState([]);
    const [serves, setServes] = useState(1);
    const [minGuests, setMinGuests] = useState(10);
    const [packageItems, setPackageItems] = useState({
        "appetizer": [],
        "main": [],
        "side": [],
        "dessert": [],
        "beverages": [],
        "other": []
    });

    const [selectedMenuOption, setSelectedMenuOption] = useState('');

    const [pricingType, setPricingType] = useState('item');
    const [totalPrice, setTotalPrice] = useState(0);
    const [deliveryFee, setDeliveryFee] = useState(0);

    function handlePackageCreate() {
        setPackages([...packages, {
            title: packageTitle,
            cuisine_type: restaurantTypes,
            serves: serves,
            min_guests: minGuests,
            items: packageItems,
            pricing: pricingType,
            package_price: totalPrice,
            delivery_fee: calculateDeliveryFee(0, false, totalPrice, 0),
            id: random(99999)
        }])

        setPackageTitle("")
        setRestaurantTypes([])
        setServes(1)
        setMinGuests(10)
        setPackageItems({
            "appetizer": [],
            "main": [],
            "side": [],
            "dessert": [],
            "beverages": [],
            "other": []
        })
        setPricingType('item')
        setTotalPrice(0)
        setDeliveryFee(0)
    }
    return (
        <PopoutModal
            title={"New Package"}
            button={<div className="flex justify-end">
                <button type='button' className="andie-outline-button border-blue-600 h-7 hover:bg-blue-100">Add a package</button>
            </div>}
            actionButton={
                <div className="flex justify-end px-4">
                    <button onClick={() => handlePackageCreate()} type="button" className='andie-dark-button w-40 mb-10' >{"Submit"}</button>
                </div>
            }
        >
            <PackageForm
                packages={packages} setPackages={setPackages}
                packageTitle={packageTitle} setPackageTitle={setPackageTitle}
                restaurantTypes={restaurantTypes} setRestaurantTypes={setRestaurantTypes}
                serves={serves} setServes={setServes}
                minGuests={minGuests} setMinGuests={setMinGuests}
                packageItems={packageItems} setPackageItems={setPackageItems}
                selectedMenuOption={selectedMenuOption} setSelectedMenuOption={setSelectedMenuOption}
                pricingType={pricingType} setPricingType={setPricingType}
                totalPrice={totalPrice} setTotalPrice={setTotalPrice}
                deliveryFee={deliveryFee} setDeliveryFee={setDeliveryFee}
            />
        </PopoutModal>
    )
}

export default PackageCreator