import React from "react";

export default function WelcomeIcon({ height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 500 500"
      viewBox="0 0 500 500"
      height={height}
    >
      <g>
        <g fill="#EBEBEB">
          <path d="M0 382.398H500V382.648H0z"></path>
          <path d="M400.674 393.042H433.796V393.292H400.674z"></path>
          <path d="M304.382 392.917H313.075V393.167H304.382z"></path>
          <path d="M430.709 398.494H449.901V398.744H430.709z"></path>
          <path d="M71.414 401.806H114.607V402.056H71.414z"></path>
          <path d="M104.556 390.888H110.889V391.138H104.556z"></path>
          <path d="M187.546 391.956H248.444V392.206H187.546z"></path>
          <path d="M237.014 337.8H43.915a5.715 5.715 0 01-5.708-5.708V60.66a5.715 5.715 0 015.708-5.708h193.099a5.715 5.715 0 015.707 5.708v271.432a5.715 5.715 0 01-5.707 5.708zM43.915 55.203a5.464 5.464 0 00-5.458 5.458v271.432a5.464 5.464 0 005.458 5.458h193.099a5.463 5.463 0 005.457-5.458V60.66a5.464 5.464 0 00-5.457-5.458H43.915zM453.31 337.8H260.212a5.714 5.714 0 01-5.707-5.708V60.66a5.715 5.715 0 015.707-5.708H453.31a5.714 5.714 0 015.708 5.708v271.432a5.714 5.714 0 01-5.708 5.708zM260.212 55.203a5.463 5.463 0 00-5.457 5.458v271.432a5.463 5.463 0 005.457 5.458H453.31a5.464 5.464 0 005.458-5.458V60.66a5.464 5.464 0 00-5.458-5.458H260.212z"></path>
        </g>
        <path fill="#F0F0F0" d="M71.111 96.333H428.889V245.222H71.111z"></path>
        <path
          fill="#FAFAFA"
          d="M182.556 -1.111H317.44500000000005V342.666H182.556z"
          transform="rotate(90 250 170.778)"
        ></path>
        <path
          fill="#FFF"
          d="M123.368 238.222L158.633 103.333 184.633 103.333 149.367 238.222z"
        ></path>
        <path
          fill="#FFF"
          d="M155.367 238.222L190.633 103.333 202.265 103.333 167 238.222z"
        ></path>
        <path
          fill="#FFF"
          d="M212.274 238.222L247.539 103.333 273.539 103.333 238.274 238.222z"
        ></path>
        <path
          fill="#FFF"
          d="M277.829 238.222L313.094 103.333 324.727 103.333 289.462 238.222z"
        ></path>
        <path
          fill="#E0E0E0"
          d="M101.875 169.889H236.764V171.667H101.875z"
          transform="rotate(90 169.32 170.778)"
        ></path>
        <path
          fill="#E0E0E0"
          d="M11.556 169.889H146.44500000000002V171.667H11.556z"
          transform="rotate(90 79 170.778)"
        ></path>
        <path
          fill="#F0F0F0"
          d="M97.486 167.278H232.375V174.278H97.486z"
          transform="rotate(90 164.93 170.778)"
        ></path>
        <path
          fill="#E0E0E0"
          d="M188.667 169.889H323.55600000000004V171.667H188.667z"
          transform="rotate(90 256.111 170.778)"
        ></path>
        <path
          fill="#F0F0F0"
          d="M184.278 167.278H319.16700000000003V174.278H184.278z"
          transform="rotate(90 251.723 170.778)"
        ></path>
        <path
          fill="#E0E0E0"
          d="M275.5 169.889H410.389V171.667H275.5z"
          transform="rotate(90 342.945 170.778)"
        ></path>
        <path
          fill="#F0F0F0"
          d="M271.111 167.278H406V174.278H271.111z"
          transform="rotate(90 338.556 170.778)"
        ></path>
        <path
          fill="#E0E0E0"
          d="M355.333 169.889H504.22200000000004V171.667H355.333z"
          transform="rotate(90 429.778 170.778)"
        ></path>
        <g>
          <path
            fill="#E0E0E0"
            d="M434.667 86.111H65.333a1.56 1.56 0 01-1.555-1.555c0-.856.7-1.556 1.555-1.556h369.333c.855 0 1.556.7 1.556 1.556 0 .855-.7 1.555-1.555 1.555z"
          ></path>
          <g opacity="0.6">
            <path fill="#F5F5F5" d="M66.778 80H142.334V351.667H66.778z"></path>
            <path
              fill="#E6E6E6"
              d="M107.722 353.889L101.389 353.889 103.167 82.222 106 82.222z"
            ></path>
            <path
              fill="#E6E6E6"
              d="M86.75 353.889L83.695 353.889 84.552 82.222 85.919 82.222z"
            ></path>
            <path
              fill="#E6E6E6"
              d="M128.75 353.889L125.695 353.889 126.552 82.222 127.919 82.222z"
            ></path>
          </g>
          <g opacity="0.6">
            <path
              fill="#F5F5F5"
              d="M356.762 80H432.318V351.667H356.762z"
            ></path>
            <path
              fill="#E6E6E6"
              d="M386.373 353.889L380.04 353.889 381.817 82.222 384.651 82.222z"
            ></path>
            <path
              fill="#E6E6E6"
              d="M372.401 353.889L369.345 353.889 370.203 82.222 371.57 82.222z"
            ></path>
            <path
              fill="#E6E6E6"
              d="M404.734 353.889L401.678 353.889 402.536 82.222 403.903 82.222z"
            ></path>
            <path
              fill="#E6E6E6"
              d="M420.29 353.889L417.235 353.889 418.093 82.222 419.46 82.222z"
            ></path>
          </g>
        </g>
        <g>
          <path
            fill="#E6E6E6"
            d="M325.216 299.32H339.419V380.697H325.216z"
            transform="rotate(-180 332.317 340.008)"
          ></path>
          <path
            fill="#F0F0F0"
            d="M160.582 299.32H330.67499999999995V380.697H160.582z"
            transform="rotate(-180 245.628 340.008)"
          ></path>
          <path
            fill="#E0E0E0"
            d="M326.855 380.697L321.735 380.697 167.931 380.697 167.931 382.398 321.735 382.398 326.855 382.398 332.074 382.398 332.074 380.697z"
          ></path>
          <path
            fill="#FAFAFA"
            d="M165.434 304.409H325.822V337.245H165.434z"
            opacity="0.5"
            transform="rotate(-180 245.628 320.828)"
          ></path>
          <path
            fill="#FAFAFA"
            d="M165.434 342.553H325.822V375.389H165.434z"
            opacity="0.5"
            transform="rotate(-180 245.628 358.971)"
          ></path>
          <path
            fill="#E0E0E0"
            d="M189.747 320.828a3.61 3.61 0 11-7.219 0 3.61 3.61 0 017.219 0zM308.728 320.828a3.61 3.61 0 11-7.219 0 3.61 3.61 0 017.219 0z"
          ></path>
          <circle cx="186.138" cy="358.971" r="3.609" fill="#E0E0E0"></circle>
          <path
            fill="#E0E0E0"
            d="M308.728 358.971a3.61 3.61 0 11-7.219 0 3.61 3.61 0 017.219 0zM249.238 320.828a3.61 3.61 0 11-7.219 0 3.61 3.61 0 017.219 0z"
          ></path>
          <circle cx="245.628" cy="358.971" r="3.609" fill="#E0E0E0"></circle>
          <path
            fill="#F0F0F0"
            d="M320.161 285.497c0 7.634-6.189 13.823-13.823 13.823s-13.823-6.189-13.823-13.823h27.646zM210.889 283.03c0 8.997-7.293 16.29-16.29 16.29s-16.29-7.293-16.29-16.29h32.58zM280.917 289.73c0 5.296-4.293 9.59-9.589 9.59s-9.589-4.293-9.589-9.59h19.178z"
          ></path>
        </g>
      </g>
      <g display="none">
        <path
          fill="#407BFF"
          d="M232.343 111.648c-38.575-2.253-78.938-24.994-116.759-3.057-28.398 16.472-32.554 48.788-24.237 79.062 21.843 79.508 144.13 92.961 213.035 73.012 71.365-20.662 151.897-91.38 105.742-163.325-19.023-29.653-52.175-34.882-88.345-25.054-30.73 8.349-59.204 41.128-89.436 39.362z"
        ></path>
        <path
          fill="#FFF"
          d="M232.343 111.648c-38.575-2.253-78.938-24.994-116.759-3.057-28.398 16.472-32.554 48.788-24.237 79.062 21.843 79.508 144.13 92.961 213.035 73.012 71.365-20.662 151.897-91.38 105.742-163.325-19.023-29.653-52.175-34.882-88.345-25.054-30.73 8.349-59.204 41.128-89.436 39.362z"
          opacity="0.9"
        ></path>
      </g>
      <g>
        <ellipse
          cx="250"
          cy="416.238"
          fill="#F5F5F5"
          rx="193.889"
          ry="11.323"
        ></ellipse>
      </g>
      <g>
        <path
          fill="#263238"
          d="M348.149 115.365c-2.134-2.131-6.233-10.204-2.3-13.951s7.431-.349 7.703 1.492c.271 1.84-.457 17.397-5.403 12.459z"
        ></path>
        <path
          fill="#FF8B7B"
          d="M347.246 405.652L338.325 405.73 335.892 385.091 344.812 385.013z"
        ></path>
        <path
          fill="#263238"
          d="M338.307 404.697l9.742-.086a.845.845 0 01.805.602l2.355 7.909c.244.82-.322 1.604-1.165 1.597-3.519-.029-8.612-.19-13.029-.152-5.165.045-7.468.366-13.525.419-3.662.032-4.956-3.659-3.448-4.008 6.864-1.587 10.415-1.8 16.167-5.57.626-.407 1.328-.704 2.098-.711z"
        ></path>
        <path
          d="M335.894 385.097L337.147 395.735 346.071 395.657 344.818 385.019z"
          opacity="0.2"
        ></path>
        <path
          fill="#407BFF"
          d="M334.624 406.289c1.004 0 2.093-.183 2.857-.345a.212.212 0 00.049-.397c-.424-.207-4.171-2.006-5.196-1.367a.634.634 0 00-.301.569c0 .421.159.764.473 1.019.478.389 1.267.521 2.118.521zm2.136-.635c-1.931.34-3.399.266-3.988-.212a.835.835 0 01-.317-.691c0-.13.051-.179.102-.211.55-.343 2.668.419 4.203 1.114z"
        ></path>
        <path
          fill="#407BFF"
          d="M337.437 405.949a.21.21 0 00.208-.246c-.027-.163-.702-3.998-2.826-3.761-.561.062-.718.345-.751.571-.147 1.002 2.143 2.933 3.286 3.419a.216.216 0 00.083.017zm-2.438-3.595c1.278 0 1.92 2.084 2.139 2.998-1.176-.666-2.74-2.18-2.653-2.778.006-.043.026-.173.38-.213.046-.004.09-.007.134-.007z"
        ></path>
        <path
          fill="#FF8B7B"
          d="M340.944 160.427l-.924.96-.859.854c-.572.56-1.161 1.085-1.747 1.613a88.758 88.758 0 01-3.603 3.039 70.404 70.404 0 01-7.796 5.377 72.492 72.492 0 01-4.218 2.308 62.96 62.96 0 01-4.461 1.976c-3.056 1.2-6.284 2.137-9.675 2.643l-.745.112a9.425 9.425 0 01-1.444.089 8.889 8.889 0 01-3.256-.64c-1.095-.427-2.039-1.09-2.765-1.771-.756-.699-1.232-1.385-1.658-2.059-.777-1.315-1.153-2.462-1.432-3.504a20.757 20.757 0 01-.526-2.904c-.211-1.817-.214-3.474-.151-5.094.067-1.612.196-3.172.376-4.707a91.906 91.906 0 011.579-8.955c.672-2.933 1.417-5.772 2.379-8.671l6.572 1.292c-.241 5.626-.51 11.384-.329 16.82.067 2.692.316 5.406.797 7.53.123.518.268.988.414 1.332.14.341.305.552.298.464-.016-.08-.068-.17-.273-.396-.189-.201-.527-.441-.938-.626a3.535 3.535 0 00-1.133-.266c-.17.003-.258-.009-.363.005-.109.021.059-.018.106-.032l.216-.056c2.304-.562 4.604-1.447 6.88-2.497a59.67 59.67 0 006.683-3.784 83.442 83.442 0 006.373-4.651 73.668 73.668 0 003.042-2.551l1.454-1.321.706-.651.607-.58 9.814 9.302z"
        ></path>
        <path
          fill="#263238"
          d="M346.229 156.899c-.34-9.425-8.083-12.706-17.778-6.282-9.198 6.106-12.335 8.857-12.335 8.857l6.275 20.137s12.021-3.809 18.941-11.292c4.696-5.077 5.013-8.208 4.897-11.42z"
        ></path>
        <path
          d="M334.42 160.92s-5.79 8.28-7.84 17.08c2.3-.99 5.27-2.43 8.2-4.32l-.36-12.76z"
          opacity="0.2"
        ></path>
        <path
          fill="#FF8B7B"
          d="M306.26 145.372l3.357-7.588-9.503-2.784s-1.634 3.63-.705 9.86l6.851.512z"
        ></path>
        <path
          fill="#FF8B7B"
          d="M310.628 128.882L302.364 129.866 300.115 134.999 309.618 137.783z"
        ></path>
        <path
          fill="#407BFF"
          d="M334.484 225.256s-6.148 51.463-6.821 78.972c-.7 28.613 4.909 86.324 4.909 86.324l16.108-.342s1.937-61.778 4.275-90.088c2.548-30.863 8.949-75.449 8.949-75.449l-27.42.583z"
        ></path>
        <path
          fill="#FFF"
          d="M334.484 225.256s-6.148 51.463-6.821 78.972c-.7 28.613 4.909 86.324 4.909 86.324l16.108-.342s1.937-61.778 4.275-90.088c2.548-30.863 8.949-75.449 8.949-75.449l-27.42.583z"
          opacity="0.6"
        ></path>
        <path
          d="M353.67 239.83c-7.2 15.87-5.27 51.32-1.18 66.52.15-2.21.31-4.3.47-6.23 1.67-20.34 5.02-46.63 7.11-62.2-1.96-2.84-4.15-3.06-6.4 1.91z"
          opacity="0.2"
        ></path>
        <path
          fill="#407BFF"
          d="M350.66 390.933L331.718 391.312 330.325 385.019 350.429 384.974z"
        ></path>
        <path
          fill="#FF8B7B"
          d="M387.043 405.652L378.122 405.73 375.689 385.091 384.609 385.013z"
        ></path>
        <path
          fill="#263238"
          d="M378.104 404.697l9.742-.086a.845.845 0 01.805.602l2.355 7.909c.244.82-.322 1.604-1.165 1.597-3.519-.029-8.612-.19-13.029-.152-5.164.045-7.468.366-13.525.419-3.662.032-4.955-3.659-3.448-4.008 6.864-1.587 10.415-1.8 16.167-5.57.626-.407 1.328-.704 2.098-.711z"
        ></path>
        <path
          d="M375.691 385.097L376.944 395.735 385.868 395.657 384.616 385.019z"
          opacity="0.2"
        ></path>
        <path
          fill="#407BFF"
          d="M374.421 406.289c1.004 0 2.093-.183 2.857-.345a.212.212 0 00.049-.397c-.424-.207-4.171-2.006-5.196-1.367a.634.634 0 00-.301.569c0 .421.159.764.473 1.019.478.389 1.267.521 2.118.521zm2.136-.635c-1.931.34-3.399.266-3.988-.212a.831.831 0 01-.316-.691c0-.13.051-.179.102-.211.549-.343 2.667.419 4.202 1.114z"
        ></path>
        <path
          fill="#407BFF"
          d="M377.234 405.949a.21.21 0 00.208-.246c-.027-.163-.702-3.998-2.826-3.761-.561.062-.718.345-.751.571-.147 1.002 2.143 2.933 3.287 3.419a.208.208 0 00.082.017zm-2.438-3.595c1.278 0 1.92 2.084 2.139 2.998-1.176-.666-2.74-2.18-2.652-2.778.006-.043.025-.173.38-.213a1.5 1.5 0 01.133-.007zM350.203 224.921s4.451 55.365 7.694 82.69c3.374 28.422 14.473 82.94 14.473 82.94l16.109-.342s-4.421-61.902-6.13-90.257c-1.864-30.912-2.344-75.712-2.344-75.712l-29.802.681z"
        ></path>
        <path
          fill="#FFF"
          d="M350.203 224.921s4.451 55.365 7.694 82.69c3.374 28.422 14.473 82.94 14.473 82.94l16.109-.342s-4.421-61.902-6.13-90.257c-1.864-30.912-2.344-75.712-2.344-75.712l-29.802.681z"
          opacity="0.6"
        ></path>
        <path
          fill="#407BFF"
          d="M390.457 390.933L371.515 391.312 370.122 385.019 390.226 384.974z"
        ></path>
        <path
          fill="#263238"
          d="M333.37 148.155s-6.594 24.458 1.114 77.101c14.374-.306 38.525-.867 45.519-1.016.173-7.33-5.234-43.269 1.094-77.924 0 0-5.982-1.152-13.782-1.362-6.102-.164-14.931-.17-20.279.433-7.051.795-13.666 2.768-13.666 2.768z"
        ></path>
        <path
          d="M378.34 170.98l-7.45-6.17c.49 4.82 4.85 16.63 7.15 22.61-.06-5.22.01-10.75.3-16.44z"
          opacity="0.2"
        ></path>
        <path
          fill="#FFF"
          d="M351.689 162.335l-8.126 16.082c-.785 1.553.904 3.234 2.415 2.403l5.311-2.92a1.733 1.733 0 011.851.123l5.226 3.767c1.404 1.012 3.227-.385 2.643-2.025l-6.113-17.176c-.523-1.471-2.512-1.629-3.207-.254z"
        ></path>
        <path
          fill="#FF8B7B"
          d="M364.819 122.163c-1.14 6.552-2.162 18.534 2.496 22.791 0 0-1.359 5.931-13.767 7.951-8.337-2.675-6.512-7.518-6.512-7.518 7.239-1.893 6.936-7.287 5.561-12.33l12.222-10.894z"
        ></path>
        <path
          d="M359.837 126.608l-7.234 6.443c.33 1.193.592 2.405.702 3.586 2.771-.461 6.525-3.593 6.758-6.51.115-1.454.025-2.848-.226-3.519z"
          opacity="0.2"
        ></path>
        <path
          fill="#FF8B7B"
          d="M368.788 114.09c-2.404 8.521-3.377 13.631-8.701 17.117-8.007 5.245-17.81-1.022-18.001-10.09-.172-8.162 3.788-20.726 12.987-22.329 9.063-1.579 16.118 6.781 13.715 15.302z"
        ></path>
        <path
          fill="#263238"
          d="M363.663 119.307c-1.121-2.718-1.928-11.675 2.733-13.873 4.662-2.197 9.521 3.169 8.298 5.705-1.898 3.939-8.433 14.466-11.031 8.168z"
        ></path>
        <path
          fill="#263238"
          d="M366.448 108.59c-1.146-2.961-14.862-4.696-16.78-7.929-2.281-3.845 7.076-4.304 13.505-2.348 5.65 1.72 9.417 3.373 8.757 7.575-.375 2.379-5.482 2.702-5.482 2.702z"
        ></path>
        <path
          fill="#263238"
          d="M369.425 105.497s2.972.826 4.648-1.381c-.521 2.179-3.057 4.385-4.648 1.381zM355.703 96.251c-4.124-3.203-9.732.561-9.2 4.73.532 4.169 14.417 7.342 14.417 7.342s.252-7.825-5.217-12.072z"
        ></path>
        <path
          fill="#263238"
          d="M346.512 101.041c.631 2.915 2.759 5.213 7.066 6.905-4.03-2.833-5.423-7.306-5.423-7.306l-1.643.401zM355.165 113.663c-.152.703-.649 1.192-1.108 1.093-.46-.1-.709-.751-.556-1.454.152-.703.649-1.192 1.108-1.093.459.1.708.751.556 1.454zM347.216 111.939c-.152.703-.649 1.193-1.108 1.093-.46-.1-.709-.751-.556-1.454.153-.703.649-1.192 1.108-1.093.46.1.709.75.556 1.454z"
        ></path>
        <path
          fill="#FF5652"
          d="M349.668 112.837s-2.479 3.71-4.664 5.286c.992 1.431 3.222 1.359 3.222 1.359l1.442-6.645z"
        ></path>
        <path
          fill="#263238"
          d="M349.85 121.866l.004.001c3.309.718 5.317-1.222 5.401-1.305a.21.21 0 10-.296-.3c-.019.019-1.937 1.86-5.014 1.193a.21.21 0 00-.251.162.207.207 0 00.156.249zM358.107 111.617a.422.422 0 00.482-.589c-.983-2.121-2.911-2.422-2.992-2.434a.424.424 0 00-.479.358.425.425 0 00.356.478c.064.01 1.564.261 2.349 1.953a.418.418 0 00.284.234zM344.32 108.452a.423.423 0 00.431-.15c1.164-1.482 2.716-1.363 2.732-1.362a.421.421 0 00.458-.384.426.426 0 00-.384-.458c-.082-.007-2.027-.157-3.472 1.681a.422.422 0 00.235.673z"
        ></path>
        <path
          fill="#FF8B7B"
          d="M383.28 153.163c1.819 3.174 3.344 6.252 4.915 9.424a176.432 176.432 0 014.357 9.627c1.358 3.262 2.661 6.561 3.8 9.957a103.372 103.372 0 013.051 10.426l.608 2.733a17.3 17.3 0 01.34 3.897c-.059 2.587-.72 4.981-1.595 7.064-1.785 4.188-4.416 7.286-7.112 9.996-2.741 2.679-5.663 4.935-8.695 6.936-1.507 1.013-3.073 1.916-4.641 2.797a58.09 58.09 0 01-4.875 2.462l-3.325-5.814a152.15 152.15 0 003.78-3.078c1.256-1.06 2.454-2.182 3.646-3.28 2.355-2.229 4.576-4.54 6.43-6.948 1.865-2.373 3.355-4.911 4.025-7.143.337-1.116.426-2.123.361-2.968a5.14 5.14 0 00-.235-1.198l-.673-2.244c-1.904-6.063-4.444-12.183-7.205-18.211-1.367-3.023-2.825-6.023-4.286-9.022-1.445-2.979-3.025-6.012-4.495-8.85l11.824-6.563z"
        ></path>
        <path
          fill="#263238"
          d="M381.444 146.385c8.335 1.81 14.139 23.561 14.139 23.561l-14.917 13.533s-7.287-7.658-11.027-18.027c-3.919-10.865 2.877-21.006 11.805-19.067z"
        ></path>
        <path
          fill="#FF8B7B"
          d="M371.871 221.682l-9.588.101 5.945 9.665s6.828-1.688 7.44-5.118l-3.797-4.648z"
        ></path>
        <path
          fill="#FF8B7B"
          d="M356.307 227.487L361.602 235.25 368.228 231.448 362.283 221.783z"
        ></path>
        <path
          fill="#FF8B7B"
          d="M371.408 121.89c-1.114 2.111-3.099 3.511-4.982 4.061-2.833.826-4.283-1.699-3.523-4.391.684-2.422 2.981-5.771 5.838-5.416 2.813.349 3.94 3.331 2.667 5.746z"
        ></path>
      </g>
      <g>
        <path
          fill="#407BFF"
          d="M275.754 287.761H286.44100000000003V288.761H275.754z"
          transform="rotate(-4.133 281.104 288.137)"
        ></path>
        <path
          fill="#407BFF"
          d="M290.772 286.872H296.021V287.872H290.772z"
          transform="rotate(-4.133 293.43 287.273)"
        ></path>
        <path
          fill="#407BFF"
          d="M341.954 169.272L165.442 182.04c-7.639.553-13.437 7.255-12.885 14.894l6.02 83.22c.553 7.639 7.255 13.437 14.894 12.884l9.984-.722a2.748 2.748 0 012.939 2.542l1.116 15.425c.097 1.339 1.764 1.892 2.642.877l16.738-19.349a4.055 4.055 0 012.773-1.391l140.321-10.15c7.639-.553 13.437-7.255 12.885-14.894l-6.02-83.22c-.553-7.638-7.255-13.436-14.895-12.884z"
        ></path>
        <path
          fill="#FFF"
          d="M191.235 220.201l9.535-.69.781 10.798 10.419-.754-.781-10.798 9.577-.693 2.232 30.857-9.577.693-.903-12.482-10.419.754.903 12.482-9.535.69-2.232-30.857z"
        ></path>
        <path
          d="M165.442 182.04c-7.639.552-13.437 7.255-12.885 14.894l6.02 83.22c.553 7.639 7.255 13.437 14.894 12.885l1.469-.106a11.124 11.124 0 0010.123-8.999l20.105-104.766-39.726 2.872z"
          opacity="0.2"
        ></path>
        <path
          fill="#FFF"
          d="M252.925 237.659l-17.175 1.242c.254 1.364.7 2.361 1.338 2.992.898.908 2.013 1.314 3.346 1.218a4.883 4.883 0 002.354-.805c.444-.3.908-.806 1.392-1.518l8.497.168c-1.129 2.339-2.57 4.061-4.323 5.169-1.754 1.107-4.335 1.784-7.745 2.031-2.961.214-5.32-.035-7.078-.747s-3.261-1.936-4.508-3.673c-1.247-1.736-1.959-3.833-2.137-6.288-.253-3.494.662-6.402 2.744-8.725 2.082-2.323 5.094-3.627 9.037-3.912 3.199-.231 5.76.07 7.682.904 1.922.834 3.434 2.135 4.535 3.904 1.101 1.768 1.758 4.126 1.971 7.072l.07.968zm-9.011-3.475c-.288-1.644-.82-2.797-1.594-3.46-.774-.663-1.751-.952-2.93-.867-1.361.098-2.41.717-3.145 1.857-.47.711-.728 1.738-.771 3.081l8.44-.611zM255.579 215.547l8.588-.621 2.232 30.857-8.588.621-2.232-30.857zM269.933 214.508l8.588-.621 2.232 30.857-8.588.621-2.232-30.857zM284.369 233.311c-.247-3.41.701-6.303 2.842-8.68 2.141-2.376 5.169-3.706 9.084-3.99 4.476-.324 7.952.73 10.427 3.16 1.989 1.958 3.095 4.466 3.316 7.525.249 3.438-.688 6.337-2.809 8.698-2.121 2.361-5.196 3.687-9.223 3.978-3.592.26-6.563-.442-8.912-2.106-2.885-2.061-4.46-4.923-4.725-8.585zm8.586-.642c.144 1.992.654 3.437 1.53 4.333.876.896 1.924 1.3 3.145 1.211 1.235-.089 2.215-.633 2.939-1.63.725-.998 1.012-2.535.862-4.611-.14-1.936-.644-3.349-1.513-4.239-.868-.889-1.892-1.291-3.071-1.206-1.249.09-2.245.646-2.99 1.666-.743 1.019-1.044 2.511-.902 4.476zM312.766 211.41l9.156-.662.509 7.03-.747 14.251-5.662.41-2.747-13.998-.509-7.031zm2.001 23.278l8.546-.618.547 7.556-8.546.618-.547-7.556z"
        ></path>
      </g>
      <g>
        <path
          fill="#263238"
          d="M162.996 154.598c-.046 1.813.083 3.32.231 4.384-11.736 4.05-22.63 3.153-28.15 2.228.268-.98.684-2.82.823-5.234-.176 1.71-1.147 3.782-1.831 5.068-1.776-.342-2.774-.629-2.774-.629s1.813-9.849-1.461-16.997c-3.274-7.158-1.646-11.153.832-12.291 4.605-14.14 21.89-11.902 27.744-6.168 7.417 7.278 7.463 27.79 8.776 32.479a49.19 49.19 0 01-3.227 1.285c-.473-1.259-.935-2.812-.963-4.125z"
        ></path>
        <path
          fill="#FFC3BD"
          d="M170.148 176.613l.904 2.846c.324.98.66 1.964 1.01 2.946a181.125 181.125 0 002.115 5.919c.756 1.962 1.471 3.945 2.255 5.906l1.155 2.952.291.738.067.168.009.027c.004.029.005.029-.002.026 0 0-.2-.18-.42-.255-.221-.077-.314-.011-.176-.006.122.012.393-.021.709-.112.643-.176 1.448-.56 2.243-1.003 1.626-.893 3.256-2.109 4.881-3.346a83.135 83.135 0 004.767-4.016c1.585-1.368 3.131-2.841 4.645-4.258l3.069 2.594c-1.278 1.837-2.636 3.52-4.032 5.232a87.684 87.684 0 01-4.495 4.903c-1.587 1.598-3.288 3.103-5.281 4.514-1.021.689-2.1 1.357-3.479 1.901a9.63 9.63 0 01-2.413.618c-.926.109-2.12.065-3.324-.448-1.214-.491-2.215-1.448-2.798-2.344a6.116 6.116 0 01-.402-.67l-.269-.531-.358-.733c-.477-.977-.96-1.953-1.414-2.943-.913-1.976-1.822-3.96-2.648-5.983a170.074 170.074 0 01-2.407-6.12 135.997 135.997 0 01-1.068-3.139 49.57 49.57 0 01-.978-3.302l7.844-2.081z"
        ></path>
        <path
          fill="#407BFF"
          d="M167.782 167.731c3.446.878 5.857 15.061 5.857 15.061l-9.497 6.459s-9.106-14.308-6.455-18.097c2.761-3.947 5.066-4.704 10.095-3.423z"
        ></path>
        <path
          fill="#407BFF"
          d="M167.782 167.731c3.446.878 5.857 15.061 5.857 15.061l-9.497 6.459s-9.106-14.308-6.455-18.097c2.761-3.947 5.066-4.704 10.095-3.423z"
        ></path>
        <path
          fill="#FFF"
          d="M167.782 167.731c3.446.878 5.857 15.061 5.857 15.061l-9.497 6.459s-9.106-14.308-6.455-18.097c2.761-3.947 5.066-4.704 10.095-3.423z"
          opacity="0.4"
        ></path>
        <path
          d="M167.471 170.898L170.118 185.194 164.78 188.814z"
          opacity="0.3"
        ></path>
        <path
          fill="#FFC3BD"
          d="M194.427 185.518l3.169-7.633 3.911 7.633s-2.539 3.8-5.889 2.873l-1.191-2.873z"
        ></path>
        <path
          fill="#FFC3BD"
          d="M201.089 177.088L206.111 179.113 201.506 185.518 197.595 177.884z"
        ></path>
        <path
          fill="#FFC3BD"
          d="M149.135 406.45L157.497 406.45 158.056 387.084 149.693 387.084z"
        ></path>
        <path
          fill="#FFC3BD"
          d="M93.01 381.505L100.704 384.781 110.765 368.017 103.071 364.741z"
        ></path>
        <path
          fill="#263238"
          d="M102.179 383.642l-8.004-4.919a.663.663 0 00-.868.137l-4.804 5.772c-.498.599-.315 1.568.364 1.969 2.82 1.667 4.265 2.33 7.785 4.493 2.165 1.331 5.191 3.5 8.181 5.338 2.925 1.798 4.967-1.021 3.886-2.054-4.845-4.632-4.709-6.83-5.619-9.566a2.18 2.18 0 00-.921-1.17zM157.784 405.481h-9.395a.661.661 0 00-.667.571l-1.07 7.433c-.111.771.552 1.501 1.341 1.487 3.275-.056 4.854-.249 8.985-.249 2.542 0 7.799.264 11.309.264 3.433 0 3.697-3.471 2.235-3.785-6.553-1.41-9.132-3.353-11.34-5.207a2.186 2.186 0 00-1.398-.514z"
        ></path>
        <path
          d="M158.054 387.09L157.768 397.072 149.402 397.072 149.688 387.09z"
          opacity="0.2"
        ></path>
        <path
          d="M103.066 364.745L110.763 368.022 105.574 376.662 97.877 373.384z"
          opacity="0.2"
        ></path>
        <path
          fill="#407BFF"
          d="M167.782 167.731s3.984 1.403-3.996 50.471h-33.962c.57-13.822.586-22.346-6.02-50.713 0 0 7.116-1.548 14.422-1.9 5.715-.275 10.398-.458 15.422.001 6.622.603 14.134 2.141 14.134 2.141z"
        ></path>
        <path
          fill="#FFF"
          d="M167.782 167.731s3.984 1.403-3.996 50.471h-33.962c.57-13.822.586-22.346-6.02-50.713 0 0 7.116-1.548 14.422-1.9 5.715-.275 10.398-.458 15.422.001 6.622.603 14.134 2.141 14.134 2.141z"
          opacity="0.4"
        ></path>
        <path
          fill="#FFF"
          d="M138.224 165.584c5.715-.268 10.395-.453 15.425.009.832.074 1.683.167 2.525.268.564.971.99 2.275.462 3.607-1.082 2.691-4.985 3.005-7.833 3.005-10.515 0-12.29-6.141-12.365-6.399l-.102-.37c.63-.046 1.259-.092 1.888-.12z"
        ></path>
        <path
          fill="#FFC3BD"
          d="M139.753 148.223c.973 4.962 1.944 14.054-1.528 17.365 0 0 1.357 5.034 10.577 5.034 10.138 0 4.845-5.033 4.845-5.033-5.533-1.321-5.391-5.427-4.427-9.282l-9.467-8.084z"
        ></path>
        <path
          fill="#263238"
          d="M140.763 144.691c-1.189 3.93.454 13.231 2.907 16.828-4.833-2.765-6.465-11.21-6.465-11.21l3.558-5.618z"
        ></path>
        <path
          d="M143.612 151.522l5.603 4.781c-.231.913-.411 1.838-.476 2.737-2.114-.305-5.018-2.626-5.242-4.839-.111-1.104-.065-2.165.115-2.679z"
          opacity="0.2"
        ></path>
        <path
          fill="#FFC3BD"
          d="M135.173 139.36c1.935 8.07 2.582 11.528 7.438 14.991 7.305 5.209 16.795 1.068 17.31-7.418.464-7.638-2.779-19.553-11.335-21.396-8.43-1.816-15.349 5.753-13.413 13.823z"
        ></path>
        <path
          fill="#407BFF"
          d="M163.786 218.201s6.596 64.416 6.048 89.917c-.57 26.525-10.29 87.02-10.29 87.02h-11.308s1.79-58.945.79-84.995c-1.09-28.399-10.111-91.942-10.111-91.942h24.871z"
        ></path>
        <path
          d="M163.786 218.201s6.596 64.416 6.048 89.917c-.57 26.525-10.29 87.02-10.29 87.02h-11.308s1.79-58.945.79-84.995c-1.09-28.399-10.111-91.942-10.111-91.942h24.871z"
          opacity="0.4"
        ></path>
        <path
          fill="#407BFF"
          d="M147.256 395.544L160.524 395.544 161.497 391.312 146.639 390.794z"
        ></path>
        <path
          d="M149.136 234.229c8.342 16.781 1.675 47.392-1.275 58.781-1.513-17.105-4.063-38.013-6.051-53.368 1.264-8.687 3.479-13.15 7.326-5.413z"
          opacity="0.2"
        ></path>
        <path
          fill="#407BFF"
          d="M154.817 218.201s-7.693 66.969-13.503 88.469c-6.898 25.526-33.124 69.004-33.124 69.004l-10.492-4.467s16.236-49.357 21.51-62.805c11.256-28.701 1.621-71.472 10.616-90.2h24.993z"
        ></path>
        <path
          d="M154.817 218.201s-7.693 66.969-13.503 88.469c-6.898 25.526-33.124 69.004-33.124 69.004l-10.492-4.467s16.236-49.357 21.51-62.805c11.256-28.701 1.621-71.472 10.616-90.2h24.993z"
          opacity="0.4"
        ></path>
        <path
          fill="#407BFF"
          d="M96.255 371.241L109.271 376.783 112.399 373.281 97.382 366.071z"
        ></path>
        <path
          fill="#263238"
          d="M147.948 139.465c.117.664.563 1.141.998 1.065.434-.076.692-.677.575-1.341-.117-.664-.563-1.141-.998-1.065-.434.077-.692.677-.575 1.341zM155.457 138.146c.117.664.563 1.141.998 1.065.434-.076.692-.677.575-1.341-.117-.664-.563-1.141-.998-1.065-.434.076-.692.676-.575 1.341z"
        ></path>
        <path
          fill="#263238"
          d="M155.847 136.875l1.515-.74c-.001 0-.6 1.39-1.515.74z"
        ></path>
        <path
          fill="#ED847E"
          d="M153.673 139.636s1.63 2.662 3.113 3.824c-.733.973-2.29.861-2.29.861l-.823-4.685z"
        ></path>
        <path
          fill="#263238"
          d="M151.488 147.447c.355.001.732-.03 1.131-.1a.198.198 0 10-.069-.391c-2.913.514-4.635-1.284-4.652-1.303a.198.198 0 10-.29.27c.066.072 1.451 1.521 3.88 1.524zM138 134.625c.167 2.442 1 6.333 1 6.333s5.005-6.323 5.827-9.714c-.514 2.723-1.145 3.851-1.145 3.851s6.73-1.462 7.386-5.905c-.064 3.621-.655 5.905-.655 5.905s6.42-2.516 5.83-6.78c-.59-4.264-10.454-5.908-14.975-4.47-4.521 1.439-8.989 6.115-7.655 12.536 1.334 6.421 3.905 8.832 3.905 8.832s-1.881-6.478.482-10.588z"
        ></path>
        <path
          fill="#FFC3BD"
          d="M134.821 145.442c.647 1.594 1.97 2.741 3.281 3.271 1.972.796 3.193-.91 2.842-2.895-.316-1.786-1.723-4.353-3.797-4.304-2.043.048-3.066 2.106-2.326 3.928z"
        ></path>
        <path
          fill="#263238"
          d="M139.213 131.552c-1.002 3.982 1.079 13.195 3.699 16.672-4.958-2.534-6.987-10.892-6.987-10.892l3.288-5.78zM145.264 137.19c.1-.03.188-.1.239-.201.792-1.579 2.237-1.761 2.254-1.763a.4.4 0 00.349-.439.397.397 0 00-.439-.349c-.076.009-1.89.235-2.873 2.195a.397.397 0 00.47.557zM158.189 134.092a.397.397 0 00.288-.669c-1.511-1.586-3.313-1.273-3.39-1.258a.396.396 0 00.144.78c.07-.013 1.471-.236 2.673 1.026.077.08.181.121.285.121z"
        ></path>
        <path
          fill="#FFC3BD"
          d="M127.927 179.36a229.607 229.607 0 00-3.843 13.54c-1.085 4.555-2.061 9.146-2.49 13.627l-.083.841c-.018.272-.028.334-.028.567.004.41.034.895.104 1.413a36.35 36.35 0 00.549 3.258 119.946 119.946 0 001.683 6.817 210.163 210.163 0 001.945 6.894l2.032 6.907-3.717 1.526c-2.279-4.361-4.258-8.804-6.109-13.381-.888-2.302-1.76-4.621-2.478-7.067-.362-1.226-.703-2.472-.971-3.828a19.063 19.063 0 01-.302-2.187c-.032-.37-.038-.913-.031-1.244l.035-.968c.066-2.592.343-5.11.699-7.603.332-2.499.824-4.942 1.345-7.376 1.059-4.88 2.339-9.586 3.989-14.368l7.671 2.632z"
        ></path>
        <path
          fill="#407BFF"
          d="M123.803 167.488c-3.403 1.035-7.489 12.751-7.489 12.751l13.201 9.438s4.814-9.781 3.384-14.179c-1.489-4.579-5.213-9.19-9.096-8.01z"
        ></path>
        <path
          fill="#FFF"
          d="M123.803 167.488c-3.403 1.035-7.489 12.751-7.489 12.751l13.201 9.438s4.814-9.781 3.384-14.179c-1.489-4.579-5.213-9.19-9.096-8.01z"
          opacity="0.4"
        ></path>
        <path
          fill="#407BFF"
          d="M105.56 386.818c.312-.097.551-.33.71-.695.135-.309.051-.541-.044-.681-.598-.884-3.62-.818-3.963-.807a.197.197 0 00-.134.338c.788.786 2.266 2.073 3.31 1.875a.972.972 0 00.121-.03zm-2.805-1.792c1.209.009 2.828.173 3.143.639.04.058.077.146.009.3-.125.287-.305.448-.542.494-.564.108-1.522-.423-2.61-1.433z"
        ></path>
        <path
          fill="#407BFF"
          d="M103.642 384.786c.921-.286 1.898-.794 2.109-1.365.064-.173.112-.509-.279-.82a1.035 1.035 0 00-.853-.214c-1.191.21-2.466 2.255-2.52 2.342a.198.198 0 00.162.301c.336.013.849-.078 1.381-.244zm.923-1.978a.97.97 0 01.124-.03.644.644 0 01.537.133c.199.159.187.282.154.372-.203.55-1.721 1.208-2.73 1.332.432-.621 1.228-1.593 1.915-1.807zM160.924 406.943c.831 0 1.598-.129 2.028-.515.273-.245.393-.57.357-.964a.637.637 0 00-.336-.527c-1.025-.562-4.358 1.141-4.734 1.337a.198.198 0 00.053.37c.71.141 1.711.299 2.632.299zm1.49-1.73c.153 0 .279.022.37.072.081.045.122.111.131.215.024.267-.051.475-.229.634-.507.456-1.884.537-3.736.228 1.148-.551 2.671-1.149 3.464-1.149z"
        ></path>
        <path
          fill="#407BFF"
          d="M158.33 406.648a.188.188 0 00.081-.018c.997-.45 2.968-2.254 2.814-3.201-.037-.224-.195-.503-.744-.559a1.444 1.444 0 00-1.097.342c-1.051.876-1.244 3.126-1.252 3.222a.197.197 0 00.198.214zm1.992-3.39c.04 0 .079.002.12.006.362.037.385.181.393.228.093.569-1.245 1.987-2.261 2.603.097-.655.376-2.006 1.065-2.579.207-.173.432-.258.683-.258z"
        ></path>
        <path
          fill="#263238"
          d="M129.315 216.208l-.868 3.04c-.119.236.161.476.553.476h34.954c.306 0 .56-.15.579-.342l.306-3.04c.021-.211-.244-.389-.579-.389h-34.392c-.253 0-.477.104-.553.255z"
        ></path>
        <g>
          <path
            fill="#407BFF"
            d="M133.934 220.054h-.921c-.184 0-.323-.094-.311-.21l.432-3.95c.013-.116.173-.21.356-.21h.921c.183 0 .323.095.311.21l-.432 3.95c-.013.115-.173.21-.356.21z"
          ></path>
          <path
            d="M133.934 220.054h-.921c-.184 0-.323-.094-.311-.21l.432-3.95c.013-.116.173-.21.356-.21h.921c.183 0 .323.095.311.21l-.432 3.95c-.013.115-.173.21-.356.21z"
            opacity="0.4"
          ></path>
        </g>
        <g>
          <path
            fill="#407BFF"
            d="M161.365 220.054h-.921c-.184 0-.323-.094-.311-.21l.432-3.95c.013-.116.173-.21.357-.21h.921c.183 0 .323.095.311.21l-.432 3.95c-.013.115-.174.21-.357.21z"
          ></path>
          <path
            d="M161.365 220.054h-.921c-.184 0-.323-.094-.311-.21l.432-3.95c.013-.116.173-.21.357-.21h.921c.183 0 .323.095.311.21l-.432 3.95c-.013.115-.174.21-.357.21z"
            opacity="0.4"
          ></path>
        </g>
        <g>
          <path
            fill="#407BFF"
            d="M147.649 220.054h-.921c-.184 0-.323-.094-.311-.21l.432-3.95c.013-.116.173-.21.357-.21h.921c.183 0 .323.095.311.21l-.432 3.95c-.013.115-.173.21-.357.21z"
          ></path>
          <path
            d="M147.649 220.054h-.921c-.184 0-.323-.094-.311-.21l.432-3.95c.013-.116.173-.21.357-.21h.921c.183 0 .323.095.311.21l-.432 3.95c-.013.115-.173.21-.357.21z"
            opacity="0.4"
          ></path>
        </g>
        <path
          fill="#263238"
          d="M148.338 138.195l1.515-.74c-.001-.001-.6 1.39-1.515.74z"
        ></path>
        <path
          fill="#FFC3BD"
          d="M127.512 232.094l7.168 5.499-8.306 1.823s-3.538-4.039-2.314-7.293l3.452-.029z"
        ></path>
        <path
          fill="#FFC3BD"
          d="M136.436 244.401L129.399 245.551 125.555 238.326 134.68 237.593z"
        ></path>
      </g>
    </svg>
  );
};
