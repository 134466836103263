import React, { useState } from "react";
import { ShieldCheckIcon, SupportIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { AndiePromiseGuests, AndiePromiseOrganizations, AndiePromiseRestaurants } from "../../components/documents/guarantee";
import sustainability from "../../assets/images/sustainability.svg";
import { SidebarRight } from "../../components/navigation";

export default function AndieGuarantee() {
    const covers = ["Verified users", "Cancellations", "Payments", "Lateness", "Delivery and execution support"];
    const tabs = ["Organizations", "Restaurants & Caterers", "Guests"];

    const [selected, setSelected] = useState("Organizations");

    const policies = [
        {
            title: "Verified users",
            description: "As part of our efforts to ensure smooth transactions, all users are reviewed and pre-vetted before they are allowed onto the platform."
        },
        {
            title: "Cancellations",
            description: "Due to the effort required to execute bid, cancellations are not permitted once a response has been accepted."
        },
        {
            title: "Payments",
            description: "All transactions on Pluck are prepaid. Final payments are released upon successful completion."
        },
        {
            title: "Lateness",
            description: "Failure to show up or deliver expect menu items on time, or within a reasonable time thereafter may result in appropriate penalties."
        },
        {
            title: "Delivery and execution support",
            description: "We provide delivery support to caterers and restaurants who are unable to deliver, and we provide execution support for all transactions."
        },
        {
            title: "Communication is key",
            description: "We encourage all parties in each transaction to overshare information and embrace being the first point-of-contact when issues arise."
        }
    ];

    return (
        <div className="h-screen">
            <div className="flex h-[calc(100%_-_40px)]">
                <div className="andie-side-panel" />
                <div className="andie-main-panel overflow-y-scroll scrollbar-hide pb-14">
                    <div className="flex">
                        <div className="lg:w-4/5 xl:w-3/4">
                            <p className="andie-description-text text-xs"></p>
                            <h1 className="text-xl font-productsans-bold">Our Promise</h1>
                            <div className="my-1 h-[2px] w-24 bg-gray-700" />

                            <p class="mt-8 mb-5 text-xs font-roboto-medium andie-description-text"><em>Last updated: 2024-01-04</em></p>
                            <div className="bg-blue-950 w-full rounded-lg py-32 flex items-center justify-center">
                                <h3 className="font-productsans-medium text-3xl xl:text-7xl text-white"><span className="text-green-600">pluck</span>guarantee</h3>
                            </div>
                        </div>
                        <div className="hidden andie-padding-left lg:inline-flex flex-col items-end w-1/4">
                            <div className="bg-gray-100 h-10 w-10 flex justify-center items-center rounded-full">
                                <ShieldCheckIcon className="h-5 text-green-700" />
                            </div>
                            <div className="mt-4 space-y-3 w-10 flex flex-col items-center">
                                {Array.from({ length: 3 }, () => <div className="h-2 w-2 rounded-full bg-gray-100" />)}
                            </div>
                        </div>
                    </div>
                    
                    <div className="mt-10 xl:w-3/4">
                        <div className="space-y-4">
                            <p className="andie-description-text">Every booking comes with the PluckGuarantee for both companies, restaurants, and caterers. Our commitment to all parties is that we always work to ensure that bookings are executed smoothly and to the satisfaction of all parties involved.</p>

                            <div className="space-y-2">
                                <p className="andie-description-text">The guarantee covers:</p>
                                {covers.map((cover, index) => (
                                    <div key={index} className="flex space-x-2 items-center">
                                        <div className="h-7 w-7 flex justify-center items-center rounded-full bg-gray-100">
                                        <ShieldCheckIcon className="h-4 text-green-700" />
                                        </div>
                                        <p className="andie-description-text font-roboto-medium">{cover}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="mt-12 grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {policies.map((policy, index) => (
                            <div key={index}>
                                <h4 className="font-productsans-medium text-base">{policy.title}</h4>
                                <div className="h-1 w-10 my-2 flex-shrink-0 bg-blue-900" />
                                <p className="my-3 andie-description-text leading-tight">{policy.description} {policy.title === "Verified users" && <span><Link className="underline text-blue-600 font-roboto-medium" to="/policies/disclaimer">Read disclaimer</Link></span>}</p>
                            </div>
                        ))}
                    </div>
                    <div className="mt-12 border-t border-gray-100 pt-6">
                        <div className="rounded-lg p-3 xl:p-4 bg-blue-50">
                            <div className="pb-3 mb-3 border-b border-blue-200 flex space-x-2">
                                {tabs.map((tab, index) => (
                                    <div key={index} onClick={() => setSelected(tab)} className={`cursor-pointer py-1.5 px-3.5 ${selected === tab && "bg-blue-600 rounded-full" }`}>
                                        <h6 className={`font-roboto-medium andie-description-text leading-tight ${selected === tab ? 'text-white' : 'text-blue-600'}`}>
                                            {tab}
                                        </h6>
                                    </div>
                                ))}
                            </div>
                            {selected === "Restaurants & Caterers" && <AndiePromiseRestaurants />}
                            {selected === "Organizations" && <AndiePromiseOrganizations />}
                            {selected === "Guests" && <AndiePromiseGuests />}
                        </div>
                    </div>
                    <div className="mt-6 pt-6 md:flex items-center md:flex-row-reverse md:justify-between border-t border-b border-gray-100">
                        <img src={sustainability} className="h-32 xl:h-60" />
                        <div className="md:w-3/5">
                            <h2 className="text-3xl w-3/4 font-productsans-bold leading-tight">Your partner in building a better future for the world.</h2>
                            <p className="mt-3 andie-description-text leading-tight">Sustainability, social, and corporate responsibilty can mean many things to different people. At Pluck, we are committed to using each corporate catering or dining experience to help you achieve what those commitments mean to you.</p>
                            <Link to="/how-pluck-is-helping-build-a-better-future" className="andie-dark-button h-8 w-24 mt-6">Learn more</Link>
                        </div>
                    </div>
                    <div className="mt-8 border flex items-center justify-between rounded-md p-3">
                        <div className="flex items-center space-x-4">
                            <div className="h-7 w-7 flex justify-center items-center rounded-full bg-blue-100">
                                <SupportIcon className="h-5 text-blue-600" />
                            </div>
                            <p className="andie-description-text">Need to reach us? Contact us by phone during business hours, or via email or chat 24/7.</p>
                        </div>
                        <Link to="/support" className="andie-text-button">Support</Link>
                    </div>
                </div>
                <SidebarRight />
            </div>
        </div>
    )
}