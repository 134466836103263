import { CardElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect } from "react";
import Modal from "react-responsive-modal"
import { Payment } from "../billing";

const URL = "ws://localhost:8001/ws/explore/explore"
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST)
const styles = {
    modal: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        maxWidth: "75%",
        maxHeight: '45%',
        justifyContent: 'center',
    },
}
export default function WebSocketInner() {
    const [input, setInput] = useState('');
    const [open, setOpen] = useState(false)
    // const [data, setData] = useState([]);
    

    return (
        <div>
            <button onClick={() => setOpen(true)}> ye </button>
            <Modal
                blockScroll={false}
                open={open}
                onClose={() => setOpen(false)}
                animationDuration={600}
                showCloseIcon={false}
                styles={styles}
                center>
                    <Payment />
            </Modal>
        </div>
    )
}