import React, { useEffect } from 'react'
import { useState } from 'react'
import { PackageForm } from '..';
import { PopoutModal, calculateDeliveryFee } from '../../common';
import { random } from 'lodash';

const PackageEditor = ({ packages, setPackages, editingPackage, setRefreshPackageList }) => {
    const [packageTitle, setPackageTitle] = useState(editingPackage.title)
    const [restaurantTypes, setRestaurantTypes] = useState(editingPackage.cuisine_type)
    const [serves, setServes] = useState(editingPackage.serves)
    const [minGuests, setMinGuests] = useState(editingPackage.min_guests)
    const [packageItems, setPackageItems] = useState(editingPackage.items)
    const [selectedMenuOption, setSelectedMenuOption] = useState('appetizer')
    const [pricingType, setPricingType] = useState(editingPackage.package_price)
    const [totalPrice, setTotalPrice] = useState(editingPackage.package_price)
    const [deliveryFee, setDeliveryFee] = useState(editingPackage.delivery_fee)
    const [id, setID] = useState(editingPackage.id)

    function handlePackageCreate() {
        const filtered_packages = packages.filter(pkg => pkg.id !== id)
        setPackages([...filtered_packages, {
            title: packageTitle,
            cuisine_type: restaurantTypes,
            serves: serves,
            min_guests: minGuests,
            items: packageItems,
            pricing: pricingType,
            package_price: totalPrice,
            delivery_fee: calculateDeliveryFee(0, false, totalPrice, 0),
            id: id
        }])

        setPackageTitle("")
        setRestaurantTypes([])
        setServes(1)
        setMinGuests(10)
        setPackageItems({
            "appetizer": [],
            "main": [],
            "side": [],
            "dessert": [],
            "beverages": [],
            "other": []
        })
        setPricingType('item')
        setTotalPrice(0)
        setDeliveryFee(0)
        setRefreshPackageList(random(99999))
    }
    return (
        <PopoutModal
            title={"Edit Package"}
            button={
                <button type='button' className="andie-text-button">Edit Package</button>
           }
            actionButton={
                <button onClick={() => handlePackageCreate()} type="button" className='andie-dark-button mb-5' >{"Edit Package"}</button>
            }
        >
            <PackageForm
                packages={packages} setPackages={setPackages}
                packageTitle={packageTitle} setPackageTitle={setPackageTitle}
                restaurantTypes={restaurantTypes} setRestaurantTypes={setRestaurantTypes}
                serves={serves} setServes={setServes}
                minGuests={minGuests} setMinGuests={setMinGuests}
                packageItems={packageItems} setPackageItems={setPackageItems}
                selectedMenuOption={selectedMenuOption} setSelectedMenuOption={setSelectedMenuOption}
                pricingType={pricingType} setPricingType={setPricingType}
                totalPrice={totalPrice} setTotalPrice={setTotalPrice}
                deliveryFee={deliveryFee} setDeliveryFee={setDeliveryFee}
            />
        </PopoutModal>
    )
}

export default PackageEditor