import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountsAPI } from "../../apis";
import { findOptions, industryOptions } from "../common";
import { toast } from "react-hot-toast";

export default function AccountRequestAccess({ redirect }) {
    const navigate = useNavigate();

    const [business, setBusiness] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [contactTitle, setContactTitle] = useState("");

    const [industry, setIndustry] = useState("Banking");
    const [find, setFind] = useState("Select an option");
    const [comments, setComments] = useState("");

    function selectIndustryType(option) {
        setIndustry(option);
    }

    function selectFindType(option) {
        setFind(option);
    }

    function formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        return null;
    }

    function formatEmail(email) {
        return email.toLowerCase()

    }

    async function submit() {
        if (!business || !firstName || !lastName || !contactTitle || !email) {
            if (!business) {
                toast("Organization needs to be filled!")
            }
            if (!firstName) {
                toast("First Name needs to be filled!")
            }
            if (!lastName) {
                toast("Last Name needs to be filled!")
            }
            if (!contactTitle) {
                toast("Job Title needs to be filled!")
            }
            if (!email) {
                toast("Email Address needs to be filled!")
            }
        } else {
            const payload = {
                organization: business,
                // business_type: businessType,
                first_name: firstName,
                last_name: lastName,
                title: contactTitle,
                email: formatEmail(email),
                // cuisine: cuisine,
                industry: industry,
                phone: formatPhoneNumber(phone),
                find: find,
                comments: comments
                // access_code: accessCode(10)
            }
            await AccountsAPI.requestAccessCode(payload).then((res) => {
                if (redirect) {
                    navigate('/success-request');
                }
            })
        }
    }

    return (
        <>
            <form className="mt-4">
                <div>
                    <label className="bid-form-input-label">Organization</label>
                    <input type="text" id="business_name" placeholder="e.g. ABC Corporation" className="w-full px-4 py-2 mt-2 andie-description-text transition duration-500 ease-in-out transform border-transparent rounded-sm bg-blue-50 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ext-black focus:border-blueGray-500" required onChange={(e) => setBusiness(e.target.value)} value={business} />
                </div>
                <div className="flex mt-4 space-x-3">
                    <div className="w-full md:w-1/2 md:mb-0">
                        <label className="bid-form-input-label"> First Name </label>
                        <input className="block w-full px-4 py-2 mt-2 andie-description-text transition duration-500 ease-in-out transform border-transparent rounded-sm bg-blue-50 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ext-black focus:border-blueGray-500" id="first_name" type="text" placeholder="First Name" required onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                    </div>
                    <div className="w-full md:w-1/2">
                        <label className="bid-form-input-label"> Last Name </label>
                        <input className="block w-full px-4 py-2 mt-2 andie-description-text transition duration-500 ease-in-out transform border-transparent rounded-sm bg-blue-50 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ext-black focus:border-blueGray-500 " id="last_name" type="text" placeholder="Last Name" required onChange={(e) => setLastName(e.target.value)} value={lastName} />
                    </div>
                </div>
                <div className="mt-4">
                    <label className="bid-form-input-label">Email Address</label>
                    <input type="email" id="email" placeholder="Email Address" className="w-full px-4 py-2 mt-2 andie-description-text transition duration-500 ease-in-out transform border-transparent rounded-sm bg-blue-50 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ext-black focus:border-blueGray-500" autocomplete="" required onChange={(e) => setEmail(e.target.value)} value={email} />
                </div>
                <div className="flex mt-4 space-x-3">
                    <div className="w-full md:w-[55%] md:mb-0">
                        <label className="bid-form-input-label"> Job Title </label>
                        <input className="block w-full px-4 py-2 mt-2 andie-description-text transition duration-500 ease-in-out transform border-transparent rounded-sm bg-blue-50 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ext-black focus:border-blueGray-500" id="contact_title" type="text" placeholder="Job Title" required onChange={(e) => setContactTitle(e.target.value)} value={contactTitle} />
                    </div>
                    <div className="w-full md:w-[45%]">
                        <label className="bid-form-input-label"> Phone Number </label>
                        <input className="block w-full px-4 py-2 mt-2 andie-description-text transition duration-500 ease-in-out transform border-transparent rounded-sm bg-blue-50 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ext-black focus:border-blueGray-500 " id="phone_number" type="tel" placeholder="E.g. 647-123-4567" required onChange={(e) => setPhone(e.target.value)} value={phone} />
                    </div>
                </div>
                <div className="mt-4 mb-6">
                    {/* <label className="bid-form-input-label">{businessType === "Corporate" ? "Industry" : "Cuisine"}</label> */}
                    <label className="bid-form-input-label">{"Industry"}</label>
                    <div className="mt-2 flex space-x-4">
                        <select id="event_type" onChange={(e) => selectIndustryType(e.target.value)} className="w-full px-3 border-transparent bg-blue-50 rounded focus:ring-0 focus:border-0 h-9 andie-description-text">
                            {industryOptions.map((item, index) => (
                                <option selected={item.name === industry}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="flex space-x-3">
                    <div className="w-full mb-5">
                        <label className="bid-form-input-label">{"Where did you hear about us?"}</label>
                        <div className="mt-2 flex space-x-4">
                            <select id="find_type" onChange={(e) => selectFindType(e.target.value)} className="w-full px-3 border-transparent bg-blue-50 rounded focus:ring-0 focus:border-0 h-9 font-sf-regular text-xs text-black">
                                {findOptions.map((item, index) => (
                                    <option selected={item.label === industry}>{item.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {(find == "Other" || find == "Pluck Sales Team") &&
                        <div className="w-full">
                            <label className="block text-sm font-medium leading-relaxed text-blueGray-700">{"Tell us more"}</label>
                            <div className="mt-2 flex space-x-4">
                                <input onChange={(e) => setComments(e.target.value)} className="block w-full px-4 py-2  text-sm text-black transition duration-500 ease-in-out transform border-transparent rounded-sm bg-blue-50 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ext-black focus:border-blueGray-500 " id="find_comment" type="text" placeholder="Leave a comment" />
                            </div>
                        </div>
                    }
                </div>

            </form>
            <div onClick={() => submit()} className="andie-dark-button bg-black mb-10">Signup</div>
        </>
    )
}

