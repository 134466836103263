import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { CalendarPopout } from '../../components/admin';

const localizer = momentLocalizer(moment);

const AdminCalendar = ({ bids }) => {
    const [selectedBid, setSelectedBid] = useState({})
    const [events, setEvents] = useState([]);

    useEffect(() => {
        let tempEvents = []
        for (const bid of bids) {
            tempEvents.push({
                start: moment(bid.event_date_time).toDate(),
                end: moment(bid.event_date_time)
                    .add(1, "hour")
                    .toDate(),
                title: bid.organization + " - " + bid.bid_title,
                bidObj: bid
            })
        }

        setEvents(tempEvents)
    }, [bids]);

    function handleEventClick(event) {
        setSelectedBid(event.bidObj)
        document.getElementById('calendarEventModal').showModal()
    }

    return (
        <div className='flex font-productsans-regular justify-center items-center h-full'>
            <CalendarPopout ID={'calendarEventModal'} selectedBid={selectedBid} />
            <Calendar
                selectable
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                events={events}
                style={{
                    height: "100%",
                    width: "100%",
                    fontSize: '14px',
                }}
                onSelectEvent={handleEventClick}
            />
        </div>
    )
}

export default AdminCalendar;
