import { useState } from "react";
import Modal from "react-responsive-modal";
import { XIcon } from "@heroicons/react/solid";
import BillingDetailsForm from "./BillingDetailsForm";

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        width: "85%",
        height: '90%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

export default function BillingDetails({ page, editBilling, setFirstname, firstname, setLastname, lastname, setEmail, email, setAddress, address, setCity, city, setPostalCode, postalCode, setProvince, province, setPhone, phone, setCountry, country }) {
    const [editBillingModal, setEditBillingModal] = useState(false);
    
    function handleSave() {
        if (page == "account") {
            editBilling()
        } 
        setEditBillingModal(false)
    }
    return (
        <>
            <Modal
                blockScroll={false}
                open={editBillingModal}
                center
                showCloseIcon={false}
                styles={styles}
                onClose={() => setEditBillingModal(false)}
                >
                <div className="p-8">
                    <div className="flex justify-between items-center border-b w-full">
                        <h6 class="text-base font-productsans-medium text-gray-700 mb-4" id="slide-over-title">Edit Billing Information</h6>
                        <div onClick={() => setEditBillingModal(false)} className="cursor-pointer mb-3.5 h-6 w-6 flex items-center justify-center bg-gray-200 rounded-full">
                            <XIcon className="h-4" />
                        </div>
                    </div>
                    <BillingDetailsForm setFirstname={(e) => setFirstname(e)} firstname={firstname} setLastname={(e) => setLastname(e)} lastname={lastname} setEmail={(e) => setEmail(e)} email={email} setAddress={(e) => setAddress(e)} address={address} setCity={(e) => setCity(e)} city={city} setPostalCode={(e) => setPostalCode(e)} postalCode={postalCode} setProvince={(e) => setProvince(e)} province={province} setPhone={(e) => setPhone(e)} phone={phone} setCountry={(e) => setCountry(e)} country={country} />
                    <div className='flex justify-end pt-6'>
                        <button className="andie-dark-button w-28" onClick={() => handleSave()}> Save </button>
                    </div>
                </div>
            </Modal>
            <div className="mt-4">
                {page == "account" ?
                    null
                :
                    <div className="flex items-center justify-between border-b pb-2 mb-3">
                        <h2 className="text-sm font-productsans-medium text-gray-600">Billing Information</h2>
                    </div>
                }
                {email === "" ?
                    <div className="bg-red-200 p-3 rounded-md">
                        <p className="andie-description-text leading-tight font-roboto-medium">There is no billing information saved for this account. Add your billing information to proceed.</p>
                    </div>
                    :
                    <div className="">
                        <h6 className="text-paragraph font-roboto-medium text-gray-600">{firstname + " " + lastname}</h6>
                        <p className="andie-description-text text-gray-500"> {email} </p>
                        <p className="text-xs font-roboto-regular text-gray-400">{address + ', ' + city + ', ' + province + ' ' + postalCode}</p>
                    </div>
                }
                <button type="button" onClick={() => setEditBillingModal(true)} className="mt-2 andie-text-button">
                    {email == "" ? "Add billing details" : "Edit details"}
                </button>
            </div>
        </>
    )
}