import { CubeIcon, InformationCircleIcon } from '@heroicons/react/solid';
import React from 'react'
import { GifticonAlt } from '../../../assets/icons';

const TemplateTitleAndPrice = ({renderPrice, item, orderInProgress }) => {
    const frommodal = true;
    return (
        <div className="flex w-full mr-4 mt-1.5 md:mt-0 items-center justify-between space-x-2">
            <div className="flex space-x-2 w-5/6">
                <div className="bg-blue-100 h-[72px] w-[72px] md:h-16 md:w-16 shrink-0 flex justify-center items-center rounded-xl">
                    <GifticonAlt height={40} />
                </div>
                <div className="flex flex-col justify-between">
                    <div>
                    <h5 className="text-lg font-productsans-bold leading-none text-gray-700">{item.title}</h5>
                    {renderPrice(item.packages, frommodal)}
                    </div>
                    <div className="h-5 px-2 inline-flex w-24 items-center bg-gray-100 rounded-full space-x-0.5">
                        <CubeIcon className="h-3.5 text-blue-800" />
                        <p className="text-xs leading-none font-roboto-medium text-gray-700">
                            {item.packages.length} Packages
                        </p>
                    </div>
                </div>
            </div>
            {orderInProgress &&
                <div className="bg-yellow-50 inline-flex w-36 py-1 px-2.5 space-x-1.5 animate-pulse duration-1000 rounded-full">
                    <InformationCircleIcon className="h-4 flex-shrink-0 text-yellow-600" />
                    <p className="andie-description-text text-xs font-roboto-medium leading-tight text-yellow-600">There is a pending order in your cart!</p>
                </div>
            }
        </div>
    )
}

export default TemplateTitleAndPrice;