import React, { useEffect, useState } from 'react';
import { calculatePackageTotaPrice, getPackageTransactionAmounts, menuoptions, pricetoString } from '../../common';
import { TemplateOrderItemQuantity } from '..';

function TemplateOrderItemSelect({ pkg, setPackageItems, setIsNextButtonDisabled, setTotalPrice, packageQuantities, setPackageQuantities, items, setItems, setGuestCount, getNumPpl }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    

    useEffect(() => {
        setTotalPrice(getPackageTransactionAmounts(
            pkg,
            packageQuantities,
            parseFloat(user['service_fee']),
            1
        ))
        setIsNextButtonDisabled(!validateOrderItems())
    }, [packageQuantities]);

    function handleSetItems(itemName, itemID, quantity) {
        if (items.length > 0) {
            const temp = packageQuantities

            const index = items.findIndex(item => (item.id == itemID) && (item.name == itemName))
            items[index].quantity = quantity

            calculateAllocation()
            setPackageItems(items)
            setGuestCount(getNumPpl())
        }

    }

    function calculateAllocation() {
        let temp = {}
        console.log('-----------')
        for (const item of items) {
            const tempMO = item.menuOption
            // console.log(item)
            if (temp[tempMO]) {
                temp[tempMO] = temp[tempMO] + (item.quantity * item.serves)
            } else {
                temp[tempMO] = (item.quantity * item.serves)
            }
        }

        setPackageQuantities(temp)
    }


    function validateOrderItems() {
        for (const option of menuoptions) {
            if (validateQuantities(option).status === false) {
                return false
            }
        }
        return true

    }

    function validateQuantities(option) {
        let res = {}
        option.value === 'main' ?
            res = {
                status: packageQuantities[option.value] >= pkg.min_guests,
                errorText: `Choose a minimum of ${pkg.min_guests} ${option.title} options.`
            }
            :
            res = {
                status: (pkg.items[option.value].length === 0) || (packageQuantities[option.value] >= packageQuantities.main),
                errorText: `Please select enough ${option.title} options for ${packageQuantities.main} people.`
            }
        return res
    }

    function renderErrorQuantities(option) {
        var result = validateQuantities(option)
        return !result.status && <h6 className="font-roboto-regular text-xs text-red-600 my-2">{result.errorText}</h6>
    }

    return (
        <div className="flex flex-col max-w-fit pb-10">
            <div className='flex justify-between'>
                <div>
                    <p className='text-gray-700 font-productsans-bold text-base'>{pkg.title}</p>
                    <div className="bg-gray-100 inline-flex py-1 px-2 rounded-full text-xs leading-none font-roboto-medium text-gray-700">{pkg.pricing === "package" ? "Bundle pricing" : "Priced per item"}</div>
                </div>
                <div className='items-end text-right'>
                    <div className="bg-green-100 inline-flex flex-col py-1.5 px-2 rounded">
                        <h6 className="text-paragraph leading-none font-productsans-bold text-green-700">{calculatePackageTotaPrice(pkg.items, pkg.pricing, pkg.package_price)}</h6>
                        <p className='bid-form-input-label mb-0 text-xs'>{(pkg.serves > 1 && pkg.pricing === "package") ? "Subtotal": " per Head"}</p>
                    </div>
                    

                    <p className='mt-4 bid-form-input-label leading-none whitespace-nowrap mb-0.5 text-blue-600'>Feeds {packageQuantities.main} people.</p>
                    <p className="font-roboto-medium text-gray-500 text-xs leading-tight">Minimum order: {pkg.min_guests}</p>
                </div>
            </div>

            {menuoptions.map(option => (
                pkg.items[option.value].length !== 0 &&
                <div className="first:border-t-0 border-t border-gray-100 pt-4 mt-4 flex flex-col">
                    <h6 className="bid-form-input-label mb-0">{option.title}</h6>
                    <p className="andie-section-descriptor">Select from the following options:</p>

                    <div className='space-y-3'>
                        {pkg.items[option.value].map((product, itemIndex) => {
                            return (
                                <div className="bg-blue-50 rounded-md p-3">
                                    <TemplateOrderItemQuantity product={product} initialQuantity={product.quantity} handleSetItems={handleSetItems} />
                                </div>
                            )
                        })}
                    </div>

                    {renderErrorQuantities(option)}
                </div>

            ))}



        </div>
    );
}

export default TemplateOrderItemSelect;
