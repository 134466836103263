
export default function BidResponseLumpSum({ bid, lumpSum, setLumpSum, sumAmount, setSumAmount }) {

    return (
        <>
            {(bid.cge_type == "Catering" || bid.cge_type == "Catering Plus") &&
                <div className="flex border-0 w-full justify-between h-10 rounded-sm flex-wrap">
                    <div className="flex flex-row gap-x-2">
                        <input type="checkbox" checked={lumpSum} onChange={() => setLumpSum(!lumpSum)} className="checkbox self-center bg-indigo-50" />
                        <p className="text-xs self-center text-gray-500 font-productsans-regular">
                            Lump some amount?
                        </p>
                    </div>
                    {lumpSum &&
                        <input
                            className="bid-form-input"
                            id="contact_name"
                            type="text"
                            placeholder="Delivery Fee"
                            required
                            aria-label="Delivery Fee"
                            onChange={(e) => setSumAmount(e.target.value)}
                            value={sumAmount}
                        />}
                </div>
            }
        </>
    )
}