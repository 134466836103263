import moment from 'moment';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import BidResponseReceipt from './BidResponseReceipt';
import LabelBidResponse from './LabelBidResponse';
import { PopoutModal } from '../common';
import { PrinterIcon, SaveIcon } from '@heroicons/react/outline';

export default function ResponseDocumentsDownload({ cgeAccountList, businessAccountList, staffAccountList, response }) {
    const [issueDate, setIssueDate] = useState(moment(moment.now()).toDate())

    function renderReceipts(response) {
        const cgeAcc = cgeAccountList.find((acc) => acc.email === response.cge_email)
        const businessAcc = businessAccountList.find((acc) => acc.email === response.business_email)
        const cgeAccManager = staffAccountList.find((acc) => acc.email === cgeAcc?.cge_representative)
        const businessAccManager = staffAccountList.find((acc) => acc.email === businessAcc?.business_representative)
        return (
            <div className="grid grid-cols-2 gap-4 mt-4">
                <BidResponseReceipt
                    response={response}
                    account_type={1}
                    account={cgeAcc}
                    representative={cgeAccManager}
                    issueDate={issueDate}
                    admin={true}
                />
                <BidResponseReceipt
                    response={response}
                    account_type={2}
                    account={businessAcc}
                    representative={businessAccManager}
                    issueDate={issueDate}
                    admin={true}
                />
                <LabelBidResponse response={response} admin={true} />
                <BidResponseReceipt
                    response={response}
                    account_type={1}
                    itemsOnly={true}
                    account={cgeAcc}
                    representative={cgeAccManager}
                    issueDate={issueDate}
                    admin={true}
                />
            </div>
        )
    }

    return (
        <PopoutModal title="Download Documents" button={
            <div className="cursor-pointer h-8 w-16 px-3 bg-blue-100 rounded flex items-center justify-center">
                <PrinterIcon className="h-5 text-blue-600" />
            </div>
        }>
            <div className="p-8">
                <div>
                    <span className="flex flex-row space-x-2">
                        <p className="w-1/6 mt-2">Issue Date: </p>
                        <ReactDatePicker
                            name="date"
                            dateFormat="MMMM d, yyyy"
                            className="w-full bid-form-input"
                            selected={moment(issueDate).toDate()}
                            onChange={(date) => setIssueDate(moment(date).toDate())}
                            minDate={moment().toDate()}
                            nextMonthButtonLabel=">"
                            previousMonthButtonLabel="<"
                            startOpen={false}
                        />
                    </span>
                </div>
                {renderReceipts(response)}
            </div>
        </PopoutModal>
    );
}
