import { moneySignText, pricetoString, getTransactionAmounts } from "../common/global";

export default function BidResponseTotalDetails({ items, cge_service_fee_rate, business_service_fee_rate, delivery_fee, business_delivers, tipAmount, cge_type, user_override, pricingType, totalPrice }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const type = user_override ? user_override : user != null ? user.account_type : 1;
    const displayValues = user_override ? true : user ? true : false

    const transAmounts = getTransactionAmounts(items, tipAmount, delivery_fee, business_delivers, cge_service_fee_rate, business_service_fee_rate, user_override, pricingType, totalPrice)
    const subtotal = transAmounts.subtotal
    const serviceFee = transAmounts.serviceFee
    const deliveryFee = transAmounts.deliveryFee
    const tax = transAmounts.tax
    const tip = transAmounts.tip

    const total = transAmounts.total

    const showDeliveryFee = !((type === 2) && (!business_delivers)) && (cge_type == "Catering" || cge_type == "Catering Plus")
    return (
        <div>
            <div className="flex justify-between">
                <div className="flex flex-col space-y-1.5 bid-label font-roboto-bold text-gray-600">
                    <p>Subtotal</p>
                    <div className="mb-2" />
                    <p className="ml-2"> + Service Fee</p>
                    {showDeliveryFee && <p className="ml-2"> + {!((type === 2) && (business_delivers)) ? "Pluck " : "Partner"} Delivery Fee</p>}
                    <p className="ml-2"> + Sales Tax</p>
                    <p className="ml-2"> + Tip or Gratuity</p>
                </div>
                {displayValues ?
                    <div className="flex flex-col space-y-1.5 items-end bid-label text-gray-600">
                        <p>${pricetoString(subtotal)}</p>
                        <div className="mb-2" />

                        <p className="mr-2">${pricetoString(serviceFee)}</p>
                        {showDeliveryFee && <p className="mr-2">${pricetoString(deliveryFee)}</p>}
                        <p className="mr-2">${pricetoString(tax)}</p>
                        <p className="mr-2">${pricetoString(tip)}</p>
                    </div>
                    :
                    <div className="flex flex-col space-y-1.5 items-end bid-label text-gray-600">
                        <p>{moneySignText(subtotal)}</p>
                        <div className="mb-2" />
                        <p>{type === 1 ? "" : "-"}{moneySignText(serviceFee)}</p>
                        {showDeliveryFee && <p>{moneySignText(deliveryFee)}</p>}
                        <p>{moneySignText(tip)}</p>
                        <p>{moneySignText(tax)}</p>
                    </div>
                }
            </div>
            <div className="border-t-2 mb-2 border-dashed border-green-700" />
            <div className="flex justify-between text-sm font-productsans-bold">
                <p>Total</p>
                {displayValues ?
                    <p>${pricetoString(total)}</p> :
                    <p>{moneySignText(total)}</p>}
            </div>
        </div>
    )
}
