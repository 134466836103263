// Reuest ACCEESS CODE
import { React } from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/common/Logo";
import { Header } from "../../components/navigation";

import { AccountRequestAccess } from "../../components/account";
import { CheckCircleIcon } from "@heroicons/react/solid";

export default function Registration() {
  function requestBlurb() {
    return (
      <>
        <h2 className="mt-12 mb-2 text-2xl font-productsans-medium text-black leading-tight sm:text-3xl title-font"> Request access code. </h2>
        <div className="w-full mb-8 andie-description-text leading-tight text-gray-500 sm:md:w-3/3 lg:text-1xl">New access codes are approved within 1 business day. An Onboarding specialist will be in touch shortly to support your first bid on Pluck.</div>

        <div className="my-4 space-y-2">
          {["Open a bid for free every time", "Unlimited responses from restaurants and caterers", "Built-in execution and transaction support"].map((item, index) => (
            <div key={index} className="flex space-x-2">
              <CheckCircleIcon className="h-5 text-blue-600" />
              <p className="text-sm text-gray-500 font-productsans-medium">{item}</p>
            </div>
          ))}
        </div>

        <p className="mt-9 mb-6 text-paragraph font-productsans-medium">Are you a restaurant or caterer? <a href="https://pluckbids.com/become-a-partner" target="_blank" className=" andie-text-button">Become a partner</a></p>
      </>
    )
  }

  return (
    <>
      <div className="hidden">
        <Header bypassToken={true} refreshFunction={() => void 0} page="home" />
      </div>
      <section className="flex flex-col items-center h-screen md:flex-row overflow-y-auto md:overflow-hidden m-4">
        <div className="container mx-auto">
          <div className="flex justify-center py-4 mb-4">
            <div className="flex w-full rounded-lg h-[calc(100vh-64px)] lg:w-5/6 xl:w-2/3 lg:shadow-xl ">
              <div className="relative hidden w-full h-auto bg-white bg-cover border-r border-gray-100 rounded-l-lg lg:block lg:w-6/12 xl:w-">
                <div className="relative z-10 m-12 text-left ">
                  <Logo />
                  {requestBlurb()}
                </div>
              </div>
              <div className="w-full py-7 bg-white rounded-sm lg:w-6/12 xl:w-3/5 lg:px-10 lg:py-4 lg:rounded-l-none">
                <div className="relative z-10 text-left ">
                  <div className="lg:hidden">
                    {requestBlurb()}
                  </div>
                  <AccountRequestAccess redirect={true}/>
                  <p className="mb-6 text-center text-paragraph font-productsans-medium">Already have an account? <Link to="/login" className=" text-blue-600 hover:text-blue-700">Login</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
