import React, { useState, useEffect } from "react";
import { CheckIcon, XIcon } from "@heroicons/react/solid";

import { dietaryOptions, packagingOptions } from "../../common";

const styles2 = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        width: "25%",
        height: '40%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

export default function EditEventPreferences({ dietaryPreferences, setDietaryPreferences, dietaryNotes, setDietaryNotes, cge_type, packagingType, setPackagingType, requiresChafing, setRequiresChafing, shafingNotes }) {


    function addDietaryRestriction(option) {
        if (dietaryPreferences.some(item => item.preference === option.preference)) {
            const updated_options = dietaryPreferences.filter(item => item.preference !== option.preference);
            setDietaryPreferences(updated_options);
        } else {
            setDietaryPreferences([...dietaryPreferences, option]);
        }
    }

    const handlePresentationSelect = (option) => {
        option !== 'Buffet / Food Station' && setRequiresChafing(false);
        setPackagingType([option]);
    }


    return (
        <div className="flex flex-col border-b py-5">
            <h6 className="bid-form-input-label md:w-1/4 xl:w-1/5 md:mb-0">Presentation style</h6>
            <div className="space-y-3 my-3 md:space-y-0 md:flex justify-between md:w-3/4 text-xs">
                {packagingOptions.map((option, index) => {
                    return <>
                        {option === 'Buffet / Food Station'
                            ? <>
                                <div className="dropdown md:mt-0 mt-1">
                                    <div onClick={() => handlePresentationSelect(option)} tabIndex={0}>
                                        <div className='flex gap-x-1'>
                                            <div className={`cursor-pointer flex-shrink-0 border-2 h-4 w-4 mr-1 flex items-center justify-center rounded ${packagingType[0] === option ? 'border-blue-600' : 'border-gray-300'}`}>
                                                {option === packagingType[0] && <div className="h-2 w-2 rounded-sm bg-blue-600" />}
                                            </div>
                                            <h6 className='bid-form-input-label mb-0 text-gray-500 text-opacity-80'>{option}</h6>
                                        </div>
                                        <div tabIndex={0} className="dropdown-content z-[1] bg-gray-50 shadow-lg menu p-2.5 rounded-sm w-64">
                                            <div className='flex justify-between gap-x-2'>
                                                <div onClick={() => shafingNotes()} className={` cursor-pointer border-2 mt-0.5 h-4 w-4 flex flex-shrink-0 items-center justify-center rounded ${requiresChafing ? 'bg-blue-600  border-blue-600' : 'border-gray-300'}`}>
                                                    {requiresChafing && <CheckIcon className="h-4 rounded-sm text-white" />}
                                                </div>
                                                <div>
                                                    <p className="bid-form-input-label mb-0 text-gray-500">Do you require any chafing dishes?</p>
                                                    <p className='andie-section-descriptor mb-0'>Additional charges may be incurred for chafing dishes. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='flex gap-x-1 md:mt-0 mt-1'>
                                    <div onClick={() => handlePresentationSelect(option)} className={`cursor-pointer flex-shrink-0 border-2 h-4 w-4 mr-1 flex items-center justify-center rounded ${packagingType[0] === option ? 'border-blue-600' : 'border-gray-300'}`}>
                                        {option === packagingType[0] && <div className="h-2 w-2 rounded-sm bg-blue-600" />}
                                    </div>
                                    <h6 className='bid-form-input-label mb-0 text-gray-500 text-opacity-80'>{option === 'Individually' ? <>{option} {cge_type?.includes("Catering") ? "packaged" : "served"}</> : <>{option}</>}</h6>
                                </div>
                            </>
                        }
                    </>
                })}
            </div>

            <h1 className="bid-form-input-label">Dietary Preferences</h1>
            <div className="w-full flex flex-wrap mt-3">
                {dietaryOptions.map((option, index) => (
                    <div className="w-1/4 pr-2 mb-4 flex items-center space-x-1">
                        <div onClick={() => addDietaryRestriction(option)} className={`mr-1 cursor-pointer border-2 h-4 w-4 flex items-center justify-center rounded ${dietaryPreferences.some(item => item.preference === option.preference) ? 'bg-blue-600  border-blue-600' : 'border-gray-300'}`}>
                            {dietaryPreferences.some(item => item.preference === option.preference) && <CheckIcon className="h-4 rounded-sm text-white" />}
                        </div>
                        <p className="bid-form-input-label mb-0">{option.preference}</p>
                    </div>
                ))}
            </div>
            <textarea
                className="mt-6 w-full h-48 bid-form-input"
                id="cus_notes"
                onChange={(e) => setDietaryNotes(e.target.value)}
                type="text"
                placeholder="If you have specific dietary requirements, please let us know here. For example, '2 guests need vegan options'."
                aria-label="Name"
                value={dietaryNotes}
            />
        </div>
    )
}