import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { toast } from "react-hot-toast";

export function clearToken(){
    localStorage.clear()
    Cookies.remove('admin_user_token')
    Cookies.remove('user_token')
}

export function setToken(token) {
    localStorage.setItem("user_token", token);
    try {
        Cookies.set(
            'user_token',
            token,
            {
                expires: new Date(jwt_decode(token).exp * 1000)
            }
        )
    } catch (e) {
        toast.error('Invalid Token')
    }
}

export function setStaffToken(token) {
    try {
        Cookies.set(
            'admin_user_token',
            token,
            {
                expires: new Date(jwt_decode(token).exp * 1000)
            }
        )
    } catch (e) {
        toast.error('Invalid Token')
    }
}

export function isTokenAlive(jwt) {
    try {
        const decoded = jwt_decode(jwt)
        const expiry = new Date(decoded.exp * 1000)
        const current = new Date();
        if (expiry > current) {
            return true

        } else {
            // localStorage.clear();
            // Cookies.remove('user_token')
            return false
        }
    } catch (e) {
        // localStorage.clear();
        // Cookies.remove('user_token')
        return false
    }

}

export function getToken() {
    const staffToken = getStaffToken()
    if (staffToken == null) {
        const jwt = Cookies.get('user_token')
        if (jwt !== null) {
            if (isTokenAlive(jwt)) {
                return jwt
            } else {
                return null
            }
        } else {
            return null
        }

    } else return staffToken
}
export function getStaffToken() {
    const jwt = Cookies.get('admin_user_token')
    if (jwt !== null) {
        if (isTokenAlive(jwt)) {
            return jwt
        } else {
            return null
        }
    } else {
        return null
    }
}

export function removeToken() {
    Cookies.remove('user_token')
}

export function removeStaffToken() {
    Cookies.remove('admin_user_token')
}

export function isAuthenticated() {
    const token = getToken()
    try {
        jwt_decode(token)
        if (isTokenAlive(token)) {
            return true
        } else {
            return false
        }
    } catch (e) {
        return false
    }
}

export function isStaffAuthenticated() {
    const token = getStaffToken()
    try {
        jwt_decode(token)
        if (isTokenAlive(token)) {
            return true
        } else {
            return false
        }
    } catch (e) {
        return false
    }
}
