import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router';

import { AccountsAPI, BiddingAPIs, StripeAPI } from '../../../apis';
import { PopoutModal } from '../../common';
import { ResponseFormAdditionalNotes, ResponseFormContact, ResponseFormDelivery, ResponseItemForm } from '..';
import { StripeReminder } from '../../billing';
import { ThreeDots } from 'react-loader-spinner';

export default function BidResponseForm({ bid, responses, setshowDrawer }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const menu_options = bid.menu_options
    const acceptableRestaurants = bid.restaurant_type
    const [restaurantName, setRestaurantName] = useState(user.business_name);
    const [contactName, setContactName] = useState(`${user.first_name} ${user.last_name}`);
    const [contactEmail, setContactEmail] = useState(user.email);
    const [contactTitle, setContactTitle] = useState(user.job_title);
    const [contactPhone, setContactPhone] = useState(user.phone);
    const [restaurantType, setRestaurantType] = useState(user.restaurant_type ? user.restaurant_type : "");
    const [responseItems, setResponseItems] = useState([]);
    const [responseImages, setResponseImages] = useState([]);

    const [accessibility, setAccessibility] = useState({ "Sidewalk": false, "Parking": false, "Stairs": false, "Elevators": false })
    const [isLoading, setIsLoading] = useState(false);
    const [additionalNotes, setAdditionalNotes] = useState("")

    const [businessDelivers, setBusinessDelivers] = useState(false)
    const [deliveryFee, setDeliveryFee] = useState(0)
    const [pricingType, setPricingType] = useState("item")
    const [totalPrice, setTotalPrice] = useState(0)

    const navigate = useNavigate();

    const [stripeLink, setStripeLink] = useState(false);
    const [stripeModal, setStripeModal] = useState(false);


    async function checkStripe() {
        if (user.account_type == 2) {
            const link = await StripeAPI.generateAPILink(user.email);
            if (link != false) {
                setStripeLink(link)
                return false
            } else {
                await AccountsAPI.VerifyUserOnLogin()
                return true
            }
        }
    }



    const submitBidResponse = async () => {
        setIsLoading(true);
        if (user.account_type == 2) {
            let res = await checkStripe(true)
            if (res == false) {
                setStripeModal(true)
                setIsLoading(false)
                return
            }
        }
        if ((acceptableRestaurants.includes(restaurantType) === false) && (acceptableRestaurants.includes("Any") === false)) {
            // alert(`Unfortunately, you cannot submit a bid response. The bid owner is only accepting bid responses from ${acceptableRestaurants.join(', ')} restaurants.`)
            toast("This bid is requesting responses from " + acceptableRestaurants.join(', ') + " restaurants. Your response may not be accepted.")

            // setIsLoading(false);
        }
        if (restaurantType == "") {
            toast.error("Please select a restaurant type!")

            setIsLoading(false);
        } else {
            // if (responses.filter(item => item.cge_email === user.email).length > 0) {
            if (responses.filter(item => item.cge_email === user.email).length > 10) {
                // alert('You have already submitted a response to this CGE bid.')
                toast.error('You have already submitted a response to this CGE bid.')

            } else {
                if (responseItems <= 0) {
                    toast.error('You have not added any items to your bid response! Please make sure you click "Save Item" button to add an item to your bid response.')
                    setIsLoading(false);
                } else {
                    // if (responseItems.filter(item => menu_options.includes(item.menuoption)).length == 0) {
                        // toast.error("Please include atleast one of the following menu options: " + menu_options)
                        // setIsLoading(false);
                    // } else {
                        const payload = {
                            restaurant_name: restaurantName,
                            // restaurant_type: restaurantType,
                            restaurant_type: "Caterer",
                            restaurant_location: user.place_id,
                            contact_name: contactName,
                            contact_title: contactTitle,
                            contact_phone: contactPhone,
                            contact_email: contactEmail,
                            business_email: user.email,
                            bid_id: bid.id,
                            items: responseItems,
                            images: responseImages,
                            accessibility: accessibility,
                            additional_notes: additionalNotes,
                            business_delivers: businessDelivers,
                            delivery_fee: deliveryFee,
                            pricing: pricingType,
                            total_price: totalPrice
                            // cge_email: user.email
                        }
                        
                        await BiddingAPIs.submitBidResponse(payload).then(() => {
                            setIsLoading(false);

                            // navigate('/home/bids')
                            setshowDrawer(false)
                        }).catch(() => {
                            setIsLoading(false);
                            // navigate('/home/bids')
                        });

                        //UZAIR need some kind of refresh call

                        // Reset all previously filled items
                        setResponseItems([]);
                    // }

                }
            }
        }
    }

    return (
        <div className="h-[calc(100vh_-_60px)] w-full pt-5 flex flex-col justify-between">
            <PopoutModal
                title={<p> Complete Stripe Signup <span> <div class="badge badge-error">URGENT!</div> </span> </p>}
                modalID={'stripe_signup'}
                trigger={stripeModal}
            >
                <StripeReminder />
            </PopoutModal>
            <div className="overflow-y-scroll space-y-5 scrollbar-hide">
                <ResponseFormContact
                    restaurantName={restaurantName} setRestaurantName={setRestaurantName}
                    contactName={contactName} setContactName={setContactName}
                    contactTitle={contactTitle} setContactTitle={setContactTitle}
                    contactPhone={contactPhone} setContactPhone={setContactPhone}
                    contactEmail={contactEmail} setContactEmail={setContactEmail}
                    restaurantType={restaurantType} setRestaurantType={setRestaurantType}
                    accessibility={accessibility} setAccessibility={setAccessibility}
                    cge_type={bid.cge_type} acceptableRestaurants={acceptableRestaurants} />

                <ResponseItemForm
                    bid={bid}
                    responseItems={responseItems} setResponseItems={setResponseItems}
                    deliveryFee={deliveryFee} businessDelivers={businessDelivers}
                    setDeliveryFee={setDeliveryFee} setBusinessDelivers={setBusinessDelivers}
                    totalPrice={totalPrice} setTotalPrice={setTotalPrice}
                    pricingType={pricingType} setPricingType={setPricingType}
                />

                {/* <ResponseFormDelivery
                    bid={bid}
                    deliveryFee={deliveryFee} setDeliveryFee={(e) => setDeliveryFee(e)}
                    businessDelivers={businessDelivers}
                    setBusinessDelivers={(e) => setBusinessDelivers(e)} /> */}

                <ResponseFormAdditionalNotes
                    additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes}
                    responseImages={responseImages} setResponseImages={setResponseImages} />

            </div>

            <div className="h-[12%] pt-4 flex justify-between">
                <div className="andie-text-button mt-2">Report an issue</div>
                <div onClick={() => isLoading ? null : submitBidResponse()} className="w-32 andie-dark-button">
                    <h6 className={isLoading ? "text-xs text-red font-productsans-medium" : "text-xs text-white font-productsans-medium"}>
                        {isLoading ? <ThreeDots
                            height="30"
                            width="30"
                            radius="9"
                            color="#ffffff"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                            :
                            <p> Submit Response</p>
                        }
                    </h6>
                </div>
            </div>
        </div>
    )
}