import React, { useState } from "react";
import Modal from 'react-responsive-modal';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { LogoutIcon } from '@heroicons/react/outline';
import { clearToken } from '../../apis/Credentials'

export default function Logout() {

    const [logoutModal, setlogoutModal] = useState(true);
    const navigate = useNavigate();
    const closeModal = () => {
        setlogoutModal(false);
        navigate("/")
    };
    const styles = {
        modal: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'none',
            padding: '0',
            margin: '0',
            maxWidth: "75%",
            maxHeight: '45%',
            justifyContent: 'center',
        },
    }
    return (
        <Modal
            blockScroll={false}
            open={logoutModal}
            onClose={closeModal}
            animationDuration={600}
            showCloseIcon={false}
            styles={styles}
            center>
            <div className='bg-white'>
                <div className='px-20 w-full'>
                    <div className='pt-8 pb-3.5 flex flex-grow justify-between items-center border-b border-grey'>
                        <h6 className='font-bold text-base text-black text-opacity-80'>
                            Are you sure you want to logout?
                        </h6>
                    </div>
                    <div className='flex flex-row text-black align-middle justify-center'>
                        <div className='flex flex-row'>
                            <Link to='/' onClick={() => clearToken()}>
                                <div class='h-16 px-4 py-2 flex items-center group'>
                                    <LogoutIcon className='h-5 text-red-600' />
                                    <h6 class='font-bold text-black-500 ml-2 '>
                                        Logout
                                    </h6>
                                </div>
                            </Link>
                            <button onClick={() => closeModal()} type="submit" >
                                <div class='h-16 px-4 py-2 flex items-center group'>
                                    <h6 class='font-bold text-white-500 ml-2 '>
                                        Cancel
                                    </h6>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
