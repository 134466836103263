import React from "react";

export default function TransferringInformation() {
    return (
        <div className="andie-description-text">
            <h1 className="font-productsans-medium text-base mb-3">Transferring Your Personal Information</h1>
            <p>&nbsp;</p>
            <p>We may transfer your personal information that we collect or that you provide as described in this policy to contractors, service providers and other third parties we use to support our business (such as analytics and search engine providers that assist us with Site improvement and optimization) and who are contractually obligated to keep personal information confidential, use it only for the purposes for which we disclose it to them, and to process personal information with the same standards set out in this policy.</p>
            <p>&nbsp;</p>
            <p>We may process, store, transfer your personal information in and to a foreign country, with different privacy laws that may or may not be as comprehensive as Canadian law. In these circumstances, the governments, courts, law enforcement or regulatory agencies of that country may be able to obtain access to your personal information through the laws of the foreign country. Whenever we engage a service provider, we require that its privacy and security standards adhere to this policy and applicable Canadian privacy legislation.</p>
            <p>&nbsp;</p>
            <p>By submitting your personal information or engaging with the Site, you consent to this transfer, storage and processing.</p>
            <p>&nbsp;</p>
        </div>
    )
}