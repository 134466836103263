import React from 'react';

import { calculatePackageTotaPrice, foodOptions, menuoptions } from '../../common';
import { random } from 'lodash';
import PackageEditor from './PackageEditor';


const PackageView = ({ packages, setPackages, refreshPackageList, setRefreshPackageList  }) => {

    function deletePackage(pkg) {
        packages.splice(
            packages.findIndex(tempPkg => tempPkg.packageID === pkg.packageID),
            1
        )
    }

    function displayCuisines(pkg) {
        let stringarr = []
        for (let index = 0; index < pkg.cuisine_type.length; index++) {
            const restaurantType = pkg.cuisine_type[index]
            const option = foodOptions.find((item) => item.value === restaurantType)
            stringarr.push(option.label)
        }
        return stringarr.join(", ")
    }

    function renderMenuOptions(pkg, menuOption) {
        const label = menuoptions.find((item) => item.value === menuOption).title
        const numOptions = pkg.items[menuOption].length

        return `${label} (${numOptions} option${numOptions !== 1 ? 's' : ""})`

    }

    return (
        <div key={refreshPackageList} className="flex flex-col gap-y-2">
            {packages.length > 0 ? packages.map((pkg) =>
                <div className='p-2.5 rounded-md bg-slate-50 shadow m-1'>
                    <div className='flex flex-row justify-between'>
                        <div>
                            <p className='bid-form-input-label mt-3 mb-0'>{pkg.title}</p>
                            <p className='text-xs font-roboto-medium text-gray-400'>{displayCuisines(pkg)}</p>
                        </div>
                        <div className='items-end text-right rounded-md mt-3'>
                            <p className='bid-form-input-label whitespace-nowrap mb-0 text-green-700'>{calculatePackageTotaPrice(pkg.items, pkg.pricing, pkg.package_price)}</p>
                            <p className='bid-form-input-label whitespace-nowrap mt-0 text-xs '>/package</p>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between'>
                        <div className=''>
                            {menuoptions.map(option =>
                                <p className='font-productsans-regular whitespace-nowrap my-0 text-xs '>{renderMenuOptions(pkg, option.value)}</p>
                            )}
                        </div>
                        <div className='items-end text-right'>
                            <p className='bid-form-input-label whitespace-nowrap mt-3 mb-0 text-xs'>serves</p>
                            <p className='bid-form-input-label whitespace-nowrap mt-0 mb-0 text-green-700'>{pkg.serves} people</p>
                            <p className='bid-form-input-label whitespace-nowrap mt-0 text-xs'>/package</p>
                        </div>
                    </div>
                    <div className='flex flex-row justify-between mt-3'>
                        <div className='flex flex-row items-center'>
                            <p className='bid-form-input-label mb-0 whitespace-nowrap text-xs mr-2'>Minimum Order:</p>
                            <p className='bid-form-input-label mb-0 whitespace-nowrap 0 text-green-700'>{pkg.min_guests} Packages</p>
                        </div>
                        <div className='flex flex-row space-x-2'>
                            <div onClick={() => { deletePackage(pkg) }} className='items-end text-right'>
                                <div className='andie-text-button'>Delete</div>
                            </div>
                            <PackageEditor
                                packages={packages} setPackages={setPackages} editingPackage={pkg}
                                setRefreshPackageList={setRefreshPackageList}
                            />
                        </div>
                    </div>
                </div>
            ) :
                <div className="mt-8 text-xs font-roboto-medium text-gray-400 text-center andie-description-text">No packages have been added to this collection.</div>}
        </div>
    );
};

export default PackageView;
