import React, { useState } from "react";
import Modal from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';
import { XIcon } from '@heroicons/react/outline';

import { AccountsAPI } from "../../apis"

export default function Deactivate() {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [deactivateModal, setDeactivatetModal] = useState(false);
    const [description, setDescription] = useState("");
    const navigate = useNavigate();
    const closeModal = () => {
        setDeactivatetModal(false);
        navigate("/")
    };
    const styles = {
        modal: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'none',
            padding: '0',
            margin: '0',
            maxWidth: "75%",
            maxHeight: '45%',
            justifyContent: 'center',
        },
    }
    async function deactivate(event) {
        event.preventDefault();
        const payload = {
            email: user.email,
            reason: description,
        }
        try {
            await AccountsAPI.DeactivateAccount(payload).then((res) => {
                localStorage.clear();
                navigate("/home")
            })
        } catch (e) {
        }

    }

    return (
        <>
            <div onClick={() => setDeactivatetModal(true)} className="flex items-start font-productsans-medium text-sm rounded-full cursor-pointer text-red-700 hover:bg-blue-50">
                Delete account
            </div>
            <Modal
                blockScroll={false}
                open={deactivateModal}
                onClose={closeModal}
                animationDuration={600}
                showCloseIcon={false}
                styles={styles}
                center>
                <div className='bg-white'>
                    <form onSubmit={(e) => deactivate(e)} className='px-20 w-full'>
                        <div className='pt-8 pb-3.5 flex flex-grow justify-between items-center border-b border-grey'>
                            <h6 className='text-sm font-roboto-medium text-black text-opacity-80'>
                                Are you sure you want to deactivate? You cannot undo this action.
                            </h6>
                        </div>
                        <div>
                            <div className="flex items-end mb-2 justify-between w-full">
                                <h6 className='mt-2 text-sm font-roboto-medium text-black text-opacity-80'>Reason for Deactivation</h6>
                            </div>
                            <textarea
                                className="w-full h-32 bid-form-input"
                                id="cus_description"
                                onChange={(e) => setDescription(e.target.value)}
                                type="text"
                                required
                                placeholder=""
                                minLength={15}
                                aria-label="Description"
                                defaultValue={description}
                            />
                        </div>
                        <div className='flex flex-row text-black align-middle justify-center'>
                            <div className='flex flex-row'>
                                <button className="" type="submit">
                                    <div class='h-16 px-4 py-2 flex items-center group'>
                                        <XIcon className='h-5 text-red-600' />
                                        <h6 class='font-bold text-black-500 ml-2 hover:text-red-500'>
                                            Deactivate
                                        </h6>
                                    </div>
                                </button>
                                <button onClick={() => closeModal()} type="button" >
                                    <div class='h-16 px-4 py-2 flex items-center group'>
                                        <h6 class='font-bold text-white-500 ml-2 '>
                                            Cancel
                                        </h6>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    )
}
