import { ChevronRightIcon, PlusIcon } from "@heroicons/react/solid";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CreditCardIcon } from "../../assets/icons";


export default function CardDetails({selectedIndex, pms, setSelectedIndex, creditToggle, setCreditToggle, newCardToggle, setNewCardToggle, savePm, setSavePm}) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const elements = useElements();
    const stripe = useStripe();
    
    function cardSelect(index){
        setSelectedIndex(index)
        setCreditToggle(false)
    }
    
    function newCard(){
        setSelectedIndex(-1)
        setNewCardToggle(true)
    }

    return (
        <>
            <div className="mt-7">
                    <div className="flex items-center justify-between border-b pb-2 mb-3">
                        <h2 className="text-sm font-productsans-medium text-gray-600">Payment Information</h2>
                    </div>
                    {user == null ?
                    <>
                        <div className="flex items-center justify-between border-b pb-2 mb-3 mt-8">
                            <h2 className="text-sm font-productsans-medium text-gray-600">Card Details</h2>
                        </div>
                        <div className={`h-12 rounded items-center flex justify-between text-paragraph font-roboto-medium text-gray-600 cursor-pointer`} onClick={() => setSelectedIndex(-1)}>
                            <CardElement className="hover:bg-gray-200 bid-form-input w-full" id="card"/>
                        </div>
                    </>
                    :
                    <div className="relative">
                        {(selectedIndex == -1 || pms.length == 0) ?
                            <div className="flex items-center justify-between bid-form-input p-3 cursor-pointer hover:bg-gray-50" onClick={() => setCreditToggle(!creditToggle)}>
                                <div className="flex items-center space-x-2">
                                    <CreditCardIcon  height={24} color={"#FF4000"} />
                                    <h6 className="andie-description-text font-roboto-bold">NEW CARD</h6>
                                </div>

                                <ChevronRightIcon className={creditToggle ? `h-5 text-gray-600 rotate-90` : `h-5 text-gray-600`} />
                            </div>
                        :
                            <div className="flex justify-between bid-form-input p-4 cursor-pointer hover:bg-gray-50" onClick={() => setCreditToggle(!creditToggle)}>
                                <div className="font-semibold">{pms[selectedIndex].card.brand.toUpperCase()}</div>
                                <div className="items-center">
                                    •••• {pms[selectedIndex].card.last4}
                                </div>
                                <div className="items-center">
                                    {pms[selectedIndex].card.exp_month}/{pms[selectedIndex].card.exp_year}
                                    </div>
                                <div className="font-semibold " ><ChevronRightIcon className={creditToggle ? `h-5 text-gray-600 rotate-90` : `h-5 text-gray-600`} /></div>
                            </div>
                        }
                        <div className={`max-h-48 absolute w-full inline-block overflow-hidden overflow-y-auto bg-gray-100 border border-gray-200 p-3 z-10 rounded-md ${!creditToggle && 'hidden'}`}>
                            {pms.map((pm, index) => {
                                return (
                                <div className={`${selectedIndex == index && "border-2 border-gray-400"} h-12 rounded-sm px-3 items-center border-b border-gray-100 grid grid-cols-3 justify-between text-paragraph font-roboto-medium text-gray-600 hover:bg-gray-200 cursor-pointer`} onClick={() => setSelectedIndex(index)}>
                                    <div className="items-center flex flex-row">
                                        {pm.card.brand.toUpperCase()}
                                    </div>
                                    <div className="items-center">
                                        •••• {pm.card.last4}
                                    </div>
                                    <div className="items-center">
                                    {pm.card.exp_month}/{pm.card.exp_year}
                                    </div>
                                </div>
                                );
                            })}
                            <>
                                {newCardToggle?
                                    <>
                                        <div className={`${selectedIndex == -1 && "border-2 border-gray-200"} h-12 rounded-md items-center flex justify-between text-paragraph font-roboto-medium text-gray-600 cursor-pointer`} onClick={() => setSelectedIndex(-1)}>
                                            <CardElement onFocus={() => setSelectedIndex(-1)} className="hover:bg-gray-200 bid-form-input w-full" id="card"/>
                                        </div>
                                        <div className="flex justify-between mt-2">
                                            {/* <div className="col-span-1">
                                                <button type="button" className="text-gray-50 bg-green-700 bid-form-input-label p-1" onClick={() => addNewCard()}>Add Payment Method</button>
                                            </div> */}
                                            <div className="flex space-x-1 items-center">
                                                <input type="checkbox" onChange={(e) => setSavePm(!savePm)} />
                                                <p className="bid-form-input-label mb-0"> Save this card</p>
                                            </div>
                                            <div className="col-span-1">
                                                <button type="button" className="andie-dark-button h-7 bg-red-600" onClick={() => {setNewCardToggle(false); setSelectedIndex(0)}}>Cancel</button>
                                            </div>
                                        </div>
                                        
                                    </>
                                :
                                    <div className="h-12 px-3 rounded items-center border-b border-gray-100 flex justify-between text-paragraph font-roboto-medium text-gray-600 hover:bg-gray-200 cursor-pointer" onClick={() => newCard()}>
                                        <div className="items-center text-green-600">
                                            Add another payment method
                                        </div>
                                        <PlusIcon className="h-3.5 text-green-600"/>
                                    </div>
                                }
                            </>
                        </div>
                    </div>
                    }
            </div>
        </>
    )
}