import React, { useState, useEffect } from "react";
import moment from "moment";
import { CheckCircleIcon, UserGroupIcon } from "@heroicons/react/solid";
import { ThreeDots } from "react-loader-spinner";

import { PopoutModal, getTransactionAmounts, pricetoString } from "../common";
import { TransactionReceipt, TransactionItemsModal } from ".";
import { BiddingAPIs } from "../../apis";
import { BidResponseReceipt, LabelBidResponse } from "../responses";
import { pad } from "../common";
import { MailIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

export default function TransactionDetails({ account_type, selectedTransaction, staffAccountList, setShowDrawer }) {
    const navigate = useNavigate();

    let user = JSON.parse(window.localStorage.getItem('user_details'));
    const [isOpen, setIsOpen] = useState(false);
    const [transaction, setTransaction] = useState(selectedTransaction);
    const [loading, setLoading] = useState(false);
    const [accManager, setAccManager] = useState({});

    useEffect(() => {
        selectedTransaction.response.bid = selectedTransaction.bid
        setTransaction(selectedTransaction);
        staffAccountList.forEach((acc) => {
            if (acc.email == user.business_representative || acc.email == user.cge_representative) {
                setAccManager(acc)
            }
        })
    }, [selectedTransaction]);

    const transAmounts = getTransactionAmounts(transaction.response.items, transaction.tipAmount, transaction.delivery_fee, transaction.business_delivers, transaction.bid.cge_service_fee, transaction.response.business_service_fee, account_type, transaction.response.pricing, transaction.response.total_price);
    const subtotal = transAmounts.subtotal;
    const service_fee = transAmounts.serviceFee;
    const delivery_fee = transAmounts.deliveryFee
    const tax = transAmounts.tax
    const gratuity = transAmounts.tip
    const total = transAmounts.total

    const statuses = ["Accepted", "Confirmed", "Completed"];

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    const updateStatus = async (status) => {
        setLoading(true);

        const payload = {
            id: transaction.id,
            status: status
        }
        try {
            var result = await BiddingAPIs.updateTransaction(payload).then(() => { setLoading(false); setShowDrawer(false) });
        } catch (e) {
        }
    }

    function statusDisplay() {
        if (transaction.status === "Accepted") {
            return <div className="bg-yellow-100 inline-flex px-2 py-0.5 font-roboto-bold text-xs items-center rounded-md">{account_type === 1 ? "Awaiting confirmation" : "Confirm to proceed"}</div>
        }

        if (transaction.status === "Confirmed") {
            return <div className="bg-blue-100 inline-flex px-2 py-0.5 font-roboto-bold text-xs text-blue-600 items-center rounded-md">Confirmed</div>
        }

        if (transaction.status === "Completed") {
            return <div className="bg-green-50 inline-flex px-2 py-0.5 font-roboto-bold text-xs text-green-700 items-center rounded-md">Completed</div>
        }
    }

    function goToBid() {
        navigate('/bid/'+ transaction.bid.transaction_code)
    }

    return (
        <>
            <div className="h-full pt-5 pb-20 space-y-3 scrollbar-hide">
                <div className="border-2 p-3 rounded-md border-gray-100">
                    <div className="flex justify-between">
                        <div>
                            <h6 className="font-productsans-medium text-gray-500 text-paragraph uppercase">Transaction ID</h6>
                            <h4 className="text-xl text-gray-400 font-productsans-bold">#{pad(transaction.id, 5)}</h4>
                        </div>
                        <div className="flex flex-col items-end space-y-2">
                            {(account_type === 2 && transaction.status === "Accepted") &&
                                <div onClick={() => updateStatus("Confirmed")} className="andie-dark-button bg-red-500">
                                    {loading ?
                                        <ThreeDots
                                            height="30"
                                            width="30"
                                            radius="9"
                                            color="#fff"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                        :
                                        'Confirm Order'
                                    }
                                </div>}

                            {(account_type === 1 && transaction.status === "Confirmed") &&
                                <div onClick={() => updateStatus("Completed and Paid")} className="andie-dark-button">
                                    {loading ?
                                        <ThreeDots
                                            height="30"
                                            width="30"
                                            radius="9"
                                            color="#fff"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                        :
                                        'Complete and Pay'
                                    }
                                </div>}
                            {transaction.status === "Completed and Paid" || transaction.status === "Completed" &&
                                <div className="bg-[#009473] h-8 w-32 p-2 text-center flex items-center justify-center text-xs text-white rounded-sm font-productsans-medium">
                                    {loading ?
                                        <ThreeDots
                                            height="30"
                                            width="30"
                                            radius="9"
                                            color="#fff"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                        :
                                        'Order Completed'
                                    }
                                </div>}
                            
                        </div>
                        
                    </div>
                    <div className="flex justify-between">
                        {statusDisplay()}
                        <LabelBidResponse response={transaction.response} />
                    </div>
                </div>
                <div className="p-3 flex justify-between border-2 rounded-md border-gray-100">
                    <div className="w-3/4">
                        <h6 className="font-productsans-medium text-gray-500 text-paragraph mb-1 uppercase">Restaurant</h6>
                        <p className="andie-description-text font-roboto-medium">{transaction.response.restaurant_name}</p>
                        <p className="andie-description-text text-gray-400 capitalize text-xs font-roboto-medium">{transaction.response.restaurant_type}</p>
                    </div>
                    <a href={`mailto:${transaction.response.business_representative}?subject=${transaction.bid.bid_title} (Transaction # ${transaction.id})`} className="andie-circle-button">
                        <MailIcon className="text-blue-600 h-4" />
                    </a>
                </div>
                <div className="flex flex-col border-2 rounded-md border-gray-100">
                    <div className="flex px-3 py-3 border-b border-gray-100">
                        <h6 className="bid-label w-2/5 flex-shrink-0 mb-0">Event Title</h6>
                        <p className="andie-description-text leading-tight">{transaction.bid.bid_title}</p>
                    </div>
                    <div className="flex items-center px-3 py-3 border-b border-gray-100">
                        <h6 className="bid-label w-2/5 flex-shrink-0 mb-0">Event type</h6>
                        <p className="andie-description-text leading-tight">{transaction.bid.event_type}</p>
                    </div>
                    <div className="flex items-center px-3 py-3 border-b border-gray-100">
                        <h6 className="bid-label w-2/5 mb-0">Booking type</h6>
                        <p className="andie-description-text font-roboto-medium text-blue-600">{transaction.bid.cge_type}</p>
                    </div>
                    <div className="flex px-3 py-3 border-b border-gray-100">
                        <h6 className="bid-label w-2/5 flex-shrink-0 mb-0">Location</h6>
                        <p className="andie-description-text leading-tight">{transaction.bid.location}</p>
                    </div>
                    <div className="flex items-center px-3 py-3 border-b border-gray-100">
                        <h6 className="bid-label w-2/5 flex-shrink-0 mb-0">Date and time</h6>
                        <p className="andie-description-text leading-tight">{new Date(transaction.bid.event_date_time).toDateString()} at {moment(transaction.bid.event_date_time).format('LT')}</p>
                    </div>
                    <div className="flex items-center px-3 py-3">
                        <h6 className="bid-label w-2/5 flex-shrink-0 mb-0">Headcount</h6>
                        <div className="flex space-x-2 items-center">
                            <UserGroupIcon className="h-4 text-gray-600 flex-shrink-0" />
                            <p className="andie-description-text leading-tight">{transaction.bid.guest_count} guests</p>
                        </div>
                    </div>
                </div>
            
                <div className="flex flex-col justify-between border-2 rounded-md border-gray-100 p-3">
                    <h6 className="font-productsans-medium text-gray-500 text-paragraph mb-1 uppercase">Status</h6>
                    <div className="mt-1">
                        {statuses.map((status, index) => (
                            <>
                                <div id={index} className="flex space-x-1 items-center">
                                    {transaction.status === status ?
                                        <CheckCircleIcon className="h-4 text-blue-600" />
                                        :
                                        <CheckCircleIcon className="h-4 text-blue-300" />
                                    }
                                    <p className={`font-roboto-regular andie-description-text ${transaction.status === status && 'font-roboto-medium text-gray-600'}`}>{status}</p>
                                </div>
                                {status !== "Completed" && <div className="ml-2 h-4 border-l border-gray-200 my-0.5" />}
                            </>
                        ))}
                    </div>
                </div>
                <div className="p-3 flex flex-col justify-between border-2 rounded-md border-gray-100">
                    <div className="flex justify-between">
                        <h6 className="font-productsans-medium text-gray-500 text-paragraph mb-1 uppercase">Financial Details</h6>
                        <PopoutModal
                            title={<div>
                                <h5 className="text-xl font-productsans-medium text-gray-500">Order Items</h5>
                                <p className="text-sm sf-regular text-gray-400">Here is what's included in this order</p>
                            </div>}
                            button={<div className="andie-text-button h-6 cursor-pointer">View items</div>}
                            >
                            <TransactionItemsModal items={transaction.response.items} transaction={transaction} />
                        </PopoutModal>
                    </div>
                    <div className="flex">
                        <div className="mt-4 w-1/2 space-y-3">
                            <div className="flex space-x-1 items-center">
                                <div className="w-1/2">
                                    <h6 className="andie-description-text underline text-gray-500">Subtotal:</h6>
                                </div>
                                <div className="w-1/2 flex justify-end">
                                    <p className="andie-description-text"> ${pricetoString(subtotal)}</p>
                                </div>
                            </div>
                            <div className="flex space-x-1 items-center">
                                <div className="w-1/2">
                                    <h6 className="andie-description-text underline text-gray-500">Service Fee:</h6>
                                </div>
                                <div className="w-1/2 flex justify-end">
                                    <p className="andie-description-text">{account_type === 2 && '-'} ${pricetoString(service_fee)}</p>
                                </div>
                            </div>
                            <div className="flex space-x-1 items-center">
                                <div className="w-1/2">
                                    <h6 className="andie-description-text underline text-gray-500">Delivery Fee:</h6>
                                </div>
                                <div className="w-1/2 flex justify-end">
                                    <p className="andie-description-text"> ${pricetoString(delivery_fee)}</p>
                                </div>
                            </div>
                            <div className="flex space-x-1 items-center">
                                <div className="w-1/2">
                                    <h6 className="andie-description-text underline text-gray-500">Tax:</h6>
                                </div>
                                <div className="w-1/2 flex justify-end">
                                    <p className="andie-description-text"> ${pricetoString(tax)}</p>
                                </div>
                            </div>
                            <div className="flex space-x-1 items-center">
                                <div className="w-1/2">
                                    <h6 className="andie-description-text underline text-gray-500">Gratuity:</h6>
                                </div>
                                <div className="w-1/2 flex justify-end">
                                    <p className="andie-description-text"> ${pricetoString(gratuity)}</p>
                                </div>
                            </div>
                            <div className="flex space-x-1 items-center border-t-2 border-b-2 border-dashed py-3 mb-8">
                                <div className="w-1/2">
                                    <h6 className="andie-description-text font-roboto-bold text-gray-500">Total:</h6>
                                </div>
                                <div className="w-1/2 flex justify-end">
                                    <p className="andie-description-text font-roboto-bold text-gray-500"> ${pricetoString(total)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2 flex flex-col space-y-2 items-end justify-end">
                            <div className="andie-outline-button w-36 h-8">
                                <BidResponseReceipt response={transaction.response} account={user} account_type={user.account_type} admin={false} representative={accManager} />
                            </div>
                            <div className="andie-outline-button w-36 h-8">
                                <LabelBidResponse subsection={true} response={transaction.response} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-between border-2 pt-3 rounded-md border-gray-100">
                    <h6 className="mx-3 font-productsans-medium text-gray-500 text-paragraph mb-1 uppercase">Requirements</h6>
                    <div className="flex px-3 py-3 border-b border-gray-100">
                        <h6 className="bid-label w-2/5 flex-shrink-0 mb-0">Dietary Requirements</h6>
                        <div className="w-full pt-1">
                            <div className="grid md:grid-cols-2 lg:grid-cols-3 mb-2.5">
                                {transaction.bid.dietary_preferences.map((preference, index) => (
                                    <div className="flex items-center">
                                        <div className="h-2 w-2 bg-gray-200 rounded-full mr-2" />
                                        <p className="andie-description-text leading-none text-gray-500">{preference.preference} ({preference.percentage}%)</p>
                                    </div>
                                ))}
                            </div>
                            {transaction.bid.dietary_notes ? 
                                <p className="andie-description-text">{transaction.bid.dietary_notes}</p>
                                :
                                <p className="andie-description-text italic text-gray-300">There are no additional dietary notes.</p>
                            }
                        </div>
                    </div>
                    <div className="flex px-3 py-3 border-b border-gray-100">
                        <h6 className="bid-label w-2/5 flex-shrink-0 mb-0">Equipments required</h6>
                        <p className="andie-description-text leading-tight pr-3">{transaction.requires_chafing ? "This booking requires chafing dishes": "This booking does not require chafing dishes"}.</p>
                    </div>
                    <div className="flex px-3 py-3 border-b border-gray-100">
                        <h6 className="bid-label w-2/5 flex-shrink-0 mb-0">Staffing</h6>
                        <p className="andie-description-text leading-tight pr-3">{transaction.requires_staffing ? "This booking requires event staffing": "This booking does not require any staffing"}.</p>
                    </div>
                    <div className="flex px-3 py-3">
                        <h6 className="bid-label w-2/5 flex-shrink-0 mb-0">{transaction.bid.cge_type.includes("Catering") ? "Delivery" : "Serving"}</h6>
                        <p className="andie-description-text leading-tight">{moment(transaction.bid.delivery_time).format('hh:mm A')}.</p>
                    </div>
                </div>
                <div className="flex justify-end border-b pb-3">
                    <div onClick={() => goToBid()} className="andie-dark-button w-36 bg-blue-100 text-blue-600">
                        View bid
                    </div>
                </div>
                <p className="andie-description-text font-roboto-medium text-gray-400">For Support or any issues with this bid, please contact us at <a className="text-blue-600" href={`mailto:bids@andie.work?subject=${transaction.bid.bid_title}(${transaction.id})`}>bids@andie.work</a>.</p>
            </div>
        </>
    )
}