import React, { useEffect, useState } from "react";
import moment from 'moment';
import DatePicker from "react-datepicker";
import toast, { Toaster } from 'react-hot-toast';
import { CheckCircleIcon, LightBulbIcon, StarIcon, ThumbUpIcon } from "@heroicons/react/solid";
import Modal from "react-responsive-modal";
import { isBefore, isAfter, isWithinInterval } from "date-fns";

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        width: "26%",
        height: '60%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

export default function BidConfirmation({ firstName, budget, guestCount, cgeType, setCGEType, expiryDate, setExpiryDate, setSelectedTab, submitBid, eventDateTime, isNextButtonDisabled, setIsNextButtonDisabled, setStep, step, func, menuButtonOptions }) {
    const [isOpen, setIsOpen] = useState(false);
    const [upgrade, setUpgrade] = useState("");
    const [upgradeViewed, setUpgradeViewed] = useState(false);
    const [waiveCancellationFee, setWaiveCancellationFee] = useState(false);

    const filterDateExpiry = (date) => {
        return moment(date).isBetween(moment(moment().toDate()).add(-1, 'd'), moment(eventDateTime).toDate(), null, [])
    };

    const filterPassedTimeExpiry = (time) => {
        const eventTimeMinus4Hours = moment(moment(eventDateTime).toDate()).add(-4, "h");
        return moment(time).isBetween(moment(), eventTimeMinus4Hours, null, "[]");
    };
    const next48HoursInterval = {
        start: moment().toDate(),
        end: moment(moment().add(2, 'd')).toDate()
    };
    const isEventWithin48Hours = isWithinInterval(expiryDate, next48HoursInterval);

    // const isNextButtonDisabled = (isEventWithin48Hours) && !waiveCancellationFee;
    setIsNextButtonDisabled((isEventWithin48Hours) && !waiveCancellationFee)
    const [deadlineGuidelines, setDeadlineGuidelines] = useState("");
    const user = JSON.parse(window.localStorage.getItem('user_details'));

    useEffect(() => {
        if (moment(expiryDate).isBefore(moment())) {
            // toast.error("Expiry Date cannot be in the past")
            setExpiryDate(moment().add(1, 'h').toDate())
        }
    }, [expiryDate])

    useEffect(() => {
        const next72HoursInterval = {
            start: moment().toDate(),
            end: moment(moment().add(72, 'hours')).toDate()
        };

        const next5DayInterval = {
            start: moment().toDate(),
            end: moment(moment().add(5, 'd')).toDate()
        };
        if ((cgeType === "Catering" || cgeType === "Catering Plus") && guestCount > 30) {
            const expiryDateMinimum = isWithinInterval(expiryDate, next72HoursInterval);
            if (expiryDateMinimum) {
                setDeadlineGuidelines("Catering");
            } else {
                setDeadlineGuidelines("");
            }
        }

        if ((cgeType === "Dining" || cgeType === "Premium Dining") && guestCount > 20) {
            const expiryDateMinimum = isWithinInterval(expiryDate, next5DayInterval);
            if (expiryDateMinimum) {
                setDeadlineGuidelines("Dining");
            } else {
                setDeadlineGuidelines("");
            }
        }


    }, [expiryDate, guestCount, cgeType])

    const plusvalues = [
        {
            title: "Same budget, diverse menu.",
            description: "Catering Plus+ combines offerings from multiple restaurants into one menu for you and your guests."
        },
        {
            title: "Dedicated execution specialist.",
            description: "A member of our execution team will be assigned to your bid to ensure that everything goes smoothly."
        },
    ]

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        func(menuButtonOptions[step])
    }, [step])

    function displayToast(decision) {
        setUpgrade(decision);

        if (decision === "Yes") {
            toast.success('Your upgrade has been added.')
        }

        if (decision === "No") {
            toast('Please click submit to proceed.')
        }
    }

    function submit(e) {
        e.preventDefault();

        if (budget.perHead * guestCount > 2000 && cgeType === "Catering" && upgradeViewed === false) {
            setUpgradeViewed(!upgradeViewed);
            setIsOpen(!isOpen);
        } else {
            submitBid();
        }
    }

    function noUpgrade() {
        toggleModal();
        displayToast("No");
    }

    function yesUpgrade() {
        setCGEType("Catering Plus");
        toggleModal();
        displayToast("Yes");
    }


    return (
        <div className="h-full flex flex-col justify-between">
            <div className="flex flex-col xl:w-[70%]">
                <div className="mt-4 h-44 w-5/6">
                    <div className="flex flex-col andie-description-text leading-tight text-gray-500">
                        Tell us when you would like to stop receiving bid responses and make a decision.
                    </div>
                    <DatePicker
                        name="date"
                        required
                        showTimeSelect
                        filterDate={filterDateExpiry}
                        filterTime={filterPassedTimeExpiry}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="mt-4 w-3/5 bid-form-input"
                        selected={expiryDate}
                        onChange={(date) => setExpiryDate(date)}
                        minDate={moment(moment().toDate()).add(1, "d")}
                        nextMonthButtonLabel=">"
                        previousMonthButtonLabel="<"
                    />
                    <div className="flex mt-2 items-center">
                        <CheckCircleIcon className="h-4 text-green-600 ml-0.5" />
                        <p className="ml-0.5 text-xs font-productsans-medium text-green-600">This bid is open until {new Date(expiryDate).toDateString()} at {moment(expiryDate).format('LT')}</p>
                    </div>
                </div>
            </div>
            <div>
                {deadlineGuidelines === "Catering" && (
                    <div className="text-red-500 text-xs font-productsans-medium mb-4">
                        Please note: The recommended expiry date for events with 30 or more guests is a minimum of 72 hours before the event.
                    </div>
                )}

                {deadlineGuidelines === "Dining" && (
                    <div className="text-red-500 text-xs font-productsans-medium mb-4">
                        Please note: The recommended expiry date for events with 20 or more guests is a minimum of 5 business days before the event.
                    </div>
                )}
                {isEventWithin48Hours &&
                    <>
                        <div className="flex space-x-2.5 w-3/4 xl:w-1/2 mb-4">
                            <input
                                className="h-4 w-4 border-2 rounded-sm checkbox checked:text-blue-600"
                                type="checkbox"
                                checked={waiveCancellationFee}
                                onChange={() => setWaiveCancellationFee(!waiveCancellationFee)}
                            />
                            <p className="xl:w-5/6 andie-section-descriptor text-gray-600 xl:mr-5">I understand that once I accept {cgeType === "Catering Plus" ? "responses" : "a response"} for this bid, I will be unable to cancel the bid or transaction without any <span data-tip="Bookings cancelled within 48 hours of the event date are non-refundable. Cancellation more than 48 hours before the event date are subject to 50% refund." className="underline tooltip tooltip-gray-100 text-blue-600">penalties</span>.</p>
                        </div>
                    </>
                }
                <div className="flex justify-between items-center mt-5 bottom-0 relative w-full">
                    <div className="flex mt-6 w-full justify-between">
                        {/* <button onClick={() => setSelectedTab("Contact")} className="bg-gray-100 text-xs font-productsans-medium text-gray-500 h-8 w-16 rounded px-2.5">Back</button> */}
                        <button disabled={step == 0} onClick={() => {
                            if (step >= 1) {
                                setStep((prevValue) => prevValue - 1)
                            }
                        }} className={`bg-gray-100 text-xs font-productsans-medium text-gray-500 h-8 w-16 rounded px-2.5 ${step == 0 ? 'opacity-0' : 'opacity-100'}`}>Back</button>
                        <div className="flex items-center space-x-2">
                            {upgrade === "Yes" &&
                                <div className="flex items-center">
                                    <StarIcon className="h-4 text-yellow-400" />
                                    <p className="text-xs font-roboto-regular text-yellow-400">Your upgrade has been added.</p>
                                </div>
                            }
                            {user != null ?
                                <button onClick={submit} disabled={isNextButtonDisabled} className={`bg-blue-600 text-xs font-productsans-medium text-white h-8 w-16 rounded px-2.5 ${isNextButtonDisabled ? 'opacity-50' : ''}`}>Submit</button>
                                :
                                <button onClick={submit} disabled={isNextButtonDisabled} className={`bg-blue-600 text-xs font-productsans-medium text-white h-8 w-48 rounded px-2.5 ${isNextButtonDisabled ? 'opacity-50' : ''}`}>Request Access and Submit</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                blockScroll={false}
                open={isOpen}
                onClose={toggleModal}
                animationDuration={400}
                showCloseIcon={false}
                styles={styles}
                center
            >
                <div className="flex flex-col w-full p-6">
                    <div className="flex items-center">
                        <div className="bg-blue-100 h-10 w-10 rounded-full flex items-center justify-center">
                            <LightBulbIcon className="h-6 text-blue-600" />
                        </div>
                        <div className="ml-3.5">
                            <h5 className="text-base font-productsans-medium text-gray-500">Upgrade to Catering+</h5>
                            <p className="andie-section-descriptor mb-0">Enhance your bid with a plus experience</p>
                        </div>
                    </div>
                    <div className="mt-8">
                        <h6 className="text-lg text-blue-600 font-productsans-medium">Congratulations, {firstName}!</h6>
                        <p className="andie-description-text leading-tight mt-1.5">Your bid is eligible for our <span className="font-sf-medium">Catering Plus</span> experience. Here is what our Catering Plus experience offers:</p>
                        <div className="mt-5 space-y-6">
                            {plusvalues.map((value, index) => (
                                <div className="flex">
                                    <div className="w-8 mt-0.5">
                                        <CheckCircleIcon className="h-4 text-blue-600" />
                                    </div>
                                    <div className="ml-1.5">
                                        <h6 className="text-paragraph font-roboto-medium text-gray-500">{value.title}</h6>
                                        <p className="andie-description-text text-gray-400 leading-tight mt-1 mr-2">{value.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-center items-center space-x-4 mt-12">
                            <p onClick={noUpgrade} className="cursor-pointer text-xs font-productsans-medium text-red-600">No I'm okay!</p>
                            <button onClick={yesUpgrade} className="h-8 w-40 andie-dark-button">
                                Upgrade to Catering+
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}