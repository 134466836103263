import React, { useState } from 'react';
export default function AdminOrderNowItemBar({ item, handleItemEdit, businessAccountList, assignedRestaurant, setAssignedRestaurant, status }) {
    // const [assignedRestaurant, setAssignedRestaurant] = useState("");

    const updateValues = (valuename, value) => {
        handleItemEdit(valuename, value)
    }

    return (
        <div className='w-full'>
            <div className="flex flex-row justify-between">
                <div className='flex-col'>
                    <p className='font-productsans-medium text-base'> {item.name}</p>
                    <p className='font-productsans-regular text-sm'> {item?.menuOption.title}</p>
                </div>
                <div className='w-2/5 '>
                    <div className='flex flex-row items-center'>
                        <p className='font-productsans-regular text-sm mr-2'>Price: </p>
                        {/* <input className='font-productsans-regular text-sm'> {item.price}</input> */}
                        <input disabled={status !== "Posted"} type="number" onWheel={(e) => e.target.blur()} onChange={(e) => updateValues('price', e.target.value)} defaultValue={item?.price} className="pl-2 h-6 w-full disabled:bg-gray-200" />

                    </div>
                    <div className='flex flex-row items-center'>
                        <p className='font-productsans-regular text-sm mr-2'>Quantity: </p>
                        <input disabled={status !== "Posted"} type="number" onWheel={(e) => e.target.blur()} onChange={(e) => updateValues('quantity', e.target.value)} defaultValue={item?.quantity} className="pl-2 h-6 mt-1 w-full disabled:bg-gray-200" />
                    </div>
                </div>
            </div>
            <div className="flex w-full flex-row items-center mt-1">
                <p className='font-productsans-regular text-sm w-2/5'> Assigned Restaurant: </p>
                <select onChange={(e) => {
                    updateValues('assigned_restaurant', e.target.value)
                    setAssignedRestaurant(e.target.value)
                }} id="assigned_restaurant"
                    disabled={status !== "Posted"}
                    className="w-full h-8 pl-2 flex border-gray-100">
                    <option selected={item.assigned_restaurant === "Unassigned"} disabled={true}>Unassigned</option>
                    {businessAccountList?.map((acc, index) => (
                        <option value={acc.email} selected={acc.email === assignedRestaurant}>{acc.business_name}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}