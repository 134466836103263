import { useEffect, useState } from "react";

import { BiddingAPIs, ExternalAPIs } from "../../../apis";
import { PackageCreator, PackageView } from '..';
import { eventOptions, cgeOptions, atmosphereOptions } from "../../common";

export default function AdminTemplateForm({ loadingSubmit, data, setModalOpen, editing }) {
    const [collectionTitle, setCollectionTitle] = useState("");
    const [collectionDescription, setCollectionDescription] = useState("");
    const [cgeType, setCgeType] = useState("Catering");
    const [packages, setPackages] = useState([]);
    const [responseImages, setResponseImages] = useState([]);
    const [eventType, setEventType] = useState("Lunch");
    const [atmosphere, setAtmosphere] = useState("Casual");
    const [rating, setRating] = useState("3");
    const [suitable, setSuitable] = useState("");
    const [id, setId] = useState(-9)

    const [refreshPackageList, setRefreshPackageList] = useState(0)

    const ratingOptions = ['1', '2', '3', '4', '5']

    useEffect(() => {
        if (data) {
            setCollectionTitle(data.title)
            setCollectionDescription(data.description)
            setEventType(data.event_type)
            setRating(data.experience)
            setSuitable(data.suitable_for)
            setAtmosphere(data.atmosphere)
            setPackages(data.packages)
            setResponseImages(data.images)
            setCgeType(data.cge_type)
            setId(data.id)
        }
    }, [])

    function validateForm() {
        if ((collectionTitle && collectionDescription && packages.length > 0 && responseImages.length > 0
            && atmosphere != "" && suitable != "" && collectionDescription.length >= 125) && loadingSubmit == false) {
            return true
        } else {
            return false
        }
    }

    const handleFileInput = async (e) => {
        // handle validations
        const file = e.target.files[0];
        const tempURL = await ExternalAPIs.uploadImage(file)
        const tempArr = responseImages.concat({ url: tempURL })
        setResponseImages(tempArr)
    };

    async function handleData() {
        const collectionPayload = {
            "title": collectionTitle,
            "description": collectionDescription,
            "suitable_for": suitable,
            "atmosphere": atmosphere,
            "cge_type": cgeType,
            "experience": rating,
            "images": responseImages,
            "event_type": eventType,
            "id":id
        }

        const packagePayloadList = []
        for (let index = 0; index < packages.length; index++) {
            const pkg = packages[index];
            const convertedPkg = {
                "title": pkg.title,
                "serves": pkg.serves,
                "business_delivers": false,
                "delivery_fee": pkg.delivery_fee,
                "cuisine_type": pkg.cuisine_type,
                "pricing": pkg.pricing,
                "min_guests": pkg.min_guests,
                "items": pkg.items,
                "package_price": pkg.package_price,
                "id": pkg.id
            }
            console.log(convertedPkg)
            packagePayloadList.push(convertedPkg)
        }

        const payload = {
            "collection": collectionPayload,
            "packages": packagePayloadList
        }

        let res
        if (editing) {
            res = await BiddingAPIs.edit_collection_and_package(payload)
        } else {
            res = await BiddingAPIs.create_collection_and_package(payload)
        }
        if (res == true) {
            setModalOpen(false)
        }
    }

    function removeImg(imgIndex) {
        const updatedImgs = responseImages.filter((img, index) => index !== imgIndex)
        setResponseImages(updatedImgs)
    }

    return (
        <>
            <form className="space-y-5 divide-y divide-gray-100 py-4 p-2">
                <div className="grid grid-cols-3 gap-x-4">
                    <div className="col-span-1">
                        <label class="label">
                            <span class="bid-form-input-label mb-0 leading-none">CGE Type</span>
                        </label>
                        <select id="cge_type" value={cgeType} onChange={(e) => setCgeType(e.target.value)} className="select py-0 min-h-0 bid-form-input h-10 w-full">
                            <option disabled selected>Pick one</option>
                            {cgeOptions.map((item, index) => (
                                <option key={index}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-2">
                        <label class="label">
                            <span class="bid-form-input-label mb-0 leading-none">Collection Title</span>
                        </label>
                        <input value={collectionTitle} onChange={(e) => setCollectionTitle(e.target.value)} type="text" placeholder="Title" class="bid-form-input h-10 w-full" />
                    </div>
                </div>
                <div className="pt-3">
                    <label class="label">
                        <span class="bid-form-input-label mb-0 leading-none">Collection Description</span>
                        <p className='text-xs font-roboto-regular text-gray-500'>Min. <b className="text-blue-600">{collectionDescription.length}/125</b> characters</p>
                    </label>
                    <textarea value={collectionDescription} minlength={125} onChange={(e) => setCollectionDescription(e.target.value)} type="text" placeholder="Description" class="bid-form-input h-28 w-full" />
                </div>
                <div className="pt-3 grid grid-cols-2 gap-x-4">
                    <div>
                        <label class="label">
                            <span class="bid-form-input-label mb-0 leading-none">Event Type</span>
                        </label>
                        <select id="cge_type" onChange={(e) => setEventType(e.target.value)} className="select py-0 min-h-0 bid-form-input h-10 w-full">
                            <option disabled>Pick one</option>
                            {eventOptions.map((item, index) => {
                                if (item.name == eventType) {
                                    return <option selected>{item.name}</option>
                                } else {
                                    return <option>{item.name}</option>
                                }
                            })}
                        </select>
                    </div>
                    <div>
                        <label class="label">
                            <span class="bid-form-input-label mb-0 leading-none">Atmosphere</span>
                        </label>
                        <select id="cge_type" onChange={(e) => setAtmosphere(e.target.value)} className="select py-0 min-h-0 bid-form-input h-10 w-full">
                            <option disabled>Pick one</option>
                            {atmosphereOptions.map((item, index) => {
                                if (item.label == atmosphere) {
                                    return <option selected>{item.label}</option>
                                } else {
                                    return <option>{item.label}</option>
                                }
                            })}
                        </select>
                    </div>
                </div>
                <div className="pt-3 grid grid-cols-3">
                    <div className="col-span-1">
                        <label class="label">
                            <span class="bid-form-input-label mb-0 leading-none">Experience Rating</span>
                        </label>
                        <div className="rating rating-sm space-x-1 align-bottom w-full ml-1 mt-1.5">
                            {ratingOptions.map((option) => {
                                if (option == rating) {
                                    return <input type="radio" name="rating-4" class="mask mask-star-2 bg-orange-400" onClick={() => setRating(option)} checked />
                                } else {
                                    return <input type="radio" name="rating-4" class="mask mask-star-2 bg-orange-400" onClick={() => setRating(option)} />
                                }
                            })}
                        </div>
                    </div>
                    <div className="col-span-2">
                        <label class="label">
                            <span class="bid-form-input-label mb-0 leading-none">Suitable For</span>
                        </label>
                        <input value={suitable} onChange={(e) => setSuitable(e.target.value)} type="text" placeholder="E.g Executive Meeting, Team Meeting etc." class="bid-form-input h-10 w-full" />
                    </div>
                </div>
                <div className="flex flex-col border-b py-3">
                    <div className="flex justify-between">
                        <label class="label">
                            <span class="bid-form-input-label mb-0 leading-none">Packages</span>
                        </label>
                        <PackageCreator
                            packages={packages}
                            setPackages={setPackages}
                            setRefreshPackageList={setRefreshPackageList}
                        />
                    </div>
                    <div className="w-full flex flex-col space-y-3 mb-4">
                        <PackageView
                            setPackages={setPackages}
                            packages={packages}
                            refreshPackageList={refreshPackageList}
                            setRefreshPackageList={setRefreshPackageList}
                        />
                       
                    </div>
                </div>

                <div>
                    <label class="label">
                        <span class="bid-form-input-label mb-0 leading-none">Images</span>
                    </label>
                    <div class="flex flex-wrap">
                        {responseImages.map((img, imgIndex) => (
                            <div class="flex w-1/3 flex-wrap grid">
                                <div class="w-full p-1 md:p-2">
                                    <button className="btn btn-sm btn-circle btn-ghost text-slate-200 bg-red-600" onClick={() => removeImg(imgIndex)}> ✕ </button>
                                    <img
                                        alt="gallery"
                                        class="block h-full w-full rounded-lg object-cover object-center"
                                        src={img.url} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div class="flex mt-5 items-center justify-center w-full">
                        <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload </span> {/*or drag and drop</p>*/} </p>
                                <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                            </div>
                            <input id="dropzone-file" type="file" onChange={handleFileInput} class="hidden" />
                        </label>
                    </div>
                </div>

                <div className="pb-10">
                    <button type="button" onClick={() => handleData()} disabled={validateForm() ? false : true} class={validateForm() ? "andie-dark-button h-9" : "h-9 cursor-none andie-disabled-button"}>{editing ? "Update Collection" : "Create Collection"}</button>
                </div>
            </form>
        </>
    )
}