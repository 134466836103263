import React from "react";

export default function DataSecurity() {
    return (
        <div className="andie-description-text">
            <h1 className="font-productsans-medium text-base mb-3">Data Security</h1>
            <p>&nbsp;</p>
            <p>The security of your personal information is very important to us. We use physical, electronic and administrative measures designed to secure your personal information from accidental loss and from unauthorized use, alternation and disclosure. We store all information you provide to us behind firewalls on our secure servers and other information will be encrypted using SSL technology.</p>
            <p>&nbsp;</p>
            <p>The safety and security of your information also depends on you. Where we have given you (or where you have a chosen) a password for access to certain parts of the Site, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
            <p>&nbsp;</p>
            <p>Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Site. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Site.</p>
            <p>&nbsp;</p>
        </div>
    )
}