import React from 'react';
import { useNavigate } from "react-router-dom";
import { Drawer, Tutorial } from '../../common';

import bghome from "../../../assets/images/hero/bg-home.png";

const ExploreHero = ({ setViewVideoModal }) => {
    const navigate = useNavigate();
    return (
        <div className="bg-blue-200 flex flex-col justify-between rounded-2xl p-5 md:p-6 w-full">
            <div className="md:flex">
                <div className="flex flex-col md:w-1/2 lg:w-3/5 my-4 md:mt-0">
                    <div className="bg-blue-400 rounded h-6 px-2.5 inline-flex w-28 items-center justify-center text-xs uppercase font-roboto-bold bg-opacity-30 mb-4">Get Started</div>
                    <h3 className="font-productsans-bold text-black leading-none text-3xl md:text-4xl max-w-md xl:pr-8">Organize <span className="text-blue-700">meals and refreshments</span> for your next event.</h3>
                    <p className="mt-2 md:mt-4 xl:w-3/4 andie-description-text leading-tight text-gray-900">No matter the headcount, event, budget, dietary restrictions, or other unique requirements find the best value and options every time with Pluck.</p>
                </div>
                <div className="xl:h-60 my-6 md:my-3 md:pl-4 lg:pl-0 md:w-1/2 lg:w-2/5">
                    <img src={bghome} className="h-full xl:mt-4 xl:scale-125 w-full object-contain rounded-lg" />
                </div>
            </div>
            <div className="my-3.5 xl:my-2 flex justify-between items-end">
                <div className="flex w-full space-x-2">
                    <div onClick={() => { setViewVideoModal(true); }} className="andie-dark-button w-32 bg-gray-900 hover:bg-gray-800 text-white">Watch the video</div>
                    {window.localStorage.getItem('user_details')
                        ? <div onClick={() => navigate('/tutorials-and-guides')} className="andie-dark-button w-32 bg-blue-950 hover:bg-blue-900">How it works</div>
                        : <div onClick={() => navigate('/signup')} className="andie-dark-button bg-blue-950 hover:bg-blue-900 w-32">Get Started</div>
                    }
                </div>
            </div>
        </div>
        
    )
}

export default ExploreHero; 