import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormikField } from "./FormikField";

const StripePaymentForm = () => {
  const data = {
    routing_number: "",
    account_number: "",
    c_account_number: "",
  };

  const PaymentValidationSchema = Yup.object({
    routing_number: Yup.string().required().label("Routing number"),
    account_number: Yup.string().required().label("Account number"),
    c_account_number: Yup.string()
      .required()
      .label("This")
      .test(
        "account_number_match",
        "Account number's must match",
        function (value) {
          return this.parent.account_number === value;
        }
      ),
  });

  const handleSubmit = (values) => {
  };

  return (
    <div className="andie-padding-horizontal flex flex-col justify-center mx-auto">
      <h1 className="andie-section-title">Add your bank to receive payouts</h1>
      <p className="andie-section-description andie-padding-vertical">
        A payout is the transfer of funds from Stripe to your bank account.
      </p>
      <Formik
        initialValues={data}
        validationSchema={PaymentValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form className="space-y-2">
          <FormikField
            fieldName={"Routing number"}
            type={"number"}
            name={"routing_number"}
            placeholder={"110000000"}
          />

          <FormikField
            fieldName={"Account number"}
            type={"number"}
            name={"account_number"}
            placeholder={"000123456789"}
          />

          <p className="text-xs font-thin">
            Your bank account must be a checking account.
          </p>
          <FormikField
            fieldName={"Confirm accounting number"}
            type={"number"}
            name={"c_account_number"}
            placeholder={"000123456789"}
          />
          <button
            className="py-2 bg-green-600 hover:bg-green-900 hover:shadow-md text-white transition duration-200 w-full font-semibold"
            type="submit"
          >
            Submit
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default StripePaymentForm;
