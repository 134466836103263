import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import moment from 'moment';
import { XIcon } from "@heroicons/react/solid";
import { isWithinInterval } from "date-fns";
import _ from 'lodash';
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

import { EditEventContactInfo, EditEventDetails, EditEventFinancials, EditEventInfo, EditEventPreferences, EditEventRestaurantAndMenu } from "../bid/edit";
import { BiddingAPIs } from "../../apis";
import { PopoutModal } from "../common";

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        width: "60%",
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

export default function BidEdit({ bid }) {
    const [isOpen, setIsOpen] = useState(false);

    const [perHead, setPerHead] = useState(bid.budget.perHead);

    const [organization, setOrganization] = useState(bid.organization);
    const [industry, setIndustry] = useState(bid.industry);
    const [firstName, setFirstName] = useState(bid.contact_first_name);
    const [lastName, setLastName] = useState(bid.contact_last_name);
    const [email, setEmail] = useState(bid.contact_email);
    const [phone, setPhone] = useState(bid.contact_phone);
    const [contactTitle, setContactTitle] = useState(bid.contact_title);

    const [bidTitle, setBidTitle] = useState(bid.bid_title);
    const [bidDescription, setBidDescription] = useState(bid.bid_description);
    const [eventType, setEventType] = useState(bid.event_type);

    const [guestCount, setGuestCount] = useState(bid.guest_count);
    const [cgeType, setCGEType] = useState(bid.cge_type);
    const [restaurantRating, setRestaurantRating] = useState(bid.restaurant_rating);

    const [location, setLocation] = useState(bid.location);
    const [locationPlaceID, setLocationPlaceID] = useState(bid.locationPlaceID);
    const [locationGeometry, setLocationGeometry] = useState(bid.locationGeometry);
    const [locationSecondaryDetails, setLocationSecondaryDetails] = useState(bid.locationSecondaryDetails ? bid.locationSecondaryDetails : "");
    const [locationAdditionalDetails, setLocationAdditionalDetails] = useState(bid.locationAdditionalDetails);

    const [eventDateTime, setEventDateTime] = useState(new Date(bid.event_date_time));
    const [deliveryTime, setDeliveryTime] = useState(new Date(bid.delivery_time));

    const [restaurantType, setRestaurantType] = useState(bid.restaurant_type);
    const [dietaryPreferences, setDietaryPreferences] = useState(bid.dietary_preferences);
    const [dietaryNotes, setDietaryNotes] = useState(bid.dietary_notes)
    const [menuOptions, setMenuOptions] = useState(bid.menu_options);

    const [currency, setCurrency] = useState(bid.currency);
    const [budget, setBudget] = useState(bid.budget);
    const [tipStatus, setTipStatus] = useState(bid.budget.tipAmount * 100);
    const [accessibility, setAccessibility] = useState(bid.accessibility);
    const [expiryDate, setExpiryDate] = useState(new Date(bid.expiry_date));
    const [paymentTerms, setPaymentTerms] = useState(bid.payment_terms);
    const [additionalNotes, setAdditionalNotes] = useState(bid.additional_notes);

    const [requiresChafing, setRequiresChafing] = useState(bid.additional_notes.includes("This bid requires a chafing dish to be provided."));
    const [packagingType, setPackagingType] = useState(["Any"]);


    const [waiveCancellationFee, setWaiveCancellationFee] = useState(false);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
    const next48HoursInterval = {
        start: moment().toDate(),
        end: moment(moment().add(2, 'd')).toDate()
    };
    const isEventWithin48Hours = isWithinInterval(expiryDate, next48HoursInterval);

    // const isSubmitButtonDisabled = ((isEventWithin48Hours) && !waiveCancellationFee);
    useEffect(() => {
        setIsSubmitButtonDisabled(((isEventWithin48Hours) && !waiveCancellationFee));
    }, [expiryDate, waiveCancellationFee])

    const isDeleteButtonDisabled = ((isEventWithin48Hours) && !waiveCancellationFee);
    const navigate = useNavigate();

    useEffect(() => {
        const hasNonNonePreference = dietaryPreferences.some(
            item => item.preference !== "None"
        );
        if (isEmpty(dietaryPreferences) || (hasNonNonePreference && !dietaryNotes) || bidTitle == "" || bidDescription == "" || location == "") {
            setIsSubmitButtonDisabled(true);
        }
        else {
            setIsSubmitButtonDisabled(false);
        }
    }, [dietaryPreferences, dietaryNotes, bidTitle, bidDescription, location])

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    function shafingNotes() {
        const shaffing_note = '\nThis bid requires a chafing dish to be provided.';
        setRequiresChafing(!requiresChafing);

        if (requiresChafing) {
            setAdditionalNotes(additionalNotes + shaffing_note);
        } else {
            setAdditionalNotes(additionalNotes.replace(shaffing_note, ""));
        }

    }

    const submitChanges = async () => {
        budget.total = budget.perHead * guestCount;
        budget.tipAmount = tipStatus/100;

        if (moment(expiryDate).isBefore(moment())) {
            toast.error("Expiry Date cannot be in the past")
        } else {
            const payload = {
                id: bid.id,
                organization: organization,
                industry: industry,

                contact_email: email,
                contact_first_name: firstName,
                contact_last_name: lastName,
                contact_phone: phone,
                contact_title: contactTitle,

                bid_title: bidTitle,
                bid_description: bidDescription,
                event_type: eventType,
                cge_type: cgeType,

                location: location.replace(/\w+/g, _.capitalize),
                locationPlaceID: locationPlaceID,
                locationGeometry: locationGeometry,
                locationSecondaryDetails: locationSecondaryDetails,
                locationAdditionalDetails: locationAdditionalDetails,
                
                restaurant_rating: restaurantRating,
                // UZAIR needs recurring_period, end_date,  NVM
                dietary_notes: dietaryNotes,
                packaging_type: packagingType,
                additional_notes: additionalNotes,
                payment_terms: paymentTerms,
                budget: budget,
                currency: currency,

                // active: true,

                event_date_time: moment(eventDateTime).format(),
                guest_count: guestCount,
                restaurant_type: restaurantType,
                menu_options: menuOptions,
                dietary_preferences: dietaryPreferences,
                accessibility: accessibility,
                expiry_date: moment(expiryDate).format(),
                delivery_time: deliveryTime
            }

            // console.log(payload)

            await BiddingAPIs.editBid(payload);

            toggleModal();
        }
    }

    function deleteBid() {



    }

    return (
        <>
            <div onClick={toggleModal} className="cursor-pointer h-9 px-3 bg-blue-600 rounded flex items-center justify-center mt-2">
                <h6 className="text-xs text-white font-productsans-medium">Edit Bid</h6>
            </div>
            <Modal
                blockScroll={false}
                open={isOpen}
                onClose={toggleModal}
                animationInDuration={400}
                animationOutDuration={700}
                showCloseIcon={false}
                styles={styles}
                center
            >
                <div className='h-full flex flex-col relative pt-4'>
                    <div className="flex px-4 border-b border-gray-100 pb-3 justify-between">
                        <div>
                            <h5 className="text-base font-productsans-medium text-gray-500">Edit bid</h5>
                            <p className="text-tiny sf-regular text-gray-400">Make changes to your bid</p>
                        </div>

                        <div onClick={toggleModal} className="cursor-pointer h-5 w-5 rounded-full flex items-center justify-center bg-gray-100">
                            <XIcon className="h-3 text-gray-500" />
                        </div>
                    </div>
                    <div className="pt-4 px-4 flex flex-col overflow-y-scroll scrollbar-hide">
                        <EditEventInfo
                            bidTitle={bidTitle}
                            setBidTitle={setBidTitle}
                            bidDescription={bidDescription}
                            setBidDescription={setBidDescription}
                            cgeType={cgeType}
                            setCGEType={setCGEType}
                            eventType={eventType}
                            setEventType={setEventType}
                            setAccessibility={setAccessibility}
                        />
                        <EditEventDetails
                            location={location}
                            setLocation={setLocation}
                            setLocationPlaceID={setLocationPlaceID}
                            setLocationGeometry={setLocationGeometry}
                            locationSecondaryDetails={locationSecondaryDetails}
                            setLocationSecondaryDetails={setLocationSecondaryDetails}
                            setLocationAdditionalDetails={setLocationAdditionalDetails}
                            eventDateTime={eventDateTime}
                            setEventDateTime={setEventDateTime}
                            guestCount={guestCount}
                            setGuestCount={setGuestCount}
                            accessibility={accessibility}
                            setAccessibility={setAccessibility}
                            expiryDate={expiryDate}
                            setExpiryDate={setExpiryDate}
                            waiveCancellationFee={waiveCancellationFee}
                            setWaiveCancellationFee={setWaiveCancellationFee}
                            cgeType={cgeType}
                            deliveryTime={deliveryTime} 
                            setDeliveryTime={setDeliveryTime}
                        />
                        <EditEventFinancials
                            currency={currency}
                            setCurrency={setCurrency}
                            perHead={perHead}
                            setPerHead={setPerHead}
                            guestCount={guestCount}
                            setBudget={setBudget}
                            tipStatus={tipStatus}
                            setTipStatus={setTipStatus}
                            paymentTerms={paymentTerms}
                            setPaymentTerms={setPaymentTerms}
                        />
                        <EditEventRestaurantAndMenu
                            restaurantType={restaurantType}
                            setRestaurantType={setRestaurantType}
                            menuOptions={menuOptions}
                            setMenuOptions={setMenuOptions}
                            restaurantRating={restaurantRating}
                            setRestaurantRating={setRestaurantRating}
                        />
                        <EditEventPreferences
                            dietaryPreferences={dietaryPreferences}
                            setDietaryPreferences={setDietaryPreferences}
                            dietaryNotes={dietaryNotes}
                            setDietaryNotes={setDietaryNotes}
                            cge_type={cgeType}
                            packagingType={packagingType}
                            setPackagingType={setPackagingType}
                            requiresChafing={requiresChafing}
                            setRequiresChafing={setRequiresChafing}
                            shafingNotes={shafingNotes}
                        />
                        <EditEventContactInfo
                            organization={organization}
                            setOrganization={setOrganization}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            contactTitle={contactTitle}
                            setContactTitle={setContactTitle}
                            phone={phone}
                            setPhone={setPhone}
                            email={email}
                            setEmail={setEmail}
                            industry={industry}
                            selectIndustry={setIndustry}
                            additionalNotes={additionalNotes}
                            setAdditionalNotes={setAdditionalNotes}
                        />
                    </div>
                    <div className="h-14 px-4 py-3 border-t border-gray-100 flex justify-between">
                        {!isDeleteButtonDisabled ?
                            <PopoutModal
                                title={"Delete your bid"}
                                button={
                                    <button disabled={isDeleteButtonDisabled} className={`cursor-pointer flex items-center justify-center bg-red-600 h-8 w-24 rounded px-2.5 ${isDeleteButtonDisabled ? 'opacity-50' : ''}`}>
                                        <h6 className="text-xs font-productsans-medium text-white">Delete Bid</h6>
                                    </button>}
                                modalID={'delete_bid_modal'}
                                footerElements={
                                    <button onClick={() => deleteBid()} className="bg-blue-200 mb-5 text-xs font-productsans-medium text-gray-500 h-8 w-full rounded px-2.5">Yes</button>
                                }
                            >
                                <div className="flex items-center space-x-2 mb-5">
                                    <p className="label-text">Are you sure you want to delete this bid?</p>
                                </div>
                            </PopoutModal>
                            : <div></div>
                        }

                        <button disabled={isSubmitButtonDisabled} onClick={submitChanges} className={`cursor-pointer flex items-center justify-center bg-blue-600 h-8 w-24 rounded px-2.5 ${isSubmitButtonDisabled ? 'opacity-50' : ''}`}>
                            <h6 className="text-xs font-productsans-medium text-white">Save Changes</h6>
                        </button>
                    </div>

                </div>
            </Modal>

        </>
    )
}