import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import { BiddingForm } from "../../components/bid/create";
import { Header } from "../../components/navigation";
import { PencilAltIcon, XCircleIcon } from "@heroicons/react/solid";

export default function CreateBid() {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [drafts, setDrafts] = useState(JSON.parse(localStorage.getItem('drafts')) || []);
    const [selectedDraft, setSelectedDraft] = useState();
    const [saved, setSaved] = useState(false);
    const [item, setItem] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search)

    useEffect(() => {
        if (location.state?.item) {
            setItem(location.state.item);
        }
        else {
            const temp = {
                "cge_email": "",
                "template_title": "",
                "template_description": "",
                "cge_type": "Catering",
                "event_type": "Office breakfast",
                "packages": [

                ],
                "max_guests": 9999999,
                "min_price": 1,
                "max_price": 999999999,
                "cuisine_type": [
                    ""
                ],
                "images": [
                ]
            }
            setItem(temp)
        }

    }, []);

    useEffect(() => {
        // const existingDrafts = JSON.parse(localStorage.getItem('drafts')) || [];
        const existingDrafts = JSON.parse(localStorage.getItem('drafts')) || [];
        setDrafts(existingDrafts);
        
    }, [setDrafts]);


    function savedBid() {
        setTimeout(() => setSaved(!saved), 3000);
        navigate('/home/bids')
    }

    function removeDraft(index) {
        if (index >= 0 && index < drafts.length) {
            drafts.splice(index, 1);
            localStorage.setItem('drafts', JSON.stringify(drafts));

            const newDrafts = JSON.parse(localStorage.getItem('drafts'))
            setDrafts(newDrafts);
        }
    }

    return (
        <div className="h-[100svh]">
            <div className="flex h-full">
                <div className="hidden md:inline-flex md:w-[17%] pt-5 px-4" />
                <div className="andie-main-panel pb-4 xl:pb-5 h-[calc(100%-40px)]">
                    <BiddingForm user={user} savedBid={savedBid} setDrafts={setDrafts} selectedDraft={selectedDraft} item={item} setCurrentStep={setCurrentStep} />
                </div>
                <div className="hidden andie-draft-panel lg:inline-flex lg:andie-padding h-3/5">
                    <div className="border-2 border-dashed p-3 h-full overflow-hidden rounded w-full">
                        <h6 className="font-productsans-bold text-gray-700 uppercase text-xs mb-3">Drafts</h6>
                        {drafts.length > 0 ? 
                            <div className="h-full space-y-3 pb-8 last:mb-5 overflow-y-scroll scrollbar-hide">
                                {drafts.map((draft, index) => (
                                    <div className="bg-indigo-50 p-3 rounded-md">
                                        <div className="flex space-x-2.5">
                                            <div className="bg-indigo-200 flex flex-shrink-0 items-center justify-center h-6 w-6 rounded">
                                                <PencilAltIcon className="text-blue-600 h-4" />
                                            </div>
                                            <div className="w-full">
                                                <h6 className="font-productsans-bold text-sm line-clamp-1">{draft.bidTitle}</h6>
                                                <p className="andie-description-text leading-tight line-clamp-2">{draft.bidDescription ? draft.bidDescription : "No description"}</p>
                                            </div>
                                            <div onClick={() => removeDraft(index)} className="cursor-pointer flex-shrink-0">
                                                <XCircleIcon className="h-5 text-gray-400" />
                                            </div>
                                        </div>
                                        <div className="mt-2 pt-2 border-t border-indigo-100 flex items-center justify-between">
                                            <p className="text-gray-500 andie-description-text text-xs font-roboto-medium">{draft.cgeType}</p>
                                            <div onClick={() => setSelectedDraft(draft)} className="andie-dark-button h-6 w-16 inline-flex">
                                                Use
                                            </div>
                                        </div>
                                    </div>   
                                ))}
                            </div>
                            :
                            <div className="h-full flex items-center justify-center text-gray-400 andie-description-text italic">
                                There are no saved drafts.
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}