import { CheckIcon, CubeIcon, PhotographIcon, StarIcon, UsersIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { BidResponse } from '../../responses';
import { Drawer, PopoutModal, calculateBudget, getTransactionAmounts, moneySignText, pricetoString } from '../../common';

export default function BidsItem({ bid, response, displayOnly, rIndex }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const total = response && getTransactionAmounts(response.items, bid.budget.tipAmount, response.delivery_fee, response.business_delivers, bid.cge_service_fee, response.business_service_fee, null, response.pricing, response.total_price).total;
    const budget = response && calculateBudget(bid["budget"].total, 1)

    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const updateScreenSize = () => {
        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };


    function renderPrice(response, bid) {
        // items.forEach((item) => {
        //     total += (item.price * item.quantity)
        // })pricetoString(calculateBudget
        
        let ratio = total / budget
        if (ratio > 1) {
            return (
                <>
                    <h6 className="text-xs font-productsans-medium text-gray-700"> ${pricetoString(total)} </h6>
                    <p className="font-roboto-bold text-red-500 md:text-xs">{(budget - total).toFixed(2)} ({ratio.toFixed(2)}%)</p>
                </>
            )
        }
        else {
            return (
                <>
                    <h6 className="text-xs font-productsans-medium text-gray-700"> ${pricetoString(total)} </h6>
                    <p className="font-roboto-bold text-green-500 md:text-xs">+{(budget - total).toFixed(2)} ({ratio.toFixed(2)}%)</p>
                </>
            )
        }
    }
    function renderLock(response, bid) {
        let total = 0
        total = getTransactionAmounts(response.items, bid.budget.tipAmount, response.delivery_fee, response.business_delivers, bid.cge_service_fee, response.business_service_fee, null, response.pricing, response.total_price).total

        return (
            <>
                <h6 className="text-sm font-productsans-medium text-green-600"> {moneySignText(total)} </h6>
            </>
        )
    }

    const navigate = useNavigate();
    if (displayOnly == null) {
        displayOnly = false
    }

    const handleClick = () => {
        if (!displayOnly) {
            navigate('/bid/' + bid.transaction_code)
        }
    }

    return (
        <>
            {screenSize.width > 425 ?
                <div key={rIndex} className="flex border-2 w-full rounded-md border-gray-100 sm:p-2 p-1.5 h-24">
                    <div className="h-full w-28 sm:flex hidden">
                        {response.images.length > 0 ?
                            <img src={response.images[0].url} className="h-full object-cover w-full rounded-md" />
                            :
                            <div className="bg-gray-200 rounded-md h-full w-full flex justify-center items-center">
                                <p className="font-roboto-bold text-xs text-gray-600">No images</p>
                            </div>
                        }
                    </div>

                    <div className="sm:ml-3.5 flex flex-grow w-full">
                        <div className="sm:w-[30%] w-[37%] flex flex-col justify-between">

                            <div className="flex flex-col">
                                <h6 className="font-productsans-medium text-base md:text-sm">Response #00{response.id}</h6>
                                <div className="-mt-0.5 flex items-center space-x-1.5">
                                    <p className="font-roboto-bold text-xs text-gray-500">{bid.event_type}</p>
                                    <div className="h-1 w-1 rounded-full bg-gray-400" />
                                    <div className="flex items-center">
                                        <p className="text-xs font-roboto-medium text-gray-800">{bid.restaurant_rating}</p>
                                        <StarIcon className="h-4 text-yellow-400" />
                                    </div>
                                </div>
                                
                                <p className="text-xs font-roboto-medium text-gray-400">{bid.cge_type}</p>
                            </div>


                            <div className="flex items-center">
                                <UsersIcon className="text-gray-500 h-3.5 mr-1" />
                                <h6 className="font-roboto-medium text-gray-500 text-xs">{bid.guest_count} guests</h6>
                            </div>
                        </div>

                        <div className="sm:px-3.5 px-2 h-full border-l-2 border-r-2 sm:w-[47%] border-dashed flex flex-col justify-between w-[35%]">
                            <div>
                                <div className="flex justify-between">
                                    <h6 className="font-roboto-bold uppercase text-[11.5px] text-gray-400">What's included</h6>
                                    <PhotographIcon className={`h-4 text-gray-300 ${response.images.length > 0 && "text-gray-600"} sm:flex hidden`} />
                                </div>
                                <ul className="space-y-0">
                                    {response.items.slice(0, 2).map((item, index) => (
                                        <li className="flex items-center space-x-1">
                                            <CheckIcon className="text-green-500 h-4" />
                                            <p className="andie-description-text line-clamp-1">{item.name}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="flex justify-between leading-none">
                                <p className="font-roboto-medium text-xs text-blue-600">{response.items.length > 2 ? "+" + (response.items.length - 2) + " items" : " "}</p>
                                <p className="font-roboto-medium text-xs text-gray-600">{response.restaurant_type}</p>
                            </div>
                        </div>


                        <div className="flex flex-col justify-between items-end sm:w-[23%] flex-grow">
                            <div className="text-right">
                                {user !== null ?
                                    <>{renderPrice(response.items, bid)}</>
                                    :
                                    <>{renderLock(response.items, bid)}</>
                                }
                            </div>
                            <Drawer
                                drawerID={'response_drawer'}
                                width={30}
                                title={"Bid Responses"}
                                button={
                                    <div className="flex justify-end">
                                        <div className="andie-text-button">
                                            More details
                                        </div>
                                    </div>
                                }
                            >
                                {(bid == null) ?
                                    <></>
                                    :
                                    bid.responses.length === 0 ?
                                        <></> :
                                        <BidResponse
                                            bid={bid}
                                            coefficient={1.12}
                                            contact_email={response.contact_email}
                                            tipAmount={bid.budget.tipAmount}
                                            bid_id={response.bid_id}
                                            bidResponse={response}
                                            cgeEmail={response.cgeEmail}
                                            response_id={response.response_id}
                                            guest_count={bid.guest_count}
                                            expired={false}
                                            bidAccepted={response.accepted}
                                            interested={true}
                                            transaction_code={bid.transaction_code.toUpperCase()}
                                            dietary_preferences={bid.dietary_preferences}
                                            budget={bid.budget}
                                        />
                                }
                            </Drawer>
                        </div>
                    </div>

                </div>
                :
                <div>
                    <div className="flex border-2 w-full rounded-md border-gray-100 sm:p-3 p-2 h-48 gap-x-2">
                        <div className="h-full w-44">
                            {response.images.length > 0 ?
                                <img src={response.images[0].url} className="h-full object-cover w-full rounded-md" />
                                :
                                <div className="bg-gray-200 rounded-md h-full w-full flex justify-center items-center">
                                    <p className="font-roboto-bold text-xs text-gray-600">No images</p>
                                </div>
                            }
                        </div>

                        <div className={`flex flex-col w-full gap-y-1 ${screenSize.width < 375 ? 'text-[10px]' : 'text-xs'}`}>

                            {/* Response Info */}
                            <div className="flex justify-between">
                                <div className="w-2/3">
                                    <h6 className="font-productsans-medium text-base">Response #00{response.id}</h6>
                                    <div className="flex items-center space-x-1.5">
                                        <p className="font-roboto-bold text-xs text-gray-500">{bid.event_type}</p>
                                        <div className="h-1 w-1 rounded-full bg-gray-400" />
                                        <div className="flex items-center">
                                            <p className=" font-roboto-medium text-xs text-gray-800">{bid.restaurant_rating}</p>
                                            <StarIcon className="h-4 text-yellow-400" />
                                        </div>
                                    </div>
                                    
                                    <p className="font-roboto-medium text-gray-400">{bid.cge_type}</p>
                                </div>
                                <div className="flex flex-col pt-1 items-end">
                                    <div className="flex items-center">
                                        <UsersIcon className="text-gray-500 h-3.5 mr-1" />
                                        <h6 className="font-roboto-medium text-gray-500">{bid.guest_count} guests</h6>
                                    </div>
                                    {user !== null ?
                                        <>{renderPrice(response.items, bid)}</>
                                        :
                                        <>{renderLock(response.items, bid)}</>
                                    }
                                </div>
                            </div>

                            {/* Whats Included */}
                            <div>
                                <div className="flex justify-between mt-3 border-t border-gray-100 pt-2.5">
                                    <div className="w-2/3">
                                        <h6 className="font-roboto-bold uppercase text-gray-400">What's included</h6>
                                        <ul className="mt-1 space-y-1">
                                            {response.items.slice(0, 2).map((item, index) => (
                                                <li className="flex items-center space-x-1">
                                                    <CheckIcon className="text-green-500 h-4 shrink-0" />
                                                    <p className="andie-description-text line-clamp-1">{item.name}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <p className="font-roboto-medium text-gray-600">{response.restaurant_type}</p>
                                </div>
                            </div>
                            <div className="mt-1.5 flex items-end justify-between">
                                <p className="font-roboto-medium w-2/3 text-blue-600">{response.items.length > 2 && "+" + (response.items.length - 2) + " items"}</p>
                                {/* <Drawer
                                    drawerID={'response_drawer'}
                                    width={screenSize.width >= 768 ? 35 : 95}
                                    title={"Bid Responses"}
                                    button={
                                        <div className="flex justify-end">
                                            <div className="andie-text-button h-5">
                                                More details
                                            </div>
                                        </div>
                                    }
                                >
                                    {(bid == null) ?
                                        <></>
                                        :
                                        bid.responses.length === 0 ?
                                            <></> :
                                            <BidResponse
                                                bid={bid}
                                                coefficient={1.12}
                                                contact_email={response.contact_email}
                                                tipAmount={bid.budget.tipAmount}
                                                bid_id={response.bid_id}
                                                bidResponse={response}
                                                cgeEmail={response.cgeEmail}
                                                response_id={response.response_id}
                                                guest_count={bid.guest_count}
                                                expired={false}
                                                bidAccepted={response.accepted}
                                                interested={true}
                                                transaction_code={bid.transaction_code.toUpperCase()}
                                                dietary_preferences={bid.dietary_preferences}
                                                budget={bid.budget}
                                            />
                                    }
                                </Drawer> */}
                                <PopoutModal
                                    modalID={'response_ribbon_template_' + String(response.id)}
                                    button={
                                        <div className="flex justify-end">
                                            <div className="andie-text-button h-5">
                                                More details
                                            </div>
                                        </div>
                                    }
                                    title="Preview Response"  
                                >
                                    <div className="h-full overflow-hidden">
                                    {(bid == null) ?
                                        <></>
                                        :
                                        bid.responses.length === 0 ?
                                            <></> :
                                            <BidResponse
                                                bid={bid}
                                                coefficient={1.12}
                                                contact_email={response.contact_email}
                                                tipAmount={bid.budget.tipAmount}
                                                bid_id={response.bid_id}
                                                bidResponse={response}
                                                cgeEmail={response.cgeEmail}
                                                response_id={response.response_id}
                                                guest_count={bid.guest_count}
                                                expired={false}
                                                bidAccepted={response.accepted}
                                                interested={true}
                                                transaction_code={bid.transaction_code.toUpperCase()}
                                                dietary_preferences={bid.dietary_preferences}
                                                budget={bid.budget}
                                                mobile={true}
                                            />
                                    }
                                    </div>
                                </PopoutModal>
                            </div>


                        </div>
                    </div>
                </div>
            }
        </>
    )
}