import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { SidebarRight } from "../../components/navigation";
import { ActivityBids, ActivityResponses, ActivityTransactions } from ".";
import { BiddingAPIs, StripeAPI } from "../../apis";
import { ThreeDots } from "react-loader-spinner";
import { LoadingIcon, PopoutModal, pricetoString } from "../../components/common";
import BidsItem from "../../components/explore/bids/BidsItem";
import { WebSocket } from "../../components/sockets";
import { CreditCardIcon } from "@heroicons/react/solid";

export default function Activity() {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [selectedTab, setSelectedTab] = useState("Current Bids");
    const [succeededBalance, setSucceededBalance] = useState(0)
    const [processingBalance, setProcessingBalance] = useState(0)
    const [bids, setBids] = useState([]);
    const [responses, setResponses] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true)
    const [loadingSaved, setLoadingSaved] = useState(true)
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const [savedBids, setSavedBids] = useState([]);
    const [bidHistory, setBidHistory] = useState([]);
    const navigate = useNavigate();
    let id = useParams();

    useEffect(() => {
        getBids();
        getCGEWalletInformation();
        loadTransactions();
        getBidHistory();
        getSavedBids();
    }, [])

    useEffect(() => {
        var something = id["*"].split('/');

        if (something[0] == 'transactions'){
            something[0] = 'Transactions'
        } else if (something[0] == 'current-responses'){
            something = ['Current Responses']
        }
        else if (something[0] == 'current-bids'){
            something = ['Current Bids']
        } else {
            something = ['Current Bids']
            navigate('/activity/current-bids')
        }


        setSelectedTab(something[0])
    }, [id])

    async function getSavedBids() {
        const res = await BiddingAPIs.getSavedBids();
        if (res.bids?.length) {
            setSavedBids(res.bids)
        }
        setLoadingSaved(false)
    }

    async function getBidHistory() {
        let ids = JSON.parse(window.localStorage.getItem('bid_history'))
        if (ids.length > 0) {
            const res = await BiddingAPIs.getBidHistory({ids: ids});
            console.log(res)
            if (res.bids?.length) {
                setBidHistory(res.bids)
            }
        }
    }

    async function getCGEWalletInformation() {
        const res = await StripeAPI.getCGEWalletInformation();
        setSucceededBalance(res['succeeded'])
        setProcessingBalance(res['requires_confirmation'])
        setLoading(false)
    }

    async function loadTransactions() {
        var result = await BiddingAPIs.getMyTransactions(user.email, user.account_type);
        const updatedTransactions = result.map((transaction) => ({
            ...transaction,
        }));
        updatedTransactions.sort((a, b) => a.id - b.id);
        updatedTransactions.forEach((transaction) => {
            transaction.status = getStatus(transaction);
        })
        setTransactions([...updatedTransactions.reverse()]);
        let temp = sidebardata
        temp[0].data = updatedTransactions.length
        setSidebardata(temp)
        // setSelectedTransaction({});
        setLoadingTransactions(false);
    }

    const getStatus = (transaction) => {
        if (transaction.pi && transaction.pi.status === "succeeded") {
            return "Completed";
        }
        return transaction.status;
    };

    async function getBids() {
        let businessType = "";
        if (user.account_type == 2) {
            businessType = "Restaurant"
        }
        var result = await BiddingAPIs.getMyBids(user.email, businessType, false);
        console.log(result)
        setResponses(result.responses.reverse())
        setBids(result.bids.reverse())

        let temp = sidebardata
        temp[1].data = result.length
        let count = 0
        if (user.account_type == 2) {
            result.forEach((resp) => {
                count += resp.bid.views
            })
        } else {
            result.forEach((bid) => {
                count += bid.views
            })
        }
        temp[2].data = count
        setSidebardata(temp)
        setLoading(false)
    }

    const tabs = [
        "Current Bids",
        "Current Responses",
        "Transactions"
    ]

    const [sidebardata, setSidebardata] = useState([
        { title: "Transactions", data: "0"},
        { title: "Submitted Bids", data: "0"},
        { title: "Views", data: "200"}
    ])

    return (
        <div className="h-screen">
            {/* <Header refreshFunction={() => {getBids(); loadTransactions();}} page="activity" /> */}
            <WebSocket refreshFunction={() => {getBids(); loadTransactions();}} page="activity" />
            {/* <button className="btn" onClick={() => socketAPI.refreshTransactions()} > ye </button> */}
            <div className="flex h-full">
                <div className="hidden md:inline-flex md:[w-[25%] xl:w-[17%] flex-col pt-5 pl-6 pr-4">
                    <h6 className="andie-sidebar-title">Activity</h6>
                    <div className="mt-4 bg-blue-50 p-2 lg:p-3.5 rounded-md">
                        <div className="-mt-1 flex items-center justify-between">
                            {loading ?
                                <h3 className="font-productsans-black text-2xl text-blue-600">
                                    <ThreeDots
                                        height="30"
                                        width="30"
                                        radius="9"
                                        color="#2563eb"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </h3>
                                :
                                <h3 className="font-productsans-black leading-none text-lg xl:text-2xl text-blue-600">
                                    ${processingBalance == null ? pricetoString(0) :pricetoString(processingBalance)}
                                </h3>
                            }
                            <CreditCardIcon className="h-5 text-gray-700" />
                        </div>
                        <h6 className="text-xs font-roboto-medium leading-tight text-gray-700">Pending</h6>
                        <div className="mt-3 mb-1 h-7 andie-dark-button" onClick={() => navigate('/account')}>Wallet</div>
                    </div>

                    <h6 className="mt-8 font-roboto-bold text-gray-400 text-xs uppercase mb-2">Last 30 days</h6>
                    <div className="border border-gray-100 rounded-md divide-y divide-gray-100">
                        {sidebardata.map((item, index) => (
                            <div className="pt-2 h-14 px-1.5 lg:px-3">
                                <h6 className="font-productsans-bold text-gray-600 text-[11px] lg:text-xs uppercase">{item.title}</h6>
                                {(loading || loadingTransactions) ?
                                <h3 className="font-productsans-black text-2xl text-yellow-500">
                                    <ThreeDots
                                        height="30"
                                        width="30"
                                        radius="9"
                                        color="#2563eb"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </h3>
                                :
                                <p className="andie-description-text mb-0">{item.data}</p>
                                }
                            </div>
                        ))}
                    </div>
                    <div className="lg:w-1/2 space-y-1.5">
                        <PopoutModal
                            button={<div className="mt-3 andie-text-button">Viewed History</div>}
                            title={"Viewed History"}>
                                <div className="h-full grid grid-cols-3 gap-4 overflow-y-scroll scrollbar-hide min-w-[50vw] min-h-[50vh]">
                                    {bidHistory.length > 0 ?
                                    bidHistory.map((bid, index) => {
                                        return (
                                            loadingSaved ?
                                                <div div className="h-full flex items-center justify-center w-full">
                                                    <div className="-mt-32">
                                                        <LoadingIcon />
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    
                                                    <BidsItem bid={bid} index={index} history={true} />
                                                </div>
                                        )
                                    })
                                :
                                <p> No saved bids </p>
                                }
                                </div>
                        </PopoutModal>
                        <PopoutModal
                            button={<div className="mt-3 andie-text-button">Saved Bids</div>}
                            title={"Saved Bids"}>
                                <div className="h-full grid grid-cols-3 gap-4 overflow-y-scroll scrollbar-hide min-w-[50vw] min-h-[50vh]">
                                    {savedBids.length > 0 ?
                                    savedBids.map((bid, index) => {
                                        return (
                                            loadingSaved ?
                                                <div div className="h-full flex items-center justify-center w-full">
                                                    <div className="-mt-32">
                                                        <LoadingIcon />
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    
                                                    <BidsItem bid={bid} index={index} history={true} />
                                                </div>
                                        )
                                    })
                                :
                                <p> No saved bids </p>
                                }
                                </div>
                        </PopoutModal>
                    </div>
                </div>
                <div className="andie-main-panel">
                    <div className="hidden md:inline-flex pt-1 items-center justify-between">
                        <div className="h-8 p-1 bg-gray-100 rounded-md grid grid-cols-3">
                            {tabs.map((tab, index) => (
                                <div onClick={() => {setSelectedTab(tab);navigate(`/activity/${tab.toLowerCase().replace(' ', '-')}`) }} className={`${selectedTab === tab && "bg-white text-blue-600"} text-gray-600 cursor-pointer rounded-md px-3 flex font-productsans-medium text-sidebar items-center justify-center`}>
                                    {tab}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="md:hidden flex h-9 border-b space-x-5">
                        {tabs.slice(0,3).map((item, index) => (
                            <div onClick={() => {setSelectedTab(item); navigate(`/activity/${item.toLowerCase().replace(' ', '-')}`)}} className={`h-full flex items-center andie-description-text font-roboto-medium justify-center cursor-pointer mb-3 hover:text-blue-600 ${selectedTab === item && "border-b-2 text-blue-600 border-blue-600"}`}>
                                {item}
                            </div>
                        ))}
                    </div>
                    
                    <div className="pt-6 md:pt-2 h-full">
                        {selectedTab === "Current Bids" && <ActivityBids bids={bids} loading={loading} />}
                        {selectedTab === "Current Responses" && <ActivityResponses responses={responses} loading={loading} />}
                        {selectedTab === "Transactions" && <ActivityTransactions transactions={transactions} loading={loadingTransactions} selectedTransaction={selectedTransaction} setSelectedTransaction={(e) => setSelectedTransaction(e)}  />}
                    </div>
                </div>
                <SidebarRight />
            </div>
        </div>
    )
}