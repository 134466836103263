import React from "react";

export default function AndiePromiseGuests() {
    const promises = [
        "We aim to deliver a great experience that you would want to talk about to your friends, family, and colleagues.",
        "When dining at one of our partner restaurants, we expect you to be on time.",
        "Showing up is important to our restaurant partners. If something changes let us know.",
        "Please treat our team, restaurants, and their staff with the utmost respect."
    ]
    return (
        <div className="bg-blue-700 xl:h-[360px] rounded-lg flex p-3 lg:p-8">
            <div className="md:w-[45%] xl:pr-5">
                <h3 className="font-productsans-medium leading-tight text-2xl text-white">Your experience is our number priority. Expect a memorable time. Always.</h3>
            </div>
            <div className="md:w-[55%] xl:pl-7">
                {promises.map((promise, index) => (
                    <div className="flex mb-7">
                        <div className="bg-blue-200 h-8 w-8 shrink-0 mr-4 rounded-full flex items-center justify-center">
                            <h6 className="text-blue-700 font-roboto-medium text-sm">{index + 1}</h6>
                        </div>
                        <div className="andie-description-text leading-tight w-4/5 text-white">
                            {promise}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}