import React from "react";

export default function Overview() {
    return (
        <div className="andie-description-text">
            <p>Pluck Technologies Inc. (the &ldquo;<strong>Company</strong>&rdquo; or &ldquo;<strong>We</strong>&rdquo;) respect your privacy and are committed to protecting it by complying with this policy and taking all necessary steps to secure the information and interactions you have on our platform.</p>
            <p>&nbsp;</p>
            <p>This policy describes:</p>
            <p>&nbsp;</p>
            <ul class="list-disc ml-10">
                <li>how we collect, use, disclose and protect the personal information of our customers and users (&ldquo;<strong>you</strong>&rdquo;);</li>
                <li>the type of information we may collect from you or that you may provide when you visit Pluck (the <strong>&ldquo;Site&rdquo;</strong>) and mobile or desktop applications you download from the Site, or third party app stores; and</li>
                <li>our practices for collecting, using, maintaining, protecting and disclosing that information.</li>
            </ul>
            <p>&nbsp;</p>
            <p>This policy applies:</p>
            <p>&nbsp;</p>
            <ul class="list-disc ml-10">
                <li>to information we collect, use, or disclose about you on the Site, in email, text and other electronic messages between you and the Site;</li>
                <li>to mobile and desktop applications you download from the Site, which provide dedicated non-browser-based interaction between you and the Site;</li>
                <li>when you interact with our advertising and applications on third-party websites and services if those applications or advertising include links to this policy.</li>
            </ul>
            <p>&nbsp;</p>
            <p>The Site may include links to third-party websites, plug-ins, services, social networks, or applications. Clicking on those links or enabling those connections may allow the third party to collect or share data about you. If you follow a link to a third-party website or engage a third-party plugin, please note that these third parties have their own privacy policies and we do not accept any responsibility or liability for these policies. We do not control these third-party websites, and we encourage you to review the privacy policy of every website you visit.</p>
            <p>&nbsp;</p>
        </div>
    )
}