import { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import toast, { Toaster } from 'react-hot-toast';

import { AccountsAPI } from "../../../apis";
import { AdminBusinessForm } from "../";

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        minWidth: "45%",
        height: '85%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

export default function AdminCreateBusiness({ staffAccountList }) {
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [rePassword, setRePassword] = useState("")
    const [businessname, setBusinessname] = useState("")
    const [businessTitle, setBusinessTitle] = useState("")
    const [phone, setPhone] = useState("")
    const [location, setLocation] = useState("");
    const [locationPlaceID, setLocationPlaceID] = useState("");
    const [locationGeometry, setLocationGeometry] = useState("");
    const [locationAdditionalDetails, setLocationAdditionalDetails] = useState("");
    const [restaurantLevel, setRestaurantLevel] = useState(4)
    const [restaurantTypes, setRestaurantTypes] = useState("");
    const [businessRep, setBusinessRep] = useState(staffAccountList[0].email);

    async function createUser(e) {
        e.preventDefault()
        if (password != rePassword) {
            toast.error("Passwords must match!")

        } else {
            let genericData = {
                email: email,
                first_name: firstname,
                last_name: lastname,
                password: password,
                re_password: rePassword,
                account_type: 2,
                access_code: "None",
            };

            let businessData = {
                email: email,
                first_name: firstname,
                last_name: lastname,
                business_name: businessname,
                place_id: locationPlaceID,
                address_details: location,
                phone: phone,
                job_title: businessTitle,
                restaurant_level: restaurantLevel,
                restaurant_type : "Fine Dining",
                restaurant_types: restaurantTypes,
                business_representative: businessRep
            };

            console.log(genericData)
            console.log(businessData)
            try {
                const genericRes = await AccountsAPI.CreateGenericUser(genericData);
                const businessRes = await AccountsAPI.CreateBusinessUser(businessData).then(() => window.location.reload());
                // await getData();
                // setBusinessModal(false)
            } catch (e) {
            }
        }
    }

    return (

            <AdminBusinessForm
                staffAccountList={staffAccountList}
                type="create" createUser={(e) => createUser(e)}
                firstname={firstname} setFirstname={(e) => setFirstname(e)}
                lastname={lastname} setLastname={(e) => setLastname(e)}
                email={email} setEmail={(e) => setEmail(e)} password={password}
                setPassword={(e) => setPassword(e)} rePassword={rePassword}
                setRePassword={(e) => setRePassword(e)} businessname={businessname}
                setBusinessname={(e) => setBusinessname(e)} phone={phone}
                setPhone={(e) => setPhone(e)} businessTitle={businessTitle}
                setBusinessTitle={(e) => setBusinessTitle(e)} location={location}
                setLocation={(e) => setLocation(e)} locationPlaceID={locationPlaceID}
                setLocationPlaceID={(e) => setLocationPlaceID(e)}
                locationGeometry={locationGeometry}
                setLocationGeometry={(e) => setLocationGeometry(e)} 
                locationAdditionalDetails={locationAdditionalDetails}
                setLocationAdditionalDetails={(e) => setLocationAdditionalDetails(e)}
                restaurantLevel={restaurantLevel} setRestaurantLevel={(e) => setRestaurantLevel(e)}
                restaurantTypes={restaurantTypes} setRestaurantTypes={(e) => setRestaurantTypes(e)}
                businessRep={businessRep}
                setBusinessRep={(e) => setBusinessRep(e)}
            />
    )
}