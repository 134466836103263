import React, { useState, useEffect } from "react";

import { AdminNavbar, AdminSidebar, AdminCGERequestAccept } from '../../components/admin';
import { AccountsAPI } from "../../apis";
import { PopoutModal } from "../../components/common";
import AccountRequestAccess from "../../components/account/AccountRequestAccess";
import { TrashIcon } from "@heroicons/react/outline";
import { PlusCircleIcon, PlusIcon } from "@heroicons/react/solid";


export default function AdminCGERequests({ parentLoading, getCGERequests, requests, staffAccountList }) {
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    var user = JSON.parse(window.localStorage.getItem('admin_user_details'));

    useEffect(() => {
        if (user !== null) {
            getData();
        }
    }, [refresh]);

    useEffect(() => {
        if (
            parentLoading[0] ||
            parentLoading[3]
        ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [parentLoading])


    async function getData() {
        setLoading(true);
        await getCGERequests();
        setLoading(false)
    }

    async function deleteItem(item) {
        console.log((item))
        await AccountsAPI.deleteAccessRequest({
            "email": item.email
        })
    }



    return (
        <div className="h-full overflow-scroll scrollbar-hide">
            {loading ?
                <div className="flex flex-col space-y-5 w-full h-full items-center justify-center">
                    <span className="loading loading-spinner loading-lg"></span>
                    <p className="font-productsans-medium">Loading Requests</p>
                </div>
                :
                <>
                    <table className="table table-pin-rows table-pin-cols w-full h-full ">
                        <thead>
                            <tr>
                                <th>Company</th>
                                <th>Client</th>
                                <th>Industry</th>
                                <th>Access Code</th>
                                <th>Accepted?</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {requests.length > 0 ?
                                requests.map((account, index) => (
                                    <tr className="font-roboto-regular text-paragraph h-10">
                                        <th>{account.organization}</th>
                                        <td>
                                            <div className="flex bid-label text-gray-600 mb-0">{account.first_name} {account.last_name}</div>
                                            <div>{account.email}</div>
                                            {account.phone}
                                        </td>
                                        <td><div className="py-0.5 font-productsans-medium text-xs uppercase px-1.5 w-28 border-2 flex justify-center items-center">
                                            {account.industry}
                                        </div></td>
                                        <td>{account.access_code}</td>
                                        <td>
                                            {account.accepted ?
                                                <button className="bg-green-200 font-productsans-medium text-paragraph cursor-none px-2 py-1">Accepted</button>
                                                :
                                                <PopoutModal
                                                    modalID={'accept_user_' + account.email}
                                                    // width={50}
                                                    button={
                                                        <button className="andie-dark-button">Accept CGE User</button>
                                                    }
                                                >
                                                    <AdminCGERequestAccept staffAccountList={staffAccountList} account={account} />
                                                </PopoutModal>
                                            }
                                        </td>
                                        <td>
                                            <PopoutModal
                                                modalID={'delete_item_' + account.email}
                                                // width={25}
                                                title={"Delete User Request?"}
                                                small={true}
                                                button={
                                                    <button className="w-full hover:bg-gray-200 p-1 rounded-md text-red-600"><TrashIcon /></button>
                                                }
                                            >
                                                <div className="flex flex-col font-roboto-regular text-md space-y-10">
                                                    <div className='space-y-2'>
                                                        <p> Are you sure you want to delete: </p>
                                                        <p className='font-roboto-bold'> {account.email} ({account.first_name} {account.last_name}) </p>
                                                        <p> user request? This is a permanent action and cannot be undone.</p>
                                                    </div>
                                                    <div className="flex flex-row space-x-2">
                                                        <button className="andie-dark-button">No</button>
                                                        <button className="andie-dark-button bg-danger hover:bg-danger-700" onClick={() => deleteItem(account)}>Yes</button>
                                                    </div>
                                                </div>
                                            </PopoutModal>
                                        </td>
                                    </tr>
                                ))
                                :
                                <></>
                            }
                        </tbody>
                    </table>
                    <PopoutModal
                        modalID={'create-user-request'}
                        title={"Create User"}
                        // width={50}
                        button={
                            <button
                                class="absolute bottom-12 right-10 z-99 flex flex-row items-center space-x-2 
                text-white px-4 w-auto h-10 bg-blue-600 rounded-full
                 hover:bg-blue-700 active:shadow-lg mouse shadow-xl
                  transition ease-in duration-200 focus:outline-none">
                                <PlusIcon className="h-4" />
                                <span>New CGE User</span>


                            </button>
                        }>
                        <div className="w-full h-full">
                            <AccountRequestAccess redirect={false} />
                        </div>
                    </PopoutModal>
                </>}
        </div>
    )
}