import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { AccountsAPI } from '../../apis';
import { Link } from "react-router-dom";
import { LogoutIcon } from "@heroicons/react/solid";

export default function AccountSettings() {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [businessName, setBusinessName] = useState("");
    const [organization, setOrganization] = useState("")
    const [industry, setIndustry] = useState("");
    const [location, setLocation] = useState("");
    const [locationPlaceID, setLocationPlaceID] = useState("");
    const [firstName, setFirstName] = useState(user?.first_name);
    const [lastName, setLastName] = useState(user?.last_name);
    const [email, setEmail] = useState(user?.email);
    const [phoneNumber, setPhoneNumber] = useState(user?.phone);
    const [jobTitle, setJobTitle] = useState("");

    useEffect(() => {
        if (user?.account_type == 1) {
            setOrganization(user?.organization)
            setIndustry(user?.industry)
            setJobTitle(user?.job_title)
        } else {
            setLocation(user?.address_details)
            setLocationPlaceID(user?.place_id)
            setBusinessName(user?.business_name)
        }
    }, [])

    const submitChanges = async () => {
        if (user?.account_type == 1) {
            const payload = {
                organization: organization,
                industry: industry,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phoneNumber,
                job_title: jobTitle
            }
            console.log(payload)
            await AccountsAPI.editCGEUser(payload)
            // .then(async (res) => {
            //     if (res) {
            //         await AccountsAPI.VerifyUserOnLogin()
            //     }
            // })
        }
        else {
            const payload = {
                business_name: businessName,
                address_details: location,
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phoneNumber,
                place_id: locationPlaceID,
            }

            await AccountsAPI.editBusinessUser(payload).then(async (res) => {
                if (res) {
                    await AccountsAPI.VerifyUserOnLogin()
                }
            })
        }
    }

    return (
        <>
            <div className="w-full md:w-4/5 lg:w-3/5">
                <h6 className="hidden md:inline-flex andie-sidebar-title">Account Settings</h6>
                <div className="mt-5">
                    <form>
                        <h6 className="text-sm font-productsans-medium text-gray-700">{user?.account_type == 1 ? "Corporate" : "Partner"} Information</h6>
                        <p className="andie-small-description md:w-4/5">Provide the necessary information about your {user?.account_type == 1 ? "organization" : "business"}. This information is used to autofill important fields in your transactions.</p>
                        <div className="flex space-x-4 mb-4">

                            {user?.account_type == 1 ?
                                <>
                                    <div className="w-3/5">
                                        <label htmlFor="name" className="bid-form-input-label">
                                            Organization
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="mt-2 w-full border-2 border-gray-100 rounded-sm p-1.5 andie-paragraph focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            required
                                            aria-label="Business Name"
                                            onChange={(e) => setOrganization(e.target.value)}
                                            value={organization}
                                        />
                                    </div>
                                    <div className="w-2/5">
                                        <label htmlFor="name" className="bid-form-input-label">
                                            Industry
                                        </label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="mt-2 w-full border-2 border-gray-100 rounded-sm p-1.5 andie-paragraph focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            required
                                            aria-label="Industry"
                                            onChange={(e) => setIndustry(e.target.value)}
                                            value={industry}
                                        />
                                    </div>
                                </>
                                :
                                <div className="w-3/5">
                                    <label htmlFor="name" className="bid-form-input-label">
                                        Business Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        className="mt-2 w-full border-2 border-gray-100 rounded-sm p-1.5 andie-paragraph focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                        aria-label="Business Name"
                                        onChange={(e) => setBusinessName(e.target.value)}
                                        value={businessName}
                                    />
                                </div>
                            }



                        </div>
                        {user?.account_type == 2 &&
                            <div className="mb-4">
                                <label htmlFor="name" className="bid-form-input-label">
                                    Address
                                </label>
                                <Autocomplete
                                    className="mt-2 w-full border-2 border-gray-100 rounded-sm p-1.5 andie-paragraph focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    // apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                    required
                                    onPlaceSelected={(place) => {
                                        setLocation(place.formatted_address)
                                        setLocationPlaceID(place.place_id)
                                    }}
                                    options={{ types: ["geocode", "establishment"] }}
                                    placeholder={location}
                                />
                            </div>
                        }

                        <h6 className="mt-7 text-sm font-productsans-medium text-gray-700">Contact Details</h6>
                        <p className="andie-small-description">Tell us who to make the point-of-contact for your organization.</p>

                        <div className="flex space-x-4 mb-4">
                            <div className="w-1/2">
                                <label htmlFor="name" className="bid-form-input-label">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    className="mt-2 w-full border-2 border-gray-100 rounded-sm p-1.5 andie-paragraph focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                    aria-label="First Name"
                                    onChange={(e) => setFirstName(e.target.value)}
                                    value={firstName}
                                />
                            </div>
                            <div className="w-1/2">
                                <label htmlFor="name" className="bid-form-input-label">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    className="mt-2 w-full border-2 border-gray-100 rounded-sm p-1.5 andie-paragraph focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                    aria-label="Last Name"
                                    onChange={(e) => setLastName(e.target.value)}
                                    value={lastName}
                                />
                            </div>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="name" className="bid-form-input-label">
                                Email Address
                            </label>
                            <input
                                type="text"
                                id="name"
                                className="mt-2 w-full border-2 border-gray-100 rounded-sm p-1.5 andie-paragraph focus:outline-none focus:ring-2 focus:ring-blue-500"
                                // required
                                aria-label="Email"
                                value={email}
                                disabled
                            />
                        </div>

                        <div className="flex space-x-4 mb-6">
                            <div className="w-1/2">
                                <label htmlFor="name" className="bid-form-input-label">
                                    Phone Number
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    className="mt-2 w-full border-2 border-gray-100 rounded-sm p-1.5 andie-paragraph focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                    aria-label="Phone Number"
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    value={phoneNumber}
                                />
                            </div>
                            {user?.account_type == 1 &&
                                <div className="w-1/2">
                                    <label htmlFor="name" className="bid-form-input-label">
                                        Job Title
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        className="mt-2 w-full border-2 border-gray-100 rounded-sm p-1.5 andie-paragraph focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                        aria-label="First Name"
                                        onChange={(e) => setJobTitle(e.target.value)}
                                        value={jobTitle}
                                    />
                                </div>
                            }
                        </div>


                        <div className="mt-4 flex justify-between">

                            <Link to='/' onClick={() => localStorage.clear()}>

                                <div className="andie-dark-button bg-red-600 inline-flex w-28">
                                    Logout
                                </div>
                            </Link>

                            <div onClick={submitChanges} className="andie-dark-button inline-flex w-28">
                                Save Changes
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}