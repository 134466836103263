import { useState, useEffect } from "react";
import { StripeAPI } from "../../apis";
import StripeSVG from '../../assets/images/stripe.svg';


export default function StripeReminder() {
    const [apiLink, setApiLink] = useState("");
    const [loading, setLoading] = useState(true);
    const user = JSON.parse(window.localStorage.getItem('user_details'));

    useEffect(() => {
        const generateLink = async () => {
            const link = await StripeAPI.generateAPILink(user.email);
            setApiLink(link.link)
            setLoading(false)
        }
        generateLink();
    }, [])

    return (
        <>
            <div className="mt-3 justify-between text-center">
                <div class="mt-2 px-7 py-3">
                    <img
                        src={StripeSVG}
                        className="w-full"
                        // style={{ height: 150, color:"6772E5"}}
                        alt="stripe logo"
                    />
                    <p className="text-sm text-gray-800">Please complete the stripe signup process for your partner account.</p>
                </div>
                <div className="items-center px-4 py-3">
                    <button onClick={() => window.open(apiLink)} className="andie-dark-button bg-[#6772E5] px-4 py-2 "> Click here to complete the process</button>
                </div>
            </div>
        </>
    )
}