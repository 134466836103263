import { CalculatorIcon } from "@heroicons/react/solid";
import React from "react";

export default function PluckCalculator() {
    return (
        <div className="h-screen">
            <div className="flex h-[calc(100%_-_40px)]">
                <div className="andie-side-panel" />
                <div className="andie-main-panel overflow-y-scroll lg:overflow-hidden scrollbar-hide pb-14">
                    <div className="bg-blue-100 p-4 md:p-5 rounded-xl">
                        <div className="flex justify-between">
                            <div className="md:w-1/2 space-y-2 pb-3">
                                <h6 className="andie-section-header">Try the ideal budget calculator</h6>
                                <p className="andie-description-text leading-tight">Use the pluck calculator to estimate the cost of meals and refreshments for your next event or booking.</p>
                            </div>
                            <CalculatorIcon height={28} color="#2563eb" />
                        </div>
                        <div className="flex justify-end">
                            <p className="andie-description-text leading-tight font-roboto-medium">v 1.3.1</p>
                        </div>
                    </div>
                    <div className="mt-5 border-t border-gray-100 pt-5 lg:h-[calc(100svh_-_250px)] grid md:grid-cols-2 gap-5">
                        <div className="">

                        </div>
                        <div className="border-2 border-dashed h-full p-3 border-gray-100 rounded-xl">
                            city, dining or catering, how many restaurants do you want to order from if its catering, preferred cuisine, experience level, number of guests
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}