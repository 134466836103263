import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { dietaryOptions, menuoptions } from '../../common';
import { random } from 'lodash';
import { toast } from 'react-hot-toast';

export default function ResponseItemFormInput({ showItemForm, setShowItemForm, responseItems, setResponseItems, editingID, setEditingID, deleteItem }) {

    const [currentItem, setCurrentItem] = useState("");
    const [currentPrice, setCurrentPrice] = useState(0);
    const [currentQuantity, setCurrentQuantity] = useState(1);
    const [currentServes, setCurrentServes] = useState(1);
    const [currentDescription, setCurrentDescription] = useState("");
    const [currentMenuOption, setCurrentMenuOption] = useState("Appetizer and Shareables");
    const [currentRestriction, setCurrentRestriction] = useState(["None"]);

    const [errors, setErrors] = useState([0, 0, 0, 0, 0, 0, 0])
    const errorText = [
        "Please enter an item name with min. 2 chars",
        "Please select a menu option.",
        "Please specify an item price.",
        "Please specify an item quantity",
        "Please specify an item serving size",
        "Please enter an item description with min. 15 chars",
        "Please select \"None\" explicitly if there are no dietary restrictions"
    ]

    const customStyles = {
        control: base => ({
            ...base,
            height: 40,
            minHeight: 35,
            border: errors[6] ? '2px solid #F3F4F6' : '2px solid #dc2626',

        }),
        valueContainer: base => ({
            ...base,
            overflowY: 'scroll', // Enable vertical scrolling
            height: 36,
            zIndex: 2 // Ensure the menu displays above other elements
        }),
        container: base => ({
            ...base,
        })
    };

    useEffect(() => {
        if (editingID !== -1) {
            const itemToEdit = responseItems.find(item => item.id === editingID)
            setCurrentItem(itemToEdit.name)
            setCurrentPrice(itemToEdit.price)
            setCurrentQuantity(itemToEdit.quantity)
            setCurrentServes(itemToEdit.serves)
            setCurrentDescription(itemToEdit.description)
            setCurrentMenuOption(itemToEdit.menuoption)
            setCurrentRestriction(itemToEdit.dietary_option)

            setShowItemForm(true)
        }

    }, [editingID])

    useEffect(() => {
        validateFields()
    }, [
        currentItem,
        currentPrice,
        currentQuantity,
        currentServes,
        currentDescription,
        currentMenuOption,
        currentRestriction
    ]);

    async function handleItemAdd() {
        if (errors.includes(false)) {
            for (const text of errorText.map(
                (item, index) => !errors[index] && item).filter(item => item !== false)
            ) {
                toast.error(text)
            }
        } else {
            const temp = {
                id: editingID === -1 ? random(99999) : editingID,
                name: currentItem,
                price: currentPrice,
                quantity: currentQuantity,
                serves: currentServes,
                description: currentDescription,
                menuoption: currentMenuOption,
                dietary_option: currentRestriction
            }

            console.log(responseItems)
            
            if (editingID === -1)
                setResponseItems([...responseItems, temp])
            else
                setResponseItems([...responseItems.filter(item => item.id !== editingID), temp])

            resetFields()
            // setShowItemForm(false)
        }
    }

    function handleEdit() {
        handleItemAdd()
        if (!errors.includes(false)) {
            resetFields()
            setShowItemForm(false)
            setEditingID(-1)
        }
    }

    async function handleCancel() {
        resetFields()
        setShowItemForm(false)
        setEditingID(-1)
    }

    function validateFields() {
        setErrors([
            (currentItem.length >= 2),
            (menuoptions.map(option => option.title).includes(currentMenuOption)),
            (currentPrice >= 0),
            (currentQuantity >= 1),
            (currentServes >= 1),
            (currentDescription.length >= 15),
            (currentRestriction.length >= 1)
        ])
    }

    function resetFields() {
        setCurrentItem("")
        setCurrentPrice(0)
        setCurrentQuantity(1)
        setCurrentServes(1)
        setCurrentDescription("")
        setCurrentMenuOption("Appetizer and Shareables")
        setCurrentRestriction(["None"])
    }

    return (
        showItemForm && <>
            <div className="mt-8 space-y-5">
                <div className="flex space-x-5">
                    <div className='w-full px-0 my-1'>
                        <label className='label'> <span className='label-text-alt'> Item Name </span> </label>
                        <input
                            className={`w-full 
                            focus:outline-0 focus:border-l-0 focus:border-t-0 focus:border-r-0 focus:border-b-2 focus:border-blue-600 
                            border-t-0 border-x-0 border-gray-200 border-b-2 andie-description-text
                            ${errors[0] ? "" : 'focus:border-red-600 border-red-600'}`}
                            id="item_name"
                            type="text"
                            placeholder="Item name"
                            aria-label="Item Name"
                            onChange={(e) => setCurrentItem(e.target.value)}
                            value={currentItem}
                            required
                        />
                    </div>
                    <div className='w-full px-0 my-1'>
                        <label className='label'> <span className='label-text-alt'> Menu Option </span> </label>
                        <select id="event_type" value={currentMenuOption} onChange={(e) => setCurrentMenuOption(e.target.value)}
                            className={`focus:outline-0 focus:border-l-0 focus:border-t-0 focus:border-r-0 focus:border-b-2 focus:border-blue-600 
                        border-t-0 border-x-0 border-gray-200 border-b-2 andie-description-text text-gray-500
                        ${errors[1] ? "" : 'focus:border-red-600 border-red-600'}`}>
                            {menuoptions.map((item, index) => {
                                return (
                                    <option> {item.title}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="grid my-1.5 grid-cols-3 gap-x-1">
                <div className=''>
                    <label className='label'> <span className='label-text-alt'> Price per Quantity </span> </label>
                    <span className=' flex flex-row'>
                        <p className="px-0 h-7 my-2 mr-1">$ </p>
                        <input
                            className={`px-0 h-7 w-full
                            focus:outline-0 focus:border-l-0 focus:border-t-0 focus:border-r-0 focus:border-b-2 focus:border-blue-600 
                            border-t-0 border-x-0 border-gray-200 border-b-2 my-1 andie-description-text
                            ${errors[2] ? "" : 'focus:border-red-600 border-red-600'}`}
                            id="price"
                            type="number"
                            placeholder="Price"
                            min={0}
                            onWheel={(e) => e.target.blur()}
                            aria-label="Price"
                            onChange={(e) => setCurrentPrice(parseFloat(e.target.value))}
                            value={currentPrice}
                            required
                        />
                    </span>
                </div>
                <div className=''>
                    <label className='label'> <span className='label-text-alt'> Quantity </span> </label>
                    <input
                        className={`px-0 h-7 w-full
                        focus:outline-0 focus:border-l-0 focus:border-t-0 focus:border-r-0 focus:border-b-2 focus:border-blue-600 
                        border-t-0 border-x-0 border-gray-200 border-b-2 my-1 andie-description-text
                        ${errors[3] ? "" : 'focus:border-red-600 border-red-600'}`}
                        id="quantity"
                        type="number"
                        min={1}
                        onWheel={(e) => e.target.blur()}
                        placeholder="Quantity"
                        aria-label="Quantity"
                        onChange={(e) => setCurrentQuantity(parseFloat(e.target.value))}
                        value={currentQuantity}
                        required
                    />
                </div>
                <div className=''>
                    <label className='label'> <span className='label-text-alt'> Serves </span> </label>
                    <input
                        className={`px-0 h-7 w-full
                        focus:outline-0 focus:border-l-0 focus:border-t-0 focus:border-r-0 focus:border-b-2 focus:border-blue-600 
                        border-t-0 border-x-0 border-gray-200 border-b-2 my-1 andie-description-text
                        ${errors[4] ? "" : 'focus:border-red-600 border-red-600'}`}
                        id="serves"
                        type="number"
                        min={1}
                        onWheel={(e) => e.target.blur()}
                        placeholder="Serves"
                        aria-label="Serves"
                        onChange={(e) => setCurrentServes(parseInt(e.target.value))}
                        value={currentServes}
                        required
                    />
                </div>

            </div>
            <div className="mt-2 flex flex-col space-x-2 my-2.5">
                <label className='label'> <span className='label-text-alt'> Description </span> </label>
                <textarea
                    className={`w-full px-0 h-7 
                    focus:outline-0 focus:border-l-0 focus:border-t-0 focus:border-r-0 focus:border-blue-600 
                    border-t-0 border-x-0 border-gray-200 border-b-2 my-1 andie-description-text
                    ${errors[5] ? "" : 'focus:border-red-600 border-red-600'}`}
                    id="price"
                    type="text"
                    placeholder="Description"
                    aria-label="Description"
                    onChange={(e) => setCurrentDescription(e.target.value)}
                    value={currentDescription}
                />
            </div>
            <div className="flex flex-col w-full mb-2">
                <label className='label'> <span className='label-text-alt'> Dietary Requirements </span> </label>
                <Select
                    defaultValue={{ value: "None", label: "None" }}
                    isMulti
                    value={currentRestriction.map((option) => { return { "value": option, "label": option } })}
                    options={dietaryOptions.map((option) => { return { "value": option.preference, "label": option.preference } })}
                    className="basic-multi-select w-full"
                    classNamePrefix="select"
                    styles={customStyles}
                    onChange={(e) => setCurrentRestriction((e.map(a => a.value)))}
                />
            </div>
            <div className='flex flex-row space-x-4'>
                <div onClick={() => handleCancel()} className="andie-outline-button cursor-pointer my-0.5">
                    <p className="text-xs font-productsans-medium text-red-600">Cancel</p>
                </div>
                {editingID === -1 ?
                    <div onClick={() => handleItemAdd()} className="andie-outline-button cursor-pointer my-0.5">
                        <p className="text-xs font-productsans-medium text-blue-600">Save Item</p>
                    </div>
                    :
                    <div onClick={() => handleEdit()} className="andie-outline-button cursor-pointer my-0.5">
                        <p className="text-xs font-productsans-medium text-blue-600">Update Item</p>
                    </div>
                }
            </div>
        </>
    );
}
