import React, { useEffect, useState } from "react";
import { ChatIcon, CheckCircleIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

import { AdminBidsItemBar, AdminChat, AdminCompleteResponse, AdminDeleteResponse } from "..";
import { BiddingAPIs } from "../../../apis";
import moment from "moment";
import { pricetoString } from "../../common";
import { BidResponseTotalDetails, ResponseDocumentsDownload } from "../../responses";
import AdminAcceptResponse from "./AdminAcceptResponse";

export default function AdminBidsResponseBar({ bid, response, staffAccountList, businessAccountList, cgeAccountList }) {
    const [rep, setRep] = useState(response.business_representative);

    const [showItemsDropdown, setshowItemsDropdown] = useState(false);
    const [edited, setEdited] = useState(false)


    useEffect(() => {
        response['bid'] = bid

    }, []);
    const handleItemBarToggle = () => {
        setshowItemsDropdown(!showItemsDropdown)
    }

    const handleItemEdit = (valuename, value, itemindex) => {
        const tempItems = response.items
        const currItem = tempItems[itemindex]
        tempItems[itemindex][valuename] = value

        response.items = tempItems
        setEdited(true)
    }

    const handleResponseEdit = (valuename, value) => {
        response[valuename] = value
        setEdited(true)

    }

    const handleRepEdit = (valuename, value) => {
        response[valuename] = value
        setEdited(true)
    }

    const submitChanges = async () => {
        if (edited) {
            const payload = response
            await BiddingAPIs.editBidResponse(payload)
        }
    }

    return (
        <div className="w-full transition-all pb-2">
            {/* <div className="flex justify-between"> */}
            {/* <div>
                    <h4 className="font-productsans-bold text-base">Bid Response #00{response.id}</h4>
                </div>
                <AdminChat item={response} /> */}
            {/* <div onClick={submitChanges} className="andie-dark-button w-48 cursor-pointer flex flex-row items-center">
                    <CheckIcon className="h-6 text-white" />
                    <p>Save Edits to Response</p>
                </div> */}
            {/* </div> */}
            <div className="flex flex-row w-full border-b  border-gray-100 justify-between items-center">
                <div className="w-3/5 mb-3">
                    <p className='font-productsans-bold text-base'> {response.restaurant_name} </p>
                    <p className='font-productsans-regular text-sm'> {response.contact_first_name} {response.contact_last_name} </p>
                    <p className='font-productsans-regular text-paragraph'> {response.business_email} </p>
                    <p className='font-productsans-regular text-paragraph'> {response.contact_phone} </p>
                </div>
                <div className="mb-3 text-right">
                    <div className="flex space-x-2">
                        {/* <div className="flex flex-grow flex-col"> */}
                        <AdminChat item={response} />
                        <ResponseDocumentsDownload
                            staffAccountList={staffAccountList}
                            businessAccountList={businessAccountList}
                            cgeAccountList={cgeAccountList}
                            response={response}
                        />
                        {/* </div> */}
                        <AdminDeleteResponse response={response} />
                    </div>
                    {(bid.status !== "Posted" && response.accepted == false && bid.cge_type !== "Catering Plus") ?
                        <div className="h-8 w-full px-3 bg-red-100 rounded flex items-center justify-center mt-2">
                            <p className="font-productsans-medium text-red-600">Response Rejected</p>
                        </div>
                        :
                        bid.status === "Completed" ?
                            <div className="h-8 w-full px-3 bg-green-100 rounded flex items-center justify-center mt-2">
                                <p className="font-productsans-medium text-green-600">Response Completed and Paid</p>
                            </div>
                            :
                            response.accepted == true ?
                                <AdminCompleteResponse response={response} />
                                :
                                <AdminAcceptResponse response={response} />


                    }
                    <p className='font-roboto-regular text-paragraph mt-2'> created on {moment(new Date(response.date_created)).format("lll")} </p>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-x-4 w-full py-3 border-b border-gray-100">
                <div>
                    <div className='font-roboto-bold text-sm'>Provides Delivery </div>
                    <div className='font-roboto-regular text-sm'>{response.business_delivers ? "Yes" : "No"}</div>
                </div>
                <div>
                    <div className='font-roboto-bold text-sm'>Restaurant Delivery Fee</div>
                    <div className='font-roboto-regular text-sm'>{response.business_delivers ? '$' + pricetoString(response.delivery_fee) : "N/A"}</div>
                </div>

                <div>
                    <div className='font-roboto-bold text-sm'> Bid Service Fee </div>
                    <div className="w-full flex border border-gray-200 items-center h-8 pl-1">
                        <input defaultValue={response.business_service_fee * 100} onChange={(e) => handleResponseEdit('business_service_fee', e.target.value / 100)} type="number" onWheel={(e) => e.target.blur()} className="w-full" />
                        <p className='mr-1 font-roboto-regular text-sm'> % </p>
                    </div>
                </div>

            </div>
            <div className="mb-3 py-3">
                <div className='font-roboto-bold text-sm'> Response Manager </div>
                <div >
                    <select onChange={(e) => {
                        handleRepEdit('business_representative', e.target.valdiv)
                        setRep(e.target.value)
                    }} id="cge_rep"
                        className="w-full h-8 pl-1 flex border border-gray-200">
                        <option disabled={true}>Assigned Manager</option>
                        {staffAccountList?.map((item, index) => (
                            <option value={item.email} selected={item.email === rep}>{item.first_name} {item.last_name}, {item.role} - {item.department}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="p-2.5 border bg-gray-50 rounded-lg shadow">
                <div className="flex flex-col justify-between ml-5 mt-3">
                    <p className='font-productsans-regular text-base'>Response Items</p>
                </div>

                {response?.items.length > 0 &&
                    response?.items.map((item, index) => (
                        <AdminBidsItemBar item={item} handleItemEdit={(y, z) => handleItemEdit(y, z, index)} />
                    ))
                }

                <div className="mx-14 my-2.5 border-t border-black"></div>
                <div className="px-5 mt-2.5 mb-5">
                    <BidResponseTotalDetails
                        items={response.items}
                        cge_service_fee_rate={bid.cge_service_fee}
                        business_service_fee_rate={response.business_service_fee}
                        delivery_fee={response.delivery_fee}
                        business_delivers={response.business_delivers}
                        tipAmount={bid.budget['tipAmount']}
                        cge_type={bid.cge_type}
                        user_override={1}
                        pricingType={response.pricing_type}
                        totalPrice={response.total_price}
                    />
                </div>
            </div>
        </div>
    )
}