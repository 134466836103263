import React from "react";
import { eventOptions, cgeOptions } from "../../../common";

export default function EditEventInfo({ bidTitle, setBidTitle, bidDescription, setBidDescription, cgeType, setCGEType, eventType, setEventType, setAccessibility }) {


    function selectCGEType(option) {
        setCGEType(option);
        if (option == "Dining" || option == "Premium Dining") {
            setAccessibility({
                "Parking": false,
                "Accessible": false,
                "Transit": false,
                "Ride Share": false
            })
        } else {
            setAccessibility({
                "Sidewalk": false,
                "Parking": false,
                "Stairs": false,
                "Elevators": false
            })
        }
    }

    function selectEventType(option) {
        setEventType(option);
    }

    return (
        <div className="border-b border-gray-100 pb-5">
            <h6 className="font-productsans-medium text-largescreen text-gray-400">About this bid</h6>
            <div className="flex mt-3">
                <div className="w-full pb-5 pr-5">
                    <input
                        className="bid-form-input w-full"
                        id="cus_bid_title"
                        onChange={(e) => setBidTitle(e.target.value)}
                        type="text"
                        required
                        placeholder="E.g. Client meeting for 2"
                        aria-label="Bid Title"
                        value={bidTitle}
                    />
                    <textarea
                        className="mt-4 w-full h-32 bid-form-input"
                        id="cus_description"
                        onChange={(e) => setBidDescription(e.target.value)}
                        type="text"
                        required
                        placeholder="E.g. We are hosting an important client dinner on the 26th. Our guests are coming from the International Space Station."
                        minLength={125}
                        aria-label="Description"
                        value={bidDescription}
                    />
                    <p className='text-xs mb-2 font-roboto-regular text-gray-500'>Min. <b>{bidDescription.length}/125</b> characters</p>
                    <select id="event_type" onChange={(e) => selectEventType(e.target.value)} className="w-full my-3 px-3 border-transparent bg-blue-100 rounded focus:ring-0 focus:border-0 h-9 andie-description-text">
                        {eventOptions.map((item, index) => (
                            <option selected={item.name === eventType}>{item.name}</option>
                        ))}
                    </select>

                    <div className="w-full mt-4 flex space-x-6">
                        {cgeOptions.map((option, index) => (
                            <div className="flex items-center">
                                <div onClick={() => selectCGEType(option.name)} className={`cursor-pointer border-2 h-4 w-4 mr-1.5 flex items-center justify-center rounded ${option.name === cgeType ? 'border-blue-600' : 'border-gray-300'}`}>
                                    {option.name === cgeType && <div className="h-2 w-2 rounded-sm bg-blue-600" />}
                                </div>
                                <p className="ml-0.5 text-xs font-semibold text-gray-500">{option.name}</p>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}