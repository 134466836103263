import moment from 'moment';
import React from 'react';
import { bidStatusColors, calculateBudget, pricetoString } from '../../common';

function AdminBidsItem({ bid, testVal }) {
    return (
        <div className={`cursor-pointer flex flex-col justify-between border-t-4 bg-blue-50 ${testVal === true ? "border-red-400" : "border-gray-100"} rounded-md p-3`} >
            <div className="mt-1">
                <div className="flex justify-between w-full">
                    <h5 className={`font-productsans-bold text-sm w-3/4 text-blue-600 line-clamp-2 leading-tight mb-1`}>{bid.bid_title} </h5>
                    <div className='flex flex-col w-1/4'>
                        {/* <p className="text-xs text-right font-roboto-medium text-gray-400">exp. {moment(new Date(bid.expiry_date)).fromNow()}</p> */}
                        
                        <div className={`inline-flex ${bidStatusColors[bid.status]} items-center justify-center font-roboto-medium rounded-md text-xs p-0.5 px-1 `}>{bid.status}</div>
                    </div>

                </div>

                <div className="flex flex-col my-2.5">
                    <h5 className={`font-productsans-medium text-sm w-3/4 text-gray-700 line-clamp-2 leading-tight`}>{bid.organization}</h5>
                    <h5 className={`font-roboto-medium text-xs w-3/4 text-gray-700 line-clamp-2 leading-tight`}>{bid.contact_first_name} {bid.contact_last_name}</h5>
                </div>
                <div className="inline-flex bg-blue-200 font-roboto-medium rounded-md text-xs p-0.5 px-1 mb-2">
                    {bid.cge_representative}
                </div>
                <div className="my-1">
                    <div className="flex items-center space-x-2">
                        <p className="font-roboto-bold text-xs text-gray-500">{bid.event_type ? bid.event_type : "No Event Type"}</p>
                        <div className="h-1 w-1 rounded-full bg-gray-400" />
                        <p className="text-xs font-roboto-medium text-gray-400">{bid.cge_type}</p>
                        <p className="text-xs font-roboto-medium text-gray-700">{bid.responses ? bid.responses.length : 0} Responses</p>
                    </div>
                </div>


                <div className="flex justify-between items-end ">
                    <div>
                        <h6 className="text-sm font-productsans-bold text-green-600">
                            ${pricetoString(calculateBudget(bid.budget.total, 1))}
                        </h6>
                        <p className="font-productsans-medium leading-none text-gray-600 text-tiny uppercase">{bid.currency}</p>
                    </div>

                    <div className="flex flex-col andie-description-text justify-between items-end font-productsans-medium">
                        {moment(new Date(bid.event_date_time)).format("ll")}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default AdminBidsItem;
