import React from "react";

export default function BuffetIcon({ height }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            fill="#000"
            version="1.1"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <g>
                <path d="M492.308 321.641h-22.176c-13.19-86.412-77.63-156.185-161.224-177.249a58.673 58.673 0 006.169-26.238c0-32.575-26.501-59.077-59.077-59.077-32.576 0-59.077 26.502-59.077 59.077a58.686 58.686 0 006.168 26.238c-83.594 21.064-148.034 90.837-161.222 177.249H19.692C8.817 321.641 0 330.457 0 341.333c0 10.874 8.817 19.692 19.692 19.692h472.614c10.875 0 19.692-8.818 19.692-19.692.002-10.876-8.815-19.692-19.69-19.692zM357.2 290.366a19.627 19.627 0 01-13.127 5.019 19.641 19.641 0 01-14.68-6.56C310.724 267.964 283.972 256 256 256c-10.875 0-19.692-8.818-19.692-19.692 0-10.877 8.817-19.692 19.692-19.692 39.161 0 76.61 16.746 102.741 45.943 7.252 8.104 6.563 20.553-1.541 27.807zM452.923 400.41H39.385v32.82c0 10.875 8.817 19.692 19.692 19.692h393.846c10.875 0 19.692-8.817 19.692-19.692v-32.82h-19.692z"></path>
            </g>
        </svg>
    )
}