import { ChatIcon } from "@heroicons/react/outline";
import { PopoutModal } from "../../common";
import ChatRender from "../../messages/ChatRender";
import { BiddingAPIs } from "../../../apis";
import { useEffect, useState } from "react";

export default function AdminChatRender({ item }) {
    const [conversations, setConversations] = useState([])
    useEffect(() => {
        loadData()
    }, []);

    async function loadData() {
        const res = await BiddingAPIs.fetchMessages(item?.id)
        setConversations([res])
    }
    return (
        <PopoutModal width={25} modalID={"admin_chat"} title={"Chat"} button={
            <div className="cursor-pointer h-8 w-16 px-3 bg-blue-100 rounded flex items-center justify-center">
                <ChatIcon className="h-5 text-blue-600" />
            </div>
        }>
            {conversations.length > 0 ?
                <ChatRender setConversations={(e) => setConversations(e)} conversations={conversations} selectedConv={0} />
                :
                <p>Loading...</p>
            }
        </PopoutModal>
    )
}
