import { CheckIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { pricetoString } from '../../common';
export default function AdminBidsBidBar({ item, handleItemEdit }) {
    const updateValues = (valuename, value) => {
        handleItemEdit(valuename, value)
    }

    return (
        // I LEFT THIS HERE JUST IN CASE WE WANNA BE ABLE TO EDIT RESPONSES FROM ADMIN SIDE LATER --uzair
        // <form className="grid grid-cols-3 gap-4 px-4 pb-1">
        //     <div className="col-span-1">
        //         <label className="input-group">
        //             <span>Name</span>
        //             <input type="text" onChange={(e) => updateValues('name', e.target.value)} defaultValue={item?.name} className="bid-form-input w-full disabled:bg-gray-200" />
        //         </label>
        //     </div>
        //     <div className="col-span-1">
        //         <label className="input-group">
        //             <span>Price</span>
        //             <input type="text" onChange={(e) => updateValues('price', e.target.value)} defaultValue={item?.price} className="bid-form-input w-full disabled:bg-gray-200" />
        //         </label>
        //     </div>
        //     <div className="col-span-1">
        //         <label className="input-group">
        //             <span>Quantity</span>
        //             <input type="text" onChange={(e) => updateValues('quantity', e.target.value)} defaultValue={item?.quantity} className="bid-form-input w-full disabled:bg-gray-200" />
        //         </label>
        //     </div>
        // </form>

        <div className="mt-2 w-full px-5" >
            <div className="flex justify-between w-full pb-1 border-b border-gray-200 last:border-0">
                <div className="flex flex-col andie-description-text w-4/5">
                    <h6 className="text-gray-700 font-roboto-medium text-paragraph">{item.name}</h6>
                    <p className="andie-description-text text-gray-500 leading-tight line-clamp-2">{item.description}</p>
                    <p className="text-xs font-roboto-medium text-gray-500">{item.menuoption}</p>
                    <div className="w-3/4">
                        <div className="space-x-2 max-w-4 flex">
                            {item.dietary_option?.filter((i) => (i != "None")).map(e =>
                                <div className="flex space-x-1.5 text-xs font-roboto-regular text-gray-500"><CheckIcon className="text-green-500 h-4" />{e}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex w-1/5 justify-end">
                    <div className="flex flex-col text-right justify-between w-full">
                        <p className="text-gray-700 font-roboto-medium text-paragraph">${pricetoString(item.price)}</p>
                        <p className=" andie-description-text justify-center leading-tight">Qty: {item.quantity}</p>
                        <p className=" andie-description-text justify-center leading-tight">{item.serves == null ? null : "Serves: " + item.serves}</p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}