import React from "react";

export default function LinkedInIcon({ height, color }) {
    return (
        <svg height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill={color} d="M0 0H24V24H0z"></path>
                <path d="M19 3a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h14m-.5 15.5v-5.3a3.26 3.26 0 00-3.26-3.26 2.9 2.9 0 00-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93a1.4 1.4 0 112.79 0v4.93h2.79M6.88 8.56a1.68 1.68 0 001.68-1.68 1.69 1.69 0 10-3.37 0 1.69 1.69 0 001.69 1.68m1.39 9.94v-8.37H5.5v8.37z"></path>
            </g>
        </svg>
    )
}