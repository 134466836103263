import { useState } from "react";

export default function ResponseFormDelivery({ bid, businessDelivers, setBusinessDelivers, deliveryFee, setDeliveryFee, type, cgeType, totalPrice, setTotalPrice, pricingType, setPricingType }) {
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user_details')));

    function handleDeliveryChecked(val) {
        val === 'true' ? setBusinessDelivers(true) : setBusinessDelivers(false)
    }
    function handleLumpsumChecked(val) {
        setPricingType(val)
    }
    return (
        type == "Template" ?
            <>
                {(cgeType == "Catering" || cgeType == "Catering Plus") &&
                    <div className="flex border-0 w-full justify-between h-10 rounded-sm flex-wrap">
                        <div className="flex flex-row gap-x-2">
                            <input type="checkbox" checked={businessDelivers} onChange={() => setBusinessDelivers(!businessDelivers)} className="checkbox self-center bg-indigo-50" />
                            <p className="text-xs self-center text-gray-500 font-productsans-regular">
                                Does restaurant deliver
                            </p>
                        </div>
                        {businessDelivers &&
                            <span className="flex flex-row">
                                <p className="mt-2">$ </p>
                                <input
                                    className="bid-form-input"
                                    min={0}
                                    onWheel={(e) => e.target.blur()}
                                    id="delivery_fee"
                                    type="number"
                                    value={deliveryFee}
                                    placeholder="Delivery Fee"
                                    required
                                    aria-label="Delivery Fee"
                                    onChange={(e) => setDeliveryFee(parseFloat(e.target.value))}
                                    defaultValue={0}
                                />
                            </span>}
                    </div>
                }
            </>
            :
            <div className="">
                <h6 className="bid-form-input-label">Additional Options</h6>

                {(bid.cge_type == "Catering" || bid.cge_type == "Catering Plus") &&
                    <div className="flex flex-col w-full justify-between py-2 px-4 bg-gray-100 rounded-lg shadow-sm mb-2">
                        <div className="w-full flex flex-row justify-between">
                            <p className="andie-description-text self-center ">
                                Will you deliver this order?
                            </p>
                            <select onChange={(e) => handleDeliveryChecked(e.target.value)} class="select select-sm h-full bid-form-input w-1/3 rounded-lg">
                                <option value={true} selected={businessDelivers == true}>Yes</option>
                                <option value={false} selected={businessDelivers == false}>No</option>
                            </select>
                        </div>
                        {businessDelivers &&
                            <div className="w-full flex flex-row justify-between">
                                <p className="andie-description-text self-center">
                                    Delivery Fee
                                </p>
                                <div className="flex flex-row align-middle w-1/3 -mr-3">
                                    <span className="self-center font-productsans-regular text-base -mr-5 z-10">$</span>
                                    <input
                                        className="h-full bid-form-input w-full pl-10 rounded-lg"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                        id="delivery_fee"
                                        type="number"
                                        value={deliveryFee}
                                        placeholder="Delivery Fee"
                                        required
                                        aria-label="Delivery Fee"
                                        onChange={(e) => setDeliveryFee(parseFloat(e.target.value))}
                                        defaultValue={0}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className="flex flex-col w-full justify-between py-2 px-4 bg-gray-100 rounded-lg shadow-sm mb-2">
                        <div className="w-full flex flex-row justify-between">
                            <p className="andie-description-text self-center ">
                                Pricing Type
                            </p>
                            <select disabled={user.email !== "cgequotes@andie.work"} onChange={(e) => handleLumpsumChecked(e.target.value)} class="select select-sm h-full bid-form-input w-1/3 rounded-lg">
                                <option value={"item"} selected={pricingType == "item"}>Per Item</option>
                                <option value={"total"} selected={pricingType == "total"}>Total</option>
                            </select>
                        </div>
                        {pricingType == "total" &&
                            <div className="w-full flex flex-row justify-between">
                                <p className="andie-description-text self-center">
                                    Subtotal
                                </p>
                                <div className="flex flex-row align-middle w-1/3 -mr-3">
                                    <span className="self-center font-productsans-regular text-base -mr-5 z-10">$</span>
                                    <input
                                        className="h-full bid-form-input w-full pl-10 rounded-lg"
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                        id="subtotal"
                                        type="number"
                                        value={totalPrice.toString() === "0" ? "" : totalPrice}
                                        placeholder="Subtotal"
                                        required
                                        aria-label="Subtotal"
                                        onChange={(e) => {e.target.value === "" ? setTotalPrice(0) : setTotalPrice(parseFloat(e.target.value))}}
                                        defaultValue={0}
                                    />
                                </div>
                            </div>
                        }
                    </div>
            </div>
    )
}