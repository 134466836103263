import React from "react";

export default function AnyPackagingIcon({ height }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            fill="#000"
            version="1.1"
            viewBox="0 0 32 32"
            xmlSpace="preserve"
        >
        <path d="M23 8h-1V4.6c0-.7-.3-1.3-.9-1.7-.6-.4-1.3-.4-1.9-.1L7.6 8.1c-.1 0-.1.1-.2.1s-.1.1-.1.1c-.2.2-.3.4-.3.7v19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-3 10.5c0 1.9-.5 3.4-1.4 4.3-.7.8-1 1.8-.9 2.8L18 28h-4l.3-2.4c.1-1-.2-2-.9-2.8-.9-1-1.4-2.5-1.4-4.3V13c0-.6.4-1 1-1s1 .4 1 1v6h1v-6c0-.6.4-1 1-1s1 .4 1 1v6h1v-6c0-.6.4-1 1-1s1 .4 1 1v5.5zM20 8h-7.4L20 4.6V8z"></path>
        </svg>
    )
}