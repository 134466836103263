import React, { useEffect } from 'react'
import { useState } from 'react'
import { PackageItemCreator, PackageItemView } from '..';
import { PopoutModal, foodOptions, menuoptions, calculatePackageTotaPrice, calculateDeliveryFee, displayPackageDeliverFee } from '../../common';
import Select from 'react-select';
import { random } from 'lodash';

const PackageForm = ({
    packageTitle, setPackageTitle,
    restaurantTypes, setRestaurantTypes,
    serves, setServes,
    minGuests, setMinGuests,
    packageItems, setPackageItems,
    selectedMenuOption, setSelectedMenuOption,
    pricingType, setPricingType,
    totalPrice, setTotalPrice,
}) => {
    const [refreshItemList, setRefreshItemList] = useState(0)
    const [editingID, setEditingID] = useState(-1)
    const [showPackageItemCreator, setShowPackageItemCreator] = useState(false);

    const handleShowPackageItemCreator = (menuOption) => {
        if (menuOption === "") {
            setSelectedMenuOption('')
            setShowPackageItemCreator(false)
        } else {
            setSelectedMenuOption(menuOption)
            setShowPackageItemCreator(true)
        }
    }

    useEffect(() => {
        if (editingID !== -1){
            for (const option of menuoptions) {
                const temp = packageItems[option.value].find(item => item.id === editingID)
                if (temp != null) setSelectedMenuOption(temp.menuOption)
            }
            setShowPackageItemCreator(true)
        };
    }, [editingID]);

    return (
        <div className='andie-description-text pb-10'>
            <div className="col-span-2 align-middle items-center">
                <p class="bid-form-input-label">Package Title </p>
                <input value={packageTitle} onChange={(e) => setPackageTitle(e.target.value)} type="text" placeholder="E.g Executive Meeting, Team Meeting etc." class="bid-form-input h-10 w-full" />
            </div>
            <div className="grid grid-cols-3 gap-4">
                <div>
                    <p class="bid-form-input-label mt-3">Cuisine Type </p>
                    <Select
                        defaultValue={foodOptions.filter(option => restaurantTypes.includes(option.value))}
                        isMulti
                        options={foodOptions}
                        className="w-full"
                        classNamePrefix=""
                        onChange={(e) => setRestaurantTypes(e.map(obj => obj.value))}
                    />
                </div>
                <div>
                    <p class="bid-form-input-label whitespace-nowrap mt-3 w-1/5">Serves </p>
                    <input value={serves} onChange={(e) => setServes(e.target.value)} type="text" placeholder="Serves" class="bid-form-input h-10 w-full" />
                </div>
                <div class="align-middle col-span-1">
                    <p class="bid-form-input-label whitespace-nowrap mt-3 w-1/5">Min. Quantity</p>
                    <input value={minGuests} onChange={(e) => setMinGuests(e.target.value)} type="text" placeholder="Guests" class="bid-form-input h-10 w-full" />
                </div>
            </div>
            <div className="my-4 pt-4 flex space-x-4 border-t border-b">
                <div className="w-3/5">
                    <p class="bid-form-input-label whitespace-nowrap mt-3 w-1/5">Menu items </p>
                    <div className='flex h-[540px] overflow-y-scroll flex-col divide-y divide-gray-100 pr-5'>
                        {menuoptions.map((option) => (
                            <div className='w-full py-3'>
                                <div className='flex flex-row justify-between w-full '>
                                    <p class="bid-form-input-label text-xs uppercase">{option.title}</p>
                                    <div className={`cursor-pointer text-paragraph font-productsans-medium andie-text-button pb-1`} onClick={() => handleShowPackageItemCreator(option.value)}>Add Item</div>
                                </div>
                                <PackageItemView
                                    packageItems={packageItems}
                                    setPackageItems={setPackageItems}
                                    menuOption={option.value}
                                    refreshItemList={refreshItemList}
                                    setRefreshItemList={setRefreshItemList}
                                    setEditingID={setEditingID}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="w-2/5">
                    {(showPackageItemCreator) ?
                        <PackageItemCreator
                            packageItems={packageItems}
                            setPackageItems={setPackageItems}
                            menuOptions={menuoptions}
                            selectedMenuOption={selectedMenuOption}
                            handleShowPackageItemCreator={handleShowPackageItemCreator}
                            editingID={editingID}
                            setEditingID={setEditingID}
                            setRefreshItemList={setRefreshItemList}
                        />
                        :
                        <div className="h-[540px] w-full bg-blue-50 rounded-md" />
                    }
                </div>
            </div>
            

            <div className='col-span-2 flex flex-col p-4 gap-y-4 bg-white border-2 border-gray-100 rounded-md shadow-lg'>
                <div className="flex justify-start gap-x-4">
                    <div className='w-full flex flex-col'>
                        <label onClick={() => { setPricingType('item') }} className="label cursor-pointer justify-start">
                            <input type="radio" name="radio-1" className="radio radio-sm" checked={pricingType == "item" ? true : false} />
                            <span className="bid-form-input-label ml-5 mb-0">Priced by item</span>
                        </label>
                        <label onClick={() => setPricingType('package')} className="label cursor-pointer justify-start ">
                            <input type="radio" name="radio-1" className="radio radio-sm" checked={pricingType == "package" ? true : false} />
                            <span className="bid-form-input-label ml-5 mb-0">Priced by package</span>
                        </label>
                    </div>
                    {pricingType === "package" ? (
                        <div class="align-middle w-3/5">
                            <p class="bid-form-input-label whitespace-nowrap">Subtotal</p>
                            <input value={totalPrice} onChange={(e) => setTotalPrice(e.target.value)} type="number" placeholder="Subtotal" class="bid-form-input h-10 w-full" />
                        </div>
                    ) : (
                        <div class="align-middle w-3/5">
                            <p class="bid-form-input-label whitespace-nowrap">Subtotal</p>
                            <input disabled value={(calculatePackageTotaPrice(packageItems, pricingType, totalPrice))} type="text" class="bid-form-input h-10 w-full disabled" />
                        </div>
                    )
                    }
                </div>

                <div className="flex gap-x-2 justify-center align-middle">
                    <p class="bid-form-input-label mb-0">Delivery Fee</p>
                    <input disabled value={displayPackageDeliverFee(packageItems, pricingType, totalPrice)} class="bid-form-input h-10 w-full" />
                </div>


            </div>
        </div>
    )
}

export default PackageForm