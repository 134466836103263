import React from "react";

export default function CalculatorIcon({ height, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" height={height}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h32v32H0z"></path>
            <path
            fill={color}
            d="M24.5 0a4 4 0 014 4v24a4 4 0 01-4 4h-17a4 4 0 01-4-4V4a4 4 0 014-4zM10 24a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-12-6a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-12-6a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm1.5-9h-15a2 2 0 00-2 2v2a2 2 0 002 2h15a2 2 0 002-2V5a2 2 0 00-2-2z"
            ></path>
        </g>
    </svg>
  );
};