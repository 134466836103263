import React, { useState, useEffect } from "react";
import { SidebarRight, SidebarTemplates } from "../../components/navigation";
import { ExploreBids, ExploreHome, ExploreResponses, ExploreTemplates } from ".";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { HiOutlineAdjustments } from 'react-icons/hi'

import { isAuthenticated } from '../../apis/Credentials';
import { StripeReminder } from "../../components/billing";
import { StripeAPI, BiddingAPIs, AccountsAPI } from "../../apis";

import { Drawer, PopoutModal, SpeedDial, filterSearchBids, filterSearchResponses, filterSearchTemplates, selectRandomItemsFromArray } from '../../components/common';
import { CreateBidButton } from "../../components/bid/create";
import { WebSocket } from "../../components/sockets";
import { HomeIcon } from "@heroicons/react/solid";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

export default function Home() {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [selectedTab, setSelectedTab] = useState("Home");
    const [authenticated, setAuthenticated] = useState(null);
    const [stripeLink, setStripeLink] = useState(false);
    const navigate = useNavigate();
    // const location = useLocation();

    const [globalFilters, setGlobalFilters] = useState({
        discover: null,
        guests: null,
        budget: null,
        event: null,
        cuisines: [],
        restaurant_level: null,
        happening_soon: null,
        popular: null
    });

    const [stripeModal, setStripeModal] = useState(false);
    const [bids, setBids] = useState([]);
    const [responses, setResponses] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [loadingTemplates, setLoadingTemplates] = useState(true);
    const [loadingBids, setLoadingBids] = useState(true);
    const [viewVideoModal, setViewVideoModal] = useState(false);

    const [searchData, setSearchData] = useState({});

    let id = useParams();

    const tabs = [
        "Home",
        "Bids",
        "Responses",
        "Collections"
    ]

    // Function to load data from window storage
  const loadDataFromStorage = () => {
    // Load input value from storage
    const storedInputValue = localStorage.getItem('inputValue');
    const storedSelectValue = localStorage.getItem('selectValue');
    let terms = [];

    if (storedInputValue) {
        const original_terms = storedInputValue.replace(/[^\w\s]/g, '').split(/\s+/);
        
        if (original_terms.includes(storedInputValue) === false) { 
            terms = [storedInputValue, ...original_terms];
        } else {
            terms = original_terms;
        }
    }

    setSearchData({ input: storedInputValue || '', category: storedSelectValue || '', terms: terms  });
    navToTab(storedSelectValue);
  };

  // Update state with values from local storage on component mount and when storage changes
  useEffect(() => {
    if (searchData.input) {
        loadDataFromStorage();
    }
  }, []); // Run on mount

  // Listen for changes in specific keys in local storage and update state accordingly
  useEffect(() => {
    const handleStorageChange = () => {
        loadDataFromStorage();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); // Run when searchData changes

    useEffect(async () => {
        if (isAuthenticated()) {
            setAuthenticated(true)
            const link = await StripeAPI.generateAPILink(user.email);
            if (user.account_type == 2) {
                const link = await StripeAPI.generateAPILink(user.email);
                if (link != false) {
                    setStripeLink(link)
                    setStripeModal(true)
                } else {
                    await AccountsAPI.VerifyUserOnLogin()
                }
            }
        }
    }, []);

    useEffect(() => {
        var something = id["*"].split('/');
        const navigatedTab = something[0].charAt(0).toUpperCase() + something[0].slice(1);

        if (something[0] === 'ref'){
            AccountsAPI.catchURLLead(something[1])
        }

        if (something.length < 1) {
            navigate('/home')
            something = ['Home']
        }

        else if (!tabs.includes(navigatedTab)) {
            navigate('/home')
        }

        setSelectedTab(navigatedTab);

        // Refresh info triggers this to update for navigated tab
        handleFilterData(selectedTab);
    }, [id, refreshInfo]);

    function handleFilterData(tab) {
        if (tab === "Collections") {
            // setFilteredData(templates);
            setFilteredData(searchData.category === "Collections" && searchData.input ? filterSearchTemplates(templates, searchData.terms) : templates);
        }

        if (tab === "Bids") {
            // setFilteredData(bids);
            setFilteredData(searchData.category === "Bids" && searchData.input ? filterSearchBids(bids, searchData.terms) : bids);
        }

        if (tab === "Responses") {
            const flattenedData = bids.flatMap(bid => {
                return bid.responses.map(response => ({ guest_count: bid.guest_count, ...response }));
            });

            // setFilteredData(flattenedData);
            setFilteredData(searchData.category === "Responses" && searchData.input ? filterSearchResponses(flattenedData, searchData.terms) : flattenedData);
        }
    }

    const onCreateBid = () => {
        const item = {
            "cge_email": "",
            "template_title": "",
            "template_description": "",
            "cge_type": "Catering",
            "event_type": "Office breakfast",
            "packages": [

            ],
            "max_guests": 9999999,
            "min_price": 1,
            "max_price": 999999999,
            "cuisine_type": [

            ],
            "images": [
            ]
        }
        navigate('/cge/create-a-bid', { state: { item } });
    }

    const menuOptions = [
        { component: <CreateBidButton func={onCreateBid} /> }
    ]

    const getBids = async () => {
        var result = await BiddingAPIs.getBids();
        result = result.filter(item => (
            (!item.event_type.includes("Breakfast")) &&
            (!((item.expired == true) && (item.cge_type !== "Catering Plus")))
            && (new Date() < new Date(item.expiry_date)))
            && ((item.recurring ? isExpiryWithin14days(item.expiry_date) : true))
        )
        
        setBids(result);

        const flattenedData = result.flatMap(bid => {
            return bid.responses.map(response => ({ bid: bid, guest_count: bid.guest_count, ...response }));
        });
        setResponses(flattenedData);

        setLoadingBids(false);
    }

    async function getTemplates() {
        const res = await BiddingAPIs.getCollections();
        setTemplates(res.data);
        setLoadingTemplates(false);
    }

    function refreshInfo() {
        getBids();
        getTemplates();
    }

    function isExpiryWithin14days(date) {
        var diff = new Date(date) - new Date()
        var convertedToDays = diff / (1000 * 60 * 60 * 24)

        return (convertedToDays < 14)
    }

    function navToTab(tab) {
        setSelectedTab(tab);  
        handleFilterData(tab);

        navigate(`/${tab.toLowerCase()}`)

        // seedData()
    }
    
    return (
        <>
            <PopoutModal
                title={<p> Complete Stripe Signup <span> <div class="badge badge-error">URGENT!</div> </span> </p>}
                modalID={'stripe_signup'}
                trigger={stripeModal}
            >
                <StripeReminder />
            </PopoutModal>

            {/* <div className="xl:hidden h-screen flex items-center justify-center">
                <div className="mx-16 text-center mb-16">
                    <svg viewBox="0 -2 24 24" className="h-40 mx-auto" id="meteor-icon-kit__solid-desktop" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 0H22C23.1046 0 24 0.89543 24 2V15C24 16.1046 23.1046 17 22 17H2C0.89543 17 0 16.1046 0 15V2C0 0.89543 0.89543 0 2 0zM16 18C16.5523 18 17 18.4477 17 19C17 19.5523 16.5523 20 16 20H8C7.44772 20 7 19.5523 7 19C7 18.4477 7.44772 18 8 18H16z" fill="#758CA3"></path></g></svg>
                
                    <h6 className="font-productsans-medium text-blue-600 text-lg mt-5">Please use a desktop device!</h6>
                    <p className="andie-description-text text-gray-400 leading-tight">Starting May 1st, 2024, Pluck will be available for access on your mobile browser.</p>
                </div>
            </div> */}

            <div>
                {/* <Header bypassToken={true} refreshFunction={() => refreshInfo()} page="home" /> */}
                <WebSocket refreshFunction={() => refreshInfo()} page="home" />
                <ModalVideo
                    autoplay
                    channel="custom"
                    isOpen={viewVideoModal}
                    url="https://plk24and18xrb21x0.s3.ca-central-1.amazonaws.com/meet-pluck-introductory-video.mp4"
                    onClose={() => setViewVideoModal(false)}
                    className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    style={{
                        zIndex: 1000, // Ensure it's above other content
                        width: "50%", // Adjust width as needed
                        maxWidth: "600px", // Limit maximum width
                        maxHeight: "80%", // Limit maximum height
                        overflow: "auto" // Enable scrolling if content exceeds modal size
                    }}
                />
                <div className="flex h-[calc(100lvh_-_40px)] w-full">
                    <div className="andie-side-panel">
                        <SidebarTemplates large={true} globalFilters={globalFilters} setGlobalFilters={setGlobalFilters} filteredData={filteredData} setFilteredData={setFilteredData} showMenu={showMenu} setShowMenu={setShowMenu} />
                    </div>
                    <div className="andie-main-panel relative">
                        <div className={`flex items-center justify-between mb-1 ${(user === null || user.account_type === 1) && "-mt-2"}`}>
                            <div className="flex w-full justify-between">
                                <div className="w-4/5 md:w-1/2 lg:w-2/5 h-8 p-1 bg-gray-100 rounded-md flex">
                                    <div onClick={() => navToTab("Home")} className={`${selectedTab.toLowerCase() === "home" && "bg-white text-blue-600"} flex cursor-pointer rounded-md px-2.5 items-center justify-center`}>
                                        <HomeIcon className={`h-5 md:h-[18px] text-black ${selectedTab.toLowerCase() === "home" && "text-blue-700"}`} />
                                    </div>
                                    <div className="grid grid-cols-3 w-full">
                                        {tabs.map((tab, index) => (
                                            <>
                                            {tab !== "Home" && 
                                                <div key={index} onClick={() => navToTab(tab)} className={`${selectedTab.toLowerCase() === tab.toLocaleLowerCase() && "bg-white text-blue-600"} text-gray-600 cursor-pointer rounded-md px-2.5 flex font-productsans-medium text-paragraph md:text-xs xl:text-sidebar items-center justify-center`}>
                                                    {tab}
                                                </div>
                                            }
                                            </>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex items-center justify-end lg:hidden">
                                    <Drawer
                                        drawerID={'global_filters'}
                                        button={<HiOutlineAdjustments className="transform rotate-90" size={20} />}
                                        title={'Filters'}
                                        width={95}
                                    >
                                        <SidebarTemplates globalFilters={globalFilters} setGlobalFilters={setGlobalFilters} filteredData={filteredData} setFilteredData={setFilteredData} showMenu={showMenu} setShowMenu={setShowMenu} />
                                    </Drawer>

                                </div>
                            </div>
                            <div>
                                {((user === null) || (user !== null && user.account_type === 1)) ?
                                    <>
                                        <div className="hidden lg:inline-flex w-full justify-end my-2">
                                            <CreateBidButton func={onCreateBid} />
                                        </div>
                                        <div className="lg:hidden inline-flex">
                                            <SpeedDial menuOptions={menuOptions} />
                                        </div>
                                    </>
                                    : <></>
                                }
                            </div>
                        </div>
                        {selectedTab.toLowerCase() === "home" && <ExploreHome setViewVideoModal={setViewVideoModal} bids={bids} templates={templates} loadingTemplates={loadingTemplates} responses={responses} />}
                        {selectedTab.toLowerCase() === "collections" && <ExploreTemplates globalFilters={globalFilters} setFilteredData={setFilteredData} loading={loadingTemplates} templates={searchData.category === "Collections" ? filteredData : templates } />}
                        {selectedTab.toLowerCase() === "bids" && <ExploreBids loading={loadingBids} globalFilters={globalFilters} bids={searchData.category === "Bids" ? filteredData : bids} setBids={(e) => setBids(e)} setFilteredData={setFilteredData} />}
                        {selectedTab.toLowerCase() === "responses" && <ExploreResponses globalFilters={globalFilters} incomingResponses={searchData.category === "Responses" ? filteredData : responses } loading={loadingBids} getBids={(e) => getBids(e)} setFilteredData={setFilteredData} />}
                        
                    </div>
                    <SidebarRight home />
                    
                </div>
            </div>
        </>
    )
}