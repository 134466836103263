
import moment from 'moment';
import React, { useEffect, useState } from 'react';

export default function CountdownTimer({ bid, datetime, page }) {
    const [days, setdays] = useState(0);
    const [hours, sethours] = useState(0);
    const [mins, setmins] = useState(0);
    const [seconds, setseconds] = useState(0);

    function getCountdownDate(date) {
        const eventDate = moment(new Date(date))
        const currDate = moment(new Date())
        var duration = moment.duration(eventDate.diff(currDate))._data
        setdays(duration.days)
        sethours(duration.hours)
        setmins(duration.minutes)
        setseconds(duration.seconds)

    }

    useEffect(() => {
        const interval = setInterval(() => {
            getCountdownDate(datetime)
        }, 999);
        return () => clearInterval(interval);

    }, [bid]);

    return (
        <div className="grid grid-flow-col gap-3 text-center auto-cols-max">
            {page == "SelectedBid" ?
                <>
                    <div className="flex flex-col items-center justify-center h-9 w-9 bg-neutral rounded-sm font-roboto-medium leading-none text-xs text-gray-500">
                        <span className="countdown mt-0.5 font-productsans-bold leading-none text-pargraph text-gray-200">
                            <span style={{ "--value": days }}></span>
                        </span>
                        days
                    </div>
                    <div className="flex flex-col items-center justify-center h-9 w-9 bg-neutral rounded-sm font-roboto-medium leading-none text-xs text-gray-500">
                        <span className="countdown mt-0.5 font-productsans-bold text-paragraph text-gray-200">
                            <span style={{ "--value": hours }}></span>
                        </span>
                        hours
                    </div>
                    <div className="flex flex-col items-center justify-center h-9 w-9 bg-neutral rounded-sm font-roboto-medium leading-none text-xs text-gray-500">
                        <span className="countdown mt-0.5 font-productsans-bold text-paragraph text-gray-200">
                            <span style={{ "--value": mins }}></span>
                        </span>
                        min
                    </div>
                    <div className="flex flex-col items-center justify-center h-9 w-9 bg-neutral rounded-sm font-roboto-medium leading-none text-xs text-gray-500">
                        <span className="countdown mt-0.5 font-productsans-bold text-paragraph text-gray-200">
                            <span style={{ "--value": seconds }}></span>
                        </span>
                        sec
                    </div>
                </>
                :
                <div className="flex gap-x-1 font-roboto-bold text-xs text-gray-600">
                    {days > 0 ?
                        <div>
                            <span className="countdown font-roboto-regular text-xs text-gray-600 mr-0.5">
                                <span style={{ "--value": days }}></span>
                            </span>
                            days
                        </div> : <></>}
                    {hours > 0 ?
                        <div>
                            <span className="countdown font-roboto-regular text-xs text-gray-600 mr-0.5">
                                <span style={{ "--value": hours }}></span>
                            </span>
                            hours
                        </div>
                        : <></>}
                    {mins > 0 ?
                        <div>
                            <span className="countdown font-roboto-regular text-xs text-gray-600 mr-0.5">
                                <span style={{ "--value": mins }}></span>
                            </span>
                            mins
                        </div> : <></>}
                    {seconds > 0 ?
                        <div>
                            <span className="countdown font-roboto-regular text-xs text-gray-600 mr-0.5">
                                <span style={{ "--value": seconds }}></span>
                            </span>
                            secs
                        </div>
                        : <></>}           </div>
            }
        </div>
    )
}