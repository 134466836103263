import React, { useState } from 'react';
import { TutorialIcon } from '../../assets/icons';
import { SidebarRight } from '../../components/navigation';
import { Tutorial, VideoGuides } from '../../components/common';

export default function Tutorials() {
    const [selectedTab, setSelectedTab] = useState("Videos");

    const tabs = ["Videos", "Guides"];

    return (
        <div className="h-screen">
            <div className="flex h-[calc(100%_-_40px)]">
                <div className="andie-side-panel">
                    <div className="flex space-x-3">
                        <TutorialIcon height={26} color="#2563eb" />
                        <h6 className="andie-sidebar-title">Tutorials &amp; Guides</h6>
                    </div>
                </div>
                <div className="andie-main-panel overflow-hidden pb-14">
                    <div className="flex space-x-4 border-b mb-5">
                        {tabs.map((item, index) => (
                            <div onClick={() => setSelectedTab(item)} className={`cursor-pointer font-roboto-medium h-7 text-paragraph ${item === selectedTab ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-500"}`}>
                                {item}
                            </div>
                        ))}
                    </div>
                    {selectedTab === "Guides" && <Tutorial />}
                    {selectedTab === "Videos" && <VideoGuides />}
                </div>
                <SidebarRight />
            </div>
        </div>
    );
};