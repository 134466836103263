import React from "react";

export default function ChoiceAboutInformation() {
    return (
        <div className="andie-description-text">
            <h1 className="font-productsans-medium text-base mb-3">Choices About How We Use and Disclose Your Information</h1>
            <p><strong>&nbsp;</strong></p>
            <p>We strive to provide you with choices regarding the personal information you provide to us. We have created (or provided information below) to provide you with the following control over your personal information:</p>
            <ul class="list-disc ml-10">
                <li>For more information, please visit Network Advertising Initiative&rsquo;s education page: <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work" class="text-blue-600 visited:text-purple-600">http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a></li>
                <li>You can set your browser to refuse all or some browser cookies, or alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe&rsquo;s website. If you disable or refuse cookies, please note that some parts of the Site may not be accessible or may not function properly.</li>
                
                <li>If you no longer want to receive emails or other promotional materials from us, you can opt-out by emailing us stating your request to <a href="mailto:privacyandterms@hellopluck.com" class="text-blue-600 visited:text-purple-600">privacyandterms@hellopluck.com</a>, or by changing your settings by logging into the Site and adjusting your user preferences by checking or unchecking the relevant boxes.</li>
                <li>If you wish to opt-out of Google Analytics: <a href="https://tools.google.com/dlpage/gaiptout" class="text-blue-600 visited:text-purple-600">https://tools.google.com/dlpage/gaiptout</a><span>.</span></li>
                <li>If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers&rsquo; target-audience preferences, you can opt-out by logging into the Site and adjusting your preferences.</li>
            </ul>
            <p><strong>&nbsp;</strong></p>
        </div>
    )
}