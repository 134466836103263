import React, { useRef } from 'react';
import { BadgeCheckIcon, LocationMarkerIcon, StarIcon } from "@heroicons/react/solid";
import { FlagIcon, MailIcon, PrinterIcon } from "@heroicons/react/outline";
import { useNavigate } from 'react-router-dom';

import { SelectedBidFooter } from '.';
import { CGETypeImage, calculateBudget, pricetoString } from '../../common';
import { cge_frontend } from '../../../apis/config';
import { useReactToPrint } from 'react-to-print';

export default function SelectedBid({ selectedBid, user }) {
    const navigate = useNavigate();
    const printComponentRef = useRef();

    function showFullBid() {
        navigate('/bid/' + selectedBid.transaction_code);
    }

    const body = `Please visit this webpage: ${cge_frontend + 'bid/' + selectedBid.transaction_code}`;
    const report_body = `The following bid: ${selectedBid.transaction_code} requires attention.. 
    ${cge_frontend + 'bid/' + selectedBid.transaction_code}`;

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
    });

    const print = () => {
        handlePrint()
    }

    const requirementsdata =  [ 
        { title: "Opportunity", data: selectedBid.cge_type },
        { title: "Headcount", data: `${selectedBid.guest_count} guests` },
        { title: "Preferred Cuisine(s)", data: selectedBid.restaurant_type.join(', ') },
        { title: "Requested Menu Options", data: selectedBid.menu_options.join(', ') },
        { title: "Packaging & presentation", data: selectedBid.packaging_type.map(packaging_type => packaging_type).join(', ') },
        { title: "Dietary requirements", data: selectedBid.dietary_preferences.map(item => item.preference).join(', ') },
    ]

    return (
        <div className="h-[calc(100svh_-_122px)] flex flex-col justify-between">
            <div ref={printComponentRef}>
                <div className="flex pb-5" >
                    <div className="w-3/4 flex items-center space-x-3">
                        <div className="bg-blue-50 h-14 w-14 flex items-center justify-center flex-shrink-0 rounded-md" >
                            <CGETypeImage type={selectedBid?.cge_type} page={"SelectedBid"} />
                        </div>
                        <div>
                            <h5 className="text-base leading-none line-clamp-1 lg:line-clamp-2 font-productsans-bold text-gray-800">{selectedBid.bid_title}</h5>
                            <div className="mt-1 flex items-center space-x-2">
                                <p className="font-roboto-bold text-xs text-gray-600">{selectedBid.event_type}</p>
                                <div className="h-1 w-1 rounded-full bg-gray-400" />
                                <div className="flex items-center">
                                    <p className="text-xs font-roboto-medium text-gray-800">{selectedBid.restaurant_rating}</p>
                                    <StarIcon className="h-4 text-yellow-400" />
                                </div>
                            </div>
                            <div className="mt-1 flex space-x-1 items-center">
                                <BadgeCheckIcon className="h-4 text-green-700" />
                                <p className="mt-0.5 font-roboto-medium leading-none text-xs text-gray-400">Verified</p>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => showFullBid()} className="w-1/4 flex justify-end">
                        <div className="andie-outline-button w-20 border-blue-500 h-8 hover:bg-blue-600 hover:border-0 hover:text-white">View bid</div>
                    </div>
                </div>
                <div className="border-t border-b h-9 flex items-center justify-between mb-5 border-gray-100">
                    <div className="flex items-center">
                        <LocationMarkerIcon className="h-4 text-gray-400 -mt-0.5 mr-1" />
                        <p className="text-xs font-roboto-regular text-gray-500">{selectedBid.locationAdditionalDetails}</p>
                    </div>
                    {user !== null ?
                        <h6 className="text-sm font-productsans-bold text-green-600">${pricetoString(calculateBudget(selectedBid.budget.total, selectedBid.restaurant_coefficient))} {selectedBid.currency ? selectedBid.currency : 'CAD'}</h6>
                        : <></>
                    }
                </div>

                <div className="flex flex-row-reverse">
                    <div className="w-1/4 flex flex-col space-y-4 items-end">
                        <div onClick={() => window.open(`mailto:?subject=Pluck Bid: ${selectedBid.transaction_code}&body=${body}`)} className="andie-circle-button">
                            <MailIcon className="h-4 text-blue-600" />
                        </div>
                        <div onClick={print} className="andie-circle-button">
                            <PrinterIcon className="h-4 text-blue-600" />
                        </div>
                        <div onClick={() => window.open(`mailto:engineering@andie.work?subject=Report on bid: ${selectedBid.transaction_code}&body=${report_body}`)} className="andie-circle-button">
                            <FlagIcon className="h-4 text-blue-600" />
                        </div>
                    </div>
                    <div className="w-3/4">
                        <p className="andie-description-text line-clamp-5 xl:line-clamp-3 h-16">{selectedBid.bid_description}</p>
                        <p className="mt-5 mb-4 andie-bg-section-descriptor uppercase text-gray-400">Overview</p>
                        <div className="space-y-3">
                            {requirementsdata.map((requirement, index) => (
                                <div className="flex space-x-1.5">
                                    <div className="flex space-x-2 flex-shrink-0">
                                        <div className="mt-1.5 bg-gray-500 h-2 w-2 rounded-full" />
                                        <p className="andie-bg-section-descriptor text-gray-500">{requirement.title}:</p>
                                    </div>
                                    {requirement.title === "Opportunity" ? 
                                        <div className="border-2 border-gray-600 text-gray-700 inline-flex items-center justify-center h-6 px-3 text-xs font-roboto-medium rounded-full">{requirement.data}</div>
                                    :
                                    <p className="andie-description-text text-gray-500 capitalize line-clamp-1">{requirement.data}</p> 
                                    }   
                                </div>
                            ))}
                        </div>   
                    </div>
                </div>
            </div>
            <SelectedBidFooter selectedBid={selectedBid} datetime={selectedBid.expiry_date} numresponses={selectedBid.responses.length} page={"SelectedBid"} />
        </div>
    )
}