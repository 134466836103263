import React from "react";

export default function ChafingDishIcon({ height, color }) {
    return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          height={height}
          fill={color}
          version='1.1'
          viewBox='0 0 512 512'
          xmlSpace='preserve'
        >
          <g>
            <path d='M429.866 164.986c-13.357-35.969-48.11-60.135-86.478-60.135h-62.596a28.005 28.005 0 003.337-13.293c0-15.535-12.593-28.128-28.128-28.128s-28.128 12.593-28.128 28.128a27.99 27.99 0 003.337 13.293h-62.596c-38.369 0-73.121 24.166-86.478 60.134l-9.471 25.502h366.673l-9.472-25.501zM457.936 258.453h-18.741l3.586-34.574H69.219l3.586 34.574H54.064C24.253 258.453 0 282.706 0 312.518s24.253 54.065 54.064 54.065h29.958l8.505 81.988h326.948l8.505-81.988h29.958c29.811 0 54.064-24.253 54.064-54.065s-24.255-54.065-54.066-54.065zM54.064 333.19v.001c-11.399 0-20.673-9.274-20.673-20.674s9.274-20.674 20.673-20.674h22.205l4.289 41.346H54.064zm403.872.001h-26.494l4.289-41.346h22.205c11.399 0 20.673 9.274 20.673 20.674s-9.274 20.672-20.673 20.672z'></path>
          </g>
        </svg>
      );
}