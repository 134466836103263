import { useEffect, useState } from "react";
import { menuoptions } from "../../common";


export default function ItemInput({ setPackages, packages, pkgIndex }) {
    const [currentItem, setCurrentItem] = useState("");
    const [currentPrice, setCurrentPrice] = useState("");
    const [currentQuantity, setCurrentQuantity] = useState("");
    const [Desciption, setDescription] = useState("");
    const [currentMenuOption, setCurrentMenuOption] = useState("Appetizer and Shareables");

    function addItem(currentItem, currentPrice, currentQuantity, Desciption, currentMenuOption) {
        const item = {
            name: currentItem,
            menuoption: currentMenuOption,
            quantity: parseInt(currentQuantity),
            price: parseFloat(currentPrice),
            description : Desciption
        }
        packages[pkgIndex].push(item)
        // temp.push(item)
        setPackages([...[...packages]]);
        setCurrentItem("");
        setCurrentPrice("");
        setCurrentQuantity("");
        setDescription("");
        setCurrentMenuOption("");
    }

    return (
        <>
            <div className="mt-8 flex my-1.5">
                <input
                    className="w-[45%] px-0 h-7 focus:ring-0 focus:border-l-0 focus:border-t-0 focus:border-r-0 focus:border-b-2 focus:border-blue-600 border-t-0 border-x-0 border-gray-100 border-b-2 my-1 font-sf-regular text-xs text-gray-500"
                    id="item_name"
                    type="text"
                    placeholder="Item name"
                    aria-label="Item Name"
                    onChange={(e) => setCurrentItem(e.target.value)}
                    value={currentItem}
                />
                <input
                    className="w-[25%] px-0 h-7 focus:ring-0 focus:border-l-0 focus:border-t-0 focus:border-r-0 focus:border-b-2 focus:border-blue-600 border-t-0 border-x-0 border-gray-100 border-b-2 my-1 font-sf-regular text-xs text-gray-500"
                    id="quantity"
                     type="number" onWheel={(e) => e.target.blur()} 
                    placeholder="Quantity"
                    aria-label="Quantity"
                    onChange={(e) => setCurrentQuantity(e.target.value)}
                    value={currentQuantity}
                />
                <input
                    className="w-[30%] px-0 h-7 focus:ring-0 focus:border-l-0 focus:border-t-0 focus:border-r-0 focus:border-b-2 focus:border-blue-600 border-t-0 border-x-0 border-gray-100 border-b-2 my-1 font-sf-regular text-xs text-gray-500"
                    id="price"
                    type="text"
                    placeholder="Price"
                    aria-label="Price"
                    onChange={(e) => setCurrentPrice(e.target.value)}
                    value={currentPrice}
                />
            </div>
            <div className="mt-2 flex my-2.5">
                <input
                        className="w-full px-0 h-7 focus:ring-0 focus:border-l-0 focus:border-t-0 focus:border-r-0 focus:border-b-4 focus:border-blue-600 border-t-0 border-x-0 border-gray-100 border-b-2 my-1 font-sf-regular text-xs text-gray-500"
                        id="price"
                        type="text"
                        placeholder="Description"
                        aria-label="Description"
                        onChange={(e) => setDescription(e.target.value)}
                        value={Desciption}
                    />
            </div>
            <div className="flex justify-between items-end mb-2">
                <select id="event_type" onChange={(e) => setCurrentMenuOption(e.target.value)} className="w-2/5 px-0 h-8 focus:ring-0 focus:border-l-0 focus:border-t-0 focus:border-r-0 focus:border-b-2 focus:border-blue-600 border-t-0 border-x-0 border-gray-100 border-b-2 font-sf-regular text-xs text-gray-500">
                    {menuoptions.map((item, index) => (
                        <option>{item.label}</option>
                    ))}
                </select>
                <div onClick={() => addItem(currentItem, currentPrice, currentQuantity, Desciption, currentMenuOption)} className="cursor-pointer mb-0.5">
                    <p className="text-xs font-productsans-medium text-blue-600">Save Item</p>
                </div>
            </div>
        </>
    )
}