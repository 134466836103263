import { ChartSquareBarIcon } from '@heroicons/react/outline';
import { BadgeCheckIcon, CalendarIcon, CheckCircleIcon, InboxIcon, InboxInIcon, ReceiptTaxIcon, UserCircleIcon } from '@heroicons/react/solid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getTransactionAmounts, pricetoString } from '../../components/common';

const AdminStatistics = ({ parentLoading, originalTransactions, bids, cgeAccountList, staffAccountList }) => {
    const [loading, setLoading] = useState(true);

    const [transCount, setTransCount] = useState(0);

    const [bidCount, setBidCount] = useState(0);
    const [bidsInPeriod, setBidsInPeriod] = useState(0);
    const [eventsInPeriod, setEventsInPeriod] = useState(0);

    const [cgeCount, setCgeCount] = useState(0);
    const [dataset, setDataset] = useState([])
    const [salesPersonDataset, setSalesPersonDataset] = useState([])

    const [responseCount, setResponseCount] = useState(0);
    const [responseInPeriod, setResponseInPeriod] = useState(0);

    const [totalSubtotalReceived, setTotalSubtotalReceived] = useState(0)
    const [totalServiceFeeReceived, setTotalServiceFeeReceived] = useState(0)
    const [totalDeliveryReceived, setTotalDeliveryReceived] = useState(0)
    const [totalTaxesReceived, setTotalTaxesReceived] = useState(0)
    const [totalTipsReceived, setTotalTipsReceived] = useState(0)
    const [totalReceived, setTotalReceived] = useState(0)

    useEffect(() => {
        makeDataset()
    }, [originalTransactions, bids, cgeAccountList]);

    useEffect(() => {
        if (
            parentLoading[4] ||
            parentLoading[6] ||
            parentLoading[0] ||
            parentLoading[2]
        ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [parentLoading])


    function makeDataset() {
        let _bidcount = 0
        let _bidcountweek = 0
        let _eventcountweek = 0
        let _responsecount = 0
        let _responsecountweek = 0
        let _transcount = 0

        let _totalSubtotalReceived = 0
        let _totalServiceFeeReceived = 0
        let _totalDeliveryReceived = 0
        let _totalTaxesReceived = 0
        let _totalTipsReceived = 0
        let _totalReceived = 0

        let unorderedDataset = {}
        let salesPersonUnorderedDataset = {}

        for (const account of cgeAccountList) {
            const accountEmail = account.email
            const accountBids = []
            const accountTransactions = []

            let accountBidsMade = 0

            for (const bid of bids) {
                if (bid.cge_email === accountEmail) {
                    accountBids.push(bid)

                    _bidcount += 1

                    if (moment(bid.date_created).isSame(new Date(), 'week'))
                        _bidcountweek += 1

                    if (moment(bid.event_date_time).isSame(new Date(), 'week'))
                        _eventcountweek += 1

                    accountBidsMade += 1

                    _responsecount += bid.responses.length

                    _responsecountweek += bid.responses.filter(resp =>
                        moment(resp.date_created).isSame(new Date(), 'week'))
                        .length
                }
            }

            let accountPaidSubtotal = 0
            let accountPaidServiceFee = 0
            let accountPaidDeliveryFee = 0
            let accountPaidTaxes = 0
            let accountPaidTips = 0
            let accountPaidTotal = 0

            for (const trans of originalTransactions) {
                if (trans.cge_email === accountEmail) {
                    accountTransactions.push(trans)
                    _transcount += 1


                    const transAmounts = getTransactionAmounts(
                        trans.response.items,
                        trans.tipAmount,
                        trans.delivery_fee,
                        trans.business_delivers,
                        trans.bid.cge_service_fee,
                        trans.response.business_service_fee,
                        1,
                        trans.response.pricing, trans.response.total_price
                    );

                    try {
                        salesPersonUnorderedDataset[trans.bid.cge_representative].totalSubtotalReceived += transAmounts.subtotal
                        salesPersonUnorderedDataset[trans.bid.cge_representative].totalServiceFeeReceived += transAmounts.serviceFee
                        salesPersonUnorderedDataset[trans.bid.cge_representative].totalTipsReceived += transAmounts.tip
                        salesPersonUnorderedDataset[trans.bid.cge_representative].totalReceived += transAmounts.total
                    } catch {
                        salesPersonUnorderedDataset[trans.bid.cge_representative] = {
                            ...staffAccountList.filter((acc) => trans.bid.cge_representative === acc.email)[0],
                            totalSubtotalReceived: transAmounts.subtotal,
                            totalServiceFeeReceived: transAmounts.serviceFee,
                            totalTipsReceived: transAmounts.tip,
                            totalReceived: transAmounts.total,
                        }
                    }

                    accountPaidSubtotal += transAmounts.subtotal;
                    accountPaidServiceFee += transAmounts.serviceFee;
                    accountPaidDeliveryFee += transAmounts.deliveryFee
                    accountPaidTaxes += transAmounts.tax
                    accountPaidTips += transAmounts.tip
                    accountPaidTotal += transAmounts.total

                    _totalSubtotalReceived += accountPaidSubtotal
                    _totalServiceFeeReceived += accountPaidServiceFee
                    _totalDeliveryReceived += accountPaidDeliveryFee
                    _totalTaxesReceived += accountPaidTaxes
                    _totalTipsReceived += accountPaidTips
                    _totalReceived += accountPaidTotal
                }
            }

            setBidCount(_bidcount)
            setBidsInPeriod(_bidcountweek)
            setEventsInPeriod(_eventcountweek)
            setResponseCount(_responsecount)
            setResponseInPeriod(_responsecountweek)
            setTransCount(_transcount)


            setTotalSubtotalReceived(_totalSubtotalReceived)
            setTotalServiceFeeReceived(_totalServiceFeeReceived)
            setTotalDeliveryReceived(_totalDeliveryReceived)
            setTotalTaxesReceived(_totalTaxesReceived)
            setTotalTipsReceived(_totalTipsReceived)
            setTotalReceived(_totalReceived)

            unorderedDataset[accountEmail] = {
                ...account,
                bids: accountBids,
                transactions: accountTransactions,
                numBids: accountBidsMade,
                subtotalPaid: accountPaidSubtotal,
                serviceFeePaid: accountPaidServiceFee,
                deliveryPaid: accountPaidDeliveryFee,
                taxesPaid: accountPaidTaxes,
                tipsPaid: accountPaidTips,
                totalPaid: accountPaidTotal,
            }
        }

        var orderedDataset = Object.keys(unorderedDataset).sort(function (a, b) {
            return unorderedDataset[b].totalPaid - unorderedDataset[a].totalPaid;
        }).map(function (sortedKey) {
            return unorderedDataset[sortedKey];
        })

        var orderedSalesPersonDataset = Object.keys(salesPersonUnorderedDataset).sort(function (a, b) {
            return salesPersonUnorderedDataset[b].totalReceived - salesPersonUnorderedDataset[a].totalReceived;
        }).map(function (sortedKey) {
            return salesPersonUnorderedDataset[sortedKey];
        })

        setCgeCount(orderedDataset.length)
        setDataset(orderedDataset)
        setSalesPersonDataset(orderedSalesPersonDataset)
    }

    return (
        <div className='flex flex-col w-full h-full space-y-2'>
            <div className="flex flex-row w-full h-24 shadow-md border rounded-lg p-5 justify-around bg-blue-100">
                <div className="flex flex-col items-center justify-center">
                    {loading ?
                        <div className="stat-value text-primary">
                            <span className="loading loading-spinner loading-sm"></span>
                        </div>
                        :
                        <div className="stat-value text-primary">{transCount}</div>}
                    <div className="stat-title">Transactions Received</div>
                </div>
                <div className="flex flex-col items-center justify-center">
                    {loading ?
                        <div className="stat-value text-primary">
                            <span className="loading loading-spinner loading-sm"></span>
                        </div>
                        :
                        <div className="stat-value text-primary">${pricetoString(totalReceived)}</div>}
                    <div className="stat-title">Total Transactions</div>
                </div>
                <div className="flex flex-col items-center justify-center">
                    {loading ?
                        <div className="stat-value text-primary">
                            <span className="loading loading-spinner loading-sm"></span>
                        </div>
                        :
                        <div className="stat-value text-primary">${pricetoString(totalServiceFeeReceived)}</div>}
                    <div className="stat-title">Total Revenue</div>
                </div>

            </div>

            <div className="flex flex-row w-full h-28 shadow-md border rounded-lg p-5 justify-around bg-blue-100">
                <div className="flex flex-col items-center justify-center">
                    {loading ?
                        <>
                            <div className="stat-value text-primary">
                                <span className="loading loading-spinner loading-sm"></span>
                            </div>
                            <div className="stat-title">Bids</div>
                        </>
                        :
                        <>
                            <div className="stat-value text-primary">{bidCount}</div>
                            <div className="stat-title">Bids</div>
                            <div className="stat-desc">{bidsInPeriod} new bids this week</div>
                        </>
                    }
                </div>
                <div className="flex flex-col items-center justify-center">
                    {loading ?
                        <>
                            <div className="stat-value text-primary">
                                <span className="loading loading-spinner loading-sm"></span>
                            </div>
                            <div className="stat-title">Responses</div>
                        </>
                        :
                        <>
                            <div className="stat-value text-primary">{responseCount}</div>
                            <div className="stat-title">Responses</div>
                            <div className="stat-desc">{responseInPeriod} responses made this week</div>
                        </>
                    }
                </div>
                <div className="flex flex-col items-center justify-center">
                    {loading ?
                        <div className="stat-value text-primary">
                            <span className="loading loading-spinner loading-sm"></span>
                        </div>
                        :
                        <div className="stat-value text-primary">{eventsInPeriod}</div>
                    }
                    <div className="stat-title">Events this week</div>
                </div>
                <div className="flex flex-col items-center justify-center">
                    {loading ?
                        <div className="stat-value text-primary">
                            <span className="loading loading-spinner loading-sm"></span>
                        </div>
                        :
                        <div className="stat-value text-primary">{cgeCount}</div>
                    }
                    <div className="stat-title">Clients</div>
                </div>
            </div>

            <div className='flex flex-row w-full space-x-3 h-[66%]'>
                <div className='mt-5 flex flex-col w-1/2 px-2 pt-2 border border-gray-100 rounded-md'>
                    <p className='font-productsans-medium my-1 ml-1'>Top Sales Associates</p>
                    <div className="overflow-x-auto space-y-1 my-1 w-full h-[calc(55vh)] scrollbar-hide">
                        {loading ?
                            <div className='space-y-2'>
                                <div className="flex h-20 rounded-md animate-pulse mx-5 py-3 border-b bg-gray-100"></div>
                                <div className="flex h-20 rounded-md animate-pulse mx-5 py-3 border-b bg-gray-100"></div>
                                <div className="flex h-20 rounded-md animate-pulse mx-5 py-3 border-b bg-gray-100"></div>
                                <div className="flex h-20 rounded-md animate-pulse mx-5 py-3 border-b bg-gray-100"></div>
                                <div className="flex h-20 rounded-md animate-pulse mx-5 py-3 border-b bg-gray-100"></div>
                            </div>
                            :
                            salesPersonDataset.map(person => (
                                person.email &&
                                <div className="justify-between flex font-roboto-regular text-paragraph mx-5 py-3 border-b ">
                                    <div className='flex flex-col w-2/3'>
                                        {/* <div className='font-productsans-bold'>{client.organization}</div> */}
                                        <div className="flex bid-label text-gray-600 mb-0">{person.first_name} {person.last_name}</div>
                                        <div>{person.role}, {person.department}</div>
                                        <div>{person.email}</div>
                                        {/* <div className='inline-flex bg-blue-200 font-roboto-medium rounded-md text-xs p-0.5 px-1 mb-2'>{client.cge_representative}</div> */}
                                    </div>
                                    <div className='flex flex-col w-1/3 font-productsans-medium space-y-1 items-end justify-end'>
                                        <div className='text-base w-4/5 bg-green-200 text-green-700 text-center rounded-full '>${pricetoString(person.totalReceived)}</div>
                                        <div className='text-sm mr-2'>Generated Pipeline</div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                <div className='mt-5 flex flex-col w-1/2 px-2 pt-2 border border-gray-100 rounded-md'>
                    <p className='font-productsans-medium my-1 ml-1'>Top Customers</p>
                    <div className="overflow-x-auto space-y-1 my-1 w-full scrollbar-hide">
                        {loading ?
                            <div className='space-y-2'>
                                <div className="flex h-20 rounded-md animate-pulse mx-5 py-3 border-b bg-gray-100"></div>
                                <div className="flex h-20 rounded-md animate-pulse mx-5 py-3 border-b bg-gray-100"></div>
                                <div className="flex h-20 rounded-md animate-pulse mx-5 py-3 border-b bg-gray-100"></div>
                                <div className="flex h-20 rounded-md animate-pulse mx-5 py-3 border-b bg-gray-100"></div>
                                <div className="flex h-20 rounded-md animate-pulse mx-5 py-3 border-b bg-gray-100"></div>
                            </div>
                            :
                            dataset.map(client => (
                                client.organization &&
                                <div className="justify-between flex font-roboto-regular text-paragraph mx-5 py-3 border-b ">
                                    <div className='flex flex-col w-2/3'>
                                        <div className='font-productsans-bold'>{client.organization}</div>
                                        <div className="flex bid-label text-gray-600 mb-0">{client.first_name} {client.last_name}</div>
                                        <div>{client.email}</div>
                                        {/* <div className='inline-flex bg-blue-200 font-roboto-medium rounded-md text-xs p-0.5 px-1 mb-2'>{client.cge_representative}</div> */}
                                    </div>
                                    <div className='flex flex-col w-1/3 font-productsans-medium space-y-1 items-end justify-end'>
                                        <div className='text-base w-4/5 bg-green-200 text-green-700 text-center rounded-full '>${pricetoString(client.totalPaid)}</div>
                                        <div className='text-sm mr-2'>{client.numBids} Bids</div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AdminStatistics;
