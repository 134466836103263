import React from 'react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { CheckIcon } from '@heroicons/react/outline';

import { AccountsAPI } from '../../../apis';

export default function AdminCGERequestAccept({ account, staffAccountList }) {
    const [rep, setRep] = useState(staffAccountList[0].email);
    const [accepted, setAccepted] = useState(false);

    async function handleAccept(account) {
        if (rep != "") {
            await AccountsAPI.acceptCGEUser({ 'access_code': account.access_code, 'cge_representative': rep }).then((res) => {
                setAccepted(true)
                // if (res) {
                //     setRefresh(!refresh)
                // }
            })
        } else {
            toast.error('Please enter a CGE Account Manager')
        }
    }

    return (
        <div className="p-5">

            <div className="grid grid-cols-1 gap-4">
                <div className="col-span-1">
                    <label className="input-group">
                        <span>CGE Account Manager</span>
                        <select onChange={(e) => setRep(e.target.value)} id="cge_account"
                            className="w-full border-2 rounded h-11 px-3 flex items-center border-gray-100 andie-description-text">
                            <option disabled={true}>Account Manager</option>
                            {staffAccountList?.map((item, index) => (
                                <option value={item.email} selected={item.email === rep}>{item.first_name} {item.last_name}, {item.role} - {item.department}</option>
                            ))}
                        </select>

                    </label>
                </div>
                {accepted ?
                    <div className="andie-dark-button bg-green-400 hover:bg-green-400 disabled w-full flex flex-row items-center">
                        <CheckIcon className="h-6 text-white" />
                        <p>Accepted</p>
                    </div>
                    :
                    <div onClick={(e) => handleAccept(account, true)} className="andie-dark-button w-full cursor-pointer flex flex-row items-center">
                        <CheckIcon className="h-6 text-white" />
                        <p>Accept User</p>
                    </div>
                }


            </div>
        </div>
    );
}