import { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Link } from "react-router-dom";
import { AccountsAPI, StripeAPI } from "../../apis";
import { BillingDetails, BillingDetailsForm, PaymentForm } from "../../components/billing";
import { DocumentTextIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { ThreeDots } from "react-loader-spinner";
import { pricetoString } from "../../components/common";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST)
const options = {
    theme: 'night'
}
const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        width: "60%",
        height: '50%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};
export default function Wallet() {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [pmModal, setPmModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState("")
    const [pms, setPms] = useState([])
    const [cardIndex, setCardIndex] = useState([])
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")

    const [city, setCity] = useState("")
    const [country, setCountry] = useState("CA")
    const [address, setAddress] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [province, setProvince] = useState("")
    const [succeededBalance, setSucceededBalance] = useState(0)
    const [processingBalance, setProcessingBalance] = useState(0)
    const [depositDays, setDepositDays] = useState(3)
    const [dashboardURL, setDashboardURL] = useState("")
    const [loadingDashboardURL, setLoadingDashboardURL] = useState(true)


    useEffect(() => {
        if (user.account_type == 1) {
            getPmData();
        }
        if (Object.keys(user?.billing_details).length > 0) {
            setFirstname(user.billing_details.name.split(" ")[0])
            setLastname(user.billing_details.name.split(" ")[1])
            setEmail(user.billing_details.email)
            setAddress(user.billing_details.address.line1)
            setCity(user.billing_details.address.city)
            setPostalCode(user.billing_details.address.postal_code)
            setProvince(user.billing_details.address.province)
            setPhone(user.billing_details.phone)
            setCountry(user.billing_details.address.country)
        }
        getCGEWalletInformation();
        getStripeDashboardURL();

    }, [])

    async function getStripeDashboardURL() {
        const res = await StripeAPI.getStripeDashboardURL();
        console.log(res)
        setDashboardURL(res)
        setLoadingDashboardURL(false);
    }


    async function getCGEWalletInformation() {
        const res = await StripeAPI.getCGEWalletInformation();
        setSucceededBalance(res['succeeded'])
        setProcessingBalance(res['requires_confirmation'])
        setDepositDays(res['deposit_days'])
    }

    async function getPmData() {
        const res = await StripeAPI.getPms();
        // const det = await AccountsAPI.getBillingDetails();
        setPms(res.data.data)
        setPmModal(false)

    }

    async function deletePm() {
        const res = await StripeAPI.deletePm(pms[cardIndex].id);
        setPms(res.data.data)
        setDeleteModal(false)
    }

    async function editBilling() {
        let billingDetails = {
            address: {
                city: city,
                country: country,
                line1: address,
                postal_code: postalCode,
                province: province
            },
            email: email,
            name: firstname + " " + lastname,
            phone: phone,
        }
        const res = await AccountsAPI.editBilling(billingDetails);
        user.billing_details = billingDetails
        localStorage.setItem('user_details', JSON.stringify(user))

    }
    return (
        <div className="w-full flex-grow-0 md:w-4/5 lg:w-3/5">
            <Modal
                blockScroll={false}
                open={pmModal}
                // closeOnOverlayClick={false}
                closeOnEsc={false}
                // showCloseIcon={false}
                styles={styles}
                onClose={() => setPmModal(false)}
                center>
                <div className="flex w-full">
                    <Elements stripe={stripePromise} options={options}>
                        <PaymentForm setPaymentMethod={(e) => setPaymentMethod(e)} page="wallet" getPmData={() => getPmData()} name={user.first_name + " " + user.last_name} />
                    </Elements>
                </div>
            </Modal>

            <Modal
                blockScroll={false}
                open={deleteModal}
                // closeOnOverlayClick={false}
                closeOnEsc={false}
                // showCloseIcon={false}
                onClose={() => setDeleteModal(false)}
                center>
                <div className="p-8" >
                    <p> Do you want to delete card ending with {pms[cardIndex]?.card.last4}? </p>
                    <button className="btn" onClick={() => deletePm()}> yes </button>
                </div>

            </Modal>
            <h6 className="hidden md:inline-flex andie-sidebar-title">Wallet</h6>
            <div className="w-full">
                <div className="space-y-2.5 w-2/3 md:w-3/5">
                    <div className="mt-5 grid grid-cols-2">
                        <div className="border-r border-gray-200">
                            <h3 className="font-productsans-black text-3xl text-yellow-500">
                                ${processingBalance == null ? 0 : pricetoString(processingBalance)}
                            </h3>
                            <h6 className="text-sm font-productsans-medium text-gray-700">Pending</h6>

                        </div>
                        <div className="pl-5">
                            <h3 className="font-productsans-black text-3xl text-green-500">
                                ${succeededBalance == null ? 0 : pricetoString(succeededBalance)}
                            </h3>
                            <h6 className="text-sm font-productsans-medium text-gray-700">Processed</h6>
                        </div>
                    </div>

                    <div className="bg-blue-100 rounded inline-flex py-1.5 px-2 space-x-1.5">
                        <InformationCircleIcon className="text-blue-600 h-4" />
                        <p className="font-roboto-regular text-gray-600 text-xs">Payment processing may take 3-7 business days.</p>
                    </div>

                    <p className="mt-4 font-roboto-medium text-gray-500 text-xs">Next deposit day: {depositDays == 0 ? "End of Day" : `${depositDays} days`} </p>
                </div>

                {user.account_type == 1 ?
                <>
                    <h6 className="mt-7 text-sm font-productsans-medium">
                        Payment Method
                    </h6>
                    <p className="andie-small-description">
                        Your saved cards and payment methods
                    </p>
                    <div className="mt-2 flex max-w-full">
                        <div className="flex flex-grow-0 items-center space-x-4 h-auto py-2 w-3/5 md:w-3/4 overflow-x-scroll scrollbar-hide">
                            {pms.length > 0 ?
                                <>
                                    {pms.map((pm, index) => {
                                        return (
                                            <div className="h-32 w-52 flex-shrink-0 lg:h-36 lg:w-60 flex flex-col bg-gray-800 rounded-md">
                                                <div className="flex justify-end">
                                                    <button class="-mt-2 -mr-2 h-5 w-5 flex items-center justify-center text-xs rounded-full text-slate-200 right-0 top-0 bg-red-600" onClick={() => { setDeleteModal(true); setCardIndex(index) }}>✕</button>
                                                </div>
                                                <div className="px-3 pb-3 h-full flex flex-col justify-between">
                                                    <div className="flex justify-between text-gray-500 items-center">
                                                        <h6 className="uppercase font-productsans-medium text-paragraph">{pm.billing_details.name}</h6>
                                                        <h4 className="uppercase text-paragraph font-productsans-black">{pm.card.brand}</h4>
                                                    </div>

                                                    <div className="flex justify-end text-gray-500">
                                                        <h6 className="text-sm font-productsans-bold">**** **** **** {pm.card.last4}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <div className="mt-4 h-40 w-60 p-3 flex flex-col justify-between border-2 border-dashed border-gray-200 rounded-xl hover:bg-gray-50 cursor-pointer">
                                    <div className="flex flex-col items-center justify-center pt-6" onClick={() => setPmModal(true)}>

                                        <p className="andie-small-description mt-2"> Add Card</p>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="flex flex-col md:w-40 pl-4 xl:pl-5 pt-4 space-y-3">
                            <div className="andie-outline-button">
                                <PlusCircleIcon className="h-4 text-blue-600 mr-1.5" />
                                <p className="hidden md:inline-flex">Add Card</p>
                            </div>
                            <Link to={"/activity"} >
                                <div className="andie-outline-button">
                                    <DocumentTextIcon className="h-4 text-blue-600 mr-1.5" />
                                    <p className="hidden md:inline-flex">Transactions</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </>
                :
                <>
                    <h6 className="mt-7 text-sm font-productsans-medium">
                            
                    </h6>

                    <div className="flex flex-row md:w-40 pl-4 xl:pl-5 pt-4 space-x-4">
                        <button onClick={() => loadingDashboardURL ? null : window.open(dashboardURL)}>
                            <div className="andie-outline-button">
                                <PlusCircleIcon className="h-4 text-blue-600 mr-1.5" />
                                {loadingDashboardURL ?
                                    <ThreeDots
                                        height="30"
                                        width="30"
                                        radius="9"
                                        color="#2563eb"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                    :
                                    <p className="hidden md:inline-flex">View Stripe Dashboard</p>
                                }
                            </div>
                        </button>
                        <Link to={"/activity"} >
                            <div className="andie-outline-button">
                                <DocumentTextIcon className="h-4 text-blue-600 mr-1.5" />
                                <p className="hidden md:inline-flex">Transactions</p>
                            </div>
                        </Link>
                    </div>   
                </>
                }

                <h6 className="mt-7 text-sm font-productsans-medium">
                    Billing Details
                </h6>
                <p className="andie-small-description">
                    Your current billing information
                </p>

                <div className="mt-4">
                    <BillingDetails editBilling={() => editBilling()} page="account" setFirstname={(e) => setFirstname(e)} firstname={firstname} setLastname={(e) => setLastname(e)} lastname={lastname} setEmail={(e) => setEmail(e)} email={email} setAddress={(e) => setAddress(e)} address={address} setCity={(e) => setCity(e)} city={city} setPostalCode={(e) => setPostalCode(e)} postalCode={postalCode} setProvince={(e) => setProvince(e)} province={province} setPhone={(e) => setPhone(e)} phone={phone} setCountry={(e) => setCountry(e)} country={country} />

                    {/* {Object.keys(user.billing_details).length == 0 ?
                        <div className="mt-4">
                            <div className="flex items-center justify-between border-b pb-2 mb-3">
                                <h2 className="text-sm font-productsans-medium text-gray-600">Billing Information</h2>
                            </div>
                            <BillingDetailsForm setFirstname={(e) => setFirstname(e)} firstname={firstname} setLastname={(e) => setLastname(e)} lastname={lastname} setEmail={(e) => setEmail(e)} email={email} setAddress={(e) => setAddress(e)} address={address} setCity={(e) => setCity(e)} city={city} setPostalCode={(e) => setPostalCode(e)} postalCode={postalCode} setProvince={(e) => setProvince(e)} province={province} setPhone={(e) => setPhone(e)} phone={phone} setCountry={(e) => setCountry(e)} country={country} />
                            <button className="andie-btn-dark text-white w-full mt-4" onClick={() => editBilling()}> Submit Changes </button>
                        </div>
                        :
                        <BillingDetails page="account" setFirstname={(e) => setFirstname(e)} firstname={firstname} setLastname={(e) => setLastname(e)} lastname={lastname} setEmail={(e) => setEmail(e)} email={email} setAddress={(e) => setAddress(e)} address={address} setCity={(e) => setCity(e)} city={city} setPostalCode={(e) => setPostalCode(e)} postalCode={postalCode} setProvince={(e) => setProvince(e)} province={province} setPhone={(e) => setPhone(e)} phone={phone} setCountry={(e) => setCountry(e)} country={country} />
                    } */}
                </div>
            </div>
        </div>
    )
}