import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { SelectedTransaction } from "../../components/transactions";
import { BiddingAPIs } from "../../apis";

const TransactionView = () => {
  const user = JSON.parse(window.localStorage.getItem("user_details"));
  const { transaction_code } = useParams();
  const [selectedTransaction, setSelectedTransaction] = useState({});

  let id = transaction_code;

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    var result = await BiddingAPIs.getTransactionById(id);
    setSelectedTransaction(result);
  }

  return (
    <>
      <div className="h-screen overflow-hidden overflow-y-auto md:mx-24">
        <div className="h-full flex flex-col md:my-12">
          <div className="p-2 flex justify-between items-center text-xs w-full">
            <img src={require("../../assets/images/pluck-logo.png")} />
          </div>
          <SelectedTransaction
            transaction={selectedTransaction}
          />
        </div>
      </div>
    </>
  );
};

export default TransactionView;
