import React, { useEffect, useState } from 'react';
import { CheckIcon, XIcon, InformationCircleIcon } from '@heroicons/react/solid';

import { currencyOptions, pricetoString, tipOptions } from '../../common';

export default function BidBudget({ tipStatus, cgeType, setTipStatus, budget, paymentTerms, setPaymentTerms, guestCount, currency, setCurrency, setBudget, setSelectedTab, isNextButtonDisabled, setIsNextButtonDisabled }) {
    const [perHead, setPerHead] = useState(budget.perHead);

    useEffect(() => {
        if (cgeType === "Dining" || cgeType === "Private Dining") {
            if (tipStatus === 0) {
                updateTip(18)
            }
        }
    }, [cgeType, tipStatus])

    function updateBudget(per) {
        const updated_budget = {
            perHead: per,
            total: per * guestCount,
            tip: tipStatus
        }

        setPerHead(per);
        setBudget(updated_budget);
    }

    function selectPaymentTerms(option) {
        setPaymentTerms(option);
    }

    function updateTip(option) {
        setTipStatus(option);
    }


    return (
        <div className="h-full flex flex-col justify-between rounded">
            <div className="flex flex-col xl:w-[70%]">
                <div className="h-44">
                    <div className="flex space-x-2">
                        {currencyOptions.map((option, index) => (
                            <div className="flex items-center">
                                <div onClick={() => setCurrency(option.name)} className={`cursor-pointer h-6 px-3 flex items-center justify-center rounded-sm ${option.name === currency ? 'bg-green-700' : 'border-2 border-gray-300'}`}>
                                    <p className={`text-xs font-roboto-medium ${option.name === currency ? 'text-white' : 'text-gray-400'}`}>{option.name}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="md:w-3/5 pr-6 mt-7 mb-4">
                        <h1 className="bid-form-input-label mb-0">How much are you looking to spend per head?</h1>
                        <p className="andie-section-descriptor">Excluding tips and applicable taxes and fees</p>
                        <div className="mt-2 flex items-center">
                            <input
                                className="w-1/3 bid-form-input p-2.5"
                                id="budget"
                                onChange={(e) => updateBudget(e.target.value)}
                                 type="number" onWheel={(e) => e.target.blur()} 
                                min="1"
                                required
                                placeholder={`${perHead}`}
                                aria-label="Budget"
                            />
                            <XIcon className="h-3.5 mx-1.5" />
                            <h5 className="text-paragraph font-roboto-medium text-[#009473]">{guestCount} guests = ${pricetoString(perHead * guestCount)}</h5>
                        </div>
                    </div>

                    <div className="flex flex-col space-y-6 mt-8">
                        <div className="md:w-1/2">
                            <div className="flex items-center space-x-1">
                                <p className="bid-form-input-label mb-0">Please select a tip or gratuity amount.</p>
                                <div className="-mt-0.5 tooltip hover:tooltip-open tooltip-right" data-tip="All tips and gratuities go directly to our restaurant partners.">
                                    <InformationCircleIcon className="h-4 text-gray-400" />
                                </div>
                            </div>

                            <div className="mt-1 flex space-x-3">
                                {tipOptions.map((item, index) => (
                                    <div className="flex items-center space-x-1">
                                        <div onClick={() => updateTip(item.value)} className={`cursor-pointer border-2 h-4 w-4 mr-1 flex items-center justify-center rounded ${tipStatus === item.value ? 'bg-blue-600  border-blue-600' : 'border-gray-300'}`}>
                                            {tipStatus === item.value && <CheckIcon className="h-4 rounded-sm text-white" />}
                                        </div>
                                        <p className="text-xs font-semibold text-gray-400">{item.name}</p>
                                    </div>
                                ))}
                            </div>
                            <p className="mt-3 andie-section-descriptor text-gray-400"></p>
                        </div>
                        <div className="w-2/5">
                            <p className="bid-form-input-label">Payment Terms</p>
                            <div className="bid-form-input bg-blue-100 border-0">
                                {/* <select id="payment_terms" onChange={(e) => selectPaymentTerms(e.target.value)} className="-ml-1.5 w-full focus:outline-none bg-transparent">
                                    {paymentoptions.map((item, index) => (
                                        <option selected={item.name === paymentTerms}>{item.name}</option>
                                    ))}
                                </select> */}
                                {paymentTerms}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}