import React from 'react';
import { PopoutModal } from '../../common';
import moment from 'moment';
import { ClockIcon, TagIcon, LocationMarkerIcon, UserCircleIcon, XIcon } from '@heroicons/react/outline';

const CalendarPopout = ({ ID, selectedBid }) => {
    return (
        selectedBid && <div>
            {/* Open the modal using document.getElementById('ID').showModal() method */}
            {/* <button className="btn" onClick={() => document.getElementById('my_modal_5').showModal()}>open modal</button> */}
            <dialog id={ID} className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <div className='flex justify-between mb-5'>
                        <h3 className="font-bold text-lg">{selectedBid.bid_title}</h3>
                        <div className="modal-action mt-0">
                            <form method="dialog">
                            <button ><XIcon className="h-7" /></button>
                        </form>
                    </div>
                </div>
                <div className='flex space-x-4'>
                    <ClockIcon height={25} />
                    <div className='flex justify-start space-x-5'>
                        <p className="font-productsans-regular">{moment(selectedBid.event_date_time).format('dddd, MMM D')}</p>
                        <p className="font-productsans-regular">{moment(selectedBid.event_date_time).format('hh:mm A')}</p>
                    </div>
                </div>

                <div className='flex justify-start align-middle items-center space-x-4 pt-4'>
                    <LocationMarkerIcon height={25} />
                    <p className="font-productsans-regular">{selectedBid.location}</p>
                </div>

                <div>
                    <div className='flex justify-start align-top items-start space-x-4 pt-4'>
                        <UserCircleIcon height={25} />
                        <div className='flex flex-col'>
                            <p className="font-productsans-bold">{selectedBid.organization}</p>
                            <p className="font-productsans-regular">{selectedBid.contact_first_name} {selectedBid.contact_last_name}</p>
                            <p className="font-productsans-regular">{selectedBid.contact_email}</p>
                            <p className="font-productsans-regular">{selectedBid.contact_phone}</p>
                        </div>
                    </div>
                </div>

                <div className='flex justify-start align-top items-start space-x-4 pt-4'>
                    <TagIcon height={25} />
                    <div>
                    <p className="font-productsans-regular">{selectedBid.bid_description}</p>
                    </div>
                </div>
            

                <div className='flex justify-between space-x-4 pt-4 '>
                    <p className="px-5 bg-green-200 rounded-xl">{selectedBid.status}</p>
                    <p className="px-5 bg-gray-200 rounded-xl">{selectedBid.cge_representative}</p>
                </div>




        </div>
            </dialog >
        </div >
    );
}

export default CalendarPopout;
