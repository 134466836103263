export { default as Overview } from "./Overview";
export { default as PersonalInformation } from "./PersonalInformation";
export { default as CollectingInformation } from "./CollectingInformation";
export { default as SharingInformation } from "./SharingInformation";
export { default as TransferringInformation } from "./TransferringInformation";
export { default as ChoiceAboutInformation } from "./ChoiceAboutInformation";
export { default as DataSecurity } from "./DataSecurity";
export { default as DataRetention } from "./DataRetention";
export { default as SaleOfPersonalInformation } from "./SaleOfPersonalInformation";
export { default as AgeConsent } from "./AgeConsent";
export { default as AccessingInformation } from "./AccessingInformation";
export { default as WithdrawingConsent } from "./WithdrawingConsent";
export { default as PolicyChanges } from "./PolicyChanges";