import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { XIcon, ClockIcon, UserIcon, UserGroupIcon } from "@heroicons/react/solid";
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import toast, { Toaster } from 'react-hot-toast';

import { BidDetails, BidDietaryRequirements } from "../../bid/create";
import { BillingForm, TemplateBillingForm } from "../../billing";
import { BiddingAPIs } from "../../../apis";
import { PopoutModal, foodOptions, getPackageTransactionAmounts, getTransactionAmounts, pricetoString } from "../../common";
import { TemplateDietaryRequirements, TemplateOrderDetails, TemplateOrderItemSelect } from "..";
import { isAuthenticated } from "../../../apis/Credentials";
import moment from "moment";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST)

export default function TemplateOrderForm({ item, selectedPackage, setIsOpen, orderInProgress, setOrderInProgress, isOrderNowButtonDisabled }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hideBack, setHideBack] = useState(false);
    const navigate = useNavigate();

    const [eventDateTime, setEventDateTime] = useState(new Date());
    const [deliveryTime, setDeliveryTime] = useState(moment(moment().toDate()).add(1, "d").add(60, 'minute').startOf('hour').toDate());
    const [location, setLocation] = useState("");
    const [locationPlaceID, setLocationPlaceID] = useState("");
    const [locationGeometry, setLocationGeometry] = useState("");
    const [locationAdditionalDetails, setLocationAdditionalDetails] = useState("");
    const [guestCount, setGuestCount] = useState(0);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
    const [cgeType, setCGEType] = useState(item.cge_type);
    const [dietaryPreferences, setDietaryPreferences] = useState([]);
    const [requiresChafing, setRequiresChafing] = useState("");
    const [dietaryNotes, setDietaryNotes] = useState("");
    const [tipInput, setTipInput] = useState(0)
    const [subtotal, setSubtotal] = useState(0)
    const [serviceFee, setServiceFee] = useState(0)
    const [total, setTotal] = useState(0)
    const [tax, setTax] = useState(0)
    const [packagingType, setPackagingType] = useState(["Any"]);
    const [accessibility, setAccessibility] = useState({
        "Sidewalk": false,
        "Parking": false,
        "Stairs": false,
        "Elevators": false
    })
    const [orderModal, setOrderModal] = useState(false);
    const [orderStep, setOrderStep] = useState(0)
    const [additionalNotes, setAdditionalNotes] = useState(item.custom_items ? makeJSONPretty(item.custom_items) : "");
    const steps = [
        'Choose Your Package Items', 'Booking Details', 'Dietary Preferences', "Billing and Payment"
    ]

    const [packageItems, setPackageItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState({});

    const [items, setItems] = useState([])
    const [packageQuantities, setPackageQuantities] = useState({
        appetizer: selectedPackage.min_guests,
        main: selectedPackage.min_guests,
        side: selectedPackage.min_guests,
        dessert: selectedPackage.min_guests,
        beverages: selectedPackage.min_guests,
        other: selectedPackage.min_guests
    });

    useEffect(() => {
        if (selectedPackage.min_guests > -1)
            preProcessData(selectedPackage)
    }, [selectedPackage]);

    function preProcessData(packageData) {
        setItems([])
        let initialQuantities = {
            appetizer: selectedPackage.min_guests,
            main: selectedPackage.min_guests,
            side: selectedPackage.min_guests,
            dessert: selectedPackage.min_guests,
            beverages: selectedPackage.min_guests,
            other: selectedPackage.min_guests
        }
        let optionNames = Object.keys(packageData.items)

        for (const optionName of optionNames) {
            let option = packageData.items[optionName]
            for (const item of option) {
                const packageItem = item
                packageItem['menuOption'] = optionName
                if (initialQuantities[optionName] > 0) {
                    packageItem.quantity = initialQuantities[optionName]
                    initialQuantities[optionName] = 0
                } else {
                    packageItem.quantity = 0
                }
                setItems((items) => [packageItem, ...items])
                setPackageItems((items) => [packageItem, ...items])
            }
        }
    }


    function getNumPpl() {
        if (packageItems.length > 0)
            return packageItems.map(tempItem => tempItem.menuOption === "main" ? tempItem.serves * tempItem.quantity : 0).reduce((acc, curr) => acc + curr)
        else return 0
    }

    function makeJSONPretty(obj) {
        var tempstr = "Customized Items:"
        for (let index = 0; index < Object.keys(obj).length; index++) {
            const menuOption = Object.keys(obj)[index]
            tempstr += "    " + menuOption + ":"

            var innerStuff = obj[menuOption]
            for (let jndex = 0; jndex < Object.keys(innerStuff).length; jndex++) {
                var foodItem = Object.keys(innerStuff)[jndex]
                var qty = obj[menuOption][foodItem].quantity
                var included = obj[menuOption][foodItem].included
                if (included) {
                    tempstr += foodItem + " x" + qty + ", "
                }
            }
            tempstr = tempstr.slice(0, -2)
            tempstr += '      '
        }
        return tempstr
    }

    useEffect(() => {
        if (!isAuthenticated()) {
            setLoggedIn(false)
        } else {
            if (user !== null) {
                if (user.account_type == 2) {
                    setLoggedIn(false)
                } else {
                    setLoggedIn(true)
                }
            }
        }
    }, []);

    async function handleSubmit(paymentInfo) {
        let cuisineTypes = selectedPackage.cuisine_type.map((type) => {
            return convertValuetoLabel(foodOptions, type)
        })
        const bidPayload = {
            organization: user.organization,
            industry: user.industry,
            cge_email: user.email,

            //contact information
            contact_first_name: user.first_name,
            contact_last_name: user.last_name,
            contact_title: user.job_title,
            contact_phone: user.phone,
            contact_email: user.email,

            //basic bid information
            bid_title: item.title,
            bid_description: item.description,
            cge_type: item.cge_type,
            event_type: item.event_type,

            // logistics
            event_date_time: eventDateTime,
            guest_count: guestCount,
            location: location,
            locationPlaceID: locationPlaceID,
            locationGeometry: locationGeometry,
            locationAdditionalDetails: locationAdditionalDetails,
            // expiry_date: "DONT NEED",

            // financial details
            budget: { "total": total, "perHead": parseFloat(total / getNumPpl()).toFixed(2), "tipAmount": tipInput },
            currency: "CAD",
            payment_terms: "Prepaid in full",
            accessibility: accessibility,

            //restaurant selection
            restaurant_type: cuisineTypes,
            restaurant_rating: "Any",
            dietary_preferences: dietaryPreferences,
            dietary_notes: dietaryNotes,
            menu_options: ['Mains'],
            bid_type: "ordernow",

            //confirmation code
            transaction_code: transactionCode(6),
            additional_notes: additionalNotes,
            delivery_time: deliveryTime

        }

        const responsePayload = {
            organization: user.organization,
            restaurant_type: selectedPackage.cuisine_type[0],
            contact_name: "ANDIE ACC",
            contact_title: "ANDIE ACC",
            contact_phone: "ANDIE ACC",
            contact_email: "cgequotes@andie.work",
            business_email: "cgequotes@andie.work",
            items: packageItems,
            restaurant_name: "CGE Team",
            // images: [],
            // cge_email: user.email
        }

        const paymentPayload = {
            subtotal: subtotal,
            serviceFee: serviceFee,
            tax: tax,
            business_delivers: selectedPackage.business_delivers,
            delivery_fee: selectedPackage.delivery_fee,
            tip: tipInput,
            total: total + (subtotal * tipInput)
        }

        const payload = {
            bidPayload: bidPayload,
            responsePayload: responsePayload,
            paymentPayload: paymentPayload,
            stripe_pi_id: paymentInfo.paymentMethod.id,
            stripe_account_token: user.stripe_account_token
        }
        try {
            setLoading(true)
            const res = await BiddingAPIs.orderNowTemplate(payload).then((res) => { 
                // setOrderModal(false);
                // setIsOpen(false);
        
                // navigate('/activity');
            })

        } catch (e) {
        }
        setLoading(false)
    }

    function navigateToActivity() {
        setOrderModal(false);
        setIsOpen(false);
        
        navigate('/activity');
    }

    function shafingNotes() {
        const shaffing_note = '\nThis bid requires a chafing dish to be provided.';
        setRequiresChafing(!requiresChafing);

        if (requiresChafing) {
            setAdditionalNotes(additionalNotes + shaffing_note);
        } else {
            setAdditionalNotes(additionalNotes.replace(shaffing_note, ""));
        }

    }

    function transactionCode(length) {
        var result = '';
        // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!%$#?&';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }

        return result;
    }

    function convertValuetoLabel(arr, value) {
        try {
            return arr.find(x => x.value === value).label
        } catch ({ name, message }) {
            if (name == "TypeError") {
                return value
            }
        }
    }

    function handleStepThree() {
        setOrderStep(3)
        setTax(totalPrice.tax)
        setSubtotal(totalPrice.subtotal)
        setServiceFee(totalPrice.serviceFee)
        setTotal(totalPrice.total)
    }

    return (
        <PopoutModal
            title={steps[orderStep]}
            modalID={"order_now_form_" + String(item.id)}
            width={"44%"}
            height={"90%"}
            button={
                loggedIn ?
                    isOrderNowButtonDisabled ?
                        <button
                            disabled={true}
                            className={`w-full sm:w-auto bg-gray-200 text-black andie-dark-button px-5 hover:bg-gray-200 hover:text-black`}
                        >Please Select a Package to Order</button>
                        :
                        <button
                            onClick={() => setOrderInProgress(!orderInProgress)}
                            className={`andie-dark-button`}
                        >Order Now</button>
                    :
                    <div
                        onClick={() => navigate('/login')}
                        className={`w-full sm:w-auto bg-red-200 text-black andie-dark-button px-5 hover:bg-gray-200 hover:text-black`}
                    >Please Login to Order</div>
            }
            footerElements={
                <div className="pt-2 flex sm:py-5 flex-col sm:flex-row w-full sm:justify-between space-y-1 
                sm:space-y-0 sm:space-x-5 sm:items-center border-t bg-white">
                    {(orderStep > 0 && !hideBack) && <button onClick={() => setOrderStep(orderStep - 1)} className="w-full sm:w-auto bg-gray-100 text-xs font-productsans-medium text-gray-500 h-8 rounded px-2.5">Back</button>}
                    {(orderStep == 0) &&
                        <div className="flex space-x-2">
                            <div disabled className={`flex items-center justify-center
                     sm:w-auto border-2 border-gray-200 bg-white text-blue-600
                    text-largescreen font-productsans-medium 
                     h-8 rounded px-2.5`}>
                                <UserGroupIcon className="h-4 -mt-1 mr-2" />
                                {getNumPpl()} people
                            </div>
                            <div disabled className={`flex items-center justify-center
                     sm:w-auto border-2 border-gray-200 bg-white text-green-600
                     text-largescreen font-productsans-medium  
                     h-8 rounded px-2.5`}>
                                ${pricetoString(totalPrice.subtotal)}
                            </div>
                        </div>

                    }

                    {(orderStep < 3) && <button disabled={isNextButtonDisabled} onClick={() => {
                        !isAuthenticated() || user.account_type == 2 ?
                            toast.error("Please log in with a corporate account to continue!") :
                            orderStep === 2 ?
                                handleStepThree() :
                                setOrderStep(orderStep + 1)
                        setIsNextButtonDisabled(true)
                    }} className={`w-full sm:w-auto bg-blue-600 text-xs font-productsans-medium text-white  h-8 rounded px-2.5 ${isNextButtonDisabled ? "opacity-50" : null}`}>Next</button>
                    }
                </div>
            }
        >
            <div className="flex flex-col h-full w-full overflow-y-hidden">
                {orderStep === 0 &&
                    <TemplateOrderItemSelect pkg={selectedPackage} setPackageItems={setPackageItems} isNextButtonDisabled={isNextButtonDisabled}
                        setIsNextButtonDisabled={setIsNextButtonDisabled} totalPrice={totalPrice} setTotalPrice={setTotalPrice} getNumPpl={getNumPpl} setGuestCount={setGuestCount}
                        packageQuantities={packageQuantities} setPackageQuantities={setPackageQuantities}
                        items={items} setItems={setItems} />
                }
                {orderStep === 1 &&
                    <TemplateOrderDetails item={item} cgeType={cgeType}
                        accessibility={accessibility} setAccessibility={setAccessibility}
                        location={location} setLocation={setLocation}
                        setLocationPlaceID={setLocationPlaceID} setLocationGeometry={setLocationGeometry}
                        setLocationAdditionalDetails={setLocationAdditionalDetails}
                        eventDateTime={eventDateTime} setEventDateTime={setEventDateTime}
                        isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled}
                        deliveryTime={deliveryTime} setDeliveryTime={setDeliveryTime}
                    />

                }
                {orderStep === 2 &&
                    <TemplateDietaryRequirements
                        cge_type={cgeType}
                        packagingType={packagingType}
                        setPackagingType={(e) => setPackagingType(e)}
                        dietaryPreferences={dietaryPreferences} setDietaryPreferences={(e) => setDietaryPreferences([...e])}
                        dietaryNotes={dietaryNotes} setDietaryNotes={setDietaryNotes} additionalNotes={additionalNotes}
                        setAdditionalNotes={setAdditionalNotes} setSelectedTab={(e) => console.log()} isNextButtonDisabled={isNextButtonDisabled}
                        setIsNextButtonDisabled={setIsNextButtonDisabled} requiresChafing={requiresChafing} setRequiresChafing={setRequiresChafing}
                        shafingNotes={shafingNotes} />
                }
                {orderStep === 3 &&
                    <Elements stripe={stripePromise}>
                        <BillingForm
                            subtotal={subtotal}
                            total={total}
                            tax={tax}
                            tipInput={tipInput} setTipInput={(e) => setTipInput(e)}
                            serviceFee={serviceFee}
                            handleSubmit={(paymentInfo) => handleSubmit(paymentInfo)}
                            items={packageItems}
                            response={selectedPackage}
                            guestCount={guestCount}
                            cgeType={cgeType}
                            setHideBack={setHideBack}
                            hideBack={hideBack}
                            navigateToActivity={navigateToActivity}
                            fromTemplate={true}
                        />
                    </Elements>
                }
            </div>
        </PopoutModal >

    )
}