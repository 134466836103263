import { CheckIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiddingAPIs } from "../../../apis";
import moment from "moment";
import { PopoutModal, pricetoString } from "../../common";
import AdminBidEdit from "./AdminEditBid";
import { AdminDeleteBid } from "..";


export default function AdminBidsInfo({ bid, staffAccountList, cgeAccountList }) {
    const [edited, setEdited] = useState(false)
    const [rep, setRep] = useState(bid.cge_representative);

    const navigate = useNavigate();


    const handleBidEdit = (valuename, value) => {
        bid[valuename] = value
        setEdited(true)
    }

    function handleOpenBid(bid) {
        navigate('/bid/' + bid.transaction_code + '/')
    }

    const submitChanges = async () => {
        if (edited) {
            const payload = bid
            await BiddingAPIs.editBid(payload).then(
                setEdited(true)
            )
        }
    }

    return (
        <div className="mb-10 p-5 bg-blue-50 shadow rounded-md">
            <div className="flex flex-row justify-between align-middle ">
                <div className="flex-col ">
                    <h4 className="font-productsans-bold text-xl">Bid #{bid.transaction_code.toUpperCase()}</h4>
                </div>
                <div className="flex-col items-end">
                    <div onClick={() => handleOpenBid(bid)} className="andie-text-button">
                        <p>View Bid</p>
                    </div>

                </div>

            </div>
            <div className="flex flex-row w-full border-b justify-between">
                <div className="w-3/5 mb-3 mt-1">
                    <p className='font-productsans-bold text-sm'> {bid.organization} </p>
                    <p className='font-roboto-regular text-xs'> {bid.contact_first_name} {bid.contact_last_name} </p>
                    <p className='font-roboto-regular text-xs'> {bid.contact_email} </p>
                    <p className='font-roboto-regular text-xs'> {bid.contact_phone} </p>
                </div>
                <div className="mb-3 text-right">
                    <p className='font-productsans-bold text-sm'> Event Date: {moment(new Date(bid.event_date_time)).format("ll")} </p>
                    <p className='font-roboto-regular text-xs'> expires {moment(new Date(bid.expiry_date)).fromNow()} </p>
                    <p className='font-roboto-regular text-xs'> created on {moment(new Date(bid.date_created)).format("ll")} </p>
                </div>
            </div>

            {/* <div className="bg-green-700 h-2 w-[700px]"> </div> */}

            <table className="w-full my-3">
                <tr>
                    <td className='font-productsans-bold text-paragraph'>Event Title </td>
                    <td className='font-roboto-regular text-paragraph'>{bid.bid_title} </td>
                </tr>
                <tr>

                    <td className='font-productsans-bold text-paragraph'>Event Type</td>
                    <td className='font-roboto-regular text-paragraph'>{bid.cge_type} for {bid.guest_count} Guests</td>
                </tr>
                <tr>
                    <td className='font-productsans-bold text-paragraph'>Event Budget</td>
                    <td className='font-roboto-regular text-paragraph'>${pricetoString(bid.budget.perHead)} per head, ${pricetoString(bid.budget.total)} total</td>
                </tr>
                <tr>
                    <td className='font-productsans-bold text-paragraph'>Event Location </td>
                    <td className='font-roboto-regular text-paragraph'>{bid.location} </td>
                </tr>
            </table>
            <table className="w-full my-3">

                <tr>
                    <td className='font-productsans-bold text-paragraph'> Bid Manager </td>
                    <td >
                        <select onChange={(e) => {
                            handleBidEdit('cge_representative', e.target.value);
                            handleBidEdit('business_representative', e.target.value)
                            setRep(e.target.value)
                        }} id="cge_rep"
                            className="w-full h-8 pl-1 flex border border-gray-200">
                            <option disabled={true}>Assigned Manager</option>
                            {staffAccountList?.map((item, index) => (
                                <option value={item.email} selected={item.email === rep}>{item.first_name} {item.last_name}, {item.role} - {item.department}</option>
                            ))}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td className='font-productsans-bold text-paragraph'> Bid Service Fee </td>
                    <td className="w-full flex border border-gray-200 bg-white items-center h-8 pl-2 mt-1">
                        <input defaultValue={bid.cge_service_fee * 100} onChange={(e) => handleBidEdit('cge_service_fee', e.target.value / 100)} type="number" onWheel={(e) => e.target.blur()} className="w-full" />
                        <p className='mr-1 font-productsans-regular text-sm'> % </p>
                    </td>
                </tr>
            </table>
            {edited &&
                <div onClick={submitChanges} className="andie-outline-button w-full h-8 cursor-pointer flex flex-row items-center">
                    <p>Save Changes to Bid</p>
                </div>
            }
            <div className="flex w-full space-x-4">
                <AdminBidEdit bid={bid} cgeAccountList={cgeAccountList} />
                <AdminDeleteBid bid={bid} />
            </div>
        </div>
    )
}