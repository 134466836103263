import React, { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";

import {
  getTransactionAmounts,
  pricetoString
} from "../common";
import { TransactionItemsDrawer, TransactionReceipt } from '.';
import { BiddingAPIs } from "../../apis";

const SelectedTransaction = ({ transaction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false);
  const [updatedTransaction, setUpdatedTransaction] = useState({});
  const [stepIndex, setStepIndex] = useState(0);

  let steps = ["Pending", "Accepted", "Confirmed", "Completed and Paid"];

  useEffect(() => {
    if (Object.keys(transaction).length > 0) {
      setStatus(transaction.status)
      setStepIndex(steps.indexOf(transaction.status));
    }
  }, [transaction])



  let bid = transaction.bid;
  let response = transaction.response;
  const user = JSON.parse(window.localStorage.getItem("user_details"));
  let account_type = user.account_type;




  useEffect(() => {
    setStatus(updatedTransaction?.status);
    setStepIndex(steps.indexOf(updatedTransaction?.status));
  }, [updatedTransaction])


  if (!transaction.bid || transaction.bid.length === 0) {
    // Handle the case when transaction.bid is undefined or empty
    return <div>Retrieving bid data...</div>;
  }

  
  const transItems = getTransactionAmounts(response.items, transaction.tipAmount, transaction.delivery_fee, transaction.business_delivers, bid.cge_service_fee, response.business_service_fee)
  const subtotal = transItems.subtotal
  const service_fee = transItems.serviceFee
  const gratuity = transItems.tip
  const tax = transItems.tax
  const total = transItems.total

  const updateStatus = async (status) => {
    setLoading(true);

    const payload = {
      id: transaction.id,
      status: status
    }
    try {
      var result = await BiddingAPIs.updateTransaction(payload);
      var data = result.data;

      if (data === "Transaction updated.") {
        var response = await BiddingAPIs.getTransactionById({ "id": transaction.id });
        setUpdatedTransaction(response);
      }
    } catch (e) {

    }
    setLoading(false);
  }
  //Test
  function toggleModal() {
    setIsOpen(!isOpen);
  }


  return (
    <div>
      <div className="grid grid-cols-2 text-xs p-2 gap-y-2">
        <div className="col-span-2 text-xl font-productsans-bold text-gray-400">
          #{transaction.id.toString().padStart(5, "0")}
        </div>

        <div className="col-span-1 uppercase font-semibold">Organization</div>

        <div className="col-span-1 flex justify-end">
          <TransactionReceipt
            transaction={transaction}
            account_type={account_type}
          />
        </div>

        <div className="col-span-2">{bid.cge_type}</div>

        <div className="bg-gray-100 w-full col-span-2 h-[1px]" />

        <div className="col-span-1 uppercase font-semibold">Location</div>
        <div className="col-span-1 uppercase font-semibold">Date and time</div>
        <div className="col-span-1">{bid.location}</div>
        <div className="col-span-1">
          {new Date(bid.event_date_time).toDateString()} at{" "}
          {moment(bid.event_date_time).format("LT")}
        </div>
        <div className="col-span-1 uppercase font-semibold">Event type</div>
        <div className="col-span-1 uppercase font-semibold">Headcount</div>
        <div className="col-span-1">{bid.event_type}</div>
        <div className="col-span-1">{bid.guest_count}</div>

        <div className="bg-gray-100 w-full col-span-2 h-[1px]" />

        <div className="col-span-2 uppercase font-semibold">Status</div>
        <ul className="col-span-2 steps steps-horizontal z-0">
          <li
            data-content={`${stepIndex >= 0 ? "✓" : "!"}`}
            className={`step ${stepIndex >= 0 ? "step-primary" : ""}`}
          >
            Pending
          </li>
          <li
            data-content={`${stepIndex >= 1 ? "✓" : "!"}`}
            className={`step ${stepIndex >= 1 ? "step-primary" : ""}`}
          >
            Accepted
          </li>
          <li
            data-content={`${stepIndex >= 2 ? "✓" : "!"}`}
            className={`step ${stepIndex >= 2 ? "step-primary" : ""}`}
          >
            Confirmed
          </li>
          <li
            data-content={`${stepIndex >= 3 ? "✓" : "!"}`}
            className={`step ${stepIndex >= 3 ? "step-primary" : ""}`}
          >
            Completed and Paid
          </li>
        </ul>

        <div className="bg-gray-100 w-full col-span-2 h-[1px]" />

        <div className="col-span-1 uppercase font-semibold mb-2">
          Financial Details
        </div>
        {/* <button className="col-span-1 cursor-pointer text-xs font-productsans-medium text-blue-600 text-right" onClick={toggleModal}>
          View Details
        </button> */}
        <TransactionItemsDrawer
          items={response.items}
          button={
            <button className="col-span-1 cursor-pointer text-xs font-productsans-medium text-blue-600 text-right flex justify-end w-full" onClick={toggleModal}>
              View Details
            </button>
          }
        />


        <div className="col-span-1 text-center font-semibold underline">Subtotal:</div>
        <div className="col-span-1 text-center">${pricetoString(subtotal)}</div>
        <div className="col-span-1 text-center font-semibold underline">Gratuity:</div>
        <div className="col-span-1 text-center">${pricetoString(gratuity)}</div>
        <div className="col-span-1 text-center font-semibold underline">Service Fee:</div>
        <div className="col-span-1 text-center">${pricetoString(service_fee)}</div>
        <div className="col-span-1 text-center font-semibold underline">Tax:</div>
        <div className="col-span-1 text-center">${pricetoString(tax)}</div>
        <div className="col-span-1 text-center font-semibold underline">Total:</div>
        <div className="col-span-1 text-center">${pricetoString(total)}</div>

        <div className="col-span-2 font-semibold uppercase">
          Dietary Requirements
        </div>
        <div className="col-span-2">
          <div className="flex gap-x-2">
            {bid.dietary_preferences.map((preference, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="flex gap-x-2 ring-1 w-fit px-2 py-1 rounded-full"
                  >
                    <h1 className="font-semibold uppercase">
                      {preference.preference}
                    </h1>
                    <p>{preference.percentage}%</p>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end w-full p-2 text-xs">
        <div className="text-right text-white px-3 py-2 rounded-md my-4">
          {
            (account_type === 2 && status === "Accepted") &&
            <div onClick={() => updateStatus('Confirmed')} className="bg-blue-600 px-3 py-2 rounded-lg">
              {loading ?
                <ThreeDots
                  height="30"
                  width="30"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
                :
                'Confirm Order'
              }
            </div>
          }
          {
            (account_type === 1 && status === "Accepted") &&
            <div className="bg-blue-600 px-3 py-2 rounded-lg">
              {loading ?
                <ThreeDots
                  height="30"
                  width="30"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
                :
                'Confirming...'
              }
            </div>
          }
          {
            (account_type === 1 && status === "Confirmed") &&
            <div onClick={() => updateStatus('Completed and Paid')} className="bg-blue-600 px-3 py-2 rounded-lg">
              {loading ?
                <ThreeDots
                  height="30"
                  width="30"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
                :
                'Complete and Pay'
              }
            </div>
          }
          {
            (account_type === 2 && status === "Confirmed") &&
            <div className="bg-blue-600 px-3 py-2 rounded-lg">
              {loading ?
                <ThreeDots
                  height="30"
                  width="30"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
                :
                'Confirming...'
              }
            </div>
          }

          {
            (account_type === 1 && status === "Completed and Paid" || status === "Completed") &&
            <div onClick={() => { status && updateStatus('Confirmed') }} className="bg-green-600 px-3 py-2 rounded-lg">
              {loading ?
                <ThreeDots
                  height="30"
                  width="30"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
                :
                'Order Completed'
              }
            </div>
          }
          {
            (account_type === 2 && status === "Completed and Paid" || status === "Completed") &&
            <div className="bg-green-600 px-3 py-2 rounded-lg">
              {loading ?
                <ThreeDots
                  height="30"
                  width="30"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
                :
                'Order Completed'
              }
            </div>
          }

        </div>
      </div>
    </div>
  );
};

export default SelectedTransaction;
