import React from "react";

export default function NewIcon({ height, color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} height={height} viewBox="0 0 48 48">
            <g>
                <g data-name="Layer 2">
                <path fill="none" d="M0 0H48V48H0z" data-name="invisible box"></path>
                <path
                    d="M42.3 24l3.4-5.1a2 2 0 00.2-1.7 1.8 1.8 0 00-1.2-1.2l-5.9-2.4-.5-5.9a2.1 2.1 0 00-.7-1.5 2 2 0 00-1.7-.3l-6.3 1.3-4.1-4.6a2.2 2.2 0 00-3 0l-4.1 4.6-6.3-1.3a2 2 0 00-1.7.3 2.1 2.1 0 00-.7 1.5l-.5 5.9L3.3 16a1.8 1.8 0 00-1.2 1.2 2 2 0 00.2 1.7L5.7 24l-3.4 5.1a2 2 0 001 2.9l5.9 2.4.5 5.9a2.1 2.1 0 00.7 1.5 2 2 0 001.7.3l6.3-1.3 4.1 4.5a2 2 0 003 0l4.1-4.5 6.3 1.3a2 2 0 001.7-.3 2.1 2.1 0 00.7-1.5l.5-5.9 5.9-2.4a2 2 0 001-2.9zM18 31.1l-4.2-3.2-1.1-.9h-.1l.6 1.4 1.7 4-2.1.8-3.5-8.6 2.1-.8 4.3 3.2 1.1.9a11.8 11.8 0 00-.6-1.3l-1.6-4.1 2.1-.9 3.5 8.6zm3.3-1.3l-3.5-8.7 6.6-2.6.7 1.8-4.4 1.7.6 1.6 3.8-1.6.7 1.7-3.8 1.5.7 1.9 4.5-1.8.7 1.8zm13.9-5.7l-2.6-3.7-.9-1.5h-.1a14.7 14.7 0 01.4 1.7l.8 4.5-2.1.9-5.9-7.7 2.2-.9 2.3 3.3 1.3 2a22.4 22.4 0 01-.4-2.3l-.7-4 2-.8 2.3 3.4 1.2 1.9s-.2-1.4-.4-2.4l-.6-3.9 2.1-.9 1.2 9.6z"
                    data-name="icons Q2"
                ></path>
                </g>
            </g>
        </svg>
    );
}