import { useState, useEffect } from "react";
import Modal from "react-responsive-modal";

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        minWidth: "45%",
        height: '85%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

export default function AdminDeleteDashboard({ deleteModal, setDeleteModal, business }) {

    return (
        <Modal
                blockScroll={false}
            open={deleteModal}
            onClose={() => setDeleteModal(false)}
            animationDuration={400}
            showCloseIcon={false}
            styles={styles}
            center
        >
            <div className="space-y-4 py-4 p-8" >
                <h2 className="border-b-2 pb-2 mb-3 text-base font-productsans-medium text-gray-600">Delete Business User</h2>
                <p> Are you sure you want to delete <span className="font-bold">{business?.business_name}</span>? </p>
                <button className="btn btn-error" onClick={() => alert('Bababooey')}>Delete User</button>
            </div>
        </Modal>
    )
}