import { useState, useEffect } from "react";
import { AdminNavbar, AdminSidebar, AdminTemplateForm } from "../../components/admin";
import { BiddingAPIs } from "../../apis";
import { Template } from "../../components/template";
import { PopoutModal } from "../../components/common";
import { Toaster } from "react-hot-toast";
import AdminTemplateCSVForm from "../../components/admin/templates/AdminTemplateCSVForm";
import { PlusIcon } from "@heroicons/react/outline";

export default function AdminCGETemplates({ parentLoading, collections, getCollections }) {
    const [loading, setLoading] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    var user = JSON.parse(window.localStorage.getItem('admin_user_details'));

    useEffect(() => {
        if (user !== null) {
            getCollections();
        }
    }, [])

    useEffect(() => {
        if (
            parentLoading[7]
        ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [parentLoading])


    async function handleSubmit(data) {
        setLoadingSubmit(true)
        // try {
        const res = await BiddingAPIs.createTemplate(data);
        setLoadingSubmit(false)
        getCollections()
        return true
        // setLoadingSubmit(false)
        // return false
        // }
    }

    return (
        loading ? 
        <div className="flex flex-col space-y-5 w-full h-full items-center justify-center">
        <span className="loading loading-spinner loading-lg"></span>
        <p className="font-productsans-medium">Loading Collections</p>
    </div>
:
            <>
                <div className="grid grid-cols-5 gap-4 overflow-hidden w-full">
                    {collections.map((item, index) => {
                        return (
                            <Template item={item} key={index} page="admin" />
                        );
                    })}
                </div>
                <PopoutModal
                    modalID={'create-collection'}
                    title={"Create Collection"}
                    isOpen={modalOpen}
                    button={
                        <button
                            class="absolute bottom-12 right-10 z-99 flex flex-row items-center space-x-2 
                                text-white px-4 w-auto h-10 bg-blue-600 rounded-full
                                hover:bg-blue-700 active:shadow-lg mouse shadow-xl
                                transition ease-in duration-200 focus:outline-none">
                            <PlusIcon className="h-4" />
                            <span>New Collection</span>
                        </button>
                    }>
                    <AdminTemplateForm handleSubmit={(data) => handleSubmit(data)} loadingSubmit={loadingSubmit} setModalOpen={(e) => setModalOpen(e)} editing={false} />
                </PopoutModal>
            </>
    )
}
