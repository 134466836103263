import axios from "axios";
import {
  main_apiEndpoint,
  cge_apiEndpoint,
  business_apiEndpoint,
} from "./config";
import { getStaffToken, getToken, setToken } from "./Credentials";
import { toast } from "react-hot-toast";
import socketAPI from "./SocketAPIs";


class BiddingAPIService {
  async openBid(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/create-bid/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };

    const API = axios(config);

    await toast.promise(API, {
      loading: "Submitting your bid",
      success: (res) => {
        let response = socketAPI.refreshBids();
        socketAPI.refreshAdminDashboard("bids");
        return "Submitted"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }
  async openRecurringBid(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/create-recurring-bids/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },

    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Submitting your bids",
      success: "Submitted",
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async deleteBid(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/delete-bid/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },

    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Deleting your bid",
      success: (res) => {
        let response = socketAPI.refreshBids();
        socketAPI.refreshAdminDashboard("bids");
        return "Deleted"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async editBid(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/edit-bid/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },

    };

    const API = axios(config);
    return toast.promise(API, {
      loading: "Editing your bid",
      success: (res) => {
        let response = socketAPI.refreshBids();
        socketAPI.refreshAdminDashboard("bids");
        return "Edited"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async closeBid(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/close-bid/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },

    };

    const API = axios(config);
    return toast.promise(API, {
      loading: "Closing your bid",
      success: "Closed",
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async saveBid(payload, account_type) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/save-bid/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },

    };

    if (account_type == 2) {
      console.log(getToken())
      var config = {
        method: "post",
        url: business_apiEndpoint + "account/save-bid/",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },

      };
    }

    const API = axios(config);
    return toast.promise(API, {
      loading: "Saving this bid",
      success: "Saved",
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async unsaveBid(payload, account_type) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/unsave-bid/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },

    };

    if (account_type == 2) {
      console.log(getToken())
      var config = {
        method: "post",
        url: business_apiEndpoint + "account/unsave-bid/",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },

      };
    }

    const API = axios(config);
    return toast.promise(API, {
      loading: "Unsaving this bid",
      success: "Unsaved",
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }


  async getBids() {
    var config = {
      method: "get",
      url: cge_apiEndpoint + "bidder/get-bids/",
    };
    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    return data;
  }

  async adminGetBids() {
    var config = {
      method: "get",
      url: cge_apiEndpoint + "bidder/admin-get-bids/",
    };
    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    return data;
  }

  async getSavedBids() {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/get-saved-bids/",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        // toast.error(error.response.data.detail);
      });
    return data;
  }

  async getBidHistory(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/get-bid-history/",
      data: payload,
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    return data;
  }

  async getResponses() {
    var config = {
      method: "get",
      url: cge_apiEndpoint + "bidder/get-responses-admin/",
      headers: {
        Authorization: "Bearer " + getStaffToken(),
      },
    };
    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        // toast.error(error.response.data.detail);
        return
      });
    return data;
  }

  async getONBids() {
    var config = {
      method: "get",
      url: cge_apiEndpoint + "bidder/get-ordernow-bids/",
    };
    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    return data;
  }

  async getSimilarBids(cgeType, eventType, restaurantType, industry) {
    var config = {
      method: "get",
      url: cge_apiEndpoint + "bidder/get-similar-bids/" +
        "?cge_type=" + cgeType +
        "&event_type=" + eventType +
        "&industry=" + industry +
        "&restaurant_type=" + restaurantType,
    };
    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    return data;
  }

  async getSingleBid(code) {
    var config = {
      method: "get",
      url:
        cge_apiEndpoint + "bidder/get-individual-bid/?transaction_code=" + code,
    };
    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    return data;
  }

  // -----------------------------------------------------------

  async submitBidResponse(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/submit-bid-response/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Submitting your bid response",
      success: (res) => {
        let response = socketAPI.refreshBids();
        socketAPI.refreshAdminDashboard("bids");
        return "Submitted"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async editBidResponse(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/edit-bid-response/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },

    };

    const API = axios(config);

    await toast.promise(API, {
      loading: "Editing your bid response",
      success: (res) => {
        let response = socketAPI.refreshBids();
        let response2 = socketAPI.refreshAdminDashboard("bids");
        return "Your bid response has been sucessfully updated."
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async acceptBidResponse(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/accept-bid-response/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };

    const API = axios(config);
    // let response1 = socketAPI.refreshHeader("response_accept");
    await toast.promise(API, {
      loading: "Accepting bid response",
      success: (res) => {
        socketAPI.refreshBids();
        let response = socketAPI.refreshAdminDashboard("bids");
        // let response2 = socketAPI.refreshTransactions(68, "Accepted");
        return "You have successfully accepted the bid. You will receive an email with additional information."
      },
      error: (err) => {
        console.log(err)
        // return `${err.response.data.detail}`
      },
    });
  }

  async adminAcceptResponse(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/admin-accept-response/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Accepting bid response",
      success: (res) => {
        socketAPI.refreshBids();
        let response = socketAPI.refreshAdminDashboard("bids");
        return "You have successfully accepted the bid. You will recieve an email with additional information."
      },
      error: (err) => {
        console.log(err)
        // return `${err.response.data.detail}`
      },
    });
  }

  async adminCompleteResponse(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/admin-update-transaction/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Accepting bid response",
      success: (res) => {
        socketAPI.refreshBids();
        let response = socketAPI.refreshAdminDashboard("bids");
        return "You have successfully accepted the bid. You will recieve an email with additional information."
      },
      error: (err) => {
        console.log(err)
        // return `${err.response.data.detail}`
      },
    });
  }



  async rejectBidResponse(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/reject-bid-response/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },

    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Rejecting bid response",
      success: "This bid response has been rejected",
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async getAllBidWithResponses(payload) {
    var config = {
      method: "get",
      url: cge_apiEndpoint + "bidder/get-all-bid-with-responses/",
      data: payload,
    };

    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    return data;
  }

  // -----------------------------------------------------------

  async getMyBids(cge_email, business_type, messageFlag) {
    var config = {
      method: "get",
      url:
        cge_apiEndpoint +
        "bidder/get-my-bids/?cge_email=" +
        cge_email +
        "&business_type=" +
        business_type +
        "&include_messages=" +
        messageFlag,

      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };
    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    return data;
  }


  async getMyTransactions(cge_email, business_type) {
    var config = {
      method: "get",
      url:
        cge_apiEndpoint +
        "bidder/get-my-transactions/?cge_email=" +
        cge_email +
        "&business_type=" +
        business_type,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };
    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    return data;
  }

  async updateTransaction(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/update-transaction/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };

    const API = axios(config);

    return await toast.promise(API, {
      loading: "Updating transaction", 
      success: (res) => {
        let response = socketAPI.refreshTransactions(payload.id, payload.status);
        let response2 = socketAPI.refreshAdminDashboard("transactions");
        // return "Transaction updated"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async getCGETransactions(payload) {
    var config = {
      method: "get",
      url: cge_apiEndpoint + "bidder/get-cge-transactions/",
      data: payload,
    };
    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    return data;
  }

  async getTransactionById(transactionId) {
    let id = 0;
    if (typeof (transactionId) === 'object') {
      id = transactionId.id
    } else {
      id = transactionId
    }
    var config = {
      method: "get",
      url: cge_apiEndpoint + `bidder/get-transaction-by-id/?id=${id}`,
    };

    let data = [];
    try {
      const response = await axios(config);
      data = response.data;
      return data;
    } catch (error) {
      toast.error("Something went wrong");
    }
  }

  // -----------------------------------------------------------

  async sendMessage(from, timestamp, message, conversationID, adminflag) {
    // alert(id)
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/send-message/",
      data: {
        from: from,
        timestamp: timestamp,
        message: message,
        conversation_id: conversationID,
        admin: adminflag
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },

    };

    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
        socketAPI.refreshMessages(conversationID)
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    return data;
  }

  async readMessages(convID) {
    // alert(convID)
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/read-messages/",
      data: {
        conversation_id: convID
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },

    };

    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
        socketAPI.refreshMessages(convID)
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    // return data;
  }

  async readAllMessages(convIDs) {
    // alert(convID)
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/read-all-messages/",
      data: {
        conversation_ids: convIDs
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },

    };

    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    // return data;
  }

  async fetchMessages(conversationID) {
    var config = {
      method: "get",
      url:
        cge_apiEndpoint +
        "bidder/fetch-messages/?conversation_id=" +
        conversationID,
      data: {
        conversation_id: conversationID,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };

    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        // toast.error(error.response.data.detail);
      });
    return data;
  }

  async fetchAllMessages(email, userType) {
    var config = {
      method: "get",
      url:
        cge_apiEndpoint +
        "bidder/fetch-all-messages/?email=" +
        email,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };

    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        // toast.error(error.response.data.detail);
      });
    return data;
  }

  async readConversation(convID, msgIndex) {
    var config = {
      method: "post",
      url:
        cge_apiEndpoint +
        "bidder/read-conversation/",
      data: {
        conv_id: convID,
        msg_index: msgIndex
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };

    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        toast.error(error.response.data.detail);
      });
    return data;
  }

  async getUnreadMessages() {
    var config = {
      method: "get",
      url: cge_apiEndpoint + "bidder/get-unread-messages/",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };
    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        // toast.error(error.response.data.detail);
      });
    return data;
  }

  // -----------------------------------------------------------

  async updateViews(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/update-views/",
      data: payload,
    };
    // const API = axios(config);

    await axios(config)
      .catch(function (error) {
      });
  }

  async supportTicket(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/support-ticket/",
      data: payload,
    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Submitting Support Ticket",
      success:
        "Your ticket has been submitted. A member of our team will be in touch shortly. We apologize for the inconvenience.",
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async BidResponseInterested(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/response-interested",
      data: payload,
    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Sending",
      success: "Your bid request has been sucessfully sent to our CGE Team",
      error: (err) => {
        return err?.response?.data;
      },
    });
  }

  async getTemplates() {
    var config = {
      method: "get",
      url: cge_apiEndpoint + "bidder/get-templates/",
    };

    return axios(config);
  }

  async createTemplate(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/create-template/",
      data: payload,
    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Creating Template",
      success: (res) => {
        let response = socketAPI.refreshTemplates();
        socketAPI.refreshAdminDashboard("templates");
        return "Template Created"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async createTemplateCSV(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/create-csv-template/",
      data: payload,
      headers: {
        Authorization: "Bearer " + getStaffToken(),
      },
    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Creating Template",
      success: (res) => {
        let response = socketAPI.refreshTemplates();
        socketAPI.refreshAdminDashboard("templates");
        return "Template Created"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async editTemplate(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/edit-template/",
      data: payload,
    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Editting Template",
      success: (res) => {
        let response = socketAPI.refreshTemplates();
        socketAPI.refreshAdminDashboard("templates");
        return "Saved Template"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async getCgeEmails() {
    var config = {
      method: "get",
      url: cge_apiEndpoint + "authentication/get-cge-emails/",
    };

    return axios(config);
  }

  async orderNowTemplate(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/order-now-template/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };

    const API = axios(config);
    await toast.promise(API, {
      loading: "Submitting Order",
      success: (res) => {
        let response = socketAPI.refreshBids();
        socketAPI.refreshAdminDashboard("ordernow");
        return "Submitted"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async orderNowResponse(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/order-now-response/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };
    const API = axios(config);
    await toast.promise(API, {
      loading: "Submitting Order Responses",
      success: (res) => {
        let response = socketAPI.refreshBids();
        socketAPI.refreshAdminDashboard("ordernow");
        return "Submitted"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async getPrebuiltResponse(payload) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/prebuilt-response/",
      data: payload,
    };

    let data = [];
    await axios(config)
      .then(function (response) {
        data = response.data;
      })
      .catch(function (error) {
        data = "Error"
      });
    return data;
  }

  async deleteBid_Deps(payload) {
    var config = {
      method: "delete",
      url: cge_apiEndpoint + "bidder/delete-bid-deps/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getStaffToken(),
      },

    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Deleting this bid",
      success: (res) => {
        let response = socketAPI.refreshBids();
        socketAPI.refreshAdminDashboard("bids");
        return "Deleted Bid and its dependencies"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async deleteResponse_Deps(payload) {
    var config = {
      method: "delete",
      url: cge_apiEndpoint + "bidder/delete-response-deps/",
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getStaffToken(),
      },

    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Deleting this bid",
      success: (res) => {
        let response = socketAPI.refreshBids();
        socketAPI.refreshAdminDashboard("bids");
        return "Deleted response and its dependencies"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async getSingleResponse(response_id, email, transaction_code) {
    var config = {
      method: "get",
      url: cge_apiEndpoint + "bidder/get-single-response/?response_id=" + response_id + "&email?" + email + "&transaction_code" + transaction_code,
      headers: {
        "Content-Type": "application/json",
      },
    } 
    let data = {};
    await axios(config)
      .then(function (response) {
        data = response.data.response;
      })
      .catch(function (error) {
        toast.error(error);
      });
    return data;
    
  }

  async getCollections() {
    var config = {
      method: "get",
      url: cge_apiEndpoint + "bidder/get-collections/",
    };

    return axios(config);
  }

  async create_collection_and_package(payload) {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: cge_apiEndpoint + 'bidder/create-collection-and-packages/',
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload
    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Creating Collection",
      success: (res) => {
        let response = socketAPI.refreshTemplates();
        socketAPI.refreshAdminDashboard("collections");
        return "Created Collection and its Packages"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async edit_collection_and_package(payload) {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: cge_apiEndpoint + 'bidder/edit-collection-and-packages/',
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload
    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Editing Collection",
      success: (res) => {
        let response = socketAPI.refreshTemplates();
        socketAPI.refreshAdminDashboard("collections");
        return "Edited Collection and its Packages"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

  async delete_collection_and_package(id) {
    var config = {
      method: "post",
      url: cge_apiEndpoint + "bidder/delete-collection-and-packages/",
      data: { collection_id: id },
    };

    const API = axios(config);

    toast.promise(API, {
      loading: "Deleting Collection and Packages",
      success: (res) => {
        let response = socketAPI.refreshTemplates();
        socketAPI.refreshAdminDashboard("collections");
        return "Deleted Collection and Packages"
      },
      error: (err) => {
        return `${err.response.data.detail}`
      },
    });
  }

}

const service = new BiddingAPIService();
export default service;
