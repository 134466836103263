import React from "react";

export default function AndiePromiseOrganizations() {
    const promises = [
        "We will always help you leave your guests speechless with an amazing experience.",
        "We believe in only being early. Your food will always arrive earlier than expected.",
        "Oversharing and checking in often is our thing. We always keep you in the loop.",
        "If something goes wrong, we are responsible. No matter how small it is."
    ];

    return (
        <div className="bg-blue-700 xl:h-[360px] rounded-lg flex p-3 lg:p-8">
            <div className="md:w-[45%] xl:pr-5">
                <h3 className="font-productsans-medium leading-tight text-2xl text-white">Our objective is making sure everything goes off without a hitch. Every time.</h3>
            </div>
            <div className="md:w-[55%] xl:pl-7">
                {promises.map((promise, index) => (
                    <div className="flex mb-7">
                        <div className="bg-blue-200 h-8 w-8 shrink-0 mr-4 rounded-full flex items-center justify-center">
                            <h6 className="text-blue-700 font-roboto-medium text-sm">{index + 1}</h6>
                        </div>
                        <div className="andie-description-text leading-tight w-4/5 text-white">
                            {promise}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}