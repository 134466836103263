import { React, useState, useEffect } from "react";
import catering from "../../assets/images/sidebar/catering.png";
import cateringplus from "../../assets/images/sidebar/cateringplus.png";
import dining from "../../assets/images/sidebar/dining.png"
import diningplus from "../../assets/images/sidebar/diningplus.png";

export default function CGETypeImage({ type, page }) {
    const [img, setImg] = useState('');

    useEffect(() => {
        switch (type) {
            case "Catering":
                setImg(catering)
                break;
            case "Dining":
                setImg(dining)
                break;
            case "Catering Plus":
                setImg(cateringplus)
                break;
            case "Premium Dining":
                setImg(diningplus)
                break;
        }
    }, [type]);

    const renderImage = () => {
        switch (page) {
            case "BidPage":
                return (<img src={img} className="h-9 object-center " />)
            case "Home":
                return (<img src={img} className="h-9 object-center " />)
            case "SelectedBid":
                return (<img src={img} className="h-6 object-center " />)
            case "Messages":
                return (<img src={img} className="h-6 object-center " />)
            case "SimilarBids":
                return (<img src={img} className="h-5 object-center " />)
            case "bidCard":
                return (<img src={img} className="h-6 object-center " />)
        }

    };

    return (
        <div>
            {renderImage()}
        </div>
    )
}