import moment from "moment";
import { useRef, useEffect, useState } from "react";
import { PaperAirplaneIcon } from "@heroicons/react/solid";

import { BiddingAPIs } from "../../apis";
import { WebSocket } from "../sockets";

export default function ChatRender({ conversations, selectedConv, setConversations, page, getUnreadMessages }) {
    let user = JSON.parse(window.localStorage.getItem('admin_user_details'));
    let admin = false
    if (user == null) {
        admin = false
        user = JSON.parse(window.localStorage.getItem('user_details'));
    } else {
        admin = true
    }

    const [conv, setConv] = useState(conversations[selectedConv])
    const [msgLoading, setMsgLoading] = useState(false);
    const [message, setMessage] = useState("");
    const botElm = useRef(null);

    useEffect(() => {
        scrollToBottom();
    }, [conv])

    useEffect(() => {
        setConv(conversations[selectedConv])
        setMessage("")
    }, [selectedConv])

    function scrollToBottom() {
        botElm?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }

    function handleText(e) {
        if (e.key == "Enter" && e.shiftKey == false) {
            e.preventDefault()
            sendMessage()
        }
    }

    async function sendMessage(e) {
        setMsgLoading(true);
        if (message.trim() != "") {
            let newMsg = {}

            newMsg = {
                "from": user.email,
                "timestamp": moment.now(),
                "message": message,
                "sending": true,
                "read": false,
                "admin": admin
            }


            let msgs = conv

            msgs.messages.push(JSON.stringify(newMsg))
            setConv(msgs);
            scrollToBottom();
            setMessage("");
            const res = await BiddingAPIs.sendMessage(newMsg.from, moment.now(), message, conv.id, newMsg.admin)
            setMsgLoading(false);
        }

    }

    async function updateConversation(id) {
        if (id != null) {
            const res = await BiddingAPIs.fetchMessages(id)
            let newConvArr = []
            conversations.map((conversation) => {
                if (conversation.id == id) {
                    conversation.messages = res.messages
                }
                newConvArr.push(conversation)
            })
            setConversations([...newConvArr])
        }
        if (getUnreadMessages != null) {
            getUnreadMessages();
        }

    }

    return (

        <div class="flex flex-row h-full w-full overflow-x-hidden">
            <WebSocket page="chat" refreshFunction={(e) => updateConversation(e)} conv_id={conv.id} />
            <div class="flex flex-col flex-auto h-full p-3">
                <div class="flex flex-col flex-auto flex-shrink-0 rounded-sm bg-gray-50 h-full p-4">
                    <div class="flex flex-col h-full overflow-x-auto mb-4">
                        <div class="flex flex-col h-full">
                            {conv.messages.map((message, index) => {
                                let msg = JSON.parse(message)
                                return (
                                    <>
                                        {user.email === msg.from ?
                                            <div class="chat chat-end mb-2" ref={index + 1 === conv.messages.length ? botElm : null}>
                                                <div class="chat-header font-roboto-medium text-gray-600 text-paragraph">
                                                    You
                                                    <time class="text-xs font-roboto-regular opacity-50 ml-2">{moment(msg.timestamp).fromNow()}</time>
                                                </div>
                                                <div class="chat-bubble my-1.5 andie-description-text text-gray-100 py-2 min-h-[36px]">{msg.message}</div>
                                                <div class="chat-footer text-xs font-roboto-medium opacity-50">
                                                    {msg.sending == true ? "Sending..." : "Delivered"}
                                                </div>
                                            </div>
                                            :
                                            <div class="chat chat-start mb-2" ref={index + 1 === conv.messages.length ? botElm : null}>
                                                <div class="chat-header font-roboto-medium text-gray-600 text-paragraph">
                                                    {msg.from.slice(0,2).toUpperCase()} {msg.admin && "(Pluck)"}
                                                    <time class="text-xs font-roboto-regular opacity-50 ml-2">{moment(msg.timestamp).fromNow()}</time>
                                                </div>
                                                <div class="my-1.5 chat-bubble andie-description-text bg-gray-200 text-gray-700 py-2 min-h-[36px]">{msg.message}</div>
                                                <div class="chat-footer text-xs font-roboto-medium opacity-50">
                                                    Read
                                                </div>
                                            </div>
                                        }
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className="flex w-full">
                        <textarea rows={10} autoFocus onChange={(e) => setMessage(e.target.value)} value={message} onKeyDown={(e) => handleText(e)} type="text" placeholder="Type your message..." class="textarea andie-description-text leading-tight h-20 textarea-bordered focus:outline-none rounded-sm scrollbar-hide w-[90%]" />
                        <button type="button" onClick={() => sendMessage()} class="andie-dark-button ml-5 w-32">
                            <h6 className="pr-2">Send</h6>
                            <PaperAirplaneIcon className="rotate-45 h-4" />
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}