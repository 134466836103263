import { useState, useEffect } from "react";
import Modal from "react-responsive-modal";

import { AccountsAPI } from "../../../apis";
import { AdminBusinessForm } from "..";

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        minWidth: "45%",
        height: '85%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

export default function AdminEditBusiness({ editModal, setEditModal, business, getData, staffAccountList }) {
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [rePassword, setRePassword] = useState("")
    const [businessname, setBusinessname] = useState("")
    const [businessTitle, setBusinessTitle] = useState("")
    const [phone, setPhone] = useState("")
    const [location, setLocation] = useState("");
    const [locationPlaceID, setLocationPlaceID] = useState();
    const [locationGeometry, setLocationGeometry] = useState();
    const [locationAdditionalDetails, setLocationAdditionalDetails] = useState();
    const [initialLocation, setInitialLocation] = useState("");
    const [restaurantLevel, setRestaurantLevel] = useState(4)
    const [restaurantTypes, setRestaurantTypes] = useState("");
    const [businessRep, setBusinessRep] = useState("");
    const [serviceFee, setServiceFee] = useState(0.15);



    useEffect(() => {
        if (business != undefined) {
            setFirstname(business.first_name)
            setLastname(business.last_name)
            setEmail(business.email)
            setBusinessname(business.business_name)
            setBusinessTitle(business.job_title)
            setPhone(business.phone)
            setInitialLocation(business.address_details)
            setRestaurantLevel(business.restaurant_level)
            setRestaurantTypes(business.restaurant_types ? business.restaurant_types : [])
            setBusinessRep(business.business_representative)
            setServiceFee(business.service_fee)
        }
    }, [business])

    function getLocationDetails(loc) {
        var country = loc.filter(item => item.types.includes('country')).map(item => item.short_name)
        var province = loc.filter(item => item.types.includes('administrative_area_level_1')).map(item => item.short_name)
        var city = loc.filter(item => item.types.includes('administrative_area_level_2')).map(item => item.short_name)

        return (city + ', ' + province + " " + country)
    }

    async function editUser(e) {
        e.preventDefault()

        let businessData = {
            first_name: firstname,
            last_name: lastname,
            email: email,
            business_name: businessname,
            phone: phone,
            job_title: businessTitle,
            restaurant_level: restaurantLevel,
            restaurant_types: restaurantTypes,
            restaurant_type : "Fine Dining",
            business_representative: businessRep,
            service_fee: serviceFee
        };

        if (locationPlaceID != null) {
            businessData['place_id'] = locationPlaceID
            businessData['address_details'] = locationAdditionalDetails
        }

        try {
            await AccountsAPI.editBusinessUser(businessData).then(() => window.location.reload());
            // await getData();
            // setEditModal(false)
        } catch (e) {
        }
    }


    return (
        <Modal
            blockScroll={false}
            open={editModal}
            onClose={() => setEditModal(false)}
            animationDuration={400}
            showCloseIcon={false}
            styles={styles}
            center
        >
            <AdminBusinessForm type="edit" 
                staffAccountList={staffAccountList}
                initialLocation={initialLocation} editUser={(e) => editUser(e)} 
                firstname={firstname} setFirstname={(e) => setFirstname(e)} 
                lastname={lastname} setLastname={(e) => setLastname(e)} email={email} 
                setEmail={(e) => setEmail(e)} password={password} setPassword={(e) => setPassword(e)} 
                rePassword={rePassword} setRePassword={(e) => setRePassword(e)} businessname={businessname} 
                setBusinessname={(e) => setBusinessname(e)} phone={phone} setPhone={(e) => setPhone(e)} 
                businessTitle={businessTitle} setBusinessTitle={(e) => setBusinessTitle(e)} location={location} 
                setLocation={(e) => setLocation(e)} locationPlaceID={locationPlaceID} 
                setLocationPlaceID={(e) => setLocationPlaceID(e)} locationGeometry={locationGeometry} 
                setLocationGeometry={(e) => setLocationGeometry(e)} 
                locationAdditionalDetails={locationAdditionalDetails} 
                setLocationAdditionalDetails={(e) => setLocationAdditionalDetails(e)} 
                restaurantLevel={restaurantLevel} setRestaurantLevel={(e) => setRestaurantLevel(e)} 
                restaurantTypes={restaurantTypes} setRestaurantTypes={(e) => setRestaurantTypes(e)} 
                businessRep={businessRep}
                setBusinessRep={(e) => setBusinessRep(e)}
                serviceFee={serviceFee}
                setServiceFee={(e) => setServiceFee(e)}
            />
        </Modal>
    )
}