import React from "react";
import { PopoutModal } from "../../common";
import moment from "moment";
import { BiddingAPIs } from "../../../apis";
import { TrashIcon } from "@heroicons/react/solid";


export default function AdminAcceptResponse({ response }) {

    async function handleAccept(responseID) {
        const res = await BiddingAPIs.adminAcceptResponse({bidResponseID: response.id})
    }

    return (
        <PopoutModal
            title={"Accept Response?"}
            button={
                <div className="cursor-pointer h-8 w-full px-3 bg-blue-100 rounded flex items-center justify-center mt-2">
                    <p className="font-productsans-medium text-blue-600"> Accept Response</p>
                </div>
            }
            modalID={"delete_response_modal"}
            footerElements={
                <button onClick={() => handleAccept()}
                    className="mb-2 cursor-pointer h-9 px-3 bg-blue-600 rounded flex items-center justify-center mt-2">
                    <h6 className="text-xs text-white font-productsans-medium">Yes, Accept Response</h6>
                </button>
            }
        >
            <h6 className="text-xs font-productsans-regular">
                Are you sure you want to accept this response?
            </h6>
            

        </PopoutModal>

    )
}