import { CogIcon } from "@heroicons/react/solid";
import { FaPowerOff } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { BellIcon } from "@heroicons/react/solid";
// import NotificationBar from "./NotificationBar";

function HeaderRightSide({ stripeLink }) {
    const user = JSON.parse(window.localStorage.getItem("user_details"));
    const navigate = useNavigate();

    const navigateLogin = () => {
        navigate("/login");
    };
    
    
    return (
        <div className="hidden md:inline-flex flex-shrink-0 items-center space-x-4 mr-3">
            {stripeLink != false &&
                <>
                    <div className="text-paragraph text-red-700 font-roboto-medium cursor-pointer" onClick={() => window.open(stripeLink)}>
                        Stripe requires action!
                    </div>
                    <div className="bg-gray-300 h-1.5 w-1.5 rounded-full" />
                </>            
            }
            <div className="text-paragraph text-gray-600 font-roboto-medium">
                Greater Toronto Area
            </div>

            <div className="bg-gray-300 h-1.5 w-1.5 rounded-full" />
            {/* <NotificationBar /> */}

            {user != null ?
                <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="cursor-pointer">
                        <div className="flex justify-end">
                            <div className="hover:border-blue-200 border-2 border-blue-100 h-8 w-8 flex items-center justify-center rounded-full">
                                <div className="text-indigo-600 text-xs font-roboto-bold flex justify-center items-center hover:bg-blue-200 h-6 w-6 bg-blue-100 rounded-full">
                                    {user.first_name.charAt(0)}{user.last_name.charAt(0)}
                                </div>
                            </div>
                        </div>
                    </label>
                    <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-4 shadow bg-base-100 rounded-box w-60">
                        <div className="border-b pb-2.5">
                            <p className="text-sm font-productsans-medium">{user.email}</p>
                            <p className="andie-section-descriptor mb-0 text-gray-500">{user.account_type == 2 ? "Partner Account" : user.account_type == 1 ? "Corporate Account" : ""}</p>
                        </div>
                        <div className="mt-2.5 flex flex-col space-y-1.5 cursor-pointer font-productsans-medium text-paragraph text-gray-500">
                            <Link className="flex space-x-2 items-center" to='/account'>
                                <CogIcon className="text-gray-800 h-5 mr-1.5" /> Settings
                            </Link>
                            <Link className="flex space-x-2 items-center" to='/' onClick={() => localStorage.clear()}>
                                <FaPowerOff className="text-gray-800 h-7 ml-0.5 mr-2.5" /> Logout
                            </Link>
                        </div>
                    </ul>
                </div>
                :
                <button type="button" onClick={navigateLogin} className="text-sm -mt-0.5 font-productsans-medium text-blue-600">
                    Log into your account
                </button>
            }
            {/* <div className="andie-dark-button bg-gray-100 text-blue-600 hover:text-white w-2 h-8">
                  Login
              </div> */}
        </div>
    )
}

export default HeaderRightSide