import React, { useEffect } from "react";
import { useState } from "react";
import { dietaryOptions, pricetoString } from "../../common";
import Select from 'react-select';
import { CheckIcon } from "@heroicons/react/solid";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { random } from "lodash";

const PackageItemView = ({ packageItems, setPackageItems, menuOption, refreshItemList, setRefreshItemList, setEditingID }) => {

  function handleRemoveItem(itemID) {
    let tempPackage = packageItems
    let tempMenuOption = packageItems[menuOption].filter(item => item.id !== itemID)

    tempPackage[menuOption] = tempMenuOption

    setPackageItems(tempPackage)
    console.log(tempPackage)
    setRefreshItemList(random(1000))
  }

  function handleEditItem(itemID) {
    setEditingID(-1)
    setEditingID(itemID)
  }

  return (
    packageItems[menuOption].length > 0) ?
    <div key={refreshItemList} className="">
      {packageItems[menuOption].map((item, index) => (
        <div className="border-b border-gray-200 py-3.5 w-full">
          <div className="flex flex-col justify-between w-full">
            <div className="flex flex-row">

              <div className="flex flex-col andie-description-text w-2/3">
                <h6 className="text-gray-700 font-roboto-medium text-paragraph">{item.name} <span className="badge badge-warning px-3 py-2 badge-xs ml-2">{item.required ? "Required" : "Optional"}</span></h6>
                <p className="andie-description-text text-gray-500 leading-tight line-clamp-2">{item.description}</p>
                {/* <p className="mt-3 text-xs font-roboto-medium text-gray-500">{item.menuoption}</p> */}
                <div className="w-3/4 mt-1">
                  <div className="mt-1 space-x-2 max-w-4 flex">
                    {item.restriction?.filter((i) => (i != "None")).length > 0 ?
                      item.restriction?.filter((i) => (i != "None")).map(e =>
                        <div className="flex space-x-1.5 text-xs font-roboto-regular text-gray-500">
                          <CheckIcon className="text-green-500 h-4" />
                          <p>{e}</p>
                        </div>
                      ) :
                      <div className="flex space-x-1.5 text-xs font-roboto-bold text-gray-500"><CheckIcon className="text-green-500 h-4" />No Dietary Requirements</div>
                    }

                  </div>
                </div>

              </div>
              <div className="flex flex-col w-1/3 justify-end items-start">
                <div className="flex flex-col items-end w-full">
                  <p className="font-productsans-medium text-green-700 text-base">${pricetoString(item.price)}</p>
                  <p className="font-productsans-medium justify-center leading-tight ">Serves: {item.serves}</p>
                </div>
                <div className="flex flex-row w-full justify-end items-start space-x-2 mt-2">
                  <div onClick={() => handleEditItem(item.id)} className=" cursor-pointer border p-1 rounded-full border-blue-600 text-blue-600">
                    <PencilIcon className="w-3 h-3" />
                  </div>
                  <div onClick={() => handleRemoveItem(item.id)} className=" cursor-pointer border p-1 rounded-full border-red-500 text-red-500">
                    <TrashIcon className="w-3 h-3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

    </div>
    :
    <div className="andie-description-text text-xs text-gray-400">No {menuOption} added yet.</div>

};

export default PackageItemView;
