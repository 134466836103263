import React from "react";

export default function AccessingInformation() {
    return (
        <div className="andie-description-text">         
            <h1 className="font-productsans-medium text-base mb-3">Accessing and Correcting Your Personal Information</h1>
            <p>&nbsp;</p>
            <p>It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes. By law, you have the right to request access to and to correct the personal information that we hold about you. You can also log into the Site and update your personal information on your dashboard. If you would like us to delete any personal information, please send your request via email at <a href="mailto:privacyandterms@hellopluck.com" class="text-blue-600 visited:text-purple-600">privacyandterms@hellopluck.com</a>.</p>
            <p>&nbsp;</p>
            <p>We may request specific information from you to help us confirm your identity and your right to access, and to provide you with the personal information that we hold about you or make your requested changes. Applicable law may allow or require us to refuse to provide you with access to some or all of the personal information that we hold about you, or we may have destroyed, erased, or made your personal information anonymous in accordance with our record retention obligations and practices. If we cannot provide you with access to your personal information, we will inform you of the reasons why, subject to any legal or regulatory restrictions.</p>
            <p>&nbsp;</p>
        </div>
    )
}