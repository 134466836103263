import React from "react";

export default function DataRetention() {
    return (
        <div className="andie-description-text">
            <h1 className="font-productsans-medium text-base mb-3">Data Retention&nbsp;</h1>
            <p>&nbsp;</p>
            <p>Except as otherwise permitted or required by applicable law, we will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements consistent with and subject to our Terms of Use. Under some circumstances we may anonymize your personal information so that it can no longer be associated with you. We reserve the right to use such anonymous and re-identified data for any legitimate business purpose without further notice to you or your consent.</p>
            <p>&nbsp;</p>
            <p>At such time we become subject to the California Consumer Privacy Act (the &ldquo;Act&rdquo;), the Act will provide California consumer with the right to obtain information about the personal information we collect, use, and disclose. &nbsp;At such time, we will update this Privacy Policy to permit you to exercise your rights through an authorized agent by providing relevant details and submitting a request to us. &nbsp;Regardless, we will not discriminate against you or provide fewer or reduced services because you choose to exercise your privacy rights created by law or set forth herein.</p>
            <p><span>&nbsp;</span></p>
        </div>
    )
}