import React from "react";

export default function TwitterIcon({ height, color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" d="M0 0H24V24H0z"></path>
                <path d="M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2m12.71 6.33A4.77 4.77 0 0019 7.92a4.41 4.41 0 01-1.44.41 2.92 2.92 0 001.12-1.47A5.62 5.62 0 0117 7.5a2.72 2.72 0 00-4.63 2.5 7.65 7.65 0 01-5.52-2.84 2.87 2.87 0 00.79 3.58 2.94 2.94 0 01-1.14-.33 2.68 2.68 0 002.08 2.68 3.35 3.35 0 01-1.14 0A2.67 2.67 0 009.9 15 5.38 5.38 0 016 16.08a7 7 0 004.28 1.23c4.41-.2 7.36-3.31 7.43-7.98z"></path>
            </g>
        </svg>
    )
}