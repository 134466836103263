import React, { useState, useEffect } from "react";
import { CheckIcon, XIcon } from "@heroicons/react/solid";

import { dietaryOptions, industryOptions } from "../../common";

export default function EditEventContact({organization, setOrganization, firstName, setFirstName, lastName, setLastName, contactTitle, setContactTitle, phone, setPhone, email, setEmail, industry, selectIndustry, additionalNotes, setAdditionalNotes }) {

    return (
        <div className="flex flex-col border-b py-5">
            <div className="border-2 rounded h-11 px-3 flex items-center border-gray-100">
                <div className="flex w-36 items-center">
                    <h4 className="text-paragraph font-productsans-medium text-gray-400">Organization</h4>
                    <div className="h-1 w-1 mx-2 bg-gray-200 rounded-full" />
                </div>
                <input
                    className="w-full border-transparent h-8 focus:outline-none rounded-sm px-2 border-0 focus:border-b-2 focus:border-blue-500 my-1 font-roboto-regular text-paragraph text-gray-500"
                    id="email"
                    type="email"
                    required
                    aria-label="Organization"
                    onChange={(e) => setOrganization(e.target.value)}
                    value={organization}
                />
            </div>

            <div className="border-2 rounded h-11 px-3 flex items-center border-gray-100">
                <div className="flex w-36 items-center">
                    <h4 className="text-paragraph font-productsans-medium text-gray-400">Contact Name</h4>
                    <div className="h-1 w-1 mx-2 bg-gray-200 rounded-full" />
                </div>
                <div className="flex w-full space-x-3 justify-between">
                    <input
                        className="w-full px-0 border-transparent h-6 placeholder-gray-300 focus:outline-none border-0 focus:border-b-2 focus:border-blue-500 my-1 font-roboto-regular text-paragraph text-gray-500"
                        id="first_name"
                        type="text"
                        required
                        aria-label="First Name"
                        placeholder="First name"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                    />
                    <input
                        className="w-full px-0 border-transparent h-6 placeholder-gray-300 focus:outline-none border-0 focus:border-b-2 focus:border-blue-500 my-1 font-roboto-regular text-paragraph text-gray-500"
                        id="contact_name"
                        type="text"
                        required
                        aria-label="Last Name"
                        placeholder="Last name"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                    />
                </div>
            </div>

            <div className='md:flex md:space-x-3'>
                <div className="border-2 md:w-1/2 rounded h-11 px-3 flex items-center border-gray-100">
                    <div className="flex w-12 items-center">
                        <h4 className="text-paragraph font-productsans-medium text-gray-400">Title</h4>
                        <div className="h-1 w-1 mx-2 bg-gray-200 rounded-full" />
                    </div>
                    <input
                        className="w-full px-0 border-transparent h-6 focus:outline-none border-0 focus:border-b-2 focus:border-blue-500 my-1 font-roboto-regular text-paragraph text-gray-500"
                        id="title"
                        type="text"
                        required
                        aria-label="Title"
                        onChange={(e) => setContactTitle(e.target.value)}
                        value={contactTitle}
                    />
                </div>
                <div className="border-2 md:w-1/2 md:mt-0 mt-4 rounded h-11 px-3 flex items-center border-gray-100">
                    <div className="flex w-20 items-center">
                        <h4 className="text-paragraph font-productsans-medium text-gray-400">Phone</h4>
                        <div className="h-1 w-1 mx-2 bg-gray-200 rounded-full" />
                    </div>
                    <input
                        className="w-full px-0 border-transparent h-6 focus:outline-none border-0 focus:border-b-2 focus:border-blue-500 my-1 font-roboto-regular text-paragraph text-gray-500"
                        id="phone_number"
                        type="tel"
                        required
                        aria-label="Phone Number"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                    />
                </div>
            </div>

            <div className="border-2 rounded h-11 px-3 flex items-center border-gray-100">
                <div className="flex w-36 items-center">
                    <h4 className="text-paragraph font-productsans-medium text-gray-400">Email Address</h4>
                    <div className="h-1 w-1 mx-2 bg-gray-200 rounded-full" />
                </div>
                <input
                    className="w-full px-0 border-transparent h-6 focus:outline-none border-0 focus:border-b-2 focus:border-blue-500 my-1 font-roboto-regular text-paragraph text-gray-500"
                    id="email"
                    type="email"
                    required
                    aria-label="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
            </div>

            <div className="border-2 rounded h-11 w-full px-3 flex items-center border-gray-100">
                <div className="flex w-28 items-center">
                    <h4 className="text-paragraph font-productsans-medium text-gray-400">Industry</h4>
                    <div className="h-1 w-1 mx-2 bg-gray-200 rounded-full" />
                </div>
                <select id="industry_type" onChange={(e) => selectIndustry(e.target.value)} className="w-full px-0 placeholder-gray-300 border-transparent focus:outline-none focus:border-0 h-8 -mt-[1px] font-roboto-regular text-paragraph text-gray-500">
                    {industryOptions.map((item, index) => (
                        <option selected={item.name === industry}>{item.name}</option>
                    ))}
                </select>
            </div>

            <div>
                <h6 className="bid-form-input-label mt-6">Please add any additional notes about your bid</h6>
                <textarea
                    className="w-full h-32 bid-form-input"
                    id="cus_notes"
                    onChange={(e) => setAdditionalNotes(e.target.value)}
                    type="text"
                    placeholder="If you have any additional notes or requirements, please let us know here."
                    aria-label="Name"
                    defaultValue={additionalNotes}
                />
            </div>
        </div >
    )
}