import React from "react";

export default function WithdrawingConsent() {
    return (
        <div className="andie-description-text">
            <h1 className="font-productsans-medium text-base mb-3">Withdrawing Your Consent&nbsp;</h1>
            <p>&nbsp;</p>
            <p>Where you have provided your consent to the collection, use and transfer of your personal information, you may have the legal right to withdraw your consent under certain circumstances. To withdraw your consent, contact us at <a href="mailto:privacyandterms@hellopluck.com" class="text-blue-600 visited:text-purple-600">privacyandterms@hellopluck.com</a>. &nbsp;Please note that if you withdraw your consent we may not be able to provide you with a particular product or service. We will explain the impact to you at the time to help you with your decision.</p>
            <p>&nbsp;</p>
            <h1 className="font-productsans-medium text-base mb-3">Changes to Our Privacy Policy</h1>
            <p>&nbsp;</p>
        </div>
    )
}
    
            