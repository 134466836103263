import React, { useEffect, useState } from "react";

import { AccountsAPI } from "../../../apis";

export default function AdminEditCGEAccount({ account }) {
    const [organization, setorganization] = useState(account.organization);
    const [first_name, setfirst_name] = useState(account.first_name);
    const [last_name, setlast_name] = useState(account.last_name);
    const [phone, setphone] = useState(account.phone);
    const [email, setemail] = useState(account.email);
    const [serviceFee, setServiceFee] = useState(account.service_fee);

    async function handleEditUser() {
        const payload = {
            organization: organization,
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: phone,
            service_fee: serviceFee
        }

        await AccountsAPI.editCGEUser(payload)
    }

    return (
        <form method="dialog" className="modal-box p-6" onSubmit={handleEditUser}>
            <h3 className="font-productsans-medium text-base mb-5">Edit User</h3>
            <div className="space-y-4">
                <div>
                    <input type="text" onChange={(e) => setorganization(e.target.value)} defaultValue={organization} className="bid-form-input" />
                </div>

                <div className="grid grid-cols-2 gap-4">
                    <input type="text" onChange={(e) => setfirst_name(e.target.value)} defaultValue={first_name} className="bid-form-input" />
                    <input type="text" onChange={(e) => setlast_name(e.target.value)} defaultValue={last_name} className="bid-form-input" />
                </div>

                <div>
                   
                    <input type="tel" onChange={(e) => setphone(e.target.value)} defaultValue={phone} className="bid-form-input w-full" />
                    <input disabled type="email" onChange={(e) => setemail(e.target.value)} defaultValue={email} className="mt-4 input w-full rounded-sm" />
                </div>
                <div>
                    <label className="input-group w-full">
                        <span className="bid-form-input-label leading-tight mb-0">Service Fee</span>
                        <input max={100} min={0} step={1}  type="number" onWheel={(e) => e.target.blur()}  onChange={(e) => setServiceFee(e.target.value/100)} defaultValue={serviceFee*100} className="bid-form-input w-full" />
                        <span className="bid-form-input-label leading-tight mb-0">%</span>
                    </label>
                </div>
            </div>

            <div className="modal-action">
                <button type="submit" className="btn h-8 font-productsans-medium text-xs">Update User</button>
            </div>
        </form>
    )
}