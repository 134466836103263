import React, { useEffect, useState } from "react";
import { PencilAltIcon, PlusIcon } from "@heroicons/react/outline";
import { AdminCreateStaffAccount, AdminEditStaffAccount, AdminDeleteStaffAccount } from '../../components/admin';
import { PopoutModal } from "../../components/common";


export default function AdminAdminAccounts({ parentLoading, accounts }) {
    const [loading, setLoading] = useState(true);
    const [createModal, setCreateModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(0)

    useEffect(() => {
        if (
            parentLoading[0]
        ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [parentLoading])

    console.log(accounts)

    return (
        <div className="h-full overflow-scroll scrollbar-hide">
            {loading ? <>
                <div className="flex flex-col space-y-5 w-full h-full items-center justify-center">
                    <span className="loading loading-spinner loading-lg"></span>
                    <p className="font-productsans-medium">Loading Staff Accounts</p>
                </div>

            </>
                :
                <>
                    <AdminEditStaffAccount setEditModal={(e) => setEditModal(e)} editModal={editModal} staffAccount={accounts[selectedAccount]} />
                    <AdminDeleteStaffAccount setDeleteModal={(e) => setDeleteModal(e)} deleteModal={deleteModal} staffAccount={accounts[selectedAccount]} />
                    <table className="table table-pin-rows table-pin-cols w-full overflow-scroll scrollbar-hide">
                        {/* head */}
                        <thead>
                            <tr>
                                {/* <th>ID</th> */}
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Dept.</th>
                                <th>Role</th>
                                <th>Level</th>
                                <th>Modify</th>
                            </tr>
                        </thead>
                        <tbody>
                            {accounts.length > 0 ?
                                accounts.map((account, index) => (
                                    <tr className="font-roboto-regular text-paragraph h-10">

                                        {/* <th>{account.id}</th> */}
                                        <th className="w-[20%]">{account.first_name} {account.last_name}</th>
                                        <td className="w-[25%]">{account.email}</td>
                                        <td className="w-[10%]">{account.phone}</td>
                                        <td className="w-[20%]">{account.department}</td>
                                        <td className="w-[10%]">{account.role}</td>
                                        <td className="w-[10%]">{account.admin_level}</td>
                                        <td className="w-[5%]">
                                            <button className="w-full hover:bg-gray-200 p-1 rounded-md text-blue-600" onClick={() => { setSelectedAccount(index); setEditModal(true) }}><PencilAltIcon /></button>
                                        </td>
                                    </tr>
                                ))
                                :
                                <></>
                            }
                        </tbody>
                    </table>
                    <PopoutModal
                        button={
                            <button
                                class="absolute bottom-12 right-10 z-99 flex flex-row items-center space-x-2 
                    text-white px-4 w-auto h-10 bg-blue-600 rounded-full
                    hover:bg-blue-700 active:shadow-lg mouse shadow-xl
                    transition ease-in duration-200 focus:outline-none">
                                <PlusIcon className="h-4" />
                                <span>New Staff User</span>
                            </button>
                        }
                        modalID={"refLink"}
                        title={"Create Staff Account"}
                    >
                        <AdminCreateStaffAccount setCreateModal={(e) => setCreateModal(e)} createModal={createModal} />
                    </PopoutModal>
                </>}

        </div>
    )
}