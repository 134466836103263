import React from 'react'

const ItemView = ({func, item, filters}) => {
    return (
        <div
            type="button"
            onClick={() => func(item.name)}
            className={`bg-blue-100 cursor-pointer flex leading-tight items-center text-center justify-center font-productsans-medium lg:text-paragraph text-xs text-black h-9 m-0 p-3 rounded-sm modal-action ${filters.localFilters?.event === item.name ? 'bg-blue-300' : ''
                }`}
        >
            {item.name}
        </div>
    )
}

export default ItemView