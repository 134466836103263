import React from 'react';

export default function BidAdditionalNotes({ selectedAdditonalNotes, setSelectedTab }) {    
    return (
        <div className="h-full mx-3 mb-4 rounded">
            <div className="flex flex-col p-4 w-[70%]">
                <div className="w-1/2">
                    <h1 className="text-sm font-productsans-medium text-gray-300">Additional Notes</h1>
                </div>

                <div className="w-4/5 mt-2 h-44">
                    <textarea
                        className="w-full h-full andie-description-text leading-tight text-gray-500 border-2 rounded border-gray-100"
                        id="cus_email"
                        onChange={(e) => selectedAdditonalNotes(e.target.value)}
                         type="number" onWheel={(e) => e.target.blur()} 
                        required
                        placeholder="Please tell us anything else we need to know..."
                        aria-label="Name"
                    />
                </div>
            </div>
            <div className="flex justify-end flex-end mt-3 mb-5 bottom-0 mx-4">
                <button onClick={() => setSelectedTab("Additional Notes")} className="bg-blue-600 text-xs font-productsans-medium text-white h-8 w-16 rounded px-2.5">Next</button>
            </div>
        </div>
    )
}