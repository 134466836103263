import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Toaster, toast } from "react-hot-toast";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import {
    BidAbout, BidBudget, BidConfirmation, BidContact, BidDetails,
    BidDietaryRequirements, BidPreferences
} from ".";
import { AccountsAPI, BiddingAPIs } from "../../../apis";
import { MenuButtons, bidTabs } from "../../common";
import { SaveIcon } from "../../../assets/icons";

export default function BiddingForm({ setDrafts, selectedDraft, user, savedBid, item, setCurrentStep }) {
    const [selectedTab, setSelectedTab] = useState("About");
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
    const [step, setStep] = useState(0);

    // const [business, setBusiness] = useState(user.businessname);
    const [organization, setOrganization] = useState(user != null ? user.organization : "");
    const [industry, setIndustry] = useState(user != null ? user.industry : "Banking");

    const [bidTitle, setBidTitle] = useState(item.template_title);
    const [bidDescription, setBidDescription] = useState(item.template_description);
    const [eventType, setEventType] = useState("Office Breakfast");

    const [guestCount, setGuestCount] = useState(item.min_guests == undefined ? 0 : item.min_guests);
    const [cgeType, setCGEType] = useState(item.cge_type == null ? "Catering" : item.cge_type);
    const [restaurantRating, setRestaurantRating] = useState("Any");

    const [location, setLocation] = useState("");
    const [locationPlaceID, setLocationPlaceID] = useState("");
    const [locationGeometry, setLocationGeometry] = useState("");
    const [locationAdditionalDetails, setLocationAdditionalDetails] = useState("");
    const [locationSecondaryDetails, setLocationSecondaryDetails] = useState("");

    const [eventDateTime, setEventDateTime] = useState(moment(moment().toDate()).add(1, "d").add(60, 'minute').startOf('hour').toDate());
    const [deliveryTime, setDeliveryTime] = useState(moment(moment().toDate()).add(1, "d").add(60, 'minute').startOf('hour').toDate());
    const [endDateTime, setEndDateTime] = useState(moment(moment().toDate()).add(8, "d").add(60, 'minute').startOf('hour').toDate());
    const [recurringPeriod, setRecurringPeriod] = useState('month')
    const [recurring, setRecurring] = useState(false)

    const [restaurantType, setRestaurantType] = useState((item?.cuisine_type?.length == 0 || item.cuisine_type == null) ? ['Any'] : item.cuisine_type);
    const [dietaryPreferences, setDietaryPreferences] = useState([]);
    const [dietaryNotes, setDietaryNotes] = useState("");
    const [menuOptions, setMenuOptions] = useState(item.menu_options == undefined ? [] : item.menu_options)
    const [packagingType, setPackagingType] = useState(["Any"]);

    const [currency, setCurrency] = useState("CAD");
    const [budget, setBudget] = useState({ perHead: 100, total: 500, tipAmount:0 });
    const [tipStatus, setTipStatus] = useState(0);
    const [paymentTerms, setPaymentTerms] = useState("Prepaid in full");

    const [firstName, setFirstName] = useState(user != null ? user.first_name : "");
    const [lastName, setLastName] = useState(user != null ? user.last_name : "");
    const [email, setEmail] = useState(user != null ? user.email : "");
    const [phone, setPhone] = useState(user != null ? user.phone : "");
    const [contactTitle, setContactTitle] = useState(user != null ? user.job_title : "");

    const [expiryDate, setExpiryDate] = useState(moment(moment()).toDate());
    const [accessibility, setAccessibility] = useState({ "Sidewalk": false, "Parking": false, "Stairs": false, "Elevators": false })

    const [additionalNotes, setAdditionalNotes] = useState(item.custom_items ? makeJSONPretty(item.custom_items) : "");
    const [requiresChafing, setRequiresChafing] = useState(false);
    const [requiresStaffing, setRequiresStaffing] = useState(false);

    const [find, setFind] = useState("Select an option");
    const [comments, setComments] = useState("");
    const navigate = useNavigate();


    useEffect(() => {
        if (item.custom_items) {
            let options = Object.keys(item.custom_items)
            setMenuOptions(options);
        }
    }, []);

    useEffect(() => {
        setExpiryDate(moment(moment(eventDateTime).toDate()).add(-4, "h").toDate())
    }, [eventDateTime]);

    useEffect(() => {
        if (selectedDraft) {
            setBidTitle(selectedDraft.bidTitle); setBidDescription(selectedDraft.bidDescription); setCGEType(selectedDraft.cgeType); setEventType(selectedDraft.eventType); setEventDateTime(moment(selectedDraft.eventDateTime).add(1, "d").add(60, 'minute').startOf('hour').toDate()); setGuestCount(selectedDraft.guestCount); setLocation(selectedDraft.location); setLocationPlaceID(selectedDraft.locationPlaceID); setLocationGeometry(selectedDraft.locationGeometry); setLocationAdditionalDetails(selectedDraft.locationAdditionalDetails); setLocationSecondaryDetails(selectedDraft.locationSecondaryDetails); setExpiryDate(moment(selectedDraft.expiryDate).add(1, "d").add(60, 'minute').startOf('hour').toDate()); setEndDateTime(moment(selectedDraft.endDateTime).add(1, "d").add(60, 'minute').startOf('hour').toDate()); setRecurringPeriod(selectedDraft.recurringPeriod); setRecurring(selectedDraft.recurring); setBudget(selectedDraft.budget); setCurrency(selectedDraft.currency); setPaymentTerms(selectedDraft.paymentTerms); setRestaurantType(selectedDraft.restaurantType); setRestaurantRating(selectedDraft.restaurantRating); setDietaryPreferences(selectedDraft.dietaryPreferences); setDietaryNotes(selectedDraft.dietaryNotes); setMenuOptions(selectedDraft.menuOptions); setAccessibility(selectedDraft.accessibility); setPackagingType(selectedDraft.packagingType); setAdditionalNotes(selectedDraft.additionalNotes); setDeliveryTime(moment(selectedDraft.deliveryTime).add(1, "d").add(60, 'minute').startOf('hour').toDate()); setRequiresChafing(selectedDraft.requiresChafing); setRequiresStaffing(selectedDraft.requiresStaffing);
        }
    }, [selectedDraft])

    useEffect(() => {
        if (selectedTab == 'About') {
            setCurrentStep(1)
        } else if (selectedTab == 'Bid Details') {
            setCurrentStep(2)
        } else if (selectedTab == 'Preferences') {
            setCurrentStep(3)
        } else if (selectedTab == 'Dietary Requirements') {
            setCurrentStep(4)
        } else if (selectedTab == 'Budget') {
            setCurrentStep(5)
        } else if (selectedTab == 'Contact') {
            setCurrentStep(6)
        } else if (selectedTab == 'Confirmation') {
            setCurrentStep(7)
        }
    }, [selectedTab])

    useEffect(() => {
        if (cgeType == "Dining" || cgeType == "Premium Dining") {
            setAccessibility({
                "Parking": false,
                "Accessible": false,
                "Transit": false,
                "Ride Share": false
            })
        } else {
            setAccessibility({
                "Sidewalk": false,
                "Parking": false,
                "Stairs": false,
                "Elevators": false,
                "Carts": false,
                "Food Trolly": false,
                "Service Entrance": false
            })
        }
    }, [cgeType])

    function makeJSONPretty(obj) {
        var tempstr = "Customized Items:"
        for (let index = 0; index < Object.keys(obj).length; index++) {
            const menuOption = Object.keys(obj)[index]
            tempstr += "    " + menuOption + ":"

            var innerStuff = obj[menuOption]
            for (let jndex = 0; jndex < Object.keys(innerStuff).length; jndex++) {
                var foodItem = Object.keys(innerStuff)[jndex]
                var qty = obj[menuOption][foodItem].quantity
                var included = obj[menuOption][foodItem].included
                if (included) {
                    tempstr += foodItem + " x" + qty + ", "
                }
            }
            tempstr = tempstr.slice(0, -2)
            tempstr += '      '
        }
        return tempstr
    }

    function shafingNotes() {
        const shaffing_note = '\nThis bid requires a chafing dish to be provided.';
        setRequiresChafing(!requiresChafing);

        if (requiresChafing) {
            setAdditionalNotes(additionalNotes + shaffing_note);
        } else {
            setAdditionalNotes(additionalNotes.replace(shaffing_note, ""));
        }

    }

    function transactionCode(length) {
        var result = '';
        // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!%$#?&';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }

        return result;
    }

    const menuButtonOptions = [
        'About',
        'Bid Details',
        'Preferences',
        'Dietary Requirements',
        'Budget',
        'Contact',
        'Confirmation',
    ]


    const submitBid = async () => {
        // converting restaurant type list to a simple array for saving in the backend instead of a dictionary
        const restaurant_type_list = []
        restaurantType.forEach(element => {
            restaurant_type_list.push(element)
        });
        
        budget.total = budget.perHead * guestCount;
        budget.tipAmount = tipStatus/100;

        var payloadRecurring = recurringPeriod
        if (!recurring) {
            payloadRecurring = 'none'
        }

        //if statement
        if (!bidTitle || !bidDescription || !location || isEmpty(dietaryPreferences)) {
            if (!bidTitle) {
                toast.error("Bid Title needs to be filled!")
            }
            if (!bidDescription) {
                toast.error("Bid description needs to be filled.")
            }
            if (!location) {
                toast.error("Location needs to be filled.")
            }
            if (isEmpty(dietaryPreferences)) {
                toast.error("Dietary Preferences needs to be filled.")
            }
        } else {

            const payload = {
                active: true,
                organization: organization,
                industry: industry,
                cge_email: user != null ? user.email : email,

                //contact information
                contact_first_name: firstName,
                contact_last_name: lastName,
                contact_title: contactTitle,
                contact_phone: phone,
                contact_email: email,

                //basic bid information
                bid_title: bidTitle,
                bid_description: bidDescription,
                cge_type: cgeType,
                event_type: eventType,

                // logistics
                event_date_time: eventDateTime,
                guest_count: guestCount,
                location: location,
                locationPlaceID: locationPlaceID,
                locationGeometry: locationGeometry,
                locationAdditionalDetails: locationAdditionalDetails,
                locationSecondaryDetails: locationSecondaryDetails,

                expiry_date: expiryDate,
                end_date: endDateTime,
                recurring_period: recurringPeriod,
                // recurring: recurring,

                // financial details
                budget: budget,
                currency: currency,
                payment_terms: paymentTerms,

                //restaurant selection
                restaurant_type: restaurant_type_list,

                restaurant_rating: restaurantRating,
                dietary_preferences: dietaryPreferences,
                dietary_notes: dietaryNotes,
                menu_options: menuOptions,
                accessibility: accessibility,
                packaging_type: packagingType,

                //confirmation code
                transaction_code: transactionCode(6),

                additional_notes: additionalNotes,
                cge_representative: user != null ? user.cge_representative : 'No Representative',
                requires_chafing: requiresChafing,
                requires_staffing: requiresStaffing,
                delivery_time: deliveryTime

            }
            
            if (user != null) {
                if (recurring) {
                    await BiddingAPIs.openRecurringBid(payload).then((res) => {
                        savedBid();
                    }).catch(() => {
                        // alert('Could not create your bid. Try again!')
                    });
                } else {
                    await BiddingAPIs.openBid(payload).then((res) => {
                        savedBid();
                    }).catch(() => {
                    });
                }
            } else {
                const requestPayload = {
                    organization: organization,
                    first_name: firstName,
                    last_name: lastName,
                    title: contactTitle,
                    email: email,
                    industry: industry,
                    phone: phone,
                    find: find,
                    comments: comments,
                    bid_payload: payload
                }

                await AccountsAPI.requestAccessCode(requestPayload).then((res) => {
                    navigate('/success-request');
                })
            }
        }
    }

    const saveDraft = async () => {
        const draft = { bidTitle, bidDescription, cgeType, eventType, eventDateTime, guestCount, location, locationPlaceID, locationGeometry, locationAdditionalDetails, locationSecondaryDetails, expiryDate, endDateTime, recurringPeriod, recurring, budget, currency, paymentTerms, restaurantType, restaurantRating, dietaryPreferences, dietaryNotes, menuOptions, accessibility, packagingType, additionalNotes, deliveryTime, requiresChafing, requiresStaffing }

        // Retrieve existing drafts from local storage
        const existingDrafts = JSON.parse(localStorage.getItem('drafts')) || [];

        // Add the new draft to the array
        const newDrafts = [...existingDrafts, draft];

        // Save the updated drafts array to local storage
        localStorage.setItem('drafts', JSON.stringify(newDrafts));
        setDrafts(newDrafts);
    }

    return (
        <>
            <div className="h-full flex flex-col w-full">
                {bidTabs.map((item, index) => (
                    <>
                        {item.title === selectedTab &&
                            <div className="h-12 flex justify-between">
                                <div>
                                    {selectedTab !== "Confirmation" ?
                                        <h6 className="text-xs font-roboto-medium text-gray-400 uppercase">Step {index + 1} of {bidTabs.length - 1}.</h6>
                                        :
                                        <h6 className="text-xs font-roboto-medium text-gray-400 uppercase">Final Step.</h6>
                                    }
                                    <h6 className="text-base font-productsans-medium text-gray-700">{item.explanation}</h6>
                                </div>
                                <div onClick={() => saveDraft()} className="inline-flex space-x-1.5 items-center rounded-sm bg-gray-100 h-8 px-2.5 hover:bg-blue-100 cursor-pointer">
                                    <SaveIcon height={14} color="#2563eb" />
                                    <p className="font-productsans-medium text-paragraph text-blue-600">Save draft</p>
                                </div>
                            </div>
                        }
                        
                    </>
                ))}
                {/* [calc(100%_-_48px)] */}
                <div className="h-[74%] w-full pt-3 pb-10 md:pb-0 md:h-full overflow-y-scroll scrollbar-hide">
                    {selectedTab === 'About' && <BidAbout bidTitle={bidTitle} bidDescription={bidDescription} setBidTitle={setBidTitle} setBidDescription={setBidDescription} cgeType={cgeType} setCGEType={setCGEType} setSelectedTab={setSelectedTab} item={item} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} />} {/* Item implemented! */}

                    {selectedTab === 'Bid Details' && <BidDetails guestCount={guestCount} location={location} setGuestCount={setGuestCount} eventType={eventType} setEventType={setEventType} setLocation={setLocation} setLocationPlaceID={setLocationPlaceID} setLocationGeometry={setLocationGeometry} setLocationAdditionalDetails={setLocationAdditionalDetails} cgeType={cgeType} eventDateTime={eventDateTime} setEventDateTime={setEventDateTime} setSelectedTab={setSelectedTab} item={item} accessibility={accessibility} setAccessibility={setAccessibility} endDateTime={endDateTime} setEndDateTime={setEndDateTime} recurringPeriod={recurringPeriod} setRecurringPeriod={setRecurringPeriod} recurring={recurring} setRecurring={setRecurring} setLocationSecondaryDetails={setLocationSecondaryDetails} locationSecondaryDetails={locationSecondaryDetails} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} deliveryTime={deliveryTime} setDeliveryTime={setDeliveryTime} />} {/* Item implemented! */}

                    {selectedTab === 'Preferences' && <BidPreferences setRestaurantType={setRestaurantType} setMenuOptions={setMenuOptions} setRestaurantRating={setRestaurantRating} restaurantRating={restaurantRating} restaurantType={restaurantType} menuOptions={menuOptions} setSelectedTab={setSelectedTab} item={item} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} />}

                    {selectedTab === 'Dietary Requirements' && <BidDietaryRequirements dietaryPreferences={dietaryPreferences} setDietaryPreferences={setDietaryPreferences} dietaryNotes={dietaryNotes} setDietaryNotes={setDietaryNotes} setSelectedTab={setSelectedTab} packagingType={packagingType} setPackagingType={setPackagingType} cge_type={cgeType} requiresChafing={requiresChafing} setRequiresChafing={setRequiresChafing} requiresStaffing={requiresStaffing} setRequiresStaffing={setRequiresStaffing} shafingNotes={shafingNotes} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} />}

                    {selectedTab === 'Budget' && <BidBudget cgeType={cgeType} tipStatus={tipStatus} setTipStatus={setTipStatus} currency={currency} budget={budget} paymentTerms={paymentTerms} setPaymentTerms={setPaymentTerms} guestCount={guestCount} setBudget={setBudget} setCurrency={setCurrency} setSelectedTab={setSelectedTab} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} />}

                    {selectedTab === 'Contact' && <BidContact comments={comments} setComments={(e) => setComments(e)} submitBid={submitBid} firstName={firstName} lastName={lastName} email={email} phone={phone} contactTitle={contactTitle} setFirstName={setFirstName} setLastName={setLastName} setEmail={setEmail} setContactTitle={setContactTitle} setPhone={setPhone} industry={industry} setIndustry={setIndustry} setSelectedTab={setSelectedTab} additionalNotes={additionalNotes} setAdditionalNotes={setAdditionalNotes} organization={organization} setOrganization={setOrganization} find={find} setFind={setFind} requiresChafing={requiresChafing} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} />}

                    {selectedTab === 'Confirmation' && <BidConfirmation cgeType={cgeType} firstName={firstName} budget={budget} guestCount={guestCount} setCGEType={setCGEType} expiryDate={expiryDate} eventDateTime={eventDateTime} setExpiryDate={setExpiryDate} setSelectedTab={setSelectedTab} submitBid={submitBid} isNextButtonDisabled={isNextButtonDisabled} setIsNextButtonDisabled={setIsNextButtonDisabled} step={step} setStep={setStep} func={setSelectedTab} menuButtonOptions={menuButtonOptions} />}
                </div>
                {selectedTab != 'Confirmation' && <MenuButtons func={setSelectedTab} check={isNextButtonDisabled} step={step} setStep={setStep} menuButtonOptions={menuButtonOptions} />}
            </div>
            
        </>
    )
}