import React, { useState, useEffect } from "react";
import { TransactionDetails } from "../../components/transactions";
import { LoadingIcon, getTransactionAmounts, pad, pricetoString } from "../../components/common";
import loadingtransactionsgif from '../../assets/animations/loading-transactions.gif';
import { Drawer } from "../../components/common";
import { useParams } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { AccountsAPI } from "../../apis";

export default function TransactionsDashboard({ transactions, loading, selectedTransaction, setSelectedTransaction }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [selectedTab, setSelectedTab] = useState("Transactions");
    const [showDrawer, setShowDrawer] = useState(false);
    const [staffAccountList, setStaffAccountList]= useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    let route = useParams();

    useEffect(() => {
        const routeSplit = route["*"].split('/').filter((str) => str !== '')
        if (routeSplit.length > 1) {
            let temp = transactions.filter(trans => trans.id == routeSplit[1])
            if (temp.length > 0) {
                selectTransaction(temp[0], 0)
            }
        }
        
    }, [route, transactions]);

    useEffect(() => {
        const getStaffAcc = async() => {
            var res = await AccountsAPI.getStaffAccounts();
            setStaffAccountList(res)
        }
        getStaffAcc();
    }, [])

    function background(status) {
        if (status === "Accepted") {
            return 'bg-yellow-50 text-gray-700'
        }

        if (status === "Pending") {
            return 'bg-gray-100 text-gray-700'
        }

        if (status === "Confirmed") {
            return 'bg-sky-50 text-sky-700'
        }

        if (status === "Completed") {
            return 'bg-green-50 text-green-700'
        }
    }

    function selectTransaction(item, index) {
        setShowDrawer(true)
        setSelectedTransaction(item);
        setSelectedIndex(index)
    }

    return (
        <div className="relative h-full">
            <Drawer
                drawerID={'transaction_drawer'}
                // button={<ChevronRightIcon className={`${pad(item.id, 5) === pad(selectedTransaction.id, 5) && "text-white"} h-5 text-blue-600`} />}
                width={30}
                stateOverride={showDrawer}
                setStateOverride={setShowDrawer}
                title={"Transaction Details"}>
                {loading ?
                    <div className="h-full flex items-center justify-center">
                        <div className="flex flex-col items-center">
                            <img src={loadingtransactionsgif} className="h-20 w-20 text-center" alt="" />
                            <p className="mt-3 tex-center text-green-600 text-xs font-sf-medium">Loading your transaction records</p>
                            <p className="text-center text-gray-400 text-tiny font-sf-regular">Please do not refresh your screen</p>
                            <div className="flex space-x-4 justify-between mt-5 mb-32">
                                <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                            </div>
                        </div>
                    </div>
                    :
                    transactions.length == 0 ?
                        null
                        :
                        Object.keys(selectedTransaction).length > 0 &&
                            <TransactionDetails setShowDrawer={(e) => setShowDrawer(e)} account_type={user.account_type} selectedTransaction={selectedTransaction} staffAccountList={staffAccountList} />
                }
            </Drawer>
            <>
                {loading ?
                    <div className="h-full flex items-center justify-center">
                        <div className="-mt-32">
                            <LoadingIcon />
                        </div>
                    </div>
                    :
                    <>
                        <div className="hidden h-full md:block">
                            {transactions.length > 0 ?
                                <>
                                    <div className="flex h-full">
                                        <div className="flex-1 h-full">
                                            <div className="mt-1.5 h-9 px-3 items-center rounded-md bg-gray-100 flex justify-between text-paragraph font-roboto-medium text-gray-600">
                                                <div className="w-[12%]">ID</div>
                                                <div className="w-[11%]">Status</div>
                                                <div className="w-[21%]">Restaurant</div>
                                                <div className="w-[10%]">Guests</div>
                                                <div className="w-[14%]">Event type</div>
                                                <div className="w-[10%]">Amount</div>
                                                <div className="w-[10%]" />
                                            </div>
                                            <div className="h-full overflow-y-scroll scrollbar-hide pb-40">
                                                {transactions.map((item, index) => (
                                                    <div className={`${item.id === selectedTransaction?.id ? "text-gray-600 shadow-sm bg-blue-100" : "text-gray-500"} h-11 px-3 items-center border-b border-gray-100 flex justify-between  text-gray-600 andie-description-text rounded-sm`}>
                                                        <div className="w-[12%] font-roboto-medium">{pad(item.id, 5)}</div>
                                                        <div className="w-[11%]">
                                                            <div className={`${background(item.status)} rounded-md px-2 h-6 text-xs font-roboto-medium inline-flex items-center justify-center`}>
                                                                {item.status}
                                                            </div>
                                                        </div>
                                                        <div className="w-[21%] line-clamp-1 xl:line-clamp-none leading-tight">{item.response.restaurant_name}</div>
                                                        <div className="w-[10%] pl-2">{item.bid.guest_count}</div>
                                                        <div className="w-[14%] line-clamp-1 xl:line-clamp-none leading-tight">{item.bid.event_type}</div>
                                                        <div className="w-[10%] font-roboto-medium text-green-700">${pricetoString(getTransactionAmounts(item.response.items, item.tipAmount, item.delivery_fee, item.business_delivers, item.bid.cge_service_fee, item.response.business_service_fee, null, item.response.pricing, item.response.total_price).total)}</div>
                                                        <div className="w-[10%] flex justify-center">
                                                            <div onClick={() => selectTransaction(item, index)} className={`h-[18px] w-[18px] cursor-pointer flex justify-center items-center bg-blue-600 rounded-full`}>
                                                                <ChevronRightIcon className={`h-4 text-white`}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {Array.from({ length: 15 - transactions.length } , () => <div className="h-11 border-b border-gray-100"/>)}
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="flex flex-col items-center justify-center h-full">
                                    <img src={loadingtransactionsgif} className="h-20 w-20 text-center" alt="" />
                                    <p className="mt-3 tex-center text-green-600 text-xs font-sf-medium">No records found</p>
                                    <p className="text-center text-gray-400 text-tiny font-sf-regular">Transactions will appear here once {user.account_type === 1 ? "you have accepted your first bid response" : "your first bid response has been accepted"}.</p>
                                    <div className="flex space-x-4 justify-between mt-5 mb-32">
                                        <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                        <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                        <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="h-full md:hidden">
                            {transactions.length > 0 ?
                                <>
                                    <div className="h-full">
                                        <div className="h-full overflow-scroll scrollbar-hide pb-40">
                                            {transactions.map((item, index) => (
                                                <div onClick={() => selectTransaction(item, index)} className={`${item.id === selectedTransaction?.id ? "text-gray-600 shadow-sm" : "text-gray-500"} bg-white pt-2 items-center border-2 rounded-md mb-3.5 text-gray-400 andie-description-text hover:shadow-2xl`}>
                                                    <div className="w-full flex justify-between pb-2 px-2">
                                                        <div>
                                                            <div className="font-productsans-bold text-gray-900">Transaction ID: <span className="font-roboto-medium text-gray-500">{pad(item.id, 5)}</span></div>
                                                            <div className={`${background(item.status)} rounded-sm w-20 h-5 text-xs text-gray-900 font-roboto-medium inline-flex items-center justify-center`}>
                                                                {item.status}
                                                            </div>
                                                        </div>
                                                        <div className="text-right text-green-700 font-productsans-bold">
                                                            ${pricetoString(getTransactionAmounts(item.response.items, item.tip, item.delivery_fee, item.business_delivers, item.bid.cge_service_fee, item.response.business_service_fee, null, item.response.pricing, item.response.total_price).total)}  
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="py-2 flex items-center justify-between bg-gray-100 px-2">
                                                            <h6>Restaurant</h6>
                                                            <p className="text-right text-gray-800 font-productsans-bold">{item.response.restaurant_name}</p>
                                                        </div>
                                                        <div className="py-2 flex items-center justify-between px-2">
                                                            <h6>Headcount</h6>
                                                            <p className="text-right text-gray-800 font-productsans-bold">{item.bid.guest_count}</p>
                                                        </div>
                                                        <div className="py-2 flex items-center justify-between rounded-b-md bg-gray-100 px-2">
                                                            <h6>Event type</h6>
                                                            <p className="text-right text-gray-800 font-productsans-bold">{item.bid.event_type}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="flex flex-col items-center justify-center h-full">
                                    <img src={loadingtransactionsgif} className="h-20 w-20 text-center" alt="" />
                                    <p className="mt-3 tex-center text-green-600 text-xs font-sf-medium">No records found</p>
                                    <p className="text-center text-gray-400 text-tiny font-sf-regular">Transactions will appear here once {user.account_type === 1 ? "you have accepted your first bid response" : "your first bid response has been accepted"}.</p>
                                    <div className="flex space-x-4 justify-between mt-5 mb-32">
                                        <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                        <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                        <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                }
            </>
        </div>
    )
}