import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExploreHero, ExploreOffersBanner, ExploreRestaurant, HeroBids, HeroCollections } from "../../components/explore/home";
import BidDisplay from "./items/BidDisplay";
import { ResponseRibbon } from "../../components/explore/responses";
import { NewAndNoteworthy } from "../../components/explore/templates";
import { foodOptions } from "../../components/common";
import { ArrowUpIcon, TrendingUpIcon, UserGroupIcon } from "@heroicons/react/solid";

export default function ExploreHome({ bids, templates, responses, loadingTemplates, setViewVideoModal }) {
    const navigate = useNavigate();
    let responsesCount = responses.length;
    let packageCount = templates.flatMap(template => template.packages.map(template => ({ template: template, ...template }))).length;

    const [cards, setCards] = useState([
        { id: 1, content: <ExploreHero setViewVideoModal={setViewVideoModal} />},
        { id: 2, content: <HeroBids /> },
        { id: 3, content: <HeroCollections /> },
    ])

    const ticker = [
        { title: "Performance", data: "Fully Operational", Icon: <div className="bg-green-500 h-2 w-2 rounded-full" /> },
        { title: "Network", data: "265 restaurants & caterers", Icon: <UserGroupIcon className="text-blue-600 h-4" /> },
        { title: "Bids", data: `${bids.length} active`, Icon: <TrendingUpIcon className="text-green-600 h-4" /> },
        { title: "Responses", data: `${responsesCount} submissions`, Icon: <ArrowUpIcon className="text-green-600 h-4" /> },
        { title: "Collections", data: `${packageCount} packages`, Icon: <div className="bg-green-500 h-2 w-2 rounded-full" /> }
    ]

    useEffect(() => {
        const intervalId = setInterval(() => {
          // Rotate the cards order every 5 seconds
          const rotatedCards = [...cards.slice(1), cards[0]];
          setCards(rotatedCards);
        }, 10000);
    
        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
      }, [cards]);
    
      const bringToFront = (id) => {
        // Find the clicked card
        const clickedCard = cards.find((card) => card.id === id);
    
        // Remove the clicked card from the current position
        const updatedCards = cards.filter((card) => card.id !== id);
    
        // Add the clicked card to the front
        setCards([clickedCard, ...updatedCards]);
    };
    
    return (
        <>
            <div className="h-full overflow-y-scroll overflow-x-hidden space-y-7 scrollbar-hide pb-20">
                <div className="stack mt-3 mb-5">
                    {cards.map((card) => (
                        <div key={card.id} onClick={() => bringToFront(card.id)}>
                            {card.content}
                        </div>
                    ))}
                </div>
                <ExploreOffersBanner />
                <ExploreRestaurant text="Are you a restaurant or caterer?" url="https://www.pluckbids.com/become-a-partner" src="https://plk24and18xrb21x0.s3.ca-central-1.amazonaws.com/images/restaurant-header.jpeg" />
                <div className="h-14 flex items-center border-gray-100 bg-gray-900 rounded-md border-y-2">
                    <div className="flex items-center space-x-2.5 animate-scrolling-ticker">
                        {ticker.map((ticker, index) => (
                            <div key={index} className="inline-flex bg-gray-800 h-9 px-1.5 rounded flex-shrink-0 space-x-3 items-center">
                                <div className="ml-0.5">
                                    <h6 className="andie-description-text font-roboto-medium text-xs leading-tight text-white">{ticker.title}</h6>
                                    <p className="andie-description-text text-xs text-gray-400 leading-none">{ticker.data}</p>
                                </div>
                                <div className="bg-gray-900 h-7 w-7 rounded flex items-center justify-center">
                                    {ticker.Icon}
                                </div>
                            </div>
                        ))}
                        {ticker.map((ticker, index) => (
                            <div key={index} className="inline-flex bg-gray-800 h-9 px-1.5 rounded flex-shrink-0 space-x-3 items-center">
                                <div className="ml-0.5">
                                    <h6 className="andie-description-text font-roboto-medium text-xs leading-tight text-white">{ticker.title}</h6>
                                    <p className="andie-description-text text-xs text-gray-400 leading-none">{ticker.data}</p>
                                </div>
                                <div className="bg-gray-900 h-7 w-7 rounded flex items-center justify-center">
                                    {ticker.Icon}
                                </div>
                            </div>
                        ))}
                        {ticker.map((ticker, index) => (
                            <div key={index} className="inline-flex bg-gray-800 h-9 px-1.5 rounded flex-shrink-0 space-x-3 items-center">
                                <div className="ml-0.5">
                                    <h6 className="andie-description-text font-roboto-medium text-xs leading-tight text-white">{ticker.title}</h6>
                                    <p className="andie-description-text text-xs text-gray-400 leading-none">{ticker.data}</p>
                                </div>
                                <div className="bg-gray-900 h-7 w-7 rounded flex items-center justify-center">
                                    {ticker.Icon}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="mt-2 border-t border-gray-100 pt-6">
                    <div className="flex items-center justify-between">
                        <h5 className="section-header">Latest Bids</h5>
                        <div onClick={() => navigate("/bids")} className="andie-text-button">View all</div>
                    </div>
                    <div className="mt-6 flex overflow-x-scroll md:grid md:grid-cols-3 space-x-3">
                        {bids.slice(-3).map((bid, index) => (
                            <div className="flex">
                                <BidDisplay bid={bid} page="Home" homepage index={index} fromHome={true} />
                            </div>
                        ))}
                    </div>
                </div>
                {responses.length > 0 &&
                    <div>
                        <div className="flex items-center justify-between">
                            <h5 className="section-header">Trending Responses</h5>
                            <div onClick={() => navigate("/responses")} className="andie-text-button">View all</div>
                        </div>
                        <div className="mt-5 flex flex-col w-full space-y-3">
                            {responses.slice(-3).map((response, rIndex) => (
                                <ResponseRibbon bid={response.bid} response={response} rIndex={rIndex} displayOnly={true} />
                            ))}
                        </div>
                    </div>
                }
                <div>
                    <div className="flex items-center justify-between">
                        <h5 className="section-header">New Collections</h5>
                        <div onClick={() => navigate("/collections")} className="andie-text-button">View all</div>
                    </div>
                    <NewAndNoteworthy filteredSelection={templates} loadingTemplates={loadingTemplates} home={true} />
                </div>
                <div>
                <h5 className="section-header">Featured Cuisines</h5>
                    <div className="mt-5 grid grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-4">
                            {foodOptions.map((option, index) => (
                                <div className="bg-blue-50 h-28 w-28 md:h-32 md:w-32 rounded-full flex flex-col flex-wrap items-center justify-center text-xs font-roboto-medium">
                                    <img src={`https://plk24and18xrb21x0.s3.ca-central-1.amazonaws.com/images/${option.value}.png`} className="h-12 mb-1.5" />
                                    <p>{option.label}</p>
                                </div>
                            ))}
                        </div>
                </div>
            </div>
        </>
    )
}