import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BidResponseReceipt, BidResponseTotalDetails, LabelBidResponse } from "../../responses";
import moment from "moment";
import { merge } from "lodash";


export default function AdminOrderNowBreakdown({ bid, allItems, sortedItems, status, cgeAccountList, businessAccountList, staffAccountList }) {
    const [responses, setResponses] = useState(bid.responses);
    const [selectedTab, setSelectedTab] = useState("invoice")

    const navigate = useNavigate();

    function renderInvQuoteReceiptButton() {
        if (responses.length > 0) {
            let tempClient = cgeAccountList.find((acc) => acc.email === bid.cge_email)
            let tempRepresentative = staffAccountList.find((acc) => acc.email === bid.cge_representative)

            const items = responses.map(resp => resp.items).flat()
            let fakeResponse = {
                items: items,
                bid: bid,
                delivery_fee: 0,
                business_delivers: false,
                business_service_fee: 0,
                id: responses[0].id
            }

            return (<div className="mt-2 space-y-2">
                <BidResponseReceipt
                    response={fakeResponse}
                    account_type={1}
                    itemsOnly={false}
                    account={tempClient}
                    representative={tempRepresentative}
                    issueDate={moment(moment.now()).toDate()}
                />
                <BidResponseReceipt
                    response={fakeResponse}
                    account_type={1}
                    itemsOnly={true}
                    account={tempClient}
                    representative={tempRepresentative}
                    issueDate={moment(moment.now()).toDate()}
                />
                <LabelBidResponse response={fakeResponse} />
            </div>)
        } else {
            return <></>
        }

    }

    function renderPOReceiptButton(restaurant) {
        if (responses.length > 0) {
            let tempResponse = responses.find(resp => resp.business_email === restaurant)
            tempResponse['bid'] = bid
            let tempBusiness = businessAccountList.find((acc) => acc.email === restaurant)
            let tempRepresentative = staffAccountList.find((acc) => acc.email === tempResponse.business_representative)

            return (
                <div className="mt-2 space-y-2">
                    <BidResponseReceipt
                        response={tempResponse}
                        account_type={2}
                        itemsOnly={false}
                        account={tempBusiness}
                        representative={tempRepresentative}
                        issueDate={moment(moment.now()).toDate()}
                    />
                </div>
            )
        } else {
            return <></>
        }
    }

    return (
        <div className="w-full border-y transition-all mt-2 py-2">
            <div className="tabs">
                <a onClick={() => setSelectedTab('invoice')} className={`tab tab-bordered ${selectedTab=='invoice'&&"tab-active"}`}>Invoice</a>
                <a onClick={() => setSelectedTab('po')} className={`tab tab-bordered ${selectedTab=='po'&&"tab-active"}`}>Purchase Orders</a>
            </div>

            {selectedTab=='invoice' && <div className="bg-slate-50 m-5 p-5">
                <p className='font-productsans-medium text-base'>Invoice</p>
                {/* UZAIR LUMPSUM TOTAL PRICING FOR PACKAGES */}
                <BidResponseTotalDetails
                    items={allItems}
                    cge_service_fee_rate={bid.cge_service_fee}
                    business_service_fee_rate={0}
                    delivery_fee={0}
                    business_delivers={false}
                    tipAmount={bid.budget['tipAmount']}
                    cge_type={bid.cge_type}
                    user_override={1}
                    pricingType={'item'} 
                    totalPrice={0}
                />
                {responses && renderInvQuoteReceiptButton()}
            </div>}

            {selectedTab=='po' && Object.keys(sortedItems).map((restaurant, index) => (
                <div className="bg-slate-50 m-5 p-5">
                    <p className='font-productsans-medium text-base'>PO: {sortedItems[restaurant][0].business_name}</p>
                    <BidResponseTotalDetails
                        items={sortedItems[restaurant]}
                        cge_service_fee_rate={0}
                        business_service_fee_rate={sortedItems[restaurant][0].service_fee ? sortedItems[restaurant][0].service_fee : 0}
                        delivery_fee={0}
                        business_delivers={false}
                        tipAmount={bid.budget['tipAmount']}
                        cge_type={bid.cge_type}
                        user_override={2}
                        pricingType={'item'} 
                        totalPrice={0}
                    />
                    {responses && renderPOReceiptButton(restaurant)}

                </div>
            ))}

        </div>
    )
}