import React, { useState } from "react";
import { CheckCircleIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import toast, { Toaster } from 'react-hot-toast';

import { AdminOrderNowItemBar } from "..";
import { BiddingAPIs } from "../../../apis";
import { useEffect } from "react";
import { random } from "lodash";

export default function AdminOrderNowResponseBar({ status, items, businessAccountList, setItems, edited, setEdited }) {
    const [showItemsDropdown, setshowItemsDropdown] = useState(false);
    function setAssignedRestaurant(restaurantEmail, itemIndex) {
        items[itemIndex].assigned_restaurant = restaurantEmail

        const tempacc = businessAccountList.find((acc) => acc.email === restaurantEmail)
        items[itemIndex].service_fee = tempacc.service_fee
        items[itemIndex].business_name = tempacc.business_name

        setItems(items)
        setEdited(random(10))
    }

    function setAllAssignedRestaurants(restaurantEmail) {
        for (let index = 0; index < items.length; index++) {
            setAssignedRestaurant(restaurantEmail, index)
        }
    }

    const handleItemEdit = (valuename, value, itemindex) => {
        items[itemindex][valuename] = value
        setItems(items)
        setEdited(random(10))
    }

    useEffect(() => {

    }, []);

    return (
        <div className="w-full border-y transition-all mt-2 py-2">
            <div className="flex flex-col justify-between mb-2">
                <p className='font-productsans-medium text-lg'>Order Now Response</p>
            </div>

            <div className="">
                {status === "Posted" &&
                    <div>
                        <div className="flex flex-row items-center my-1">
                            <p className='font-productsans-regular text-sm w-3/5'> Assign all items to restaurant: </p>
                            <select disabled={status !== "Posted"}
                                onChange={(e) => {
                                    setAllAssignedRestaurants(e.target.value)
                                }} id="assigned_restaurant"
                                className="w-full h-8 pl-1 flex border border-gray-200">
                                <option value='none' disabled={true}>Assigned Partner</option>
                                {businessAccountList?.map((item, index) => (
                                    <option value={item.email} selected={item.email === items[0].assigned_restaurant}>{item.business_name}, {item.address_details}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                }
                {
                    items.length > 0 ?
                        items.map((item, index) => (
                            <div className="flex flex-row items-center bg-slate-100 p-2 rounded-md">
                                <div className="h-2 w-2 rounded-full mr-5 border-2 border-black"></div>
                                {item?.quantity > 0 &&
                                    <AdminOrderNowItemBar
                                        item={item}
                                        handleItemEdit={(y, z) => handleItemEdit(y, z, index)}
                                        businessAccountList={businessAccountList}
                                        assignedRestaurant={item.assigned_restaurant}
                                        setAssignedRestaurant={(email) => setAssignedRestaurant(email, index)}
                                        status={status}
                                    />
                                }
                            </div>
                        ))
                        : <></>
                }
            </div>
        </div>
    )
}