import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { CheckIcon, PlusIcon, StarIcon, XIcon } from "@heroicons/react/solid";
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';
import { isEmpty } from "lodash";

import { foodOptions, starOptions, menuoptions } from '../../common';

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        width: "30%",
        height: '60%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

const styles_mobile = {
    modal: {
      backgroundColor: "white",
      boxShadow: "none",
      overflow: "none",
      padding: "0",
      margin: "0",
      width: "90%",
      height: "65%",
      justifyContent: "center",
      borderRadius: "2px",
    },
    overlay: {
      backgroundColor: "#5F656C",
      opacity: 0.8,
      padding: 0,
    },
  };

export default function BidPreferences({ restaurantType, restaurantRating, menuOptions, setRestaurantType, setRestaurantRating, setMenuOptions, setSelectedTab, seatingType, setSeatingType, packagingType, setPackagingType, cge_type, isNextButtonDisabled, setIsNextButtonDisabled }) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isEmpty(menuOptions)) {
            setIsNextButtonDisabled(true);
        }
        else {
            setIsNextButtonDisabled(false)
        }
    }, [menuOptions])

    function addRemoveMenu(option) {

        if (menuOptions.includes(option)) {
            const updated_options = menuOptions.filter(item => item !== option);
            setMenuOptions(updated_options);
        } else {
            setMenuOptions([...menuOptions, option]);
        }
    }

    const addRemoveRestaurant = (option) => {
        let updated_options = [...restaurantType]

        if (!restaurantType.includes(option)) {
            if (updated_options.length < 7) {
                updated_options = [...restaurantType, option]
                if (updated_options.length > 1 && updated_options.includes('Any')) {
                    updated_options = updated_options.filter(item => item !== 'Any')
                }
            } else {
                toast.error('You have selected the maximum number of restaurant types.');
            }
        } else {
            updated_options = updated_options.filter(item => item !== option);
            if (updated_options.length == 0) {
                updated_options = ['Any']
            }
        }

        setRestaurantType(updated_options)
    }

    function convertValuetoLabel(arr, value) {
        try {
            return arr.find(x => x.value === value).label
        } catch ({ name, message }) {
            if (name == "TypeError") {
                return value
            }
        }
    }

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    // useEffect(() => {
    // }, [restaurantType])


    return (
        <div className="h-full flex flex-col justify-between">
            <div className="flex flex-col xl:w-[70%]">
                <div className="h-44">
                    <div className="flex justify-between">
                        <div className="w-5/6 md:w-3/4 xl:w-3/5 pr-6">
                            <h6 className="bid-form-input-label mb-0">Cuisine type</h6>
                            <p className="andie-section-descriptor xl:mr-8">Select up to five (5) cuisine types. To include all available cuisine options, select <b className="font-sf-semibold">Any</b>.</p>
                            <div className="flex flex-wrap mt-3 pr-6">
                                {restaurantType?.map((restaurant, index) => (
                                    <div className="bg-blue-100 h-6 px-2 mb-2 mr-1.5 rounded-full inline-flex space-x-2 justify-between items-center">
                                        <p className="text-xs font-roboto-medium text-blue-600">{convertValuetoLabel(foodOptions, restaurant)}</p>
                                        <div onClick={() => addRemoveRestaurant(restaurant)} className="flex items-center justify-center h-3.5 w-3.5 bg-blue-300 rounded-full cursor-pointer">
                                            <XIcon className="h-2.5 text-blue-600" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div onClick={toggleModal} className="cursor-pointer mt-3.5 text-xs h-3 text-blue-600 font-productsans-medium underline pb-3">Select cuisines</div>
                        </div>

                    </div>
                    <div className='mt-10 xl:flex xl:items-center xl:space-x-5'>
                        <h6 className="bid-form-input-label mb-0">Restaurant level</h6>
                        {starOptions.map((option, index) => (
                            <div className="flex items-center space-x-1.5">
                                <div onClick={() => setRestaurantRating(option.title)} className={`cursor-pointer border-2 h-4 w-4 flex items-center justify-center rounded ${option.title === restaurantRating ? 'border-blue-600' : 'border-gray-300'}`}>
                                    {option.title === restaurantRating && <div className="h-2 w-2 rounded-sm bg-blue-600" />}
                                </div>

                                <div className="flex items-center ml-1">
                                    <StarIcon className='h-4 text-blue-600' />
                                    <p className="bid-form-input-label mb-0 text-gray-500 text-opacity-80">{option.title}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mt-6 xl:w-5/6">
                        <h6 className="bid-form-input-label mb-0">Menu options</h6>
                        <p className="andie-section-descriptor">Choose the type of items you would like to include in bid responses.</p>
                        <div className="grid md:grid-cols-2 gap-4 mt-3">
                            {menuoptions.map((option, index) => (
                                <div className="flex space-x-3.5 mt-1.5 mb-2">
                                    <div onClick={() => addRemoveMenu(option.title)} className={`cursor-pointer border-2 mt-0.5 h-4 w-4 flex flex-shrink-0 items-center justify-center rounded ${menuOptions?.includes(option.title) ? 'bg-blue-600  border-blue-600' : 'border-gray-300'}`}>
                                        {menuOptions?.includes(option.title) && <CheckIcon className="h-4 rounded-sm text-white" />}
                                    </div>
                                    <div>
                                        <p className="text-paragraph font-roboto-medium leading-tight text-gray-500">{option.title}</p>
                                        <p className="andie-description-text leading-tight text-gray-400 mr-5">{option.explanation}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="flex justify-between mt-3 mb-5 bottom-0">
                <button onClick={() => setSelectedTab("Bid Details")} className="bg-gray-100 text-xs font-productsans-medium text-gray-500 h-8 w-16 rounded px-2.5">Back</button>
                <button disabled={isNextButtonDisabled} onClick={() => setSelectedTab("Dietary Requirements")} className={`bg-blue-600 text-xs font-productsans-medium text-white h-8 w-16 rounded px-2.5 ${isNextButtonDisabled ? 'opacity-50' : ''}`}>Next</button>
            </div> */}
            <Modal
                blockScroll={false}
                open={isOpen}
                onClose={toggleModal}
                animationDuration={400}
                showCloseIcon={false}
                styles={window.innerWidth < 768 ? styles_mobile: styles}
                center
            >
                <div className='h-full flex flex-col justify-between pt-3'>
                    <div>
                        <div className="flex px-4 border-b border-gray-100 pb-3 justify-between">
                            <div>
                                <h5 className="text-base font-productsans-medium text-gray-600">Select cuisine type</h5>
                                <p className="andie-section-descriptor mb-0 text-gray-400">Choose up to five (5) options</p>
                            </div>

                            <div onClick={toggleModal} className="cursor-pointer h-5 w-5 rounded-full flex items-center justify-center bg-gray-100">
                                <XIcon className="h-3 text-gray-500" />
                            </div>
                        </div>
                        <div className="pt-4 px-4 flex flex-col ">
                            <div className="flex flex-wrap pb-2 border-b border-gray-50 mb-3 sticky top-14">
                                {restaurantType?.map((restaurant, index) => (
                                    <div className="bg-blue-100 h-6 pl-3 pr-2 mb-2 mr-2.5 rounded-full flex justify-center items-center">
                                        <p className="text-tiny font-sf-medium mt-0.5 text-blue-500">{convertValuetoLabel(foodOptions, restaurant)}</p>
                                        <div onClick={() => addRemoveRestaurant(restaurant)} className="flex items-center justify-center ml-1.5 h-3.5 w-3.5 bg-blue-300 rounded-full cursor-pointer">
                                            <XIcon className="h-2.5 text-blue-600" />
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>

                    <div>
                        <div className="flex flex-wrap max-h-64 overflow-hidden overflow-y-scroll scrollbar-hide mx-4">
                            {foodOptions.map((restaurant, index) => (
                                <>
                                    <div onClick={() => addRemoveRestaurant(restaurant.label)} className="cursor-pointer mb-4 mr-4 flex items-center space-x-1">
                                        {restaurantType.includes(restaurant.label)
                                            ? <>
                                                <div className='flex items-center bg-blue-100 rounded-full pl-3 pr-2 py-1 '>
                                                    <h6 className="text-xs font-sf-medium text-blue-500">{restaurant.label}</h6>
                                                    <div onClick={() => addRemoveRestaurant(restaurant)} className="flex items-center justify-center ml-1.5 h-3.5 w-3.5 bg-blue-300 rounded-full cursor-pointer">
                                                        <XIcon className="h-2.5 text-blue-600" />
                                                    </div>
                                                </div>
                                            </>
                                            : <>
                                                <h6 className="text-xs font-sf-medium text-gray-500">{restaurant.label}</h6>
                                                <PlusIcon className="h-3.5 text-blue-500" />
                                            </>
                                        }
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>

                    <div className="mt-5 flex h-16 items-center justify-end">
                        <div onClick={toggleModal} className="w-1/4 andie-dark-button mr-4">Proceed</div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}