import React, { useState } from 'react'
import { AiOutlinePlus,AiOutlineClose } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom';

export default function SpeedDial({ menuOptions }) {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <div className="absolute bottom-0 right-0 mb-5 mr-3 z-10 text-white flex flex-col-reverse items-end transition-all duration-300">
            <div className="h-12 w-12 flex items-center justify-center rounded-full bg-blue-600 text-white mt-2 transition-all duration-300 ease-in-out" onClick={() => setMenuOpen(!menuOpen)}>
                {menuOpen ? <AiOutlineClose size={16} /> :<AiOutlinePlus size={20} />}
            </div>
            <div className="relative">
                {menuOpen && menuOptions.map((option) => {
                    return <>
                        <div className="mt-2">{option.component}</div>
                    </>
                })}
            </div>
        </div>
    )
}