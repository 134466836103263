// components/TidioChat.js

import React, { useState, useEffect } from 'react';

export default function SupportChat({ toplevel }) {
    const [loadTidioScript, setLoadTidioScript] = useState(false);

    useEffect(() => {
        if (loadTidioScript) {
        const script = document.createElement('script');
        script.src = 'https://code.tidio.co/d0xnzhowjxmogasokhzfg1n7semuao2c.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            // Access the Tidio iframe and apply custom styling
            const tidioFrame = document.getElementById('tidio-chat-iframe');
            if (tidioFrame) {
            const tidioDoc = tidioFrame.contentDocument || tidioFrame.contentWindow.document;

            // Example: Change background color of the chat button
            const chatButton = tidioDoc.querySelector('.button-open-chat');
            if (chatButton) {
                chatButton.style.backgroundColor = '#0038FE'; // Replace with your desired color
            }

            // Example: Change color of the chat icon
            const chatIcon = tidioDoc.querySelector('.icon-container');
            if (chatIcon) {
                chatIcon.style.color = '#0038FE'; // Replace with your desired color
            }
            }
        };

        return () => {
            document.body.removeChild(script);
        };
        }
    }, [loadTidioScript]);

    const handleButtonClick = () => {
        setLoadTidioScript(true);
    };

    return (
        <>
            {toplevel ? 
                <div className="andie-dark-button w-32 h-8" onClick={handleButtonClick}>Contact Us</div>
                :
                <div className="cursor-pointer andie-description-text font-roboto-medium text-blue-600 underline" onClick={handleButtonClick}>
                    Chat
                </div>
            }
        </>
    );
};
