import { useState, useEffect } from "react";
import { LoadingResponseExplore, getTransactionAmounts } from "../../components/common";
import { ResponseRibbon } from "../../components/explore/responses";

export default function ExploreResponses({ globalFilters, incomingResponses, loading }) {
    const [responses, setResponses] = useState([]);
    const [filters, setFilters] = useState([])
    const [respIndex, setRespIndex] = useState(1)

    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const updateScreenSize = () => {
        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    useEffect(() => {
        // Set up the event listener when the component mounts
        window.addEventListener('resize', updateScreenSize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);

    useEffect(() => {
        // let result = myBids.flatMap(bid => bid.responses.map(response => ({ bid: bid, ...response })));
        let result = incomingResponses;

        if (incomingResponses.length > 0) {

            if (globalFilters?.discover != null) {
                result = result.filter(response => response.bid.cge_type === globalFilters?.discover);
            }

            if (globalFilters.cuisines.length > 0) {
                result = result.filter(response => globalFilters.cuisines.includes(response.restaurant_type));
            }

            if (globalFilters.happening_soon != null && globalFilters.happening_soon === true) {
                // Get the current date
                const currentDate = new Date();

                // Calculate the date 1 week from now
                const oneWeekFromNow = new Date();
                oneWeekFromNow.setDate(currentDate.getDate() + 7);
                result = result.filter(response => {
                    const expiryDate = new Date(response.bid.expiry_date);
                    if (((expiryDate > currentDate && expiryDate <= oneWeekFromNow))) {
                        return response;
                    }
                })
            }

            if (globalFilters?.popular !== null && globalFilters?.popular === true) {
                result = result.filter(response => response.bid.views >= 50);
            }

            if (globalFilters?.restaurant_level !== null && globalFilters?.restaurant_level !== "Any") {
                result = result.filter(response => globalFilters?.restaurant_level === response.bid.restaurant_rating);
            }

            if (globalFilters?.event !== null && globalFilters?.event !== "") {
                result = result.filter(response => globalFilters?.event === response.bid.event_type);
            }


            if (globalFilters?.guests !== null) {
                result = result.filter(response => (!globalFilters?.guests || response.bid.guest_count <= globalFilters.guests.max && response.bid.guest_count >= globalFilters.guests.min));
            }
            
            if (globalFilters?.budget != null) {
                result = result.filter(response => ((getTransactionAmounts(response.items, response.bid.budget.tipAmount, response.delivery_fee, response.business_delivers, response.bid.cge_service_fee, response.business_service_fee, null, response.pricing, response.total_price).total / response.bid.guest_count >= globalFilters.budget.min)
                && (getTransactionAmounts(response.items, response.bid.budget.tipAmount, response.delivery_fee, response.business_delivers, response.bid.cge_service_fee, response.business_service_fee, null, response.pricing, response.total_price).total / response.bid.guest_count <= globalFilters.budget.max)))
            }

            setResponses(result);
        }
        
    }, [incomingResponses, globalFilters])

    useEffect(() => {
        setFilters({ ...filters, globalFilters: globalFilters });
    }, [globalFilters])



    return (
        <>
            {loading ?
                <>
                    <div div className="w-full space-y-4 pt-2 h-[85%] overflow-scroll scrollbar-hide pb-6 ">
                        <LoadingResponseExplore />
                    </div>
                </>
                :
                <div className="h-full w-full">
                    <div className="flex border-b border-gray-100 justify-between pb-2 my-2 space-x-2">
                        {globalFilters?.event}
                        <h6 className="bid-form-input-label font-roboto-regular mb-0 text-gray-600">Showing <span className="text-blue-600 font-productsans-bold">{responses.length}</span> responses</h6>
                        <div className="inline-flex items-center px-2 font-roboto-medium text-xs text-red-600 border-2 border-red-600 rounded-sm">
                            Live
                            <span class="ml-1 relative flex h-1.5 w-1.5">
                                <span class="animate-ping absolute inline-flex h-1.5 w-1.5 rounded-full bg-red-400 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-1.5 w-1.5 bg-red-500"></span>
                            </span>
                        </div>
                    </div>
                    <div className="flex h-[85%] flex-col space-y-2">
                        <div className="w-full space-y-3 h-full overflow-scroll scrollbar-hide pb-6">
                            {/* Bids View */}
                            {responses.length > 0 ? (
                                responses.slice((respIndex-1)*10, respIndex*10).map((response, rIndex) => {
                                    return (
                                        <>
                                            <ResponseRibbon bid={response.bid} response={response} rIndex={rIndex} displayOnly={true} />
                                        </>
                                    )
                                })
                            ) : (
                                <div className="flex items-center h-full justify-center font-roboto-medium">No responses to display matching the selected filter.</div>
                            )}
                        </div>
                        <div className="flex justify-center">
                            <div onClick={() => {respIndex > 1 && setRespIndex(respIndex-1)}} class="flex items-center justify-center px-3 h-8 ml-0 cursor-pointer leading-tight text-gray-500 bg-blue-100 rounded-l-lg hover:bg-gray-200 hover:text-blue-600 text-sm font-productsans-medium">Previous</div>
                            
                            {/* {renderPagination()} */}
                            <div className="px-4">
                                <span className="flex flex-row">
                                    {/* <input value={respIndex} max={50} min={1} onChange={(e) => setRespIndex(parseInt(e.target.value))} type="number" className=" flex items-center justify-center px-3 h-8 ml-0 border-2 border-gray-200" /> */}
                                    <p className="flex items-center font-roboto-medium justify-center px-3 h-8 ml-0 text-sm"> Page {respIndex} of {Math.ceil(responses.length/10)}</p>
                                </span>
                            </div>
                            <div onClick={() => {respIndex < Math.ceil(responses.length/10) && setRespIndex(respIndex+1)}} class="flex items-center justify-center px-3 h-8 ml-0 cursor-pointer leading-tight text-gray-500 bg-blue-100 rounded-r-lg hover:bg-gray-200 hover:text-blue-600 text-sm font-productsans-medium">Next</div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}