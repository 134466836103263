import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

import { calculateBudget, getTransactionAmounts, pricetoString } from "../common";

export default function BidChart({ bid, user }) {
    const [series, setSeries] = useState([]);
    const isNoData = bid?.responses.length < 1;
    const options = {
        chart: {
            type: 'area',
            stacked: false,
            height: 100,
            zoom: {
                type: 'x',
                enabled: false,
                autoScaleYaxis: true,
                autoScaleXaxis: true
            },
            toolbar: {
                show: false,
                tools: {
                    download: false
                }
            },
        },
        noData: {
            text: "Please login to see response trends",
            align: 'center',
            verticalAlign: 'centre',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#9ca3af",
                fontSize: '13px',
                fontFamily: 'Helvetica, Arial, sans-serif',
            }
        },
        stroke: {
            width: 2,
            colors: isNoData ? ['#dce1e8'] : ['#3d8cf3'],
        },
        grid: {
            show: true,
            borderColor: '#f3f4f6',
            strokeDashArray: 0,
            position: user ? 'back' : 'front',
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 2,
            colors: isNoData ? ['#dce1e8'] : ['#3d8cf3'],
            strokeColor: isNoData ? ['#dce1e8'] : ['#3d8cf3'],
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0,
                stops: isNoData ? [0, 0, 0] : [0, 90, 100],
                colorStops: isNoData ? [{
                    offset: 0,
                    color: "#dce1e8",
                    opacity: 0.2
                }]
                    :
                    [{
                        offset: 0,
                        color: "#3d8cf3",
                        opacity: 0.2
                    }]
            },
        },
        yaxis: {
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
                color: '#f3f4f6',
                offsetX: 0,
                offsetY: 0
            },
            forceNiceScale: false
        },
        xaxis: {
            x: 0,
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
                color: '#f3f4f6',
                offsetX: 0,
                offsetY: 0
            },
            forceNiceScale: true
        },
        annotations: {
            yaxis: user ? [{
                y: calculateBudget(bid?.budget?.total, 1),
                label: {
                    show: true,
                    text: pricetoString(calculateBudget(bid?.budget?.total, 1)),
                    style: {
                        color: "#009473",
                        fontWeight: "bold"
                    }
                },
                borderColor: '#999',
            }] : [],
        }
    };

    function extractChartData(responses) {
        const chartvalues = [];
        
        if (user !== null) {
            if (responses?.length >= 1) {
                chartvalues.push(0);
                responses.map((item, index) => (
                    chartvalues.push(getTransactionAmounts(item.items, bid.budget.tipAmount , item.delivery_fee, item.business_delivers, bid.cge_service_fee, item.business_service_fee, 0, item.pricing, item.total_price).total)
                ));
            } else if (responses.length == 0) {
                chartvalues.push(0, 1000, 2000, 5000, 3500, 3500, 4000);
            }
        }


        const seriesdata = [{
            name: "Bid Response",
            data: chartvalues
        }]

        return seriesdata;
    }
    useEffect(() => {
        if (bid) {
            setSeries(extractChartData(bid.responses))
        }
    }, [bid])
    return (
        <div className="-mt-2 border-2 rounded-bl-xl border-gray-200">
            <ReactApexChart options={options} series={series} type="area" height={100} />
        </div>
    )
}