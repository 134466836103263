import React from "react";
import { useEffect, useState } from "react";
import Cookies from 'js-cookie';

import { BiddingAPIs, StripeAPI } from "../../../apis";
import { isAuthenticated, setToken } from "../../../apis/Credentials";
import WebSocket from "../../sockets/WebSocket";
import { HeaderLeftSide, HeaderLoginModal, HeaderRightSide, NewAccountPassword } from "..";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { headerBlacklist } from "../../common";
import { SearchIcon } from "@heroicons/react/solid";

export default function Header({ bypassToken, refreshFunction, page, conv_id }) {
  const user = JSON.parse(window.localStorage.getItem("user_details"));
  const user_type = JSON.parse(window.localStorage.getItem("user_type"));
  // console.log(Cookies.get('user_token'))
  const [loginModalOpened, setLoginModalOpened] = useState(false);

  // const [stripeModal, setStripeModal] = useState(false);
  const param = useLocation();
  const navigate = useNavigate();
  const [passwordModalOpened, setPasswordModalOpened] = useState(false);
  const [tokenExists, setTokenExists] = useState(false);
  const [stripeLink, setStripeLink] = useState(false);

  // State variables to store input and select values
  const [inputValue, setInputValue] = useState('');
  const [selectValue, setSelectValue] = useState('');

  const selectOptions = ["Bids", "Responses", "Collections"];

  // Sockets
  const [unreadMsgs, setUnreadMsgs] = useState(0);

  // Function to handle input change
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    
    if (value === "") {
      // Clear input value from storage when input is cleared
      localStorage.removeItem('inputValue');
    } else {
      // Save input value to storage
      localStorage.setItem('inputValue', value);
    }
  };

  // Function to handle select change
  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
    saveDataToStorage(); // Save select value to storage
  };

  // Function to save data to window storage
  const saveDataToStorage = () => {
    if (["bids", "home", "collections", "responses"].includes(param.pathname) === false) {
      navigate(`/${selectValue.toLowerCase()}`)
    }
    // Save input value to storage
    localStorage.setItem('inputValue', inputValue);

    // Save select value to storage
    localStorage.setItem('selectValue', selectValue);

    window.dispatchEvent(new Event('storage')); // Trigger storage event
  };

  // Function to load data from window storage
  const loadDataFromStorage = () => {
    // Load input value from storage
    const storedInputValue = localStorage.getItem('inputValue');
    if (storedInputValue) {
      setInputValue(storedInputValue);
    }

    // Load select value from storage
    const storedSelectValue = localStorage.getItem('selectValue');
    if (storedSelectValue) {
      setSelectValue(storedSelectValue);
    }
  };

  // Call load data function when component mounts
  useEffect(() => {
    loadDataFromStorage();
  }, []);

  // Function to handle Enter key press
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (selectValue === "") {
        setSelectValue("Bids");
      }
      saveDataToStorage();
      
    }
  };

  function searchClick() {
    if (selectValue === "") {
      setSelectValue("Bids");
    }
    saveDataToStorage();
  }

  useEffect(() => {
    const fetchData = async () => {


      if (isAuthenticated()) {
        setTokenExists(true);
        const res = await StripeAPI.generateAPILink(user.email)
        if (res != false) {
          setStripeLink(res.link)
        }
      } else {
        if (bypassToken !== true){
          setLoginModalOpened(true);
        }
      }
    };

    fetchData();

    user && user_type == 1 && user.first_time_login == true
      ? setPasswordModalOpened(true)
      : setPasswordModalOpened(false);

  }, [tokenExists]);

  async function refreshLiveData(type, status, id) {
    if (user != null) {
      if (type == "transaction_ping") {
        if (status == "Accepted") { 
          toast.success("Transaction #" + id + " has been accepted!");
        } else if (status == "Confirmed") {
          toast.success("Transaction #" + id + " has been confirmed!");
        } else {
          toast.success("Transaction #" + id + " has been completed and paid for! Please visit the activity page for more details.")
        }
      } else {
        // console.log('unread message')
        const res = await BiddingAPIs.getUnreadMessages()
        setUnreadMsgs(res.unread)
        if (page != "messages" && res.unread > 0) {
          toast.success("New message!")
        }
      }
    }
  }

  function routeCheck() {
    if (headerBlacklist.some((name) => param.pathname.includes(name))) {
      return false
    } else {
      return true
    }
  }

  return (
    routeCheck() ?
      <>
        <NewAccountPassword passwordModalOpened={passwordModalOpened} setPasswordModalOpened={setPasswordModalOpened} />
        <HeaderLoginModal loginModalOpened={loginModalOpened} setLoginModalOpened={setLoginModalOpened} />
        <div className="h-10 w-full bg-white px-3 md:px-6 flex items-center border-b border-gray-100 justify-between">
          {(page != null && page != "messages") && <WebSocket refreshFunction={() => refreshFunction()} page={page} conv_id={conv_id} />}
          {/* <WebSocket page="header" refreshFunction={(e) => refreshLiveData(e)} /> */}
          
          {/* Comment in after refactoring */}
          {user !== null && <WebSocket refreshFunction={(type, status, id) => refreshLiveData(type, status, id)} page={"header"} />}
          <HeaderLeftSide user={user} unreadMsgs={unreadMsgs} setLoginModalOpened={setLoginModalOpened} />
          <div className="border-2 border-gray-100 justify-between hidden lg:inline-flex  items-center pr-1.5 rounded-full w-1/4 xl:mr-6 h-[30px] ">
            <div className="h-full rounded-l-full bg-slate-100 border-gray-100 px-1.5 w-40">
              <select id="search_type" value={selectValue} onChange={handleSelectChange} className="w-full border-transparent bg-transparent capitalize focus:outline-none text-sm text-gray-400 font-productsans-medium">
                  {selectOptions.map((item, index) => (
                      <option index={index} selected={item} value={item}>{item}</option>
                  ))}
              </select>
            </div>
            <input value={inputValue} onChange={handleInputChange} onKeyPress={handleKeyPress} className="border-0 bid-form-input bg-transparent w-full" />
            <SearchIcon onClick={searchClick} className="h-4 mr-1.5 cursor-pointer flex-shrink-0 text-blue-600" />
            
          </div>
          <HeaderRightSide stripeLink={stripeLink} />
        </div>
      </>
    :
      null
  );
}
