import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useMemo } from 'react';

export default function BidMap({ geo }) {
    const center = useMemo(() => ({ lat: JSON.parse(geo).lat, lng: JSON.parse(geo).lng }), []);

    return (
        <>
            <GoogleMap
                mapContainerClassName="w-full h-56 md:h-72 rounded-2xl"
                center={center}
                zoom={16}
            >
                <Marker position={center} />
            </GoogleMap>
        </>
    );
}