import { useEffect, useState } from "react"
import { TransactionReceipt } from "../../components/transactions";
import { LabelBidResponse, BidResponseReceipt, ResponseDocumentsDownload } from "../../components/responses";
import { PopoutModal, getTransactionAmounts, pad, pricetoString } from "../../components/common";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { toUpper } from "lodash";

const tabs = [
    "All",
    "Pending",
    "Accepted",
    "Rejected"
]


export default function AdminCGEResponses({ parentLoading, getCGEResponses, originalResponses, businessAccountList, staffAccountList, cgeAccountList }) {
    var user = JSON.parse(window.localStorage.getItem('admin_user_details'));
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState("All");
    const [responses, setResponses] = useState(originalResponses)

    useEffect(() => {
        if (user != null) {
            getCGEResponses();
        }
    }, [])

    useEffect(() => {
        if (
            parentLoading[5] ||
            parentLoading[0] ||
            parentLoading[1] ||
            parentLoading[2]
        ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [parentLoading])

    useEffect(() => {
        if (selectedTab == "Pending") {
            let temp = []
            originalResponses.forEach((response) => { if (response.bid.status === "Posted") { temp.push(response) } })
            setResponses(temp)
        } else if (selectedTab == "Accepted") {
            let temp = []
            originalResponses.forEach((response) => { if (response.bid.status !== "Posted" && response.accepted) { temp.push(response) } })
            setResponses(temp)
        } else if (selectedTab == "Rejected") {
            let temp = []
            originalResponses.forEach((response) => { if (response.bid.status !== "Posted" && !response.accepted) { temp.push(response) } })
            setResponses(temp)
        } else {
            setResponses(originalResponses)
        }
    }, [selectedTab, originalResponses])


    return (
        <div className="h-full space-y-1 overflow-scroll scrollbar-hide">
            <div className="h-8 p-1 mb-4 bg-gray-100 rounded-md grid grid-cols-4 space-x-2">
                {tabs.map((tab, index) => (
                    <div onClick={() => setSelectedTab(tab)} className={`${selectedTab === tab && "bg-white text-blue-600"} text-gray-600 cursor-pointer rounded-md px-3 flex font-productsans-medium text-sidebar items-center justify-center`}>
                        {tab}
                    </div>
                ))}
            </div>
            {loading ?
                <div className="flex flex-col space-y-5 w-full h-full items-center justify-center">
                    <span className="loading loading-spinner loading-lg"></span>
                    <p className="font-productsans-medium">Loading Responses</p>
                </div>

                :
                responses?.length > 0 ?
                    responses.map((response) => {
                        return (
                            <div className="flex w-full p-5 space-x-5 align-middle items-center border rounded-md justify-between">
                                {/* <div className="w-[15%]">Transaction #{transaction.id}</div> */}
                                <div className="w-[50%] text-left ">
                                    <p><span className="text-xl font-productsans-medium">#{response.id}</span> ({response.restaurant_name})</p>
                                    <p><span className="font-productsans-medium">Bid #{toUpper(response.bid.transaction_code)}</span> ({response.bid.organization})</p>
                                </div>

                                <div className="w-[20%] text-right font-productsans-bold text-green-700">
                                    <p>PO: ${pricetoString(getTransactionAmounts(response.items, 0, response.delivery_fee, response.business_delivers, response.bid.cge_service_fee, response.business_service_fee, 2).total)}</p>
                                    <p>Invoice: ${pricetoString(getTransactionAmounts(response.items, 0, response.delivery_fee, response.business_delivers, response.bid.cge_service_fee, response.business_service_fee, 1).total)}</p>
                                </div>

                                <div className="w-[17.5%] justify-end flex space-x-2 text-right font-productsans-medium text-white">
                                    {response.bid.status === "Posted" ?
                                        <span className="bg-gray-600 px-5 py-1 rounded-md">Pending</span>
                                        :
                                        response.accepted ?
                                            <span className="bg-green-600 px-5 py-1 rounded-md">Accepted</span>
                                            :
                                            <span className="bg-red-600 px-5 py-1 rounded-md">Rejected</span>
                                    }


                                    <div className="">
                                        <ResponseDocumentsDownload
                                            staffAccountList={staffAccountList}
                                            businessAccountList={businessAccountList}
                                            cgeAccountList={cgeAccountList}
                                            response={response}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    <></>
            }
        </div>
    )
}