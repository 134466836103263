import React from "react";

export default function AgeConsent() {
    return (
        <div className="andie-description-text">
            <h1 className="font-productsans-medium text-base mb-3">Individuals Under 18</h1>
            <p>&nbsp;</p>
            <p>The Site is not intended for individuals under the age of 18. Persons under the age of 18 are not permitted to create their own Company ID, and no one under the age of 18 may provide any information to or on the Site. We do not knowingly collect personal information from individuals under 18. If you use the Site, you are representing and warrantying to the Company that you are at least 18 years of age. If you are under 18, you are not permitted to and shall not use or provide information on this Site or on any or through any of its features/register on the Site, subscribe to any services, or provide any information about yourself, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from an individual under 18 without verification of parental consent, we will delete that information. If you believe we might have information from or about an individual under 18, please contact us at <a href="mailto:privacyandterms@hellopluck.com" class="text-blue-600 visited:text-purple-600">privacyandterms@hellopluck.com</a>. If we learn that we have collected the personal information of any person under the age of 18, we will take reasonable steps to delete the information as soon as possible. &nbsp;</p>
            <p>&nbsp;</p>
        </div>
    )
}