import { useState } from "react"
import { CheckIcon, PlusIcon, StarIcon, XIcon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/solid";

import { ItemInput } from "..";
import listIcon from '../../../assets/images/list-icon.png';

export default function PackageInput({ packages, removeItem, responseItems, removePackage, pkgIndex, setPackages }) {
    const [showItemForm, setShowItemForm] = useState(false);

    return (
        <div class="inline-block px-3">
            <div class="w-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <button type="button" onClick={() => removePackage(pkgIndex)}> - </button>
                <div onClick={() => setShowItemForm(true)} className="cursor-pointer flex items-center space-x-1">
                    <PlusCircleIcon className="h-4 text-blue-600" />
                    <p className="text-tiny font-productsans-medium">Add item</p>
                </div>
                {showItemForm === true ?
                    <div>
                        {packages[pkgIndex].length > 0 &&
                            <>
                                <div className="mt-3 mb-1.5 text-tiny text-blue-600 font-sf-semibold flex">
                                    <div className="w-[45%]">
                                        Item
                                    </div>
                                    <div className="w-[25%]">
                                        Quantity
                                    </div>
                                    <div className="w-[30%]">
                                        Price
                                    </div>
                                </div>
                                <div className="space-y-3">
                                    {packages[pkgIndex].map((item, itemIndex) => (
                                        <div className="text-xs font-sf-regular text-gray-500 flex pb-3 border-b border-gray-50">
                                            <div className="w-[45%]">
                                                {item.name}
                                            </div>
                                            <div className="w-[25%]">
                                                {item.quantity}
                                            </div>
                                            <div className="w-[30%] flex justify-between">
                                                <div className="ml-1" onClick={() => { removeItem(item) }}>
                                                    <MinusCircleIcon className="h-4 text-gray-200" />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        }
                        <ItemInput setPackages={(e) => setPackages(e)} packages={packages} pkgIndex={pkgIndex} />
                    </div>
                    :
                    <div className="flex flex-col items-center">
                        <img src={listIcon} className="mt-4 h-12 w-12" alt="" />
                        <p className="my-3 text-center text-tiny font-sf-regular text-gray-500">
                            There are no items added.
                        </p>
                    </div>
                }
            </div>
        </div>
    )
}