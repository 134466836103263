import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { AdminNavbar, AdminSidebar, AdminBidsBidBar, AdminCreateBid, AdminBidsItem } from '../../components/admin';
import { BiddingAPIs } from "../../apis";
import moment from "moment";
import { Drawer, PopoutModal, calculateBudget, pricetoString } from '../../components/common';
import { BidsItem } from "../../components/explore/bids";
import { StarIcon } from "@heroicons/react/solid";

const tabs = [
    "All",
    "Posted",
    "Accepted",
    "Completed",
    "Closed/Expired",
]

export default function AdminCGEBids({ parentLoading, getCGEBids, originalBids, businessAccountList, staffAccountList, cgeAccountList }) {
    const [selectedTab, setSelectedTab] = useState("All");
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [bids, setBids] = useState(originalBids)
    const [drawerWidth, setDrawerWidth] = useState(55);
    const [showTest, setShowTest] = useState(false);
    var user = JSON.parse(window.localStorage.getItem('admin_user_details'));

    useEffect(() => {
        if (user !== null) {
            getData();
        } else {

        }
    }, [refresh]);

    useEffect(() => {
        if (
            parentLoading[4] ||
            parentLoading[0] ||
            parentLoading[1] ||
            parentLoading[2]
        ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [parentLoading])

    async function getData() {
        setLoading(true);
        getCGEBids();
        setLoading(false)
    }


    function getDateFormatted(trans) {
        const val = trans?.bid.event_date_time
        const date = moment(new Date(val)).format("MM/DD/YYYY")
        return date
    }

    useEffect(() => {
        if (selectedTab == "Posted") {
            let temp = []
            originalBids.forEach((bid) => { if (bid.status == "Posted") { temp.push(bid) } })
            setBids(temp)
        } else if (selectedTab == "Closed/Expired") {
            let temp = []
            originalBids.forEach((bid) => { if ((bid.status == "Expired") || bid.status == "Closed") { temp.push(bid) } })
            setBids(temp)
        } else if (selectedTab == "Accepted") {
            let temp = []
            originalBids.forEach((bid) => { if (bid.status == "Accepted") { temp.push(bid) } })
            setBids(temp)
        } else if (selectedTab == "Completed") {
            let temp = []
            originalBids.forEach((bid) => { if (bid.status == "Completed") { temp.push(bid) } })
            setBids(temp)
        } else {
            setBids(originalBids)
        }
    }, [selectedTab, originalBids])


    return (
        <div className="h-full">
            <div className="h-8 p-1 mb-4 bg-gray-100 rounded-md grid grid-cols-5 space-x-2">
                {tabs.map((tab, index) => (
                    <div onClick={() => setSelectedTab(tab)} className={`${selectedTab === tab && "bg-white text-blue-600"} text-gray-600 cursor-pointer rounded-md px-3 flex font-productsans-medium text-sidebar items-center justify-center`}>
                        {tab}
                    </div>
                ))}
            </div>
            {loading ?
                                <div className="flex flex-col space-y-5 w-full h-full items-center justify-center">
                                <span className="loading loading-spinner loading-lg"></span>
                                <p className="font-productsans-medium">Loading Bids</p>
                            </div>
            
                :
                <>
                    <div className="grid grid-cols-3 pb-28 h-full gap-4 overflow-scroll scrollbar-hide">
                        {bids.length > 0 ?
                            bids.map((bid, index) => (
                                <PopoutModal
                                    title={"Edit Bid and Responses"}
                                    button={
                                        <AdminBidsItem bid={bid} testVal={
                                            bid.cge_email == "bid.testing@andie.work"
                                            ||
                                            bid.cge_email == "barries88@hotmail.com"
                                        } />
                                    }>
                                    <AdminBidsBidBar bid={bid} drawerWidth={75} setDrawerWidth={(e) => setDrawerWidth(e)} staffAccountList={staffAccountList} cgeAccountList={cgeAccountList} businessAccountList={businessAccountList} />
                                </PopoutModal>
                            ))
                            : <>No Bids of this status</>}
                    </div>
                    <AdminCreateBid cgeAccountList={cgeAccountList} />
                </>
            }

        </div>
    )
}