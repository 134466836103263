import React, { useState } from "react";
import { WelcomeIcon } from "../../../../assets/icons";
import { MinusIcon } from "@heroicons/react/solid";

export default function TutorialWelcome() {
    return (
        <div className="">
            <div className="md:flex space-x-4 border-b border-gray-100 pb-7">
                <div className="space-y-5 md:w-3/5">
                    <div>
                        <h6 className="text-2xl leading-tight font-productsans-bold text-blue-600">Hi there!👋 We're glad to see you on Pluck.</h6>
                        <p className="font-roboto-medium text-base  text-gray-800">Reimagine corporate guest experiences with us.</p>
                    </div>
                    <p className="andie-description-text leading-tight">Welcome to Pluck - the bidding platform where restaurants and caterers bid on corporate catering and dining opportunities. We are excited to have you as a part of our community as we redefine the process of connecting organizations and restaurants and caterers.</p>
                    <p className="andie-description-text leading-tight">We can't wait to see the great <span className="font-roboto-meidum">guest experiences</span> you will help bring to life on Pluck.</p>
                </div>
                <WelcomeIcon height={220} />
            </div>
            <div className="mt-5 space-y-8 xl:w-4/5">
                <div>
                    <h6 className="font-roboto-medium text-sm mb-2 text-blue-600">Navigation:</h6>
                    <div className="space-y-3">
                        <div className="flex">
                            <MinusIcon className="h-4 mr-2 flex-shrink-0" />
                            <p className="andie-description-text leading-tight">The <b>Explore page</b> is the main hub for Pluck. Here you will find the latest bids, collections, and responses.</p>
                        </div>
                        <div className="flex">
                            <MinusIcon className="h-4 mr-2 flex-shrink-0" />
                            <p className="andie-description-text leading-tight">You can find your bids, transactions, views, and history on the <b>Activity page</b>.</p>
                        </div>
                        <div className="flex">
                            <MinusIcon className="h-4 mr-2 flex-shrink-0" />
                            <p className="andie-description-text leading-tight">Communication with restaurants and catering partners can be found in the <b>Messages section</b>.</p>
                        </div>
                        <div className="flex">
                            <MinusIcon className="h-4 mr-2 flex-shrink-0" />
                            <p className="andie-description-text leading-tight">You can edit your account information, view pending payments and wallets from the <b>Account page</b>.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <h6 className="font-roboto-medium text-sm mb-2 text-blue-600">Support:</h6>
                    <p className="andie-description-text leading-tight">For any issues please email <span className="font-roboto-medium text-blue-600">support@andie.work</span>.</p>
                </div>
            </div>
        </div>
    )
}