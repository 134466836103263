import React from "react";

export default function SharingInformation() {
    return (
        <div className="andie-description-text">
            <h1 className="font-productsans-medium text-base mb-3">Sharing your information</h1>
            <p class="mt-2">We may disclose aggregate information about users and information that does not identify any individual without restriction.</p>
            <p class="mt-2">We may disclose your personal information or any information that includes personal information that we collect or you provide us (as described in this privacy policy):</p>
            <ul class="list-disc ml-10">
                <li>internally and to our subsidiaries or affiliates;</li>
                <li>to comply with applicable laws and regulations, or other lawful request for information we receive;</li>
                <li>to third parties to market their products or services to you if you have not opted out of these disclosures. We contractually require these third parties to keep personal information confidential and use it only for the purposes for which we disclose it to them;</li>
                <li>in accordance with applicable law, to a buyer or other successor in the event of a merger, restructuring, reorganization, dissolution or other sale or transfer of some or all of the Company&rsquo;s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by the Company about our customers or users is among the assets transferred;</li>
                <li>to fulfil the purpose for which you provide it;</li>
                <li>in relation to a business transaction whereby the Company sells its business assets or is involved in a bankruptcy, merger, acquisition, or reorganization. &nbsp;In such an event it is possible your personal information may be among the assets sold or transferred as part of a business transaction. &nbsp;</li>
                <li>for any other purpose disclosed by us when you provide the information; and</li>
                <li>with your consent.</li>
            </ul>
            <p class="mt-6">We may also disclose your personal information:</p>
            <ul class="list-disc ml-10">
                <li>to comply with any court order, law, or legal process, including to respond to any government or regulatory request, in accordance with applicable law;</li>
                <li>if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers or others.</li>
            </ul>
            <p className="mt-6">In the event we disclose any personal information, we will do so in accordance with any relevant laws. &nbsp;</p>
            <p>&nbsp;</p>
        </div>
    )
}