import { XIcon } from "@heroicons/react/solid"

export default function BillingDetailsForm({ setFirstname, firstname, setLastname, lastname, setEmail, email, setAddress, address, setCity, city, setPostalCode, postalCode, setProvince, province, setPhone, phone, setCountry, country }) {

    return (
        <div className="h-full flex flex-col justify-between">
            <div>
                <div className="mt-4 space-y-2">
                    <div className="grid grid-cols-2 gap-x-2.5">
                        <div className="w-full">
                            <h2 className="pt-2 bid-form-input-label"> First Name </h2>
                            <input 
                                class="bid-form-input w-full" 
                                type="text" 
                                placeholder="Ex. John" 
                                required
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                            />
                        </div>
                        <div>
                            <h2 className="pt-2 bid-form-input-label"> Last Name </h2>
                            <input 
                                class="bid-form-input w-full" 
                                type="text" 
                                placeholder="Ex. Doe" 
                                required
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-x-2.5">
                        <div className="">
                            <h2 className="pt-2 bid-form-input-label"> E-mail </h2>
                            <input 
                                class="bid-form-input w-full" 
                                type="email" 
                                // placeholder="Ex. johndoe40@gmail.com" 
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="">
                            <h2 className="pt-2 bid-form-input-label"> Address </h2>
                            <input 
                                class="bid-form-input w-full" 
                                type="text" 
                                placeholder="E.g. 1234 Maple Street" 
                                required
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}

                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-5 gap-x-2.5">
                        <div className="col-span-2">
                            <h2 className="pt-2 bid-form-input-label"> City </h2>
                            <input 
                                class="bid-form-input w-full" 
                                type="text" 
                                placeholder="E.g. Toronto" 
                                required
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </div>
                        <div className="col-span-2">
                            <h2 className="pt-2 bid-form-input-label"> Postal Code </h2>
                            <input 
                                class="bid-form-input w-full" 
                                type="text" 
                                placeholder="Ex. 1A1 A1A" 
                                required
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                            />
                        </div>

                        <div className="col-span-1">
                            <h2 className="pt-2 bid-form-input-label"> Province </h2>
                            <input 
                                class="bid-form-input w-full" 
                                type="text" 
                                placeholder="E.g. ON" 
                                required
                                value={province}
                                onChange={(e) => setProvince(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-x-3">
                        <div className="col-span-1">
                            <h2 className="pt-2 bid-form-input-label"> Phone </h2>
                            <input 
                                class="bid-form-input w-full" 
                                type="text" 
                                placeholder="Ex. (123)456-7890" 
                                required
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div className="col-span-1">
                            <h2 className="pt-2 bid-form-input-label"> Country </h2>
                            <select onChange={(e) => setCountry(e.target.value)} value={country} class="bid-form-input w-full">
                                <option disabled selected>Select a country</option>
                                <option>CA</option>
                                <option>US</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}