import { CubeIcon, FireIcon, PlusIcon, SparklesIcon } from '@heroicons/react/solid';
import React from 'react';

const TemplateCard = ({highlight, noteworthy, renderPrice, renderRating, item, popular}) => {
    const allCuisines = item.packages
    .map(element => element.cuisine_type.map(cuisine => cuisine.charAt(0).toUpperCase() + cuisine.slice(1)))
    .flat()
    .join(', ');
    return (
        <div className={`cursor-pointer mt-1.5 ${popular && "w-72"}`}>
            <div className="relative">
                <img src={item.images[0]?.url} className={`object-cover w-full h-32 lg:h-40 rounded-md mb-3 ${highlight && "h-48 lg:h-64"} ${popular && "border-8 border-yellow-500 h-64 rounded-xl"}`} />
                <div className="absolute top-2.5 right-2.5 bg-white px-1 m-0.5 rounded-sm">{renderPrice(item.packages)}</div>
                {highlight &&
                    <div className="absolute bottom-2.5 right-2.5">
                        <div className={`h-6 w-6 rounded-full flex items-center justify-center ${noteworthy ? "bg-blue-100" : "bg-yellow-300"}`}>
                            {noteworthy ? <SparklesIcon className="h-4 text-blue-600" /> : <FireIcon className="h-4 text-red-600" />}
                        </div>   
                    </div>
                }
            </div>
            <div className="flex items-center justify-between">
                <h6 className={`font-productsans-medium text-base md:text-sm mb-0 mr-3 leading-tight text-gray-700 ${highlight ? "line-clamp-2" : "line-clamp-1 leading-none"} w-[90%]`}>{item.title}</h6>
                <div className="andie-circle-button shrink-0 h-7 w-7 md:h-6 md:w-6">
                    <PlusIcon className="h-4 text-blue-600" />
                </div>
            </div>
            {!highlight && 
                <>
                    <div className="mt-1 mb-2 flex space-x-1.5">
                        <div className="h-6 px-2 inline-flex shrink-0 items-center bg-gray-100 rounded-full space-x-0.5">
                            <CubeIcon className="h-4 text-blue-800" />
                            <p className="text-xs font-roboto-medium text-gray-700">
                                {item.packages.length} Package{item.packages.length > 1 && 's'}
                            </p>
                        </div>
                        <div className="h-6 px-2 inline-flex items-center border-2 border-gray-200 rounded-full">
                            <p className="text-xs line-clamp-1 font-roboto-medium andie-description-text text-gray-800">{item.cge_type}</p>
                        </div>
                    </div>
            
                    <div className="flex space-x-2.5 justify-between">
                        <p className="font-roboto-regular text-xs line-clamp-1">{allCuisines}</p>
                        <div className="flex items-end pb-1 space-x-1.5">
                            {renderRating(item.experience)}
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default TemplateCard;