import { useEffect, useRef, useState } from "react"
import { w3cwebsocket as W3CWebSocket } from "websocket"
import { WebSocketInner } from ".";
import toast from 'react-hot-toast';

// const URL = "ws://localhost:8004/ws/chat/cge.1/"
// const URL = "ws://localhost:8001/ws/bids/get-bids/cge.94/"
// const URL = {
//     "home": "ws://localhost:8001/ws/explore/",
//     "activity": "ws://localhost:8001/ws/explore/",
//     "messages": "ws://localhost:8001/ws/messages/",
//     "chat": "ws://localhost:8001/ws/messages/",
//     "base": "ws://localhost:8001/ws/home/",
//     "header": "ws://localhost:8001/ws/header/",
//     "admin": "ws://localhost:8001/ws/admin/",
//     "settings": "ws://localhost:8001/ws/settings/",
// }

const URL = {
    "home": "wss://wallepy-cge.azurewebsites.net/ws/explore/",
    "activity": "wss://wallepy-cge.azurewebsites.net/ws/explore/",
    "messages": "wss://wallepy-cge.azurewebsites.net/ws/messages/",
    "base": "wss://wallepy-cge.azurewebsites.net/ws/home/",
    "chat": "wss://wallepy-cge.azurewebsites.net/ws/messages/",
    "header": "wss://wallepy-cge.azurewebsites.net/ws/header/",
    "admin": "wss://wallepy-cge.azurewebsites.net/ws/admin/",
    "settings": "wss://wallepy-cge.azurewebsites.net/ws/settings/",
}

export default function WebSocket({ refreshFunction, page, conv_id }) {
    const user = JSON.parse(window.localStorage.getItem("user_details"));
    const clientRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false);
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);
    const [data, setData] = useState([]);
    const [message, setMessage] = useState('');
    // const ws = new W3CWebSocket(URL + 'ws/test/');


    useEffect(() => {
        if (waitingToReconnect) {
            return
        }
        // if (user == null || user == "") {
        //     return
        // } else {
            if (!clientRef.current) {
                let client = ""
                if (page == "chat" || page == "messages") {
                    client = new W3CWebSocket(URL[page] + conv_id + "/")
                } else if (page == "admin-user") {
                    client = new W3CWebSocket(URL['admin'] + page + "/")
                } else {
                    if (user?.email != null) {
                        client = new W3CWebSocket(URL[page] + user?.email + "/")
                    }
                    else {
                        client = new W3CWebSocket(URL[page] + "guest" + "/")
                    }
                }
                clientRef.current = client
                if (page != "header") {
                    window.client = client
                }
                
                client.onerror = (e) => {
                    console.log(e)
                }
    
                client.onopen = () => {
                    // console.log("Open!")
                    setIsOpen(true)
                };
    
                client.onmessage = (event) => {
                    const data = JSON.parse(event.data);
                    // setMessage(data.message);
                    // console.log(data)
                    if (data.status == "success") {
                        if (refreshFunction != null) {
                            if (data.type == "refresh_msg_ping") {
                                refreshFunction(data.conv_id);
                            } else if (data.type == "transaction_ping") {
                                refreshFunction(data.type, data.transaction_status, data.transaction_id);
                            } else if (data.type == "refresh_admin") {
                                refreshFunction(data.page);
                            } else {
                                refreshFunction();
                            }
                        } else {
                            refreshFunction();
                        }
                    }
                    // setTemplates(data.templates);
                    // setBids(data.bids)
                };
    
                client.onclose = (e) => {
                    // console.log(e)
                    // console.log("Closed!")
                    if (clientRef.current) {
                    } else {
                        return
                    }
    
                    if (waitingToReconnect) {
                        return
                    }
                    setIsOpen(false)
    
                    setWaitingToReconnect(true)
                    setTimeout(() => setWaitingToReconnect(null), 5000);
                };
    
                return () => {    
                    // Dereference, so it will set up next time
                    clientRef.current = null;
    
                    client.close();
                }
            }
        // }

    }, [waitingToReconnect])




    return (
        <></>
    )
}