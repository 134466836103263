var user = JSON.parse(window.localStorage.getItem('admin_user_details'));
if (user == null) {
    user = JSON.parse(window.localStorage.getItem('user_details'));
}
class SocketAPIs {

    async refreshBids() {
        const data = { type: "refresh bids", username: user.email, page: "" };
        const res = await window.client.send(JSON.stringify(data));
    }

    async refreshTransactions(transactionID, status) {
        if (!user?.email) {
            user = JSON.parse(window.localStorage.getItem('user_details'));
        }
        const data = { type: "refresh transactions", username: user.email, transaction_id: transactionID, page: "", transaction_status: status };
        const res = await window.client.send(JSON.stringify(data));
    }

    async refreshMessages(convId, to) {
        if (!user?.email) {
            user = JSON.parse(window.localStorage.getItem('user_details'));
        }
        const data = { type: "refresh messages", username: user.email, "conv_id": convId, page: "" };
        const res = await window.client.send(JSON.stringify(data));
    }

    async refreshUser() {
        if (!user.email) {
            user = JSON.parse(window.localStorage.getItem('user_details'));
        }
        const data = { type: "refresh user", username: user.email, page: "" };
        const res = await window.client.send(JSON.stringify(data));
    }

    async refreshAdminDashboard(page) {
        const data = { type: "refresh admin", page: page };
        const res = await window.client.send(JSON.stringify(data));
    }

    async refreshSettings() {
        const data = { type: "refresh settings", page: "" };
        const res = await window.client.send(JSON.stringify(data));
    }

    async refreshHeader() {
        const data = { type: "refresh header", page: "" };
        const res = await window.client.send(JSON.stringify(data));
    }

    async refreshTemplates() {
        const data = { type: "refresh templates", page: "" };
        const res = await window.client.send(JSON.stringify(data));
    }
}
const socketAPI = new SocketAPIs();
export default socketAPI;
