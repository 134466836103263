import React from "react";

export default function AndiePromiseRestaurants() {
    const promises = [
        "We will always be a great partner to you. We hope you would do the same for us.",
        "We know cashflow is important. We will ensure you have funds based on the agreed guidelines.",
        "All transactions are always guaranteed. Feel free to proceed wihtout any hesistation.",
        "We value transparency. We'll always overshare and we expect the same.",
        "Your dedicated growth associate is always looking for opportunities that match your business."
    ]
    return (
        <div className="bg-blue-700 xl:h-[360px] rounded-lg flex p-3 lg:p-8">
            <div className="md:w-[45%] pr-5">
                <h3 className="font-productsans-medium leading-tight text-2xl text-white">We are with you in this partnership for the long run.</h3>
            </div>
            <div className="md:w-[55%] xl:pl-7">
                {promises.map((promise, index) => (
                    <div className="flex mb-7">
                        <div className="bg-blue-200 h-8 w-8 shrink-0 mr-4 rounded-full flex items-center justify-center">
                            <h6 className="text-blue-700 font-roboto-medium text-sm">{index + 1}</h6>
                        </div>
                        <div className="andie-description-text leading-tight w-4/5 text-white">
                            {promise}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}