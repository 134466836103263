import React from "react";

export default function ReferralGiftIcon({ height, color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            fill={color}
            version="1.1"
            viewBox="0 0 449 449"
            xmlSpace="preserve"
        >
        <g>
            <path d="M385.534 441.092L366.744 96.39a7.5 7.5 0 00-7.489-7.092h-69.461V65.294C289.794 29.291 260.503 0 224.5 0s-65.294 29.291-65.294 65.294v24.004H89.745a7.5 7.5 0 00-7.489 7.092l-18.79 344.702A7.498 7.498 0 0070.955 449h307.09a7.5 7.5 0 007.489-7.908zM174.206 65.294C174.206 37.562 196.768 15 224.5 15s50.294 22.562 50.294 50.294v24.004H174.206V65.294zM78.875 434l17.972-329.702h62.359v31.875a7.5 7.5 0 0015 0v-31.875h100.588v31.875a7.5 7.5 0 0015 0v-31.875h62.358L370.125 434H78.875z"></path>
            <path d="M282.992 243.998l-35.206-6.068-16.651-31.608a7.499 7.499 0 00-13.272 0l-16.65 31.608-35.206 6.068a7.5 7.5 0 00-4.101 12.622l24.916 25.603-5.108 35.358a7.5 7.5 0 0010.737 7.801l32.049-15.784 32.05 15.784a7.494 7.494 0 007.722-.662 7.5 7.5 0 003.015-7.14l-5.108-35.358 24.916-25.603a7.5 7.5 0 00-4.103-12.621zm-34.14 30.41a7.5 7.5 0 00-2.048 6.303l3.601 24.923-22.591-11.126a7.5 7.5 0 00-6.626 0l-22.591 11.126 3.601-24.923a7.5 7.5 0 00-2.048-6.303l-17.563-18.047 24.816-4.277a7.502 7.502 0 005.362-3.896l11.736-22.28 11.737 22.28a7.5 7.5 0 005.362 3.896l24.816 4.277-17.564 18.047z"></path>
        </g>
        </svg>
    );
}