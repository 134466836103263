import { CheckIcon, XIcon } from "@heroicons/react/solid";
import React from "react";
import { currencyOptions, pricetoString, tipOptions } from "../../../common";

export default function EditEventFinancials({ currency, setCurrency, perHead, setPerHead, guestCount, setBudget, tipStatus, setTipStatus, paymentTerms, setPaymentTerms }) {

    function updateBudget(per) {
        const updated_budget = {
            perHead: per,
            total: per * guestCount
        }

        setPerHead(per);
        setBudget(updated_budget);
    }


    function updateTip(option) {
        setTipStatus(option);
    }

    return (
        <div className="flex flex-col border-b border-gray-100 py-5">
            <h1 className="text-largescreen font-productsans-medium text-gray-400">Budget</h1>
            <div className="mt-3 flex space-x-2">
                {currencyOptions.map((option, index) => (
                    <div className="flex items-center">
                        <div onClick={() => setCurrency(option.name)} className={`cursor-pointer h-6 px-3 flex items-center justify-center rounded-sm ${option.name === currency ? 'bg-green-700' : 'border-2 border-gray-300'}`}>
                            <p className={`text-xs font-roboto-medium ${option.name === currency ? 'text-white' : 'text-gray-400'}`}>{option.name}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-3 flex items-center">
                <input
                    className="w-52 bid-form-input"
                    id="budget"
                    onChange={(e) => updateBudget(e.target.value)}
                     type="number" onWheel={(e) => e.target.blur()} 
                    min="1"
                    required
                    placeholder={`${perHead}`}
                    aria-label="Budget"
                />
                <XIcon className="h-3.5 mx-1.5" />
                <h5 className="text-paragraph font-roboto-medium text-green-600">{guestCount} guests = ${pricetoString(perHead * guestCount)}</h5>
            </div>

            <p className="mt-8 bid-form-input-label">Prepay your tip or gratuity</p>
            <div className="mt-2.5 flex space-x-3">
                {tipOptions.map((item, index) => (
                    <div className="flex items-center space-x-1">
                        <div onClick={() => updateTip(item.value)} className={`cursor-pointer border-2 h-4 w-4 mr-1 flex items-center justify-center rounded ${tipStatus === item.value ? 'bg-blue-600  border-blue-600' : 'border-gray-300'}`}>
                            {tipStatus === item.value && <CheckIcon className="h-4 rounded-sm text-white" />}
                        </div>
                        <p className="bid-form-input-label mb-0 text-gray-400">{item.name}</p>
                    </div>
                ))}
            </div>
            <p className="mt-8 bid-form-input-label">Payment Terms</p>
            <div className="mt-2.5">
                <div className="bid-form-input bg-blue-100 border-0">
                    {paymentTerms}
                </div>
            </div>
        </div>
    )
}