import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import dining from "../../assets/images/categories/dining.png";
import catering from "../../assets/images/categories/catering.png";
import experiences from "../../assets/images/categories/experiences.png";
import events from "../../assets/images/categories/events.png";

import { AccountsAPI } from "../../apis";
import { Header } from "../../components/navigation";
import base64url from "base64url";
import { Logo } from "../../components/common";

window.Buffer = window.Buffer || require("buffer").Buffer; 

export default function Login() {
  const navigate = useNavigate();

  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  })

  const [searchParams, setSearchParams] = useSearchParams();

  const LoginInformationValidationSchema = Yup.object({
    email: Yup.string().trim().required().email().label("Email"),
    password: Yup.string().trim().required().label("Password"),
  });

  useEffect(() => {
    if (searchParams.get('key') != null) {
      setLoading(true)
      const s_key = JSON.parse(base64url.decode(searchParams.get('key')))
      if((s_key.email!=null)&&(s_key.OTP!=null)){
        const s_dict = {
          email:s_key.email,
          password:s_key.OTP
        }
        setInitialValues(s_dict)
        handleLogin(s_dict)
      }
    }
  }, []);

  const handleLogin = async (values) => {
    setLoading(true)
    const payload = {
      email: values.email.toLowerCase().trim(),
      password: values.password.trim()
    }

    var loginStatus = await AccountsAPI.LoginUser(payload);

    if (loginStatus === 200) {
      navigate("/");
      window.location.reload()
    } else if (loginStatus === "Retry") {
      setLoginError(true);
    }
    setLoading(false)
  };

  const footer = [
    {
      title: "Privacy Policy",
      link: "/policies/privacy-policy",
    },
    {
      title: "Terms",
      link: "/policies/terms-of-use",
    },
    {
      title: "Support",
      link: "/support",
    },
    {
      title: "About Pluck",
      link: "https://pluckbids.com",
    },
  ];
  return (
    <>
      <div className="hidden">
        <Header bypassToken={true} />
      </div>
      <div className="h-screen flex flex-col md:flex-row overflow-hidden">
        <div className="h-1/3 md:h-full flex flex-col justify-between md:w-1/2 lg:w-1/3 p-4 bg-black">
          <div className="flex justify-start mt-1">
            <QuestionMarkCircleIcon className="h-5 text-white" />
          </div>

          <div className="my-12 lg:mt-24 lg:mb-20 mx-12">
            <h3 className="md:text-xl lg:text-4xl font-productsans-medium text-white leading-0">
              Great corporate guest experiences start here.
            </h3>
            <div className="my-10 flex flex-wrap">
              <div className="w-1/4 items-center mb-5">
                <img
                  src={dining}
                  alt="Dining"
                  className={`object-cover h-11`}
                />
                <h6 className="text-gray-300 ml-0.5 font-productsans-medium text-xs">
                  Dining
                </h6>
              </div>
              <div className="w-1/4 items-center mb-5">
                <img
                  src={catering}
                  alt="Dining"
                  className={`object-cover h-10`}
                />
                <h6 className="text-gray-300 mt-1 font-productsans-medium text-xs">
                  Catering
                </h6>
              </div>
              <div className="w-1/4 items-center mb-5">
                <img
                  src={experiences}
                  alt="Dining"
                  className={`object-cover h-11`}
                />
                <h6 className="text-gray-300 font-productsans-medium text-xs">
                  Experiences
                </h6>
              </div>
              <div className="w-1/4 flex flex-col justify-end items-center mb-5">
                <img
                  src={events}
                  alt="Dining"
                  className={`object-cover h-10`}
                />
                <h6 className="text-gray-300 mt-1 font-productsans-medium text-xs">
                  Events
                </h6>
              </div>
              <div className="w-1/4 items-center mb-5">
                <h6 className="text-gray-300 mt-1 font-productsans-medium text-xs">
                  + More
                </h6>
              </div>
            </div>
          </div>
          <div className="hidden md:flex flex-col justify-center items-center">
            <h2 className="font-productsans-regular text-gray-100 text-4xl">
              {moment(new Date()).format("h:mma")}
            </h2>
            <p className="text-xs font-sf-regular text-white">
              {moment(new Date()).format("dddd, MMMM Do YYYY")}
            </p>
          </div>
          <div className="hidden md:flex items-center justify-between md:mt-12 xl:mt-28 mb-1">
            <h4 className="font-productsans-regular text-xl text-white">
              Pluck
            </h4>
            <p className="text-xs font-sf-regular text-white mr-2">
              Private Access
            </p>
          </div>
        </div>
        <div className="h-2/3 md:h-full w-full md:w-1/2 lg:w-2/3 py-4 px-5 flex flex-col justify-between">
          <div className="flex items-center justify-between">
            <Logo />
            <div className="flex justify-end">
              <div className="text-indigo-600 text-xs font-sf-semibold flex justify-center items-center bg-blue-100 h-8 w-8 rounded-full">
                GU
              </div>
            </div>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={LoginInformationValidationSchema}
            onSubmit={handleLogin}
          >
            <Form className="flex justify-center">

              <div className="w-3/4 lg:w-[30%] flex flex-col items-center">
                <div className={`search-container px-0 border-2 rounded-sm my-2 ${loginError ? 'border-red-500' : ''}`}>
                  <Field
                    type="text"
                    placeholder="Enter your email"
                    name="email"
                    disabled={loading}
                    className={`py-1.5 px-3 w-full font-roboto-regular text-paragraph border-none focus:outline-none focus:border-0`}
                  />
                </div>
                <div className="search-container px-0 border-2 rounded-sm my-2">
                  <Field
                    type="password"
                    placeholder="Enter your password"
                    name='password'
                    disabled={loading}
                    className="py-1.5 px-3 w-full font-roboto-regular text-paragraph border-none focus:outline-none focus:border-0"
                  />
                </div>
                {loginError && (
                  <div className="bg-red-200 text-red-800 px-3 py-2 rounded mt-2">
                    <p className="text-red-600 text-xs">
                      The username or password you have entered in incorrect.
                    </p>
                  </div>
                )}
                <div className="mt-7 flex w-full gap-x-4">
                  <button
                    type="submit"
                    className="andie-dark-button"
                  >
                    {loading ? (
                      <ThreeDots
                        height="30"
                        width="30"
                        radius="9"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
                <button
                    onClick={() => navigate('/forgot-password')}
                    type="button"
                    className="andie-dark-button bg-white text-blue-600 hover:bg-blue-600 hover:text-white mt-4"
                  >
                    Forgot Password?
                  </button>
                <div className="flex w-full items-center my-6">
                  <div className="w-1/5 border-b border-gray-300 h-1" />
                  <h6 className="w-3/5 uppercase text-center font-roboto-regular text-xs text-gray-600">Or request access</h6>
                  <div className="w-1/5 border-b border-gray-300 h-1" />
                </div>
                <div onClick={() => navigate("/signup")} className="andie-outline-button">
                  Get started
                </div>
              </div>
            </Form>
          </Formik>

          <div className="flex justify-center">
            <div className="flex space-x-5 mb-2">
              {footer.map((item, index) => (
                <a
                  target="_blank"
                  href={item.link}
                  key={index}
                  rel="noreferrer"
                  className="text-docoration-none cursor-pointer text-xs font-roboto-medium text-gray-500"
                >
                  {item.title}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
