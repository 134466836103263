import { PlayIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';

const videoList = [
    { 
        title: "Meet Pluck - Introductory Video",
        description: "Meet Pluck, the bidding platform that connects restaurants and caterers with corporate catering and dining opportunities, in as little as four easy steps.",
        url: 'https://plk24and18xrb21x0.s3.ca-central-1.amazonaws.com/meet-pluck-introductory-video.mp4',
        thumbnail: "https://plk24and18xrb21x0.s3.ca-central-1.amazonaws.com/thumbnails/welcome-to-pluck.png",
        time: "1:08"
    }
];

export default function VideoGuides() {
    const [selectedVideo, setSelectedVideo] = useState(videoList[0]);

    return (
        <div className="h-full md:flex md:space-x-6 xl:space-x-8">        
            {/* Main Video Player */}
            <div className="md:w-3/4">
                {selectedVideo ? (
                    <video
                        controls
                        width="100%"
                        src={selectedVideo.url}
                        type="video/mp4"
                        className="rounded-lg shadow"
                    />
                    ) : (
                    <p className="text-center text-gray-500">Select a video to play</p>
                )}

                <div className="mt-6 border-t space-y-3 md:pt-4">
                    <h6 className="font-productsans-regular text-lg">{selectedVideo.title}</h6>
                    <p className="andie-description-text leading-tight text-gray-500">{selectedVideo.description}</p>
                    <div className="space-y-1.5">
                        <p className="andie-description-text leading-tight text-xs"><span className="font-roboto-medium">Author:</span></p>
                        <p className="andie-description-text leading-tight text-xs"><span className="font-roboto-medium">Published:</span></p>
                    </div>
                </div>

                <div className="mt-4 bg-blue-600 p-3 w-full flex items-center md:space-x-6 rounded-sm">
                    <div className="md:w-4/5">
                        <p className="andie-description-text leading-tightxl:w-4/5 text-white">See how Pluck can help you achieve your catering and dining needs.</p>
                    </div>
                    <div className="andie-dark-button w-32 bg-white text-blue-600 hover:bg-blue-700 hover:text-white">
                        Request a demo
                    </div>
                </div>
            </div>

            {/* Horizontal Scrollable Video List */}
            <div className="md:w-1/4 md:h-full">
                <h6 className="font-roboto-medium text-gray-600 text-xs uppercase">Current Playlist</h6>
                <div className="overflow-x-auto md:overflow-x-hidden md:mt-4 md:h-full scrollbar-hide md:pb-8 md:border-b-2 md:border-gray-100 space-y-4 md:overflow-y-scroll whitespace-nowrap md:whitespace-normal">
                {videoList.map((video, index) => (
                    <div
                        key={index}
                        className="inline-block cursor-pointer w-full rounded-xl"
                        onClick={() => setSelectedVideo(video)}
                    >
                        <img src={video.thumbnail} className='h-32 w-44 md:w-full object-cover rounded-xl' alt={video.description} />
                        <h6 className={`mt-3 andie-description-text leading-none font-roboto-medium line-clamp-2 ${selectedVideo.title === video.title && "text-blue-600"}`}>{video.title}</h6>
                        <div className='mt-1.5 flex justify-between items-center'>
                            <p className="andie-description-text text-xs leading-tight line-clamp-2">{video.time}</p>

                            {selectedVideo.title === video.title &&
                                <div className="bg-blue-600 flex items-center justify-center space-x-0.5 h-5 rounded-sm w-16">
                                    <PlayIcon className="h-3.5 text-white flex-shrink-0" />
                                    <p className='andie-description-text text-white font-roboto-medium text-[10px]'>Playing</p>
                                </div>
                            }
                        </div>
                    </div>
                ))}
                {Array.from({ length: 4 - videoList.length } , () => <div className="h-32 w-full flex bg-gray-100 rounded-xl mb-4" />)}
                </div>
            </div>
        </div>
    );
};