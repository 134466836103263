import { CheckCircleIcon, NewspaperIcon, PlusCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { getTransactionAmounts, pricetoString } from "../common";
import { moneySignText } from "../common";


export default function BidResponseBar({ bid, bidExpired, showBidResponse, showBidResponseForm }) {
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user_details')));


    function renderLock(response) {
        let total = 0
        total = getTransactionAmounts(response.items, bid.budget.tipAmount, response.delivery_fee, response.business_delivers, bid.cge_service_fee, response.business_service_fee, 0, response.pricing, response.total_price).total

        return <div className="flex justify-center items-center text-base md:text-sm text-green-700 font-productsans-medium">
            {moneySignText(total)}
        </div>

    }

    function renderBidResponses() {
        let bidArr = [];
        bidArr = bid.responses.map((item, index) => {
            // console.log(item)
            return (
                <div onClick={() => showBidResponse(index)} className={`select-none flex shrink-0 flex-col justify-between cursor-pointer h-32 w-56 border-2 border-gray-100 p-2.5 rounded ${item.accepted && "border-0 bg-green-50"}`}>
                    <div className="flex justify-between">
                        <div>
                            <div className="flex items-center space-x-1">
                                {user !== null ?
                                    <h6 className="leading-none text-base md:text-sm text-green-700 font-productsans-medium">
                                        ${pricetoString(getTransactionAmounts(item.items, bid.budget.tipAmount, item.delivery_fee, item.business_delivers, bid.cge_service_fee, item.business_service_fee, 0, item.pricing, item.total_price).total)}
                                    </h6>
                                :
                                <>{renderLock(item)}</>
                                }
                            </div>
                            <div className="mt-1 flex items-center space-x-1.5">
                                <p className="text-xs font-roboto-medium text-gray-500">{item.restaurant_type}</p>
                                <div className="bg-gray-300 w-1 h-1 rounded-full" />
                                <p className="text-xs font-roboto-regular text-gray-400">{item.items.length} items</p>
                            </div>
                        </div>
                        {item.rejected ?
                            <XCircleIcon className="h-4 text-red-600" />
                            :
                            item.accepted ?
                                <CheckCircleIcon className="h-4 text-green-600" />
                                :
                                <CheckCircleIcon className="h-4 text-gray-300" />

                        }

                    </div>
                    <div className="mt-5 flex justify-between items-end">
                        <h6 className="text-paragraph text-gray-400 font-roboto-medium">BR #00{item.id}</h6>
                        <div>
                            <div className="andie-text-button">View</div>
                        </div>
                    </div>
                </div>
            )
        }, [])
        return bidArr

    }

    return (
        <div className="mt-2">
            <div className="flex space-x-2.5 mb-4 items-center">
                <NewspaperIcon className="h-5" />
                <div className="flex items-center space-x-1">
                    <h6 className="bid-details-header">Bid Responses</h6>
                    <div className="bg-red-600 h-4 w-4 text-white font-roboto-medium text-xs flex items-center justify-center rounded-full">{bid.responses.length}</div>
                </div>
            </div>
            <div className="mt-3 md:flex space-x-4">
                {bid.responses.length === 0 ?
                    <div className="flex overflow-x-auto space-x-4">
                        {Array.from({ length: 3 } , () => <div className="h-32 w-56 flex bg-gray-100 rounded" />)}
                    </div>
                    :
                    <div className="flex overflow-x-auto space-x-4">
                        {renderBidResponses()}
                        {bid.responses.length < 5 && Array.from({ length: 4 - bid.responses.length } , () => <div className="h-32 w-56 flex bg-gray-100 rounded" />)}
                    </div>
                }

                {((user !== null) && (user.account_type === 2) && (!bidExpired)) ?
                    (((bid.responses.filter(response => response.business_email == user.email).length == 0) || user.email === "cgequotes@andie.work" || "babatesting@andie.work") &&
                        <div onClick={() => showBidResponseForm()} className="hidden md:inline-flex hover:bg-blue-50 hover:border-blue-100 cursor-pointer h-32 w-56 shrink-0 flex-col items-center justify-center border-2 rounded-xl border-gray-100">
                            <PlusCircleIcon className="h-5 text-blue-600" />
                            <p className="mt-1 text-xs text-blue-600 font-productsans-medium">Respond</p>
                        </div>)
                    :
                    bid.responses.length === 0 &&
                    <div className="mt-4 md:mt-0 flex items-center justify-center">
                        <p className="font-roboto-medium text-xs text-gray-400 xl:ml-5">There are currently no submitted responses for this bid.</p>
                    </div>
                }
            </div>


        </div>
    )
}