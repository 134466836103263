import { ClockIcon, CubeIcon } from '@heroicons/react/solid';
import React from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { bidStatusColors, getTransactionAmounts } from '../../common';
import Highlighter from 'react-highlight-words';

export default function ResponseItem({ bid, displayOnly, history, resp, searchInput }) {
    const navigate = useNavigate();
    const amounts = getTransactionAmounts(resp.items, 0, resp.delivery_fee, resp.business_delivers, bid.cge_service_fee, bid.business_service_fee, 2, resp.pricing, resp.total_price);
    if (displayOnly == null) {
        displayOnly = false
    }

    const handleClick = () => {
        if (!displayOnly){
            navigate('/bid/'+ bid.transaction_code)
        }
    }

    const renderStatus = () => {
        if (resp.accepted == true) {
            return ("Accepted")
        } else {
            if (bid.status == "Accepted") {
                if (bid.cge_type == "Catering Plus") {
                    return "Posted"
                } else {
                    return "Closed"
                }
            } else {
                return bid.status
            }
        }
    }

    return (
        <div onClick={() => handleClick()} className="cursor-pointer border-2 flex flex-col justify-between h-40 rounded-md border-gray-100 px-3.5 py-3">
            <div>
                <div className="flex justify-between">
                <div className="w-4/5">
                        <h6 className="text-sm leading-none mb-0.5 text-gray-700 font-productsans-bold">Response #<Highlighter searchWords={[searchInput]} textToHighlight={resp.id.toString()} /> </h6>
                        <h6 className="andie-description-text font-roboto-medium text-gray-500 leading-none line-clamp-1"><Highlighter searchWords={[searchInput]} textToHighlight={bid.bid_title} /> </h6>
                    </div>
                    <CubeIcon className="text-gray-300 h-3.5" />
                </div>

                <div className="mt-2 flex space-x-2">
                    {history == true ?
                        null
                    :
                        <div className="py-1 px-2 font-roboto-medium text-xs inline-flex items-center rounded-full bg-gray-100">
                            <div className="h-2 w-2 bg-blue-600 rounded-full mr-1" />
                            {bid.bid_type == "ordernow" ? "Package" : "Bid"}
                        </div>
                    }

                    <div class={`py-1 px-2 leading-none font-roboto-medium text-xs inline-flex items-center rounded-full ${bidStatusColors[renderStatus()]}`}>
                        <ClockIcon className={`h-3 text-black ${["Closed","Accepted","Completed"].includes(renderStatus()) && "text-white"} mr-1`} />
                        {renderStatus()}
                    </div>
                </div>            
            </div>

            <div className="mb-1 flex items-end justify-between">
                <div>
                    <h6 className="font-productsans-medium text-paragraph text-gray-500">{moment(bid.event_date_time).format('ll')}</h6>
                    <p className="text-[11px] text-gray-400 font-roboto-regular">{moment(bid.event_date_time).format('LT')}</p>
                </div>
                <div className="text-right">
                    <h6 className="text-paragraph inline-flex items-center rounded-full text-green-700 font-roboto-bold">${amounts.total.toFixed(2)}</h6> 
                    <p className="font-roboto-medium text-xs text-gray-400">{bid.cge_type}</p>
                </div>
            </div>
        </div>
    )
}