import React from "react";

export default function PersonalInformation() {
    return (
        <div className="andie-description-text">
            <h1 className="font-productsans-medium text-base mb-3">Personal Information We Collect About You</h1>
            <p>We may collect and use several types of information from and about you, including:</p>
            <p class="mt-2 sm:mt-8"><strong>Personal Information:&nbsp;</strong>When you create a corporate or restaurant account, we collect limited Personal Information that we can reasonably use to directly or indirectly identify you, such as your name, title, postal code, email, and phone number and any other identifier we may use to contact you (&ldquo;<strong>personal information</strong>&rdquo;).</p>
            <p class="mt-4"><em>We provide an opportunity for any user to opt-out of contact for marketing purposes on an ongoing basis by accessing the account section of the Site or using the unsubscribe mechanism at the bottom of our emails.</em></p>
            <p class="mt-2 sm:mt-8"><strong>Non-personal information:&nbsp;</strong>We do not collect other information directly from you, but we may derive information that does not directly or indirectly reveal your identity or directly relate to an identifiable individual, such as demographic information, or statistical or aggregated information. Statistical or aggregated data does not directly identify a specific person, but we may derive non-personal statistical or aggregated data from personal information. &nbsp;This information does not, on its own, permit direct association with any specific individual. &nbsp;</p>
            <p class="mt-2 sm:mt-8"><strong>Non-personal information about your Site interactions:</strong> We also derive non-personal information about your Site interactions including the full Uniform Resource Locators (URLs), clickstream to, through and from our Site (including date and time), products you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks and mouse-overs), methods used to browse away from the page.</p>
            <p class="mt-2 sm:mt-8"><strong>Location</strong>: Your physical location while you are using our services or interacting with the Site.
            </p>
            <p>&nbsp;</p>
        </div>
    )
}