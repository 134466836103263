import React from "react";

export default function PolicyChanges() {
    return (
        <div className="andie-description-text">
            <h1 className="font-productsans-medium text-base mb-3">Changes to Our Privacy Policy</h1>
            <p>&nbsp;</p>
            <p>It is our policy to post any changes we make to our privacy policy on this page. If we make any material changes to how we treat our users&rsquo; personal information, we will notify you by email to the email address you provided through the Site. We include the date the privacy policy was last revised at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you, and for periodically visiting our Site and this privacy policy to check for any changes.</p>
            <p>&nbsp;</p>
            <p><strong><u>Contact Information and Challenging Compliance</u></strong></p>
            <p>&nbsp;</p>
            <p>We welcome your questions, comments, and requests regarding this privacy policy and our privacy practices. Please contact us at <a href="mailto:privacyandterms@hellopluck.com" class="text-blue-600 visited:text-purple-600">privacyandterms@hellopluck.com</a>.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    )
}