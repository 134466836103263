import React, { useState } from "react";
import { TutorialBids, TutorialCollections, TutorialWelcome } from "./tutorials";

export default function Tutorial() {
    const [selectedTab, setSelectedTab] = useState("Welcome");

    const tabs = ["Welcome", "Bidding", "Collections"];

    const topics = [
        {
            tab: "Welcome",
            includes: ["What is Pluck?", "Navigating the Platform"]
        },
        {
            tab: "Bidding",
            includes: ["Creating a bid", "Editing a bid", "Viewing responses", "Accepting a response"]
        },
        {
            tab: "Collections",
            includes: ["Celebrate in style", "How to order a collection"]
        }
    ]

    return (
        <div className="h-full overflow-hidden">
            <div className="md:hidden mt-3 flex space-x-4 pt-2.5 border-b">
                {tabs.map((item, index) => (
                    <div onClick={() => setSelectedTab(item)} className={`cursor-pointer font-roboto-medium h-7 text-paragraph ${item === selectedTab ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-500"}`}>
                        {item}
                    </div>
                ))}
            </div>
            <div className="flex h-full">
                <div className="hidden md:inline-flex flex-col w-1/4 xl:w-1/3 pr-4 xl:pr-8">
                    <div className="bg-blue-100 p-4 rounded-xl">
                        <h6 className="font-roboto-bold text-gray-900 uppercase text-xs">Topics</h6>
                        <div className="space-y-3 mb-5">
                            {topics.map((topic, index) => (
                                <div key={index} onClick={() => setSelectedTab(topic.tab)} className="cursor-pointer">
                                    <h6 className="font-productsans-medium text-sm text-blue-600 mt-3 mb-1">{topic.tab}</h6>
                                    <>
                                        {topic.includes.map((included, index) => (
                                            <div key={index} className="flex items-center space-x-2">
                                                <div className="h-1 w-1 rounded-full bg-gray-700" />
                                                <p className="andie-description-text font-roboto-medium text-gray-600">{included}</p>
                                            </div>
                                        ))}
                                    </>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-3/4 xl:w-2/3 xl:mb-5 overflow-y-scroll">
                    {selectedTab === "Welcome" && <TutorialWelcome />}
                    {selectedTab === "Bidding" && <TutorialBids />}
                    {selectedTab === "Collections" && <TutorialCollections />}
                </div>
            </div>
        </div>
    )
}