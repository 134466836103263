import React from 'react';
import { useNavigate } from "react-router-dom";

import bgcollection from "../../../assets/images/hero/bg-collections.png";

export default function HeroBids() {
    const navigate = useNavigate();
    return (
        <div className="bg-amber-200 flex flex-col justify-between rounded-2xl p-5 md:p-6 w-full">
            <div className="md:flex">
                <div className="flex flex-col md:w-1/2 lg:w-3/5 my-4 md:mt-0">
                    <div className="bg-amber-400 rounded h-6 px-2.5 inline-flex w-28 items-center justify-center text-xs uppercase font-roboto-bold bg-opacity-30 mb-4">Collections</div>
                    <h3 className="font-productsans-bold text-black leading-none text-3xl md:text-4xl max-w-md xl:w-4/5">Looking for something <span className="text-yellow-600">small, quick, interesting, or special</span>?</h3>
                    <p className="mt-2 md:mt-4 xl:w-[72%] andie-description-text leading-tight text-gray-900">Explore the latest packages and limited-time offerings curated by our  team in collaboration with our restaurants and caterers partners.</p>
                </div>
                <div className="xl:h-60 my-6 md:my-3 md:pl-4 lg:pl-0 md:w-1/2 lg:w-2/5">
                    <img src={bgcollection} className="h-full xl:mt-4 xl:scale-125 w-full object-contain rounded-lg" />
                </div>
            </div>
            <div className="my-3.5 xl:my-2 flex space-x-2">
                <div className="flex space-x-2">
                    <div onClick={() => navigate('/bids')} className="andie-dark-button bg-yellow-600 hover:bg-yellow-700 w-40 text-white">View Collections</div>
                </div>
            </div>
        </div>
    )
}; 