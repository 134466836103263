import React, { useState } from "react";
import { Header, SidebarRight } from "../../components/navigation";
import { BellIcon, CogIcon, CreditCardIcon } from "@heroicons/react/outline";
import { FaPowerOff } from "react-icons/fa";

import { AccountNotifications, AccountSettings, AccountWallet } from ".";
import { AccountLogout, AccountDeactivate } from "../../components/account";
import { AccountsAPI } from "../../apis";
import { WebSocket } from "../../components/sockets";

export default function Account() {
    const [selectedTab, setSelectedTab] = useState("Settings");
    const sidebar = [
        {
            name: "Settings",
            Icon: CogIcon
        },
        {
            name: "Notifications",
            Icon: BellIcon
        },
        {
            name: "Wallet",
            Icon: CreditCardIcon
        },
        {
            name: "Logout",
            Icon: FaPowerOff
        }
    ]

    async function refreshUserData() {
        await AccountsAPI.VerifyUserOnLogin();
    }

    return (
        <div className="h-screen">
            {/* <Header page="settings" refreshFunction={() => refreshUserData()} /> */}
            <WebSocket refreshFunction={() => refreshUserData()} page="settings" />
            <div className="flex h-[calc(100vh_-_40px)]">
                <div className="hidden md:inline-flex md:w-[25%] xl:w-[17%] flex-col items-end justify-between py-5 px-4">
                    <div className="h-full flex-col justify-between">
                        {sidebar.map((item, index) => (
                            <div onClick={() => setSelectedTab(item.name)} className={`h-9 w-9 flex items-center justify-center rounded-full cursor-pointer mb-3 hover:bg-blue-50 ${selectedTab === item.name && "bg-blue-50"}`}>
                                <item.Icon className={`h-5 text-gray-800 hover:text-blue-600 ${selectedTab === item.name && "text-blue-600"}`} />
                            </div>
                        ))}
                    </div>
                    <AccountDeactivate />
                </div>
                <div className="andie-main-panel">
                    <div className="md:hidden flex h-9 border-b space-x-5">
                        {sidebar.slice(0, 3).map((item, index) => (
                            <div onClick={() => setSelectedTab(item.name)} className={`h-full flex items-center andie-description-text font-roboto-medium justify-center cursor-pointer mb-3 hover:text-blue-600 ${selectedTab === item.name && "border-b-2 text-blue-600 border-blue-600"}`}>
                                {item.name}
                            </div>
                        ))}
                    </div>
                    <div className="w-full">
                        {selectedTab === "Settings" && <AccountSettings />}
                        {selectedTab === "Notifications" && <AccountNotifications />}
                        {selectedTab === "Wallet" && <AccountWallet />}
                        {selectedTab === "Logout" && <AccountLogout />}
                    </div>
                </div>
                <SidebarRight />
            </div>
        </div>
    )
}