import React from "react";
import { PopoutModal } from "../../common";
import moment from "moment";
import { BiddingAPIs } from "../../../apis";
import { TrashIcon } from "@heroicons/react/solid";


export default function AdminDeleteBid({ bid }) {
    function handleDelete() {
        BiddingAPIs.deleteBid_Deps({
            "bid_id": bid.id
        })
    }

    return (
        <PopoutModal
            title={"Delete Bid?"}
            button={
                <div className="cursor-pointer h-9 w-16 px-3 bg-red-600 rounded flex items-center justify-center mt-2">
                    <TrashIcon className="h-5 text-white" />
                </div>
            }
            modalID={"delete_bid_modal"}
            footerElements={
                <div onClick={() => handleDelete()}
                    className="mb-2 cursor-pointer h-9 px-3 bg-red-600 rounded flex items-center justify-center mt-2">
                    <h6 className="text-xs text-white font-productsans-medium">Yes, Delete Bid and Dependencies</h6>
                </div>
            }
        >
            <h6 className="text-xs font-productsans-regular">
                Are you sure you want to delete this bid?
            </h6>
            <h6 className="text-xs font-productsans-regular">
                Deleting this bid will remove ALL responses and transactions associated with this bid.
            </h6>

            <div className="flex align-middle items-center mt-2">
                <div className="rounded-full bg-black w-1 h-1 mr-2"></div>
                <h6 className="text-xs font-productsans-regular">
                    <span className="font-productsans-bold">Bid #{bid.transaction_code.toUpperCase()}</span>, by {bid.organization}, on {moment(bid.event_date_time).format('LL')}
                </h6>
            </div>

            {bid.responses?.length > 0 &&
                bid.responses.map(response => (
                    <>
                        <div className="flex align-middle items-center ml-4">
                            <div className="rounded-full bg-black w-1 h-1 mr-2"></div>
                            <h6 className="text-xs font-productsans-regular">
                                <span className="font-productsans-bold">Response {response.id}</span>, by {response.restaurant_name}
                            </h6>
                        </div>
                        <div className="flex align-middle items-center ml-8">
                            <div className="rounded-full bg-black w-1 h-1 mr-2"></div>
                            <h6 className="text-xs font-productsans-regular">
                                <span className="font-productsans-bold">Conversation {response.id}</span>
                            </h6>
                        </div>
                        {response.accepted && (
                            <div className="flex align-middle items-center ml-8">
                                <div className="rounded-full bg-black w-1 h-1 mr-2"></div>
                                <h6 className="text-xs font-productsans-regular">
                                    <span className="font-productsans-bold">Applicable Transactions</span>
                                </h6>
                            </div>
                        )}

                    </>

                ))
            }
        </PopoutModal>
    )
}