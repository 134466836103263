import { CheckCircleIcon, StarIcon, XIcon } from "@heroicons/react/solid";
import moment from "moment";

export default function BidResponseRestaurantDetails({ bid, bidResponse }) {
    function getTimeToRespond() {
        const duration = moment.duration(moment(bidResponse.date_created).diff(moment(bid.date_created)))
        var durationString = ""
        if (duration.asHours() < 1) {
            durationString = "~" + duration.asMinutes().toFixed(0) + " minutes"
        } else {
            durationString = "~" + duration.asHours().toFixed(0) + " hours"
        }
        return durationString
    }


    return (
        <div className="border-2 space-y-3 border-gray-100 p-3 rounded-md mt-4">
            <div className="flex pb-1 border-b border-gray-100 items-center">
                <div className="w-1/3 bid-label">
                    Cuisine
                </div>
                <div className="w-2/3 space-x-2 flex flex-row">
                    <div className="bg-blue-100 py-0.5 px-1.5 inline-flex text-xs bid-form-input-label text-gray-800 rounded-sm">{bidResponse.restaurant_type}</div>
                    {((bid.restaurant_type.includes(bidResponse.restaurant_type) === false) && (bid.restaurant_type.includes("Any") === false)) ?
                        < XIcon className="h-4 pt-1 text-red-700" /> : <CheckCircleIcon className="h-4 text-green-700" />
                    }
                </div>
            </div>

            <div className="flex pb-2 border-b border-gray-100 items-center gap-x-1">
                <div className="w-1/3 bid-label mb-0">
                    Restaurant Level
                </div>
                <div className="w-2/3 flex items-center space-x-1">
                    <p className="andie-description-text mb-0">4.5 stars</p>
                    <StarIcon className="h-4 text-yellow-400" />
                </div>
            </div>

            <div className="flex pb-2 border-b border-gray-100 items-center">
                <div className="w-1/3 bid-label mb-0">
                    Location
                </div>
                <div className="w-2/3 andie-description-text mb-0">
                    {bid.locationAdditionalDetails}
                </div>
            </div>
            <div className="flex items-center">
                <div className="w-1/3 bid-label mb-0">
                    Response time
                </div>
                <div className="w-2/3 flex items-center space-x-1.5">
                    <div className="flex space-x-1">
                        <div className="h-4 w-1 bg-blue-500" />
                        <div className="h-4 w-1 bg-gray-200" />
                        <div className="h-4 w-1 bg-gray-200" />
                        <div className="h-4 w-1 bg-gray-200" />
                        <div className="h-4 w-1 bg-gray-200" />
                    </div>
                    <p className="andie-description-text mb-0">{getTimeToRespond()}</p>
                </div>
            </div>
        </div>
    )
}