import { LogoutIcon, MenuIcon, UserCircleIcon, HomeIcon, ChartBarIcon, CalendarIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginModal } from ".";
import { WebSocket } from "../sockets";

export default function AdminSidebar({ tab, setSelectedTab, refreshFunction, page, useDummyData, handleUseDummyData, reloading }) {
    const navigate = useNavigate();
    const [adminUser, setAdminUser] = useState(JSON.parse(window.localStorage.getItem('admin_user_details')));
    const [reloadingData, setReloadingData] = useState(false)
    useEffect(() => {
        let temp = false
        for (const page of reloading) {
            if (page) temp = true
        }

        setReloadingData(temp)
    }, [reloading]);

    useEffect(() => {
        if (tab != "") {
            setSelectedTab(tab)
            navigate('/9CzFO4REGlf93l2bDHEnpqjDg/dashboard/' + tab)
        }
    }, [])

    function handleNav(suffix) {
        setSelectedTab(suffix)
        navigate('/9CzFO4REGlf93l2bDHEnpqjDg/dashboard/' + suffix)
    }

    return (
        <div className="h-screen p-5 w-full">
            <div className="h-full flex flex-col flex-grow w-full shadow-md bg-white border-gray-100">
                <div className="flex flex-col justify-between p-5 h-14 ">
                    <WebSocket refreshFunction={(e) => refreshFunction(e)} page={page} />
                    <div className="flex flex-row space-x-2 items-center ">
                        <img className="h-7 rounded-sm" src={require("../../assets/images/icon-black.png")} />
                        <a className="text-black text-xl font-productsans-medium mt-0.5">Staff Dashboard</a>
                    </div>
                </div>

                <div className="h-full overflow-y-scroll inline-block menu font-productsans-regular w-full ">
                    <ul className="mb-1 border-b">
                        <li><a onClick={() => handleNav('admin-stats')} className={tab === 'admin-stats' ? 'active' : " "}>Home</a></li>
                        <li><a onClick={() => handleNav('admin-calendar')} className={tab === 'admin-calendar' ? 'active' : " "}>Calendar</a></li>
                    </ul>
                    <ul className="mb-1 border-b">
                        <li><a onClick={() => handleNav('cge-transactions')} className={(tab === 'cge-transactions') ? 'active' : " "}>Transactions</a></li>
                        <li><a onClick={() => handleNav('cge-bids')} className={(tab === 'cge-bids') ? 'active' : " "}>Bids</a></li>
                        <li><a onClick={() => handleNav('cge-responses')} className={(tab === 'cge-responses') ? 'active' : " "}>Responses</a></li>
                    </ul>
                    <ul className="mb-1 border-b">
                        <li><a onClick={() => handleNav('cge-users')} className={tab === 'cge-users' ? 'active' : " "}>CGE Users</a></li>
                        <li><a onClick={() => handleNav('cge-requests')} className={tab === 'cge-requests' ? 'active' : " "}>Requests</a></li>
                        <li><a onClick={() => handleNav('business-users')} className={tab === 'business-users' ? 'active' : " "}>Partners</a></li>
                        <li><a onClick={() => handleNav('admin-accounts')} className={tab === 'admin-accounts' ? 'active' : " "}>Staff Users</a></li>
                    </ul>
                    <ul className="mb-1 border-b">
                        <li><a onClick={() => handleNav('cge-collections')} className={(tab === 'cge-collections') ? 'active' : " "}>Collections</a></li>
                        <li><a onClick={() => handleNav('cge-ordernow')} className={(tab === 'cge-ordernow') ? 'active' : " "}>Order Now</a></li>
                        <li><a onClick={() => handleNav('cge-urlleads')} className={(tab === 'cge-urlleads') ? 'active' : " "}>Lead Tracking</a></li>
                    </ul>

                </div>

                <div className="flex flex-col justify-end h-28">
                    {reloadingData ?
                        <div className="flex space-x-3 items-center ml-6 mb-3">
                            <div className="w-3 h-3 shadow-md bg-orange-400 rounded-full"></div>
                            <span className="text-base font-productsans-medium">Reloading</span>
                        </div>
                        :
                        <div className="flex space-x-3 items-center ml-6 mb-3">
                            <div className="w-3 h-3 shadow-md bg-green-400 rounded-full"></div>
                            <span className="text-base font-productsans-medium">Up To Date</span>
                        </div>
                        }
                    <div className="px-5 ">
                        <label className="label cursor-pointer">
                            <span className="text-base font-productsans-medium">Test Mode</span>
                            <input type="checkbox" className="toggle" checked={useDummyData} onChange={(e) => handleUseDummyData(e.target.checked)} />
                        </label>
                    </div>

                    <div className="flex justify-start m-2 items-center">
                        <div className="dropdown dropdown-top">
                            <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                                <div className="w-7 rounded-full">
                                    <UserCircleIcon />
                                </div>
                            </label>
                            <ul tabIndex={0} className="menu menu-sm dropdown-content mb-5 z-[1] p-2 shadow bg-base-200 rounded-box w-52">
                                {adminUser != null ?
                                    <>
                                        <li onClick={() => {
                                            localStorage.clear()
                                            window.location.reload()
                                            // navigate('/')
                                        }}><a>
                                                <LogoutIcon className='h-5 text-red-600' />
                                                Logout</a></li>
                                    </>
                                    :
                                    <>
                                        <LoginModal />

                                    </>
                                }
                            </ul>
                        </div>

                        {adminUser != null ?
                            <a className="text-base font-productsans-medium">{adminUser.first_name} {adminUser.last_name}</a>
                            :
                            <a className="text-base font-productsans-medium">Please Log In</a>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}