import { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import toast, { Toaster } from 'react-hot-toast';

import { AccountsAPI } from "../../../apis";
import { AdminStaffForm } from "..";

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        minWidth: "45%",
        height: '85%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

export default function AdminCreateBusiness({ }) {
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [department, setDepartmentName] = useState("")
    const [role, setRole] = useState("")
    const [adminLevel, setAdminLevel] = useState(10)
    const [phone, setPhone] = useState("")

    async function createUser(e) {
        e.preventDefault()
        let data = {
            email: email,
            first_name: firstname,
            last_name: lastname,
            department: department,
            role: role,
            admin_level: adminLevel,
            phone: phone
        };

        try {
            const staffRes = await AccountsAPI.CreateStaffUser(data);
            // await getData();
            // setBusinessModal(false)
        } catch (e) {
        }

    }

    return (
        <AdminStaffForm
            type="create" createUser={(e) => createUser(e)}
            firstname={firstname} setFirstname={(e) => setFirstname(e)}
            lastname={lastname} setLastname={(e) => setLastname(e)}
            email={email} setEmail={(e) => setEmail(e)} department={department}
            setDepartmentName={(e) => setDepartmentName(e)} phone={phone}
            setPhone={(e) => setPhone(e)} role={role}
            setRole={(e) => setRole(e)}
            adminLevel={adminLevel} setAdminLevel={(e) => setAdminLevel(e)}
        />
    )
}