import React, { useEffect, useState } from 'react';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { isEmpty } from 'lodash';

import { packagingOptions, dietaryOptions } from '../../common';

export default function BidDietaryRequirements({ page, dietaryPreferences, setDietaryPreferences, dietaryNotes, setDietaryNotes, setSelectedTab, item, packagingType, setPackagingType, cge_type, requiresChafing, setRequiresChafing, shafingNotes, setIsNextButtonDisabled, requiresStaffing, setRequiresStaffing }) {
    const [isOpen, setIsOpen] = useState(false);
    const [newRestriction, setNewRestriction] = useState("");

    useEffect(() => {
        const hasNonNonePreference = dietaryPreferences.some(
            item => item.preference !== "None"
        );
        if (isEmpty(dietaryPreferences) || (hasNonNonePreference && !dietaryNotes)) {
            setIsNextButtonDisabled(true);
        }
        else {
            setIsNextButtonDisabled(false)
        }
    }, [dietaryPreferences, dietaryNotes])


    function addDietaryRestriction(option) {
        if (dietaryPreferences.some(item => item.preference === option.preference)) {
            const updated_options = dietaryPreferences.filter(item => item.preference !== option.preference);
            setDietaryPreferences(updated_options);
        } else {
            setDietaryPreferences([...dietaryPreferences, option]);
        }
    }

    function addNewRestriction(restriction) {
        dietaryOptions.push({ value: restriction.toLowerCase(), preference: restriction });
        addDietaryRestriction(restriction);
        toggleModal();
    }

    function toggleSubmit() {
        setSelectedTab("Budget");
    }

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    const handlePresentationSelect = (option) => {
        option !== 'Buffet / Food Station' && setRequiresChafing(false);
        setPackagingType([option]);
    }

    return (
        <div className="h-full flex flex-col justify-between">
            <div className={`flex flex-col w-[calc(100vw-26px)] md:w-5/6 ${page === "ordernow" ? "xl:w-5/6" : "xl:w-[70%]"}`}>
                <div className="">
                    <div className='md:flex mb-8 items-center'>
                        <h6 className="bid-form-input-label md:w-1/4 xl:w-1/5 md:mb-0">Presentation style</h6>
                        <div className="space-y-3 md:space-y-0 md:flex justify-between md:w-3/4 text-xs">
                            {packagingOptions.map((option, index) => {
                                return <>
                                    {option === 'Buffet / Food Station'
                                        ? <>
                                            <div className="dropdown md:mt-0 mt-1">
                                                <div onClick={() => handlePresentationSelect(option)} tabIndex={0}>
                                                    <div className='flex gap-x-1'>
                                                        <div className={`cursor-pointer flex-shrink-0 border-2 h-4 w-4 mr-1 flex items-center justify-center rounded ${packagingType[0] === option ? 'border-blue-600' : 'border-gray-300'}`}>
                                                            {option === packagingType[0] && <div className="h-2 w-2 rounded-sm bg-blue-600" />}
                                                        </div>
                                                        <h6 className='bid-form-input-label mb-0 text-gray-500 text-opacity-80'>{option}</h6>
                                                    </div>
                                                    <div tabIndex={0} className="dropdown-content z-[1] bg-gray-50 shadow-lg menu p-2.5 rounded-sm w-64">
                                                        <div className='flex justify-between gap-x-2'>
                                                            <div onClick={() => shafingNotes()} className={` cursor-pointer border-2 mt-0.5 h-4 w-4 flex flex-shrink-0 items-center justify-center rounded ${requiresChafing ? 'bg-blue-600  border-blue-600' : 'border-gray-300'}`}>
                                                                {requiresChafing && <CheckIcon className="h-4 rounded-sm text-white" />}
                                                            </div>
                                                            <div>
                                                                <p className="bid-form-input-label mb-0 text-gray-500">Do you require any chafing dishes?</p>
                                                                <p className='andie-section-descriptor mb-0'>Additional charges may be incurred for chafing dishes. </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='flex gap-x-1 md:mt-0 mt-1'>
                                                <div onClick={() => handlePresentationSelect(option)} className={`cursor-pointer flex-shrink-0 border-2 h-4 w-4 mr-1 flex items-center justify-center rounded ${packagingType[0] === option ? 'border-blue-600' : 'border-gray-300'}`}>
                                                    {option === packagingType[0] && <div className="h-2 w-2 rounded-sm bg-blue-600" />}
                                                </div>
                                                <h6 className='bid-form-input-label mb-0 text-gray-500 text-opacity-80'>{option === 'Individually' ? <>{option} {cge_type?.includes("Catering") ? "packaged" : "served"}</> : <>{option}</>}</h6>
                                            </div>
                                        </>
                                    }
                                </>
                            })}
                        </div>
                    </div>
                    
                    {cge_type.includes("Catering") && 
                        <div className='md:flex mb-8 space-x-2 items-center'>
                            <h6 className="bid-form-input-label md:mb-0">Do you require any servers or hosts?</h6>
                            <div className='flex gap-x-1' onClick={() => setRequiresStaffing(!requiresStaffing)}>
                                <div 
                                className={`cursor-pointer flex-shrink-0 border-2 h-4 w-4 mr-1 flex items-center justify-center rounded ${requiresStaffing === true ? 'border-blue-600' : 'border-gray-300'}`}>
                                    {requiresStaffing === true && <div className="h-2 w-2 rounded-sm bg-blue-600" />}
                                </div>
                            </div>
                        </div>
                    }

                    <div className="mb-6">
                        {page === "ordernow" ?
                            <p className="andie-description-text">Please specify any dietary requirements:</p>
                            :
                            <>
                                <h6 className="bid-form-input-label mb-0">Dietary Requirements</h6>
                                <p className="andie-section-descriptor mr-8">Please specify any dietary requirements:</p>
                            </>
                        }
                        <div className="mt-5 grid grid-cols-2 md:grid-cols-4 gap-4">
                            {dietaryOptions.map((option, index) => (
                                <div className="flex items-center space-x-1">
                                    <div onClick={() => addDietaryRestriction(option)} className={`mr-1 cursor-pointer border-2 h-4 w-4 flex items-center justify-center rounded ${dietaryPreferences.some(item => item.preference === option.preference) ? 'bg-blue-600  border-blue-600' : 'border-gray-300'}`}>
                                        {dietaryPreferences.some(item => item.preference === option.preference) && <CheckIcon className="h-4 rounded-sm text-white" />}
                                    </div>
                                    <p className="bid-form-input-label text-opacity-80 mb-0">{option.preference}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div>
                        <h6 className="bid-form-input-label">Dietary notes</h6>
                        <textarea
                            className="w-full md:w-5/6 xl:w-3/4 h-44 xl:h-36 bid-form-input"
                            id="cus_notes"
                            onChange={(e) => setDietaryNotes(e.target.value)}
                            type="text"
                            placeholder="If you have specific dietary requirements, please add it here. For example, '2 guests need vegan options'."
                            aria-label="Name"
                            value={dietaryNotes}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}