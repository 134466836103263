import React, { useEffect, useState } from 'react';
import { CheckCircleIcon, CheckIcon, MenuIcon, UserGroupIcon, ViewGridIcon } from '@heroicons/react/solid';
import { calculatePackageTotaPrice, foodOptions, menuoptions, pricetoString } from '../../common';


const PackageViewer = ({ item, pkg, howmany, selectedPackage, setSelectedPackage, page }) => {
    const [tabs, setTabs] = useState(menuoptions)
    const [selectedTab, setSelectedTab] = useState(menuoptions[1]);

    useEffect(() => {
        setTabs(menuoptions.filter(opt => pkg.items[opt.value].length > 0))
        setSelectedTab(tabs[1])
    }, [pkg]);

    function displayCuisines(pkg) {
        let stringarr = []
        for (let index = 0; index < pkg.cuisine_type.length; index++) {
            const restaurantType = pkg.cuisine_type[index]
            const option = foodOptions.find((item) => item.value === restaurantType)
            stringarr.push(option.label)
        }
        return stringarr.join(", ")
    }

    function renderNonItems() {
        const minNumItems = 3
        const remainder = minNumItems - pkg.items[selectedTab.value].length
        let itemList = []

        for (let i = 0; i < remainder; i++) {
            itemList.push(true)
        }
        return (
            itemList.map(placeholder => (
                <div className="p-3 rounded-lg flex h-20">

                </div>
            ))
        )
    }

    function getItemsCount(entiremenu) {
        const count = entiremenu.appetizer.length + entiremenu.beverages.length + entiremenu.dessert.length + entiremenu.main.length + entiremenu.other.length + entiremenu.side.length
        return count;
    }

    return (
        <div className={`border-2 ${howmany > 1 ? "w-[90%]" : "w-full"} md:w-full lg:w-[45%] rounded-xl border-gray-100 flex-shrink-0`}>

            <div className='flex flex-row justify-between p-4'>
                <div className="flex flex-col justify-between">
                    <div className="space-y-1">
                        <h6 className="text-gray-700 font-productsans-bold leading-tight text-base">{pkg.title}</h6>
                        <div className="flex space-x-1.5 items-center">
                            <p className="andie-description-text text-xs font-roboto-medium">{item.cge_type}</p>
                            <div className="h-1.5 w-1.5 rounded-full bg-gray-400" />
                            <p className='text-xs font-roboto-medium text-gray-400'>{displayCuisines(pkg)}</p>
                        </div>
                        
                        <div className="mt-2 flex space-x-2">
                            <div className="bg-gray-100 inline-flex items-center py-1 px-2 rounded-full text-xs leading-none font-roboto-medium text-gray-700"><CheckCircleIcon className="h-3.5 text-green-700 mr-1" />{pkg.pricing === "package" ? "Bundle pricing" : "Priced per item"}</div>
                            <div className="bg-gray-100 inline-flex items-center py-1 px-2 rounded-full text-xs leading-none font-roboto-medium text-gray-700">+{getItemsCount(pkg.items)} items</div>
                        </div>
                    </div>
                    {/* <p className="font-productsans-medium text-green-700 text-xs leading-tight">Serves {pkg.serves} people.</p>
                                <p className="font-productsans-medium text-green-700 text-xs leading-tight">Minimum order: {pkg.min_guests} guests.</p> */}
                </div>
                <div className='flex flex-col flex-shrink-0 items-end text-right'>
                    {page === 'admin' ?
                        <>
                            {/* <div className='andie-outline-button mb-1'>View/Edit</div> */}
                            <h6 className="text-sm font-productsans-medium">{calculatePackageTotaPrice(pkg.items, pkg.pricing, pkg.package_price)}</h6>
                            <p className='bid-form-input-label whitespace-nowrap mt-0 text-xs '>/package</p>
                        </>
                        :
                        <>
                            <input checked={pkg.id == selectedPackage.id} onChange={() => { setSelectedPackage(pkg) }} type="checkbox" className="checkbox checkbox-xs mb-1 border-2" />
                            <div className="bg-green-100 mt-1 inline-flex flex-col py-1.5 px-2 rounded">
                                <h6 className="text-paragraph leading-none font-productsans-bold text-green-700">{calculatePackageTotaPrice(pkg.items, pkg.pricing, pkg.package_price)}</h6>
                                <p className='bid-form-input-label mb-0 text-xs'>{(pkg.serves > 1 && pkg.pricing === "package") ? "Subtotal": " per Head"}</p>
                            </div>
                            <div className="mt-1 inline-flex items-center text-xs leading-none font-roboto-medium text-gray-700">
                                Min. order: <span className="ml-1 h-4 w-4 rounded-full bg-gray-600 text-white font-roboto-bold flex items-center justify-center">{pkg.min_guests}</span>
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className="mt-2 px-4 flex justify-between items-center">
                <p className="andie-description-text font-roboto-medium text-gray-400">Menu options</p>
                <div className="flex items-center space-x-1">
                    <UserGroupIcon className="h-4 text-gray-600" />
                    <p className="andie-description-text font-roboto-medium text-xs">Serves {pkg.serves} {pkg.serves === 1 ? "person" : "people"}.</p>
                </div>
                {/* <div className="flex items-center">
                    <p className="font-roboto-medium text-blue-500 text-xs leading-tight">Minimum: {pkg.min_guests}</p>
                </div> */}
            </div>


            <div className="mt-3 px-4 flex space-x-4 border-t pt-2.5 border-b">
                {tabs.map((item, index) => (
                    <div onClick={() => setSelectedTab(item)} className={`cursor-pointer font-roboto-medium h-7 text-[13px] ${item === selectedTab ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-500"}`}>
                        <p className="line-clamp-1">{item.title}</p>
                    </div>
                ))}
            </div>

            <div className="flex flex-col px-4 mb-5">
                <div className="mt-3 space-y-3">
                    {pkg.items[selectedTab.value].length === 0 ?
                        <div className="bg-gray-100 p-3 rounded-lg flex">
                            <div className="h-9 w-9 flex-shrink-0 flex items-center justify-center bg-gray-200 rounded">
                                <MenuIcon className="h-5" />
                            </div>
                            <div className="ml-3 w-full">
                                <h6 className="andie-description-text font-roboto-medium">None</h6>
                                <p className="andie-description-text text-gray-500 leading-tight line-clamp-2 text-xs w-4/5">There are no Items of this type</p>
                                <div className="flex w-full justify-end">
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            {pkg.items[selectedTab.value].map((product, itemIndex) => {
                                return (
                                    <div className="bg-blue-50 p-3 rounded-lg flex flex-col">
                                        <div className=' flex'>
                                            <div className="h-10 w-10 md:h-9 md:w-9 flex-shrink-0 flex items-center justify-center bg-blue-200 rounded">
                                                <MenuIcon className="h-5" />
                                            </div>
                                            <div className="ml-3 w-full">
                                                <div className="flex justify-between">
                                                    <h6 className="andie-description-text leading-tight font-roboto-medium">{product.name}</h6>
                                                    <div className="flex flex-shrink-0 pt-1 space-x-1">
                                                        <p className="andie-description-text font-roboto-medium text-largescreen md:text-xs text-gray-400 leading-none">{product.serves} {product.serves > 1 ? "servings": "serving"}</p>
                                                    </div>
                                                </div>
                                                
                                                <p className="mt-1 andie-description-text text-gray-500 leading-tight line-clamp-2 w-[90%] text-largescreen md:text-xs">{product.description}</p>
                                            </div>
                                        </div>
                                        <div className="flex w-full justify-end">
                                            <div className="w-full flex justify-end mt-1 items-center">
                                                {/* there is no quantity value taken for package items */}
                                                {/* <div className='flex space-x-1.5 text-[11px] font-roboto-medium text-gray-500'>Quantity: {product.quantity}</div> */}
                                                <div className="mt-1 space-x-2 max-w-4 flex">
                                                    {product.restriction?.filter((i) => (i != "None")).length > 0 &&
                                                        product.restriction?.filter((i) => (i != "None")).map(e =>
                                                            <div className="flex space-x-1.5 text-xs font-roboto-medium text-gray-500">
                                                                <CheckIcon className="text-green-500 h-4" />
                                                                <p>{e}</p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* <p className="mt-3 text-xs font-roboto-medium text-gray-500">{product.menuoption}</p> */}

                                    </div>
                                )
                            })}
                            {renderNonItems()}
                        </>
                    }
                </div>
            </div>

        </div>
    )
}

export default PackageViewer;