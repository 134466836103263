import React from "react";

export default function SaleOfPersonalInformation() {
    return (
        <div className="andie-description-text">
            <h1 className="font-productsans-medium text-base mb-3">Sale of Personal Information</h1>
            <p><span>&nbsp;</span></p>
            <p>In some jurisdictions, you may have the right to opt-out of the sale of your personal information. We do not sell your personal information. &nbsp;</p>
            <p>&nbsp;</p>
            <h1 className="font-productsans-medium text-base mb-3">Individuals Under 18</h1>
            <p>&nbsp;</p>
            <p>The Site is not intended for individuals under the age of 18. Persons under the age of 18 are not permitted to create their own Company ID, and no one under the age of 18 may provide any information to or on the Site. We do not knowingly collect personal information from individuals under 18. If you use the Site, you are representing and warrantying to the Company that you are at least 18 years of age. If you are under 18, you are not permitted to and shall not use or provide information on this Site or on any or through any of its features/register on the Site, subscribe to any services, or provide any information about yourself, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from an individual under 18 without verification of parental consent, we will delete that information. If you believe we might have information from or about an individual under 18, please contact us at <a href="mailto:privacyandterms@hellopluck.com" class="text-blue-600 visited:text-purple-600">privacyandterms@hellopluck.com</a>. If we learn that we have collected the personal information of any person under the age of 18, we will take reasonable steps to delete the information as soon as possible. &nbsp;</p>
            <p>&nbsp;</p>
            <h1 className="font-productsans-medium text-base mb-3">Accessing and Correcting Your Personal Information</h1>
            <p>&nbsp;</p>
            <p>It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes. By law, you have the right to request access to and to correct the personal information that we hold about you. You can also log into the Site and update your personal information on your dashboard. If you would like us to delete any personal information, please send your request via email at <a href="mailto:privacyandterms@hellopluck.com" class="text-blue-600 visited:text-purple-600">privacyandterms@hellopluck.com</a>.</p>
            <p>&nbsp;</p>
            <p>We may request specific information from you to help us confirm your identity and your right to access, and to provide you with the personal information that we hold about you or make your requested changes. Applicable law may allow or require us to refuse to provide you with access to some or all of the personal information that we hold about you, or we may have destroyed, erased, or made your personal information anonymous in accordance with our record retention obligations and practices. If we cannot provide you with access to your personal information, we will inform you of the reasons why, subject to any legal or regulatory restrictions.</p>
            <p>&nbsp;</p>
            <h1 className="font-productsans-medium text-base mb-3">Withdrawing Your Consent&nbsp;</h1>
            <p>&nbsp;</p>
            <p>Where you have provided your consent to the collection, use and transfer of your personal information, you may have the legal right to withdraw your consent under certain circumstances. To withdraw your consent, contact us at <a href="mailto:privacyandterms@hellopluck.com" class="text-blue-600 visited:text-purple-600">privacyandterms@hellopluck.com</a>. &nbsp;Please note that if you withdraw your consent we may not be able to provide you with a particular product or service. We will explain the impact to you at the time to help you with your decision.</p>
            <p>&nbsp;</p>
            <h1 className="font-productsans-medium text-base mb-3">Changes to Our Privacy Policy</h1>
            <p>&nbsp;</p>
            <p>It is our policy to post any changes we make to our privacy policy on this page. If we make any material changes to how we treat our users&rsquo; personal information, we will notify you by email to the email address you provided through the Site. We include the date the privacy policy was last revised at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you, and for periodically visiting our Site and this privacy policy to check for any changes.</p>
            <p>&nbsp;</p>
            <h1 className="font-productsans-medium text-base mb-3">Contact Information and Challenging Compliance</h1>
            <p>&nbsp;</p>
            <p>We welcome your questions, comments, and requests regarding this privacy policy and our privacy practices. Please contact us at <a href="mailto:privacyandterms@hellopluck.com" class="text-blue-600 visited:text-purple-600">privacyandterms@hellopluck.com</a>.</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    )
}