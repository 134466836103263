import React from "react";

export default function DrinksIcon({ height, color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            fill={color}
            version="1.1"
            viewBox="0 0 512.005 512.005"
            xmlSpace="preserve"
        >
            <g>
                <path d="M256.003 102.405h25.6a8.536 8.536 0 008.533-8.533v-25.6c0-18.825-15.309-34.133-34.133-34.133h-25.6a8.536 8.536 0 00-8.533 8.533v25.6c-.001 18.825 15.317 34.133 34.133 34.133zm-17.067-51.2h17.067c9.412 0 17.067 7.654 17.067 17.067v17.067h-17.067c-9.412 0-17.067-7.654-17.067-17.067V51.205zM315.736 102.405h25.6c18.825 0 34.133-15.309 34.133-34.133v-25.6a8.536 8.536 0 00-8.533-8.533h-25.6c-18.816 0-34.133 15.309-34.133 34.133v25.6a8.536 8.536 0 008.533 8.533zm8.533-34.133c0-9.412 7.654-17.067 17.067-17.067h17.067v17.067c0 9.412-7.654 17.067-17.067 17.067h-17.067V68.272zM162.392 341.339h-.085c-4.71 0-8.491 3.823-8.491 8.533s3.866 8.533 8.576 8.533c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533z"></path>
                <path d="M426.669 290.139c0-23.45 0-94.805-15.428-163.994a8.535 8.535 0 00-8.328-6.673h-226.97L118.573 4.724A8.537 8.537 0 00107.121.901c-4.216 2.116-5.922 7.236-3.814 11.452l53.555 107.119h-47.77a8.536 8.536 0 00-8.329 6.673c-15.428 69.188-15.428 140.544-15.428 163.994 0 91.221 72.004 165.743 162.133 170.231v34.569h-68.267c-4.71 0-8.533 3.823-8.533 8.533s3.823 8.533 8.533 8.533h153.6c4.71 0 8.533-3.823 8.533-8.533s-3.823-8.533-8.533-8.533h-68.267V460.37c90.131-4.488 162.135-79.01 162.135-170.231zm-170.666 153.6c-84.693 0-153.6-68.907-153.6-153.6 0-14.276.026-46.703 3.627-85.333h64.64c4.71 0 8.533-3.823 8.533-8.533s-3.823-8.533-8.533-8.533h-62.857c1.954-16.58 4.574-33.911 8.175-51.2h280.03c13.585 65.237 13.585 131.379 13.585 153.6 0 84.692-68.898 153.599-153.6 153.599z"></path>
                <path d="M216.297 204.805h159.172c4.71 0 8.533-3.823 8.533-8.533s-3.823-8.533-8.533-8.533H208.617l-13.099-29.099c-1.937-4.301-7.006-6.212-11.281-4.284a8.537 8.537 0 00-4.284 11.281l76.8 170.667a8.532 8.532 0 0011.281 4.284c4.301-1.937 6.221-6.989 4.284-11.281l-56.021-124.502z"></path>
                <path d="M298.925 256.005h-.085c-4.71 0-8.491 3.823-8.491 8.533 0 4.71 3.866 8.533 8.576 8.533a8.53 8.53 0 008.533-8.533 8.53 8.53 0 00-8.533-8.533zM333.059 307.205h-.085c-4.71 0-8.491 3.823-8.491 8.533s3.866 8.533 8.576 8.533c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533zM247.725 392.539h-.085c-4.71 0-8.491 3.823-8.491 8.533s3.866 8.533 8.576 8.533c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533zM375.683 298.672c0 4.71 3.866 8.533 8.576 8.533 4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533h-.085c-4.711 0-8.491 3.823-8.491 8.533z"></path>
            </g>
        </svg>
    );
}