import { XIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';

const Drawer = ({ children, width, title, drawerID, button, stateOverride, setStateOverride }) => {
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        setOpened(stateOverride)
    }, [stateOverride]);

    const handleClose = () => {
        if (stateOverride != null){
            setStateOverride(false)
        } else {
            setOpened(false)
        }
    }

    return (
        <div className="drawer drawer-end">
            <input checked={opened} id={drawerID} type="checkbox" className="drawer-toggle" />
            <div className="drawer-content">
                <label htmlFor={drawerID} onClick={() => setOpened(!opened)}>{button}</label>
            </div>
            <div className="drawer-side h-full z-10">
                <label htmlFor={drawerID} className="drawer-overlay"></label>

                <ul className={`menu p-4 h-full bg-white text-base-content max-h-full overflow-hidden overflow-y-auto w-[95%] md:w-[50%] lg:w-[${width}%]`}>
                    <div className="h-10">
                        <div className='flex items-center justify-between'>
                            {title != null ?
                                <>
                                    <h1 className='font-productsans-medium text-base'>{title}</h1>
                                    <div className='h-6 w-6 flex items-center justify-center cursor-pointer bg-gray-200 rounded-full' onClick={handleClose}><XIcon className="h-4 text-black" /></div>
                                </>
                                :
                                <div></div>
                            }
                        </div>

                        <div className=''>
                            <div className="h-full overflow-y-auto">
                                {children}
                            </div>
                        </div>
                    </div>
                </ul>
            </div>
        </div>
    );
}

export default Drawer;
