import React from 'react'
import { Template } from '../../template';
import { LoadingCollectionsExplore } from '../../common';

const NewAndNoteworthy = ({filteredSelection, loading, home }) => {

    function comparedate(a, b) {
        if(Date(a.date_created) < Date(b.date_created)) return -1
        else return 1
      }

      
    return (
        <div>
            {!home && 
            <div>
                <h5 className="section-header">New & Noteworthy</h5>
                <p className="andie-section-descriptor mb-0">The newest collections and packages curated by our partners.</p>
            </div>}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
                {loading ?
                        <LoadingCollectionsExplore />
                    :
                        filteredSelection.length <= 0 ?
                            <div className="flex items-center col-span-2 col-start-2 h-full justify-center font-roboto-medium">No collections under this category.</div>
                            :
                            filteredSelection.sort((a,b) => comparedate(a,b)).slice(0, 4).map((item, index) => {
                                return <Template item={item} key={index} highlight={true} noteworthy={true} />
                            })
                }
                
            </div>
        </div>

    )
}

export default NewAndNoteworthy