import React from "react";
import createbid from "./create-a-bid.png";
import editingbid from "./editing-a-bid.png";
import viewresponse from "./bid-responses-views.png";
import acceptresponse from "./accepting-a-response.png";


export default function TutorialBids() {
    return (
        <div className="space-y-6 mb-8">
            <div className="pb-6 border-b border-gray-100">
                <div className="w-3/4 space-y-3">
                <h6 className="font-productsans-bold text-lg">Creating a bid.</h6>
                <p className="andie-description-text leading-tight">You can create a new bid by clicking the <b>Create a bid</b> button on the Explore page, and entering all the requirements and specifications for the bid.</p>
                <div className="inline-flex bg-blue-200 p-2 mt-4 rounded-lg">
                    <img src={createbid} className="h-10 rounded-md" />
                </div>
                <p className="andie-description-text leading-tight">Your bid will automatically open and appear in the Bids section of the Explore page upon submission. If you account has not been verified, your bid will apply immediately after your verification is complete. <b>Please note that bidding on Pluck is restricted to verified accounts only</b>.</p>
                </div>
            </div>
            <div className="pb-6 border-b border-gray-100">
                <div className="w-3/4 space-y-3">
                    <h6 className="font-productsans-bold text-lg">Editing a bid.</h6>
                    <p className="andie-description-text leading-tight">You can edit and make changes to your bid at any time. Simply navigate to the <b>bid page</b> using the <b>Explore or Activity pages</b>.</p>
                </div>
                <div className="bg-blue-200 p-2 mt-4 rounded-lg">
                    <img src={editingbid} className="h-auto w-full rounded-md" />
                </div>
            </div>
            <div className="pb-4 border-b border-gray-100">
                <h6 className="font-productsans-bold text-lg">Viewing responses.</h6>
                <div className="mt-3 grid md:grid-cols-2 gap-4">
                    <div className="space-y-3">
                        <p className="andie-description-text leading-tight">You can view submitted responses for your bids (or any bids), by navigating to the <b>bid page</b> at any time and clicking the view button on any responses that have been submitted. Trending responses are also available on the <b>Explore pages</b> as a way to help you discover great offerings you might want to order.</p>
                        <p className="andie-description-text leading-tight">See something you like? You can request additional information about any responses by emailing <span className="font-roboto-medium text-blue-600">support@andie.work</span>.</p>
                    </div>
                    <div>
                        <div className=" bg-blue-200 p-3 rounded-lg">
                            <img src={viewresponse} className="h-auto w-full rounded-md" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb-4">
                <h6 className="font-productsans-bold text-lg">Accepting a response.</h6>
                <div className="mt-3 grid md:grid-cols-2 gap-4">
                    <div className="space-y-3 md:order-2">
                        <p className="andie-description-text leading-tight">You can accept a response by clicking the Accept button in the Response footer.</p>
                        <p className="andie-description-text leading-tight"><span className="font-roboto-medium">Catering Plus </span>opportunties allow you to combine multiple offerings and accept more than one response.</p>
                    </div>
                    <div>
                        <div className=" bg-blue-200 px-3 py-6 rounded-lg">
                            <img src={acceptresponse} className="h-auto w-full rounded-md" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}