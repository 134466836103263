import React, { useState } from 'react'
import { XIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';
import Modal from "react-responsive-modal";

const PopoutModal = ({ children, button, width, height, modalID, title, actionButton, footerElements, trigger }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (trigger == true) {
            toggleModal()
        }

    }, [trigger]);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    const styles = {
        modal: {
            height: height ? height: '95%',
            width: width ? width : '65%',
            borderRadius: "0.5rem",
            justifyContent: "center",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
        },
        modalContainer: {
            //need something to center this div
        }
    }
    const mobileStyles = {
        modal: {
            width: "100%",
            maxHeight: '90%',
            borderStartStartRadius: "1rem",
            borderStartEndRadius: "1rem",
            margin: 0,
            position: "absolute",
            bottom: 0,
            left: 0,
            paddingTop: 0,
        }
    }


    return (
        <div className=''>
            <span onClick={toggleModal}>
                {button}
            </span>

            <Modal
                center={!window.innerWidth < 768}
                blockScroll={false}
                open={isOpen}
                modalId={modalID}
                onClose={toggleModal}
                showCloseIcon={false}
                styles={window.innerWidth < 768 ? mobileStyles : styles}
                animationDuration={200}
                classNames={
                    window.innerWidth < 768 ? {
                        modalAnimationIn: 'enterMobileModalAnimation',
                        modalAnimationOut: 'leaveMobileModalAnimation',

                    } : {}
                }
            >
                <div className={`sticky top-0 z-10 bg-white border-b font-productsans-medium 
                text-base text-gray-700 flex py-3.5 md:andie-modal-padding 
                ${title ? 'justify-between' : 'justify-end'}`}>
                    {title}
                    <div className="bg-gray-200 h-6 w-6 shrink-0 flex items-center justify-center rounded-full">
                        <XIcon onClick={() => toggleModal()} className='h-4 text-gray-600 cursor-pointer' />
                    </div>
                </div>

                <div className='flex flex-col pt-4 w-full md:andie-modal-padding'>
                    {children}
                </div>

                {footerElements ?
                    <div className={`sticky bottom-0 justify-center md:andie-modal-padding bg-white border-t`}>
                        {footerElements}
                        {actionButton &&
                            <div onClick={() => toggleModal()} className="right-5">
                                {actionButton}
                            </div>
                        }
                    </div> :
                    actionButton &&
                    <div onClick={() => toggleModal()} className="right-5">
                        {actionButton}
                    </div>
                }


            </Modal>
        </div>
    );
};


export default PopoutModal
