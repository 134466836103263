import React from 'react'
import ItemView from './ItemView'
import { eventOptions } from '../../common'

const TopCategories = ({filters, setFilters}) => {

        const handleEventFilter = (eventoption) => {

        if (filters.globalFilters?.event == null) {

            setFilters((prevFilters) => ({
                ...prevFilters,
                globalFilters: {
                    ...prevFilters.globalFilters,
                    event: eventoption,
                },
            }));
        } else {
            if (filters.globalFilters.event == eventoption) {
                setFilters((prevFilters) => ({
                    ...prevFilters,
                    globalFilters: {
                        ...prevFilters.globalFilters,
                        event: null,
                    },
                }));
            } else {
                setFilters((prevFilters) => ({
                    ...prevFilters,
                    globalFilters: {
                        ...prevFilters.globalFilters,
                        event: eventoption,
                    },
                }));
            }
        }
    }


    return (
        <div className="mt-7 md:mt-9 lg:mt-12">
            <div className="flex items-end justify-between" >
                <div>
                    <h5 className="section-header">Top Categories</h5>
                    <p className="hidden lg:flex andie-section-descriptor mb-0">Discover packages based these popular event types.</p>
                </div>
                <div onClick={() => {
                    window.eventsModal.showModal()
                }} className="andie-text-button group">
                    View all
                </div>
                <dialog id="eventsModal" className="modal modal-bottom sm:modal-middle">
                    <form method="dialog" className="modal-box">
                        <h3 className="font-productsans-medium text-base text-gray-700 mb-4">Event Types</h3>
                        <div className="grid grid-cols-3 gap-2">
                            {eventOptions.map((eventoption, index) => (
                                <ItemView item={eventoption} func={handleEventFilter} filters={filters} />
                            ))}
                        </div>
                        <div className="modal-action">
                            <div>
                                <button className="andie-dark-button h-8 w-28">Close</button>
                            </div>
                        </div>
                    </form>
                </dialog>
            </div>
            <div className="grid grid-cols-3 lg:grid-cols-6 gap-2 mt-4">
                {eventOptions.slice(0, 6).map((eventoption, index) => (
                    <ItemView item={eventoption} func={handleEventFilter} filters={filters} />
                ))}
            </div>
        </div>
    )
}

export default TopCategories