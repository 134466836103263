import React, { useState } from "react";
import moment from "moment";
import { LinkIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/solid";
import Autocomplete from "react-google-autocomplete";
import Select from 'react-select';
import ReactDatePicker from "react-datepicker";

import { AdminBidsResponseBar } from "../";
import { BiddingAPIs } from "../../../apis";
import { foodOptions } from "../../common";
import AdminBidEdit from "./AdminEditBid";
import AdminBidsInfo from "./AdminBidsInfo";

export default function AdminBidsBidBar({ bid, cgeAccountList, staffAccountList, businessAccountList }) {
    const [rep, setRep] = useState(bid.cge_representative);

    const [edited, setEdited] = useState(false)
    const [eventDate, setEventDate] = useState(new Date(bid?.event_date_time))
    const [expiryDate, setExpiryDate] = useState(new Date(bid?.expiry_date))
    const [selectedTab, setSelectedTab] = useState(bid?.responses.length > 0 ? bid?.responses[0].id : -1)

    const navigate = useNavigate();

    function get_cge_rep_under_bid() {
        const bid_email = bid?.cge_email
        const cge_rep = cgeAccountList.filter(item => item.email === bid_email)[0].cge_representative
        if (cge_rep != null) {
            if (bid['cge_representative'] == "") {
                bid['cge_representative'] = cge_rep
            }
            return cge_rep.email

        } else {
            bid['cge_representative'] = bid['cge_representative']
            return ""
        }

    }

    const filterPassedTime = (time) => {
        return moment(moment().toDate()).add(1, "d") < moment(time);
    };

    const filterDate = (date) => {
        return moment(date) > moment(moment().toDate());
    };

    const filterDateExpiry = (date) => {
        return moment(date).isBetween(moment(moment().toDate()).add(-1, 'd'), moment(new Date(bid?.event_date_time)).toDate(), null, [])
    };

    const filterPassedTimeExpiry = (time) => {
        const eventTimeMinus4Hours = moment(moment(new Date(bid?.event_date_time)).toDate()).add(-4, "h");
        return moment(time).isBetween(moment(), eventTimeMinus4Hours, null, "[]");
    };


    function handleOpenBid(bid) {
        navigate('/bid/' + bid.transaction_code + '/')
    }

    const submitChanges = async () => {
        if (edited) {
            const payload = bid
            await BiddingAPIs.editBid(payload)
        }
    }

    const handleBidEdit = (valuename, value) => {
        bid[valuename] = value
        setEdited(true)
    }

    const handleBidExpiryDate = (date) => {
        setExpiryDate(date)
        handleBidEdit('expiry_date', moment(date).format())
        setEdited(true)
    }

    const handleBidEventDate = (date) => {
        setEventDate(date)
        handleBidEdit('event_date_time', moment(date).format())
        setEdited(true)
    }

    const handleBidLocationEdit = (place) => {
        bid['locationAdditionalDetails'] = getLocationDetails(place.address_components)
        bid['location'] = place.formatted_address
        bid['locationPlaceID'] = place.place_id
        bid['locationGeometry'] = place.geometry.location
        setEdited(true)
    }

    function getLocationDetails(loc) {
        var country = loc.filter(item => item.types.includes('country')).map(item => item.short_name)
        var province = loc.filter(item => item.types.includes('administrative_area_level_1')).map(item => item.short_name)
        var city = loc.filter(item => item.types.includes('administrative_area_level_2')).map(item => item.short_name)

        return (city + ', ' + province + " " + country)
    }

    return (
        <div className="andie-description-text flex flex-col flex-grow mb-5 xl:w-[650px]">
            <AdminBidsInfo bid={bid} staffAccountList={staffAccountList} cgeAccountList={cgeAccountList} />
            {bid?.responses.length > 0 ?
                <>
                    <div className="">
                        <div className="flex flex-row justify-between items-center">
                            <div className="tabs tabs-boxed w-full bg-inherit space-x-1 pb-2 border-b ">
                                {bid?.responses.map((response, index) => (<>
                                    <a onClick={() => setSelectedTab(response.id)} className={`tab tab-md ${selectedTab == response.id ? "bg-blue-600 text-white" : "bg-white border border-blue-600 text-blue-600"}`}>Response #00{response.id}</a>
                                </>))}
                            </div>
                        </div>

                        <div className="mt-4">

                            {bid?.responses.map((response, index) => (
                                (selectedTab == response.id) &&
                                <AdminBidsResponseBar bid={bid} response={response} businessAccountList={businessAccountList} cgeAccountList={cgeAccountList} staffAccountList={staffAccountList} />
                            ))}

                        </div>
                    </div>
                </>
                :
                <></>}
        </div>
    )
}