import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import Autocomplete from "react-google-autocomplete";
import { CheckIcon, InformationCircleIcon } from "@heroicons/react/solid";

import { eventOptions } from '../../common';

export default function BidDetails({ page, cgeType, eventType, setEventType, guestCount, setGuestCount, setLocation, setLocationPlaceID, setLocationGeometry, setLocationAdditionalDetails, eventDateTime, setEventDateTime, setSelectedTab, item, accessibility, setAccessibility, endDateTime, setEndDateTime, recurringPeriod, setRecurringPeriod, recurring, setRecurring, location, locationSecondaryDetails, setLocationSecondaryDetails, isNextButtonDisabled, setIsNextButtonDisabled, deliveryTime, setDeliveryTime }) {
    const filterPassedTime = (time) => {
        return moment(moment().toDate()).add(1, "d") < moment(time);
    };
    // const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

    function selectEventType(option) {
        setEventType(option);
    }

    const recurringoptions = ['none', 'month', 'week']

    useEffect(() => {
        if (guestCount <= 0 || !location) {
            setIsNextButtonDisabled(true);
        }
        else {
            setIsNextButtonDisabled(false)
        }

    }, [guestCount, location])

    useEffect(() => {
        if (moment(eventDateTime).isBefore(moment().add(1, 'd'))) {
            // toast.error("Expiry Date cannot be in the past")
            setEventDateTime(moment().add(1, 'd').add(1, 'h').toDate())
        }
    }, [eventDateTime])

    useEffect(() => {
        if (recurring === false) {
            setRecurringPeriod('month')
        }
    }, [recurring]);


    const filterDate = (date) => {
        return moment(date) > moment(moment().toDate());
    };

    function handleAccessibility(item) {
        let arr = { ...accessibility }
        arr[item] = !arr[item]
        setAccessibility(arr)
    }

    function getLocationDetails(loc) {
        var country = loc.filter(item => item.types.includes('country')).map(item => item.short_name)
        var province = loc.filter(item => item.types.includes('administrative_area_level_1')).map(item => item.short_name)
        var city = loc.filter(item => item.types.includes('administrative_area_level_2')).map(item => item.short_name)

        return (city + ', ' + province + " " + country)
    }

    function convertRecurringOptionToText(option) {
        if (option == 'week') {
            return "Repeats Weekly"
        } else if (option == 'month') {
            return "Repeats Monthly"
        } else {
            return "Does not Repeat"
        }
    }

    return (
        <div className="h-full flex flex-col justify-between">
            <div className="flex flex-col xl:w-[70%]">
                <div className="w-2/5 xl:w-1/4">
                    <h6 className="bid-form-input-label">No. of Guests</h6>
                    <div className="border-2 rounded h-11 px-3 flex items-center border-gray-100">
                        <input
                            className="w-full text-right px-0 border-0 focus:outline-none focus:border-b-2 focus:border-blue-500 rounded-none font-roboto-regular text-paragraph text-gray-500"
                            id="guest_count"
                            type="number" onWheel={(e) => e.target.blur()}
                            min="1"
                            placeholder="0"
                            required
                            aria-label="Guest_count"
                            onChange={(e) => setGuestCount(e.target.value)}
                            value={guestCount}
                        />
                    </div>
                </div>
                <div className="mt-4 space-y-5">
                    <div className="md:flex md:space-x-5 justify-between pt-5">
                        <div className="md:w-3/5 w-4/5 mb-5 xl:mb-0">
                            <h6 className="bid-form-input-label">{cgeType == "Catering" || cgeType == "Catering Plus" ? "Event Location" : "Preferred Area"}</h6>

                            <Autocomplete
                                className="w-full bid-form-input"
                                // apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                required
                                // id="location"
                                onPlaceSelected={(place) => {
                                    setLocationAdditionalDetails(getLocationDetails(place.address_components));
                                    setLocation(place.formatted_address)
                                    setLocationPlaceID(place.place_id)
                                    setLocationGeometry(JSON.stringify(place.geometry.location))
                                }}
                                options={{ types: ["geocode", "establishment"] }}
                                placeholder={cgeType == "Catering" || cgeType == "Catering Plus" ? "Enter an address or location" : "Please enter a specific city or neighborhood"}
                                defaultValue={location}
                            />
                            {(cgeType == "Catering" || cgeType == "Catering Plus") &&
                                <input
                                    className="w-full bid-form-input mt-2"
                                    id="secondary_details_input"
                                    onChange={(e) => setLocationSecondaryDetails(e.target.value)}
                                    type="text"
                                    required
                                    placeholder="Apt, Suite, Unit, Building"
                                    aria-label="Bid Title"
                                    defaultValue={locationSecondaryDetails}
                                />
                            }
                            {(cgeType == "Dining" || cgeType == "Premium Dining") &&
                                <div className="mt-3 flex space-x-2.5">
                                    <InformationCircleIcon className="text-gray-400 h-3.5" />
                                    <p className="w-4/5 andie-section-descriptor text-gray-400 mr-5">E.g. Downtown Toronto, King West, or Los Angeles.</p>
                                </div>
                            }
                        </div>
                        <div className="md:w-2/5 w-4/5">
                            <h6 className="bid-form-input-label">Event Date and time</h6>
                            <DatePicker
                                name="date"
                                required
                                showTimeSelect
                                filterTime={filterPassedTime}
                                filterDate={filterDate}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className="w-full bid-form-input"
                                selected={eventDateTime}
                                onChange={(date) => setEventDateTime(date)}
                                minDate={moment(moment().toDate())}
                                nextMonthButtonLabel=">"
                                previousMonthButtonLabel="<"
                            />
                            {page == "ordernow" ?
                                null
                                :

                                <>
                                    <div className="dropdown dropdown-bottom w-full">
                                        <div tabIndex={0} onClick={() => setRecurring(!recurring)} className='flex items-start mt-2.5'>
                                            <div className={`cursor-pointer border-2 h-4 w-4 flex flex-shrink-0 items-center justify-center rounded ${recurring ? 'bg-blue-600  border-blue-600' : 'border-gray-300'}`}>
                                                {recurring && <CheckIcon className="h-4 rounded-sm text-white" />}
                                            </div>
                                            <h6 className="ml-2 bid-form-input-label leading-tight mb-0">Is this a recurring event?</h6>
                                        </div>

                                        {recurring ?
                                            <div className="dropdown-content flex flex-row z-[1] p-2 shadow bg-base-100 rounded-box w-auto">
                                                <ul tabIndex={0} className="menu">
                                                    <li className={recurringPeriod == "week" && "bg-blue-600 text-white rounded-md"}
                                                        onClick={() => {
                                                            setRecurring(true)
                                                            setRecurringPeriod('week')
                                                        }}><a>Repeats weekly</a></li>

                                                    <li className={recurringPeriod == "month" && "bg-blue-600 text-white rounded-md"}
                                                        onClick={() => {
                                                            setRecurring(true)
                                                            setRecurringPeriod('month')
                                                        }}><a>Repeats monthly</a></li>
                                                </ul>

                                                <div className="">
                                                    <h6 className="w-60 bid-form-input-label text-sm font-productsans-medium col-span-3 mt-2">Until:</h6>
                                                    <DatePicker
                                                        name="date"
                                                        required
                                                        showTimeSelect
                                                        filterTime={filterPassedTime}
                                                        filterDate={filterDate}
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        className="w-48 bid-form-input"
                                                        selected={endDateTime}
                                                        onChange={(date) => setEndDateTime(date)}
                                                        minDate={moment(moment().toDate())}
                                                        nextMonthButtonLabel=">"
                                                        previousMonthButtonLabel="<"
                                                    />
                                                </div>
                                            </div>
                                            : <></>}
                                    </div>
                                </>
                            }

                        </div>
                        <div className="md:w-1/5 w-3/5">
                            <h6 className="bid-form-input-label">{cgeType == "Catering" || cgeType == "Catering Plus" ? "Delivery Time" : "Serving Time"}</h6>
                            <DatePicker
                                name="time"
                                required
                                showTimeSelectOnly
                                showTimeSelect
                                // filterTime={filterPassedTime}
                                filterDate={filterDate}
                                dateFormat="h:mm aa"
                                className="w-full bid-form-input"
                                selected={deliveryTime}
                                onChange={(date) => setDeliveryTime(date)}
                                minDate={moment(moment().toDate())}
                                nextMonthButtonLabel=">"
                                previousMonthButtonLabel="<"
                            />
                        </div>
                    </div>
                    <div className="xl:w-3/4">
                        <>
                            <h6 className="bid-form-input-label mb-0 mt-8">{cgeType == "Catering" || cgeType == "Catering Plus" ? "Location" : "Preferred"} Amenities</h6>
                            <p className="andie-section-descriptor">Please select the facilities available at this location.</p>
                        </>
                        <div className="grid grid-cols-2 md:grid-cols-4">
                            {Object.keys(accessibility).map((item, index) => (
                                <div key={index} className={`flex space-x-1 items-center text-gray-500 bid-form-input-label ${item === "Service Entrance" && "md:col-span-2"}`}>
                                    <div onClick={() => handleAccessibility(item)} className={`cursor-pointer border-2 h-4 w-4 flex flex-shrink-0 items-center justify-center rounded ${accessibility[item] ? 'bg-blue-600  border-blue-600' : 'border-gray-300'}`}>
                                        {accessibility[item] && <CheckIcon className="h-4 rounded-sm text-white" />}
                                    </div>
                                    <p>
                                        {item}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-2/5">
                        <h6 className="bid-form-input-label mt-8">Event Type</h6>
                        <div className="bid-form-input bg-blue-100 border-0">
                            <select id="event_type" defaultValue={eventType} onChange={(e) => selectEventType(e.target.value)} className="-ml-1.5 bg-transparent w-full focus:outline-none">
                                {eventOptions.map((event, index) => (
                                    <option>{event.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {/* <MenuButtons func={setSelectedTab} check={isNextButtonDisabled} /> */}
        </div>
    )
}