import { useState } from "react"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { XIcon } from "@heroicons/react/solid"

import { BiddingAPIs } from "../../apis"
import { PopoutModal } from "../common"

export default function BidCancellationButton({ bid }) {
    const [closeCancelReason, setCloseCancelReason] = useState('')
    const [closeCancelReasonOther, setCloseCancelReasonOther] = useState('')

    const navigate = useNavigate();

    function calculateCancelFee() {
        const bidAlreadyAccepted = bid?.responses.filter(response => response.accepted == true).length > 0
        const timeToEvent = Math.abs(new Date(bid?.event_date_time) - new Date()) / (1000 * 3600)
        let fee
        if (bidAlreadyAccepted) {
            if (timeToEvent <= 48) {
                fee = 1
            } else {
                fee = 0.5
            }
        } else {
            fee = 0
        }
        return fee
    }

    function cancelBid() {
        const fee = calculateCancelFee()
        if (closeCancelReason == "") {
            toast.error("Please provide a reason.")
        } else {
            var reasonstring = closeCancelReason
            if (closeCancelReasonOther != "") {
                reasonstring += ": " + closeCancelReasonOther
            }
            const payload = {
                id: bid.id,
                mode: 'cancel',
                reason: reasonstring,
                fee: fee
            }
            BiddingAPIs.closeBid(payload)
            navigate('/home/bids')
        }
    }

    function closeBid() {
        const fee = calculateCancelFee()
        if (closeCancelReason == "") {
            toast.error("Please provide a reason.")
        } else {
            var reasonstring = closeCancelReason
            if (closeCancelReasonOther != "") {
                reasonstring += ": " + closeCancelReasonOther
            }
            const payload = {
                id: bid.id,
                mode: 'close',
                reason: reasonstring,
                fee: fee
            }
            BiddingAPIs.closeBid(payload)
            navigate('/home/bids')
        }
    }

    return (
        (calculateCancelFee() > 0) ?
            <PopoutModal
                title={"Cancel your bid"}
                button={
                    <h6 className="text-xs text-red-500 font-productsans-bold cursor-pointer">Cancel bid</h6>
                }
                modalID={'cancel_bid_modal'}
                footerElements={
                    <>
                        <p className="mt-10 label-text">I agree that I will not be able to cancel my order transaction.</p>
                        <button onClick={() => cancelBid()} className="bg-blue-200 mt-2 mb-5 text-xs font-productsans-medium text-gray-500 h-8 w-full rounded px-2.5">Cancel Bid</button>
                    </>
                }
            >
                <div className="flex items-center space-x-2 mb-5">
                    <div className="grid grid-cols-1 gap-4">
                        <div className="col-span-2">
                            <div className="form-control">
                                <label className="label cursor-pointer col-span-1 gap-x-10">
                                    <span className="label-text">The event is no longer happening.</span>
                                    <input onChange={(e) => setCloseCancelReason("I did not receive a sufficient amount of responses.")} type="radio" name="radio-10" className="radio" />
                                </label>
                                <label className="label cursor-pointer col-span-1 gap-x-10">
                                    <span className="label-text">I created this bid as a test.</span>
                                    <input onChange={(e) => setCloseCancelReason("I don't like any of the responses I received.")} type="radio" name="radio-10" className="radio" />
                                </label>
                                <label className="label cursor-pointer col-span-1 gap-x-10">
                                    <span className="label-text">Other (Please Specify)</span>
                                    <input onChange={(e) => setCloseCancelReason("Other")} type="radio" name="radio-10" className="radio" />
                                </label>
                                {closeCancelReason == "Other" ?
                                    <textarea
                                        className="w-full h-full andie-description-text leading-tight text-gray-500 border-2 rounded border-gray-100"
                                        id="closeCancelReasonOther"
                                        onChange={(e) => setCloseCancelReasonOther(e.target.value)}
                                        type="text"
                                        required
                                        placeholder=""
                                        aria-label="closeCancelReasonOther"
                                    /> : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </PopoutModal>
            :
            <PopoutModal
                title={"Close your bid"}
                button={
                    <h6 className="text-xs text-red-500 font-productsans-bold cursor-pointer">Close bid</h6>
                }
                modalID={'close_bid_modal'}
                footerElements={
                    <button onClick={() => closeBid()} className="bg-blue-200 mb-5 text-xs font-productsans-medium text-gray-500 h-8 w-full rounded px-2.5">Close Bid</button>
                }
            >
                <div>
                    <div className="flex items-center space-x-2 mb-5">
                        <div className="grid grid-cols-1 gap-4">
                            <div className="col-span-2">
                                <div className="form-control">
                                    <label className="label cursor-pointer col-span-1 gap-x-10">
                                        <span className="label-text">I did not receive a sufficient amount of responses.</span>
                                        <input onChange={(e) => setCloseCancelReason("I did not receive a sufficient amount of responses.")} type="radio" name="radio-10" className="radio" />
                                    </label>
                                    <label className="label cursor-pointer col-span-1 gap-x-10">
                                        <span className="label-text">I don't like any of the responses I received.</span>
                                        <input onChange={(e) => setCloseCancelReason("I don't like any of the responses I received.")} type="radio" name="radio-10" className="radio" />
                                    </label>
                                    <label className="label cursor-pointer col-span-1 gap-x-10">
                                        <span className="label-text">The event has been cancelled.</span>
                                        <input onChange={(e) => setCloseCancelReason("The event has been cancelled.")} type="radio" name="radio-10" className="radio" />
                                    </label>
                                    <label className="label cursor-pointer col-span-1 gap-x-10">
                                        <span className="label-text">Other (Please Specify)</span>
                                        <input onChange={(e) => setCloseCancelReason("Other")} type="radio" name="radio-10" className="radio" />
                                    </label>
                                    {closeCancelReason == "Other" ?
                                        <textarea
                                            className="w-full h-full andie-description-text leading-tight text-gray-500 border-2 rounded border-gray-100"
                                            id="closeCancelReasonOther"
                                            onChange={(e) => setCloseCancelReasonOther(e.target.value)}
                                            type="text"
                                            required
                                            placeholder=""
                                            aria-label="closeCancelReasonOther"
                                        /> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ PopoutModal>
    )
}