import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../components/navigation";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import dining from "../../assets/images/categories/dining.png";
import catering from "../../assets/images/categories/catering.png";
import experiences from "../../assets/images/categories/experiences.png";
import events from "../../assets/images/categories/events.png";
import moment from "moment";
import toast from "react-hot-toast";
import { AccountsAPI } from "../../apis";
import { Logo } from "../../components/common";

export default function AccountPasswordReset() {
    const { uid, token} =  useParams();
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const navigate = useNavigate();

    const footer = [
        {
          title: "Privacy Policy",
          link: "/policies/privacy-policy",
        },
        {
          title: "Terms",
          link: "/policies/terms-of-use",
        },
        {
          title: "Support",
          link: "/support",
        },
        {
          title: "About Pluck",
          link: "https://pluckbids.com",
        },
    ];

    async function resetPassword(e) {
        e.preventDefault();
        if (password.trim() == "" || rePassword.trim() == ""){
            toast.error("Please fill out both password fields!")
        } else if (password != rePassword) {
            toast.error("Both passwords must match!")
        } else {
            const data = {
                "uid": uid,
                "token": token,
                "new_password": password,
                "re_new_password": rePassword
            }
            const res = AccountsAPI.resetPassword(JSON.stringify(data)).then(() => navigate('/login'))
        }
    }

    return (
        <>
            <div className="hidden">
                <Header bypassToken={true} />
            </div>
            <div className="h-screen flex overflow-hidden">
                <div className="h-full flex flex-col justify-between md:w-1/2 lg:w-1/3 p-4 bg-emerald-800">
                <div className="flex justify-start mt-1">
                    <QuestionMarkCircleIcon className="h-5 text-white" />
                </div>

                <div className="my-12 lg:mt-24 lg:mb-20 mx-12">
                    <h3 className="md:text-xl lg:text-4xl font-productsans-medium text-white leading-0">
                    Great corporate guest experiences start here.
                    </h3>
                    <div className="my-10 flex flex-wrap">
                    <div className="w-1/4 items-center mb-5">
                        <img
                        src={dining}
                        alt="Dining"
                        className={`object-cover h-11`}
                        />
                        <h6 className="text-gray-300 ml-0.5 font-productsans-medium text-xs">
                        Dining
                        </h6>
                    </div>
                    <div className="w-1/4 items-center mb-5">
                        <img
                        src={catering}
                        alt="Dining"
                        className={`object-cover h-10`}
                        />
                        <h6 className="text-gray-300 mt-1 font-productsans-medium text-xs">
                        Catering
                        </h6>
                    </div>
                    <div className="w-1/4 items-center mb-5">
                        <img
                        src={experiences}
                        alt="Dining"
                        className={`object-cover h-11`}
                        />
                        <h6 className="text-gray-300 font-productsans-medium text-xs">
                        Experiences
                        </h6>
                    </div>
                    <div className="w-1/4 flex flex-col justify-end items-center mb-5">
                        <img
                        src={events}
                        alt="Dining"
                        className={`object-cover h-10`}
                        />
                        <h6 className="text-gray-300 mt-1 font-productsans-medium text-xs">
                        Events
                        </h6>
                    </div>
                    <div className="w-1/4 items-center mb-5">
                        <h6 className="text-gray-300 mt-1 font-productsans-medium text-xs">
                        + More
                        </h6>
                    </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center">
                    <h2 className="font-productsans-regular text-gray-100 text-4xl">
                    {moment(new Date()).format("h:mma")}
                    </h2>
                    <p className="text-xs font-sf-regular text-white">
                    {moment(new Date()).format("dddd, MMMM Do YYYY")}
                    </p>
                </div>
                <div className="flex items-center justify-between md:mt-12 xl:mt-28 mb-1">
                    <h4 className="font-productsans-regular text-xl text-white">
                    Pluck
                    </h4>
                    <p className="text-xs font-sf-regular text-white mr-2">
                    Private Access
                    </p>
                </div>
                </div>
                <div className="w-full md:w-1/2 lg:w-2/3 py-4 px-5 flex flex-col justify-between">
                <div className="flex items-center justify-between">
                    <Logo />
                    <div className="flex justify-end">
                        <div className="text-indigo-600 text-xs font-sf-semibold flex justify-center items-center bg-blue-100 h-8 w-8 rounded-full">
                            GU
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center items-center">
                    <h3 className="md:text-xl lg:text-2xl font-productsans-medium leading-0"> Reset Account Password </h3>
                    <p className="text-xs andie-description-text xl:mx-60 pb-12"> Enter a new password for your account </p>
                    <form onSubmit={(e) => resetPassword(e)}>
                        <label class="label" className="space-y-2">
                            <span class="label-text">Password</span>
                        </label>
                        <input type="password" placeholder="********" value={password} onChange={(e) => setPassword(e.target.value)} class="bid-form-input w-full max-w-xs" />
                        <label class="label">
                            <span class="label-text">Confirm Password</span>
                        </label>
                        <input type="password" placeholder="********" value={rePassword} onChange={(e) => setRePassword(e.target.value)} class="bid-form-input w-full max-w-xs" />
                        <button className="andie-dark-button mt-4"> Submit </button>
                    </form>
                </div>
                

                <div className="flex justify-center">
                    <div className="flex space-x-5 mb-2">
                    {footer.map((item, index) => (
                        <a
                            target="_blank"
                            href={item.link}
                            key={index}
                            rel="noreferrer"
                            className="text-docoration-none cursor-pointer text-xs font-roboto-medium text-gray-500"
                        >
                        {item.title}
                        </a>
                    ))}
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}