import { CubeIcon } from "@heroicons/react/solid";

export default function LoadingActivityBids() {
    let arr = [1, 2, 3, 4, 5, 6, 7, 8]
    return (
        <div className="animate-pulse">
            <div className="hidden md:inline-flex w-full justify-between items-center border-b pb-3 px-4 md:px-0">
                <h6 className="bid-form-input-label font-roboto-regular mb-0 text-gray-600">Showing <span className="text-blue-600 bg-gray-200 font-productsans-bold rounded-sm">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> responses that you created </h6>
                <div className="flex space-x-2 items-center">
                    <p className="font-roboto-medium text-paragraph">Sort by:</p>
                    <select class="bid-form-input h-8 p-1">
                        <option selected value={"All"}>All</option>
                        <option value={"Upcoming"}>Upcoming</option>
                        <option value={"Posted"}>Posted</option>
                        <option value={"Expired"}>Expired</option>
                        <option value={"Closed"}>Closed</option>
                        <option value={"Accepted"}>Accepted</option>
                        <option value={"Order Now"}>Package Order</option>
                        <option value={"Completed"}>Completed</option>
                    </select>
                </div>
            </div>
            <div className="flex flex-col-reverse md:flex-row h-full">
                <div className="h-full flex-grow-0 overflow-y-scroll scrollbar-hide md:grid md:grid-cols-2 gap-3 md:pt-6 w-full md:w-2/3 lg:w-3/5 lg:pr-5 pb-32 md:pb-40">
                    {arr.map(() => {
                        return (
                            <div className="cursor-pointer border-2 flex flex-col justify-between h-36 rounded-md border-gray-100 px-3.5 py-3">
                                <div>
                                    <div className="flex justify-between">
                                        <div className="flex flex-col w-3/4 space-y-2">
                                            <div className='h-2 bg-gray-200 w-8/12'/>
                                            <div className='h-2 bg-gray-200 w-8/12'/>
                                        </div>
                                        <div className="w-2/5 flex items-center justify-end">
                                            <CubeIcon className="text-gray-300 h-3.5" />
                                        </div>
                                    </div>

                                    <div className='flex flex-row w-full space-x-4 mt-2'>
                                        <div className='h-2 bg-gray-200 w-3/12'/>
                                        
                                        <div className='h-2 bg-gray-200 w-3/12'/>

                                    </div>
                                    
                                </div>

                                <div className="mb-1 flex flex-row items-end justify-between">
                                    <div className="flex flex-col w-full space-y-2">
                                        <div className='h-2 bg-gray-200 w-8/12'/>
                                        <div className='h-2 bg-gray-200 w-8/12'/>
                                    </div>
                                    <div className='h-2 bg-gray-200 w-8/12'/>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="md:w-1/3 lg:w-2/5 flex flex-col md:items-end md:pl-0 lg:pl-4 md:pt-6">
                    <input className="bid-form-input mb-3.5 w-full md:w-2/3 bg-gray-200" disabled />
                    <div className="hidden md:flex md:flex-col md:w-2/3 items-end space-y-5">
                        <div className='h-2 bg-gray-200 w-8/12'/>
                        <div className='h-2 bg-gray-200 w-6/12'/>
                        <div className='h-2 bg-gray-200 w-4/12'/>
                        <div className='h-2 bg-gray-200 w-3/12'/>
                    </div>
                </div>
            </div>
        </div>
    )
}