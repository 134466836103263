import { ChatIcon, CheckIcon, InformationCircleIcon as InformationCircleIcon2, XIcon } from "@heroicons/react/solid";
import { Elements } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

import { BidAccept } from "../bidpage";
import { BiddingAPIs } from "../../apis";
import { PopoutModal, getTransactionAmounts } from "../common";
import { BillingForm } from "../billing";
import { useEffect } from "react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST)

export default function BidResponseFooter({ bid, bidResponse, interested, accepted, drawerWidth, setDrawerWidth, setshowDrawer }) {
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user_details')));
    const [openModal, setOpenModal] = useState(false);
    const [tax, setTax] = useState(0)
    const [serviceFee, setServiceFee] = useState(0)
    const [subtotal, setSubtotal] = useState(0)
    const [deliveryFee, setDeliveryFee] = useState(0)
    const [total, setTotal] = useState(0)
    const [tipInput, setTipInput] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        let transItems = {}
        if (bid.cge_type !== "Dining" && bid.cge_type !== "Premium Dining") {
            transItems = getTransactionAmounts(bidResponse.items, bid.budget.tipAmount, bidResponse.delivery_fee, bidResponse.business_delivers, bid.cge_service_fee, bid.business_service_fee, null, bidResponse.pricing, bidResponse.total_price)
        } else {
            transItems = getTransactionAmounts(bidResponse.items, bid.budget.tipAmount, 0, false, bid.cge_service_fee, bid.business_service_fee, null, bidResponse.pricing, bidResponse.total_price)
        }
        const subTot = transItems.subtotal
        const dFee = transItems.deliveryFee
        const svFee = transItems.serviceFee
        const tx = transItems.tax
        const total = transItems.total
        bidResponse['bid'] = bid

        setTax(tx)
        setDeliveryFee(dFee)
        setServiceFee(svFee)
        setSubtotal(subTot)
        setTotal(total)
    }, [])

    async function rejectBiR(responseId) {
        const payload = {
            id: responseId
        }

        await BiddingAPIs.rejectBidResponse(payload).then(() =>
            navigate('/home/bids')
        )
    }

    async function handleSubmit(paymentInfo) {
        try {
            const payload = {
                bidResponseID: bidResponse.id,
                paymentMethodID: paymentInfo.paymentMethod.id,
                cgeStripeID: user.stripe_account_token,
                subtotal: subtotal,
                delivery_fee: deliveryFee,
                business_delivers: bidResponse.business_delivers,
                tax: tax,
                serviceFee: serviceFee,
                total: total,
                tip: bid.budget.tipAmount,
            }
            
            const res = await BiddingAPIs.acceptBidResponse(
                payload
            ).then(() => {
                setshowDrawer(false);
            })
        } catch (e) {
        }
    }

    function renderResponseCtrl() {
        if (user != null && user.account_type == 1 && user.email == bidResponse.cge_email) {
            if (!interested) {
                if ((bid.cge_type == "Catering Plus" && bidResponse.accepted == false) || accepted == false) {
                    return (
                        <div className="z-10">
                            <div className="grid grid-cols-3 gap-x-2">
                                <div onClick={() => rejectBiR(bidResponse.id)}>
                                    <div className="cursor-pointer flex justify-center items-center h-8 px-1 hover:bg-red-50  border-2 border-gray-100 rounded-md">
                                        <XIcon className="h-4 text-red-600 mr-1" />
                                        <p className="text-paragraph font-productsans-medium text-red-600">Reject</p>
                                    </div>
                                </div>
                                <div onClick={() => { drawerWidth == 40 ? setDrawerWidth(65) : setDrawerWidth(40) }}>
                                    <div className="cursor-pointer flex justify-center items-center h-8 px-2 hover:bg-blue-50 border-2 border-gray-100 rounded-md">
                                        <ChatIcon className="h-4 text-blue-600 mr-1" />
                                        <p className="text-paragraph font-productsans-medium text-blue-600">Message</p>
                                    </div>
                                </div>
                                {/* <div onClick={() => setOpenModal(true)}>
                                    <div className="cursor-pointer flex justify-center h-8 items-center border-0 bg-blue-600 rounded-md">
                                        <CheckIcon className="h-4 text-white mr-1" />
                                        <p className="text-paragraph text-white font-productsans-medium">Accept</p>
                                    </div>
                                </div> */}
                                <PopoutModal
                                    title={"Billing and Payment"}
                                    button={
                                        <button className="w-full">
                                            <div className="cursor-pointer flex justify-center h-8 items-center border-0 bg-blue-600 rounded-md">
                                                <CheckIcon className="h-4 text-white mr-1" />
                                                <p className="text-paragraph text-white font-productsans-medium">Accept</p>
                                            </div>
                                        </button>}
                                    modalID={'bid_accept_modal'}
                                    // footerElements={
                                    //     <button onClick={() => console.log()} className="bg-blue-200 mb-5 text-xs font-productsans-medium text-gray-500 h-8 w-full rounded px-2.5">Yes</button>
                                    // }
                                >
                                        <div className="w-[50vw]">
                                            <Elements stripe={stripePromise} >
                                                {/* <BillingForm
                                                    bid={bid}
                                                    response={bidResponse} 
                                                    handleSubmit={(paymentInfo) => handleSubmit(paymentInfo)}
                                                    respType={"Bid Response"}
                                                    tax={tax} serviceFee={serviceFee} subtotal={subtotal} 
                                                /> */}
                                                <BillingForm
                                                    serviceFeeRate={bid.cge_service_fee}
                                                    subtotal={subtotal}
                                                    total={total}
                                                    tax={tax}
                                                    tipInput={tipInput} setTipInput={(e) => setTipInput(e)}
                                                    serviceFee={serviceFee}
                                                    handleSubmit={(paymentInfo) => handleSubmit(paymentInfo)}
                                                    items={bidResponse.items}
                                                    response={bidResponse}
                                                    guestCount={bid.guest_count}
                                                    deliveryFee={deliveryFee}
                                                    cgeType={bid.cge_type}
                                                />
                                            </Elements>
                                        </div>
                                </PopoutModal>
                                
                            </div>
                        </div>
                    )
                } else {
                    if (bidResponse.accepted == true) {
                        return (
                            <div className="mx-3 mt-3.5 flex justify-center items-center text-blue-600">
                                <InformationCircleIcon2 className="h-4" />
                                <p className="ml-1 text-xs font-sf-regular">You have accepted this bid response.</p>
                            </div>
                        )
                    }
                }
            }
        } else if (user != null && user.account_type == 2 && user.email == bidResponse.business_email) {

            if (bidResponse.accepted == true) {
                return (
                    <div className="mx-3 mt-2.5 flex justify-center items-center text-blue-600">
                        <InformationCircleIcon2 className="h-4" />
                        <p className="ml-1 text-xs font-sf-regular">Your entry has been accepted for this bid.</p>
                    </div>
                )
            } else {
                return (
                    <div onClick={() => { drawerWidth == 30 ? setDrawerWidth(65) : setDrawerWidth(30) }}>
                        <div className="cursor-pointer flex justify-center items-center h-8 px-2 hover:bg-blue-50 border-2 border-gray-100 rounded-md">
                            <ChatIcon className="h-4 text-blue-600 mr-1" />
                            <p className="text-paragraph font-productsans-medium text-blue-600">Message</p>
                        </div>
                    </div>
                )
            }
        } else {
            if (bidResponse.accepted == true) {
                return (
                    <div className="mx-3 mt-2.5 flex justify-center items-center text-blue-600">
                        <InformationCircleIcon2 className="h-4" />
                        <p className="ml-1 text-xs font-sf-regular">This bid response has been accepted.</p>
                    </div>
                )
            }
        }
    }

    return (
        <>
            {renderResponseCtrl()}
        </>
    )
}