import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

import { BidsItem } from '.';

export default function BidsList({ myBids, loadingBids, getBid }) {

    return (
        <div className="h-full flex flex-col">
            <div className="flex items-center justify-between px-4 pt-3.5 pb-2 border-b border-gray-50">
                <h4 className="text-sm my-0.5 font-productsans-bold text-gray-600">My Bids</h4>
            </div>
            {loadingBids ?
                <div className="flex items-center justify-center h-full">
                    <ThreeDots
                        height="30"
                        width="30"
                        radius="9"
                        color="#2563eb"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
                :
                <div className="flex flex-col grid grid-cols-4 gap-4 mb-4 p-4 overflow-y-scroll scrollbar-hide">
                    {myBids.map((bid, index) => (
                        <BidsItem bid={bid} index={index} getBid={(e) => getBid(e)} />
                    ))}
                </div>
            }
        </div>
    )
}