import { Link } from "react-router-dom";
import logoLight from '../../assets/images/icon-light.png';
import logo from '../../assets/images/icon-black.png';

export default function Logo({ lightMode, styleValue }) {
    return (
        <Link to="/" className="cursor-pointer mr-2">
            {lightMode
                ? <img src={logo} alt="" className="object-cover h-7" />
                : <img src={logo} alt="" className={`object-cover h-7 ${styleValue}`} />
            }
        </Link>
    )
}

