import moment from "moment"
import { useEffect, useState } from "react";

export default function ConversationTab({ conv, selectedConv, index, reloadFlag }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [read, setRead] = useState(true);

    useEffect(() => {
        if (JSON.parse(conv.messages.at(-1)).read == false) {
            setRead(false)
        }
    }, [conv.messages])

    useEffect(() => {
        setRead(true)
    }, [reloadFlag])

    async function handleRead() {
        setRead(true)
        // await BiddingAPIs()
    }

    return (
        <div onClick={() => handleRead()} className="h-full flex flex-col justify-between">
            <div className="flex justify-between">
                <div className="w-full flex space-x-2.5">
                    <div className={`bg-blue-200 rounded-full w-9 h-9 flex flex-shrink-0 items-center justify-center`}>
                        <span className="text-gray-900 text-base font-productsans-medium uppercase">{conv.contact_name.slice(0, 2)}</span>
                    </div>
                    <div className="mr-4">
                        <h6 className={`mt-1 font-productsans-bold leading-tight text-sm line-clamp-1 text-gray-600 ${selectedConv == index && "text-blue-600"}`}>RE: {   conv.bid_title}</h6>
                        {conv.messages.at(-1) != undefined ?
                            
                            <p className="font-roboto-regular text-gray-500 text-paragraph line-clamp-1"> {JSON.parse(conv.messages.at(-1)).message} </p>
                            :
                            null
                        }
                    </div>
                </div>
                {conv.messages.at(-1) != undefined ?
                    read == false && JSON.parse(conv.messages.at(-1)).to == user.email &&
                        <div className="h-2 w-2 flex items-center text-white justify-center rounded-full font-productsans-medium text-xs bg-red-600">
                            {/* {convTotal} */}
                        </div>
                        :
                        null
                }

            </div>
            <div className="flex justify-between items-center">
                <div><p className="font-roboto-medium text-gray-400 text-xs">{moment(JSON.parse(conv.messages.at(-1)).timestamp).fromNow()}</p></div>
                <div><p className="text-right font-roboto-bold text-gray-500 text-xs">BR #00{conv.resp_id}</p></div>
            </div>
        </div>
    )
}