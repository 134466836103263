import React, { useState, useEffect } from "react";

import { AdminNavbar, AdminSidebar, LoginModal } from '../../components/admin';
import { AccountsAPI, BiddingAPIs } from "../../apis";
import {
    AdminAdminAccounts, AdminBusinessUsers, AdminCGEBids, AdminCGEOrderNow,
    AdminCGERequests, AdminCGEResponses, AdminCGETemplates, AdminCGETransactions, AdminCGEURLLeads, AdminCGEUsers, AdminCalendar, AdminStatistics, AdminStatisticsBaba
} from './index';
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getStaffToken } from "../../apis/Credentials";
import { random } from "lodash";

const routes = [
    'admin-accounts',
    'cge-requests',
    'cge-users',
    'cge-transactions',
    'cge-bids',
    'cge-collections',
    'cge-ordernow',
    'business-users',
]

export default function AdminDashboard() {
    const [adminUser, setAdminUser] = useState(JSON.parse(window.localStorage.getItem('admin_user_details')));

    const [loading, setLoading] = useState([false, false, false, false, false, false, false, false, false, false]);
    const [reloading, setReloading] = useState([false, false, false, false, false, false, false, false, false, false]);
    const [loadCounter, setLoadCounter] = useState(0);

    const [staffAccLoading, setStaffAccLoading] = useState(false);
    const [businessAccLoading, setBusinessAccLoading] = useState(false);
    const [cgeAccLoading, setCGEAccLoading] = useState(false);
    const [reqAccLoading, setReqAccLoading] = useState(false);
    const [bidsLoading, setBidsLoading] = useState(false);
    const [respLoading, setRespLoading] = useState(false);
    const [transLoading, setTransLoading] = useState(false);
    const [collLoading, setCollLoading] = useState(false);
    const [orNowLoading, setOrNowlLoading] = useState(false);
    const [leadsLoading, setLeadsLoading] = useState(false)

    const [refresh, setRefresh] = useState(false);
    const [selectedTab, setSelectedTab] = useState(window.location.pathname.split('/').pop());
    const [requests, setRequests] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [originalTransactions, setOriginalTransactions] = useState([]);
    const [bids, setBids] = useState([]);
    const [responses, setResponses] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [collections, setCollections] = useState([]);
    const [onBids, setOnBids] = useState([]);
    const [URLLeads, setURLLeads] = useState([]);

    const [useDummyData, setUseDummyData] = useState(false)
    const [dummydatarefresh, setDummydatarefresh] = useState(0)
    const [cgeAccountList, setCgeAccountList] = useState([]);
    const [businessAccountList, setBusinessAccountList] = useState([]);
    const [staffAccountList, setStaffAccountList] = useState([]);
    const [initLoad, setInitLoad] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const staffDataLoading = async () => {
            var cgeAccountList = await AccountsAPI.getCGEAccounts();
            var businessAccountList = await AccountsAPI.getBusinessAccounts();
            var staffAccountList = await AccountsAPI.getStaffAccounts();
            setCgeAccountList(cgeAccountList);
            setBusinessAccountList(businessAccountList);
            setStaffAccountList(staffAccountList);
        }
        if (getStaffToken() != null) {
            staffDataLoading()
        }
        if (routes.includes(window.location.pathname.split('/').pop()) == false) {
            setSelectedTab('admin-stats')
            navigate('admin-stats')
        }
    }, []);

    useEffect(() => {
        if (loadCounter < 10){
            setLoading([
                staffAccLoading,
                businessAccLoading,
                cgeAccLoading,
                reqAccLoading,
                bidsLoading,
                respLoading,
                transLoading,
                collLoading,
                orNowLoading,
                leadsLoading
            ])
        }

        setReloading([
            staffAccLoading,
            businessAccLoading,
            cgeAccLoading,
            reqAccLoading,
            bidsLoading,
            respLoading,
            transLoading,
            collLoading,
            orNowLoading,
            leadsLoading
        ])
        setLoadCounter(loadCounter + 1)
        console.log(loadCounter)
    }, [
        staffAccLoading,
        businessAccLoading,
        cgeAccLoading,
        reqAccLoading,
        bidsLoading,
        respLoading,
        transLoading,
        collLoading,
        orNowLoading,
        leadsLoading
    ])

    async function getData(page) {
        if (page == "signup") {
            toast.success("New account request!")
            await getCGERequests();
            getCGEAccounts();
        } else if (page == "settings") {
            getCGEAccounts();
        } else if (page == "staff") {
            getStaffAccounts();
        } else if (page == "business") {
            getBusinessAccounts();
        } else if (page == "transactions") {
            getCGETransactions();
            toast.success("New transaction update!")
        } else if (page == "bids") {
            getCGEBids();
            getCGEResponses();
        } else if (page == "responses") {
            getCGEBids();
            getCGEResponses();
            toast.success("New bid response!")
        } else if (page == "ordernow") {
            getONBids();
            toast.success("New template order!")
        } else if (page == "templates") {
            getTemplates();
        } else if (page == "collections") {
            getCollections();
        } else if (page == "urlleads") {
            getCGEURLLeads()
        }
    }

    async function getCGERequests() {
        setReqAccLoading(true)
        var result = await AccountsAPI.GetCGERequests();
        setRequests([...result.reverse()]);
        console.log("updated requests")
        setReqAccLoading(false)
    }

    async function getCGEAccounts() {
        setCGEAccLoading(true)
        var result = await AccountsAPI.getCGEAccounts();
        setAccounts(result.reverse());
        console.log("updated cge accounts")
        setCGEAccLoading(false)
    }

    async function getStaffAccounts() {
        setStaffAccLoading(true)
        var result = await AccountsAPI.getStaffAccounts();
        setStaffAccountList([...result.reverse()]);
        console.log("updated staff accounts")
        setStaffAccLoading(false)
    }

    async function getBusinessAccounts() {
        setBusinessAccLoading(true)
        var result = await AccountsAPI.getBusinessAccounts();
        setBusinessAccountList([...result.reverse()]);
        console.log("updated business accounts")
        setBusinessAccLoading(false)
    }

    async function getCGETransactions() {
        setTransLoading(true)
        var result = await BiddingAPIs.getCGETransactions();
        var output = [...result.reverse()]
        if (!useDummyData) {
            output = [...result.reverse()].filter(trans => trans.bid.cge_email != "bid.testing@andie.work" && trans.bid.cge_email != "barries88@hotmail.com")
        }
        setOriginalTransactions(output);
        console.log("updated transations")
        setTransLoading(false)
    }

    async function getCGEBids() {
        setBidsLoading(true)
        var result = await BiddingAPIs.adminGetBids({});

        if (initLoad == true) {
            setInitLoad(false)
        } else {
            if (result.length > bids) {
                toast.success("New bid opened!")
            }
        }

        var output = [...result]
        if (!useDummyData) {
            output = [...result].filter(bid => bid.cge_email != "bid.testing@andie.work" && bid.cge_email != "barries88@hotmail.com")
        }

        setBids(output)
        console.log("updated bids")
        setBidsLoading(false)
    }

    async function getCGEResponses() {
        setRespLoading(true)
        var result = await BiddingAPIs.getResponses();
        var output = [...result.responses]

        if (!useDummyData) {
            output = [...result.responses].filter(resp => resp.bid.cge_email != "bid.testing@andie.work" && resp.bid.cge_email != "barries88@hotmail.com")
        }

        setResponses(output)
        console.log("updated responses")
        setRespLoading(false)
    }

    async function getTemplates() {
        const res = await BiddingAPIs.getTemplates()
        setTemplates([...res.data.reverse()])
        console.log("updated templates")
    }

    async function getCollections() {
        setCollLoading(true)
        const res = await BiddingAPIs.getCollections()
        setCollections([...res.data.reverse()])
        console.log("updated collections")
        setCollLoading(false)
    }

    async function getONBids() {
        setOrNowlLoading(true)
        var result = await BiddingAPIs.getONBids({});

        var output = result
        if (!useDummyData) {
            output = result.filter(bid => bid.cge_email != "bid.testing@andie.work" && bid.cge_email != "barries88@hotmail.com")
        }
        setOnBids(output)
        console.log("updated onbids")
        setOrNowlLoading(false)
    }

    async function getCGEURLLeads() {
        setLeadsLoading(true)
        var result = await AccountsAPI.getCGEURLLeads()
        setURLLeads([...result])
        console.log("updated leads")
        setLeadsLoading(false)
    }

    async function handleUseDummyData(value) {
        setUseDummyData(value)
        setDummydatarefresh(random(9999))
    }

    return (
        <div className="h-screen">
            {adminUser == null && <LoginModal trigger={true} />}
            <div className="flex flex-row w-full h-full ">
                <div className="flex w-[20%]">
                    <AdminSidebar tab={selectedTab} setSelectedTab={(e) => setSelectedTab(e)} useDummyData={useDummyData} handleUseDummyData={handleUseDummyData} page="admin-user" refreshFunction={(e) => getData(e)} reloading={reloading}/>
                </div>
                <div key={dummydatarefresh} className="flex h-full overflow-hidden w-[65%] border-l border-r">
                    <div className={`${selectedTab == "admin-accounts" ? "w-full andie-padding" : "hidden"}`}>
                        <AdminAdminAccounts
                            parentLoading={loading}
                            accounts={staffAccountList} />
                    </div>
                    <div className={`${selectedTab == "cge-requests" ? "w-full andie-padding" : "hidden"}`}>
                        <AdminCGERequests
                            parentLoading={loading}
                            requests={requests} getCGERequests={() => getCGERequests()}
                            staffAccountList={staffAccountList} />
                    </div>
                    <div className={`${selectedTab == "cge-users" ? "w-full andie-padding" : "hidden"}`}>
                        <AdminCGEUsers
                            parentLoading={loading}
                            accounts={accounts}
                            getCGEAccounts={() => getCGEAccounts()}
                        />
                    </div>
                    <div className={`${selectedTab == "cge-transactions" ? "w-full andie-padding" : "hidden"}`}>
                        <AdminCGETransactions
                            parentLoading={loading}
                            originalTransactions={originalTransactions} getCGETransactions={() => getCGETransactions()}
                            staffAccountList={staffAccountList}
                            cgeAccountList={cgeAccountList}
                            businessAccountList={businessAccountList} />
                    </div>
                    <div className={`${selectedTab == "cge-bids" ? "w-full andie-padding" : "hidden"}`}>
                        <AdminCGEBids
                            parentLoading={loading}
                            originalBids={bids} getCGEBids={() => getCGEBids()}
                            staffAccountList={staffAccountList}
                            cgeAccountList={cgeAccountList}
                            businessAccountList={businessAccountList}
                        />
                    </div>
                    <div className={`${selectedTab == "cge-responses" ? "w-full andie-padding" : "hidden"}`}>
                        <AdminCGEResponses
                            parentLoading={loading}
                            originalResponses={responses} getCGEResponses={() => getCGEResponses()}
                            staffAccountList={staffAccountList}
                            cgeAccountList={cgeAccountList}
                            businessAccountList={businessAccountList}
                        />
                    </div>
                    <div className={`${selectedTab == "cge-collections" ? "w-full andie-padding" : "hidden"}`}>
                        <AdminCGETemplates
                            parentLoading={loading}
                            collections={collections} getCollections={() => getCollections()}
                        />
                    </div>
                    <div className={`${selectedTab == "cge-ordernow" ? "w-full andie-padding" : "hidden"}`}>
                        <AdminCGEOrderNow
                            parentLoading={loading}
                            onBids={onBids} getONBids={() => getONBids()}
                            staffAccountList={staffAccountList}
                            cgeAccountList={cgeAccountList}
                            businessAccountList={businessAccountList}
                        />
                    </div>
                    <div className={`w-full ${selectedTab == "cge-urlleads" ? "w-full andie-padding" : "hidden"}`}>
                        <AdminCGEURLLeads
                            parentLoading={loading}
                            URLLeads={URLLeads} getCGEURLLeads={() => getCGEURLLeads()}
                        />
                    </div>
                    <div className={`${selectedTab == "business-users" ? "w-full andie-padding" : "hidden"}`}>
                        <AdminBusinessUsers
                            parentLoading={loading}
                            staffAccountList={staffAccountList} getBusinessAccounts={getBusinessAccounts}
                        />
                    </div>
                    <div className={`${selectedTab == "admin-stats" ? "w-full andie-padding" : "hidden"}`}>
                        <AdminStatistics
                            parentLoading={loading}
                            getCGETransactions={() => getCGETransactions()}
                            originalTransactions={originalTransactions}
                            bids={bids}
                            staffAccountList={staffAccountList}
                            cgeAccountList={cgeAccountList}
                        />
                    </div>
                    <div className={`${selectedTab == "admin-calendar" ? "w-full andie-padding" : "hidden"}`}>
                        <AdminCalendar
                            parentLoading={loading}
                            bids={bids}
                        />
                    </div>
                </div>
                <div className="w-[15%]"></div>
            </div>
        </div>
    )
}