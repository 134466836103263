import React from "react";
import Modal from "react-responsive-modal";
import { CheckIcon, XIcon } from "@heroicons/react/solid";

import { getTransactionAmounts, pricetoString } from "../common";
import { useState } from "react";
import { BiddingAPIs } from "../../apis";
import { useEffect } from "react";
import { cloneDeep } from "lodash";
import toast from "react-hot-toast";

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        width: "25%",
        height: '50%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

export default function TransactionItemsModal({ items, transaction }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [transItems, setTransItems] = useState([])
    const [originalItems, setOriginalItems] = useState([])

    useEffect(() => {
        setTransItems(cloneDeep(items))
        setOriginalItems(cloneDeep(items))
    }, [items])

    async function handleEdit() {
        let errFlag = false;
        let type = 0
        // console.log(responseItems)
        originalItems.forEach((ogItem, index) => {
            transItems.forEach((newItem) => {
                if (isNaN(newItem.quantity)) {
                    errFlag = true
                    type = 1
                    return
                }
                if (ogItem.name == newItem.name) {
                    if (newItem.quantity < ogItem.quantity) {
                        errFlag = true
                    }
                }
            })
        })
        if (errFlag == true) {
            if (type == 1) {
                toast.error("Please enter a valid quantity for your items!")
            } else {
                toast.error("You cannot reduce the quantity of your response!")
            }

        } else {
            const transAmounts = getTransactionAmounts(transItems, transaction.bid.budget.tipAmount, transaction.response.delivery_fee, transaction.response.business_delivers, transaction.bid.cge_service_fee, transaction.response.business_service_fee, null, transaction.response.pricing, transaction.response.total_price)
            const resp = await BiddingAPIs.editBidResponse(
                {
                    items: transItems,
                    id: transaction.response.id,
                    amount: transAmounts.total
                }).then(() => 0)
        }
    }

    function handleItemEdit(input, index) {
        let temp = transItems
        if (input == "") {
            input = "0"
        }
        temp[index].quantity = parseInt(input)
        setTransItems([...temp])
    }
    return (
        <div className='h-full flex flex-col relative overflow-y-hidden'>
            <div className="overflow-y-scroll scrollbar-hide pb-8 px-4 flex flex-col">
                {items.map((item, index) => (
                    <div className="border-b border-gray-200 last:border-0 py-3.5 w-full">
                        <div className="flex justify-between w-full">
                            <div className="flex flex-col andie-description-text w-4/5">
                                <h6 className="text-gray-700 font-roboto-medium text-paragraph">{item.name}</h6>
                                <p className="andie-description-text text-gray-500 leading-tight line-clamp-2">{item.description}</p>
                                <p className="mt-3 text-xs font-roboto-medium text-gray-500">{item.menuoption}</p>
                                <div className="w-3/4 mt-1">
                                    <div className="mt-1 space-x-2 max-w-4 flex">

                                        {item.dietary_option?.filter((i) => (i != "None")).map(e =>
                                            <div className="flex space-x-1.5 text-xs font-roboto-regular text-gray-500"><CheckIcon className="text-green-500 h-4" />{e}</div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-1/5 space-y-1 justify-end">
                                <p className="andie-description-text leading-tight line-clamp-1">${pricetoString(item.price)}</p>
                                <input disabled={!(user?.email == transaction.response.business_email)} className="input input-bordered w-full"  type="number" onWheel={(e) => e.target.blur()}  defaultValue={item.quantity} onChange={(e) => handleItemEdit(e.target.value, index)}/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {user?.email == transaction.response.business_email &&
                <div className="flex justify-end px-4 pb-4">
                    <button onClick={() => handleEdit()} className={`cursor-pointer flex items-center justify-center bg-blue-600 p-2 rounded px-2.5`}>
                        <h6 className="text-sm font-productsans-medium text-white">Save Changes</h6>
                    </button>
                </div>
            }
        </div>
    )
}