
export default function LoadingResponseExplore() {
    const arr = [1, 2, 3, 4, 5]
    console.log(Math.floor(Math.random() * 4) + 4)
    return (
        <div className="w-full animate-pulse flex flex-col">
            <div className="flex border-b border-gray-100 justify-between pb-2 mb-2 space-x-2">
                <div className="my-2 h-2 bg-gray-200 w-1/3 "/>
            </div>
            <div className="space-y-4">
                {arr.map(() => {
                    return (
                        <div className="flex border-2 w-full rounded-md border-gray-100 sm:p-3 p-1.5 h-24">
                            <div className="h-full w-32 sm:flex hidden">
                                <svg class=" text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
                                </svg>
                            </div>
                            <div className="sm:ml-3.5 flex flex-grow w-full">
                                <div className="sm:w-[30%] w-[37%] flex flex-col justify-between">
                                    <div className="flex flex-col space-y-2">
                                        <div className='h-2 bg-gray-200 w-9/12'/>
                                        <div className="-mt-0.5 flex items-center space-x-1.5">
                                            <div className='h-2 bg-gray-200 w-7/12'/>
                                        </div>
                                        <div className="flex items-center">
                                            <div className='h-2 bg-gray-200 w-3/12'/>
                                        </div>
                                        <div className='h-2 bg-gray-200 w-5/12'/>
                                    </div>


                                    <div className="flex items-center">
                                        <div className='h-2 bg-gray-200 w-7/12'/>
                                    </div>
                                </div>

                                <div className="sm:px-3.5 px-2 h-full border-l-2 border-r-2 sm:w-[47%] border-dashed flex flex-col justify-between w-[35%]">
                                    <div>
                                        <div className="flex mb-2 justify-between">
                                            <div className='h-2 bg-gray-200 w-6/12'/>
                                            <div className='h-2 bg-gray-200 w-1/12'/>
                                        </div>
                                        <ul className="space-y-2">
                                            <div className={`h-2 bg-gray-200 w-${Math.floor(Math.random() * 4) + 4}/12`}/>
                                            <div className={`h-2 bg-gray-200 w-${Math.floor(Math.random() * 4) + 4}/12`}/>
                                            <div className={`h-2 bg-gray-200 w-${Math.floor(Math.random() * 4) + 4}/12`}/>
                                        </ul>
                                    </div>
                                    <div className="flex justify-between leading-none">
                                        <div className='h-2 bg-gray-200 w-3/12'/>
                                        <div className='h-2 bg-gray-200 w-4/12'/>
                                    </div>
                                </div>

                                <div className="flex flex-col justify-between sm:w-[23%] flex-grow">
                                    <div className="flex flex-col items-end space-y-2">
                                        <div className='h-2 bg-gray-200 w-5/12'/>
                                        <div className='h-2 bg-gray-200 w-9/12'/>
                                    </div>
                                    <div className="flex justify-end">
                                        <div className='h-2 bg-gray-200 w-7/12'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}