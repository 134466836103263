import React from 'react';
import { useState, useEffect } from 'react';
import { InformationCircleIcon, LockClosedIcon } from "@heroicons/react/solid";
import { useParams } from 'react-router-dom';
import { BiddingAPIs } from '../../apis';
import { LoadingIcon, menuoptions, pricetoString } from '../common';
import { Header } from '../navigation';
import { toast } from 'react-hot-toast';

export default function PrebuiltResponse() {
  const { business_user, bid_id } = useParams();
  const [response, setResponse] = useState(); 
  const [prebuiltResponse, setPrebuiltResponse] = useState();
  const [error, setError]  = useState(false);
  const menuOptionNames = menuoptions.map(option => option.title)
  const [responseItems, setResponseItems] = useState([]);
  const [editedResponseItems, setEditedResponseItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subtotal, setSubtotal] = useState();
  const [editing, setEditing] = useState(false);
  useEffect(() => {
      getData();
    }, []);
  
  async function getData() {
    let payload = {
        business_user : business_user,
        id : bid_id
    }
    var result = await BiddingAPIs.getPrebuiltResponse(payload);
    if (result == "Error") {
      setError(true)
    }
    else{
      setSubtotal(result["prebuilt_response"]["order_total"])
      setResponse(result);
      setPrebuiltResponse(result["prebuilt_response"]);
      setLoading(false);
      console.log(result["prebuilt_response"])
      console.log(result["responses"])
      const newResponseItems = [];

      for (const menuOption in result["prebuilt_response"]) {
        if (menuOptionNames.includes(menuOption)) {
          const item = result["prebuilt_response"][menuOption];
          newResponseItems.push({
            name: item.name,
            price: item.price.toFixed(2),
            quantity: item.quantity,
            menuoption: menuOption,
            description: item.description,
            dietary_option: item.dietary_requirements.length ? item.dietary_requirements : ["None"]
          });
        }
      }

      setResponseItems(newResponseItems);
    }
  }

  async function handleSubmit(){
    let responses = response["responses"]
    if ((responses.filter(item => item.business_email === business_user).length > 0)){
      toast.error('You have already submitted a response to this CGE bid.')
    }
    else if (prebuiltResponse){
      let user = prebuiltResponse["user"][0]
      const payload = {
        restaurant_name: user["business_name"],
        restaurant_type: user["restaurant_type"],
        restaurant_location: user["place_id"],
        contact_name: user["first_name"] + " " + user["last_name"],
        contact_title: user["job_title"],
        contact_phone: user["phone"],
        contact_email: user["email"],
        business_email: user["email"],
        bid_id: bid_id,
        items: responseItems,
      }
      console.log(payload)
      // var result = await BiddingAPIs.submitBidResponse(payload);
      //submit needs to login before
      }
    }

    function setEditMode() {
      setEditing(true);
      const editedItems = responseItems.map(item => ({ ...item }));
      setEditedResponseItems(editedItems);
  }
    
    function handleEditChange(menuOption, field, value) {
      const updatedItems = editedResponseItems.map(item => {
          if (item.menuoption === menuOption) {
              return { ...item, [field]: value };
          }
          return item;
      });

      setEditedResponseItems(updatedItems);

    }
    function calculateSubtotal(){
      let newSubtotal = 0;
      
      editedResponseItems.forEach(item => {
          const itemSubtotal = item.quantity * item.price;
          newSubtotal += itemSubtotal;
      });
  
      setSubtotal(newSubtotal);
    }
    function handleSaveEdit() {
      if (editing) {
        setResponseItems(editedResponseItems);
        calculateSubtotal();
        setEditing(false);
      }
      else{
        setEditMode();
      }
    }
    
  return (
    <>
    {(response && (prebuiltResponse && prebuiltResponse["order_total"]>0))?
    <>
      <Header/>
      <div className="grid justify-center items-center pt-5 px-5">
        <div className="flex space-x-3">
            <h5 className="text-base leading-none line-clamp-2 font-productsans-bold text-gray-800">{response["bid_title"]}</h5>
            <div className="flex items-center space-x-2">
              <p className="font-roboto-bold text-xs text-gray-600">{response["event_type"]}</p>
              <div className="h-1 w-1 rounded-full bg-gray-400 " />
                  <p className="text-xs font-roboto-medium text-gray-600">{response["restaurant_rating"]}</p>
            </div>
        </div>
        <div className="border-t border-b h-9 flex items-center justify-between mb-2 border-gray-100">
          <div className="flex items-center">
              <p className="text-xs font-roboto-regular text-gray-500">{response["locationAdditionalDetails"]}</p>
          </div>
          <h6 className="text-sm font-productsans-bold text-green-600">${prebuiltResponse["budget"]}</h6>
        </div>
        <div className="flex">
          <div className='w-full items-center justify-between'>
            <p className="andie-description-text">{response["bid_description"]}</p>

            <div className="mt-2 grid grid-cols-3 gap-3">
                <div className="bg-gray-50 p-3 rounded-md">
                    <p className="andie-bg-section-descriptor text-gray-500">Opportunity</p>
                    <p className="andie-description-text text-gray-500 capitalize">{response["cge_type"]}</p>
                </div>
                <div className="bg-gray-50 p-3 rounded-md">
                    <p className="andie-bg-section-descriptor text-gray-500">Headcount</p>
                    <p className="andie-description-text text-gray-500 capitalize">{pricetoString(response["guest_count"])}</p>
                </div>
                <div className="bg-gray-50 p-3 rounded-md">
                    <p className="andie-bg-section-descriptor text-gray-500">Industry</p>
                    <p className="andie-description-text text-gray-500 capitalize">{response["industry"]}</p>
                </div>
            </div>

            <div className="mt-3 bg-gray-50 p-3 rounded-md">
                <p className="andie-bg-section-descriptor text-gray-500">Preferred Cuisines</p>
                <p className="andie-description-text text-gray-500 capitalize">{response["restaurant_type"].join(', ')}</p>
            </div>

            <div className="mt-3 bg-gray-50 p-3 rounded-md">
                <p className="andie-bg-section-descriptor text-gray-500">Dietary Requirements</p>
                <p className="andie-description-text text-gray-500 capitalize">{response["dietary_preferences"].map(item => item.preference).join(', ')}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between pb-3">
            <h6 className="font-productsans-bold mt-0.5 text-gray-500 sm:mt-1.5 ">Menu Breakdown</h6>
            <button
              className="andie-secondary-action-button font-productsans-bold mt-0.5 text-gray-500 hover:text-blue-500"
              onClick={handleSaveEdit} 
            >
              {editing ? "Save" : "Edit"} 
            </button>
        </div>
        <div className="py-3.5 px-4 bg-gray-100 rounded-lg shadow-sm">
          <div className="flex justify-between pb-3">
            <div className="space-y-1">
              <h6 className="bid-label mb-0 text-gray-600">What's included</h6>
            </div>
          </div>
          <div className="border-t-2 border-b-2 border-dashed border-gray-300">
            {responseItems.map(item => (
              <div key={item.menuoption} className="border-b border-gray-200 last:border-0 py-3.5 w-full">
                <div className="flex justify-between w-full">
                  <div className="flex flex-col andie-description-text w-2/3">
                    <h6 className="text-gray-700 font-roboto-medium text-paragraph">{item.name}</h6>
                    <p className="andie-description-text leading-tight line-clamp-1">{item.description}</p>
                  </div>
                  <div className="text-right andie-description-text font-productsans-medium w-1/3 flex justify-end grid grid-row-2">
                    {editing ? (  
                      <div className='grid grid-row-2'>
                        <input
                          className='text-right text-green-600 mb-1 items-end'
                           type="number" onWheel={(e) => e.target.blur()} 
                          value={editedResponseItems.find(editedItem => editedItem.menuoption === item.menuoption).price}
                          onChange={e => handleEditChange(item.menuoption, 'price', e.target.value)}
                          />
                        <input
                          className='text-right text-xs font-roboto-medium text-gray-500'
                           type="number" onWheel={(e) => e.target.blur()} 
                          value={editedResponseItems.find(editedItem => editedItem.menuoption === item.menuoption).quantity}
                          onChange={e => handleEditChange(item.menuoption, 'quantity', e.target.value)}
                          />
                      </div>
                    ) : (
                      <>
                        <p className="text-green-600">${item.price}</p>
                        <p className="text-xs font-roboto-medium text-gray-500">Qty: {item.quantity}</p>
                      </>
                    )}
                  </div>
                </div>
                <div className="w-3/4 mt-1">
                  <p className="mt-3 text-xs font-roboto-medium text-gray-500">{item.menuoption}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-3.5 h-9 bg-gray-200 text-gray-700 rounded-sm flex items-center justify-between px-3.5">
            <h6 className="text-paragraph font-productsans-medium">Subtotal (Fees will apply):</h6>
            <div className="flex space-x-1 items-center">
              <p className="andie-description-text font-roboto-medium pl-0.5 ">${subtotal.toFixed(2)} </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-5">
          {!editing &&
            <button className="w-32 andie-dark-button" onClick={handleSubmit}>
              Submit
            </button>
          }
        </div>
      </div>
    </>
    :
    <>
      {(loading && !error) ?
      <div className="h-full flex items-center justify-center w-full mt-60" >
        <LoadingIcon />
      </div>
      :
      <h6> There was no responses found. Please update your inventory to view pre-made responses to our bids. </h6>
      }
    </>
  }
  </>
  );
}