import axios from "axios";
import { main_apiEndpoint, cge_apiEndpoint, business_apiEndpoint } from "./config";
import { getToken, setToken } from "./Credentials";
import { toast } from "react-hot-toast";


class StripeAPIService {

    async setPaymentMethod(id) {
        // alert(id)
        var config = {
            method: 'post',
            url: cge_apiEndpoint + 'stripe/create-stripe-customer/',
            data: {
                pm: id
            },
        };

        let data = [];
        await axios(config)
            .then(function (response) {
                data = response.data;
            })
            .catch(function (error) {
            });
        return data;
    }

    async generateAPILink(email) {
        // alert(id)
        var config = {
            method: 'post',
            url: main_apiEndpoint + 'auth/stripe/create-stripe-url/',
            data: {
                email: email
            },
        };

        let data = [];
        let status = ""
        await axios(config)
            .then(function (response) {
                status = response.status;
                data = response.data;
            })
            .catch(function (error) {
            });
        if (status == 208) {
            return false
        } else {
            return data;
        }
    }

    async verifyStripe(email) {
        // alert(id)
        var config = {
            method: 'post',
            url: main_apiEndpoint + 'auth/stripe/verify-stripe/',
            data: {
                email: email
            },
        };

        let data = [];
        await axios(config)
            .then(function (response) {
                data = response.data;
            })
            .catch(function (error) {
            });
        return data;
    }

    async attachPm(pm) {
        var config = {
            method: 'post',
            url: main_apiEndpoint + 'auth/stripe/attach-pm/',
            data: { "pm": pm },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            },
        };
      
        const API = axios(config);
    
        return toast.promise(API, {
            loading: "Adding Card",
            success: "Added",
            error: (err) => {
                return err?.response?.data;
            },
        });

    }

    async getPms() {
        var config = {
            method: 'post',
            url: main_apiEndpoint + 'auth/stripe/get-pms/',
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            },
        };

        return axios(config);
    }

    async deletePm(pm) {
        var config = {
            method: 'post',
            url: main_apiEndpoint + 'auth/stripe/delete-pm/',
            data: { "pm": pm },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            },
        };

        const API = axios(config);
    
        return toast.promise(API, {
            loading: "Deleting Card",
            success: "Deleted",
            error: (err) => {
                return err?.response?.data;
            },
        });

    }

    async getCGEWalletInformation() {
        var config = {
            method: 'get',
            url: cge_apiEndpoint + 'stripe/get-cge-wallet-information/',
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            },
        };

        let data = {};
        await axios(config)
            .then(function (response) {
                data = response.data;
            })
            .catch(function (error) {
            });
        return data
    }

    async getStripeDashboardURL() {
        var config = {
            method: 'post',
            url: cge_apiEndpoint + 'stripe/something/',
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            },
        };

        let data = {};
        await axios(config)
            .then(function (response) {
                data = response.data;
            })
            .catch(function (error) {
            });
        return data
    }

}

const service = new StripeAPIService()
export default service