import { useState, useEffect } from "react";
import Modal from "react-responsive-modal";

import { AccountsAPI } from "../../../apis";
import { AdminBusinessForm, AdminStaffForm } from "..";

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        minWidth: "45%",
        height: '85%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

export default function AdminEditStaffAccount({ editModal, setEditModal, staffAccount }) {
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [department, setDepartmentName] = useState("")
    const [role, setRole] = useState("")
    const [adminLevel, setAdminLevel] = useState("")
    const [phone, setPhone] = useState("")

    useEffect(() => {
        if (staffAccount != undefined) {
            setFirstname(staffAccount.first_name)
            setLastname(staffAccount.last_name)
            setEmail(staffAccount.email)
            setDepartmentName(staffAccount.department)
            setRole(staffAccount.role)
            setAdminLevel(parseInt(staffAccount.admin_level))
            setPhone(staffAccount.phone)
        }
    }, [staffAccount])

    async function editUser(e) {
        e.preventDefault()

        let data = {
            email: email,
            first_name: firstname,
            last_name: lastname,
            department: department,
            role: role,
            admin_level: adminLevel,
            phone: phone
        };

        try {
            await AccountsAPI.editStaffUser(data);
            setEditModal(false)
            // await getData();
            // setEditModal(false)
        } catch (e) {
        }
    }


    return (
        <Modal
                blockScroll={false}
            open={editModal}
            onClose={() => setEditModal(false)}
            animationDuration={400}
            showCloseIcon={false}
            styles={styles}
            center
        >
            <AdminStaffForm type="edit"
                editUser={(e) => editUser(e)}
                firstname={firstname} setFirstname={(e) => setFirstname(e)}
                lastname={lastname} setLastname={(e) => setLastname(e)} email={email}
                setEmail={(e) => setEmail(e)} department={department}
                setDepartmentName={(e) => setDepartmentName(e)} phone={phone} setPhone={(e) => setPhone(e)}
                role={role} setRole={(e) => setRole(e)}
                adminLevel={adminLevel} setAdminLevel={(e) => setAdminLevel(e)} />
        </Modal>
    )
}