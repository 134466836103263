import React, { useState, useEffect } from 'react';
import { DocumentDownloadIcon } from "@heroicons/react/solid";


import { LoadingBidExplore } from '../../components/common';
import { SelectedBid } from '../../components/explore/bids';
import BidDisplay from './items/BidDisplay';

export default function Bids({ globalFilters, bids, setBids, loading }) {
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user_details')));

    // const [bids, setBids] = useState([]);
    // const [filteredBids, setFilteredBids] = useState([]);
    // const [originalbids, setOriginalBids] = useState([]);

    const [show, setShow] = useState(false);

    const [selectedIndex, setSelectedIndex] = useState(0);

    const [filters, setFilters] = useState([])

    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const updateScreenSize = () => {
        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    useEffect(() => {
        // function loadData() {
        //     getData()
        //     // setTimeout(loadData, 25000);
        // }
        // loadData()

        // Set up the event listener when the component mounts
        window.addEventListener('resize', updateScreenSize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);

    // useEffect(() => {
    //     if (bids.length > 0) {
    //         // setOriginalBids(bids);
    //         // setBids(bids)

    //         // setFilteredBids(bids)
    //         // setLoading(false);
    //     }
    //     // getCountdownDate(moment(new Date(result[0]?.event_date_time)))
    // }, [bids])


    useEffect(() => {
        if (!loading) {
            setShow(true);
        }
    }, [loading])

    useEffect(() => {
        setFilters({ ...filters, globalFilters: globalFilters })
    }, [globalFilters])


    const updatedFilteredBids = bids.filter((bid) => {

        // Filter by cge_type
        const filterByCgeType = (!filters.globalFilters?.discover || bid.cge_type === filters.globalFilters.discover);

        // Filter by guests
        const filterByGuests = (!filters.globalFilters?.guests || bid.guest_count <= filters.globalFilters.guests.max && bid.guest_count >= filters.globalFilters.guests.min);

        //Filter by Budget
        const filterByBudget = (!filters.globalFilters?.budget
            || ((bid.budget.total / bid.guest_count >= filters.globalFilters.budget.min)
                && (bid.budget.total / bid.guest_count <= filters.globalFilters.budget.max))
        );

        //Filter by cuisine
        const filterByCuisine = (!filters.globalFilters?.cuisines.length > 0 || bid.restaurant_type.some(cuisine => filters.globalFilters.cuisines.includes(cuisine)));


        //Filter by event
        const filterByEvent = (!filters.globalFilters?.event || (bid.event_type.toLowerCase() == filters.globalFilters.event.toLowerCase()))

        // Convert the string to a Date object
        const expiryDate = new Date(bid.expiry_date);

        // Get the current date
        const currentDate = new Date();

        // Calculate the date 1 week from now
        const oneWeekFromNow = new Date();
        oneWeekFromNow.setDate(currentDate.getDate() + 7);

        const filterByHappeningSoon = (!filters.globalFilters?.happening_soon || (expiryDate > currentDate && expiryDate <= oneWeekFromNow))

        const filterByPopular = (!filters.globalFilters?.popular || bid.views > 50)

        const filterByLevel = (!filters.globalFilters?.restaurant_level || filters.globalFilters.restaurant_level == bid.restaurant_rating);

        return filterByCgeType && filterByGuests && filterByBudget && filterByCuisine && filterByEvent && filterByHappeningSoon && filterByPopular && filterByLevel;
    })

    return (
        <>
        <div className="flex h-[calc(100svh_-_40px)] overflow-hidden">
            {!show ?
                <>
                    <LoadingBidExplore />
                </>
                :
                <div className="w-[100svw]">
                    {bids.length > 0 && show ?
                        <div className="flex w-full h-full">
                            <div className="md:w-[40%] lg:w-[32%] h-full">
                                <div className="flex items-center justify-between">
                                    <h6 className="bid-form-input-label font-roboto-regular my-2 text-gray-500">Showing <span className="text-blue-600 font-productsans-bold">{updatedFilteredBids.length}</span> results</h6>
                                </div>
                                {/*  Bids View */}
                                <div className="h-full overflow-scroll space-y-3 scrollbar-hide md:pr-4 pb-40">
                                    <input id="my-drawer" type="checkbox" className="drawer-toggle" />
                                    {updatedFilteredBids.map((bid, index) => (
                                        <BidDisplay bid={bid} index={index} screenSize={screenSize} setSelectedIndex={setSelectedIndex} selectedIndex={selectedIndex} />
                                    ))}
                                </div>
                            </div>
                            <div className="hidden md:inline-flex flex-col md:w-[60%] lg:w-[68%] h-full overflow pl-5 pt-4">
                                {
                                    updatedFilteredBids[selectedIndex] != null ?
                                    <SelectedBid selectedBid={updatedFilteredBids[selectedIndex]} user={user} />
                                    :
                                    <div className="flex items-center h-full  font-roboto-medium">No bids to display matching the selected filter.</div>
                                }
                            </div>
                        </div>
                        :
                        <div className="flex flex-col items-center justify-center h-full w-full">
                            <DocumentDownloadIcon className="h-20 text-blue-600" />
                            <p className="mt-3 tex-center text-green-600 text-xs font-roboto-medium">No records found</p>
                            <div className="flex space-x-4 justify-between mt-5 mb-32">
                                <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
        </>
    )
}