import React, { useEffect } from 'react'
import { toast } from 'react-hot-toast';

const MenuButtons = ({ func, check, step, setStep, menuButtonOptions }) => {
    const user = JSON.parse(window.localStorage.getItem('user_details'));

    useEffect(() => {
        func(menuButtonOptions[step])
    }, [step]);

    function message(step) {
        switch (step) {
            case 0:
                return "Please provide a title and description for your bid to proceed.";
            case 1:
                return "Please add the number of guests and event location to proceed.";
            case 2:
                return "Please select your menu preference(s) to proceed.";  
            case 3:
                return "Please specify your dietary requirements to proceed.";
            case 4:
                return "Please add your budget details to proceed.";
            case 5:
                return "Please add your business details and contact information to proceed.";
        }
    }

    function handleNext() {
        if (check) {
            const warning = message(step);
            toast.error(warning);
        } else {
            if (step < menuButtonOptions.length) {
                setStep((prevValue) => prevValue + 1)
            }
        }
    };


    return (
        <div className='h-12 flex items-center bottom-0'>
            <div className="flex justify-between w-full">
                <button disabled={step == 0} onClick={() => {
                    if (step >= 1) {
                        setStep((prevValue) => prevValue - 1)
                    }
                }} className={`bg-gray-100 text-xs font-productsans-medium text-gray-500 h-8 w-16 rounded px-2.5 ${step == 0 ? 'opacity-0' : 'opacity-100'}`}>Back</button>

                <div>
                    <button onClick={() => handleNext()} className={`bg-blue-600 text-xs cursor-pointer font-productsans-medium text-white h-8 w-16 ${step === menuButtonOptions.length - 1 && !user ? 'w-fit' : 'w-16'} rounded px-2.5 ${check ? 'opacity-50' : ''}`}>{step == menuButtonOptions.length - 1 ? <>{user ? <>Submit</> : <>Request Access and Submit</>}</> : <>Next</>}</button>
                </div>
            </div>
        </div>
    )
}

export default MenuButtons;