import { useState } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import CountdownTimer from "../common/CountdownTimer";

import { CGETypeImage, pricetoString } from "../common";
import { BidChart } from ".";

export default function BidHeader({ bid, open, max, min }) {
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user_details')));

    return (
        <div className="pt-7 md:pt-2 pb-4 px-3 md:px-6 border-b border-gray-100">
            <div className="flex items-center justify-between">
                <div className="-mt-2 lg:w-2/3">
                    <div className="flex md:items-center">
                        <div className="h-24 w-24 md:h-28 md:w-28 shrink-0 mr-3 md:mr-6 rounded-lg bg-blue-50 flex justify-center items-center">
                            <CGETypeImage type={bid.cge_type} page={"BidPage"} />
                        </div>
                        <div>
                            <h3 className="text-lg font-productsans-bold leading-tight lg:font-productsans-medium text-gray-700">
                                {bid.bid_title}
                            </h3>
                            <div className="flex space-x-1.5 items-center">
                                <p className="text-xs font-roboto-medium text-blue-600">{bid.cge_type}</p>
                                <div className="bg-blue-600 h-1 w-1 rounded-full" />
                                <p className="andie-description-text text-xs text-gray-500">{bid.industry}</p>
                            </div>
                            <div className="mt-0.5 flex space-x-2 items-center">
                                <p className="andie-description-text tracking-wider text-[11px] text-gray-400">{bid.locationAdditionalDetails}</p>
                            </div>
                            {bid.bid_type === "ordernow" ?
                                <div className="mt-2 bg-yellow-200 inline-flex text-xs font-roboto-medium px-2.5 py-1 rounded-full">Package order</div>
                                :
                                <div className="mt-2 md:mt-4 flex space-x-1">
                                    <p className="font-roboto-bold text-xs text-gray-600">Expires in </p>
                                    <CountdownTimer bid={bid} datetime={bid.expiry_date} />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="mt-4 flex items-center space-x-3">
                        <div className=" flex items-center font-roboto-medium text-xs text-gray-400">
                            <CheckIcon className="h-4 -mt-0.5 mr-0.5 text-green-600" />
                            Verified
                        </div>
                        <div className=" flex items-center font-roboto-medium text-xs text-gray-400">
                            <CheckIcon className="h-4 -mt-0.5 mr-0.5 text-green-600" />
                            {(bid.dietary_preferences.filter(pref => pref.preference != "None")).length} Dietary Requirements
                        </div>
                        <div className=" flex items-center font-roboto-medium text-xs text-gray-400">
                            <CheckIcon className="h-4 -mt-0.5 mr-0.5 text-green-600" />
                            {bid.recurring ? "Recurring event" : "One-time event"}
                        </div>
                    </div>
                </div>
                <div className="hidden lg:inline-flex flex-col lg:w-[30%]">
                    <div className="h-9 px-2 flex items-center border-2 border-b-0 border-gray-200 rounded-t-xl">
                        <h6 className="andie-description-text mb-1.5 font-roboto-medium text-xs">Bid Performance</h6>
                    </div>
                    <div className="flex w-full">
                        <BidChart bid={bid} user={user} />
                        <div className="-mt-2 w-2/5">
                            <div className="bg-gray-200 h-10 py-1.5 px-1">
                                <h6 className="text-[11px] bid-label leading-none mb-0 text-gray-600 uppercase">Open</h6>
                                <p className="text-xs font-productsans-medium text-gray-700">{user !== null ? (open === "" ? "- - -" : "$"+pricetoString(open)) : "$$"}</p>
                            </div>
                            <div className="bg-green-100 h-10 py-1.5 px-1">
                                <h6 className="text-[11px] bid-label leading-none mb-0 text-gray-600 uppercase">High</h6>
                                <p className="text-xs font-productsans-medium text-green-700">{user !== null ? (max === "" ? "- - -" : "$"+pricetoString(max)) : "$$$$"}</p>
                            </div>
                            <div className="bg-red-100 rounded-br-xl h-10 py-1.5 px-1">
                                <h6 className="text-[11px] bid-label leading-none mb-0 text-gray-600 uppercase">Low</h6>
                                <p className="text-xs font-productsans-medium text-red-700">{user !== null ? (min === "" ? "- - -" : "$"+pricetoString(min)) : "$"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}