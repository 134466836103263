import React, { useState, useEffect } from "react";

import { NewAndNoteworthy, PopularAndTrending, AllTemplates } from "../../components/explore/templates";
import { calulatePackageMaxAndMin, foodOptions } from "../../components/common";
import { EmojiHappyIcon } from "@heroicons/react/solid";

export default function Templates({ globalFilters, templates, loading }) {
    const [filters, setFilters] = useState([]);
    const [selectedCuisines, setSelectedCuisines] = useState([]);

    useEffect(() => {
    }, [filters])

    useEffect(() => {
        setFilters({ ...filters, globalFilters: globalFilters })
    }, [globalFilters])

    // const getMinPrice = (packages) => {
    //     let min = 0;
    //     packages.map((pack) => {
    //         for (const menuOption of Object.keys(pack.items)) {
    //             pack.items[menuOption].map((item) => {
    //                 min += parseFloat(item.price)
    //             })
    //         }
    //     })
    //     return min
    // }
    // const getMaxPrice = (packages) => {
    //     let total = 0;
    //     packages.map((pack) => {
    //         for (const menuOption of Object.keys(pack.items)) {
    //             pack.items[menuOption].map((item) => {
    //                 total += parseFloat(item.price) * parseFloat(item.quantity)
    //             })
    //         }
    //     })
    //     return total
    // }


    let filteredSelection = templates.filter((event) => {
        // Filter by cge_type
        const filterByCgeType = (!filters.globalFilters?.discover || event.cge_type === filters.globalFilters?.discover);

        // Filter by guests
        // const filterByGuests = (!filters.globalFilters?.guests || (event.min_guests >= filters.globalFilters.guests.min && event.min_guests <= filters.globalFilters.guests.max));

        const filterByGuests = (
            !filters.globalFilters?.guests ||
            (
              event.min_guests >= filters.globalFilters.guests.min &&
              event.min_guests <= filters.globalFilters.guests.max
            ) ||
            (
              event.packages &&
              event.packages
                .flatMap(pac => pac.serves || [])
                .some(minGuest => minGuest >= filters.globalFilters.guests.min && minGuest <= filters.globalFilters.guests.max)
            )
        );

        const packagePrices = event.packages && event.packages.map(pkg => calulatePackageMaxAndMin(pkg.items, pkg.pricing, pkg.package_price)) || []
        let pricesList = []
        for (const prices of packagePrices) {
            if (prices.max >= 0) pricesList.push(prices.max)
            if (prices.min >= 0) pricesList.push(prices.min)
            if (prices.total >= 0) pricesList.push(prices.total)
        }
        const collMax = Math.max(...pricesList)
        const collMin = Math.min(...pricesList)

        const filterByBudget = (!filters.globalFilters?.budget
            || (
                filters.globalFilters?.budget?.max >= collMin &&
                filters.globalFilters?.budget?.min <= collMax
            ))


        //Filter by event
        const filterByEvent = (!filters.globalFilters?.event || (event.event_type.toLowerCase() == filters.globalFilters.event.toLowerCase()))

        //Filter by cuisine
        const filterByCuisine = (!filters.globalFilters?.cuisines.length > 0 || event.packages.map(pkg => pkg.cuisine_type).flat().some(cuisine => filters.globalFilters.cuisines.includes(foodOptions.find(option => option.value === cuisine).label)))

        // Combine filters using logical AND (&&)
        return filterByCgeType && filterByGuests && filterByBudget && filterByEvent && filterByCuisine;
    });

    return (
        <>
            <div className="h-full pt-2 overflow-y-scroll scrollbar-hide pb-20">
                <div className="space-y-7 md:space-y-10">
                    <NewAndNoteworthy filteredSelection={filteredSelection} loading={loading} />

                    <PopularAndTrending filteredSelection={filteredSelection.slice(4, 9)} loading={loading} />

                    <AllTemplates
                        selectedCuisines={selectedCuisines}
                        setSelectedCuisines={setSelectedCuisines}
                        filteredSelection={filteredSelection}
                        loading={loading}
                    />
                </div>

                <div className="mt-14 flex justify-center">
                    <div className="space-x-2 inline-flex items-center px-2 py-1 border-2 rounded-full">
                        <EmojiHappyIcon className="h-4 text-blue-600" />
                        <p className="text-xs font-roboto-medium text-gray-400">This is the end of the list.</p>
                    </div>
                </div>
            </div>
        </>
    )
}