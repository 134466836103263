import React, { useEffect, useState } from 'react';

import { cgeOptions } from '../../common';

export default function BidAbout({ bidTitle, bidDescription, cgeType, setCGEType, setBidTitle, setBidDescription, setSelectedTab, item, isNextButtonDisabled, setIsNextButtonDisabled }) {
    function selectCGEType(option) {
        setCGEType(option);
    }
    useEffect(() => {
        if (!bidTitle || !bidDescription) {
            setIsNextButtonDisabled(true);
        }
        else {
            setIsNextButtonDisabled(false)
        }

    }, [bidTitle, bidDescription])

    function test() {
        let str = bidTitle[0].toUpperCase() + bidTitle.slice(1)
    }

    return (
        <div className="h-full flex flex-col justify-between">
            <div className="flex flex-col xl:w-[70%]">
                <div className="xl:w-4/5 space-y-5">
                    <div className="w-full">
                        <h6 className='bid-form-input-label'>Title</h6>
                        <input
                            className="w-full bid-form-input"
                            id="cus_bid_title"
                            onChange={(e) => setBidTitle(e.target.value)}
                            type="text"
                            required
                            placeholder="e.g. Client meeting for 2"
                            aria-label="Bid Title"
                            defaultValue={bidTitle}
                        />
                    </div>
                    <div>
                        <div className="flex items-end mb-2 justify-between w-full">
                            <h6 className='bid-form-input-label'>Description</h6>
                            <p className='text-xs font-roboto-regular text-gray-500'>Min. <b className="text-blue-600">{bidDescription?.length}/125</b> characters</p>
                        </div>
                        <textarea
                            className="w-full h-24 md:h-32 bid-form-input"
                            id="cus_description"
                            onChange={(e) => setBidDescription(e.target.value)}
                            type="text"
                            required
                            placeholder="E.g. We are hosting an important client dinner on the 26th. Our guests are coming from the International Space Station."
                            minLength={125}
                            aria-label="Description"
                            defaultValue={bidDescription}
                        />
                    </div>
                    <div>
                        <h6 className="bid-form-input-label">Opportunity</h6>
                        <div className="grid md:grid-cols-2 gap-4">
                            {cgeOptions.map((option, index) => (
                                <div key={index} className="flex justify-between border-2 border-gray-100 p-2.5">
                                    <div className='flex space-x-3'>
                                        <img src={option.icon} className='h-6 w-6' />
                                        <div>
                                            <p className="text-paragraph font-productsans-medium text-gray-500">{option.name}</p>
                                            <p className="w-3/4 md:w-full text-xs font-roboto-regular leading-tight text-gray-400">{option.explanation}</p>
                                        </div>
                                    </div>
                                    <div onClick={() => selectCGEType(option.name)} className={`cursor-pointer flex-shrink-0 border-2 h-4 w-4 mr-1 flex items-center justify-center rounded ${option.name === cgeType ? 'border-blue-600' : 'border-gray-300'}`}>
                                        {option.name === cgeType && <div className="h-2 w-2 rounded-sm bg-blue-600" />}
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="flex justify-end flex-end mt-3 mb-5 bottom-0">
                <button disabled={isNextButtonDisabled} onClick={() => setSelectedTab("Bid Details")} className={`bg-blue-600 text-xs font-productsans-medium text-white h-8 w-16 rounded px-2.5 ${isNextButtonDisabled ? 'opacity-50' : ''}`}>Next</button>
            </div> */}
        </div>
    )
}