import { taxi, bus, elevator, stairs, accessible } from '../../assets/images/amenities';

export default function BidResponseBookingOverview({ bid, bidResponse }) {
    return (
        <div className="mb-5 py-5 border-t border-b border-gray-200">
            <h6 className="font-productsans-bold text-gray-500 text-xs uppercase mb-4">Booking Overview</h6>
            <div className="flex flex-row-reverse">

                <div className="w-full grid grid-cols-3 border-2 border-gray-100 rounded divide-y-black gap-3">
                    <div className="border-r border-gray-100 py-2 md:pl-3 pl-1.5">
                        <h6 className="bid-label mb-0">Headcount</h6>
                        <p className="andie-description-text">{bid.guest_count} guests</p>
                    </div>
                    <div className="border-r border-gray-100 py-2">
                        <h6 className="bid-label mb-0">Cuisine</h6>
                        <p className="andie-description-text">{bidResponse.restaurant_type}</p>
                    </div>
                    <div className="py-2">
                        <h6 className="bid-label mb-0">Event type</h6>
                        <p className="andie-description-text">{bid.cge_type}</p>
                    </div>
                </div>
            </div>
            {bid.accessibility.length > 0 && <div className="w-1/2 mt-5">
                <h6 className="bid-label">Restaurant Amenities</h6>
                {/* PLACEHOLDER  */}
                <div className="">
                    <div className="flex space-x-2 mb-4">
                        {Object.keys(bid.accessibility).map(((option) => {
                            if (bid.accessibility[option] == true) {
                                let img = null;
                                if (option == "Parking") {
                                    img = <img src={taxi} className='p-2' />
                                } else if (option == "Accessible") {
                                    img = <img src={accessible} className='p-2' />
                                } else if (option == "Transit") {
                                    img = <img src={bus} className='p-2' />
                                } else if (option == "Elevators") {
                                    img = <img src={elevator} className='p-2' />
                                } else if (option == "Stairs") {
                                    img = <img src={stairs} className='p-2' />
                                } else {
                                    img = <p> {option[0]} </p>
                                }
                                return (
                                    <div className="h-10 w-10 rounded-lg bg-purple-200 flex items-center justify-center font-roboto-bold">
                                        {/* {option[0]} */}
                                        {img}
                                    </div>
                                )
                            }
                        }))}
                    </div>
                    {/* {renderAccess()} */}
                </div>
            </div>}
        </div>
    )
}
