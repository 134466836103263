import { useState } from "react";
import Autocomplete from "react-google-autocomplete";
import Select from 'react-select';

import { foodOptions } from "../../common";

export default function AdminBusinessForm({ createUser, editUser, initialLocation, type, setFirstname, firstname, setLastname, lastname, setEmail, email, setPhone, phone, setBusinessname, businessname, setBusinessTitle, businessTitle, setPassword, setRePassword, location, setLocation, setLocationPlaceID, setLocationGeometry, setLocationAdditionalDetails, restaurantLevel, setRestaurantLevel, restaurantTypes, setRestaurantTypes, businessRep, setBusinessRep, staffAccountList, serviceFee, setServiceFee }) {
    const [viewPass, setViewPass] = useState(false)
    const [viewRePass, setViewRePass] = useState(false)

    function getLocationDetails(loc) {
        var country = loc.filter(item => item.types.includes('country')).map(item => item.short_name)
        var province = loc.filter(item => item.types.includes('administrative_area_level_1')).map(item => item.short_name)
        var city = loc.filter(item => item.types.includes('administrative_area_level_2')).map(item => item.short_name)

        return (city + ', ' + province + " " + country)
    }

    return (
        <form class="space-y-4" onSubmit={type == "edit" ? (e) => editUser(e) : (e) => createUser(e)}>
            <div className="grid grid-cols-2 gap-12">
                <div>
                    <label class="label">
                        <span class="text-base label-text">First Name</span>
                    </label>
                    <input onChange={(e) => setFirstname(e.target.value)} value={firstname} type="text" placeholder="First Name" class="w-full bid-form-input" required />
                </div>
                <div>
                    <label class="label">
                        <span class="text-base label-text">Last Name</span>
                    </label>
                    <input onChange={(e) => setLastname(e.target.value)} value={lastname} type="text" placeholder="Last Name" class="w-full bid-form-input" required />
                </div>
            </div>
            <div className="flex flex-row grid grid-cols-2 gap-12">
                <div>
                    <label class="label">
                        <span class="text-base label-text">Email</span>
                    </label>
                    <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder="Email" class="w-full bid-form-input" required />
                </div>
                <div>
                    <label class="label">
                        <span class="text-base label-text">Phone</span>
                    </label>
                    <input onChange={(e) => setPhone(e.target.value)} value={phone} type="text" placeholder="Phone" class="w-full bid-form-input" required />
                </div>
            </div>
            <div className="flex flex-row grid grid-cols-2 gap-12">
                <div>
                    <label class="label">
                        <span class="text-base label-text">Partner Business Name</span>
                    </label>
                    <input onChange={(e) => setBusinessname(e.target.value)} value={businessname} type="text" placeholder="Partner Business Name" class="w-full bid-form-input" required />
                </div>
                <div>
                    <label class="label">
                        <span class="text-base label-text">Job Title</span>
                    </label>
                    <input onChange={(e) => setBusinessTitle(e.target.value)} value={businessTitle} type="text" placeholder="Job Title e.g. Owner, Manager, etc." class="w-full bid-form-input" required />
                </div>
            </div>

            {type == "edit" ?
                <div className="">
                    <label class="label">
                        <span class="text-base label-text"> Business Location </span>
                    </label>
                    <p class="text-sm text-gray-400 label-text"> Current location: {initialLocation} </p>
                    <Autocomplete
                        className="w-full bid-form-input"
                        // apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        onPlaceSelected={(place) => {
                            setLocationAdditionalDetails(getLocationDetails(place.address_components));
                            setLocation(place.formatted_address)
                            setLocationPlaceID(place.place_id)
                            setLocationGeometry(JSON.stringify(place.geometry.location))
                        }}
                        options={{ types: ["geocode", "establishment"] }}
                        placeholder={location ? location : "Enter an address or location"}
                    />
                </div>
                :
                <div className="">
                    <label class="label">
                        <span class="text-base label-text"> Business Location </span>
                    </label>
                    <Autocomplete
                        className="w-full bid-form-input"
                        // apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        required
                        onPlaceSelected={(place) => {
                            setLocationAdditionalDetails(getLocationDetails(place.address_components));
                            setLocation(place.formatted_address)
                            setLocationPlaceID(place.place_id)
                            setLocationGeometry(JSON.stringify(place.geometry.location))
                        }}
                        options={{ types: ["geocode", "establishment"] }}
                        placeholder={location ? location : "Enter an address or location"}
                    />
                </div>}

            <div className="flex flex-row grid grid-cols-2 gap-12">
                <label class="label">
                    <span class="text-base label-text">Restaurant Level</span>
                </label>
                <div className="rating self-center">
                    <input onClick={() => setRestaurantLevel(1)} checked={restaurantLevel == 1} type="radio" name="rating-2" className="mask mask-star-2 bg-orange-400" />
                    <input onClick={() => setRestaurantLevel(2)} checked={restaurantLevel == 2} type="radio" name="rating-2" className="mask mask-star-2 bg-orange-400" />
                    <input onClick={() => setRestaurantLevel(3)} checked={restaurantLevel == 3} type="radio" name="rating-2" className="mask mask-star-2 bg-orange-400" />
                    <input onClick={() => setRestaurantLevel(4)} checked={restaurantLevel == 4} type="radio" name="rating-2" className="mask mask-star-2 bg-orange-400" />
                    <input onClick={() => setRestaurantLevel(5)} checked={restaurantLevel == 5} type="radio" name="rating-2" className="mask mask-star-2 bg-orange-400" />
                </div>
            </div>
            <div className="flex flex-row grid grid-cols-2 gap-12">
                <div className="">
                    <label class="label">
                        <span class="text-base label-text">Cuisine Type</span>
                    </label>
                    <Select
                        defaultValue={foodOptions.filter(option => restaurantTypes.includes(option.value))}
                        isMulti
                        options={foodOptions}
                        className="bid-form-input"
                        classNamePrefix=""
                        onChange={(e) => setRestaurantTypes(e.map(obj => obj.value))}
                    />

                </div>
                <div className="">
                    <label class="label">
                        <span class="text-base label-text">Partner Account Manager</span>
                    </label>
                    <select onChange={(e) => { setBusinessRep(e.target.value) }} id="cge_account"
                        className="w-full border-2 rounded h-11 px-3 flex items-center border-gray-100 andie-description-text">
                        <option disabled={true}>Account Manager</option>
                        {staffAccountList?.map((item, index) => (
                            <option value={item.email} selected={item.email === businessRep}>{item.first_name} {item.last_name}, {item.role} - {item.department}</option>
                        ))}
                    </select>
                    <label class="label">
                        <span class="text-base label-text">Account Service Fee</span>
                    </label>
                    <label class="input-group w-full">
                        <input max={100} min={0} step={1} type="number" onWheel={(e) => e.target.blur()} onChange={(e) => setServiceFee(e.target.value / 100)} defaultValue={serviceFee * 100} className="bid-form-input w-full" />
                        <span className="bid-form-input-label leading-tight mb-0">%</span>
                    </label>
                </div>
                <div className="">

                </div>
            </div>

            {type == "edit" ?
                null
                :
                <div className="flex flex-row grid grid-cols-2 gap-12">
                    <div>
                        <label class="label">
                            <span class="text-base label-text">Password <span onClick={() => setViewPass(!viewPass)} className="text-sm text-gray-400 border-b border-gray-400 ml-2 curser-pointer"> View </span></span>
                        </label>
                        <input onChange={(e) => setPassword(e.target.value)} type={viewPass == true ? "text" : "password"} placeholder="Password" class="w-full bid-form-input" required />
                    </div>
                    <div>
                        <label class="label">
                            <span class="text-base label-text">Confirm Password <span onClick={() => setViewRePass(!viewRePass)} className="text-sm text-gray-400 border-b border-gray-400 ml-2 curser-pointer"> View </span></span>
                        </label>
                        <input onChange={(e) => setRePassword(e.target.value)} type={viewRePass == true ? "text" : "password"} placeholder="Confirm Password" class="w-full bid-form-input" required />
                    </div>
                </div>
            }

            <div className="flex justify-end">
                <button type='submit' className="btn-xs rounded-sm text-white bg-blue-600">{type == "edit" ? "Edit User" : "Create User"}</button>
            </div>
        </form>
    )
}