import { ChevronRightIcon, GlobeAltIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CalculatorIcon, NewIcon, SustainabilityIcon, TutorialIcon } from "../../../assets/icons";
import { Drawer, WhatsNew } from "../../common";

export default function SidebarRight({ home }) {
    const links = [ { title: "Privacy", url: "/policies/privacy-policy" }, { title: "Terms Of Use", url: "/policies/terms-of-use" }, { title: "Disclaimer", url: "/policies/disclaimer" }, { title: "Customer Support", url: "/support" }, { title: "About Pluck", url: "https://pluckbids.com" }]
    const socials = [ { title: "Facebook", Icon: FaFacebookF }, { title: "Instagram", Icon: FaInstagram }, { title: "LinkedIn", Icon: FaLinkedinIn }, { title: "Twitter", Icon: FaTwitter }]
    const [activeCard, setActiveCard] = useState(null);

    const handleClick = (cardId) => {
        setActiveCard(cardId === activeCard ? null : cardId);
    };

    const helpful = [
        // { title: "Ideal Budget Calculator", Icon: CalculatorIcon },
        { title: "Tutorials & Guides", Icon: TutorialIcon, to: "/tutorials-and-guides" }
    ]

    return (
        <div className={`hidden andie-right-panel andie-padding h-full lg:inline-flex flex-col ${home ? "justify-between":"justify-end"}`}>
            {home && <div className="space-y-1.5">
                <h6 className="font-productsans-bold text-gray-500 text-xs uppercase mb-2">Helpful Links</h6>
                <div className="space-y-2.5">
                    <Link to="/how-pluck-is-helping-build-a-better-future" className="andie-outline-button border-0 px-2.5 space-x-3 bg-teal-50 rounded-md flex justify-between h-20 w-full">
                        <div className="bg-teal-100 rounded-md h-16 w-16 flex items-center ml-0.5 justify-center flex-shrink-0">
                            <SustainabilityIcon height={28} color="#046d3d" /> 
                        </div>
                        <div>
                            <h6>Better Future Initiative</h6>
                            <p className="andie-description-text leading-tight text-xs mr-4">Learn how we are helping organizations make a positive impact.</p>
                        </div>
                    </Link>
                    {helpful.map((item, index) => (
                        <Link key={index} to={item.to} className="andie-outline-button px-2.5 rounded-md flex justify-between h-11 w-full">
                            <div className="flex space-x-1.5">
                                <item.Icon height={20} color="#2563eb" />
                                <p>{item.title}</p>
                            </div>
                            <ChevronRightIcon className="h-5 text-blue-600" />
                        </Link>
                    ))}
                    <div>
                    <Drawer title={"What's New"} width={30} button={
                        <div className="andie-outline-button px-2.5 rounded-md flex justify-between h-11 w-full">
                            <div className="flex space-x-1.5">
                                <NewIcon height={20} color="#2563eb" />
                                <p>What's New</p>
                            </div>
                            <ChevronRightIcon className="h-5 text-blue-600" />
                        </div>
                    } >
                        <WhatsNew />
                    </Drawer>
                </div>
                </div>
            </div>}
            <div className="hidden xl:inline-flex flex-col">
                {/* <div className="flex space-x-3">
                    {socials.map((social, index) => (
                        <div key={index} className="h-8 w-8 flex items-center justify-center cursor-pointer bg-slate-100 hover:bg-slate-200 rounded-lg">
                            <social.Icon className="h-4 text-[#334155]" />
                        </div>
                    ))}
                </div> */}
                <div className="mt-3 flex flex-wrap xl:w-4/5">
                    {links.map((link, index) => (
                        <>
                            {link.title === "About Pluck" ?
                                <a href={link.url} target="_blank" className="cursor-pointer text-xs font-roboto-medium mr-3 mb-0.5 hover:text-blue-600">{link.title}</a>
                                :
                                <Link to={link.url} key={index} className="cursor-pointer text-xs font-roboto-medium mr-3 mb-0.5 hover:text-blue-600">
                                    {link.title}
                                </Link>
                            }
                        </>
                    ))}
                </div>
                <div className="mt-5 h-6 w-28 flex items-center space-x-1 rounded-full px-1 border-2 border-gray-100">
                    <GlobeAltIcon className="h-4 text-blue-600" />
                    <p className="font-roboto-medium text-xs">English (CA)</p>
                </div>
            </div>
        </div>
    )
}