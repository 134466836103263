import { useEffect, useRef, useState } from "react";
import { CheckIcon, ExclamationCircleIcon, PencilAltIcon, CheckCircleIcon, XIcon, MenuIcon, UsersIcon } from "@heroicons/react/solid";

import { ResponseFormDelivery, ResponseItemForm, BidResponseTotalDetails } from ".";
import { toast } from "react-hot-toast";
import { clone, cloneDeep } from "lodash";
import { BiddingAPIs } from "../../apis";
import { getTransactionAmounts, PopoutModal, pricetoString } from "../common";

export default function BidResponseMenuBreakdown({ setshowDrawer, bid, bidResponse, drawerWidth, setDrawerWidth, checkIfPreferenceIsInReponse, dietary_preferencesList }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [editing, setEditing] = useState(false)
    const [showItemForm, setShowItemForm] = useState(false);
    const [responseItems, setResponseItems] = useState([]);
    const [originalItems, setOriginalItems] = useState([]);
    const [editedItems, setEditedItems] = useState([]);
    const [businessDelivers, setBusinessDelivers] = useState(false)
    const [deliveryFee, setDeliveryFee] = useState(0)
    const [pricingType, setPricingType] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)

    useEffect(() => {
        setEditedItems(cloneDeep(bidResponse.items))
        setOriginalItems(cloneDeep(bidResponse.items))
        setResponseItems(cloneDeep(bidResponse.items))
        setBusinessDelivers(bidResponse.business_delivers)
        setDeliveryFee(bidResponse.delivery_fee)

    }, [bidResponse, bid])

    async function handleEdit() {
        let errFlag = false;
        let type = 0
        // console.log(responseItems)
        setDeliveryFee(bidResponse.delivery_fee)
        originalItems.forEach((ogItem, index) => {
            responseItems.forEach((newItem) => {
                if (isNaN(newItem.quantity)) {
                    errFlag = true
                    type = 1
                    return
                }
            })
        })
        if (responseItems.length == 0) {
            errFlag = true
            type = 2
        }
        if (errFlag == true) {
            alert(type)
            if (type == 1) {
                toast.error("Please enter a valid quantity for your items!")
            } else if (type == 2) {
                toast.error("You must have at least one item!")
            }

        } else {
            setEditing(false)
            const resp = await BiddingAPIs.editBidResponse({
                items: responseItems,
                id: bidResponse.id,
                amount: getTransactionAmounts(responseItems, bid.budget.tipAmount, bidResponse.delivery_fee, bidResponse.business_delivers, bid.cge_service_fee, bid.business_service_fee, null, bidResponse.pricing, bidResponse.total_price).total,
                business_delivers: businessDelivers,
                delivery_fee: deliveryFee
            }).then(() => setshowDrawer(false))
        }
    }

    function cancelResponseEdit() {
        setEditing(false);
        setResponseItems(originalItems);
        setBusinessDelivers(bidResponse.business_delivers);
        setDeliveryFee(bidResponse.delivery_fee)
    }

    return (
        <>
            <div>
                <div className="flex justify-between pb-3">
                    <div>
                        <h6 className="font-productsans-bold text-gray-500 text-xs uppercase">What's included</h6>
                        <div className="mt-0.5 py-0.5 px-2 inline-flex text-xs bid-label text-black rounded-full bg-purple-300">{bidResponse.items.length} items</div>
                    </div>
                    {bidResponse.cge_email == user?.email ?
                        drawerWidth && <div onClick={() => { drawerWidth == 30 ? setDrawerWidth(65) : setDrawerWidth(0) }} className="andie-text-button">Ask for changes</div>
                        :
                        null
                    }
                </div>
                {
                    bidResponse.business_email == user?.email && editing == true ?

                        <ResponseItemForm
                            bid={bid}
                            deliveryFee={deliveryFee} businessDelivers={businessDelivers}
                            setDeliveryFee={setDeliveryFee} setBusinessDelivers={setBusinessDelivers}
                            totalPrice={totalPrice} setTotalPrice={setTotalPrice}
                            pricingType={pricingType} setPricingType={setPricingType}
                            responseItems={responseItems} setResponseItems={setResponseItems} />
                        :
                        <>
                            <div>
                                <div className="space-y-3">
                                    {responseItems.map((item, index) => (
                                        
                                        <div className="bg-gray-100 p-3 rounded-lg flex">
                                            <div className="h-9 w-9 flex flex-shrink-0 items-center justify-center bg-gray-200 rounded">
                                                <MenuIcon className="h-5" />
                                            </div>
                                            <div className="ml-3 w-full">
                                                <div className="flex w-full">
                                                    <div className="flex w-4/5 flex-col andie-description-text">
                                                        <h6 className="text-gray-700 font-roboto-medium text-sm andie-description-text">{item.name}</h6>
                                                        <p className="text-xs font-roboto-medium text-gray-500">{item.menuoption}</p>
                                                        
                                                        <p className=" mt-3 andie-description-text text-gray-500 leading-tight">{item.description}</p>
                                                        
                                                        <div className="mt-2 flex flex-wrap">
                                                            {item.dietary_option?.filter((i) => (i != "None")).map(e =>
                                                                <div className="inline-flex space-x-1.5 text-xs font-roboto-regular text-gray-500 mr-1.5"><CheckIcon className="text-green-500 h-4" />{e}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="flex w-1/5 space-y-1 justify-end">
                                                        <div className="flex flex-col">
                                                            <div className="inline-flex py-1 px-2.5 h-6 bg-black rounded-full items-center justify-center">
                                                                <p className=" andie-description-text justify-center leading-tight text-white">{item.quantity}</p>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-end">
                                                    <div className="flex items-center space-x-2">
                                                        <UsersIcon className="h-4 text-gray-600" />
                                                        <p className="mt-0.5 andie-description-text text-xs font-roboto-medium leading-none">{item.serves == null ? null : "Serves: " + item.serves}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="my-3 border-t-2 border-dashed border-gray-100 h-1" />
                            <div className="bg-green-50 text-gray-700 rounded-lg p-3">
                                <BidResponseTotalDetails
                                    items={bidResponse.items}
                                    cge_service_fee_rate={bid.cge_service_fee}
                                    business_service_fee_rate={bidResponse.business_service_fee}
                                    delivery_fee={bidResponse.delivery_fee}
                                    business_delivers={bidResponse.business_delivers}
                                    tipAmount={bid.budget['tipAmount']}
                                    cge_type={bid.cge_type}
                                    pricingType={bidResponse.pricing} 
                                    totalPrice={bidResponse.total_price}
                                />
                            </div>
                        </>
                }
                {!checkIfPreferenceIsInReponse() && dietary_preferencesList.length > 0 ?
                    <div className="flex my-4 space-x-2 p-4 bg-blue-100 h-24 rounded-sm">
                        <ExclamationCircleIcon className="h-5 flex-shrink-0 text-blue-600 mr-1" />
                        <div className="flex flex-col">
                            <p className="andie-description-text leading-tight">None of the menu items above satisfies the following dietary requirements:</p>
                            <p className="mt-1.5 andie-description-text font-roboto-medium">{bid?.dietary_preferences.map(a => a.preference).filter((i) => (i != "None")).join(", ")}</p>
                        </div>
                    </div>
                    :
                    <></>
                }
                <div className="flex w-full justify-between py-2">
                    {((bidResponse.business_email == user?.email && bidResponse.accepted == false) && (bid.status == "Posted" && bid.active == true)) &&
                        <>
                            {editing ?
                                <div className="flex felx-row w-full justify-between space-x-10">
                                    <div onClick={() => cancelResponseEdit()} className="w-1/2 cursor-pointer flex justify-center items-center h-8 px-2 hover:bg-red-50 border-2 border-gray-100 rounded-md">
                                        <XIcon className="h-4 text-red-600 mr-1" />
                                        <p className="text-paragraph font-productsans-medium text-red-600">Cancel</p>
                                    </div>

                                    <div onClick={() => handleEdit()} className="w-1/2 cursor-pointer flex justify-center items-center h-8 px-2 hover:bg-blue-50 border-2 border-gray-100 rounded-md">
                                        <PencilAltIcon className="h-4 text-blue-600 mr-1" />
                                        <p className="text-paragraph font-productsans-medium text-blue-600">Save Changes</p>
                                    </div>
                                </div>
                                :
                                <div onClick={() => setEditing(true)} className="w-full cursor-pointer flex justify-center items-center h-8 px-2 hover:bg-blue-50 border-2 border-gray-100 rounded-md">
                                    <PencilAltIcon className="h-4 text-blue-600 mr-1" />
                                    <p className="text-paragraph font-productsans-medium text-blue-600">Make Changes</p>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            {bidResponse.images.length > 0 ? (
                <div className="py-5 border-t border-gray-200">
                    <h6 className="font-productsans-bold text-gray-500 text-xs uppercase mb-4">Gallery</h6>
                    <div className="grid grid-cols-3 gap-4">
                        {bidResponse.images.map((img) => (
                            <img
                                alt="gallery"
                                className="block h-28 w-full rounded-lg object-cover object-center"
                                src={img.url}
                            />
                        ))}
                    </div>
                </div>

            ) : (
                <></>
            )}
        </>
    )
}