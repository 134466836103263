import { Link } from "react-router-dom";
import { pages } from "../../common";
import iconblack from "../../../assets/images/icon-black.svg";
import React from "react";
import toast from "react-hot-toast";

function HeaderRightSide({unreadMsgs, user }) {
    return (
        <div className="flex space-x-5 justify-between md:justify-start">
            <div className="flex items-center">
                <img className="h-7 rounded-sm" src={iconblack} />
                <div className="ml-3 md:hidden bg-gray-300 rounded-full h-1.5 w-1.5" />
            </div>
            <div className="flex items-center space-x-6 md:w-[50%]">
                {/* {pages.map((page, index) => {
                    if (page.name === "Messages" && user !== null) {
                        return (
                            <Link
                                key={index} // Added key prop for the link
                                to={page.route}
                                className="cursor-pointer font-productsans-medium text-paragraph text-gray-500"
                            >
                                <div className="flex flex-row">
                                    {page.name}
                                    {unreadMsgs > 0 &&
                                        <div className="h-4 w-4 ml-1 flex items-center text-white justify-center rounded-full font-productsans-medium text-xs bg-red-600">
                                            {unreadMsgs}
                                        </div>
                                    }
                                </div>
                            </Link>
                        )
                    } else {
                        return (
                            <Link
                                key={index} // Added key prop for the link
                                to={page.route}
                                className="cursor-pointer font-productsans-medium text-paragraph text-gray-500"
                            >
                                {page.name}
                            </Link>
                        )
                    }
                })} */}
                {pages.map((page, index) => {
                    const isExplore = page.name === "Explore";
                    const isMessages = page.name === "Messages";

                    const isClickable = user !== null || isExplore; // "Explore" link is always clickable when user is not null

                    return (
                        <React.Fragment key={index}>
                            {isClickable ? (
                                <Link
                                    to={page.route}
                                    className="cursor-pointer font-productsans-medium text-paragraph text-gray-500"
                                >
                                    {isMessages ? (
                                        <div className="flex flex-row">
                                            {page.name}
                                            {unreadMsgs > 0 && (
                                                <div className="h-4 w-4 ml-1 flex items-center text-white justify-center rounded-full font-productsans-medium text-xs bg-red-600">
                                                    {unreadMsgs}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        page.name
                                    )}
                                </Link>
                            ) : (
                                <span
                                    className="cursor-pointer font-productsans-medium text-paragraph text-gray-500"
                                    onClick={() => {
                                        // Display a toast notification when the user clicks on the disabled link
                                        toast.error("Please sign in to view this page", {
                                            style: {
                                                borderRadius: '10px',
                                                background: '#333',
                                                color: '#fff',
                                            },
                                            duration: 4000 // Duration of the notification in milliseconds (4 seconds)
                                        });
                                    }}
                                >
                                    {page.name}
                                </span>
                            )}
                        </React.Fragment>
                    );
                })}

            </div>
        </div>
    )
}

export default HeaderRightSide