import React, { useEffect, useState } from 'react'
import { toast } from "react-hot-toast";
import { dietaryOptions, foodOptions, menuoptions } from '../../common';
import Select from 'react-select';
import { CheckIcon } from '@heroicons/react/solid';
import { random } from 'lodash';

const PackageItemCreator = ({ packageItems, setPackageItems, selectedMenuOption, setRefreshItemList, handleShowPackageItemCreator, editingID, setEditingID }) => {
    const [name, setName] = useState('');
    const [serves, setServes] = useState(1);
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState('');
    const [menuOption, setMenuOption] = useState(selectedMenuOption);
    const [restriction, setRestriction] = useState([]);
    const [required, setRequired] = useState(true);

    const customStyles = {
        control: base => ({
            ...base,
            height: 40,
            minHeight: 35,
            border: '2px solid #F3F4F6',

        }),
        valueContainer: base => ({
            ...base,
            overflowY: 'scroll', // Enable vertical scrolling
            height: 36,
            zIndex: 2 // Ensure the menu displays above other elements
        }),
        container: base => ({
            ...base,
        })
    };

    useEffect(() => {
        if (editingID !== -1) {
            let itemToEdit
            for (const option of menuoptions) {
                const temp = packageItems[option.value].find(item => item.id === editingID)
                if (temp != null) itemToEdit = temp
            }
            if (itemToEdit != null) {
                setName(itemToEdit.name)
                setPrice(itemToEdit.price)
                setServes(itemToEdit.serves)
                setDescription(itemToEdit.description)
                setMenuOption(itemToEdit.menuOption)
                setRestriction(itemToEdit.restriction)
                setRequired(itemToEdit.required)
            }
        }

    }, [editingID])


    const handleCancel = () => {
        // if (editing) {
        //     setEditing(false)
        //     setEditingIndex(-1)
        // } else {
        //     handleShowPackageItemCreator();
        // }
        setName('');
        setServes(1);
        setPrice(0);
        setDescription('');
        setMenuOption('');
    }

    const addItemToPackage = () => {
        if (description.length >= 40) {
            let ready = false
            let item = { id: random(9999), name, serves, price, description, menuOption, restriction, required }

            if (editingID !== -1) {
                item.id = editingID
                
                for (const option of menuoptions) {
                    const tempIdx = packageItems[option.value].findIndex(item => item.id === editingID)
                    if (tempIdx >= 0) {
                        packageItems[option.value].splice(tempIdx)
                        ready = true
                    }
                }

                if (ready) {
                    let tempPkgItems = packageItems
                    if (!tempPkgItems[selectedMenuOption])
                        tempPkgItems[selectedMenuOption] = []

                    tempPkgItems[selectedMenuOption].push(item)
                    setPackageItems(tempPkgItems);
                }
                // setEditingIndex(-1)
            } else {
                let tempPkgItems = packageItems
                if (!tempPkgItems[selectedMenuOption])
                    tempPkgItems[selectedMenuOption] = []

                tempPkgItems[selectedMenuOption].push(item)
                setPackageItems(tempPkgItems);
            }
            setName('');
            setServes(1);
            setPrice(0);
            setDescription('');
            setRestriction([])
            setRefreshItemList(random(1000))
            setEditingID(-1)
        } else {
            toast.error("Item description must be longer than 40 characters!")
        }
    }

    function handleAddItem() {
        if (name == "" || serves === "" || price == "" || description == "") {
            toast.error("Please ensure all fields are filled!")
        } else {
            addItemToPackage();
            handleShowPackageItemCreator('')
        }
    }

    return (
        <div className={`flex flex-col h-[540px] p-4 gap-y-4 bg-blue-50 rounded-md`}>
            <div className="flex items-center justify-between">
                <div className='w-full'>
                    <p class="bid-form-input-label text-xs leading-none">Add option for</p>
                    <p class="bid-form-input-label text-paragraph leading-none font-productsans-bold text-blue-600">{menuoptions.find(option => option.value === selectedMenuOption).title}</p>
                </div>
                <div>
                    <p className="andie-description-text text-xs font-roboto-medium">Required</p>
                    <input type="checkbox" className="toggle border-2" checked={required} onClick={(e) => setRequired(e.target.checked)} />
                </div>
            </div>
            <div className='w-full'>
                <label className="bid-form-input-label">Item Name</label>
                <input
                    className='mt-2 w-full bid-form-input h-9'
                    type='text'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="grid grid-cols-2 gap-x-3">
                <div className='w-full'>
                    <label className="bid-form-input-label">Serves</label>
                    <input
                        className='mt-1.5 w-full bid-form-input h-9'
                        type='number'
                        placeholder='Serves'
                        value={serves}
                        onChange={(e) => setServes(e.target.value)}
                    />
                </div>
                <div className='w-full'>
                    <label className="bid-form-input-label">Price</label>
                    <label class="mt-1.5 input-group">
                        <span>$</span>
                        <input
                            className='w-full bid-form-input h-9'
                            type='number'
                            placeholder='Price'
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                    </label>
                </div>
            </div>
            <div className="flex flex-col">
                <label className="bid-form-input-label">Description</label>
                <textarea
                    className='mt-1 w-full bid-form-input h-20'
                    type='text'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <p className='mt-1 bid-form-input-label text-xs'>Min. <b className="text-blue-600">{description.length}/40</b> characters</p>
            </div>

            <div>
            <label className="bid-form-input-label">Dietary Requirements</label>
                <Select
                    defaultValue={{ value: "None", label: "None" }}
                    isMulti
                    value={restriction.map((option) => { return { "value": option, "label": option } })}
                    options={dietaryOptions.map((option) => { return { "value": option.preference, "label": option.preference } })}
                    className="basic-multi-select w-full"
                    classNamePrefix="select"
                    styles={customStyles}
                    onChange={(e) => setRestriction((e.map(a => a.value)))}
                />
            </div>

            <div className="flex justify-end font-productsans-medium space-x-5">
                <button type="button" className='andie-outline-button h-8 border-red-600 hover:border-red-600 text-red-600 hover:bg-red-600 hover:text-white w-1/5' onClick={() => handleCancel()}>Clear</button>
                <button type="button" className='andie-dark-button h-8' onClick={() => handleAddItem()}>{editingID !== -1 ? "Update item" : "Add " + selectedMenuOption + " to package"}</button>
            </div>
        </div>
    )
}

export default PackageItemCreator