import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from 'react-router-dom';
import { BiddingAPIs } from '../../apis';
import { Header } from "../../components/navigation";
import { Drawer, LoadingBidPage, LoadingIcon, getTransactionAmounts } from '../../components/common';
import { BidResponse, BidResponseForm } from '../../components/responses';
import { ChatRender } from '../../components/messages';
import { BidDetails, BidHeader, BidInfoBar, BidResponseBar, BidTools, BidTopBar } from '../../components/bidpage';
import { WebSocket } from '../../components/sockets';

export default function BidPage() {
    const [loading, setLoading] = useState(true);

    const [bid, setbid] = useState({});
    const [bidExpired, setBidExpired] = useState(false);

    const [accepted, setAccepted] = useState(false);
    const [similarBids, setSimilarBids] = useState([]);

    const [showDrawer, setshowDrawer] = useState(false);
    const [selectedResponse, setselectedResponse] = useState({});
    const [selectedResponseIndex, setselectedResponseIndex] = useState(0);

    const [sideBarMode, setsideBarMode] = useState(0); //0 is createbidresponse, 1 is view bidresponse

    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user_details')));
    const [drawerWidth, setDrawerWidth] = useState(30);
    const [conversations, setConversations] = useState([])

    const [maxResponse, setMaxResponse] = useState("");
    const [minResponse, setMinResponse] = useState("");
    const [openResponse, setOpenResponse] = useState("");
    const printComponentRef = useRef();

    const pageparams = useParams()

    const [top, setTop] = useState(true);

    useEffect(() => {
    const scrollHandler = () => {
        window.scrollY > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);


    useEffect(() => {
        loadData()
        isBidExpired();
    }, [pageparams]);

    useEffect(() => {
        if (Object.keys(bid).length !== 0) {
            bid?.responses.map((response) => {
                if (response.accepted == true) {
                    setAccepted(true)
                }
            })
        }
    }, [bid])

    async function loadData() {
        setLoading(true)
        let singleBid = await BiddingAPIs.getSingleBid(pageparams.transaction_code);
        let simBids = await BiddingAPIs.getSimilarBids(singleBid.cge_type, singleBid.event_type, singleBid.restaurant_type, singleBid.industry);
        setbid(singleBid)
        oneMoreView(singleBid.id)
        setSimilarBids(simBids)
        let bidHistory = JSON.parse(window.localStorage.getItem("bid_history"));
        if (bidHistory) {
            let x = []
            if (!bidHistory.includes(singleBid.id)) {
                bidHistory.push(singleBid.id)
                if (bidHistory.length > 10) {
                    window.localStorage.setItem('bid_history', JSON.stringify(bidHistory.slice(0,9)))
                } else {
                    window.localStorage.setItem('bid_history', JSON.stringify(bidHistory))
                }
            }
        } else {
            window.localStorage.setItem('bid_history', JSON.stringify([singleBid.id]))
        }
        setLoading(false)
    }

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
    });

    async function getSingleBid() {
        let singleBid = await BiddingAPIs.getSingleBid(pageparams.transaction_code);
        setbid(singleBid)
        oneMoreView(singleBid.id)
        if(showDrawer == true) {
            setselectedResponse(singleBid.responses[selectedResponseIndex])
        }
    }

    async function showBidResponse(index) {
        setshowDrawer(false)
        setsideBarMode(1)
        try {
            if (user != null && (user.email == bid.responses[index].cge_email || user.email == bid.responses[index].business_email)) {
                const res = await BiddingAPIs.fetchMessages(bid.responses[index].id)
                res['resp_id'] = bid.responses[index].bid_id
                res['bid_code'] = ""
                res['bid_title'] = ""
                res['contact_name'] = bid.responses[index].contact_name
                res['cge_email'] = bid.responses[index].cge_email
                res['business_email'] = bid.responses[index].business_email
                setConversations([res])
            }
        } catch(e) {
        }
        setselectedResponseIndex(index)
        setselectedResponse(bid.responses[index])
        setshowDrawer(true)
    }

    function showBidResponseForm() {
        setshowDrawer(false)
        setsideBarMode(0)
        setshowDrawer(true)
    }

    function isBidExpired() {
        const currentDate = new Date();
        const dDate = new Date(bid.expiry_date);

        var diffDays = parseInt((dDate - currentDate) / (1000 * 60 * 60 * 24), 10);

        if (diffDays < 0) {
            setBidExpired(!bidExpired)
        }
    }

    const oneMoreView = async (id) => {
        const payload = {
            id: id,
        }

        await BiddingAPIs.updateViews(payload);
    }

    useEffect(() => {
        let data = [];
        if (user !== null) {
            if (Object.keys(bid).length !== 0) {
                bid?.responses.map((response) => {
                    let value = getTransactionAmounts(response.items, bid.budget.tipAmount, response.delivery_fee, response.business_delivers, bid.cge_service_fee, response.business_service_fee, null, response.pricing, response.total_price).total;
                    data.push(value)
                })

                if (bid?.responses.length !== 0) {
                    setMaxResponse(Math.max(...data));
                    setMinResponse(Math.min(...data));
                    setOpenResponse(getTransactionAmounts(bid?.responses[0].items, bid.budget.tipAmount, bid?.responses[0].delivery_fee, bid?.responses[0].business_delivers, bid?.cge_service_fee, bid?.responses[0].business_service_fee, null, bid?.responses[0].pricing, bid?.responses[0].total_price).total);
                }
            }
        }
    }, [bid.responses]);

    return (
        <div className="h-screen">
            {/* <Header bypassToken={true} page={"home"} refreshFunction={() => getSingleBid()} /> */}
            <WebSocket refreshFunction={() => getSingleBid()} page="home" />
            <div className="flex h-full w-full" ref={printComponentRef}>
                {loading ?
                    <LoadingBidPage />
                    :
                    <>
                        <div className="hidden md:inline-block andie-side-panel pt-6 px-4">
                            <BidTools bid={bid} handlePrint={handlePrint} />
                        </div>
                        <div className="andie-main-panel pt-0 px-0">
                            <BidTopBar bid={bid} />
                            <div className="md:pt-0 pb-10 xl:pb-20 flex-grow h-full overflow-y-auto scrollbar-hide">
                                <BidHeader bid={bid} open={openResponse} max={maxResponse} min={minResponse} />
                                <BidInfoBar top={top} bid={bid} />
                                <div className="px-3 pt-6 md:px-6">
                                    <BidResponseBar
                                        bid={bid}
                                        bidExpired={bidExpired}
                                        showBidResponse={showBidResponse}
                                        showBidResponseForm={showBidResponseForm}
                                        accepted={accepted}
                                        conversations={conversations}
                                        drawerWidth={drawerWidth}
                                        selectedResponse={selectedResponse}
                                        setConversations={setConversations}
                                        setDrawerWidth={setDrawerWidth}
                                        sideBarMode={sideBarMode}
                                    />
                                    <BidDetails bid={bid} similarBids={similarBids} />

                                    <div className="md:hidden lg:hidden xl:hidden w-full">
                                        <BidTools bid={bid} handlePrint={handlePrint} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="andie-right-panel" />
                    </>
                }
            </div>
            <Drawer
                drawerID={'bid_response'}
                title={"Bid Response"}
                width={drawerWidth}
                stateOverride={showDrawer}
                setStateOverride={setshowDrawer}
            >
                {(sideBarMode == 0 && user?.account_type==2) ?
                    <BidResponseForm
                        bid={bid}
                        responses={bid.responses}
                        setshowDrawer={(e) => setshowDrawer(e)}
                    />
                    :
                    Object.keys(selectedResponse).length == 0 ?
                        <></>
                        :
                        <>
                            <div className={"h-full w-full grid grid-cols-5 overflow-y-scroll scrollbar-hide"} >
                                <div className={drawerWidth != 30 ? 'col-span-2' : 'col-span-5'}>
                                    <BidResponse
                                        bid={bid}
                                        bidResponse={selectedResponse}
                                        accepted={accepted} //can be changed to a model variable
                                        setDrawerWidth={(e) => setDrawerWidth(e)}
                                        drawerWidth={drawerWidth}
                                        setshowDrawer={(e) => setshowDrawer(e)}
                                    />
                                </div>

                                {(drawerWidth !== 30 && user !== null) &&
                                    <div className="col-span-3 pt-3 shadow-sm">
                                        <div className="flex px-3 justify-end">
                                            <div className="andie-text-button" onClick={() => setDrawerWidth(30)}>Close messages</div>
                                        </div>
                                        <ChatRender setConversations={(e) => setConversations(e)} conversations={conversations} selectedConv={0} />
                                    </div>
                                }
                            </div>
                        </>}
            </Drawer>
        </div>
    )
}
