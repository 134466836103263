import { ArrowLeftIcon, CheckIcon, EyeIcon, NewspaperIcon, UserGroupIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import { BidChart } from "../../bidpage";

export default function LoadingBidPage() {
    const navigate = useNavigate();


    function goBack() {
        navigate(-1)
    }
    return (
        <div className="flex h-full w-full animate-pulse">
            <div className="hidden md:inline-block w-[17%] pt-6 px-4">
            </div>
            {/* MID */}
            {/* TOP BAR */}
            <div className="w-full md:w-[80%] xl:w-[63%] border-l border-r border-gray-100">
                <div className="hidden md:inline-flex w-full mt-1.5 items-center justify-between h-10 px-3 md:px-6">
                    <div className="cursor-pointer" onClick={() => goBack()}>
                        <ArrowLeftIcon className="h-4 text-gray-500" />
                    </div>
                    <div className='h-2 bg-gray-200 w-1/12'/>
                </div>
                {/* HEADER */}
                <div className="pt-7 md:pt-2 pb-4 px-3 md:px-6 border-b border-gray-100">
                    <div className="flex items-center justify-between">
                        <div className="-mt-2 lg:w-2/3">
                            <div className="flex items-center">
                                <div className="h-20 w-20 md:h-28 md:w-28 mr-3 md:mr-6 rounded-lg bg-blue-50 flex justify-center items-center">
                                    <div className="bg-blue-50 h-12 w-12 lg:h-14 lg:w-14 flex items-center justify-center flex-shrink-0 rounded-md" >
                                        {/* <img src={img} className="h-9 object-center " /> */}
                                        <div className="bg-blue-50 h-12 w-12 rounded-md flex justify-center items-center object-covermb-4" />
                                    </div>
                                </div>
                                <div>
                                    <div className='h-4 bg-gray-200 w-9/12'/>
                                    <div className="flex space-x-1.5 items-center mt-2">
                                        <div className='h-3 bg-gray-200 w-9/12'/>
                                        <div className="bg-blue-600 h-1 w-1 rounded-full" />
                                        <div className='h-3 bg-gray-200 w-9/12'/>
                                    </div>
                                    <div className="mt-2 flex space-x-2 items-center">
                                        <div className='h-3 bg-gray-200 w-10/12'/>
                                    </div>
                                    <div className="mt-2 md:mt-4 flex space-x-1">
                                        <p className="font-roboto-bold text-xs text-gray-600">Expires in </p>
                                        {/* <CountdownTimer bid={bid} datetime={bid.expiry_date} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 flex flex-row  items-center space-x-3">
                                <div className='h-2 bg-gray-200 w-2/12' />
                                <div className='h-2 bg-gray-200 w-2/12' />
                                <div className='h-2 bg-gray-200 w-2/12' />
                            </div>
                        </div>
                        <div className="hidden lg:inline-flex flex-col lg:w-[30%]">
                            <div className="h-9 px-2 flex items-center border-2 border-b-0 border-gray-200 rounded-t-xl">
                                <h6 className="andie-description-text mb-1.5 font-roboto-medium text-xs">Bid Performance</h6>
                            </div>
                            <div className="flex w-full">
                                <BidChart bid={{"responses": [], "budget": { "total": "0"}}} user={{}} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* INFO BAR */}
                <div className="flex justify-between px-3 md:px-6 border-b border-gray-100">
                    <div className="flex">
                        <div className="pr-2 py-1.5 md:pr-4 md:py-2 border-r border-gray-100">
                            <div className='h-8 bg-gray-200 w-12 mt-1'/>                        
                        </div>
                        <div className="px-2 py-1.5 md:px-4 md:py-2 border-r border-gray-100">
                            <div className='h-8 bg-gray-200 w-24 mt-1'/>
                        </div>
                        <div className="px-2 py-1.5 md:px-4 md:py-2 border-r border-gray-100">
                            <div className='h-8 bg-gray-200 w-12 mt-1'/>
                        </div>
                        <div className="px-2 py-1.5 md:px-4 md:py-2 border-r border-gray-100">
                            <div className='h-8 bg-gray-200 w-24 mt-1'/>
                        </div>
                        <div className="pl-2 md:pl-4 py-1.5 md:py-2 border-gray-100">
                            <div className='h-8 bg-gray-200 w-24 mt-1'/>
                        </div>
                    </div>
                </div>
                {/* BID RESPONSE BAR */}
                <div className="pt-6 pb-80 px-3 md:px-6 flex-grow h-full overflow-y-auto scrollbar-hide">
                    <div className="mt-2 lg:w-5/6">
                        <h6 className="bid-details-header">Bid Responses</h6>
                        <div className="mt-3 md:flex space-x-4">
                            <div className="flex overflow-x-auto space-x-4">
                                <div className="h-32 w-56 flex bg-gray-200 rounded-xl" />
                                <div className="h-32 w-56 flex bg-gray-200 rounded-xl" />
                                <div className="h-32 w-56 flex bg-gray-200 rounded-xl" />
                            </div>
                        </div>
                    </div>
                    {/* BID DETAILS */}
                    <div className="mt-14 md:w-3/4">
                        <h6 className="bid-details-header">About this bid</h6>
                        <div className="flex flex-col space-y-2 mt-2">
                            <div className='h-3 bg-gray-200 w-full'/>
                            <div className='h-3 bg-gray-200 w-full'/>
                            <div className='h-3 bg-gray-200 w-full'/>
                            <div className='h-3 bg-gray-200 w-full'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}