import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function AdminEditTransItems({items}) {
    return (
        <div className="">
            <div className="form-control">
                {items.map((item, index) => (
                    <label className="input-group py-1">
                        <span className="w-[50%]">{item.name}</span>
                        <input type="text" placeholder={"Qty: "+item.quantity} className="w-[25%] input input-bordered" />
                        <input type="text" placeholder={"Price: $"+item.price} className="w-[25%] input input-bordered" />
                    </label>
                ))}
            </div>
        </div>
    )
}