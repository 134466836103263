import React, { useState, useEffect } from "react";
import { InformationCircleIcon, PencilAltIcon, PlusIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { toast } from "react-hot-toast";
import { AdminNavbar, AdminSidebar, AdminCreateBusiness, AdminEditBusiness, AdminDeleteBusiness } from '../../components/admin';
import { AccountsAPI } from "../../apis";
import { PopoutModal } from "../../components/common";

//RICKY WHERES THE LOADING HERE
export default function AdminBusinessUsers({ parentLoading, staffAccountList }) {
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [businessModal, setBusinessModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedBusiness, setSelectedBusiness] = useState(0)
    var user = JSON.parse(window.localStorage.getItem('admin_user_details'));

    useEffect(() => {
        if (
            parentLoading[0]
        ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [parentLoading])

    useEffect(() => {
        if (user !== null) {
            getData();
        } else {
            setAccounts([])
        }
    }, [refresh]);

    async function getData() {
        setLoading(true);
        var result = await AccountsAPI.getBusinessAccounts();
        setAccounts([...result]);
        setLoading(false)
    }



    return (
        <div className="h-full overflow-scroll scrollbar-hide">
            {loading ?
                <div className="flex flex-col space-y-5 w-full h-full items-center justify-center">
                    <span className="loading loading-spinner loading-lg"></span>
                    <p className="font-productsans-medium">Loading Business Users</p>
                </div>

                :
                <>
                    <AdminEditBusiness staffAccountList={staffAccountList} setEditModal={(e) => setEditModal(e)} editModal={editModal} business={accounts[selectedBusiness]} getData={() => getData()} />
                    <AdminDeleteBusiness setDeleteModal={(e) => setDeleteModal(e)} deleteModal={deleteModal} business={accounts[selectedBusiness]} />
                    <table className="table table-pin-rows table-pin-cols w-full">
                        {/* head */}
                        <thead>
                            <tr>
                                {/* <th>ID</th> */}
                                <th>Company</th>
                                <th>Partner</th>
                                <th>Address</th>
                                <th>Account Manager</th>
                                <th>Modify</th>
                            </tr>
                        </thead>
                        <tbody>
                            {accounts.length > 0 ?
                                accounts.map((account, index) => (
                                    <tr className="font-roboto-regular text-paragraph">
                                        <th>{account.business_name}</th>
                                        <td className="w-[30%]">
                                            <div className="flex bid-label text-gray-600 mb-0">{account.first_name} {account.last_name}</div>
                                            <div>{account.email}</div>
                                            {account.phone}
                                        </td>
                                        <td className="w-[25%]">{account.address_details}</td>
                                        <td className="w-[10%]">{account?.business_representative}</td>
                                        <td className="w-[5%]">
                                            <button className="w-full hover:bg-gray-200 p-1 rounded-md text-blue-600" onClick={() => { setSelectedBusiness(index); setEditModal(true) }}><PencilAltIcon /></button>
                                        </td>
                                    </tr>
                                ))
                                :
                                <></>
                            }
                        </tbody>
                    </table>

                    <PopoutModal
                        button={
                            <button
                                class="absolute bottom-12 right-10 z-99 flex flex-row items-center space-x-2 
                    text-white px-4 w-auto h-10 bg-blue-600 rounded-full
                    hover:bg-blue-700 active:shadow-lg mouse shadow-xl
                    transition ease-in duration-200 focus:outline-none">
                                <PlusIcon className="h-4" />
                                <span>New Partner User</span>


                            </button>
                        }
                        modalID={"refLink"}
                        title={"Create Partner Account"}
                    >
                        <AdminCreateBusiness staffAccountList={staffAccountList} setBusinessModal={(e) => setBusinessModal(e)} businessModal={businessModal} getData={() => getData()} />
                    </PopoutModal>
                </>}
        </div>
    )
}