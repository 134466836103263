import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { getTransactionAmounts } from "../common";
import { BiddingAPIs } from '../../apis';
import { BillingForm } from '../billing';


export default function BidAccept({ bid, response, guestCount, openModal, setOpenModal }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    
    const transItems = getTransactionAmounts(response.items, bid.budget.tipAmount, response.delivery_fee, response.business_delivers, bid.cge_service_fee, response.business_service_fee)
    const [responseSubtotal, setResponseSubtotal] = useState(transItems.subtotal)
    const [serviceFee, setServiceFee] = useState(transItems.serviceFee)
    const [deliveryFee, setDeliveryFee] = useState(transItems.deliveryFee)
    const [tax, setTax] = useState(transItems.tax)
    const [tip, setTip] = useState(transItems.tip)

    async function handleSubmit(paymentInfo) {
        try {
            const payload = {
                bidResponseID: response.id,
                paymentMethodID: paymentInfo.paymentMethod.id,
                cgeStripeID: user.stripe_account_token,
                subtotal: responseSubtotal,
                delivery_fee: deliveryFee,
                business_delivers: response.business_delivers,
                tax: tax,
                serviceFee: serviceFee,
                tip: tip,
            }
            
            const res = await BiddingAPIs.acceptBidResponse(
                payload
            ).then(() => {
            })
        } catch (e) {
        }
    }
    return (
        <>

            <Transition appear show={openModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setOpenModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="md:w-[60%] w-[100%] h-[70%] transform overflow-hidden rounded-lg bg-gray-50 px-10 pt-4 pb-10 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="md:text-2xl text-xl text-center fold-bold font-productsans-large leading-6 text-gray-900 pb-4"
                                    >
                                        Confirm Order
                                    </Dialog.Title>
                                    <BillingForm
                                        serviceFeeRate={bid.cge_service_fee}
                                        tax={tax} setTax={(e) => setTax(e)}
                                        tip={tip} 
                                        subtotal={responseSubtotal} setSubtotal={(e) => setResponseSubtotal(e)}
                                        serviceFee={serviceFee} setServiceFee={(e) => setServiceFee(e)}
                                        handleSubmit={(paymentInfo) => handleSubmit(paymentInfo)}
                                        items={response.items} openModal={true}
                                        setOpenModal={(e) => console.log()} response={response}
                                        user={user} tipAmount={"5.00"} guestCount={guestCount}
                                        bid={bid} />
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
