import React from 'react';
import { PackageItemsView } from '..';


const PackageViewer = ({ item, setOrderStep, selectedPackage, setSelectedPackage, page }) => {
    return (
        <div className="mt-5 flex overflow-x-scroll gap-4">
            {item.packages.map((pkg, pkgIndex) => (
                <PackageItemsView howmany={item.packages.length} item={item} pkg={pkg} page={page} selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage} />
            ))}
        </div>
    )
}

export default PackageViewer;