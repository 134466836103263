export const password_styles = {
    modal: {
        backgroundColor: "white",
        boxShadow: "none",
        overflow: "none",
        padding: "0",
        margin: "0",
        width: "35%",
        height: "80%",
        justifyContent: "center",
        borderRadius: "2px",
    },
    overlay: {
        backgroundColor: "#5F656C",
        opacity: 0.8,
        padding: 0,
    },
};

//victord commit
export const header_styles_mobile = {
    modal: {
      backgroundColor: "white",
      boxShadow: "none",
      overflow: "none",
      padding: "0",
      margin: "0",
      width: "90%",
      height: "75%",
      justifyContent: "center",
      borderRadius: "2px",
    },
    overlay: {
      backgroundColor: "#5F656C",
      opacity: 0.8,
      padding: 0,
    },
  };
  
  export const header_styles = {
    modal: {
      backgroundColor: "white",
      boxShadow: "none",
      overflow: "none",
      padding: "0",
      margin: "0",
      maxWidth: "53%",
      height: "90%",
      justifyContent: "center",
      borderRadius: "2px",
    },
    overlay: {
      backgroundColor: "#5F656C",
      opacity: 0.8,
      padding: 0,
    },
  };
  