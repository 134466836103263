import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { PDFViewer, View, Text, Page, Document, PDFDownloadLink } from '@react-pdf/renderer';
import Modal from "react-responsive-modal";
import _ from 'lodash';

import { pricetoString, pad, getTransactionAmounts } from "../common";
import moment from "moment";

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        width: "40%",
        height: '85%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
    page: {
        flexDirection: 'col',
        backgroundColor: '#FFF',
        paddingHorizontal: 25
    },
    text: {
        marginTop: 4,
        fontSize: 10,
        color: "#9ca3af"
    },
    subheading: {
        color: "#374151",
        fontSize: 9,
        marginBottom: 5
    }
};


export default function TransactionReceipt({ transaction, account_type, paymentDate }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isTransactionView, setTransactionView] = useState(false);
    const location = useLocation();

    const transItems = getTransactionAmounts(transaction.response.items, transaction.tipAmount, transaction.delivery_fee, transaction.business_delivers, transaction.bid.cge_service_fee, transaction.response.business_service_fee, account_type, transaction.response.pricing, transaction.response.total_price)
    const subtotal = transItems.subtotal
    const service_fee = transItems.serviceFee
    const delivery_fee = transItems.deliveryFee
    const tax = transItems.tax
    const gratuity = transItems.tip
    const total = transItems.total


    useEffect(() => {
        // Function to check if the URL contains "/view/transaction/"
        const checkTransactionView = () => {
            const containsTransactionView = location.pathname.includes('/view/transaction/');
            setTransactionView(containsTransactionView);
        };

        // Call the function immediately to set the initial state based on the current URL
        checkTransactionView();
    }, [location]);


    const eventDetails = [
        { title: "Location", data: transaction.bid.location },
        { title: "Date", data: new Date(transaction.bid.event_date_time).toDateString() },
        { title: "Type", data: transaction.bid.cge_type }
    ];

    const payment = [
        { title: "Due Date", data: moment(paymentDate).format("ddd MMMM Do YYYY")},
        { title: "Payment Method", data: "Digital" }
    ];

    const summary = [
        { title: "Subtotal", data: subtotal },
        { title: "Service Fee", data: service_fee },
        { title: "Delivery Fee", data: delivery_fee },
        { title: "Tax", data: tax },
        { title: "Gratuity", data: gratuity },
    ];

    const rows = 12 - transaction.response.items.length;

    // Create Document Component
    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{ marginTop: 30, marginBottom: 15, flexDirection: "row", justifyContent: "space-between" }}>
                    <View>
                        <Text style={styles.text}>Pluck Technologies Inc.</Text>
                        <Text style={styles.text}>600 - 10 Dundas Street East,</Text>
                        <Text style={styles.text}>Toronto, ON M5B 2G9</Text>
                    </View>
                    <View>
                        <Text style={{ fontSize: 16, color: "#2563eb", fontWeight: "extrabold" }}>{account_type === 2 ? "PO" : "Invoice"} #{pad(transaction.id, 5)}</Text>
                        <Text style={{ marginTop: 5, fontSize: 10, color: "#4b5563" }}><Text style={{ color: "#374151" }}>Issue Date:</Text> {moment(moment.now()).format("ddd MMMM Do YYYY")}</Text>
                    </View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-between", borderTop: "1px solid #F3F4F6", borderBottom: "1px solid #F3F4F6", marginVertical: 15 }}>
                    <View style={{ width: "30%", paddingVertical: 10, paddingRight: 10, borderRight: "1px solid #F3F4F6" }}>
                        <Text style={styles.subheading}>Bill To:</Text>
                        <Text style={styles.text}>{transaction.bid.organization}</Text>
                        <Text style={styles.text}>{transaction.bid.contact_first_name} {transaction.bid.contact_last_name}</Text>
                        <Text style={styles.text}>{transaction.bid.contact_email}</Text>
                    </View>
                    <View style={{ width: "40%", padding: 10, borderRight: "1px solid #F3F4F6" }}>
                        <Text style={styles.subheading}>Event Details</Text>
                        {eventDetails.map((detail, index) => (
                            <Text style={styles.text} id={index}><Text style={{ color: "#6b7280" }}>{detail.title}:</Text> {detail.data} </Text>
                        ))}
                    </View>
                    <View style={{ width: "30%", padding: 10 }}>
                        <Text style={styles.subheading}>Payment</Text>
                        {payment.map((detail, index) => (
                            <Text style={styles.text} id={index}><Text style={{ color: "#6b7280" }}>{detail.title}:</Text> {detail.data} </Text>
                        ))}
                        <View style={{ marginTop: 2, alignItems: "center", justifyContent: "center", width: 50, height: 15, border: 0, borderRadius: 2, backgroundColor: "#2563eb" }}>
                            <Text style={[styles.subheading, { color: "#FFF", marginBottom: 0 }]}>{transaction.status}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ marginTop: 5 }}>
                    <View style={{ flexDirection: "row", height: 32, backgroundColor: "#dbeafe", borderRadius: 2, paddingHorizontal: 10, alignItems: "center" }}>
                        <View style={{ width: "40%" }}>
                            <Text style={[styles.text, { color: "#1d4ed8", marginTop: 0 }]}>Item</Text>
                        </View>
                        <View style={{ width: "14%" }}>
                            <Text style={[styles.text, { color: "#1d4ed8", marginTop: 0 }]}>Category</Text>
                        </View>
                        <View style={{ width: "14%" }}>
                            <Text style={[styles.text, { color: "#1d4ed8", marginTop: 0 }]}>Price</Text>
                        </View>
                        <View style={{ width: "14%" }}>
                            <Text style={[styles.text, { color: "#1d4ed8", marginTop: 0 }]}>Quantity</Text>
                        </View>
                        <View style={{ width: "14%" }}>
                            <Text style={[styles.text, { color: "#1d4ed8", marginTop: 0 }]}>Serves</Text>
                        </View>
                        <View style={{ width: "14%", textAlign: "right" }}>
                            <Text style={[styles.text, { color: "#1d4ed8", marginTop: 0 }]}>Subtotal</Text>
                        </View>
                    </View>
                    {transaction.response.items.map((item, index) => (
                        <View style={{ height: 32, flexDirection: "row", borderBottom: "1px dashed #e5e7eb", alignItems: "center", paddingHorizontal: 10 }}>
                            <View style={{ width: "40%" }} id={index}>
                                <Text style={[styles.text, { marginTop: 0 }]}>{item.name}</Text>
                            </View>
                            <View style={{ width: "14%" }}>
                                <Text style={[styles.text, { marginTop: 0 }]}>{item.menuoption}</Text>
                            </View>
                            <View style={{ width: "14%" }}>
                                <Text style={[styles.text, { marginTop: 0 }]}>${pricetoString(item.price)}</Text>
                            </View>
                            <View style={{ width: "14%" }}>
                                <Text style={[styles.text, { marginTop: 0 }]}>{item.quantity}</Text>
                            </View>
                            <View style={{ width: "14%" }}>
                                <Text style={[styles.text, { marginTop: 0 }]}>{item.serves}</Text>
                            </View>
                            <View style={{ width: "14%", textAlign: "right" }}>
                                <Text style={[styles.text, { marginTop: 0 }]}>${pricetoString(item.price * item.quantity)}</Text>
                            </View>
                        </View>
                    ))}
                    {_.times(rows, () => (
                        <View style={{ height: 32, flexDirection: "row", borderBottom: "1px dashed #e5e7eb", padding: 10 }} />
                    ))}
                    <View style={{ flexDirection: "col", paddingHorizontal: 10 }}>
                        {summary.map((item, index) => (
                            <View style={{ flexDirection: "row" }} id={index}>
                                <View style={{ width: "85%", textAlign: "right", paddingRight: 15, paddingVertical: 10, borderRight: "1px dashed #e5e7eb" }}>
                                    <Text style={[styles.text, { marginTop: 0 }]}>{item.title}</Text>
                                </View>
                                <View style={{ width: "15%", textAlign: "right", paddingLeft: 15, paddingVertical: 10 }}>
                                    <Text style={[styles.text, { marginTop: 0 }]}>{(account_type === 2 && item.title === "Service Fee") && "-"}${pricetoString(item.data)}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                    <View style={{ flexDirection: "row", paddingHorizontal: 10, backgroundColor: "#dbeafe" }}>
                        <View style={{ width: "85%", textAlign: "right", paddingRight: 15, paddingVertical: 10 }}>
                            <Text style={[styles.text, { marginTop: 0, color: "#2563eb" }]}>Total</Text>
                        </View>
                        <View style={{ width: "15%", textAlign: "right", paddingLeft: 15, paddingVertical: 10 }}>
                            <Text style={[styles.text, { marginTop: 0, color: "#2563eb" }]}>${pricetoString(total)}</Text>
                        </View>
                    </View>
                </View>
                <Text style={[styles.text, { textAlign: "right", marginTop: 10 }]}>This document is private and confidential.</Text>
            </Page>
        </Document>
    );

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    return (
        <div>
            {isTransactionView ? (
                <div>
                    <PDFDownloadLink document={<MyDocument />} fileName={`Andie_Transaction_${pad(transaction.id)}.pdf`} className="cursor-pointer text-xs font-productsans-medium text-blue-600">
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : `Download ${account_type === 2 ? "Purchase Order" : "Invoice"}`
                        }
                    </PDFDownloadLink>
                </div>
            ) : (
                <div>
                    <div onClick={toggleModal} className="cursor-pointer text-paragraph font-productsans-medium text-blue-600">Download {account_type === 2 ? "P.O." : "Invoice"}</div>

                    <Modal
                blockScroll={false}
                        open={isOpen}
                        onClose={toggleModal}
                        animationDuration={400}
                        showCloseIcon={false}
                        styles={styles}
                        center
                    >
                        <PDFViewer className="w-full h-full">
                            <MyDocument />
                        </PDFViewer>
                    </Modal>
                </div>
            )}
            {/*  */}
        </div>
    );

}