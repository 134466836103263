import { useState } from "react";
import { CurrencyDollarIcon, NewspaperIcon, UserGroupIcon } from "@heroicons/react/solid";
import moment from "moment";

import { BidEdit } from ".";
import { calculateBudget, moneySignText, pricetoString } from "../common";

export default function BidInfoBar({ bid }) {
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem('user_details')));

    return (
        <div className="flex justify-between px-3 md:px-6 border-b border-gray-100">
            <div className="flex">
                <div className="pr-2 py-1.5 md:pr-4 md:py-2 border-r border-gray-100">
                    <div className="flex space-x-1 items-center mb-0">
                        <h6 className="font-productsans-medium text-sm md:text-paragraph xl:text-sm text-gray-700">{bid?.responses.length}</h6>
                        <NewspaperIcon className="h-4 text-gray-700" />
                    </div>
                    <p className="andie-description-text font-roboto-medium text-[11px] text-gray-500">{bid.responses.length != 1 ? "Responses" : "Response"}</p>
                </div>
                <div className="px-2 py-1.5 md:px-4 md:py-2 border-r border-gray-100">
                    {user !== null ?
                        <div className="flex space-x-1 items-center mb-0">
                            <h6 className="font-productsans-medium text-sm md:text-paragraph xl:text-sm text-gray-700">${pricetoString(calculateBudget(bid.budget.total, 1))}</h6>
                            <CurrencyDollarIcon className="hidden md:inline-flex h-4 text-gray-700" />
                        </div>
                        : <h6 className="font-productsans-medium text-sm md:text-paragraph xl:text-sm text-gray-700">{moneySignText(bid.budget.total)}</h6>}
                    <p className="andie-description-text font-roboto-medium text-[11px] text-gray-500">Budget</p>
                </div>
                <div className="px-2 py-1.5 md:px-4 md:py-2 border-r border-gray-100">
                    <div className="flex space-x-1 items-center mb-0">
                        <UserGroupIcon className="mt-[0.125rem] h-4 text-gray-700" />
                    </div>
                    <p className="mt-[0.158rem] andie-description-text font-roboto-medium text-[11px] text-gray-500">{bid.guest_count} guests</p>
                </div>
                <div className="px-2 py-1.5 md:px-4 md:py-2 border-r border-gray-100">
                    <div className="flex space-x-1 items-center mb-0">
                        <h6 className="font-productsans-medium text-sm line-clamp-1 md:text-paragraph xl:text-sm text-gray-700">{bid.event_type}</h6>
                    </div>
                    <p className="andie-description-text font-roboto-medium text-[11px] text-gray-500">Event type</p>
                </div>
                <div className="pl-2 md:pl-4 py-1.5 md:py-2 border-gray-100">
                    <h6 className="font-productsans-medium text-sm line-clamp-1 md:text-paragraph xl:text-sm text-gray-700">{moment(bid.event_date_time).format('ll')}</h6>
                    <p className="text-[11px] text-gray-500 mt-[0.158rem] font-roboto-regular">{moment(bid.event_date_time).format('LT')}</p>
                </div>
            </div>
            {((user !== null) && (user.email == bid.cge_email)) && <BidEdit bid={bid} />}
        </div>
    )
}