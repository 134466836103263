import { CubeIcon } from "@heroicons/react/solid"

export default function LoadingCollectionsExplore({ popular }) {
    const arr = [1, 2, 3, 4]
    return (
        arr.map(() => {
            return (
                <div className={`cursor-pointer my-3 animate-pulse ${popular ? "w-full" : ""}`}>
                    <div className="relative">
                        <div className="bg-gray-200 rounded-md flex justify-center items-center object-cover w-full h-32 lg:h-40 mb-4" />
                        <div className="absolute top-2 right-2 bg-white px-2 py-1 rounded-full h-4 w-20" />
                    </div>
                    <div className='h-2 bg-gray-200 w-9/12'/>

                    <div className="mt-1 flex justify-between">
                        <div className="flex flex-col w-full mt-1 space-y-2">
                            <div className='h-2 bg-gray-200 w-4/12'/>
                            <div className='h-2 bg-gray-200 w-2/12'/>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div className='h-2 bg-gray-200 w-4/12'/>
                    </div>

                </div>
            )
        })
    )
}