import React , {useEffect, useState} from "react";
import ReactDatePicker from "react-datepicker";
import Autocomplete from "react-google-autocomplete";
import moment from "moment";
import {isWithinInterval} from "date-fns";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { CheckIcon} from "@heroicons/react/solid";

export default function EditEventDetails({ location, setLocation, setLocationPlaceID, setLocationGeometry, locationAdditionalDetails, setLocationAdditionalDetails, setLocationSecondaryDetails, locationSecondaryDetails, eventDateTime, setEventDateTime, guestCount, setGuestCount, accessibility, setAccessibility, expiryDate, setExpiryDate, waiveCancellationFee, setWaiveCancellationFee, cgeType, deliveryTime, setDeliveryTime }) {
    const filterPassedTime = (time) => {
        return moment(moment().toDate()).add(1, "d") < moment(time);
    };

    const filterDate = (date) => {
        return moment(date) > moment(moment().toDate());
    };
    
    const filterDateExpiry = (date) => {
        return moment(date).isBetween(moment(moment().toDate()).add(-1,'d'), moment(eventDateTime).toDate(), null, [])
    };
    
    const filterPassedTimeExpiry = (time) => {
        const eventTimeMinus4Hours = moment(moment(eventDateTime).toDate()).add(-4, "h");
        return moment(time).isBetween(moment(), eventTimeMinus4Hours, null, "[]");
    };
    const next48HoursInterval = {
        start: moment().toDate(),
        end: moment(moment().add(2, 'd')).toDate()
    };
    const isEventWithin48Hours = isWithinInterval(expiryDate, next48HoursInterval);
    const [deadlineGuidelines, setDeadlineGuidelines] = useState("");
    function handleEventDatetime(date){
        setEventDateTime(date)
        setExpiryDate(moment(moment(date).toDate()).add(-4, "h").toDate())
    }

    function handleAccessibility(item) {
        let arr = {...accessibility}
        arr[item] = !arr[item]
        setAccessibility(arr)
    }

    useEffect (() => {
        if (moment(expiryDate).isBefore(moment())){
            setExpiryDate(moment().add(1,'h').toDate())
        }
    },[expiryDate])
    
    useEffect (() => {
        if (moment(eventDateTime).isBefore(moment().add(1,'d'))){
            // toast.error("Expiry Date cannot be in the past")
            setEventDateTime(moment().add(1,'d').add(1,'h').toDate())
        }
    },[eventDateTime])

    useEffect(() => {
        const next72HoursInterval = {
            start: moment().toDate(),
            end: moment(moment().add(72, 'hours')).toDate()
        };

        const next5DayInterval = {
            start: moment().toDate(),
            end: moment(moment().add(5, 'd')).toDate()
        };
        if ((cgeType === "Catering" || cgeType === "Catering Plus") && guestCount > 30) {
            const expiryDateMinimum = isWithinInterval(expiryDate, next72HoursInterval);
            if (expiryDateMinimum) {
                setDeadlineGuidelines("Catering");
            } else {
                setDeadlineGuidelines("");
            }
        }

        if ((cgeType === "Dining" || cgeType === "Premium Dining") && guestCount > 20) {
            const expiryDateMinimum = isWithinInterval(expiryDate, next5DayInterval);
            if (expiryDateMinimum) {
                setDeadlineGuidelines("Dining");
            } else {
                setDeadlineGuidelines("");
            }
        }
    
        
    },[expiryDate,guestCount,cgeType])

    function getLocationDetails(loc) {
        var country = loc.filter(item => item.types.includes('country')).map(item => item.short_name)
        var province = loc.filter(item => item.types.includes('administrative_area_level_1')).map(item => item.short_name)
        var city = loc.filter(item => item.types.includes('administrative_area_level_2')).map(item => item.short_name)

        return (city + ', ' + province + " " + country)
    }

    return (
        <div className="flex border-b border-gray-100 py-5">
            <div className="w-full pr-5">
                <h1 className="bid-form-input-label">Event location</h1>
                <Autocomplete
                    className="mt-3 w-full bid-form-input"
                    onPlaceSelected={(place) => {
                        setLocation(place.formatted_address)
                        setLocationAdditionalDetails(getLocationDetails(place.address_components));
                        setLocationPlaceID(place.place_id)
                        setLocationGeometry(JSON.stringify(place.geometry.location))
                    }}
                    options={{ types: ["geocode", "establishment"] }}
                    placeholder="Enter event location"
                    defaultValue={location}
                />
                <input
                    className="w-full bid-form-input mt-2"
                    id="secondary_details_input"
                    onChange={(e) => setLocationSecondaryDetails(e.target.value)}
                    type="text"
                    required
                    placeholder="Apt, Suite, Unit, Building"
                    aria-label="Bid Title"
                    defaultValue={locationSecondaryDetails}
                />
                <div className="flex mt-5 space-x-4">
                    <div className="w-1/2">
                        <h1 className="bid-form-input-label">Date and time of Event</h1>
                        <ReactDatePicker
                            name="date"
                            required
                            showTimeSelect
                            filterTime={filterPassedTime}
                            filterDate={filterDate}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="w-full bid-form-input"
                            selected={eventDateTime}
                            onChange={(date) => handleEventDatetime(date)}
                            minDate={moment(moment().toDate())}
                            nextMonthButtonLabel=">"
                            previousMonthButtonLabel="<"
                        />
                    </div>
                    <div className="w-1/4">
                        <h1 className="bid-form-input-label">{cgeType == "Catering" || cgeType == "Catering Plus" ? "Delivery Time" : "Serving Time"}</h1>
                        <ReactDatePicker
                            name="time"
                            required
                            showTimeSelectOnly
                            showTimeSelect
                            filterTime={filterPassedTime}
                            filterDate={filterDate}
                            dateFormat="h:mm aa"
                            className="w-full bid-form-input"
                            selected={deliveryTime}
                            onChange={(date) => setDeliveryTime(date)}
                            minDate={moment(moment().toDate())}
                            nextMonthButtonLabel=">"
                            previousMonthButtonLabel="<"
                        />
                    </div>
                    <div className="w-1/2">
                        <h1 className="bid-form-input-label">Expiry Date</h1>
                        <ReactDatePicker
                            name="date"
                            required
                            showTimeSelect
                            filterDate={filterDateExpiry}
                            filterTime={filterPassedTimeExpiry}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="w-full bid-form-input"
                            selected={expiryDate}
                            onChange={(date) => setExpiryDate(date)}
                            minDate={moment(moment().toDate()).add(1, "d")}
                            nextMonthButtonLabel=">"
                            previousMonthButtonLabel="<"
                        />
                    </div>
                </div>
                {deadlineGuidelines === "Catering" && (
                    <div className="text-red-500 text-xs font-productsans-medium mb-4 mt-2">
                        Please note: The minimum expiry date for events with more than 30 people is a minimum of 72 hours before the event.
                    </div>
                )}

                {deadlineGuidelines === "Dining" && (
                    <div className="text-red-500 text-xs font-productsans-medium mb-4 mt-2">
                        Please note: The minimum expiry date for events with more than 20 people is a minimum of 5 business days before the event.
                    </div>
                )}
                {isEventWithin48Hours &&
                        <>
                            <div className="flex grid grid-cols-4 mt-2">
                                <h6 className="bid-form-input-label text-sm font-productsans-medium col-span-3">Waive Cancellation Fee</h6>
                                <input
                                    className="h-4 items-left"
                                    type="checkbox"
                                    checked={waiveCancellationFee}
                                    onChange={() => setWaiveCancellationFee(!waiveCancellationFee)}
                                />
                            </div>
                            <div className="flex space-x-2.5">
                                <InformationCircleIcon className="text-gray-400 h-3.5" />
                                <p className="w-4/5 andie-section-descriptor text-gray-400 mr-5">I understand that once I select a restaurant for this bid, I cannot cancel the transaction less than 48 hours to the event.</p>
                            </div>
                        </>
                    }
                <div className="w-full mt-5">
                        <h1 className="bid-form-input-label">No. of guests</h1>
                        <div className="mt-3">
                            <div className="border-2 rounded h-9 px-3 flex items-center border-gray-100">
                                <div className="flex w-60 items-center">
                                    <h4 className="text-xs font-productsans-medium text-gray-400">Guest Count</h4>
                                    <div className="h-1 w-1 mx-2 bg-gray-200 rounded-full" />
                                </div>
                                <input
                                    className="w-full text-right px-0 border-transparent h-6 focus:ring-0 focus:border-l-0 focus:border-t-0 focus:border-r-0 focus:border-b-2 focus:border-blue-600 my-1 andie-description-text text-gray-500"
                                    id="guest_count"
                                     type="number" onWheel={(e) => e.target.blur()} 
                                    min="1"
                                    required
                                    aria-label="Guest_count"
                                    onChange={(e) => setGuestCount(e.target.value)}
                                    value={guestCount}
                                />
                            </div>
                        </div>
                    </div>
                <div className="flex flex-col border-b py-5">
                    <h1 className="bid-form-input-label">Location Details</h1>
                    <div className="w-full flex flex-wrap mt-3">
                        {Object.keys(accessibility).map((item, index)=> (
                            <div className="w-1/4 pr-2 mb-2 flex items-center space-x-1">
                                <div onClick={() => handleAccessibility(item)} className={`cursor-pointer border-2 h-4 w-4 mr-1 flex items-center justify-center rounded ${accessibility[item] ? 'bg-blue-600  border-blue-600' : 'border-gray-300'}`}>
                                    {accessibility[item]==true && <CheckIcon className="h-4 rounded-sm text-white" />}
                                </div>
                                <p className="ml-1.5 bid-form-input-label mb-0">{item}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}