import React from "react";

export default function CollectingInformation() {
    return (
        <div className="andie-description-text">
            <h1 className="font-productsans-medium text-base mb-3">How We Collect Information About You</h1>
            <p>We use different methods to collect your information, including:</p>
            <ul class="list-disc ml-10 mt-2">
                <li>direct interactions with you when you provide it to us (for example, by filling in forms or corresponding to us by phone, email or otherwise);</li>
                <li>automated technologies or interactions, as you navigate through our Site, including Device Information (defined below); and</li>
                <li>third party or publicly available sources (for example, our business partners).</li>
            </ul>
            <p class="mt-2 sm:mt-8"><strong>Information You Provide Us</strong></p>
            <p>The information we collect directly from you on or through our Site may include:</p>
            <ul class="list-disc ml-10">
                <li>information you provide by filling in forms on our Site, which may include information provided at the time of registering to use our Site, subscribing to our service, posting material, and/or requesting further service; we may also ask you for information when you report a problem with the Site;</li>
                <li>records and copies of your correspondence, if you contact us; and</li>
                <li>details of transactions you carry out through the Site and of the fulfillment of any service requests.</li>
            </ul>
            <p class="mt-2 sm:mt-8"><strong>Information We Collect Through Cookies and other Automatic Data Collection Technologies</strong></p>
            <p>When you visit the Site, we may use cookies or other automatic data collection technologies to collect certain information about your device, equipment, browsing actions and patterns, including information about your web browser, IP address, time zone, logs, web beacons, tags, pixels, operating system, traffic data, location data, other communication data and the resources that you access and use on the Site and some of the cookies that are installed on your device. Additionally, as you browse the Site, we may collect information about the individual web pages or features that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site over time. We refer to all automatically collected data as &ldquo;<strong>Device Information</strong>.&rdquo;</p>
            <p class="mt-8">Collecting Device Information helps us improve our Site and to deliver a better, more personalize service, enabling us to estimate our audience size and usage pattern; store information about preferences; speed up your searches; and recognize you when you return to the Site.</p>
            <p>The technologies we use to collect Device Information may include:</p>
            <ul class="list-disc ml-10">
                <li>&ldquo;<strong>Cookies</strong>&rdquo; which are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit&nbsp;<a href="http://www.allaboutcookies.org" class="text-blue-600 visited:text-purple-600">http://www.allaboutcookies.org</a>. &nbsp;</li>
                <li>&ldquo;<strong>Log files</strong>&rdquo; which track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
                <li>&ldquo;<strong>Web beacons</strong>,&rdquo; &ldquo;<strong>tags</strong>,&rdquo; and &ldquo;<strong>pixels</strong>&rdquo; which are electronic files used to record information about how you browse the Site.</li>
            </ul>
            <p class="mt-2 sm:mt-8">We may also use technologies to collect information about your online activities over time and across third party websites or other online services (behaviour tracking). To learn more or to opt-out of tailored advertising please visit <a href="https://youradchoices.ca/en/tools" class="text-blue-600 visited:text-purple-600">https://youradchoices.ca/en/tools</a> and/or the other links below for information on how your can opt out of behavioral tracking on the Site and how we respond to web browser signals and other mechanism that enable customers to exercise choice about behaviour tracking.</p>
            <p><span>&nbsp;</span></p>
        </div>
    )
}