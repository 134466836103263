import { PlusCircleIcon } from '@heroicons/react/outline';
import React from 'react';

const CreateBidButton = ({func}) => {
    return (
        <button
            className="w-32 andie-dark-button"
            onClick={func}>
            <PlusCircleIcon className="h-5 text-white mr-1" />
            Create a bid
        </button>
    )
}

export default CreateBidButton;