import React from 'react';

const TemplateImage = ({item}) => {
    return (
        <div className="flex overflow-x-scroll scrollbar-hide xl:w-5/6 space-x-4">
            {item.images.map((img) => {
                return (
                    <img src={img?.url} className="h-44 w-2/5 md:w-1/4 object-cover rounded-lg" />
                )
            })}
        </div>
    )
}

export default TemplateImage;