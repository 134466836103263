import React, { useState } from "react";

import { AdjustmentsIcon, MinusIcon, ShieldCheckIcon } from "@heroicons/react/solid";
import { cgeOptions, priceFilters, guestFilters, eventOptions, foodOptions, starOptions } from "../../../components/common/global";
import { useNavigate } from "react-router-dom";

export default function SidebarTemplates({ globalFilters, setGlobalFilters, filteredData }) {
    const navigate = useNavigate();

    const [minBudget, setMinBudget] = useState(null)
    const [maxBudget, setMaxBudget] = useState(null)

    const [cuisineViewAll, setCuisineViewAll] = useState(false);
    const [happeningSoon, setHappeningSoon] = useState(false)
    const [popular, setPopular] = useState(false);

    const handleFilter = (category, filter) => {
        let updatedFilters = { ...globalFilters }; // Create a copy of globalFilters
        
        switch (category) {
            case 'discover':
                if (updatedFilters.discover === null || updatedFilters.discover !== filter) {
                    updatedFilters.discover = filter;
                } else {
                    updatedFilters.discover = null;
                }
                break;
            case 'budget':
                if (updatedFilters.budget === null || updatedFilters.budget.title !== filter.title) {
                    updatedFilters.budget = filter;
                } else {
                    updatedFilters.budget = null
                    handlePriceChange(null, null);
                }
                break;
            case 'guests':
                if (updatedFilters.guests === null || updatedFilters.guests.title !== filter.title) {
                    updatedFilters.guests = filter;
                } else {
                    updatedFilters.guests = null
                }
                break;
            case 'event':
                if (updatedFilters.event === null || updatedFilters.event !== filter) {
                    updatedFilters.event = filter
                } else {
                    updatedFilters.event = null
                }
                break;
            case 'cuisine':
                if (updatedFilters.cuisines === null || !updatedFilters.cuisines.includes(filter)) {
                    updatedFilters.cuisines = [...updatedFilters.cuisines, filter]
                } else {
                    updatedFilters.cuisines = updatedFilters.cuisines.filter(cuisine => cuisine !== filter)
                }
                break;
            case 'level':
                if (updatedFilters.restaurant_level === null | !updatedFilters.restaurant_level !== filter) {
                    updatedFilters.restaurant_level = filter
                } else {
                    updatedFilters.restaurant_level = null
                }
                break;
            default:
                break;
        }
        setGlobalFilters(updatedFilters);
    };

    const handleHappeningSoonFilter = () => {
        setHappeningSoon(prevHappeningSoon => !prevHappeningSoon);
        setGlobalFilters(prevFilters => ({ ...prevFilters, happening_soon: !happeningSoon }));
    };

    const handlePopularFilter = () => {
        setPopular(prevPopular => !prevPopular)
        setGlobalFilters(prevFilters => ({ ...prevFilters, popular: !popular }))
    }

    const handlePriceChange = (min, max) => {
        min != null ? setMinBudget(min) : setMinBudget('')
        max != null ? setMaxBudget(max) : setMaxBudget('')
    };

    function filtersCounter(filters_dict) {
        var count = 0;

        if (filters_dict.discover) { count +=1 }

        if (filters_dict.guests) { count +=1 }

        if (filters_dict.budget) { count +=1 }

        if (filters_dict.event) { count +=1  }

        if (filters_dict.cuisines) { count += filters_dict.cuisines.length }

        if (filters_dict.restaurant_level) { count +=1 }

        if (filters_dict.happening_soon) { count +=1 }

        if (filters_dict.popular) { count +=1 }

        return count;
    }

    function navigateToGuarantee() {
        navigate('/policies/andie-guarantee')
    }

    function filterHeadcount(item) {
        var minGuests;

        if (item.packages) {
            minGuests = item.packages.flatMap(pac => pac.serves || []);
        } else {
            minGuests = item.guest_count
        }
        return minGuests;
    }


    return (
        <div className="flex flex-col justify-between">
            <div className="h-full space-y-4 pb-10 overflow-y-scroll scrollbar-hide">
                <div>
                    <div className="flex justify-between">
                        <h6 className="andie-sidebar-title">Discover</h6>
                        <div className="hidden lg:inline-flex items-center space-x-1 px-2 py-0.5 bg-slate-100 rounded-full">
                            <AdjustmentsIcon className="rotate-90 h-3.5 text-blue-600" />
                            <p className="text-paragraph leading-none font-productsans-medium text-blue-600">Filters</p>
                            <div className="h-4 w-4 rounded-full flex items-center justify-center text-xs font-productsans-bold text-white bg-gray-800">
                                {filtersCounter(globalFilters)}
                            </div>
                        </div>
                    </div>
                    <div className="mt-2.5">
                        {cgeOptions.map((discoverfilter, index) => (
                            <div key={index} className={`cursor-pointer flex space-x-2 items-center pl-2 ${globalFilters.discover === discoverfilter.name && "bg-blue-100 rounded-md"} py-2`} onClick={() => handleFilter('discover', discoverfilter.name)}>
                                <button className="flex justify-between w-full items-center">
                                    <div className={`flex space-x-2`}>
                                        <img src={discoverfilter.icon} className="h-5 object-cover" />
                                        <p className="font-productsans-medium text-paragraph text-gray-600">{discoverfilter.name}</p>
                                    </div>
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="pt-4 border-t">
                    <h6 className="bid-form-input-label text-gray-700">Budget per guest</h6>
                    <div className="flex items-center w-full space-x-2 justify-between">
                        <input className="bid-form-input pr-1 h-9 xl:h-10 w-[45%]" placeholder="Minimum" value={minBudget} onChange={(e) => setMinBudget(e.target.value)} type="number" onWheel={(e) => e.target.blur()} />
                        <MinusIcon className="h-4 text-gray-500" />
                        <input className="bid-form-input pr-1 h-9 xl:h-10 w-[45%]" placeholder="Maximum" value={maxBudget} onChange={(e) => setMaxBudget(e.target.value)} type="number" onWheel={(e) => e.target.blur()} />
                    </div>
                    <div className="mt-3.5 mb-1.5 grid gap-2 grid-cols-2">
                        {priceFilters.map((priceFilter, index) => (
                            <button type="button" onClick={() => {
                                handlePriceChange(priceFilter.min, priceFilter.max)
                                handleFilter('budget', priceFilter)
                            }} key={index} className={`h-7 bg-gray-100 text-xs lg:text-[11px] font-roboto-medium rounded-sm flex items-center justify-center last:col-span-2 ${globalFilters.budget?.title === priceFilter?.title ? 'bg-gray-300' : ''}`}>
                                {priceFilter.title}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="pt-5 border-t">
                    <div className="flex items-center mb-3 justify-between">
                        <h6 className="bid-form-input-label mb-0 text-gray-700">Headcount</h6>
                        <div className="text-paragraph font-productsans-medium text-blue-600 cursor-pointer" onClick={() => setGlobalFilters({ ...globalFilters, guests: null })}>Any</div>
                    </div>
                    <div className="space-y-1 font-roboto-regular">
                        {guestFilters.map((guestFilter, index) => (
                            <button onClick={() => handleFilter('guests', guestFilter)} key={index} className={`andie-description-text text-gray-700 lg:text-xs cursor-pointer w-full flex justify-between items-center ${globalFilters.guests?.title === guestFilter?.title && 'font-roboto-medium text-blue-600'}`}>
                                {guestFilter.title}
                                <div className={`${globalFilters.guests?.title === guestFilter?.title ? `text-blue-600` : 'text-gray-500'}`}>{
                                    (filteredData.filter(item => {
                                        const minGuests = filterHeadcount(item);
                                        if (Array.isArray(minGuests)) {
                                            return (
                                                Array.isArray(minGuests) &&
                                                minGuests.some(guest => guest >= guestFilter.min && guest <= guestFilter.max)
                                            );
                                        } else {
                                            return minGuests >= guestFilter.min && minGuests <= guestFilter.max;
                                        }
                                    })).length
                                }</div>
                            </button>
                        ))}
                    </div>
                </div>
                <div className="pt-5 border-t">
                    <h6 className="bid-form-input-label text-gray-700">Event type</h6>
                    <div className="rounded-md h-10 border-2 border-gray-100 flex items-center px-2">
                        <select id="event_type" className="bg-transparent andie-description-text text-gray-600 w-full focus:outline-none text-xs scrollbar-hide" onChange={(event) => handleFilter('event', event.target.value)}>
                            <option selected={true} value={''}>Any</option>
                            {eventOptions.map((event, index) => {
                                return <option className="">{event.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="pt-5 border-t">
                    <div className="flex justify-between mb-2">
                        <h6 className="bid-form-input-label text-gray-700">Cuisines</h6>
                        <div className="andie-text-button mr-2" onClick={() => setCuisineViewAll(!cuisineViewAll)}>
                            {cuisineViewAll ? 'Hide All' : 'View All'}
                        </div>
                    </div>
                    <div className="grid grid-cols-4 lg:grid-cols-3 gap-2">
                        {foodOptions
                            .filter(cuisine => (cuisineViewAll ? cuisine : cuisine.label.length <= 8))
                            .slice(0, cuisineViewAll ? -1 : 12)
                            .map((cuisine, index) => {
                                return (
                                    <div
                                        className={`h-8 w-full cursor-pointer flex justify-center items-center bg-blue-100 andie-description-text leading-none text-[11px] text-center font-roboto-medium rounded-sm ${globalFilters.cuisines.includes(cuisine.label) && 'bg-blue-300'}`}
                                        onClick={() => handleFilter('cuisine', cuisine.label)}
                                        key={index}
                                    >
                                        {cuisine.label}
                                    </div>
                                );
                            })}
                    </div>



                </div>
                {!window.location.href.includes('templates') &&
                    <>
                        <div className="pt-5 border-t">
                            <h6 className="bid-form-input-label text-gray-700">Restaurant level</h6>
                            <div className="rounded-md h-9 border-2 border-gray-100 flex items-center px-2">
                                <select id="event_type" className="bg-transparent andie-description-text text-gray-600 w-full focus:outline-none text-xs scrollbar-hide" onChange={(event) => handleFilter('level', event.target.value)}>
                                    {starOptions.map((event, index) => {
                                        return <option className="">{event.title}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="pt-5 border-t">
                            <div className="flex justify-between items-center" onClick={handleHappeningSoonFilter}>
                                <h6 className="bid-form-input-label text-gray-700">Happening soon</h6>
                                <input checked={happeningSoon} type="checkbox" value={happeningSoon} className="checkbox mb-2 border-2 border-gray-300 checked:border-0 h-3.5 w-3.5 rounded-sm" />
                            </div>
                            <div className="flex justify-between items-center" onClick={handlePopularFilter}>
                                <h6 className="bid-form-input-label text-gray-700">Popular</h6>
                                <input checked={popular} value={popular} type="checkbox" className="checkbox mb-2 border-2 border-gray-300 checked:border-0 h-3.5 w-3.5 rounded-sm" />
                            </div>
                        </div>
                    </>
                }
            </div>



            <div className="h-12 border-t pt-2">
                <div className="bg-slate-100 flex py-2 pl-1.5 rounded">
                <ShieldCheckIcon className="text-green-700 h-4 mr-1.5 flex-shrink-0" />
                <div>
                    <p className="andie-description-text text-xs w-[90%] leading-tight">All transactions are backed by the <b>Pluck Guarantee</b>. <span onClick={navigateToGuarantee} className="andie-description-text text-xs underline text-blue-600 font-roboto-medium cursor-pointer">Learn more</span></p>
                </div>
                </div>
            </div>
        </div>
    )
} 