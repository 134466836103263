import { XIcon } from '@heroicons/react/solid'
import React from 'react'
import Drawer from '../common/Drawer'
import { pricetoString } from "../common/global";

const TransactionItemsDrawer = ({ items, button }) => {
    return (
        <Drawer
            drawerID={'transaction_view'}
            title={'Order Items'}
            width={95}
            button={button}
        >
            <div className='h-full flex flex-col relative overflow-y-hidden w-full'>
                <p className="text-tiny sf-regular text-gray-400">Here is what's included in this order</p>
                <div className="overflow-y-scroll scrollbar-hide pt-2 pb-8 px-4 flex flex-col h-[calc(100vh-60px)] justify-center">
                    {items.map((item, index) => (
                        <div className="border-b-2 border-dashed border-blue-200 py-3.5">
                            <div className="flex justify-between">
                                <div className="flex flex-col text-xs">
                                    <p className="font-sf-medium text-gray-500">{item.name}</p>
                                    <p className="text-tiny text-gray-500">{item.menuoption}</p>
                                </div>
                                <div className="text-right">
                                    <p className="font-sf-regular text-[11px] text-green-600">${pricetoString(item.price)}</p>
                                    <p className="font-sf-medium text-[11px]  text-gray-500">x {item.quantity}</p>
                                </div>
                            </div>
                            <div className="mt-2 flex justify-end">
                                <p className="font-sf-medium text-[11px] text-green-600">${(pricetoString(item.price) * item.quantity)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Drawer>
    )
}

export default TransactionItemsDrawer