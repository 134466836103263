import React from 'react'

const EasyMobileDrawer = ({id, rightSide, children}) => {
    return (
        <div>
            <div className={`drawer ${rightSide && 'drawer-end'} z-20`}>
                <input id={`${id}`} type="checkbox" className="drawer-toggle" />
                <div className="drawer-side">
                    <label htmlFor={`${id}`} className="drawer-overlay"></label>
                    <ul className="menu p-4 w-5/6 md:w-2/4 h-full bg-white text-base-content">
                        {children}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default EasyMobileDrawer