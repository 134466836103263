import React, { useState } from 'react';
import { Template } from '../../template';
import { LoadingCollectionsExplore } from '../../common';

const PopularAndTrending = ({ filteredSelection, loading }) => {

    const [currentIndex, setCurrentIndex] = useState(0)
    return (
        <div>
            <div className="flex items-end justify-between">
                <div>
                    <h5 className="section-header">Popular & Trending</h5>
                    <p className="andie-section-descriptor mb-0">Browse our most commonly viewed and booked CGE Collections.</p>
                </div>
            </div>

            <div className="flex flex-row pb-4 mt-4 overflow-x-auto space-x-4">
                {loading ?
                    <LoadingCollectionsExplore popular={true} />
                :
                    filteredSelection.length <= 0 ?
                        <div className="flex w-full items-center col-span-2 col-start-2 h-full justify-center font-roboto-medium">No collections under this category.</div>
                    :
                        filteredSelection.map((item, index) => {
                            return <Template item={item} key={index} highlight={true} popular={true} />
                        })   
                }
            </div>
        </div>
    )
}

export default PopularAndTrending