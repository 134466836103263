import React from "react";
import { SidebarRight } from "../../components/navigation";
import { AccessingInformation, AgeConsent, ChoiceAboutInformation, CollectingInformation, DataRetention, DataSecurity, Overview, PersonalInformation, PolicyChanges, SaleOfPersonalInformation, SharingInformation, TransferringInformation, WithdrawingConsent } from "../../components/documents/policy";

export default function PrivacyPolicy() {
    const tabs = ["Overview", "Personal Information", "How we collect information", "Transfer of Information", "Choices About How We Use and Disclose Your Information", "Data and security", "Data retention", "Sale of Information", "Individuals under 18", "Accessing and correcting information", "Withdrawing Consent", "Policy changes"];

    return (
        <div className="h-screen">
            <div className="flex h-[calc(100%_-_40px)]">
                <div className="andie-side-panel flex flex-col space-y-4 items-end">
                    {tabs.map((tab, index) => (
                        <div className="text-blue-600 andie-description-text leading-tight text-right font-roboto-medium">{tab}</div>
                    ))}
                </div>
                <div className="andie-main-panel overflow-y-scroll">

                    <p className="andie-description-text text-xs">12mins Read</p>
                    <h1 className="text-xl font-productsans-bold">Privacy Policy</h1>

                    <div className="my-1 h-1 w-32 bg-gray-700" />
                    <p class="mt-8 mb-5 text-xs font-roboto-medium andie-description-text"><em>Last updated: 2024-01-19</em></p>

                    <div className="w-3/4">
                        <Overview />
                        <PersonalInformation />
                        <CollectingInformation />
                        <SharingInformation />
                        <TransferringInformation />
                        <ChoiceAboutInformation />
                        <DataSecurity />
                        <DataRetention />
                        <SaleOfPersonalInformation />
                        <AgeConsent />
                        <AccessingInformation />
                        <WithdrawingConsent />
                        <PolicyChanges />
                    </div>
                </div>
                <SidebarRight />
            </div>
        </div>
    )
}