import React from 'react';
import {CountdownTimer} from '../../common';

export default function SelectedBidFooter({ datetime, numresponses, selectedBid, page }) {

    return (
        <div className="mb-5">
            <div className="flex justify-between items-end">
                <p className="font-roboto-bold text-paragraph text-gray-600">{numresponses} {numresponses==1 ? "Response" : "Responses"}</p>
                <CountdownTimer bid={selectedBid} datetime={datetime} page={"SelectedBid"}/>
            </div>
        </div>
    )
}