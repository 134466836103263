import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { AdminNavbar, AdminSidebar, AdminOrderNowBidBar, AdminBidsItem } from '../../components/admin';
import { BiddingAPIs } from "../../apis";
import moment from "moment";
import { PopoutModal } from "../../components/common";

export default function AdminCGEOrderNow({ parentLoading, getONBids, onBids, cgeAccountList, staffAccountList, businessAccountList }) {
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    var user = JSON.parse(window.localStorage.getItem('admin_user_details'));

    useEffect(() => {
        if (user !== null) {
            getData();
        } else {
            // setBids([])
        }
    }, [refresh]);

    async function getData() {
        setLoading(true);
        getONBids();
        setLoading(false)
    }

    useEffect(() => {
        if (
            parentLoading[8] ||
            parentLoading[0] ||
            parentLoading[1] ||
            parentLoading[2]
        ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [parentLoading])


    function getDateFormatted(trans) {
        const val = trans?.bid.event_date_time
        const date = moment(new Date(val)).format("MM/DD/YYYY")
        return date
    }

    return (
        <div className="overflow-y-scroll h-full w-full grid grid-cols-3 gap-2 ">
            {loading ?                 <div className="flex flex-col space-y-5 w-full h-full items-center justify-center">
                    <span className="loading loading-spinner loading-lg"></span>
                    <p className="font-productsans-medium">Loading Order Now Bids</p>
                </div>
 :
                onBids.length > 0 ?
                    onBids.map((bid, index) => (
                        <PopoutModal
                            title={"Edit Bid and Responses"}
                            button={
                                <AdminBidsItem bid={bid} />
                            }>
                            {/* <AdminBidsBidBar bid={bid} drawerWidth={75} setDrawerWidth={(e) => setDrawerWidth(e)} staffAccountList={staffAccountList} cgeAccountList={cgeAccountList} /> */}
                            <AdminOrderNowBidBar bid={bid} cgeAccountList={cgeAccountList} staffAccountList={staffAccountList} businessAccountList={businessAccountList} />
                        </PopoutModal>
                    )) : <>None</>}
        </div>
    )
}