import React from "react";

import { useState } from "react";
import { AccountsAPI } from "../../../apis";
import { ThreeDots } from "react-loader-spinner";
import { PopoutModal } from "../../common";
import { toast } from "react-hot-toast";
import { BsPower } from "react-icons/bs";
import { LogoutIcon } from "@heroicons/react/outline";

export default function AdminNavbarLogin({ trigger }) {
    const [loginError, setLoginError] = useState(false);
    const [notAdminError, setNotAdminError] = useState(false);
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [validated, setValidated] = useState(false)

    const handleLogin = async () => {
        setLoading(true)
        console.log(email)
        console.log(password)
        const payload = {
            email: email.toLowerCase().trim(),
            password: password.trim()
        }

        var loginStatus = await AccountsAPI.LoginStaffUser(payload);

        if (loginStatus === 200) {
            console.log('yay')
            setLoginError(false);
            setNotAdminError(false);
            setValidated(true)
            toast.success("Logged In, loading additional data.")

            window.location.reload(false);
        } else if (loginStatus === "Retry") {
            setLoginError(true);
            setNotAdminError(false);
        } else if (loginStatus === "Not Admin") {
            setNotAdminError(true)
            setLoginError(false);
        } else {
            console.log(loginStatus)
        }
        setLoading(false)
    }

    return (
        <PopoutModal width={350} height={350} modalID={"admin_login"} title={"Login"} isOpen={true} trigger={trigger}
            button={trigger == true ? null :
                <li>
                    <a>
                        Login
                    </a>
                </li>} >

            <div className="w-full flex flex-col items-center">
                <div className={`search-container px-0 border-2 rounded-sm my-2 ${(loginError || notAdminError) ? 'border-red-500' : ''}`}>
                    <input
                        type="text"
                        placeholder="Enter your email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={loading}
                        className={`py-1.5 px-3 w-full font-roboto-regular text-paragraph border-none focus:outline-none focus:border-0`}
                    />
                </div>
                <div className="search-container px-0 border-2 rounded-sm my-2">
                    <input
                        type="password"
                        placeholder="Enter your password"
                        name='password'
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={loading}
                        className="py-1.5 px-3 w-full font-roboto-regular text-paragraph border-none focus:outline-none focus:border-0"
                    />
                </div>
                {loginError && (
                    <div className="bg-red-200 text-red-800 px-3 py-2 rounded mt-2">
                        <p className="text-red-600 text-xs">
                            The username or password you have entered in incorrect.
                        </p>
                    </div>
                )}
                {notAdminError && (
                    <div className="bg-red-200 text-red-800 px-3 py-2 rounded mt-2">
                        <p className="text-red-600 text-xs">
                            This page is for Pluck Staff users only. Please log into cge.alwaysandie.com.
                        </p>
                    </div>
                )}
                <div
                    onClick={() => handleLogin()}
                    className="my-10 andie-dark-button"
                >
                    {loading ? (
                        <ThreeDots
                            height="30"
                            width="30"
                            radius="9"
                            color="#fff"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    ) : (
                        "Login"
                    )}
                </div>
            </div>


        </PopoutModal>



    )
}