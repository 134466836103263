import React from "react";
import { PopoutModal } from "../../common";
import moment from "moment";
import { BiddingAPIs } from "../../../apis";
import { TrashIcon } from "@heroicons/react/solid";


export default function AdminDeleteResponse({ response }) {

    function handleDelete() {
        BiddingAPIs.deleteResponse_Deps({
            "response_id": response.id
        })
    }

    return (
        <PopoutModal
            title={"Delete Response?"}
            button={
                <div className="cursor-pointer h-8 w-16 px-3 bg-red-100 rounded flex items-center justify-center">
                    <TrashIcon className="h-5 text-red-600" />
                </div>
            }
            modalID={"delete_response_modal"}
            footerElements={
                <div onClick={() => handleDelete()}
                    className="mb-2 cursor-pointer h-9 px-3 bg-red-600 rounded flex items-center justify-center mt-2">
                    <h6 className="text-xs text-white font-productsans-medium">Yes, Delete Response and Dependencies</h6>
                </div>
            }
        >
            <h6 className="text-xs font-productsans-regular">
                Are you sure you want to delete this response?
            </h6>
            <h6 className="text-xs font-productsans-regular">
                Deleting this response will remove all transactions associated with this bid.
            </h6>

            <div className="flex align-middle items-center ml-4">
                <div className="rounded-full bg-black w-1 h-1 mr-2"></div>
                <h6 className="text-xs font-productsans-regular">
                    <span className="font-productsans-bold">Response {response.id}</span>, by {response.restaurant_name}
                </h6>
            </div>
            <div className="flex align-middle items-center ml-8">
                <div className="rounded-full bg-black w-1 h-1 mr-2"></div>
                <h6 className="text-xs font-productsans-regular">
                    <span className="font-productsans-bold">Conversation {response.id}</span>
                </h6>
            </div>
            {response.accepted && (
                <div className="flex align-middle items-center ml-8">
                    <div className="rounded-full bg-black w-1 h-1 mr-2"></div>
                    <h6 className="text-xs font-productsans-regular">
                        <span className="font-productsans-bold">Applicable Transactions</span>
                    </h6>
                </div>
            )}

        </PopoutModal>

    )
}