import React from "react";
import { CheckIcon, PlusIcon, StarIcon, XIcon } from "@heroicons/react/solid";

import { foodOptions, menuoptions, starOptions } from "../../common";

export default function EditEventRestaurantAndMenu({ restaurantType, setRestaurantType, menuOptions, setMenuOptions, restaurantRating, setRestaurantRating }) {

    function addRemoveMenu(option) {
        if (menuOptions.includes(option)) {
            const updated_options = menuOptions.filter(item => item !== option);
            setMenuOptions(updated_options);
        } else {
            setMenuOptions([...menuOptions, option]);
        }
    }

    function addRemoveRestaurant(option) {
        if (restaurantType.some(item => item === option)) {
            const updated_options = restaurantType.filter(item => item !== option);
            setRestaurantType(updated_options);
        } else {
            if (restaurantType.some(item => item === option)) {
                alert('This restaurant type has already been selected.');
            } else {
                if (restaurantType.length < 7) {
                    setRestaurantType([...restaurantType, option]);
                } else {
                    alert('You have selected the maximum number of restaurant types.');
                }
            }
        }
    }

    return (
        <>
            <div className="flex flex-col border-b border-gray-100 py-5">
                <h1 className="bid-form-input-label">Restaurant Preference</h1>
                <div className="w-full mt-3">
                    <div className="flex flex-wrap pb-2 border-b border-gray-50 mb-3">
                        {restaurantType.map((restaurant, index) => (
                            <div className="bg-blue-100 h-6 pl-3 pr-2 mb-2 mr-2.5 rounded-full flex justify-center items-center">
                                <p className="text-tiny font-sf-medium mt-0.5 text-blue-500">{restaurant}</p>
                                <div onClick={() => addRemoveRestaurant(restaurant)} className="flex items-center justify-center ml-1.5 h-3.5 w-3.5 bg-blue-300 rounded-full cursor-pointer">
                                    <XIcon className="h-2.5 text-blue-600" />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-wrap">
                        {foodOptions.map((restaurant, index) => (
                            <>
                                <div onClick={() => addRemoveRestaurant(restaurant.label)} className="cursor-pointer mb-4 mr-4 flex items-center space-x-1">
                                    <h6 className="text-paragraph text-gray-500 font-roboto-regular">{restaurant.label}</h6>
                                    <PlusIcon className="h-3.5 text-blue-500" />
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex flex-col pt-5">
                <h1 className="bid-form-input-label">Menu options</h1>
                <div className="w-full flex flex-grow mt-3">
                    {menuoptions.map((option, index) => (
                        <div className="w-1/4 pr-2 mb-2 flex space-x-1">
                            <div onClick={() => addRemoveMenu(option.title)} className={`mt-0.5 cursor-pointer border-2 h-4 w-4 mr-1 flex items-center justify-center rounded ${menuOptions.includes(option.title) ? 'bg-blue-600  border-blue-600' : 'border-gray-300'}`}>
                                {menuOptions.includes(option.title) && <CheckIcon className="h-4 rounded-sm text-white" />}
                            </div>
                            <p className="ml-1.5 bid-form-input-label mb-0 text-gray-500">{option.title}</p>
                        </div>
                    ))}
                </div>
            </div>
            <h6 className="py-5 bid-form-input-label mb-0">Restaurant level</h6>
            <div className='w-full pb-5 xl:flex xl:items-center xl:space-x-5 border-b flex flex-row space-x-5'>
                {starOptions.map((option, index) => (
                    <div className="flex items-center px-2 space-x-1.5">
                        <div onClick={() => setRestaurantRating(option.title)} className={`cursor-pointer border-2 h-4 w-4 flex items-center justify-center rounded ${option.title === restaurantRating ? 'border-blue-600' : 'border-gray-300'}`}>
                            {option.title === restaurantRating && <div className="h-2 w-2 rounded-sm bg-blue-600" />}
                        </div>

                        <div className="flex items-center ml-1">
                            <StarIcon className='h-4 text-blue-600' />
                            <p className="bid-form-input-label mb-0 text-gray-500 text-opacity-80">{option.title}</p>
                        </div>
                    </div>
                ))}
            </div>

        </>
    )
}