import React from 'react';
import { SidebarRight } from '../../components/navigation';
export default function TermsOfUse() {
    return (
        <div className="h-screen">
            <div className="flex h-[calc(100%_-_40px)]">
                <div className="andie-side-panel" /> 
                <div className="andie-main-panel overflow-y-scroll">
                    <p className="andie-description-text text-xs">18mins Read</p>
                    <h1 className="text-xl font-productsans-bold">Terms of Use</h1>
                    <div className="my-1 h-1 w-32 bg-gray-700" />
                    
                    <p class="mt-8 mb-5 text-xs font-roboto-medium andie-description-text"><em>Last updated: 2024-01-19</em></p>
                    <div class="andie-description-text xl:w-3/4">
                        <p><span>These terms and conditions of use (these “<strong>Terms and Conditions</strong>”) together with any documents and/or terms they expressly incorporate by reference, govern your access to and use of the <strong>Services</strong>, including any content and functionality, as well as any services offered on or through&nbsp;</span><a href="https:pluckbids.com/" class="text-blue-600"><u><span>https:pluckbids.com/</span></u><span>&nbsp;</span></a><span>(the “<strong>Website</strong>”) by Pluck Technologies Inc. (“<strong>we</strong>”, “<strong>us</strong>”, “<strong>our</strong>”, “<strong>Company</strong>”). &nbsp;For purposes of these Terms of Use, Services means the use of our software, applications, website or Services. &nbsp;These Terms of Use create an Agreement between You and Us that is to be read in conjunction with any other Agreement or Agreement between the Parties. &nbsp;To the extent of any conflict between this and any other Agreement between the Parties, these Terms and Conditions shall govern. &nbsp;</span></p>
                        <p><strong><span>&nbsp;</span></strong></p>
                        <p><span>It is important that you read these Terms and Conditions carefully and we strongly recommend that you do so. By using the Services, including without limitation the Website, you accept and agree to be bound by and comply with these Terms and Conditions, and our privacy policy, found at&nbsp;</span><a href="/policy/privacy" class="text-blue-600"><u><span>Privacy Policy</span></u><span>&nbsp;</span></a><span>(the "<strong>Privacy Policy</strong>"), incorporated herein by reference. If you do not agree to these Terms and Conditions, you must not use the Services.</span></p>
                        <p><span>&nbsp;</span></p>
                        <p><span>By accessing the Services, you represent and warrant that you:&nbsp;</span></p>
                        <p><span>&nbsp;</span></p>
                        <ul class="list-disc ml-10 mt-2">
                            <li><span>are at least 18 years of age;&nbsp;</span></li>
                            <li><span>have read, understood and agreed to be bound by these Terms and Conditions as they may be amended from time to time;&nbsp;</span></li>
                            <li><span>meet all the foregoing eligibility requirements; and&nbsp;</span></li>
                            <li><span>have read and understand the Privacy Policy.</span></li>
                        </ul>
                        <p><span>&nbsp;</span></p>
                        <ol class="list-decimal ml-4 list-inside">
                            <li><strong><span>Agreement between You and the Company</span></strong></li>
                            <p><strong><span><span>&nbsp;</span></span></strong></p>
                            <p><span>This Agreement, and the Terms of Use set forth herein, govern your use of our Services. By using the Services, you explicitly accept these Terms of Use. We strongly recommend that, as you read these Terms of Use, you also access and read the information contained in the other pages and websites referred to in these Terms of Use, as they may contain further terms and conditions that apply to you. Underlined words and phrases are links to these pages and websites. The term “Agreement” means the agreement formed between you and us pursuant to these Terms of Use and any other terms and conditions posted on the our Website and any other written agreement between you and us, as the same may be modified from time to time, all of which are incorporated herein by reference. &nbsp; To use the Services you need compatible hardware, software and Internet access. &nbsp; Our Service’s performance may be affected by these factors. &nbsp;</span></p>
                            <p><strong><span><span>&nbsp;</span></span></strong></p>
                            <li><strong><span>Amendments to these Terms and Conditions</span></strong></li>
                            <p><span>&nbsp;</span></p>
                            <p><span>We reserve the right in our sole discretion to revise and update these Terms and Conditions from time to time. Any and all such modifications are effective immediately upon posting and apply to all access to and continued use of the Services. You agree to periodically review these Terms and Conditions in order to be aware of any such modifications and your continued use shall be your acceptance of these Terms and Conditions. We may change, withdraw, terminate discontinue or otherwise revise information and material on the Services, including the provision of any services, at any time in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the Services is restricted to users or unavailable at any time or for any period.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Your Use of the Services, Account Set-Up, and Security</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>The security of your personal information is very important to us. We use physical, electronic, and administrative measures designed to secure your personal information from accidental loss and from unauthorized access, use, alternation, and disclosure.&nbsp;</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>The safety and security of your information also depends on you. You are responsible for obtaining your own access to the Services. You are required to ensure that all persons who access the Services through your internet connection are aware of these Terms and Conditions and that they comply with them. You are responsible for the security of Your own data, any data on or relating to the Services and the Website. &nbsp;The Company is not responsible to you or to any third party for unauthorized access to any data or the unauthorized use of any your Content, information or any data or information belonging to you by any third party unless the unauthorized use resulted from our reckless or gross negligent failure to take commercially reasonable steps to protect such data. &nbsp;You are responsible for the use of the Services by any of your employees, agents or representatives or any person to whom you provide access to the Services or any person who gains access to the Services as a result of your failure to use reasonable security precautions, even is such use was not authorized by you.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Customer Content</span></strong></li>
                            <p><span>&nbsp;</span></p>
                            <p><span>You represent and warrant that you own all rights to any information or data you provide in relation to the Website or the Services (the “Customer Content”) or otherwise has and will continue to hold all rights and permissions necessary to legally use, share, display, transfer and/or license any Customer Content in any manner contemplated by, in furtherance of or pursuant to this Agreement. &nbsp;You further represents and warrants that our use of any Customer Content in the provision of any Service(s) will not infringe or violate the right(s) of any third party, including without limitation, any copyrights, trademarks, privacy rights, contract rights or any other intellectual property or proprietary rights. &nbsp;</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Registration Information</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>Certain content or areas of the Services, and access to services offered through the Services, may require user registration. You agree and understand that you are responsible for maintaining the confidentiality of your password, which, together with your name and e-mail address (“<strong>User ID</strong>”), allows you to access the Services. Your User ID, together with any other contact information you provide us at the time of signing up on the Services form your “<strong>Registration Information</strong>.” You agree that all Registration Information provided to us will be accurate and up to date. You agree to keep your password secure. We will not be liable if we are unable to retrieve or reset a lost password. If you become aware of any unauthorized use of your password or account, you agree to notify us via e-mail at&nbsp;</span><a href="mailto:privacyandterms@hellopluck.com" class="text-blue-600"><u><span>privacyandterms@hellopluck.com</span></u><span>&nbsp;</span></a><span>as soon as possible. You also agree to ensure that you logout from your account at the end of each session. You are responsible for any password misuse or any unauthorized access. Your provision of Registration Information and any submissions you make to the Services, through any functionality such as applications, emails, profiles constitutes your consent to all actions we take with respect to such information consistent with our Privacy Policy, found at&nbsp;</span><a href="/policy/privacy" class="text-blue-600"><u><span>Privacy Policy</span></u><span>&nbsp;</span></a><span>.&nbsp;</span><span>We reserve the right at any time, and from time to time, to disable or terminate your account, any username, password, or other identifier, whether chosen by you or provided by us, in our sole discretion for any or no reason, including any violation of any provision of these Terms and Conditions.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Accounts</span></strong></li>
                            <p><span>&nbsp;</span></p>
                            <p><span>You may not open an account if you are a competitor of the Company.</span> <span>The Company expressly and without limitation revokes the right of any competitor to access the Website or the Services in any way and for any purpose, including, but not limited to, through employees, officers, directors, third party agents, affiliates, or independent contractors. If you are a competitor, you acknowledge that you are accessing the Website or the Services without legal authorization, and agree to immediately discontinue such access, and to direct all parties within your control or under your direction, including, but not limited to, employees, officers, directors, third party agents, affiliates, or independent contractors, to cease accessing the Services on your behalf, or for your benefit. &nbsp;</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>User Responsibility</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>You agree that you are solely responsible for any breach of your obligations under these Terms and Conditions and for the consequences of any such breach. We have no responsibility to you or to any third party for such breaches or the consequences of such breaches (including losses or damage that we may incur). You understand that when using the Services, you may come across material that you find objectionable, offensive or indecent and agree that you are using the Services at your own risk.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Technical Requirements</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>Use of the Services requires internet access through your computer or mobile device. You may be required to have location technology enabled to use certain content and functionality within the Services, and some features of the Services may not be accessible with such technologies disabled.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Security</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>You are prohibited from attempting to circumvent and from violating the security of the Services, including, without limitation:</span></p>
                            <p><span>&nbsp;</span></p>
                            <ul class="list-disc ml-10">
                                <li><span>accessing content and data that is not intended for you;</span></li>
                                <li><span>attempting to breach or breach the security and/or authentication measures which are not authorized;</span></li>
                                <li><span>restricting, disrupting, or disabling service to users, hosts, servers, or networks;</span></li>
                                <li><span>illicitly reproducing TCP/IP packet header;</span></li>
                                <li><span>disrupting network services and otherwise disrupting the Services owner’s ability to monitor the Services;</span></li>
                                <li><span>using any robot, spider, or other automatic device, process, or means to access the Services for any purpose, including monitoring or copying any of the material on the Services;&nbsp;</span></li>
                                <li><span>introducing any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful;&nbsp;</span></li>
                                <li><span>attacking the Services via a denial-of-service attack, distributed denial-of-service attack, flooding, mailbombing, or crashing; and&nbsp;</span></li>
                                <li><span>otherwise attempting to interfere with the proper working of the Services.</span></li>
                            </ul>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Other Business</span></strong></li>
                            <p><strong><span><span>&nbsp;</span></span></strong></p>
                            <p><span>Certain third-party Vendors which maintain a dashboard on the Services may operate stores, provide services or software, or sell product through the Services. In addition, we provide links to the Services of affiliated companies and certain other businesses. If you purchase any of the products or services offered by these businesses or individuals, you are purchasing directly from those third parties, not from the Company. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any of these businesses or individuals or the content of their Services. The Company does not assume any responsibility or liability for the actions, product, and content of all these and any other third parties. You should carefully review their privacy statements and conditions of use.</span></p>
                            <p><strong><span><span>&nbsp;</span></span></strong></p>
                            <li><strong><span>Grant of License</span></strong></li>
                            <p><span>&nbsp;</span></p>
                            <p><span>No information you submit, post, publish, transmit, display to the Services (collectively “<strong>User Submissions</strong>”), will be subject to any confidentiality by the Company, except in accordance with our Privacy Policy. By providing any User Submission to the Services, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to a world-wide, royalty free, perpetual, irrevocable, non-exclusive license to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose and according to your account settings and/or incorporate such material into any form, medium or technology throughout the world without compensation to you. You further waive any moral rights or other rights of authorship as a condition of submitting User Submissions.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>By submitting the User Submissions, you declare and warrant that you own or have the necessary rights to submit the User Submissions and have the right to grant the licence hereof to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns to the User Submissions and comply with these&nbsp;Terms&nbsp;and Conditions. You represent and warrant that all User Submissions comply with applicable laws and regulations.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>You understand and agree that you, not the Company nor the Company's parent, subsidiaries, affiliates nor their respective directors, officers, employees, agents, service providers, contractors, licensors, suppliers or successors, are fully responsible for any User Submissions you submit or contribute, and you are fully responsible and legally liable, including to any third party, for such content and its accuracy. We are not responsible or legally liable to any third party for the content or accuracy of any User Submissions submitted by you or any other user of the Services.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Conditions of Use</span></strong></li>
                            <p><span>&nbsp;</span></p>
                            <p><span>You agree to use the Services only for lawful purposes and in accordance with these Terms and Conditions.</span> <span>You represent, warrant and agree that you have and shall continue to comply with any and all laws, rules or regulations applicable to your use or receipt of the Services.</span></p>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <li><strong><span>Unauthorized Access</span></strong></li>
                            <p><span>&nbsp;</span></p>
                            <p><span>You agree to only access (or try to access) and use the Services through interfaces provided by us. You shall not access (or try to access) and use the Services through any automated means, including, but not limited to, scrapers, scripts, robots, or web crawlers. You agree not to use or attempt to use the account of another user of the Services. You agree not to impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your personal information, or your affiliations with any person or entity.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Prohibited Uses</span></strong></li>
                            <p><span>&nbsp;</span></p>
                            <p><span>You represent and agree that your use of the Services, shall not:</span></p>
                            <p><span>&nbsp;</span></p>
                            <ul class="list-disc ml-10">
                                <li><span>in any manner breach or violate any applicable federal, provincial, local, or international law or&nbsp;regulation, including, without limitation, any laws regarding the export of data or software, patent, trademark, trade secret, or other intellectual property, legal rights (including the rights of publicity and privacy of others) or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms and Conditions and our Privacy Policy found at&nbsp;</span><a href="/policy/privacy" class="text-blue-600"><u><span>Privacy Policy</span></u><span>&nbsp;</span></a><span>;</span></li>
                                <li><span>in any manner, violate the terms of use of any third-party Services that is linked to the Services, including but not limited to, any third-party social media website;</span></li>
                                <li><span>in any way be considered harassment to another person or entity;</span></li>
                                <li><span>in any way be unlawful or fraudulent, or have any unlawful or fraudulent purpose or effect;</span></li>
                                <li><span>include or contain any material that is exploitive, obscene, harmful, threatening, abusive, defamatory, misleading, fraudulent, pornographic, sexually explicit, inflammatory, discriminatory based on race, sex, religion, nationality, disability, sexual orientation, or age or otherwise explicit in nature or written in bad faith, or such other legally prohibited ground or be otherwise objectionable, such determination to be made in the Company’s sole discretion;</span></li>
                                <li><span>in any way damage, disable, overburden, and/or impair the Services, or any network connected to the server of the Services, and/or interfere with any other party’s use or enjoyment of the Services;</span></li>
                                <li><span>use the Services to upload, post, link to, email, transmit or otherwise make available any material that contains viruses or any other computer code, files or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or any telecommunication equipment;</span></li>
                                <li><span>post or distribute any computer program that damages, detrimentally interferes with, surreptitiously intercepts, or expropriates any system, data, or personal information</span></li>
                                <li><span>harm or attempt to harm minors in any way;</span></li>
                                <li><span>reproduce, duplicate, copy, sell, resell or exploit any portion of the Services or any goods or services offered through the Services;&nbsp;</span></li>
                                <li><span>abuse, either verbally, physically, written or other abuse, invoke stalking, attempt to exploit or harm (including threats of abuse or retribution) any individual, including without limitation, any customers, employees, members, or officers of the Company;</span></li>
                                <li><span>impersonate or attempt to impersonate the Company, a Company employee, another use, or any other person or entity (including without limitation, by using email addresses, or screen names associated with any of the foregoing);</span></li>
                                <li><span>transmit, or precure the sending of, any advertisements or promotions, sales, or encourage any other commercial activities, including without limitation, any “spam” “junk mail”, “chain letter”, contests, sweepstakes and other sales promotions, barter, or advertisements or any other similar solicitation;</span></li>
                                <li><span>encourage any other conduct that restricts or inhibits anyone’s use or enjoyment of the Services, or which, as determined by us, may harm the Company or users of the Services, or expose them to liability;</span></li>
                                <li><span>cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, or alarm another person;&nbsp;</span></li>
                                <li><span>promote any illegal activity, or advocate, promote or assist any unlawful act; or</span></li>
                                <li><span>otherwise disrupt the functioning of the Services, in any manner.</span></li>
                            </ul>
                            <p class="mt-4"><span>You understand and agree that the Company may, in its sole discretion, review and delete any content in whole or in part, for any reason whatsoever, including without limitation, violation of these Terms and Conditions or a violation of any the Prohibited Uses above.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>You also understand that in order for us to operate the Services, User Submissions may be transmitted by you or us over various public networks and in various media in compliance with our security protocols and we may make changes to User Submissions to meet the technological requirements of such networks and media. We will use available tools to protect User Submissions, however, you are responsible for ensuring that User Submissions are protected and your rights in User Submissions are enforced; we have no responsibility to protect or enforce your rights on your behalf with respect to User Submissions.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Intellectual Property Rights and Ownership</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>You understand and agree that the Services, associate data files, contents, features, and functionality, including, but not limited to, all information, software, code, text, displays, graphics, photographs, video, audio, design, presentation, selection, arrangement, advertisements, sponsored content (collectively, “</span><strong><span>Product Content</span></strong><span>”), are owned by the Company, its licensors, or other providers of such material and are protected in all forms by intellectual property laws including without limitation, copyright, trademark, patent, trade secret, and any other proprietary rights.&nbsp;</span></p>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>Pluck Technologies Inc., current and pending trademarks and patents of Pluck Technologies Inc., the logo of Pluck Technologies Inc., and all related names, logos, product and service names, designs, images, and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. Other names, logos, product and service names, designs, images, and slogans mentioned, or which appear on this Services are the trademarks of their respective owners. Use of any such property, except as expressly authorized, shall constitute an infringement or violation of the rights of the property owner and may be a violation of deferral or other laws and could subject the infringer to legal action.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>You may only use the Services for your personal and non-commercial use. You shall not directly or indirectly reproduce, compile for internal database, distribute, modify, decipher, decompile, disassemble, reconstruct, translate, reverse engineer, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on the Services, in any form of medium whatsoever, or discover any of the intellectual property or ideas, algorithms, file formats, programming, or interoperability interfaces underlying the Services, or rent, lease, loan, sell, distribute or create any derivative products or services (or parts of services products or services) based on the Product Content, except: (a) your computer or browser may temporary store or cache copies of materials being accessed and viewed, and (b) one singe user copy may be downloaded with the proprietary notices intact, for your own personal, non-commercial use, conditional on an agreement to be bound by our end user license agreement for such downloads.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>No Reliance</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>The content on our Services is provided for general information purposes only. It is not intended to amount to advice on which you should rely. You must obtain more specific or professional advice before taking, or refraining from, any action or inaction on the basis of the content on the Services.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>Although we make reasonable efforts to update the information on our Services, we make no representations, warranties, or guarantees, whether express or implied, that the content on our Services is accurate, complete, or up to date. Your&nbsp;use&nbsp;of the Services is at your own risk and neither the Company nor its parent, subsidiaries, affiliates, and their respective directors, officers, employees, agents, service providers, contractors, licensors, licensees, suppliers, or successors have any responsibility or liability whatsoever for your&nbsp;use&nbsp;of this Services. Without limiting the generality of the foregoing:</span></p>
                            <p><span>&nbsp;</span></p>
                            <ul class="list-disc ml-10">
                                <li><span>the Services, including without limitation, any services offered therein, is provided “as-is” without warranties of any kind, either expressed or implied;</span></li>
                                <li><span>the Services may be temporarily unavailable from time to time for maintenance or other reasons and we assume no responsibility for any error, inaccuracy, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, any communications between you and the Services;</span></li>
                                <li><span>we accept no responsibility for the accuracy of any User Submissions provided by or created using the Services. The provision or storage of User Submissions through the Services does not constitute our endorsement or warranty as to the compliance of such User Submissions with applicable privacy legislation, nor to the accuracy, timeliness, materiality, completeness, or reliability of such User Submissions. You are responsible for ensuring that the information you have entered into our system is accurate, reliable and complete;</span></li>
                                <li><span>we do not accept any liability for monitoring the Services or for unauthorized or unlawful content on the Services or use of the Services by users;</span></li>
                                <li><span>any material downloaded or otherwise obtained through the use of our services and Services is done at your own discretion and risk, and you will be solely responsible for any damage to your computer system or other device or loss of data that results from the download of any such material; and</span></li>
                                <li><span>if you are an individual providing User Submissions that is to be directed to your organization’s account, you agree and acknowledge that we accept no responsibility and are not liable for any damages that may arise by the organization’s use of that User Submissions. You further agree and acknowledge that we are not liable for any damages that may arise if the User Submissions is misdirected to the wrong organization due to any reason, including error on your part or a flaw in the Services.</span></li>
                            </ul>
                            <p><span>&nbsp;</span></p>
                            <p><span>This Services may include content provided by third parties, including from other users and third-party licensors. We make no representations about any other Services that may be accessed from the Services. We make no representation with respect to content, goods and/or services accessed from the Services own respect to vendor-specific dashboards of users and third-party licensors. If you choose to access such websites or vendor-specific dashboards, you do so at your own risk. All statements and/or opinions expressed in any such third-party content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. Such materials do not necessarily reflect the opinion of the Company. Neither the Company nor its parent, subsidiaries, affiliates, and their respective directors, officers, employees, agents, service providers, contractors, licensors, licensees, suppliers, or successors have any responsibility or liability whatsoever to you, or any third party, for the content or accuracy of any third-party materials, or for or in respect to any goods or services purchased by third-party licensors. You are solely responsible for use of any such websites or vendor-specific dashboards or resources and compliance with their policies. Should you elect to enter into a binding contract with any such website, or any third-party you agree to hold us harmless and hereby release us from any liability whatsoever, whether arising out of contract, tort or otherwise, for any liability, claim, injury, loss or damage suffered as a result of your actions or the actions of any user associated with your account, offering to accept or having accepted any products or services that are available from those websites.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Site Monitoring, Enforcement, Suspension and Termination; Feedback</span></strong></li>
                            <p><span>&nbsp;</span></p>
                            <p><span>The Company has the right, without provision of notice to:</span></p>
                            <p><span>&nbsp;</span></p>
                            <ul class="list-disc ml-10">
                                <li><span>Remove or refuse to post on the Services any User Submissions for any or no reason in our sole discretion.</span></li>
                                <li><span>At all times, take such actions with respect to any User Submission deemed necessary or appropriate in our sole discretion, including, without limitation, for violating these Terms&nbsp;and Conditions.</span></li>
                                <li><span>Take appropriate legal action, including, without limitation, referral to law enforcement or regulatory authority, or notifying the harmed party of any illegal or unauthorized&nbsp;use&nbsp;of the Services. Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Services.</span></li>
                                <li><span>Terminate or suspend your access to all or part of the Services for any or no reason, including, without limitation, any violation of these&nbsp;Terms&nbsp;and Conditions.</span></li>
                            </ul>
                            <p class="mt-4"><span>YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS PARENT, SUBSIDIARIES, AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY AND ANY OF THE FOREGOING PARTIES RELATING TO ANY, INVESTIGATIONS BY EITHER THE COMPANY OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>We have no obligation, nor any responsibility to any party to monitor the Services or its&nbsp;use, and do not and cannot undertake to review material that you or other users submit to the Services. We cannot ensure prompt removal of objectionable material after it has been posted and we have no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party, subject to applicable laws.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>Upon termination of our relationship, we will immediately revoke your license to use the Services and block all access to your account and may delete all data and information associated with your account 30 days after such termination.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Privacy</span></strong></li>
                            <p><span>&nbsp;</span></p>
                            <p><span>By submitting your personal information and using our Services, you consent to the collection, use, reproduction, hosting, transmission, and disclosure of any such user content submissions in compliance with our Privacy Policy, found at&nbsp;</span><a href="/policy/privacy" class="text-blue-600 visited:text-purple-600"><u><span>Privacy Policy</span></u><span>&nbsp;</span></a><span>, as we deem necessary for&nbsp;use&nbsp;of the Services and provision of services.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Data Retention</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>The Services may store your data as long as your account is current and active and for 30 days after our relationship with you has been terminated.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>On a regular basis we create a backup of all data in our system, which is retained for 30 days, after which it will be removed permanently from all our systems. This backup is for use by the Company only in the case of disaster recovery or to maintain business operations in the case of an emergency. The Company will not restore data unless it determines, in its sole discretion, that a data recovery is necessary.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Disclaimer of Warranties</span></strong></li>
                            <p><strong><span><span>&nbsp;</span></span></strong></p>
                            <p><span>YOU UNDERSTAND AND AGREE THAT YOUR&nbsp;USE&nbsp;OF THE SERVICES, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE SERVICES IS AT YOUR OWN RISK. THE SERVICES, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR AVAILABILITY OF THE SERVICES OR ITS CONTENTS. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS REPRESENT OR WARRANT THAT THE SERVICES, ITS CONTENT, OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE SERVICES WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR&nbsp;USE&nbsp;OF THE SERVICES AND YOUR COMPUTER, INTERNET, AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR&nbsp;USE&nbsp;OF THE SERVICES OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE SERVICES OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SERVICES LINKED TO IT.</span></p>
                            <p><strong><span><span>&nbsp;</span></span></strong></p>
                            <li><strong><span>Limitation of Liability</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE WILL THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE SERVICES, ANY LINKED SERVICES OR SUCH OTHER THIRD-PARTY SERVICES, NOR ANY SERVICES CONTENT, MATERIALS, POSTING, OR INFORMATION THEREON EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW. &nbsp;NOTWITHSTANDING ANY PROVISION OF THIS AGREEMENT TO THE CONTRARY, THE COMPANY’S MAXIMUM AGGREGATE LIABILITY HEREUNDER FOR ANY CLAIMS WHATSOEVER SHALL BE LIMITED TO THE AMOUNT PAID UNDER THIS AGREEMENT BY YOU TO THE COMPANY DURING THE THREE (3) MONTH PERIOD PRECEDING THE EVENT, OCCURRENCE OR CAUSE GIVING RISE TO ANY SUCH CLAIM, IF ANY.</span></p>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>Without limiting the generality of the foregoing, you hereby release, remise and forever discharge us and our directors, employees, officers, and our affiliates, partners, service providers, vendors, and contractors and each of their respective agents, directors, officers, employees, and all other related persons or entities from any and all manner of rights, losses, costs, claims, complaints, demands, debts, damages, causes of action, proceedings, liabilities, obligations, legal fees, costs and disbursements of any nature whatsoever, and for any special, indirect or consequential, incidental or exemplary damages (collectively, a “<strong>Claim</strong>”), whether in contract or tort, whether known or unknown, which now or hereafter arise from, to the maximum extent allowed by law, that relate to, or are connected with:</span></p>
                            <p><span>&nbsp;</span></p>
                            <ul class="list-disc ml-10">
                                <li><span>your provision of any personal information provided to us subject to our legal requirements relating to the protection of personal information;</span></li>
                                <li><span>communications received to you through your access to the Services;</span></li>
                                <li><span>the use of the Services and any related applications including third party services;</span></li>
                                <li><span>the use of any software related to the Services;</span></li>
                                <li><span>viruses, spyware, service provider failures or internet access interruptions;</span></li>
                                <li><span>loss of use, loss of data, inaccuracy of data, payment failure, payment defect, inaccurate calculations, downtime, identity theft, fraud or unauthorized access; or</span></li>
                                <li><span>any content relating to the use of the Services;</span></li>
                            </ul>
                            <p class="mt-4"><span>even, in each or any case, if you have been advised of the possibility of such Claim, or such Claim was reasonably foreseeable and notwithstanding the sufficiency or insufficiency of any remedy provided for herein or in any license.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Indemnification</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>To the extent permitted by applicable laws, you agree to defend, indemnify and hold harmless the Company, its parent, subsidiaries, affiliates and their respective officers, directors, shareholders, employees, agents and representatives, service providers, contractors, licensors, and assigns from and against any and all damages, judgments, awards, losses, liability, costs and expenses (including without limitation any reasonable legal fees), in whole or in part arising out of or attributable to: (a) generally, your breach of these Terms and Conditions; your access to and/or use of the Services, including but not limited to, User Submissions, third-party sites, any use of the Services’ content, any use of services provided through the Services; and any loss of, or damage to, any property, or injury to, or death of, any person (including you) caused by your access to and/or use of the Services; and (b) specifically, your breach of any representation or warranty contained herein or your breach of the intellectual property rights of any third party to these Terms and Conditions.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>You agree that you are solely responsible for all activities that occur under your account, whether you are aware of them or not. You agree to hold us harmless and release us from any loss or liability whatsoever that you may incur as a result of someone other than you using your password or account, either with or without your knowledge. You agree to indemnify us for any damages, third party claims or liabilities whatsoever that we may incur as a result of activities that occur on or through your account, whether or not you were directly or personally responsible.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Governing Law</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>The Services, these&nbsp;Terms&nbsp;and Conditions, the Privacy Policy and any dispute arising out of or relating to these agreements specifically or the Website or Services generally will be governed by and construed in accordance with the laws of the province of Ontario and the federal laws of Canada applicable therein, without giving effect to any choice or conflict of law provision, principle, or rule (whether of the laws of the province of Ontario or any other jurisdiction) and notwithstanding your domicile, residence, or physical location.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>Any action or proceeding arising out of or relating to the Services, the Website or the Terms&nbsp;and Conditions will be instituted in the courts of the province of Ontario and/or the Federal Court of Canada, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding. You waive any and all objections to the exercise of jurisdiction over you by such courts and to the venue of such courts.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>You agree not to commence or prosecute any action in connection therewith other than in the province of Ontario, and you hereby consent to, and waive all defenses of lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in the provincial courts of the province of Ontario.</span></p>
                            <p><span>&nbsp;</span></p>
                            <p><span>Each party shall be responsible for its own legal fees and other expenses incurred in connection with the performance of any of its obligations hereunder except you agree to pay reasonable attorneys' fees and court costs incurred by us to collect any unpaid amounts owed by you.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Force Majeure</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>You agree that we are not liable for a delay or failure in performance of the Services or the provisions of these Terms and Conditions caused by reason of any occurrence of unforeseen events beyond our reasonable control, including but not limited to, acts of God, natural disasters, power failures, server failures, third party service provider failures or service interruptions, embargo, labour disputes, lockouts and strikes, riots, war, floods, insurrections, legislative changes, pandemics and governmental actions.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Severability</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>If any portion of these Terms and Conditions is deemed unlawful, void or unenforceable by any arbitrator or court of competent jurisdiction, these Terms and Conditions as a whole shall not be deemed unlawful, void or unenforceable, but only that portion of these Terms and Conditions that is unlawful, void or unenforceable shall be stricken from these Terms and Conditions.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Waiver</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>No failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from these&nbsp;Terms&nbsp;and Conditions operates, or may be construed, as a waiver thereof. No single or partial exercise of any right, remedy, power, or privilege hereunder precludes any other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Entire Agreement</span></strong></li>
                            <p><strong><span>&nbsp;</span></strong></p>
                            <p><span>These Terms and Conditions and our Privacy Policy constitute the entire agreement between us and you with respect to the subject matter hereof and all prior oral or written agreements, representations or statements with respect to such subject matter are superseded hereby.</span></p>
                            <p><span>&nbsp;</span></p>
                            <li><strong><span>Contact</span></strong></li>
                        </ol>
                        <p><strong><span>&nbsp;</span></strong></p>
                        <p><span>By providing us with your e-mail address, you agree to receive all required notices electronically, to that e-mail address or by mobile notifications via the Services. It is your responsibility to update or change that address, as appropriate.</span></p>
                        <p><span>&nbsp;</span></p>
                        <p><span>You are not required to provide the Company with any comments, suggestions, recommendations, requests or any other feedback (“<strong>Feedback</strong>”). In the event that you do provide the Company with Feedback, the Company may use such feedback to improve the Services or for any other purpose. Furthermore, the Company shall own such Feedback and the Company and its affiliates, licensees, clients, partners, third-party providers and other authorized entitled may use, license, distribute, reproduce and commercialize the Feedback, and you hereby assign, irrevocably, exclusively and on a royalty-free basis, all such Feedback to the Company.</span></p>
                        <p><span>&nbsp;</span></p>
                        <p><span>Should you become aware of misuse of the Services, including libelous or defamatory conduct, you must report it to the Company at&nbsp;</span><a href="mailto:privacyandterms@hellopluck.com" class="text-blue-600 visited:text-purple-600"><u><span>privacyandterms@hellopluck.com</span></u><span>.</span></a></p>
                        <p><span>&nbsp;</span></p>
                        <p><span>If you have any questions or comments regarding these Terms and Conditions please contact our head office by email at&nbsp;</span><a href="mailto:privacyandterms@hellopluck.com" class="text-blue-600 visited:text-purple-600"><u><span>privacyandterms@hellopluck.com</span></u><span>.</span></a></p>
                        <p><span>&nbsp;</span></p>
                        <p><span>&nbsp;</span></p>
                        <p><span>&nbsp;</span></p>
                        <p><span>&nbsp;</span></p>
                        <p><strong><span>&nbsp;</span></strong></p>
                        <p><span>&nbsp;</span></p>
                    </div>
                </div>
                <SidebarRight />
            </div>
        </div>
    );
}