import React from 'react'

const TemplateExperience = ({renderRating, item}) => {
    return (
        <div>
            <p className="mt-7 bid-label text-gray-500">Experience</p>
            <div className="flex space-x-1.5 pt-2">
                <div className="flex space-x-1.5">
                    {renderRating(item.experience)}
                </div>
            </div>

        </div>
    )
}

export default TemplateExperience