import React, { useState, useEffect } from "react";
import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { toast } from "react-hot-toast";
import { AdminEditCGEAccount, AdminNavbar, AdminSidebar } from '../../components/admin';
import { AccountsAPI } from "../../apis";
import { PopoutModal } from "../../components/common";

export default function AdminCGEUsers({ parentLoading, accounts, getCGEAccounts }) {
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    var user = JSON.parse(window.localStorage.getItem('admin_user_details'));

    useEffect(() => {
        if (
            parentLoading[0] ||
            parentLoading[2]
        ) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [parentLoading])

    useEffect(() => {
        getData();
        if (user !== null) {
            getData();
        }
    }, [refresh]);

    async function getData() {

        setLoading(true);
        await getCGEAccounts();
        setLoading(false)
    }

    async function deleteItem(item) {
        await AccountsAPI.deleteUserAccount({
            "email": item.email
        })
    }



    return (
        <div className="h-full overflow-scroll scrollbar-hide">
            {loading ? 
                            <div className="flex flex-col space-y-5 w-full h-full items-center justify-center">
                            <span className="loading loading-spinner loading-lg"></span>
                            <p className="font-productsans-medium">Loading Users</p>
                        </div>

:
                <table className="table table-pin-rows table-pin-cols w-full">
                    {/* head */}
                    <thead>
                        <tr>
                            {/* <th>ID</th> */}
                            <th>Company</th>
                            <th>Client</th>
                            <th>Industry</th>
                            <th>Account Manager</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {accounts.length > 0 ?
                            accounts.map((account, index) => (
                                <tr className="font-roboto-regular text-paragraph h-10 ">
                                    <th className="w-[20%]">
                                        {account.organization}

                                    </th>
                                    <td className="w-[35%]">
                                        <div className="flex bid-label text-gray-600 mb-0">{account.first_name} {account.last_name}</div>
                                        <div>{account.email}</div>
                                        {account.phone}
                                    </td>
                                    <td className="w-[16.67%]"><div className="py-0.5 font-productsans-medium text-xs uppercase px-1.5 w-28 border-2 flex justify-center items-center">
                                        {account.industry}
                                    </div></td>
                                    <td className="w-[16.67%]">{account.cge_representative}</td>
                                    <td className="w-[16.67%]">
                                        <div className="flex w-full justify-center">
                                            <PopoutModal
                                                modalID={'edit_cge_user_' + account.email}
                                                // width={250}
                                                title={"Edit User"}
                                                small={true}
                                                button={
                                                    <button className="w-9 hover:bg-gray-200 p-1 rounded-md text-blue-600"><PencilAltIcon /></button>
                                                }
                                            >
                                                <AdminEditCGEAccount account={account} />

                                            </PopoutModal>

                                            <PopoutModal
                                                modalID={'delete_cge_user_' + account.email}
                                                width={25}
                                                title={"Delete User?"}
                                                small={true}
                                                button={
                                                    <button className="w-9 hover:bg-gray-200 p-1 rounded-md text-red-600"><TrashIcon /></button>
                                                }
                                            >
                                                <div className="flex flex-col font-roboto-regular text-md space-y-10">
                                                    <div className='space-y-2'>
                                                        <p> Are you sure you want to delete: </p>
                                                        <p className='font-roboto-bold'> {account.email} ({account.first_name} {account.last_name}) </p>
                                                        <p> user account? This is a permanent action and cannot be undone.</p>
                                                    </div>
                                                    <div className="flex flex-row space-x-2">
                                                        <button className="andie-dark-button">No</button>
                                                        <button className="andie-dark-button bg-danger hover:bg-danger-700" onClick={() => deleteItem(account)}>Yes</button>
                                                    </div>
                                                </div>
                                            </PopoutModal>
                                        </div>
                                    </td>
                                </tr>
                            ))
                            :
                            <></>
                        }
                    </tbody>
                </table>
            }
        </div>
    )
}