import React from 'react';
import { ExternalAPIs } from '../../../apis';

export default function ResponseFormAdditionalNotes({ additionalNotes, setAdditionalNotes, responseImages, setResponseImages }) {

    const handleFileInput = async (e) => {
        // handle validations
        const file = e.target.files[0];
        const tempURL = await ExternalAPIs.uploadImage(file)
        const tempArr = responseImages.concat({ url: tempURL })
        setResponseImages(tempArr)
    };

    return (
        <>
            <div>
                <h6 className="bid-form-input-label mb-0">Additional Notes</h6>
                <textarea
                    className="w-full h-32 bid-form-input"
                    id="cus_notes"
                    onChange={(e) => setAdditionalNotes(e.target.value)}
                    type="text"
                    placeholder="Please add any additional notes to your bid response."
                    aria-label="Name"
                    defaultValue={additionalNotes}
                />
            </div>

            <div className="border-b space-y-3 pb-5">
                <div>
                    <h6 className="bid-form-input-label mb-0">Add images (optional)</h6>
                    <p className="andie-section-descriptor">Select images of {"your dining area and"} the menu items you are submitting.</p>
                </div>
                <div class="grid grid-cols-3 gap-3">
                    {responseImages.map((img) => (
                        <img
                            alt="gallery"
                            class="block h-28 w-full rounded-lg object-cover object-center"
                            src={img.url} />
                    ))}
                </div>

                <div class="flex mt-3 items-center justify-center w-full">
                    <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-32 border-2 rounded-sm border-dashed cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload </span> {/*or drag and drop</p>*/} </p>
                            <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                        </div>
                        <input id="dropzone-file" type="file" onChange={handleFileInput} class="hidden" />
                    </label>
                </div>
            </div>
        </>
    );
}

