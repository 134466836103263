import { useEffect, useState } from 'react'
import { getTransactionAmounts, pricetoString } from "../common/global";
import toast from 'react-hot-toast';
import { AccountsAPI, StripeAPI } from '../../apis';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { BillingDetailsForm, BillingDetails, PaymentCardDetails } from '.';
import transactioncomplete from "../../assets/images/transaction-complete.svg";
import { InformationCircleIcon } from '@heroicons/react/solid';

export default function BillingForm({ response, guestCount, tax, serviceFee, subtotal, total, handleSubmit, navigateToActivity, fromTemplate, hideBack, setHideBack, setTipInput, tipInput, items, deliveryFee, cgeType }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState("CA")
    const [address, setAddress] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [province, setProvince] = useState("")
    const [saveBilling, setSaveBilling] = useState(false)
    const [savePm, setSavePm] = useState(false)
    const [loading, setLoading] = useState(true)
    const [pms, setPms] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [creditToggle, setCreditToggle] = useState(false);
    const [newCardToggle, setNewCardToggle] = useState(true);

    const [paymentSuccess, setPaymentSuccess] = useState(false);

    const [numPackages, setNumPackages] = useState(0)
    const elements = useElements();
    const stripe = useStripe();

    console.log(user)

    useEffect(() => {
        if (Object.keys(user.billing_details).length > 0) {
            setFirstname(user.billing_details.name.split(" ")[0])
            setLastname(user.billing_details.name.split(" ")[1])
            setEmail(user.billing_details.email)
            setAddress(user.billing_details.address.line1)
            setCity(user.billing_details.address.city)
            setPostalCode(user.billing_details.address.postal_code)
            setProvince(user.billing_details.address.province)
            setPhone(user.billing_details.phone)
            setCountry(user.billing_details.address.country)
            getPms();
        }
        if (response.bid){

        } else {
            let tempPackageCount = 0
            const mains = items.filter(i => i.menuOption === 'main')
            for (const main of mains){
                tempPackageCount += main.quantity
            }
            setNumPackages(tempPackageCount)
            
        }

        setLoading(false)
    }, [])

    async function getPms() {
        const res = await StripeAPI.getPms();
        if (res.data.data.length > 0) {
            setNewCardToggle(false)
        }
        setPms(res.data.data)
    }

    async function submitPaymentForm(e) {
        // e.preventDefault();
        let billingDetails = {
            address: {
                city: city,
                country: country,
                line1: address,
                postal_code: postalCode,
                state: province
            },
            email: email,
            name: firstname + lastname,
            phone: phone,
        }
        if (saveBilling == true) {
            let billingDetails = {
                address: {
                    city: city,
                    country: country,
                    line1: address,
                    postal_code: postalCode,
                    province: province
                },
                email: email,
                name: firstname + " " + lastname,
                phone: phone,
            }
            const res = await AccountsAPI.editBilling(billingDetails);
            user.billing_details = billingDetails
            localStorage.setItem('user_details', JSON.stringify(user))
        }
        let details = {};
        let pm = {};
        try {
            if (selectedIndex == -1) {
                let pmCreate = await stripe.createPaymentMethod({ elements, params: { billing_details: billingDetails } })
                pm = pmCreate.paymentMethod
            } else {
                pm = pms[selectedIndex]
            }
            if (savePm == true) {
                try {
                    console.log('saving')
                    const res = await StripeAPI.attachPm(pm.id);
                } catch (e) {
                    console.log(e)
                    toast.error("Issue saving payment information!")
                    return
                }
            } else {
                console.log('not saving')
            }
            if (user != null) {
                if (Object.keys(pm)[0] == "error") {
                    toast.error("Please enter your payment information!")
                    return
                } else {
                    setNewCardToggle(false)
                }
            }
        } catch (e) {
            console.log(e)
        }

        details = {
            billingDetails: billingDetails,
            paymentMethod: pm
        }

        try {
            handleSubmit(details);
            if (fromTemplate) { setHideBack(!hideBack) }
            setPaymentSuccess(!paymentSuccess);
        } catch (e) {
            alert('error')
        }
    }

    function handleTipInput(tipVal) {
        if (parseFloat(tipVal) > 100) {
            setTipInput(1)
        } else if (parseFloat(tipVal) > 0) {
            setTipInput(parseFloat(tipVal) / 100)
        }
        else {
            setTipInput(0)
        }
    }

    return (        
        <form>
            {paymentSuccess ? 
                <div className="h-full">
                    <div className="flex flex-col items-center mx-auto max-w-md">
                        <img src={transactioncomplete} className="h-72" />
                        <h5 className="mt-4 text-green-700 font-productsans-medium text-lg">Transaction initiated.</h5>
                        <p className="andie-description-text leading-tight text-center text-gray-500">Your transaction is now awaiting confirmation from the restaurant or caterer. Confirmation may take up to 24 hours. You will be notified once the transaction has been confirmed.</p>

                        <p className="mt-4 andie-description-text leading-tight text-center text-gray-500">To monitor this transaction, visit your <span onClick={() => navigateToActivity()} className="underline cursor-pointer font-productsans-medium text-blue-600">Activity</span> page.</p>
                        <div className="my-4 flex space-x-3 border-dashed border-2 border-gray-100 rounded-md p-3">
                            <InformationCircleIcon className="h-4 flex-shrink-0 text-blue-600" />
                            <p className="andie-description-text leading-tight text-xs font-roboto-medium text-gray-400">Notifications will be sent to <span className="text-gray-700">{user.email}</span> and <span className="text-gray-700">{user.phone}</span>. To opt-out of notifications, edit your settings in the Account page.</p>
                        </div>
                        <div className="andie-dark-button mt-4 mb-6 w-40" onClick={() => navigateToActivity()}>Track transaction</div>
                    </div>
                </div>
                :
                <div className="md:flex h-full pb-5 space-x-6">
                    <div className="w-1/2">
                        {/* Billing Details */}
                        <div>
                            {user == null ?
                                <div className="">
                                    <div className="flex items-center justify-between border-b pb-2 mb-3">
                                        <h2 className="text-sm font-productsans-medium text-gray-600">Billing Information</h2>
                                    </div>
                                    <BillingDetailsForm setFirstname={(e) => setFirstname(e)} firstname={firstname} setLastname={(e) => setLastname(e)} lastname={lastname} setEmail={(e) => setEmail(e)} email={email} setAddress={(e) => setAddress(e)} address={address} setCity={(e) => setCity(e)} city={city} setPostalCode={(e) => setPostalCode(e)} postalCode={postalCode} setProvince={(e) => setProvince(e)} province={province} setPhone={(e) => setPhone(e)} phone={phone} setCountry={(e) => setCountry(e)} country={country} />
                                </div>
                                :
                                <BillingDetails setFirstname={(e) => setFirstname(e)} firstname={firstname} setLastname={(e) => setLastname(e)} lastname={lastname} setEmail={(e) => setEmail(e)} email={email} setAddress={(e) => setAddress(e)} address={address} setCity={(e) => setCity(e)} city={city} setPostalCode={(e) => setPostalCode(e)} postalCode={postalCode} setProvince={(e) => setProvince(e)} province={province} setPhone={(e) => setPhone(e)} phone={phone} setCountry={(e) => setCountry(e)} country={country} />
                            }
                        </div>
                        {user != null &&
                            <div className="flex space-x-2 mt-4 items-center">
                                <input type="checkbox" onChange={(e) => setSaveBilling(!saveBilling)} />
                                <p className="bid-form-input-label text-gray-400 mb-0 leading-none"> Save billing information</p>
                            </div>
                        }

                        {/* Card Details */}
                        {loading == false ?
                                <div>
                                    <div className="mt-7 col-span-1">
                                        <div className="flex items-center justify-between border-b pb-2 mb-3">
                                            <h2 className="text-sm font-productsans-medium text-gray-600">Tip Percentage</h2>
                                        </div>
                                        <label class="input-group">
                                            <>
                                                <input
                                                    class="bid-form-input h-9 rounded-md w-full"
                                                        type="number" onWheel={(e) => e.target.blur()} 
                                                    placeholder="0"
                                                    required
                                                    onChange={(e) => handleTipInput(e.target.value)}
                                                /><span>%</span>
                                            </>
                                        </label>
                                    </div>
                                    
                                    {
                                        // user == null ?
                                        // <>
                                        //     <div className="flex items-center justify-between border-b pb-2 mb-3 mt-8">
                                        //         <h2 className="text-sm font-productsans-medium text-gray-600">Card Details</h2>
                                        //     </div>
                                        //     <div className={`${selectedIndex == -1 && "border-2 border-gray-400"} h-12 rounded items-center flex justify-between text-paragraph font-roboto-medium text-gray-600 cursor-pointer`} onClick={() => setSelectedIndex(-1)}>
                                        //         <CardElement className="hover:bg-gray-200 bid-form-input w-full" id="card"/>
                                        //     </div>
                                        // </>
                                        // :
                                        <PaymentCardDetails selectedIndex={selectedIndex} pms={pms} setSelectedIndex={(e) => setSelectedIndex(e)} newCardToggle={newCardToggle} setNewCardToggle={(e) => setNewCardToggle(e)} creditToggle={creditToggle} setCreditToggle={(e) => setCreditToggle(e)} setSavePm={(e) => setSavePm(e)} savePm={savePm} />
                                    }
                                </div>
                                :
                                null
                        }
                    </div>
                    
                    <div className="md:flex md:flex-col w-1/2 space-y-3">
                        <div className="h-full p-3.5 border rounded-md bg-gray-100">
                            <h2 className="border-b-2 pb-2 font-productsans-medium text-sm text-gray-600">Order Summary</h2>
                            <div className="py-1.5 flex items-center justify-between border-b border-gray-100">
                                <h6 className="bid-form-input-label mb-0 text-gray-600">Headcount</h6>
                                <p className="andie-description-text text-gray-500">{response.bid ? response.bid.guest_count : guestCount } guests</p>
                            </div>
                            <div className="py-1.5 flex justify-between border-b border-gray-100">
                                <h6 className="bid-form-input-label mb-0 text-gray-600">Restaurant type</h6>
                                <div className="cursor-none px-3 py-0.5 rounded-sm bg-indigo-100">
                                    {/* find item.cuisine_type in  template.js. Double check that response.restaurant_type works if DOUBLE NAMING occurs*/}
                                    {response.cuisine_type != null ?
                                        <p className="text-tiny font-roboto-bold text-indigo-700">{response.cuisine_type}</p>
                                        :
                                        <p className="text-tiny font-roboto-bold text-indigo-700">{response.restaurant_type}</p>
                                    }
                                </div>
                            </div>
                            <h6 className="mt-3 bid-form-input-label mb-0 pb-1 text-gray-600 border-b">Items</h6>
                            <div className="overflow-y-scroll h-52">
                                {items.map((product, index) => (
                                    <div className="pt-3 border-b-2 border-dashed w-full">
                                        <div className="flex justify-between w-full">
                                            <div className="flex flex-col text-xs w-4/5">
                                                <p className="andie-description-text font-roboto-medium text-gray-600">{product.name}</p>
                                                <p className="andie-section-descriptor leading-tight text-gray-500 line-clamp-2">{product.description}</p>
                                            </div>
                                            <div className="text-right">
                                                <p className="font-roboto-bold text-[11px] text-green-600">${pricetoString(product.price)}</p>
                                                <p className="font-roboto-medium text-[11px]  text-gray-500">x {product.quantity}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>

                        <div className="flex flex-col p-3.5 border rounded-md border-gray-200">
                            <h2 className="border-b-2 pb-2 font-productsans-medium text-sm text-gray-600">Order Total</h2>
                            <div className="border-b-2">
                                <div className="flex justify-between">
                                    {loading ?
                                        null
                                        :
                                        <>
                                            <div className="text-sm space-y-2 py-3">
                                                <p className="bid-form-input-label leading-none text-gray-600 mb-0">Subtotal</p>
                                                <p className="bid-form-input-label leading-none text-gray-600 mb-0">Tax</p>
                                                <p className="bid-form-input-label leading-none text-gray-600 mb-0">Tip</p>
                                                {(cgeType !== "Dining" && cgeType !== "Premium Dining") &&
                                                    <p className="bid-form-input-label leading-none text-gray-600 mb-0">{(response.business_delivers) ? "Partner " : "Pluck"} Delivery Fee</p>
                                                }
                                                <p className="bid-form-input-label leading-none text-gray-600 mb-0">Service Fee</p>
                                            </div>
                                            <div className="text-right text-xs space-y-2 py-3">
                                                <p className="andie-description-text leading-none text-gray-600 mb-0">${pricetoString(subtotal)}</p>
                                                <p className="andie-description-text leading-none text-gray-600 mb-0">${pricetoString(tax)}</p>
                                                <p className="andie-description-text leading-none text-gray-600 mb-0">${(Math.round(parseFloat(tipInput * subtotal) * 100) / 100).toFixed(2)}</p>
                                                {(cgeType !== "Dining" && cgeType !== "Premium Dining") &&
                                                    <p className="andie-description-text leading-none text-gray-600 mb-0">${response.delivery_fee == null? deliveryFee.toFixed(2) : response?.delivery_fee?.toFixed(2)}</p>
                                                }
                                                <p className="andie-description-text leading-none text-gray-600 mb-0">${serviceFee.toFixed(2)}</p>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <hr className="border"></hr>
                            <div className="flex justify-between pt-2">
                                <div className="flex flex-col text-xs">
                                    <p className="font-roboto-bold text-sm text-gray-700">Total</p>
                                </div>
                                <div className="text-right text-xs">
                                    <p className="font-roboto-bold text-sm text-green-500">${pricetoString(total+(Math.round(parseFloat(tipInput * subtotal) * 100) / 100))}</p>
                                </div>
                            </div>
                            <button className="mt-7 andie-dark-button" type="button" onClick={() => submitPaymentForm()}>Complete</button>
                        </div>
                    </div>
                </div>
            }
        </form>
    )
}
