import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useState } from "react";
import { StripeAPI } from "../../apis";

export default function PaymentForm({ setPaymentMethod, getPmData, name }) {
    const[cardholder, setCardholder] = useState("")
    const[cvv, setCVV] = useState(0)
    const [cardDate, setCardDate] = useState()
    const [loading, setLoading] = useState(false)
    const [holderName, setHolderName] = useState(name)

    const stripe = useStripe();
    const elements = useElements();

    const paymentElementOptions = {
        layout: "tabs"
    }

    const handleSubmit = async (e) => {
        // e.preventDefault();
        setLoading(true)
        // setPaymentMethod("")
        try{
            const pm = await stripe.createPaymentMethod({elements, params:{ billing_details: {name: holderName} }})
            const res = await StripeAPI.attachPm(pm.paymentMethod.id);
            getPmData();
        } catch(e) {
        }
        setLoading(false)
    }

    return (
        // <div className="p-4">
        <>
            <div className="flex w-full grid grid-cols-2 gap-x-3 p-8">
                <div className="col-span-2">
                    <input value={holderName} onChange={(e) => setHolderName(e.target.value)} />
                </div>
                <div className="col-span-2">
                    <h2 className="pt-2 bid-form-input-label"> Payment Details </h2>
                    <CardElement className="bid-form-input w-full" id="card"  />
                </div>
                <div className="flex flex-col">
                    <button disabled={loading} className={`${loading == true ? "bg-gray-400" : null} andie-btn-dark text-white mt-4`} onClick={() => handleSubmit()}> Submit </button>
                </div>
            </div>
        </>
        // </div>
    )
}