import axios from "axios";

class ExternalAPIService {
    async uploadImage(file) {
        const cloudname = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
        const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET

        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", uploadPreset);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.cloudinary.com/v1_1/' + cloudname + '/upload',
            headers: {
                'Content-Type': 'text/plain'
            },
            data: formData
        };

        return axios.request(config)
            .then((response) => {
                return (response.data.url)
            })
            .catch((error) => {
                return
            });
    }

    async pull_ip() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://api.ipify.org/',
        };

        return axios.request(config)
            .then((response) => {
                return (response.data);
            })
            .catch((error) => {
                return ('unknown');
            });

    }

    async pull_loc_from_ip(ip) {
        let config = {
            method: 'get',
            url: "http://ip-api.com/json/" + ip
        }

        return axios.request(config)
            .then((response) => {
                return (response.data);
            })
            .catch((error) => {
                return ('unknown');
            });
    }

}

const service = new ExternalAPIService()
export default service;