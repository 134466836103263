import axios from "axios";
import { main_apiEndpoint, cge_apiEndpoint, business_apiEndpoint } from "./config";
import { clearToken, getStaffToken, getToken, setStaffToken, setToken } from "./Credentials";
import { toast } from "react-hot-toast";
import socketAPI from "./SocketAPIs";
import { ExternalAPIs } from ".";

class AccountAPIService {
    async requestAccessCode(payload) {
        var config = {
            method: 'post',
            url: cge_apiEndpoint + 'authentication/request-access-code/',
            data: payload,
        };

        const API = axios(config)
        toast.promise(API, {
            loading: 'Submitting Request',
            success: (res) => {
                let response = socketAPI.refreshAdminDashboard("signup");
                if (response) {
                    return "Request Submitted"
                }
            },
            error: (err) => {
                return `${err.response.data.detail}`
            },
        });
        return API

    }

    async deleteAccessRequest(payload) {
        var config = {
            method: 'delete',
            url: cge_apiEndpoint + 'authentication/delete-cge-request/',
            data: payload,
            headers: {
                Authorization: "Bearer " + getStaffToken(),
            },
        };
        const API = axios(config)
        toast.promise(API, {
            loading: 'Deleting',
            success: (res) => {
                let response = socketAPI.refreshAdminDashboard("signup");
                if (response) {
                    return "Deleted"
                }
            },
            error: (err) => {
                return `${err.response.data.detail}`
            },
        });
        return API
    }

    async deleteUserAccount(payload) {
        var config = {
            method: 'delete',
            url: cge_apiEndpoint + 'account/delete-cge-account/',
            data: payload,
            headers: {
                Authorization: "Bearer " + getStaffToken(),
            },
        };
        const API = axios(config)
        toast.promise(API, {
            loading: 'Deleting',
            success: (res) => {
                let response = socketAPI.refreshAdminDashboard("settings");
                if (response) {
                    return "Deleted"
                }
            },
            error: (err) => {
                return `${err.response.data.detail}`
            },
        });
        return API
    }

    async GetCGERequests() {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: cge_apiEndpoint + 'authentication/get-cge-requests/',
        };
        const API = axios(config)
        return API.then((response) => {
            return response.data
        }).catch((error) => {
            return []
        });
    }

    async getCGEAccounts() {
        var config = {
            method: 'get',
            url: cge_apiEndpoint + 'bidder/get-cge-accounts/',
            headers: {
                Authorization: "Bearer " + getStaffToken(),
            },
        };
        const API = axios(config)
        return API.then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async getBusinessAccounts() {
        var config = {
            method: 'get',
            url: business_apiEndpoint + 'account/get-business-accounts/',
        };
        const API = axios(config)
        return API.then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async getStaffAccounts() {
        var config = {
            method: 'get',
            url: main_apiEndpoint + 'auth/get-staff-accounts/',
            // Authorization: "Bearer " + getStaffToken(),
        };
        const API = axios(config)
        return API.then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }


    async DeactivateAccount(payload) {

        let config = {
            method: "post",
            url: main_apiEndpoint + "auth/deactivate-account/",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),

            },
            data: payload,
        };

        const API = axios(config)

        return toast.promise(API, {
            loading: 'Deactivating Account',
            success: 'Account Deactivated',
            error: (err) => {
                return `${err.response.data.detail}`
            },
        })
    }

    async CreateGenericUser(formData) {

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: main_apiEndpoint + "auth/users/",
            headers: {
                "Content-Type": "application/json",
            },
            data: formData,
        };

        const API = axios(config)

        return toast.promise(API, {
            loading: 'Creating Generic User',
            success: 'Generic User Created',
            error: (err) => {
                return `${err.response.data.detail}`
            },

        })
    }

    async CreateStaffUser(formData) {

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: main_apiEndpoint + "auth/register_staff/",
            headers: {
                "Content-Type": "application/json",
            },
            data: formData,
        };

        const API = axios(config)

        return toast.promise(API, {
            loading: 'Creating Staff User',
            success: (res) => {
                let response = socketAPI.refreshAdminDashboard("staff");
                if (response) {
                    return "Staff User Created"
                }
            },
            error: (err) => {
                return `${err.response.data}`
            },

        })
    }

    async CreateBusinessUser(formData) {

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: business_apiEndpoint + "auth/register_business/",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            },
            data: formData,
        };

        const API = axios(config)

        return toast.promise(API, {
            loading: 'Creating Partner User',
            success: (res) => {
                let response = socketAPI.refreshAdminDashboard("business");
                if (response) {
                    return "Partner User Created"
                }
            },
            error: (err) => {
                return `${err.response.data.detail}`
            },
        })
    }


    // -------------------
    async acceptCGEUser(payload) {
        var config = {
            method: 'post',
            url: cge_apiEndpoint + 'authentication/provide-access-code/',
            data: payload,
        };

        const API = axios(config)

        return toast.promise(API, {
            loading: 'Accepting User',
            success: (res) => {
                let response = socketAPI.refreshAdminDashboard("signup");
                if (response) {
                    return "CGE User Accepted"
                }
            },
            error: (err) => {
                return `${err.response.data.detail}`
            },
        })
    }

    // -------------------

    async LoginUser(d) {
        clearToken()
        const ip_addr = await ExternalAPIs.pull_ip()
        let data = JSON.stringify({
            email: d.email,
            password: d.password,
            ip_addr: ip_addr
        });

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: main_apiEndpoint + "auth/obtain_token/",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        try {
            const response = await axios.request(config);
            setToken(response.data.access);
            let res = await this.VerifyUserOnLogin();
            return res;
        } catch (error) {
            if (error.response.data.detail === 'No active account found with the given credentials') {
                return ("Retry")
            }
            // alert(error.response.data.detail);
        }
    }

    async LoginStaffUser(d) {
        clearToken()
        const ip_addr = await ExternalAPIs.pull_ip()
        let data = JSON.stringify({
            email: d.email,
            password: d.password,
            ip_addr: ip_addr
        });

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: main_apiEndpoint + "auth/obtain_token/",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        try {
            const response = await axios.request(config);
            setStaffToken(response.data.access);
            let res = await this.VerifyStaffUserOnLogin();
            return res;
        } catch (error) {
            if (error.response.data.detail === 'No active account found with the given credentials') {
                return ("Retry")
            }
            // alert(error.response.data.detail);
        }
    }


    async VerifyUserOnLogin() {
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: cge_apiEndpoint + "auth/get-user-and-type/",
            headers: {
                Authorization: "Bearer " + getToken(),
            },
        };

        try {
            const response = await axios.request(config);
            localStorage.setItem('user_details', JSON.stringify(response.data))
            localStorage.setItem('user_type', response.data.account_type)
            return response.status
        } catch (error) {
            return error.response.status;
        }
    }

    async VerifyStaffUserOnLogin() {
        let data = {
            token: getStaffToken(),
        };
        console.log(data)

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: main_apiEndpoint + "auth/verify_jwt_admin/",
            headers: {
                Authorization: "Bearer " + getStaffToken(),
            },
            data: data
        };

        try {
            const response = await axios.request(config);
            if (response.data.account_type === 9) {
                localStorage.setItem('admin_user_details', JSON.stringify(response.data))
                localStorage.setItem('admin_user_type', response.data.account_type)
                return response.status
            } else {
                return ("Not Admin")
            }
        } catch (error) {
            return error.response.status;
        }
    }

    async editStaffUser(payload) {
        var config = {
            method: 'post',
            url: main_apiEndpoint + 'auth/edit-staff-account/',
            data: payload,
        };

        const API = axios(config);
        return toast.promise(API, {
            loading: 'Editing User Details',
            success: (res) => {
                let response = socketAPI.refreshAdminDashboard("staff");
                return "Staff User Edited"
            },
            error: (err) => {
                return `${err.response.data.detail}`
            },
        })
    }

    async editBusinessUser(payload) {
        var config = {
            method: 'post',
            url: business_apiEndpoint + 'account/edit-business-user/',
            data: payload,
        };

        const API = axios(config);
        return toast.promise(API, {
            loading: 'Editing User Details',
            success: (res) => {
                let response = socketAPI.refreshSettings();
                socketAPI.refreshAdminDashboard("settings");

                if (response) {
                    return "Edited"
                }
            },
            error: (err) => {
                return `${err.response.data.detail}`
            },
        })
    }

    async editCGEUser(payload) {
        var config = {
            method: 'post',
            url: cge_apiEndpoint + 'bidder/edit-cge-account/',
            data: payload,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
        };

        const API = axios(config);
        return toast.promise(API, {
            loading: 'Editing User Details',
            success: (res) => {
                socketAPI.refreshSettings();
                socketAPI.refreshAdminDashboard("settings");
                return "Editted"
            },
            error: (err) => {
                return `${err.response.data.detail}`
            },
        })
    }

    async changePassword(payload) {
        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: main_apiEndpoint + 'auth/change-password/',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            data: payload
        };

        const API = axios(config);
        return toast.promise(API, {
            loading: 'Updating password',
            success: 'Password successfully changed.',
            error: (err) => {
                return `${err.response.data.detail}`
            },
        })
    }

    async editBilling(payload) {
        var config = {
            method: 'post',
            url: main_apiEndpoint + 'auth/account/edit-billing-info/',
            data: payload,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
            },
        };

        const API = axios(config);
        return toast.promise(API, {
            loading: 'Editing Billing Details',
            success: 'Edited',
            error: (err) => {
                return err?.response?.data
            }
        })
    }

    async resetPassword(payload) {
        var config = {
            method: 'post',
            url: main_apiEndpoint + 'auth/users/reset_password_confirm/',
            data: payload,
            headers: {
                "Content-Type": "application/json",
            },
        };

        const API = axios(config);
        return toast.promise(API, {
            loading: 'Resetting Password',
            success: 'Password Reset!',
            error: (err) => {
                return "Error: Could not reset password. Please request again."
            }
        })
    }

    async requestResetPassword(payload) {
        var config = {
            method: 'post',
            url: main_apiEndpoint + 'auth/users/reset_password/',
            data: payload,
            headers: {
                "Content-Type": "application/json",
            },
        };

        const API = axios(config);
        return toast.promise(API, {
            loading: 'Resetting Password',
            success: 'Request submitted!',
            error: (err) => {
                return "Error: Could not request password reset. Please request again."
            }
        })
    }
    async getCGEURLLeads() {
        let config = {
            method: 'get',
            url: cge_apiEndpoint + 'authentication/get_url_referrals/',
        };

        const API = axios(config)
        return API.then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async catchURLLead(url_slug) {
        var ip_addr = await ExternalAPIs.pull_ip()
        var ip_loc = await ExternalAPIs.pull_loc_from_ip(ip_addr)

        let data = JSON.stringify({
            "url_slug": url_slug,
            "ip_addr": ip_addr,
            "location": ip_loc
        });

        let config = {
            method: 'post',
            url: cge_apiEndpoint + 'authentication/url_referral/',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        const API = axios(config)
        
        return API.then((response) => {
            return true
        }).catch((error) => {
            return false
        });

    }
}

const service = new AccountAPIService()
export default service;