import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import moment from 'moment';
import { XIcon } from "@heroicons/react/solid";
import { isWithinInterval } from "date-fns";
import _ from 'lodash';
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

import { CreateEventContactInfo, CreateEventDetails, CreateEventFinancials, CreateEventInfo, CreateEventPreferences, CreateEventRestaurantAndMenu } from "./createbid";
import { BiddingAPIs } from "../../../apis";
import { PopoutModal } from "../../common";
import { PlusIcon } from "@heroicons/react/outline";

const styles = {
    modal: {
        backgroundColor: 'white',
        boxShadow: 'none',
        overflow: 'none',
        padding: '0',
        margin: '0',
        width: "60%",
        height: '85%',
        justifyContent: 'center',
        borderRadius: '2px'
    },
    overlay: {
        backgroundColor: '#5F656C',
        opacity: 0.8,
        padding: 0,
    },
};

export default function BidCreate({ cgeAccountList }) {
    // const [adminUser, setAdminUser] = useState(JSON.parse(window.localStorage.getItem('admin_user_details')));
    const [isOpen, setIsOpen] = useState(false);

    const [perHead, setPerHead] = useState(0);

    const [organization, setOrganization] = useState("");
    const [industry, setIndustry] = useState("Banking");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [contactTitle, setContactTitle] = useState("");
    // const [cgeRepresentative, setCgeRepresentative] = useState

    const [bidTitle, setBidTitle] = useState("");
    const [bidDescription, setBidDescription] = useState("");
    const [eventType, setEventType] = useState("Office Breakfast");

    const [guestCount, setGuestCount] = useState(0);
    const [cgeType, setCGEType] = useState("Catering");
    const [restaurantRating, setRestaurantRating] = useState("Any");

    const [location, setLocation] = useState("");
    const [locationPlaceID, setLocationPlaceID] = useState("");
    const [locationGeometry, setLocationGeometry] = useState("");
    const [locationSecondaryDetails, setLocationSecondaryDetails] = useState("");
    const [locationAdditionalDetails, setLocationAdditionalDetails] = useState("");

    const [eventDateTime, setEventDateTime] = useState(moment(moment()).toDate());
    const [deliveryTime, setDeliveryTime] = useState(moment(moment().toDate()).add(1, "d").add(60, 'minute').startOf('hour').toDate());

    const [restaurantType, setRestaurantType] = useState(['Any']);
    const [dietaryPreferences, setDietaryPreferences] = useState([]);
    const [dietaryNotes, setDietaryNotes] = useState("")
    const [menuOptions, setMenuOptions] = useState([]);

    const [currency, setCurrency] = useState("CAD");
    const [budget, setBudget] = useState({ perHead: 100, total: 500, tipAmount: 0 });
    const [tipStatus, setTipStatus] = useState(0);
    const [accessibility, setAccessibility] = useState({ "Sidewalk": false, "Parking": false, "Stairs": false, "Elevators": false });
    const [expiryDate, setExpiryDate] = useState(moment(moment()).toDate());
    const [paymentTerms, setPaymentTerms] = useState("Prepaid in full");
    const [additionalNotes, setAdditionalNotes] = useState("");

    const [requiresChafing, setRequiresChafing] = useState(false);
    const [packagingType, setPackagingType] = useState(["Any"]);

    const [waiveCancellationFee, setWaiveCancellationFee] = useState(false);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
    const next48HoursInterval = {
        start: moment().toDate(),
        end: moment(moment().add(2, 'd')).toDate()
    };
    const isEventWithin48Hours = isWithinInterval(expiryDate, next48HoursInterval);

    // const isSubmitButtonDisabled = ((isEventWithin48Hours) && !waiveCancellationFee);

    useEffect(() => {
        setIsSubmitButtonDisabled(((isEventWithin48Hours) && !waiveCancellationFee));
    }, [expiryDate, waiveCancellationFee])

    const isDeleteButtonDisabled = ((isEventWithin48Hours) && !waiveCancellationFee);
    const navigate = useNavigate();

    useEffect(() => {
        const hasNonNonePreference = dietaryPreferences.some(
            item => item.preference !== "None"
        );
        if (isEmpty(dietaryPreferences) || (hasNonNonePreference && !dietaryNotes) || bidTitle == "" || bidDescription == "" || location == "") {
            setIsSubmitButtonDisabled(true);
        }
        else {
            setIsSubmitButtonDisabled(false);
        }
    }, [dietaryPreferences, dietaryNotes, bidTitle, bidDescription, location])

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    function shafingNotes() {
        const shaffing_note = '\nThis bid requires a chafing dish to be provided.';
        setRequiresChafing(!requiresChafing);

        if (requiresChafing) {
            setAdditionalNotes(additionalNotes + shaffing_note);
        } else {
            setAdditionalNotes(additionalNotes.replace(shaffing_note, ""));
        }
    }

    const submitChanges = async () => {
        budget.total = budget.perHead * guestCount
        budget.tipAmount = tipStatus / 100;

        if (moment(expiryDate).isBefore(moment())) {
            toast.error("Expiry Date cannot be in the past")
        } else {
            const payload = {
                id: 999,
                organization: organization,
                industry: industry,
                cge_email: email,

                contact_email: email,
                contact_first_name: firstName,
                contact_last_name: lastName,
                contact_phone: phone,
                contact_title: contactTitle,

                bid_title: bidTitle,
                bid_description: bidDescription,
                event_type: eventType,
                cge_type: cgeType,

                event_date_time: moment(eventDateTime).format(),
                guest_count: guestCount,
                location: location.replace(/\w+/g, _.capitalize),
                locationPlaceID: locationPlaceID,
                locationGeometry: locationGeometry,
                locationSecondaryDetails: locationSecondaryDetails,
                locationAdditionalDetails: locationAdditionalDetails,

                expiry_date: moment(expiryDate).format(),
                end_date: "2025-11-14T15:00:00.000Z", //UZAIR
                recurring_period: "week", //UZAIR

                budget: budget,
                currency: currency,
                payment_terms: paymentTerms,

                restaurant_type: restaurantType,

                restaurant_rating: restaurantRating,
                dietary_preferences: dietaryPreferences,
                dietary_notes: dietaryNotes,
                accessibility: accessibility,
                packaging_type: packagingType,
                menu_options: menuOptions,

                transaction_code: transactionCode(6),
                additional_notes: additionalNotes,
                delivery_time: deliveryTime
            }

            let recurring = false //UZAIR

            
            if (recurring) {
                await BiddingAPIs.openRecurringBid(payload).then((res) => {
                }).catch(() => {
                    // alert('Could not create your bid. Try again!')
                });
            } else {
                await BiddingAPIs.openBid(payload).then((res) => {
                }).catch(() => {
                    alert('Could not create your bid. Try again!')
                });
            }

            toggleModal();
        }
    }

    // function deleteBid() {
    //     if (bid?.responses.filter(response => response.accepted == true).length > 0) {
    //         toast.error("You cannot delete this bid. Please submit a cancellation request.")
    //     } else {
    //         const payload = {
    //             id: bid.id
    //         }
    //         BiddingAPIs.deleteBid(payload)
    //         // navigate('/home/bids')
    //     }


    // }
    function transactionCode(length) {
        var result = '';
        // var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!%$#?&';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }

        return result;
    }
    return (

        <>
            <button
                onClick={toggleModal}
                class="absolute bottom-12 right-10 z-99 flex flex-row items-center space-x-2 
                                text-white px-4 w-auto h-10 bg-blue-600 rounded-full
                                hover:bg-blue-700 active:shadow-lg mouse shadow-xl
                                transition ease-in duration-200 focus:outline-none">
                <PlusIcon className="h-4" />
                <span>Create Bid</span>
            </button>

            <Modal
                blockScroll={false}
                open={isOpen}
                onClose={toggleModal}
                animationInDuration={400}
                animationOutDuration={700}
                showCloseIcon={false}
                styles={styles}
                center
            >
                <div className='h-full flex flex-col relative pt-4'>
                    <div className="flex px-4 border-b border-gray-100 pb-3 justify-between">
                        <div>
                            <h5 className="text-base font-productsans-medium text-gray-500">Create bid</h5>
                            <p className="text-tiny sf-regular text-gray-400">Create a bid on behalf of a client</p>
                        </div>

                        <div onClick={toggleModal} className="cursor-pointer h-5 w-5 rounded-full flex items-center justify-center bg-gray-100">
                            <XIcon className="h-3 text-gray-500" />
                        </div>
                    </div>
                    <div className="pt-4 px-4 flex flex-col overflow-y-scroll scrollbar-hide">
                        <CreateEventContactInfo
                            edit={false}
                            cgeAccountList={cgeAccountList}
                            organization={organization}
                            setOrganization={setOrganization}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            contactTitle={contactTitle}
                            setContactTitle={setContactTitle}
                            phone={phone}
                            setPhone={setPhone}
                            email={email}
                            setEmail={setEmail}
                            industry={industry}
                            selectIndustry={setIndustry}
                            additionalNotes={additionalNotes}
                            setAdditionalNotes={setAdditionalNotes}
                        />
                        <CreateEventInfo
                            bidTitle={bidTitle}
                            setBidTitle={setBidTitle}
                            bidDescription={bidDescription}
                            setBidDescription={setBidDescription}
                            cgeType={cgeType}
                            setCGEType={setCGEType}
                            eventType={eventType}
                            setEventType={setEventType}
                            setAccessibility={setAccessibility}
                        />
                        <CreateEventDetails
                            location={location}
                            setLocation={setLocation}
                            setLocationPlaceID={setLocationPlaceID}
                            setLocationGeometry={setLocationGeometry}
                            locationSecondaryDetails={locationSecondaryDetails}
                            setLocationSecondaryDetails={setLocationSecondaryDetails}
                            setLocationAdditionalDetails={setLocationAdditionalDetails}
                            eventDateTime={eventDateTime}
                            setEventDateTime={setEventDateTime}
                            guestCount={guestCount}
                            setGuestCount={setGuestCount}
                            accessibility={accessibility}
                            setAccessibility={setAccessibility}
                            expiryDate={expiryDate}
                            setExpiryDate={setExpiryDate}
                            waiveCancellationFee={waiveCancellationFee}
                            setWaiveCancellationFee={setWaiveCancellationFee}
                            cgeType={cgeType}
                            deliveryTime={deliveryTime} 
                            setDeliveryTime={setDeliveryTime}
                        />
                        <CreateEventFinancials
                            currency={currency}
                            setCurrency={setCurrency}
                            perHead={perHead}
                            setPerHead={setPerHead}
                            guestCount={guestCount}
                            setBudget={setBudget}
                            tipStatus={tipStatus}
                            setTipStatus={setTipStatus}
                            paymentTerms={paymentTerms}
                            setPaymentTerms={setPaymentTerms}
                        />
                        <CreateEventRestaurantAndMenu
                            restaurantType={restaurantType}
                            setRestaurantType={setRestaurantType}
                            menuOptions={menuOptions}
                            setMenuOptions={setMenuOptions}
                            restaurantRating={restaurantRating}
                            setRestaurantRating={setRestaurantRating}
                        />
                        <CreateEventPreferences
                            dietaryPreferences={dietaryPreferences}
                            setDietaryPreferences={setDietaryPreferences}
                            dietaryNotes={dietaryNotes}
                            setDietaryNotes={setDietaryNotes}
                            cge_type={cgeType}
                            packagingType={packagingType}
                            setPackagingType={setPackagingType}
                            requiresChafing={requiresChafing}
                            setRequiresChafing={setRequiresChafing}
                            shafingNotes={shafingNotes}
                        />
                        <div>
                            <h6 className="bid-form-input-label mt-4">Please add any additional notes about your bid</h6>
                            <textarea
                                className="w-full h-32 bid-form-input"
                                id="cus_notes"
                                onChange={(e) => setAdditionalNotes(e.target.value)}
                                type="text"
                                placeholder="If you have any additional notes or requirements, please let us know here."
                                aria-label="Name"
                                defaultValue={additionalNotes}
                            />
                        </div>

                    </div>
                    <div className="h-14 px-4 py-3 border-t border-gray-100 flex justify-between">
                        {/* {!isDeleteButtonDisabled ?
                            <PopoutModal
                                title={"Cancel your bid"}
                                button={
                                    <button disabled={isDeleteButtonDisabled} className={`cursor-pointer flex items-center justify-center bg-red-600 h-8 w-24 rounded px-2.5 ${isDeleteButtonDisabled ? 'opacity-50' : ''}`}>
                                        <h6 className="text-xs font-productsans-medium text-white">Delete Bid</h6>
                                    </button>}
                                modalID={'Create_bid_modal'}
                                width={50}
                            >
                                <div>
                                    <div className="flex items-center space-x-2 mb-5">
                                        <div className="p-5">
                                            <div className="grid grid-cols-1 gap-4">
                                                <p className="label-text">Are you sure you want to delete this bid?</p>
                                                <button onClick={() => deleteBid()} className="bg-blue-200 mt-2 text-xs font-productsans-medium text-gray-500 h-8 w-full rounded px-2.5">Yes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </PopoutModal>
                            : <div></div>
                        } */}

                        <button onClick={submitChanges} className={`cursor-pointer flex items-center justify-center bg-blue-600 h-8 w-24 rounded px-2.5 ${isSubmitButtonDisabled ? 'opacity-50' : ''}`}>
                            <h6 className="text-xs font-productsans-medium text-white">Save Changes</h6>
                        </button>
                    </div>

                </div>
            </Modal >
        </>
    )
}