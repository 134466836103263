export { default as AnyPackagingIcon } from "./AnyPackagingIcon";
export { default as BuffetIcon } from "./BuffetIcon";
export { default as WhatsNewIcon } from "./WhatsNewIcon";
export { default as WelcomeIcon } from "./WelcomeIcon";
export { default as StaffingIcon } from "./StaffingIcon";
export { default as ChafingDishIcon } from "./ChafingDishIcon";
export { default as SaveIcon } from "./SaveIcon";
export { default as CreditCardIcon } from "./CreditCardIcon";
export { default as OffersIcon } from "./OffersIcon";
export { default as DrinksIcon } from "./DrinksIcon";
export { default as BreakfastIcon } from "./BreakfastIcon";
export { default as ReferralGiftIcon } from "./ReferralGiftIcon";
export { default as LinkedInIcon } from "./LinkedInIcon";
export { default as FacebookIcon } from "./FacebookIcon";
export { default as InstagramIcon } from "./InstagramIcon";
export { default as TwitterIcon } from "./TwitterIcon";
export { default as CalculatorIcon } from "./CalculatorIcon";
export { default as SustainabilityIcon } from "./SustainabilityIcon";
export { default as TutorialIcon } from "./TutorialIcon";
export { default as NewIcon } from "./NewIcon";
export { default as GifticonAlt } from "./GiftIconAlt";