import React from "react";

export default function FacebookIcon({ height, color }) {
    return (
        <svg height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
                <path fill="none" color={color} d="M0 0H24V24H0z"></path>
                <path d="M5 3h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2m13 2h-2.5A3.5 3.5 0 0012 8.5V11h-2v3h2v7h3v-7h3v-3h-3V9a1 1 0 011-1h2z"></path>
            </g>
        </svg>
    )
}