import { PlusCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { useState, useEffect, useRef } from 'react';

import listIcon from '../../../assets/images/list-icon.png';
import { cloneDeep } from "lodash";

import { BidResponseTotalDetails, ResponseFormDelivery, ResponseFormItem, ResponseItemFormInput } from '..';
import { menuoptionssort } from '../../common';

export default function ResponseItemForm({ responseItems, setResponseItems, bid, deliveryFee, businessDelivers, setDeliveryFee, setBusinessDelivers, totalPrice, setTotalPrice, pricingType, setPricingType }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));
    const [editingID, setEditingID] = useState(-1);
    const [showItemForm, setShowItemForm] = useState(false);
    const inputRef = useRef(null);
    function checkIfPreferenceIsInReponse() {
        const preferencesList = bid?.dietary_preferences?.map(a => a.preference).filter((i) => (i != "None"))
        const responsePreferences = responseItems.flatMap((item, index) => {
            return item.dietary_option.map((option, index) => option);
        });
        return (preferencesList?.some(item => responsePreferences.includes(item)))
    }

    const dietary_preferencesList = bid?.dietary_preferences?.map(a => a.preference).filter((i) => (i != "None"))

    function deleteItem(itemID) {
        // console.log(itemID)
        setResponseItems(responseItems.filter(item => item.id !== itemID))
    }

    function renderItems() {
        let items = cloneDeep(responseItems);
        items.sort((a, b) => { return menuoptionssort[a.menuoption] - menuoptionssort[b.menuoption] })
        // console.log(inputRef)
        return (
            items.map((item, index) => {
                return (
                    <ResponseFormItem item={item} editingID={editingID} setEditingID={(e) => {setEditingID(e); inputRef.current.scrollIntoView({ behavior: "smooth" })}} deleteItem={(id) => deleteItem(id)} />
                )
            })
        )
    }

    return (
        <div className="border-b space-y-3 pb-5 w-full">
            <div className="border-2 rounded-sm border-dashed py-3 px-3">
                {/* <div className='absolute right-0 group text-right'>
                    <div className="andie-text-button">Instructions</div>
                    <div className='hidden group-hover:flex flex-col mt-3'>
                        <div className='absolute -mt-2 bg-white flex right-7 text-gray-400'><BsChevronUp /></div>
                        <div className='border-2 bg-white w-full p-3 rounded-2xl'>
                            <div className="space-y-4">
                                <p className="andie-description-text">Your submission should include at least one of the following menu options:</p>
                                <div className='flex gap-2 flex-wrap'>
                                    {bid?.menu_options?.map(item =>
                                        <span className="flex text-tiny font-productsans-medium badge badge-info gap-2">{item}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="flex justify-between items-center">
                    <h6 className="text-paragraph text-gray-600 font-productsans-medium">Items included</h6>
                    <div onClick={() => setShowItemForm(true)} className="cursor-pointer flex items-center space-x-1">
                        <PlusCircleIcon className="h-4 text-blue-600" />
                        <p className="text-xs font-productsans-medium">Add item</p>
                    </div>
                </div>
                <div ref={inputRef}>
                    <ResponseItemFormInput
                        responseItems={responseItems} setResponseItems={setResponseItems}
                        showItemForm={showItemForm} setShowItemForm={setShowItemForm}
                        editingID={editingID} setEditingID={setEditingID}
                        deleteItem={(id) => deleteItem(id)}
                    />
                </div>
                {responseItems.length > 0 ?
                    <div className="space-y-3 w-full">
                        {renderItems()}


                        <div className='mx-2 pt-1'>
                            <BidResponseTotalDetails
                                items={responseItems}
                                cge_service_fee_rate={bid.cge_service_fee}
                                business_service_fee_rate={user.service_fee}
                                delivery_fee={deliveryFee}
                                business_delivers={businessDelivers}
                                tipAmount={bid.budget.tipAmount}
                                cge_type={bid.cge_type}
                                pricingType={pricingType} 
                                totalPrice={totalPrice}
                            />
                        </div>

                    </div>
                    :
                    <div className="flex flex-col items-center">
                        <img src={listIcon} className="mt-4 h-12 w-12" alt="" />
                        <p className="my-3 text-center text-tiny font-roboto-regular text-gray-500">
                            There are no items included.
                        </p>
                    </div>
                }

                <div className='mt-4'>
                    <ResponseFormDelivery
                        bid={bid}
                        deliveryFee={deliveryFee} setDeliveryFee={(e) => setDeliveryFee(e)}
                        businessDelivers={businessDelivers} setBusinessDelivers={(e) => setBusinessDelivers(e)}
                        totalPrice={totalPrice} setTotalPrice={(e) => setTotalPrice(e)}
                        pricingType={pricingType} setPricingType={(e) => setPricingType(e)}
                    />
                </div>
            </div>

            {!checkIfPreferenceIsInReponse() && dietary_preferencesList?.length > 0 ?
                <div className="flex my-4 space-x-2 p-4 bg-blue-100 h-24 rounded-sm">
                    <ExclamationCircleIcon className="h-5 flex-shrink-0 text-blue-600 mr-1" />
                    <div className="flex flex-col">
                        <p className="andie-description-text leading-tight">Please ensure you have menu items that satisfy the following dietary requirements:</p>
                        <p className="mt-1.5 andie-description-text font-roboto-medium">{bid?.dietary_preferences?.map(a => a.preference).filter((i) => (i != "None")).join(", ")}</p>
                    </div>
                </div>
                :
                <></>
            }
        </div>
    )
}