import React, { useState, useEffect } from 'react';
import { DocumentDownloadIcon } from "@heroicons/react/solid";
import moment from 'moment';

import BidsItem from '../../components/explore/bids/BidsItem';
import { LoadingActivityBids, LoadingIcon } from '../../components/common';

export default function ActivityBids({ bids, loading }) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));

    const [loadingBid, setLoadingBid] = useState(true);

    const [premiumDining, setPremiumDining] = useState("")
    const [cateringPlus, setCateringPlus] = useState("")
    const [catering, setCatering] = useState("")
    const [dining, setDining] = useState("")

    const [searchInput, setSearchInput] = useState("");
    const [filteredBids, setFilteredBids] = useState([]);

    useEffect(() => {
        getBids()

    }, [bids]);

    const getBids = async () => {
        let result = bids
        setFilteredBids(result);
        setPremiumDining((result.filter(bid => bid.cge_type == "Premium Dining")).length)
        setCateringPlus((result.filter(bid => bid.cge_type == "Catering Plus")).length)
        setCatering((result.filter(bid => bid.cge_type == "Catering")).length)
        setDining((result.filter(bid => bid.cge_type == "Dining")).length)
        if (loading == false) {
            setLoadingBid(false);
        }
    }

    // console.log(bids)

    function selectBidsTab(tabName) {
        let arr = []
        switch (tabName){
            case "Upcoming":
                let temp = []
                let now = moment().format("YYYYMMDD")
                bids.forEach((bid) => {
                    if (moment(bid.event_date_time).format("YYYYMMDD") >= now ) {
                        temp.push(bid)
                    }
                })
                temp.sort((a, b) => (moment(a.event_date_time).format("YYYYMMDD") >= now && moment(b.event_date_time).format("YYYYMMDD") >= now) && moment(a.event_date_time).format("YYYYMMDD") - moment(b.event_date_time).format("YYYYMMDD"))
                setFilteredBids(temp)
                break;
            case "Posted":
                bids.forEach((bid) => {
                    if (bid.status == "Posted") {
                        arr.push(bid)
                    }
                })
                setFilteredBids(arr)
                break;
            case "Expired":
                bids.forEach((bid) => {
                    if (bid.status == "Expired") {
                        arr.push(bid)
                    }
                })
                setFilteredBids(arr)
                break;
            case "Closed":
                bids.forEach((bid) => {
                    if (bid.status == "Closed") {
                        arr.push(bid)
                    }
                })
                setFilteredBids(arr)
                break;
            case "Accepted":
                bids.forEach((bid) => {
                    if (bid.status == "Accepted") {
                        arr.push(bid)
                    }
                })
                setFilteredBids(arr)
                break;
            case "Completed":
                bids.forEach((bid) => {
                    if (bid.status == "Completed") {
                        arr.push(bid)
                    }
                })
                setFilteredBids(arr)
                break;
            case "Order Now":
                bids.forEach((bid) => {
                    if (bid.bid_type == "ordernow") {
                        arr.push(bid)
                    }
                })
                setFilteredBids(arr)
                break;
            default:
                getAll();
        }
    }
    
    const getAll = async() =>{
        setFilteredBids(bids);
    }


    function getCountdownDate(date) {
        const currDate = moment(new Date())
        var duration = moment.duration(date.diff(currDate))._data
        return [duration.days, duration.hours, duration.minutes, duration.seconds]
    }

    return (
        <div className="h-full">
            {(loading || loadingBid) ?
                <LoadingActivityBids />
                :
                <>
                    <div className="mt-2 hidden md:inline-flex w-full justify-between items-center border-b pb-3 px-4 md:px-0">
                        <h6 className="bid-form-input-label font-roboto-regular mb-0 text-gray-600">Showing <span className="text-blue-600 font-productsans-bold">{bids.length}</span> {user?.account_type == 1? "bids that you own" : "active bids that you have responded to"}</h6>
                        <div className="flex space-x-2 items-center">
                            <input className="bid-form-input h-8 w-full" placeholder="Search for a bid" onChange={(e) => setSearchInput(e.target.value)} />
                            <div className="flex flex-shrink-0 space-x-2 items-center">
                                <p className="font-roboto-medium text-paragraph">Sort by:</p>
                                <select class="bid-form-input h-8 p-1" onChange={(e) => selectBidsTab(e.target.value)}>
                                    <option selected value={"All"}>All</option>
                                    <option value={"Upcoming"}>Upcoming</option>
                                    <option value={"Posted"}>Posted</option>
                                    <option value={"Expired"}>Expired</option>
                                    <option value={"Closed"}>Closed</option>
                                    <option value={"Accepted"}>Accepted</option>
                                    <option value={"Order Now"}>Package Order</option>
                                    <option value={"Completed"}>Completed</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div className="flex h-full overflow-hidden">
                        {filteredBids.length > 0 ?
                                <div className="h-full overflow-y-scroll scrollbar-hide md:grid md:grid-cols-2 xl:grid-cols-3 gap-3 md:andie-padding-top md:w-4/5 lg:w-5/6 xl:w-[85%] w-full lg:pr-5 pb-32 md:pb-40">
                                    {filteredBids.map((bid, index) => {
                                        if (bid.bid_title.toLowerCase().includes(searchInput.toLowerCase()) || bid.cge_type.toLowerCase().includes(searchInput.toLowerCase())) {
                                            return (
                                                <BidsItem bid={bid} index={index} searchInput={searchInput} />
                                            )
                                        }
                                    })}
                                    {Array.from({ length: 12 - filteredBids.length } , () => <div className=" h-40 w-full rounded-md bg-gray-50"/>)}
                                </div>
                            :
                            <div className="flex flex-col items-center h-full md:pt-6 w-full md:w-2/3 lg:w-3/5 lg:pr-5 pb-32 md:pb-40">
                                <DocumentDownloadIcon className="h-20 text-blue-600" />
                                <p className="mt-3 tex-center text-green-600 text-xs font-sf-medium">No records found</p>
                                <div className="flex space-x-4 justify-between mt-5 mb-32">
                                    <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                    <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                    <div className="h-1.5 w-1.5 bg-gray-200 rounded-full" />
                                </div>
                            </div>
                        }
                        <div className="flex flex-col md:items-end md:w-1/5 lg:w-1/6 xl:w-[15%] pl-4 md:andie-padding-top">
                            <div className="hidden md:inline-block text-right space-y-2">
                                <div className="font-roboto-regular text-gray-500 text-paragraph">{premiumDining} Premium Dining</div>
                                <div className="font-roboto-regular text-gray-500 text-paragraph">{cateringPlus} Catering Plus</div>
                                <div className="font-roboto-regular text-gray-500 text-paragraph">{catering} Catering</div>
                                <div className="font-roboto-regular text-gray-500 text-paragraph">{dining} Dining</div> 
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}