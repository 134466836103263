
export default function LoadingBidExplore() {
    const arr = [1, 2, 3, 4, 5]
    return (
        <div className="w-full animate-pulse flex flex-row">
            <div className="w-full md:w-[40%] lg:w-[32%] overflow-scroll scrollbar-hide">
            <div className="my-2 h-2 bg-gray-200 w-1/3"/>
                {arr.map(() => {
                    return (
                        <div className="flex flex-col h-48 md:h-36 md:pr-4  w-full border-t border-gray-200 first:border-0 p-3 mb-3">
                            <div className='flex flex-row justify-between mt-1'>
                                <div className='h-2 bg-gray-200 w-7/12'/>
                                <div className='h-2 bg-gray-200 w-3/12'/>
                            </div>
                            <div className='flex flex-row items-center mt-2 space-x-2'>
                                <div className='h-2 bg-gray-200 w-5/12'/>
                                <div className="h-1 w-1 rounded-full bg-gray-400" />
                                <div className='h-2 bg-gray-200 w-2/12'/>
                            </div>
                            <div className='flex flex-row mt-2'>
                                <div className='h-2 bg-gray-200 w-3/12'/>
                            </div>
                            <div className='flex h-2 flex-row mt-2'>
                            </div>
                            <div className='flex h-2 flex-row mt-2'>
                            </div>
                            <div className='flex flex-row mt-2'>
                                <div className='h-2 bg-gray-200 w-3/12'/>
                            </div>
                            <div className='flex flex-row justify-between mt-2'>
                                <div className='h-2 bg-gray-200 w-3/12'/>
                                <div className='h-2 bg-gray-200 w-3/12'/>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="hidden md:inline-flex flex-col md:w-[60%] lg:w-[68%] h-[100vh] overflow pl-5 pt-4">
                <div className="h-[80vh] w-full">
                    <div class="flex flex-row w-full pb-5">
                        <svg class="h-14 w-14 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
                        </svg>
                        <div className="flex flex-col justify-center w-full space-y-2 px-4">
                            <div className='h-3 bg-gray-200 w-3/12'/>
                            <div className='h-2 bg-gray-200 w-3/12'/>
                        </div>
                    </div>
                    <div className="border-t border-b h-9 flex items-center justify-between mb-7 border-gray-100">
                        <div className='h-3 bg-gray-200 w-3/12'/>
                    </div>
                    <div className="mb-7 space-y-2">
                        <div className='h-2 bg-gray-200 w-9/12'/>
                        <div className='h-2 bg-gray-200 w-9/12'/>
                        <div className='h-2 bg-gray-200 w-9/12'/>
                    </div>
                    <div className="mt-7 grid grid-cols-3 gap-3 w-9/12">
                            <div className="bg-gray-50 p-4 rounded-md space-y-2">
                                <div className='h-2 bg-gray-200 w-full'/>
                                <div className='h-2 bg-gray-200 w-full'/>
                            </div>
                            <div className="bg-gray-50 p-3 rounded-md space-y-2">
                                <div className='h-2 bg-gray-200 w-full'/>
                                <div className='h-2 bg-gray-200 w-full'/>
                            </div>
                            <div className="bg-gray-50 p-3 rounded-md space-y-2">
                                <div className='h-2 bg-gray-200 w-full'/>
                                <div className='h-2 bg-gray-200 w-full'/>
                            </div>
                        </div>
                    <div className="mt-7 w-9/12">
                        <div className="bg-gray-50 p-4 rounded-md space-y-2">
                            <div className='h-2 bg-gray-200 w-full'/>
                            <div className='h-2 bg-gray-200 w-full'/>
                        </div>
                    </div>
                    <div className="mt-7 w-9/12">
                        <div className="bg-gray-50 p-4 rounded-md space-y-2">
                            <div className='h-2 bg-gray-200 w-full'/>
                            <div className='h-2 bg-gray-200 w-full'/>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between">
                        
                        <div className='h-3 bg-gray-200 w-2/12'/>
                        <div className="grid grid-flow-col gap-3 text-center auto-cols-max -mt-6">
                            <div className="flex flex-col items-center justify-center h-9 w-9 bg-neutral rounded-sm font-roboto-medium leading-none text-xs text-gray-500">
                                <span className="countdown mt-0.5 h-2 font-productsans-bold leading-none text-pargraph text-gray-200">
                                    
                                </span>
                                days
                            </div>
                            <div className="flex flex-col items-center justify-center h-9 w-9 bg-neutral rounded-sm font-roboto-medium leading-none text-xs text-gray-500">
                                <span className="countdown mt-0.5 h-2 font-productsans-bold leading-none text-pargraph text-gray-200">
                                    
                                </span>
                                hours
                            </div>
                            <div className="flex flex-col items-center justify-center h-9 w-9 bg-neutral rounded-sm font-roboto-medium leading-none text-xs text-gray-500">
                                <span className="countdown mt-0.5 h-2 font-productsans-bold leading-none text-pargraph text-gray-200">
                                    
                                </span>
                                min
                            </div>
                            <div className="flex flex-col items-center justify-center h-9 w-9 bg-neutral rounded-sm font-roboto-medium leading-none text-xs text-gray-500">
                                <span className="countdown mt-0.5 h-2 font-productsans-bold leading-none text-pargraph text-gray-200">
                                    
                                </span>
                                sec
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}