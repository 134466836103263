import { useEffect } from "react"
import { foodOptions } from "../../common"

export default function ResponseFormContact({
    restaurantName, setRestaurantName, 
    contactName, setContactName,
    contactTitle, setContactTitle, 
    contactPhone, setContactPhone, 
    contactEmail, setContactEmail,
    restaurantType, setRestaurantType, 
    accessibility, setAccessibility,
    cge_type, acceptableRestaurants
}) {
    const user = JSON.parse(window.localStorage.getItem('user_details'));

    useEffect(() => {
        if (cge_type == "Dining" || cge_type == "Premium Dining") {
            setAccessibility({
                "Parking": false,
                "Accessible": false,
                "Transit": false,
                "Ride Share": false
            })
        } else {
            setAccessibility({
                "Sidewalk": false,
                "Parking": false,
                "Stairs": false,
                "Elevators": false
            })
        }
    }, [cge_type])


    useEffect(() => {
        const options = foodOptions.filter(obj => user?.restaurant_types?.includes(obj.value))

        for (let index = 0; index < options.length; index++) {
            if (acceptableRestaurants?.includes(options[index].label)) {
                setRestaurantType(options[index].label)
            }
        }
    }, [])

    function handleAccessibility(item) {
        let arr = { ...accessibility }
        arr[item] = !arr[item]
        setAccessibility(arr)
    }

    return (
        <div className="border-b space-y-3 pb-5">
            <div>
                <h6 className="bid-form-input-label mb-0">Business Information</h6>
                <p className="andie-section-descriptor mr-8">Verify the business and contact information for your reponse.</p>
            </div>

            <div className="grid grid-cols-2 gap-3">
                <input
                    className="bid-form-input col-span-1"
                    id="restaurant_name"
                    type="text"
                    placeholder="Restaurant name"
                    required
                    aria-label="Restaurant name"
                    onChange={(e) => setRestaurantName(e.target.value)}
                    value={restaurantName}
                />
                <input
                    className="bid-form-input col-span-1"
                    id="contact_name"
                    type="text"
                    placeholder="Contact Name"
                    required
                    aria-label="Contact Name"
                    onChange={(e) => setContactName(e.target.value)}
                    value={contactName}
                />
            </div>
            <div className="grid grid-cols-5 gap-3">
                <input
                    className="bid-form-input col-span-3"
                    id="contact_title"
                    type="text"
                    placeholder="Title e.g. owner"
                    required
                    aria-label="Contact Title"
                    onChange={(e) => setContactTitle(e.target.value)}
                    value={contactTitle}
                />
                <input
                    className="bid-form-input col-span-2"
                    id="business_name"
                    type="text"
                    placeholder="Phone number"
                    required
                    aria-label="Phone number"
                    onChange={(e) => setContactPhone(e.target.value)}
                    value={contactPhone}
                />
            </div>
            <div className="grid grid-cols-1 gap-3">
                <input
                    className="bid-form-input"
                    id="business_name"
                    type="text"
                    placeholder="Contact email"
                    required
                    aria-label="Contact email"
                    onChange={(e) => setContactEmail(e.target.value)}
                    value={contactEmail}
                />
            </div>
            <div className="grid grid-cols-1 gap-3">
                <select id="event_type" onChange={(e) => setRestaurantType(e.target.value)} value={restaurantType} defaultValue={restaurantType} className="bid-form-input">
                    <option value="" disabled>
                        Please select an option
                    </option>
                    {foodOptions.filter(obj => user?.restaurant_types?.includes(obj.value)).map((item, index) => (
                        <option selected={item.label === restaurantType}>
                            {item.label}
                        </option>
                    ))}
                </select>
            </div>
            {(cge_type == "Dining" || cge_type == "Premium Dining") &&
                <div className="flex border-0  rounded-sm mt-2 h-9 gap-x-2 flex-wrap">
                    {Object.keys(accessibility).map((item, index) => (
                        <div className="flex flex-row gap-x-2">
                            <input type="checkbox" checked={accessibility[item]} onChange={() => handleAccessibility(item)} className="checkbox self-center bg-indigo-50" />
                            <p className="text-xs self-center text-gray-500 font-productsans-regular">
                                {item}
                            </p>
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}