import cateringdata from "./seeds/catering.json";

function filterSearchTemplates(records, searchTerms) {
    const filteredResults = records.filter(event => {
        let match = false;
        searchTerms.forEach(word => {
            if (
                Object.values(event).some(value =>
                    typeof value === 'string' && value.toLowerCase().includes(word.toLowerCase())
                ) ||
                (event.packages && event.packages.some(packageItem =>
                    Object.values(packageItem).some(packageValue =>
                        typeof packageValue === 'string' && packageValue.toLowerCase().includes(word.toLowerCase())
                    )
                ))
            ) {
                match = true;
            }
        });
        return match;
    });

    return filteredResults;
};


function filterSearchBids(bids, keywords) {
    // If keywords array is empty, return all bids
    const filteredResults = bids.filter(bid => {
        // Check if any of the keywords match any of the bid properties
        return keywords.some(keyword => {
            const lowerKeyword = keyword.toLowerCase();
            
            // Check for matches in bid_title and bid_description
            const titleMatch = bid.bid_title && bid.bid_title.toLowerCase().includes(lowerKeyword);
            const descriptionMatch = bid.bid_description && bid.bid_description.toLowerCase().includes(lowerKeyword);
        
            // Check for matches in cge_type, event_type, dietary_notes
            const cgeTypeMatch = bid.cge_type && bid.cge_type.toLowerCase().includes(lowerKeyword);
            const eventTypeMatch = bid.event_type && bid.event_type.toLowerCase().includes(lowerKeyword);
            const dietaryNotesMatch = bid.dietary_notes && bid.dietary_notes.toLowerCase().includes(lowerKeyword);
        
            // Custom filter for budget
            const budgetMatch = bid.budget && (bid.budget.total === parseInt(lowerKeyword) ||
                                bid.budget.perHead === parseInt(lowerKeyword) ||
                                bid.budget.tipAmount === parseInt(lowerKeyword));
        
            // Custom filter for restaurant_type
            const restaurantTypeMatch = bid.restaurant_type && bid.restaurant_type.includes(lowerKeyword);
        
            // Custom filter for menu_options
            const menuOptionsMatch = bid.menu_options && bid.menu_options.includes(lowerKeyword);
        
            // Custom filter for dietary_preferences
            const dietaryPreferencesMatch = bid.dietary_preferences && bid.dietary_preferences.some(pref => pref.value && pref.value.toLowerCase() === lowerKeyword);
        
            // Combine all matches using OR logic
            return titleMatch || descriptionMatch || cgeTypeMatch || eventTypeMatch || dietaryNotesMatch || budgetMatch || restaurantTypeMatch || menuOptionsMatch || dietaryPreferencesMatch;
        });
    });

    return filteredResults;
}

function filterSearchResponses(responses, searchTerms) {
    // If searchTerms array is empty, return all responses
    if (searchTerms.length === 0) {
        return responses;
    }

    // Filter the responses based on the search terms
    const filteredResults = responses.filter(response => {
        // Check if the response matches any of the search terms
        return searchTerms.some(term => {
            const lowerTerm = term.toLowerCase();
            // Check for matches in each field of the response
            return Object.values(response).some(value => {
                if (typeof value === 'string' && value.toLowerCase().includes(lowerTerm)) {
                    return true;
                }
                if (Array.isArray(value) && value.some(item => typeof item === 'string' && item.toLowerCase().includes(lowerTerm))) {
                    return true;
                }
                if (typeof value === 'object' && value !== null) {
                    return Object.values(value).some(val => typeof val === 'string' && val.toLowerCase().includes(lowerTerm));
                }
                return false;
            });
        });
    });

    return filteredResults;
}


// function selectRandomItemsFromArray(event_type, options) {
//     let data = cateringdata;
//     const numItems = Math.floor((Math.random() * 13) + 2);
    

//     // if (event_type.includes("Catering")) {
//     //     data = cateringdata;
//     // }
//     const flattenedArray = (options.length > 0 ? data.filter(c => options.includes(c.cuisine)) : data.filter(c => c.cuisine !== 'Happy Hour')).flatMap(c => c.menus);
//     return [...new Set(Array(numItems).fill().map(_ => flattenedArray[Math.floor(Math.random() * flattenedArray.length)]))];
// }


// function selectRandomItemsFromArray(event_type, options) {
//     let data = cateringdata;
//     const numItems = Math.floor((Math.random() * 13) + 2);

//     const flattenedArray = (options.length > 0 ? data.filter(c => options.includes(c.cuisine)) : data.filter(c => c.cuisine !== 'Happy Hour')).flatMap(c => {
//         let allItems = [];
//         for (const key in c) {
//             if (Array.isArray(c[key])) {
//                 allItems = allItems.concat(c[key]);
//             }
//         }
//         return allItems;
//     });

//     const selectedItems = Array(numItems).fill().map(_ => flattenedArray[Math.floor(Math.random() * flattenedArray.length)]);

//     // Transform the selected items to match the desired format
//     // const transformedItems = selectedItems.map(item => ({
//     //     id: Math.floor(Math.random() * 100000), // Generate a random ID
//     //     name: item.item || 'Unknown', // Use 'Unknown' if item is undefined
//     //     price: item.price ? Number(item.price.slice(1)) : 0.00, // Set price to 0.00 if not specified
//     //     quantity: 1,
//     //     serves: 70, // This value can be adjusted according to your requirements
//     //     description: item.description || '', // Use empty string if description is undefined
//     //     menuoption: getMenuOption(item), // You can customize this based on the item type
//     //     dietary_option: getDietaryOptions(item) // Populate dietary options based on item properties
//     // }));


//     const transformedItems = selectedItems.map(item => {
//         // Create a shallow copy of the original item
//         const transformedItem = { ...item };
    
//         // Loop through each property of the item
//         for (const key in transformedItem) {
//             // Check if the property is an array
//             if (Array.isArray(transformedItem[key])) {
//                 // If it's an array, transform its elements
//                 transformedItem[key] = transformedItem[key].map(subItem => ({
//                     id: Math.floor(Math.random() * 100000), // Generate a random ID
//                     name: subItem.item || 'Unknown', // Use 'Unknown' if item is undefined
//                     price: subItem.price ? Number(subItem.price.slice(1)) : 0.00, // Set price to 0.00 if not specified
//                     quantity: 1,
//                     serves: 70, // This value can be adjusted according to your requirements
//                     description: subItem.description || '', // Use empty string if description is undefined
//                     menuoption: getMenuOption(subItem), // You can customize this based on the item type
//                     dietary_option: getDietaryOptions(subItem) // Populate dietary options based on item properties
//                 }));
//             }
//         }
    
//         // Return the transformed subItems
//         return transformedItem;
//     });    

//     return transformedItems;
// } 

// function selectRandomItemsFromArray(event_type, cge_type, options) {
//     let data = cateringdata;
//     const numItems = Math.floor((Math.random() * 13) + 2);

//     let flattenedArray=[];

//     flattenedArray = (options.length > 0 ? data.filter(c => options.includes(c.cuisine)) : data.filter(c => c.cuisine !== 'Happy Hour')).flatMap(c => {
//         let allItems = [];
//         for (const key in c) {
//             if (Array.isArray(c[key])) {
//                 allItems = allItems.concat(c[key]);
//             }
//         }
//         return allItems;
//     });

//     // Handle "Happy Hour" cuisine separately
//     const happyHourItems = data.find(c => c.cuisine === 'Happy Hour');
//     if (happyHourItems) {
//         for (const key in happyHourItems) {
//             if (Array.isArray(happyHourItems[key])) {
//                 flattenedArray.push(...happyHourItems[key]);
//             }
//         }
//     }

//     const selectedItems = Array(numItems).fill().map(_ => flattenedArray[Math.floor(Math.random() * flattenedArray.length)]);

//     console.log(selectedItems)

//     const transformedItems = selectedItems.map(item => {
//         // Create a shallow copy of the original item
//         const transformedItem = { ...item };
    
//         // Initialize an empty array to hold all items
//         const allItems = [];
    
//         // Loop through each property of the item
//         for (const key in transformedItem) {
//             // Check if the property is an array
//             if (Array.isArray(transformedItem[key])) {
//                 // If it's an array, transform its elements
//                 transformedItem[key] = transformedItem[key].map(subItem => ({
//                     id: Math.floor(Math.random() * 100000), // Generate a random ID
//                     name: subItem.item || 'Unknown', // Use 'Unknown' if item is undefined
//                     price: subItem.price ? Number(subItem.price.slice(1)) : 0.00, // Set price to 0.00 if not specified
//                     quantity: 1,
//                     serves: 70, // This value can be adjusted according to your requirements
//                     description: subItem.description || '', // Use empty string if description is undefined
//                     menuoption: getMenuOption(item), // You can customize this based on the item type
//                     dietary_option: getDietaryOptions(subItem) // Populate dietary options based on item properties
//                 }));
    
//                 // Concatenate the array to the allItems array
//                 allItems.push(...transformedItem[key]);
    
//                 // Remove the individual array property
//                 delete transformedItem[key];
//             }
//         }
    
//         // Assign the concatenated array to a new property called allItems
//         transformedItem.allItems = allItems;

//         // Add in code to push bartender
//         if (event_type.includes("Happy Hour") && cge_type.includes("Catering")) {
//             const bar = {
//                 "id": Math.floor(Math.random() * 100000),
//                 "name": "Bartender",
//                 "price": Math.floor((Math.random() * 175) + 120),
//                 "serves": 1,
//                 "quantity": 1,
//                 "menuoption": "Other",
//                 "description": "Bartender.",
//                 "dietary_option": [
//                     "None"
//                 ]
//             }

//             allItems.push(bar)
//         }
    
//         // Return the transformed item
//         return transformedItem;
//     });

//     return transformedItems;
// }


function selectRandomItemsFromArray(event_type, cge_type, options, size) {
    let data = cateringdata;
    const numItems = Math.floor((Math.random() * 5) + 2);

    let flattenedArray=[];

    // Handle "Breakfast" and "Happy Hour" cuisine separately if available
    const excludedCuisines = ['Breakfast', 'Happy Hour'];
    data.forEach(restaurant => {
        if ((!options || options.length === 0 || options.includes(restaurant.cuisine)) && !excludedCuisines.includes(restaurant.cuisine)) {
            for (const key in restaurant) {
                if (Array.isArray(restaurant[key])) {
                    flattenedArray.push(...restaurant[key].map(item => ({ ...item, cuisine: restaurant.cuisine })));
                }
            }
        }
    });

    // Handle "Happy Hour" cuisine separately if available
    // const happyHourItems = data.find(c => c.cuisine === 'Breakfast');
    // if (happyHourItems) {
    //     for (const key in happyHourItems) {
    //         if (Array.isArray(happyHourItems[key])) {
    //             // Append happy hour items to the flattened array
    //             flattenedArray.push(...happyHourItems[key].map(item => ({ ...item, cuisine: 'Breakfast' })));
    //         }
    //     }
    // }

    const selectedItems = [];
    const selectedRestaurants = new Set();

    // Randomly select items while ensuring only one submission per restaurant
    while (selectedItems.length < numItems && flattenedArray.length > 0) {
        const randomIndex = Math.floor(Math.random() * flattenedArray.length);
        const selectedItem = flattenedArray[randomIndex];
        const restaurantName = selectedItem.restaurant || selectedItem.cuisine;

        // Check if the restaurant has already been selected
        if (!selectedRestaurants.has(restaurantName)) {
            selectedItems.push(selectedItem);
            selectedRestaurants.add(restaurantName);
        }

        // Remove the selected item from the flattened array to prevent duplicates
        flattenedArray.splice(randomIndex, 1);
    }

    const transformedItems = selectedItems.map(item => {
        // Create a shallow copy of the original item
        const transformedItem = { ...item };
    
        // Initialize an empty array to hold all items
        const allItems = [];
    
        // Loop through each property of the item
        for (const key in transformedItem) {
            // Check if the property is an array
            if (Array.isArray(transformedItem[key])) {
                // If it's an array, transform its elements
                transformedItem[key] = transformedItem[key].map(subItem => ({
                    id: Math.floor(Math.random() * 100000), // Generate a random ID
                    name: subItem.item || 'Unknown', // Use 'Unknown' if item is undefined
                    price: subItem.price ? Number(subItem.price.slice(1)) : 0.00, // Set price to 0.00 if not specified
                    quantity: Math.floor(Math.random() * (size / 2 + 1)),
                    serves: Math.floor(Math.random() * (size / 2 + 1)), // This value can be adjusted according to your requirements
                    description: subItem.description || '', // Use empty string if description is undefined
                    menuoption: getMenuOption(item), // You can customize this based on the item type
                    dietary_option: getDietaryOptions(subItem) // Populate dietary options based on item properties
                }));
    
                // Concatenate the array to the allItems array
                allItems.push(...transformedItem[key]);
    
                // Remove the individual array property
                delete transformedItem[key];
            }
        }
    
        // Assign the concatenated array to a new property called allItems
        transformedItem.allItems = allItems;

        // Add in code to push bartender
        if (event_type.includes("Happy Hour") && cge_type.includes("Catering")) {
            const bar = {
                "id": Math.floor(Math.random() * 100000),
                "name": "Bartender",
                "price": Math.floor((Math.random() * 175) + 120),
                "serves": 1,
                "quantity": 1,
                "menuoption": "Other",
                "description": "Bartender.",
                "dietary_option": [
                    "None"
                ],
                "cuisine": "Happy Hour" // Add cuisine property for happy hour items
            }

            allItems.push(bar)
        }
    
        // Return the transformed item
        return transformedItem;
    });

    return transformedItems;
}




// Function to determine menu option based on item type
function getMenuOption(item) {
    if (item.main_course) {
        return "Main Course";
    } else if (item.appetizer) {
        return "Appetizer";
    } else if (item.soup) {
        return "Soup";
    } else if (item.side) {
        return "Side";
    } else if (item.dessert) {
        return "Dessert";
    } else {
        return "Other";
    }
}

// Function to populate dietary options based on item properties
function getDietaryOptions(item) {
    const options = [];
    if (item.gluten_free) {
        options.push("Gluten free");
    }
    if (item.vegetarian) {
        options.push("Vegetarian");
    }
    if (item.nut_free) {
        options.push("Nut free");
    }
    return options;
}




export { filterSearchTemplates, filterSearchBids, filterSearchResponses, selectRandomItemsFromArray }